import { formatNumber } from "../../../../../../util/helper";

export const simulationChartConfig = {
	chart: {
		events: {
			redraw: function () {
				if (this.series[3].data && this.series[3].data.length > 0) {
					this.series[3].data.map((d, i) => {
						if (d?.options?.dataLabels?.end === false) {
							this.series[3].data[i].graphic.attr({
								width: this.series[3].data[i].graphic.element.point.shapeArgs.width - (this.series[3].data[i].graphic.element.point.shapeArgs.width / 100) * 40,
							});
						}
					});
				}
			},
		},
	},
	title: {
		text: "",
	},
	xAxis: {
		categories: [],
		title: { text: null },
	},
	yAxis: {
		title: { text: null },
		labels: {
			formatter: function () {
				return formatNumber(this.value.toFixed(2));
			},
		},
	},
	tooltip: {
		crosshairs: true,
		shared: true,
		style: {
			fontSize: "12px",
		},
	},
	exporting: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
	plotOptions: {
		column: {
			pointPadding: 0,
			groupPadding: 0,
			borderWidth: 0,
			shadow: false,
		},
	},
	series: [
		{
			type: "area",
			name: "Real",
			color: "#6FBAE3",
			data: [],
			tooltip: {
				pointFormatter: function () {
					return `Real: ${formatNumber(this.y.toFixed(2))}<br>`;
				},
			},
		},
		{
			type: "line",
			name: "Model Output",
			color: "#007a99",
			data: [],
			tooltip: {
				pointFormatter: function () {
					return `Model Output: ${formatNumber(this.y.toFixed(2))}<br>`;
				},
			},
		},
		{
			type: "line",
			name: "No Pricing Simulation",
			color: "#d41165",
			data: [],
			dashStyle: "dash",
			tooltip: {
				pointFormatter: function () {
					return `No Pricing Simulation: ${formatNumber(this.y.toFixed(2))}<br>`;
				},
			},
		},
		{
			type: "column",
			name: "Holidays",
			color: "#fed8b199",
			data: [],
			tooltip: {
				pointFormatter: function () {
					return `Holidays: ${this.options?.dataLabels?.name}<br>`;
				},
			},
		},
	],
};
