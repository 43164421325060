import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import FilterAccordion from "../../../../components/Filters";
import Indicator from "../../../../components/Loader";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import { IAssortmentPenetrationLimit, IMnAFilterConfigurations } from "../../../../types/mixAndAssortment";
import DatasourceTooltip from "../../../../components/MnAFilters/DatasourceTooltip/DatasourceTooltip";
import UserInputFilters from "./UserInputFilters";
import dataObject from "../../../../mocks/executionTracker";

const disableFilter = (selectedFilters: IMnAFilterConfigurations, selectedPenetrationLimit: IAssortmentPenetrationLimit) => {
	let flag: boolean = true;
	if (
		selectedFilters.assortmentScenario?.length &&
		selectedFilters.businessUnit.length &&
		selectedFilters.channel.length &&
		selectedFilters.country.length &&
		selectedFilters.dateTimePeriod.length &&
		selectedFilters.mixScenario?.length &&
		selectedFilters.region.length &&
		selectedFilters.storeSegment.length &&
		selectedPenetrationLimit.mustHaveSKU > 0 &&
		selectedPenetrationLimit.goodToHaveSKU > 0
	)
		flag = false;
	return flag;
};

const TopFilters: FC<{
	selectedFilters: IMnAFilterConfigurations;
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
	setSkeleton: Dispatch<SetStateAction<boolean>>;
}> = ({ selectedFilters, setSelectedFilters, setSkeleton }) => {
	const [filterOptions, setFilterOptions] = useState<IMnAFilterConfigurations>(dataObject.overallFilterOptions);
	const [selectedPenetrationLimit, setSelectedPenetrationLimit] = useState<IAssortmentPenetrationLimit>({
		mustHaveSKU: 0,
		mustHaveSkuCurrentAverage: 48,
		goodToHaveSKU: 0,
		goodToHaveSkuCurrentAverage: 27,
	});

	const onChangeFilter = (key, value) => {
		const selectedValues = { ...selectedFilters, [key]: value };
		setSelectedFilters(selectedValues);
	};

	const onApplyFilter = () => {
		setSkeleton(false);
	};

	const clearFilter = () => {
		setSelectedFilters({
			country: [],
			dateTimePeriod: [],
			businessUnit: [],
			endTimePeriod: [],
			region: [],
			channel: [],
			storeSegment: [],
			mixScenario: [],
			assortmentScenario: [],
		});
		setSelectedPenetrationLimit({
			mustHaveSKU: 0,
			mustHaveSkuCurrentAverage: 48,
			goodToHaveSKU: 0,
			goodToHaveSkuCurrentAverage: 27,
		});
		setSkeleton(true);
	};

	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<Indicator position="absolute" show={false} />
			<CardContent>
				<>
					<DatasourceTooltip dashboardName="Execution" />
					<Grid marginTop={3}>
						<FilterAccordion title="Overall Filters">
							<Grid container>
								<Grid item sm={9} className="geo_filters_left">
									<CommonMnAFilters
										filterData={filterOptions}
										onChange={onChangeFilter}
										data={dataObject.overallFilters}
										defaultFilters={selectedFilters ?? null}
										filterOrder={dataObject.analysisFilterOrder}
									/>
								</Grid>
							</Grid>
						</FilterAccordion>
						<FilterAccordion title="Geo Filters">
							<Grid container>
								<Grid item sm={9} className="geo_filters_left">
									<CommonMnAFilters
										filterData={filterOptions}
										onChange={onChangeFilter}
										data={dataObject.geoFilter}
										showSkeleton={false}
										defaultFilters={selectedFilters ?? null}
										filterOrder={dataObject.geoFilterOrder}
									/>
								</Grid>
							</Grid>
						</FilterAccordion>
						<UserInputFilters
							selectedFilters={selectedFilters}
							setSelectedFilters={setSelectedFilters}
							onChangeFilter={onChangeFilter}
							selectedPenetrationLimit={selectedPenetrationLimit}
							setSelectedPenetrationLimit={setSelectedPenetrationLimit}
						/>
					</Grid>
					<Grid className="p-l-16" marginTop={2}>
						<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
							Clear Filter
						</OrangeBtn>
						<PrimaryBtn color="primary" onClick={onApplyFilter} disabled={disableFilter(selectedFilters, selectedPenetrationLimit)}>
							Run Simulation
						</PrimaryBtn>
					</Grid>
				</>
			</CardContent>
		</Card>
	);
};

export default TopFilters;
