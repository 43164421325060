const primaryFilter = {
	country: {
		title: "Country",
		options: [],
	},
	geoLevel: {
		title: "Geo-Level",
		options: [],
		placeholder: "Select",
		all: false,
		multiple: false,
	},
	region: {
		title: "Region",
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
	},
	city: {
		title: "City",
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
	},
	channel: {
		title: "Channel",
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
	},
	subChannel: {
		title: "Sub Channel",
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
	},
	keyAccounts: {
		title: "Key Accounts",
		options: [],
		placeholder: "Null",
		all: false,
		multiple: false,
	},
};

const productFilter = {
	category: {
		title: 'Category <span style="color: #858c94;">(PepsiCo)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		first: true,
		children: "segment",
	},
	segment: {
		title: 'Segment <span style="color: #858c94;">(PepsiCo)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "brand",
	},
	brand: {
		title: 'Brand <span style="color: #858c94;">(PepsiCo)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "subBrand",
	},
	subBrand: {
		title: 'Sub Brand <span style="color: #858c94;">(PepsiCo)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "packSize",
	},
	packSize: {
		title: 'Pack Size <span style="color: #858c94;">(PepsiCo)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "permutation",
	},
	permutation: {
		title: 'Permutation <span style="color: #858c94;">(PepsiCo)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		last: true,
	},
};

const anchorFilter = {
	anchorVendor: {
		title: 'Vendor <span style="color: #858c94;">(Pep & Competitor)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		first: true,
		children: "anchorCategory",
	},
	anchorCategory: {
		title: 'Category <span style="color: #858c94;">(Pep & Competitor)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		first: true,
		children: "anchorSegment",
	},
	anchorSegment: {
		title: 'Segment <span style="color: #858c94;">(Pep & Competitor)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "anchorBrand",
	},
	anchorBrand: {
		title: 'Brand <span style="color: #858c94;">(Pep & Competitor)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "anchorSubBrand",
	},
	anchorSubBrand: {
		title: 'Sub Brand <span style="color: #858c94;">(Pep & Competitor)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "anchorPackSize",
	},
	anchorPackSize: {
		title: 'Pack Size <span style="color: #858c94;">(Pep & Competitor)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "anchorPermutation",
	},
	anchorPermutation: {
		title: 'Permutation <span style="color: #858c94;">(Pep & Competitor)</span>',
		options: [],
		placeholder: "All",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		last: true,
	},
};

const measureFilter = {
	variable1: {
		options: [
			{ id: "1", value: "SOM", disabled: false },
			{ id: "2", value: "Volume", disabled: false },
			{ id: "3", value: "Revenue", disabled: false },
			{ id: "4", value: "Units", disabled: false },
		],
		defaultOption: { id: "1", value: "SOM" },
	},
	SOMVarConfig: {
		options: [
			{ id: "1", value: "Sales" },
			{ id: "2", value: "Volume" },
			{ id: "3", value: "Units" },
		],
		defaultOption: { id: "1", value: "Sales" },
	},
	SOM: {
		options: [
			{ id: "1", value: "Category" },
			{ id: "2", value: "Segment" },
		],
		defaultOption: { id: "1", value: "Category" },
	},
	measure: {
		options: [
			{ id: "1", value: "Price per Unit" },
			{ id: "2", value: "Price per Volume" },
		],
		defaultOption: { id: "1", value: "Price per Unit" },
	},
};

const correlationPepsiCoTable = [
	{
		title: "Date",
		values: [],
	},
	{
		title: "PepsiCo Pack Size",
		values: [],
	},
	{
		title: "PepsiCo Grammage",
		values: [],
	},
	{
		title: "P Grammage(%)",
		values: [],
	},
];

const correlationAnchorTable = [
	{
		title: "Date",
		values: [],
	},
	{
		title: "Anchor Pack Size",
		values: [],
	},
	{
		title: "Anchor Grammage",
		values: [],
	},
	{
		title: "A Grammage(%)",
		values: [],
	},
];

const APISimulation = [
	{
		title: "Sales Out",
		value: "0",
		simulated: "0",
		percentVar: 0,
		anchor: "0",
	},
	{
		title: "Volume Out",
		value: "0",
		simulated: "0",
		percentVar: 0,
		anchor: "0",
	},
	{
		title: "Units Out",
		value: "0",
		simulated: "0",
		percentVar: 0,
		anchor: "0",
	},
	{
		title: "Share Of Market",
		value: "0",
		simulated: "0",
		percentVar: 0,
		anchor: "0",
	},
];

const realESTVolumeTable = {
	heading: [{ title: "API" }, { title: "Real Volume" }, { title: "EST Volume" }],
	value: [],
};

const regressionStatsTable: any = {
	heading: [{ title: "Regression Statistics", colSpan: 2 }],
	value: [
		{ statsName: "Multiple R", statsPer: 0 },
		{
			statsName: { bgcolor: null, color: null, value: "R Square" },
			statsPer: { bgcolor: null, color: null, value: 0 },
		},
		{ statsName: "Adjusted R Square", statsPer: 0 },
		{ statsName: "Standard Error", statsPer: 0 },
		{ statsName: "Obervations", statsPer: 0 },
	],
};

const coefficientTable = {
	heading: [
		{ title: "" },
		{ title: "Coefficients" },
		{ title: "Standard Errors" },
		{ title: "T-stat" },
		{ title: "P-value" },
		{ title: "Lower 95" },
		{ title: "Upper 95" },
	],
	value: [],
};

const crossElasticityFilter = {
	title: "Cross Elasticity",
	options: [
		{ id: "1", value: "Yes" },
		{ id: "2", value: "No" },
	],
	defaultOption: { id: "1", value: "Yes" },
	showRadioButton: true,
};

const monthRangeFilter = {
	title: "Select Range of Month",
	options: [
		{ id: "1", value: "3 Months" },
		{ id: "2", value: "6 Months" },
		{ id: "3", value: "12 Months" },
	],
	defaultOption: { id: "1", value: "3 Months" },
	showRadioButton: true,
};

const realESTVolumeIndexTable = {
	heading: [{ title: "API" }, { title: "Real Volume Index" }, { title: "EST Volume Index" }],
};

const defaultAPIVolumeFilterConfigurations = [
	{
		country: "MEXICO",
		geoLevel: ["REGION-CHAN"],
		geoLevel2: ["MX NORTH-DTS"],
		channel: ["DTS"],
		periodView: ["na"],
		vendor: ["PEPSICO"],
		category: ["SAV-SALTY"],
		segment: ["SAV-POTATO", "SAV-TORTILLA"],
		brand: ["DORITOS", "SABRITAS PC"],
		subBrand: [
			"DORITOS BLAST",
			"DORITOS CHILE",
			"DORITOS FLAMIN HOT",
			"DORITOS HABANERO BITES",
			"DORITOS INCOGNITA",
			"DORITOS NACHO",
			"DORITOS OTHER SUBBRAND",
			"DORITOS PIZZEROLA",
			"DORITOS POPMIX",
			"DORITOS RAINBOW",
			"DORITOS VARIETY",
			"SABRITAS ACIDULCE",
			"SABRITAS ADOBADAS",
			"SABRITAS BLAZIN CHEESE",
			"SABRITAS CHAMPIONS",
			"SABRITAS CHILE MANZANO",
			"SABRITAS CHORIQUESO",
			"SABRITAS CREMA Y ESPECIAS",
			"SABRITAS FLAMIN HOT",
			"SABRITAS HABANERO",
			"SABRITAS LIMON",
			"SABRITAS PC OTHER SUBBRAND",
			"SABRITAS PC VARIETY",
			"SABRITAS SAL",
			"SABRITAS SENSACIONES",
		],
		packSize: ["04X-CHICO", "09X-JUMBO", "11X-COMPARTE", "12X-FAMILIAR"],
		permutation: [
			"DOR BLA_TORTIL_04X-CHICO",
			"DOR BLA_TORTIL_11X-COMPARTE",
			"DOR BLA_TORTIL_12X-FAMILIAR",
			"DOR CHI_TORTIL_04X-CHICO",
			"DOR CHI_TORTIL_09X-JUMBO",
			"DOR CHI_TORTIL_11X-COMPARTE",
			"DOR FLA_TORTIL_04X-CHICO",
			"DOR FLA_TORTIL_09X-JUMBO",
			"DOR FLA_TORTIL_11X-COMPARTE",
			"DOR FLA_TORTIL_12X-FAMILIAR",
			"DOR INC_TORTIL_04X-CHICO",
			"DOR INC_TORTIL_09X-JUMBO",
			"DOR INC_TORTIL_11X-COMPARTE",
			"DOR INC_TORTIL_12X-FAMILIAR",
			"DOR NCH_TORTIL_04X-CHICO",
			"DOR NCH_TORTIL_09X-JUMBO",
			"DOR NCH_TORTIL_11X-COMPARTE",
			"DOR NCH_TORTIL_12X-FAMILIAR",
			"DOR OTH_TORTIL_04X-CHICO",
			"DOR OTH_TORTIL_09X-JUMBO",
			"DOR OTH_TORTIL_11X-COMPARTE",
			"DOR OTH_TORTIL_12X-FAMILIAR",
			"DOR PIZ_TORTIL_04X-CHICO",
			"DOR PIZ_TORTIL_09X-JUMBO",
			"DOR PIZ_TORTIL_11X-COMPARTE",
			"DOR PIZ_TORTIL_12X-FAMILIAR",
			"DOR POP_TORTIL_11X-COMPARTE",
			"DOR POP_TORTIL_12X-FAMILIAR",
			"DOR RAI_TORTIL_04X-CHICO",
			"DOR RAI_TORTIL_11X-COMPARTE",
			"SPC ACI_POTATO_04X-CHICO",
			"SPC ACI_POTATO_09X-JUMBO",
			"SPC ADO_POTATO_04X-CHICO",
			"SPC ADO_POTATO_09X-JUMBO",
			"SPC ADO_POTATO_11X-COMPARTE",
			"SPC ADO_POTATO_12X-FAMILIAR",
			"SPC BCH_POTATO_04X-CHICO",
			"SPC BCH_POTATO_12X-FAMILIAR",
			"SPC CHA_POTATO_04X-CHICO",
			"SPC CHA_POTATO_09X-JUMBO",
			"SPC CHA_POTATO_12X-FAMILIAR",
			"SPC CHM_POTATO_04X-CHICO",
			"SPC CHM_POTATO_09X-JUMBO",
			"SPC CHO_POTATO_04X-CHICO",
			"SPC CHO_POTATO_09X-JUMBO",
			"SPC CYE_POTATO_04X-CHICO",
			"SPC CYE_POTATO_11X-COMPARTE",
			"SPC CYE_POTATO_12X-FAMILIAR",
			"SPC FLA_POTATO_04X-CHICO",
			"SPC FLA_POTATO_09X-JUMBO",
			"SPC FLA_POTATO_11X-COMPARTE",
			"SPC FLA_POTATO_12X-FAMILIAR",
			"SPC HAB_POTATO_04X-CHICO",
			"SPC HAB_POTATO_09X-JUMBO",
			"SPC HAB_POTATO_12X-FAMILIAR",
			"SPC LIM_POTATO_04X-CHICO",
			"SPC LIM_POTATO_09X-JUMBO",
			"SPC LIM_POTATO_11X-COMPARTE",
			"SPC LIM_POTATO_12X-FAMILIAR",
			"SPC OTH_POTATO_04X-CHICO",
			"SPC OTH_POTATO_09X-JUMBO",
			"SPC OTH_POTATO_11X-COMPARTE",
			"SPC OTH_POTATO_12X-FAMILIAR",
			"SPC VAR_POTATO_11X-COMPARTE",
			"SPC SAL_POTATO_04X-CHICO",
			"SPC SAL_POTATO_09X-JUMBO",
			"SPC SAL_POTATO_11X-COMPARTE",
			"SPC SAL_POTATO_12X-FAMILIAR",
		],
		anchorVendor: [
			"ALARA WHOLEFOODS",
			"BIMBO",
			"BOBS RED MILL",
			"BOKADOS",
			"BOTANAS SOL",
			"BYDSA",
			"COLOMBINA SA",
			"CONAGRA",
			"CUETARA",
			"DONDE",
			"DULCEREL",
			"EL CUERNITO",
			"ESPINOZA",
			"FELIPE GUILLEN",
			"GENERAL MILLS",
			"GOLDEN FOODS",
			"HUGO VICTORIA",
			"IGNACIO MICHEL",
			"KELLOGGS",
			"LA LUPITA",
			"LA MODERNA",
			"MARIBEL",
			"MONDELEZ",
			"NATURES PATH FOODS",
			"NESTLE",
			"NISHIKAWA",
			"OTHER VENDOR",
			"PEPSICO",
			"PROD VIDA",
			"RIVERA",
			"RIVERO",
			"TOTIS",
			"VERDE VALLE",
			"YUPI",
		],
		anchorCategory: ["SAV-SALTY"],
		anchorSegment: ["SAV-POTATO", "SAV-TORTILLA"],
		anchorBrand: [
			"ANITA NACHOS",
			"AYALA POTATO",
			"BARCEL CHIPOTLES",
			"BARCEL CHIPS",
			"BARCEL DE LA FERIA",
			"BARCEL PAPA FLAT",
			"BARCEL TOREADAS",
			"BARCEL TORTILLA OTHER",
			"BARCEL WAPAS",
			"BETO PAPA",
			"BLANCAS",
			"BOGOS TORTILLA",
			"BOKADOS PAPAS",
			"BOKADOS TORTILLA",
			"BOT DER POTATO",
			"BOT DER TORTILLA",
			"BOTATAS PAPAS",
			"BUFALO NACHOS",
			"BUFALO PAPAS",
			"CACHITOS TORTILLA",
			"CAPED",
			"CARITO",
			"CHARLY PAPAS",
			"CHARRAS",
			"CHECHITOS TORTILLA",
			"CHIHUAHUA TORTILLA",
			"CHIPYDALE TORTILLA",
			"CRUJIMAX NACHOS",
			"CRUJIMAX PAPAS",
			"DE LA",
			"DE MI TIERRA",
			"DESCO POTATO",
			"DESCO TORTILLA",
			"DIANIS",
			"DON SILVIO",
			"DON SIMON",
			"DORIS PAPAS",
			"DULYBOT POTATO",
			"DURAN POTATO",
			"DZ",
			"EL ANGEL POTATO",
			"EL MAICITO",
			"ENCANTO PAPAS",
			"ENCANTO TORTILLA",
			"EXCELL TORTILLA",
			"FCACHURROS POTATO",
			"FIESTA FRITURAS POTATO",
			"FRITURIN POTATO",
			"GAPI",
			"GARCIA",
			"GONAC POTATO",
			"GONAC TORTILLA",
			"GONZALEZ POTATO",
			"GUERRERO POTATO",
			"GUIMORI POTATO BRAND",
			"IGOMEZ TORTILLA",
			"JAVY POT",
			"JHONY",
			"JOMET PAPA",
			"JOMET TORTILLA",
			"JOVI TORTILLA",
			"JULIMOY POTATO",
			"KETTLE",
			"KI SNACKS",
			"KIPI TORTILLA",
			"KOIO PAPAS",
			"KRIS",
			"LA FE TOTOPO RANCHERO",
			"LA LUPITA PAPAS",
			"LA LUPITA TORTILLA",
			"LA MEXICANA PAPAS",
			"LEO PAPAS",
			"LEO TORTILLA",
			"LOS COYOTES POTATO",
			"LOS COYOTES TORTILLA",
			"LOS REYES POTATO",
			"LOS REYES TORTILLA",
			"LOURDES PAPAS",
			"LOVIES TORTILLA",
			"LUZMA TORTILLA",
			"MAMA CONEJA",
			"MANOLITO POTATO",
			"MERZA",
			"MI REINA",
			"MONTERREY",
			"MOY POTATO",
			"MR. SAULO",
			"NACHOS TITO SURTIDO",
			"NUEVO LEON",
			"NUEVO LEON POTATO",
			"OCHOA",
			"OTHER LABEL SAVORY POTATO",
			"OTHER LABEL TORTILLA",
			"OTHER POTATO BRAND",
			"OTHER TORTILLA BRAND",
			"OYUKI RANCHERO",
			"PAPATINAS",
			"PAPATITAS FER",
			"PARIOS CHIPS",
			"PELAYO POTATO",
			"PICOREY POTATO",
			"PIKIS PAPAS",
			"PRINGLES REGULAR",
			"PRINGLES TORTILLA",
			"PROFE",
			"PROPLASTEC",
			"PROVI POTATO",
			"PVL POTATO",
			"PVL TORTILLA",
			"Q PAPAS",
			"RANCHIPS",
			"RANGEL",
			"RAYADITAS",
			"RD PAPAS",
			"REBOTANA POTATO",
			"RICA PAPA",
			"RICOMAIZ",
			"RIVERA PAPAS",
			"ROLIGO POTATO",
			"SOL POTATO",
			"SOL TUBIPAPA",
			"TAKIS",
			"TOLUQUENAS PAPAS",
			"TOSTACHOS",
			"TOSTIDADAS",
			"TOTIS PAPS",
			"TOTIS TORTILLA",
			"VIKINGO PAPAS",
			"VILLA SAN MIGUEL",
			"VIRIDIANA PAPAS",
			"WINNUTS POTATO",
			"WIPO POTATO",
			"WISE CHIPS",
			"YORICA",
			"YUDITH",
			"YUMI",
			"ZORRITOS",
		],
		anchorSubBrand: [
			"ANITA NACHO",
			"AYALA REGULAR",
			"BARCEL CHIPOTLES REGULAR",
			"BARCEL CHIPS A LA DIABLA",
			"BARCEL CHIPS ADOBADAS",
			"BARCEL CHIPS CHIPOTLE",
			"BARCEL CHIPS FUEGO",
			"BARCEL CHIPS HABANERO",
			"BARCEL CHIPS JALAPENO",
			"BARCEL CHIPS LIMON",
			"BARCEL CHIPS MIX",
			"BARCEL CHIPS OTHER SUBBRAND",
			"BARCEL CHIPS QUESO",
			"BARCEL CHIPS SAL",
			"BARCEL CHIPS TOREADAS",
			"BARCEL CHIPS VARIETY",
			"BARCEL DE LA FERIA OTHER SUBBRAND",
			"BARCEL PAPA FLAT ADOBO MEXICANO",
			"BARCEL PAPA FLAT FUEGO",
			"BARCEL PAPA FLAT SAL",
			"BARCEL TOREADAS HABANERAS",
			"BARCEL TOREADAS SERRANO",
			"BARCEL TOREADAS TRO PICOSAS",
			"BARCEL TORTILLA OTHER SUBBRAND",
			"BARCEL WAPAS LIMON",
			"BARCEL WAPAS QUESO",
			"BARCEL WAPAS SALSA",
			"BETO PAPA SAL",
			"BLANCAS TOTOPO",
			"BOGOS TORTILLA REGULAR",
			"BOKA ENRE REGULAR",
			"BOKA HUEKOS REGULAR",
			"BOKA TOPITOS GRILL",
			"BOKA TOPITOS JALAPENO",
			"BOKA TOPITOS LINAZA",
			"BOKA TOPITOS OTHER",
			"BOKA TOPITOS PIZZA",
			"BOKA TOPITOS QUESO",
			"BOKA TOPITOS REGULAR",
			"BOKA TOPITOS SALSA VERDE",
			"BOKA TOSTADITA REGULAR",
			"BOKADOS PAPAS REGULAR",
			"BOT DER ENCANTO POTATO",
			"BOT DER REGULAR",
			"BOT DER SALSA",
			"BOTATAS PAPAS ADOBADA",
			"BOTATAS PAPAS ORIGINALES",
			"BUFALO NACHOS CHILE SALVAJE",
			"BUFALO NACHOS QUESO CHILE",
			"BUFALO PAPAS CLASICA SAL",
			"BUFALO PAPAS FUEGO REJILLA",
			"BUFALO PAPAS JALAPENO REJILLA",
			"BUFALO PAPAS MIX",
			"BUFALO PAPAS QUESO REJILLA",
			"BUFALO PAPAS SAL DE MAR",
			"CACHITOS REGULAR TORTILLA",
			"CAPED REGULAR",
			"CARITO NACHO",
			"CHARLY REGULAR",
			"CHARRAS REGULAR",
			"CHECHITOS TORTILLA REGULAR",
			"CHIHUAHUA NACHO",
			"CHIPYDALE RANCHERITOS",
			"CRUJI NACHOS CHARRAS",
			"CRUJIMAX PAPA BRAVO",
			"CRUJIMAX PAPA QUESO",
			"DE LA REGULAR",
			"DE MI TIERRA TOTOPO CON SAL",
			"DESCO BOTANA",
			"DESCO PAPAS SAL DE MAR",
			"DESCO RANCHEVITOS",
			"DESCO TOTOPO",
			"DIANIS P CHILAQUILES",
			"DON SILVIO",
			"DON SIMON REGULAR",
			"DORIS PAPAS REGULAR",
			"DULYBOT PAPA",
			"DURAN PAPAS REGULAR",
			"DZ REGULAR",
			"EL ANGEL POTATO REGULAR",
			"EL MAICITO REGULAR",
			"ENCANTO PAPAS ADOBADAS",
			"ENCANTO PAPAS QUESO",
			"ENCANTO PAPAS REGULAR",
			"ENCANTO TACHOS",
			"EXCELL TORTILLA REGULAR",
			"FCACHURROS PAPAS",
			"FIESTA POTATO REGULAR",
			"FRITURIN PAPAS",
			"GAPI NATURAL",
			"GAPI REGULAR",
			"GARCIA REGULAR",
			"GONAC CHECHI FRANCESA REGULAR",
			"GONAC CHECHI SALSA NEGRA",
			"GONAC CHECHI TORTILLA",
			"GONAC KIUBO CHECHI TOTOPO KIUBO INTENSO",
			"GONAC KIUBO DIPACHOS",
			"GONAC KIUBO PAPA CASERA",
			"GONAC KIUBO PAPA CASERA FUEGO",
			"GONAC KIUBO PAPA CASERA HABANERO",
			"GONAC KIUBO PAPA CASERA MEGA FUEGO",
			"GONAC KIUBO PAPA CASERA MEGA ONDULADAS ZIGZAG QUESO",
			"GONAC KIUBO PAPA CASERA SAL DE MAR",
			"GONAC KIUBO PAPA CASERA SALSA NEGRA",
			"GONAC KIUBO PAPA ONDULADA",
			"GONAC KIUBO PAPAS FUEGO",
			"GONAC KIUBO PAPAS QUESO",
			"GONAC KIUBO PAPAS REGULAR",
			"GONAC KIUBO PAPAS SAL",
			"GONAC KIUBO TOTOPO INTENSO",
			"GONAC KIUBO TOTOPO MEGA EXTREMO",
			"GONAC KIUBO TOTOPO MEGA MEXICANA",
			"GONAC KIUBO TOTOPO MEGA NACHO",
			"GONAC KIUBO TOTOPO REGULAR",
			"GONAC KIUBO TOTOPO SALSA NEGRA",
			"GONAC KIUBO TOTOPO TOSTACOS",
			"GONAC OTHER",
			"GONZALEZ PAPA",
			"GUERRERO PAPAS ORIGINAL",
			"GUIMORI POTATO SUBBRAND",
			"IGOMEZ NACHO",
			"JAVY POTATO",
			"JHONY TOTOPO",
			"JOMET PAPA SAL",
			"JOMET RANCHERITOS",
			"JOVI RANCHERITOS",
			"JULIMOY FUEGO",
			"JULIMOY HABANERO",
			"JULIMOY JALAPENO",
			"JULIMOY LIMON",
			"JULIMOY QUESO",
			"JULIMOY REGULAR",
			"JULIMOY SAL",
			"JULIMOY VARIETY",
			"KETTLE REGULAR",
			"KI SNACKS HABANERO",
			"KI SNACKS MIX",
			"KI SNACKS QUESO",
			"KI SNACKS REGULAR",
			"KI SNACKS SAL",
			"KIPI FUEGO",
			"KIPI REGULAR",
			"KOIO CHILE",
			"KOIO CHIPOTLE",
			"KOIO HABANERO",
			"KOIO QUESO",
			"KRIS TOTOPOS",
			"LA FE TOTOPO RANCHERO DESHIDRATADO",
			"LA LUPITA PAPAS REGULAR",
			"LA LUPITA TOTOPOS",
			"LA MEXICANA PAPAS",
			"LEO PAPAS ADOBADAS",
			"LEO PAPAS ONDULADAS",
			"LEO TORTILLA REGULAR",
			"LOS COYOTES FUEGO",
			"LOS COYOTES HABANERO",
			"LOS COYOTES JALAPENO",
			"LOS COYOTES NACHO",
			"LOS COYOTES NACHO EL REPARO",
			"LOS COYOTES NATURAL",
			"LOS COYOTES PAPA ADOBADA",
			"LOS COYOTES PAPA HABANERAS",
			"LOS COYOTES PAPA MIX",
			"LOS COYOTES PAPA PAPATIAS",
			"LOS COYOTES PAPA RAYADITAS CON QUESO",
			"LOS COYOTES PAPA TAPATIA CASERA",
			"LOS COYOTES QUESO",
			"LOS COYOTES SALSA",
			"LOS REYES NACHOS AL COMAL",
			"LOS REYES PAPA FRITA",
			"LOS REYES TOTOPO NATURAL ENCHILADO",
			"LOURDES REGULAR",
			"LOVIES RANCHERITOS",
			"LUZMA TOTOPO",
			"MAMA CONEJA QUESO",
			"MAMA CONEJA REGULAR",
			"MANOLITO POTATO REGULAR",
			"MERZA PAPAS",
			"MI REINA REGULAR",
			"MONTERREY REGULAR",
			"MOY POTATO REGULAR",
			"MR. SAULO REGULAR",
			"NACHOS TITO VARIETY",
			"NUEVO LEON FRITURAS",
			"NUEVO LEON FRITURAS POTATO",
			"OCHOA TOTOPO",
			"OTHER POTATO SUBBRAND",
			"OTHER SAVORY POTATO",
			"OTHER TORTILLA",
			"OTHER TORTILLA SUBBRAND",
			"OYUKI RANCHERO REGULAR",
			"PAPATINAS CATSUP",
			"PAPATINAS CHILE EN VINAGRE",
			"PAPATINAS CHILIX",
			"PAPATINAS FUEGO",
			"PAPATINAS OTHER SUBBRAND",
			"PAPATITAS",
			"PARIOS CHIPS REGULAR",
			"PELAYO REGULAR",
			"PICOREY PAPA",
			"PIKIS PAPAS NATURAL",
			"PRINGLES BBQ",
			"PRINGLES CHILE Y LIMON",
			"PRINGLES CREMA Y CEBOLLA",
			"PRINGLES EXTRA HOT",
			"PRINGLES JALAPENO",
			"PRINGLES ORIGINAL",
			"PRINGLES OTHER SUBBRAND",
			"PRINGLES PICANTE",
			"PRINGLES PIZZA",
			"PRINGLES QUESO",
			"PRINGLES SPICY QUESO",
			"PRIVATE LABEL POTATO",
			"PRIVATE LABEL TORTILLA",
			"PROFE MIX",
			"PROPLASTEC REGULAR",
			"PROVI POTATO REGULAR",
			"Q PAPAS REGULAR",
			"RANCHIPS SAL",
			"RANGEL TOTOPOS",
			"RAYADITAS REGULAR",
			"RD PAPAS REGULAR",
			"REBOTANA POTATO REGULAR",
			"RICA PAPA NATURAL",
			"RICOMAIZ REGULAR",
			"RIVERA PAPAS REGULAR",
			"ROLIGO REGULAR",
			"SOL POTATO",
			"SOL TUBIPAPA",
			"TAKIS BLUE HEAT",
			"TAKIS COBRA",
			"TAKIS FUEGO",
			"TAKIS HUAKAMOLE",
			"TAKIS LIMON",
			"TAKIS OTHER SUBBRAND",
			"TAKIS REGULAR",
			"TAKIS SALSA BRAVA",
			"TAKIS VERDE",
			"TAKIS ZOMBIE",
			"TOLUQUENAS PAPAS REGULAR",
			"TOSTACHOS REGULAR",
			"TOSTIDADAS REGULAR",
			"TOTIS PAPS ADOBADAS",
			"TOTIS PAPS FRANCESA",
			"TOTIS PAPS HABANERO",
			"TOTIS PAPS HOT CHILI",
			"TOTIS PAPS JALAPENO",
			"TOTIS PAPS ONDULADAS",
			"TOTIS PAPS ONDULADAS QUESO",
			"TOTIS PAPS OTHER",
			"TOTIS PAPS QUESO",
			"TOTIS PAPS SAL",
			"TOTIS PAPS SAL DE MAR",
			"TOTIS PAPS SALSA NEGRA",
			"TOTIS TOTOPOS",
			"VIKINGO PAPAS REGULAR",
			"VILLA SAN MIGUEL TOTOPOS",
			"VIRIDIANA HOJUELA DE PAPA",
			"VIRIDIANA RUFLE PAPA",
			"WINNUTS POTATO REGULAR",
			"WIPO REGULAR",
			"WISE CHIPS HOME STYLE",
			"WISE CHIPS REGULAR",
			"YORICA TOTOPOS",
			"YUDITH TOTOPO",
			"YUMI NATURAL",
			"YUMI QUESO",
			"YUMI RANCHIPS QUESO",
			"YUMI RANCHIPS SAL",
			"YUMI REGULAR",
			"YUMI SAL",
			"ZORRITOS REGULAR",
		],
		anchorPackSize: ["07X-MAX", "11X-COMPARTE"],
		anchorPermutation: [
			"BCL CHP_TORTIL_07X-MAX",
			"BCL CHP_TORTIL_11X-COMPARTE",
			"BCP DIA_POTATO_07X-MAX",
			"BCP ADO_POTATO_11X-COMPARTE",
			"BCP CCH_POTATO_11X-COMPARTE",
			"BCP FUE_POTATO_07X-MAX",
			"BCP FUE_POTATO_11X-COMPARTE",
			"BCP JAL_POTATO_07X-MAX",
			"BCP JAL_POTATO_11X-COMPARTE",
			"BCP VER_POTATO_07X-MAX",
			"BCP VER_POTATO_11X-COMPARTE",
			"BCP OTH_POTATO_07X-MAX",
			"BCP OTH_POTATO_11X-COMPARTE",
			"BCP QUE_POTATO_11X-COMPARTE",
			"BCP SAL_POTATO_07X-MAX",
			"BCP SAL_POTATO_11X-COMPARTE",
			"BCP TOR_POTATO_07X-MAX",
			"BCP TOR_POTATO_11X-COMPARTE",
			"BDL FOTH_POTATO_11X-COMPARTE",
			"BPA FAM_POTATO_07X-MAX",
			"BPA FAF_POTATO_07X-MAX",
			"BPA FAF_POTATO_11X-COMPARTE",
			"BPA FAS_POTATO_07X-MAX",
			"BTO HAB_POTATO_07X-MAX",
			"BTO HAB_POTATO_11X-COMPARTE",
			"BATL OTH_TORTIL_11X-COMPARTE",
			"BWA LIM_POTATO_07X-MAX",
			"BWA QUE_POTATO_07X-MAX",
			"BWA QUE_POTATO_11X-COMPARTE",
			"BWA SLS_POTATO_07X-MAX",
			"BLS TOT_TORTIL_07X-MAX",
			"BKTL ENR_TORTIL_07X-MAX",
			"BKTL ENR_TORTIL_11X-COMPARTE",
			"BKTL HKS_TORTIL_07X-MAX",
			"BKTL HKS_TORTIL_11X-COMPARTE",
			"BKTL JAL_TORTIL_11X-COMPARTE",
			"BKTL OTH_TORTIL_07X-MAX",
			"BKTL OTH_TORTIL_11X-COMPARTE",
			"BKTL QSO_TORTIL_07X-MAX",
			"BKTL QSO_TORTIL_11X-COMPARTE",
			"BKTL REG_TORTIL_11X-COMPARTE",
			"BKTL SVD_TORTIL_07X-MAX",
			"BKTL SVD_TORTIL_11X-COMPARTE",
			"BKPS REG_POTATO_07X-MAX",
			"BKPS REG_POTATO_11X-COMPARTE",
			"BDET SAL_TORTIL_07X-MAX",
			"CJIP PAQ_POTATO_07X-MAX",
			"DLA REG_POTATO_11X-COMPARTE",
			"DEPO BOT_POTATO_11X-COMPARTE",
			"DEPO PSM_POTATO_11X-COMPARTE",
			"DETL RAN_TORTIL_07X-MAX",
			"DSV REG_POTATO_07X-MAX",
			"DRSP REG_POTATO_07X-MAX",
			"DRSP REG_POTATO_11X-COMPARTE",
			"ELAP REG_POTATO_11X-COMPARTE",
			"ENPS ADO_POTATO_07X-MAX",
			"ENPS QSO_POTATO_07X-MAX",
			"ENPS REG_POTATO_07X-MAX",
			"ENTL TAC_TORTIL_07X-MAX",
			"ENTL TAC_TORTIL_11X-COMPARTE",
			"GOTL TRT_TORTIL_07X-MAX",
			"GOTL TRT_TORTIL_11X-COMPARTE",
			"GOPO PCM_POTATO_07X-MAX",
			"GOPO PCM_POTATO_11X-COMPARTE",
			"GOPO PCS_POTATO_11X-COMPARTE",
			"GOPO PFU_POTATO_11X-COMPARTE",
			"GOPO PRE_POTATO_11X-COMPARTE",
			"GOPO PSA_POTATO_07X-MAX",
			"GOTL TMN_TORTIL_07X-MAX",
			"GOTL TMN_TORTIL_11X-COMPARTE",
			"GOTL TRE_TORTIL_11X-COMPARTE",
			"GOTL TSN_TORTIL_07X-MAX",
			"GOTL TSN_TORTIL_11X-COMPARTE",
			"GOPO OTH_POTATO_07X-MAX",
			"GOPO OTH_POTATO_11X-COMPARTE",
			"GNZP PAP_POTATO_11X-COMPARTE",
			"GUEP PAP_POTATO_11X-COMPARTE",
			"JOM PAP_POTATO_07X-MAX",
			"JOT RAN_TORTIL_11X-COMPARTE",
			"JULP FGO_POTATO_11X-COMPARTE",
			"JULP HAB_POTATO_11X-COMPARTE",
			"JULP JAL_POTATO_11X-COMPARTE",
			"JULP LMN_POTATO_11X-COMPARTE",
			"JULP QSO_POTATO_11X-COMPARTE",
			"JULP REG_POTATO_07X-MAX",
			"JULP REG_POTATO_11X-COMPARTE",
			"JULP SAL_POTATO_11X-COMPARTE",
			"KTT REG_POTATO_11X-COMPARTE",
			"KSN HAB_POTATO_07X-MAX",
			"KSN HAB_POTATO_11X-COMPARTE",
			"KSN MIX_POTATO_11X-COMPARTE",
			"KSN QUE_POTATO_07X-MAX",
			"KSN QUE_POTATO_11X-COMPARTE",
			"KSN REG_POTATO_07X-MAX",
			"KSN REG_POTATO_11X-COMPARTE",
			"KSN SAL_POTATO_07X-MAX",
			"KSN SAL_POTATO_11X-COMPARTE",
			"LFT RAN_TORTIL_11X-COMPARTE",
			"LUPS REG_POTATO_07X-MAX",
			"LPPS PPS_POTATO_11X-COMPARTE",
			"LEPS ADO_POTATO_07X-MAX",
			"LEPS OND_POTATO_07X-MAX",
			"LETL REG_TORTIL_07X-MAX",
			"LETL REG_TORTIL_11X-COMPARTE",
			"LCOT NAC_TORTIL_07X-MAX",
			"LCOT NER_TORTIL_07X-MAX",
			"LCO NAT_POTATO_07X-MAX",
			"LCO PAD_POTATO_07X-MAX",
			"LCO PAD_POTATO_11X-COMPARTE",
			"LCO PPA_POTATO_07X-MAX",
			"LCO PTC_POTATO_11X-COMPARTE",
			"RYSP PFR_POTATO_11X-COMPARTE",
			"LDSP REG_POTATO_11X-COMPARTE",
			"LOVT RAN_TORTIL_07X-MAX",
			"MNPO REG_POTATO_11X-COMPARTE",
			"MOPA REG_POTATO_11X-COMPARTE",
			"OCH TOT_TORTIL_07X-MAX",
			"OPTO SUB_POTATO_07X-MAX",
			"OPTO SUB_POTATO_11X-COMPARTE",
			"OTH SAP_POTATO_07X-MAX",
			"OTH SAP_POTATO_11X-COMPARTE",
			"OTH TOR_TORTIL_07X-MAX",
			"OTH TOR_TORTIL_11X-COMPARTE",
			"OTLA REG_TORTIL_07X-MAX",
			"OTLA REG_TORTIL_11X-COMPARTE",
			"PAP VIN_POTATO_07X-MAX",
			"PAP CHX_POTATO_07X-MAX",
			"PAP OTH_POTATO_07X-MAX",
			"PTI REG_POTATO_11X-COMPARTE",
			"PSH REG_POTATO_07X-MAX",
			"PSH REG_POTATO_11X-COMPARTE",
			"PYOP REG_POTATO_11X-COMPARTE",
			"PKPS NAT_POTATO_11X-COMPARTE",
			"PRIT SQU_TORTIL_11X-COMPARTE",
			"PVL POT_POTATO_07X-MAX",
			"PVL POT_POTATO_11X-COMPARTE",
			"PVL TOR_TORTIL_07X-MAX",
			"PVL TOR_TORTIL_11X-COMPARTE",
			"PRPO REG_POTATO_07X-MAX",
			"PRPO REG_POTATO_11X-COMPARTE",
			"BTAP REG_POTATO_11X-COMPARTE",
			"RPP NAT_POTATO_07X-MAX",
			"SOL POT_POTATO_07X-MAX",
			"SOPO TPP_POTATO_07X-MAX",
			"SOPO TPP_POTATO_11X-COMPARTE",
			"TAK BHT_TORTIL_07X-MAX",
			"TAK BHT_TORTIL_11X-COMPARTE",
			"TAK COB_TORTIL_11X-COMPARTE",
			"TAK FUE_TORTIL_07X-MAX",
			"TAK FUE_TORTIL_11X-COMPARTE",
			"TAK HUA_TORTIL_07X-MAX",
			"TAK LIM_TORTIL_07X-MAX",
			"TAK LIM_TORTIL_11X-COMPARTE",
			"TAK OTH_TORTIL_07X-MAX",
			"TAK OTH_TORTIL_11X-COMPARTE",
			"TAK BRV_TORTIL_07X-MAX",
			"TAK BRV_TORTIL_11X-COMPARTE",
			"TAK VER_TORTIL_07X-MAX",
			"TAK VER_TORTIL_11X-COMPARTE",
			"TAK ZOM_TORTIL_11X-COMPARTE",
			"TLPS PPS_POTATO_07X-MAX",
			"TLPS PPS_POTATO_11X-COMPARTE",
			"TCH REG_TORTIL_07X-MAX",
			"TCH REG_TORTIL_11X-COMPARTE",
			"TOP ADO_POTATO_07X-MAX",
			"TOP ADO_POTATO_11X-COMPARTE",
			"TOP HCH_POTATO_07X-MAX",
			"TOP OTH_POTATO_07X-MAX",
			"TOP PPS_POTATO_07X-MAX",
			"TOP PPS_POTATO_11X-COMPARTE",
			"TOTL TTP_TORTIL_07X-MAX",
			"TOTL TTP_TORTIL_11X-COMPARTE",
			"YRC TOT_TORTIL_11X-COMPARTE",
			"YUM NAT_POTATO_11X-COMPARTE",
			"YUM QUE_POTATO_11X-COMPARTE",
			"YUM RQU_POTATO_11X-COMPARTE",
			"YUM RSA_POTATO_11X-COMPARTE",
			"YUM REG_POTATO_11X-COMPARTE",
			"YUM SAL_POTATO_07X-MAX",
		],
		variable1: "Units",
		somMeasure: "Category",
		somGroup: "Sales",
		apiUnits: "Volume",
		date: "01/01/2023-03/31/2023",
	},
	{
		country: "GUATEMALA",
		geoLevel: ["COUNTRY-TTL"],
		geoLevel2: ["TTL GUATEMALA-ALL"],
		channel: ["ALL OUTLET"],
		periodView: ["na"],
		vendor: ["PEPSICO"],
		category: ["SAV-SALTY"],
		segment: ["SAV-POTATO"],
		brand: [
			"FILLERS POTATO",
			"HULA HOOPS REGULAR",
			"LAYS",
			"LAYS BAKED",
			"LAYS KETTLE",
			"LAYS MAXX",
			"LAYS STAX",
			"OTHER PEPSICO POTATO SUBBRAND",
			"RECETA CRUJIENTE SAL",
			"RUFFLES REGULAR",
			"SABRITAS REGULAR",
		],
		subBrand: [
			"FILLERS POTATO",
			"HULA HOOPS REGULAR",
			"LAYS",
			"LAYS BAKED",
			"LAYS KETTLE",
			"LAYS MAXX",
			"LAYS STAX",
			"OTHER PEPSICO POTATO SUBBRAND",
			"RECETA CRUJIENTE SAL",
			"RUFFLES REGULAR",
			"SABRITAS REGULAR",
		],
		packSize: ["01X-SS XSMALL", "02X-SS SMALL", "03X-SS MEDIUM", "04X-SS LARGE", "05X-SS XLARGE", "06X-SS XXLARGE"],
		permutation: [
			"FLR POT_POTATO_01X-SS XSMALL",
			"FLR POT_POTATO_04X-SS LARGE",
			"HOP REG_POTATO_01X-SS XSMALL",
			"HOP REG_POTATO_04X-SS LARGE",
			"LAY REG_POTATO_01X-SS XSMALL",
			"LAY REG_POTATO_02X-SS SMALL",
			"LAY REG_POTATO_03X-SS MEDIUM",
			"LAY REG_POTATO_04X-SS LARGE",
			"LAY REG_POTATO_05X-SS XLARGE",
			"LAY REG_POTATO_06X-SS XXLARGE",
			"LAY BKD_POTATO_02X-SS SMALL",
			"LAY KET_POTATO_03X-SS MEDIUM",
			"LAY KET_POTATO_04X-SS LARGE",
			"LAY MAX_POTATO_01X-SS XSMALL",
			"LAY MAX_POTATO_02X-SS SMALL",
			"LAY MAX_POTATO_03X-SS MEDIUM",
			"LAY MAX_POTATO_04X-SS LARGE",
			"LAY MAX_POTATO_05X-SS XLARGE",
			"LAY MAX_POTATO_06X-SS XXLARGE",
			"LAY STX_POTATO_04X-SS LARGE",
			"LAY STX_POTATO_05X-SS XLARGE",
			"OTP POT_POTATO_03X-SS MEDIUM",
			"REC SAL_POTATO_04X-SS LARGE",
			"RUF REG_POTATO_02X-SS SMALL",
			"RUF REG_POTATO_03X-SS MEDIUM",
			"SBA REG_POTATO_02X-SS SMALL",
			"SBA REG_POTATO_03X-SS MEDIUM",
		],
		anchorVendor: [
			"13 CEREALES",
			"ABIL",
			"AGROINDUSTRIAS ALBAY",
			"ALCSA",
			"ALIMENTOS CAMPESTRES",
			"ALIMENTOS COOK",
			"ALIMENTOS DIANA",
			"ALIMENTOS H & H",
			"ALIMENTOS JACKS",
			"ALIMENTOS SELECTOS",
			"AMERICAN POP CORN",
			"AMERICAN ROLAND FOOD",
			"ARCOR",
			"BEAR NAKED",
			"BLUE DIAMOND GROWERS",
			"BOCADELI",
			"BRIO",
			"BROHDERS",
			"CAMPBELLS",
			"CASA BAZZINI",
			"CASADEMONT",
			"CENTRAL DE ALIMENTOS",
			"CHOCOLATES BEST",
			"COLOMBINA",
			"CONSERVAS LA COSTEÑA",
			"CORPORACION MULTI INVERSIONES",
			"D CARLOS",
			"DANCAKE",
			"DAVIDS",
			"DEL CARMEN",
			"DELIRICE",
			"DERIVADOS DE LECHE LA ESMERALDA",
			"DESOBRY",
			"DIAMOND FOODS",
			"DINANT",
			"DISTRIBUIDORA DE ALIMENTOS Y BEBIDAS GUACHA",
			"DOMITICA",
			"ELLEDI",
			"ENLACES GUATEMALA",
			"EXCELLENT",
			"FABRICA DE GALLETAS GULLON",
			"FERRERO",
			"FORNO BONOMI",
			"GENERAL MILLS",
			"GLOBAL BRANDS",
			"GRUPO BIMBO",
			"GRUPO CAROZZI",
			"GRUPO INDUSTRIAL ALIMENTICIO",
			"GRUPO VIDA",
			"HEBBE",
			"IBERIA FOODS",
			"IDEALSA",
			"INA",
			"INDUSTRIAS HELIOS",
			"INDUSTRIAS MAFAM",
			"INDUSTRIAS UNIDAS",
			"JOHN B SANFILIPPO & SON",
			"KAMOJA",
			"KELLOGGS",
			"KELSEN",
			"KETTLE FOODS",
			"KIDDYLICIOUS",
			"LA CHILERA",
			"LA FLOR BURGALESA",
			"LINAVENA",
			"LOTUS BAKERY",
			"MADA",
			"MAIZITOS",
			"MANITOBA",
			"MOLINO DI FERRO",
			"MOM BRANDS",
			"MONDELEZ",
			"NATURAL HONEY",
			"NATURASOL",
			"NESTLE",
			"NURTURE",
			"NUTRIEVO",
			"ORGRAN",
			"ORIGINAL GOURMET FOOD",
			"OTHER VENDOR",
			"OTROS",
			"PAN SINAI",
			"PANDURATA ALIMENTOS",
			"PASQUIER",
			"PEPSICO",
			"PICONAS",
			"POP TIME",
			"POST FOODS",
			"PRODECA",
			"PRODUCTOS ALIMENTICIOS CENTROAMERICANOS",
			"PRODUCTOS ALIMENTICIOS CYP",
			"PRODUCTOS HIDROTERRA",
			"PRODUCTOS KITTY",
			"PRODUCTOS RIQUISIMA",
			"PROSNACKS",
			"QUILALI",
			"SALUVITA",
			"SAN ENRIQUE",
			"SEEBERGER",
			"SNYDERS LANCE",
			"TAIFELDS",
			"TELUMA",
			"THE HAIN CELESTIAL",
			"TIA ANGELITA",
			"TORTI EXPRESS",
			"TOSTADAS CHARRAS",
			"TOTIS",
			"TROPICAL NUT & FRUIT",
			"TUTO SNACK",
			"UNILEVER",
			"WALMART",
			"WEAVER POPCORN",
			"YOGI SUPERFOODS",
			"YUMUS",
		],
		anchorCategory: ["SAV-SALTY"],
		anchorSegment: ["SAV-POTATO"],
		anchorBrand: [
			"BOCADELI POTATO REGULAR",
			"C&P POTATO",
			"CHIPS",
			"CYP REGULAR",
			"DEEP RIVER REGULAR",
			"DIANA CRAKS SAVORY",
			"GREAT VALUE POTATO REGULAR",
			"HERRS POTATO REGULAR",
			"KETTLE REGULAR",
			"KITTY POTATO REGULAR",
			"MEGA NUTS POTATO REGULAR",
			"OTHER SUBBRAND POTATO",
			"PRINGLES",
			"PRO PAPAS REGULAR",
			"RICKS POTATO REGULAR",
			"SENORIAL RUFITAS",
			"SULI POTATO REGULAR",
			"TERRA POTATO REGULAR",
			"YUMMIES ZIBAS POTATO",
		],
		anchorSubBrand: [
			"BOCADELI POTATO REGULAR",
			"C&P POTATO",
			"CHIPS",
			"CYP REGULAR",
			"DEEP RIVER REGULAR",
			"DIANA CRAKS SAVORY",
			"GREAT VALUE POTATO REGULAR",
			"HERRS POTATO REGULAR",
			"KETTLE REGULAR",
			"KITTY POTATO REGULAR",
			"MEGA NUTS POTATO REGULAR",
			"OTHER SUBBRAND POTATO",
			"PRINGLES",
			"PRO PAPAS REGULAR",
			"RICKS POTATO REGULAR",
			"SENORIAL RUFITAS",
			"SULI POTATO REGULAR",
			"TERRA POTATO REGULAR",
			"YUMMIES ZIBAS POTATO",
		],
		anchorPackSize: ["01X-SS XSMALL", "02X-SS SMALL", "03X-SS MEDIUM", "04X-SS LARGE", "05X-SS XLARGE", "06X-SS XXLARGE", "09X-XXXLARGE"],
		anchorPermutation: [
			"BDC POT_POTATO_01X-SS XSMALL",
			"BDC POT_POTATO_02X-SS SMALL",
			"BDC POT_POTATO_03X-SS MEDIUM",
			"CCP POT_POTATO_03X-SS MEDIUM",
			"CCP POT_POTATO_04X-SS LARGE",
			"CCP POT_POTATO_05X-SS XLARGE",
			"CCP POT_POTATO_06X-SS XXLARGE",
			"CCP POT_POTATO_09X-XXXLARGE",
			"CHS ORI_POTATO_01X-SS XSMALL",
			"CHS ORI_POTATO_02X-SS SMALL",
			"CHS ORI_POTATO_03X-SS MEDIUM",
			"CHS ORI_POTATO_04X-SS LARGE",
			"CHS ORI_POTATO_05X-SS XLARGE",
			"CYP REG_POTATO_04X-SS LARGE",
			"CYP REG_POTATO_05X-SS XLARGE",
			"CYP REG_POTATO_06X-SS XXLARGE",
			"DPR REG_POTATO_02X-SS SMALL",
			"DPR REG_POTATO_04X-SS LARGE",
			"DAN CRKS_POTATO_01X-SS XSMALL",
			"DAN CRKS_POTATO_03X-SS MEDIUM",
			"GRV POT_POTATO_02X-SS SMALL",
			"GRV POT_POTATO_04X-SS LARGE",
			"GRV POT_POTATO_05X-SS XLARGE",
			"GRV POT_POTATO_06X-SS XXLARGE",
			"HRS POT_POTATO_01X-SS XSMALL",
			"HRS POT_POTATO_05X-SS XLARGE",
			"KET REG_POTATO_04X-SS LARGE",
			"KTY POT_POTATO_03X-SS MEDIUM",
			"KID CRK_POTATO_04X-SS LARGE",
			"KTY POT_POTATO_04X-SS LARGE",
			"KTY POT_POTATO_05X-SS XLARGE",
			"KTY POT_POTATO_09X-XXXLARGE",
			"MGN POT_POTATO_04X-SS LARGE",
			"OSB POT_POTATO_01X-SS XSMALL",
			"OSB POT_POTATO_02X-SS SMALL",
			"OSB POT_POTATO_03X-SS MEDIUM",
			"OSB POT_POTATO_04X-SS LARGE",
			"OSB POT_POTATO_05X-SS XLARGE",
			"OSB POT_POTATO_06X-SS XXLARGE",
			"PRI ORI_POTATO_01X-SS XSMALL",
			"PRI ORI_POTATO_02X-SS SMALL",
			"PRI ORI_POTATO_03X-SS MEDIUM",
			"PRI ORI_POTATO_04X-SS LARGE",
			"PRI ORI_POTATO_05X-SS XLARGE",
			"PRI ORI_POTATO_06X-SS XXLARGE",
			"PRO POT_POTATO_04X-SS LARGE",
			"RKS POT_POTATO_01X-SS XSMALL",
			"RKS POT_POTATO_02X-SS SMALL",
			"RKS POT_POTATO_04X-SS LARGE",
			"RKS POT_POTATO_05X-SS XLARGE",
			"RKS POT_POTATO_06X-SS XXLARGE",
			"SRL RUF_POTATO_01X-SS XSMALL",
			"SRL RUF_POTATO_02X-SS SMALL",
			"SRL RUF_POTATO_03X-SS MEDIUM",
			"SRL RUF_POTATO_04X-SS LARGE",
			"SLI POT_POTATO_04X-SS LARGE",
			"TRR POT_POTATO_02X-SS SMALL",
			"TRR POT_POTATO_03X-SS MEDIUM",
			"TRR POT_POTATO_04X-SS LARGE",
			"TRR POT_POTATO_05X-SS XLARGE",
			"YZB POT_POTATO_01X-SS XSMALL",
			"YZB POT_POTATO_02X-SS SMALL",
			"YZB POT_POTATO_03X-SS MEDIUM",
			"YZB POT_POTATO_05X-SS XLARGE",
		],
		variable1: "Units",
		somMeasure: "Category",
		somGroup: "Sales",
		apiUnits: "Units",
		date: "10/01/2023-10/31/2023",
	},
	{
		country: "COLOMBIA",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL COLOMBIA-DTS"],
		channel: ["DTS"],
		periodView: ["na"],
		vendor: ["PEPSICO"],
		category: ["SAV-SALTY"],
		segment: ["SAV-POTATO"],
		brand: ["MARGARITA FOSFORITOS", "MARGARITA KETTEL", "MARGARITA REGULAR", "MARGARITA SENSATIONS", "MARGARITA WAVY", "STAX REGULAR"],
		subBrand: ["MARGARITA FOSFORITOS", "MARGARITA KETTEL", "MARGARITA REGULAR", "MARGARITA SENSATIONS", "MARGARITA WAVY", "STAX REGULAR"],
		permutation: [
			"MAR FOS_POTATO_04X-SMALL",
			"MAR FOS_POTATO_11X-BIG",
			"MAR KTL_POTATO_04X-SMALL",
			"MAR KTL_POTATO_11X-BIG",
			"MAR KTL_POTATO_12X-EXTRA BIG",
			"MAR REG_POTATO_01X-MINI",
			"MAR REG_POTATO_04X-SMALL",
			"MAR REG_POTATO_07X-MEDIUM",
			"MAR REG_POTATO_11X-BIG",
			"MAR REG_POTATO_12X-EXTRA BIG",
			"MAR SEN_POTATO_11X-BIG",
			"MAR SEN_POTATO_12X-EXTRA BIG",
			"MAR WAV_POTATO_04X-SMALL",
			"MAR WAV_POTATO_07X-MEDIUM",
			"MAR WAV_POTATO_11X-BIG",
			"STX REG_POTATO_11X-BIG",
		],
		anchorVendor: [
			"BIMBO",
			"CIA NAL CHOCOLATES",
			"COLOMBINA SA",
			"COMESTIBLES RICOS",
			"DIPSA",
			"FRITO MIX",
			"KELLOGGS",
			"LA VICTORIA",
			"MANITOBA",
			"MARS",
			"MEKATO",
			"OTHER VENDOR",
			"PEPSICO",
			"PRIVATE LABEL",
			"PRODUCTOS RAMO",
			"QUALA",
			"VECTOR FOODS",
			"YUPI",
		],
		packSize: ["01X-MINI", "04X-SMALL", "07X-MEDIUM", "11X-BIG", "12X-EXTRA BIG"],
		anchorCategory: ["SAV-SALTY"],
		anchorSegment: ["SAV-POTATO"],
		anchorBrand: [
			"FRITO MIX LISSITAZ",
			"FRITO MIX MEGA ONDITAS",
			"FRITO MIX POTATO",
			"FRITO MIX POTATO MEGAFRITOS",
			"FRITO MIX RIZZZ",
			"FRITO MIX RULASS",
			"FRITO MIX RULITASS",
			"FRITO MIX RULIZZ",
			"LA VICTORIA POTATO",
			"LA VICTORIA WAVY",
			"MEKATO REGULAR",
			"MONTEROJO POTATO",
			"OTHER SUBBRAND POTATO",
			"PRINGLES REGULAR",
			"PRIVATE LABEL POTATO",
			"RAMO CASERITAS",
			"SUPER RICAS POTATO",
			"YUPI POTATO",
			"YUPI RIZADAS",
		],
		anchorSubBrand: [
			"FRITO MIX LISSITAZ",
			"FRITO MIX MEGA ONDITAS",
			"FRITO MIX POTATO",
			"FRITO MIX POTATO MEGAFRITOS",
			"FRITO MIX RIZZZ",
			"FRITO MIX RULASS",
			"FRITO MIX RULITASS",
			"FRITO MIX RULIZZ",
			"LA VICTORIA POTATO",
			"LA VICTORIA WAVY",
			"MEKATO REGULAR",
			"MONTEROJO POTATO",
			"OTHER SUBBRAND POTATO",
			"PRINGLES REGULAR",
			"PRIVATE LABEL POTATO",
			"RAMO CASERITAS",
			"SUPER RICAS POTATO",
			"YUPI KETTLE",
			"YUPI POTATO",
			"YUPI WAVY",
		],
		anchorPackSize: ["01X-MINI", "04X-SMALL", "07X-MEDIUM", "11X-BIG", "12X-EXTRA BIG"],
		anchorPermutation: [
			"FRT LIS_POTATO_01X-MINI",
			"FRT OND_POTATO_01X-MINI",
			"FRT POT_POTATO_01X-MINI",
			"FRT POT_POTATO_04X-SMALL",
			"FRT POT_POTATO_11X-BIG",
			"FRT MFR_POTATO_01X-MINI",
			"FRT RIZ_POTATO_04X-SMALL",
			"FRT RLS_POTATO_01X-MINI",
			"FRT RUL_POTATO_01X-MINI",
			"FRT RLZ_POTATO_04X-SMALL",
			"LVC POT_POTATO_01X-MINI",
			"LVC POT_POTATO_04X-SMALL",
			"LVC POT_POTATO_11X-BIG",
			"LVC POT_POTATO_12X-EXTRA BIG",
			"LVC POTW_POTATO_04X-SMALL",
			"LVC POTW_POTATO_07X-MEDIUM",
			"LVC POTW_POTATO_11X-BIG",
			"MEK REG_POTATO_01X-MINI",
			"MEK REG_POTATO_04X-SMALL",
			"MEK REG_POTATO_11X-BIG",
			"MNT POT_POTATO_01X-MINI",
			"MNT POT_POTATO_04X-SMALL",
			"MNT POT_POTATO_11X-BIG",
			"OTH POT_POTATO_01X-MINI",
			"OTH POT_POTATO_04X-SMALL",
			"OTH POT_POTATO_07X-MEDIUM",
			"OTH POT_POTATO_11X-BIG",
			"OTH POT_POTATO_12X-EXTRA BIG",
			"PRI REG_POTATO_01X-MINI",
			"PRI REG_POTATO_04X-SMALL",
			"PRI REG_POTATO_07X-MEDIUM",
			"PRI REG_POTATO_11X-BIG",
			"PRI REG_POTATO_12X-EXTRA BIG",
			"PV POT_POTATO_01X-MINI",
			"PV POT_POTATO_04X-SMALL",
			"PV POT_POTATO_11X-BIG",
			"PV POT_POTATO_12X-EXTRA BIG",
			"RMO CAS_POTATO_01X-MINI",
			"RMO CAS_POTATO_04X-SMALL",
			"RMO CAS_POTATO_11X-BIG",
			"SPI POT_POTATO_01X-MINI",
			"SPI POT_POTATO_04X-SMALL",
			"SPI POT_POTATO_11X-BIG",
			"SPI POT_POTATO_12X-EXTRA BIG",
			"YUP POT_POTATO_04X-SMALL",
			"YUP POT_POTATO_11X-BIG",
			"YUP POT_POTATO_01X-MINI",
			"YUP POT_POTATO_04X-SMALL",
			"YUP POT_POTATO_07X-MEDIUM",
			"YUP POT_POTATO_11X-BIG",
			"YUP POT_POTATO_12X-EXTRA BIG",
			"YUP RZD_POTATO_01X-MINI",
			"YUP RZD_POTATO_04X-SMALL",
			"YUP RZD_POTATO_11X-BIG",
			"YUP RZD_POTATO_12X-EXTRA BIG",
		],
		variable1: "Volume",
		somMeasure: "Category",
		somGroup: "Sales",
		apiUnits: "Volume",
		date: "10/01/2023-10/31/2023",
	},
	{
		country: "CHILE",
		geoLevel: ["COUNTRY-SUBCHAN"],
		geoLevel2: ["TTL CHILE-SMKT"],
		channel: ["OT"],
		periodView: ["na"],
		vendor: ["PEPSICO"],
		category: ["SAV-SALTY"],
		segment: ["SAV-POTATO"],
		brand: [
			"EVERCRISP POTATO",
			"LAYS ARTESANAS",
			"LAYS CLASSIC",
			"LAYS FLAVOURS",
			"LAYS HILO",
			"LAYS JUNTEMONOS",
			"LAYS MAXX",
			"LAYS MEDITERRANEAS",
			"LAYS SENSATIONS",
			"LAYS WAVY",
			"MOMS CASERAS",
			"RAICES CHILENAS",
			"RUFFLES ORIGINAL",
			"STAX REGULAR",
		],
		subBrand: [
			"EVERCRISP POTATO",
			"LAYS ARTESANAS",
			"LAYS CLASSIC",
			"LAYS FLAVOURS",
			"LAYS HILO",
			"LAYS JUNTEMONOS",
			"LAYS MAXX",
			"LAYS MEDITERRANEAS",
			"LAYS SENSATIONS",
			"LAYS WAVY",
			"MOMS CASERAS",
			"RAICES CHILENAS",
			"RUFFLES ORIGINAL",
			"STAX REGULAR",
		],
		packSize: [
			"01X-MINI",
			"01X-SS XSMALL",
			"02P-MULTIPK",
			"02X-JR LINE",
			"04X-SS LARGE",
			"05P-MULTIPK",
			"07X-MAX",
			"07X-MEDIUM",
			"09X-GRANDE",
			"09X-JUMBO",
			"10X-MANGA",
			"11X-BIG",
			"12X-EXTRA BIG",
			"20P-MULTIPK",
		],
		permutation: [
			"EVE POT_POTATO_02P-MULTIPK",
			"EVE POT_POTATO_09X-GRANDE",
			"LAY ART_POTATO_01X-SS XSMALL",
			"LAY ART_POTATO_04X-SS LARGE",
			"LAY ART_POTATO_07X-MAX",
			"LAY ART_POTATO_07X-MEDIUM",
			"LAY ART_POTATO_09X-JUMBO",
			"LAY CLA_POTATO_01X-SS XSMALL",
			"LAY CLA_POTATO_02X-JR LINE",
			"LAY CLA_POTATO_04X-SS LARGE",
			"LAY CLA_POTATO_07X-MAX",
			"LAY CLA_POTATO_07X-MEDIUM",
			"LAY CLA_POTATO_09X-GRANDE",
			"LAY CLA_POTATO_10X-MANGA",
			"LAY CLA_POTATO_11X-BIG",
			"LAY FLV_POTATO_01X-SS XSMALL",
			"LAY FLV_POTATO_02X-JR LINE",
			"LAY FLV_POTATO_04X-SS LARGE",
			"LAY FLV_POTATO_07X-MAX",
			"LAY FLV_POTATO_07X-MEDIUM",
			"LAY FLV_POTATO_09X-GRANDE",
			"LAY FLV_POTATO_11X-BIG",
			"LAY HIL_POTATO_09X-JUMBO",
			"LYS JNT_POTATO_05P-MULTIPK",
			"LAY MAX_POTATO_01X-SS XSMALL",
			"LAY MAX_POTATO_07X-MAX",
			"LAY MAX_POTATO_07X-MEDIUM",
			"LAY MED_POTATO_01X-SS XSMALL",
			"LAY MED_POTATO_02X-JR LINE",
			"LAY MED_POTATO_07X-MAX",
			"LAY MED_POTATO_07X-MEDIUM",
			"LAY MED_POTATO_09X-GRANDE",
			"LAY MED_POTATO_09X-JUMBO",
			"LAY MED_POTATO_10X-MANGA",
			"LAY MED_POTATO_11X-BIG",
			"LAY SEN_POTATO_07X-MAX",
			"LAY WAV_POTATO_01X-MINI",
			"LAY WAV_POTATO_01X-SS XSMALL",
			"LAY WAV_POTATO_02P-MULTIPK",
			"LAY WAV_POTATO_02X-JR LINE",
			"LAY WAV_POTATO_04X-SS LARGE",
			"LAY WAV_POTATO_07X-MAX",
			"LAY WAV_POTATO_07X-MEDIUM",
			"LAY WAV_POTATO_09X-GRANDE",
			"LAY WAV_POTATO_09X-JUMBO",
			"LAY WAV_POTATO_10X-MANGA",
			"LAY WAV_POTATO_11X-BIG",
			"LAY WAV_POTATO_12X-EXTRA BIG",
			"LAY WAV_POTATO_20P-MULTIPK",
			"MOM CAS_POTATO_01X-MINI",
			"MOM CAS_POTATO_04X-SS LARGE",
			"MOM CAS_POTATO_07X-MAX",
			"MOM CAS_POTATO_07X-MEDIUM",
			"MOM CAS_POTATO_09X-GRANDE",
			"MOM CAS_POTATO_09X-JUMBO",
			"MOM CAS_POTATO_10X-MANGA",
			"MOM CAS_POTATO_11X-BIG",
			"MOM CAS_POTATO_12X-EXTRA BIG",
			"RAC CHI_POTATO_07X-MAX",
			"RAC CHI_POTATO_09X-JUMBO",
			"RUF ORI_POTATO_07X-MEDIUM",
			"STX REG_POTATO_01X-SS XSMALL",
			"STX REG_POTATO_02P-MULTIPK",
			"STX REG_POTATO_07X-MEDIUM",
			"STX REG_POTATO_10X-MANGA",
			"STX REG_POTATO_20P-MULTIPK",
		],
		anchorVendor: [
			"BARCEL",
			"CAFE DO BRASIL",
			"CARLOS ORTIZ",
			"COMERCIAL BUENA MESA",
			"DE LA ROSA",
			"FRUTISA",
			"GRUPO NUTRESA",
			"KELLOGGS",
			"MILLANTU",
			"OTHER VENDOR",
			"PAILLAPEN",
			"PANCHO VILLA",
			"PEPSICO",
			"PRIMOR",
			"PRIVATE LABEL",
			"TRENDY",
			"VELARDE",
			"ZE FARMS",
		],
		anchorCategory: ["SAV-SALTY"],
		anchorSegment: ["SAV-POTATO"],
		anchorBrand: ["KRYZPO REGULAR", "PANCHO VILLA PAPAS", "PRINGLES REGULAR"],
		anchorSubBrand: ["KRYZPO REGULAR", "PANCHO VILLA PAPAS", "PRINGLES REGULAR"],
		anchorPackSize: [
			"01X-SS XSMALL",
			"02P-MULTIPK",
			"02X-JR LINE",
			"04P-MULTIPK",
			"04X-SS LARGE",
			"06P-MULTIPK",
			"07X-MAX",
			"07X-MEDIUM",
			"09X-JUMBO",
			"10X-MANGA",
			"12X-EXTRA BIG",
		],
		anchorPermutation: [
			"KRY REG_POTATO_01X-SS XSMALL",
			"KRY REG_POTATO_02P-MULTIPK",
			"KRY REG_POTATO_02X-JR LINE",
			"KRY REG_POTATO_04P-MULTIPK",
			"KRY REG_POTATO_04X-SS LARGE",
			"KRY REG_POTATO_06P-MULTIPK",
			"KRY REG_POTATO_07X-MAX",
			"KRY REG_POTATO_07X-MEDIUM",
			"KRY REG_POTATO_09X-JUMBO",
			"KRY REG_POTATO_10X-MANGA",
			"KRY REG_POTATO_12X-EXTRA BIG",
			"PAV PAP_POTATO_07X-MEDIUM",
			"PRI REG_POTATO_04X-SS LARGE",
			"PRI REG_POTATO_07X-MAX",
			"PRI REG_POTATO_07X-MEDIUM",
		],
		variable1: "SOM",
		somMeasure: "Category",
		somGroup: "Units",
		apiUnits: "Volume",
		date: "10/01/2023-10/31/2023",
	},
	{
		country: "ARGENTINA",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL ARGENTINA-OT"],
		channel: ["OT"],
		periodView: ["na"],
		vendor: ["PEPSICO"],
		category: ["SAV-SALTY"],
		segment: ["SAV-POTATO"],
		brand: [
			"BUN PAPAS",
			"DORITOS QUESO",
			"LAYS ACANALADAS",
			"LAYS BAKED",
			"LAYS CLASSIC",
			"LAYS FLAVOURS",
			"LAYS GOURMET",
			"LAYS OTHER SUBBRAND",
			"LAYS PAY",
			"LAYS SIN SAL",
			"LAYS STAX",
			"PEHUAMAR ACANALADAS",
			"PEHUAMAR CLASICAS",
			"PEP PALITOS",
		],
		subBrand: [
			"BUN PAPAS",
			"DORITOS QUESO",
			"LAYS ACANALADAS",
			"LAYS BAKED",
			"LAYS CLASSIC",
			"LAYS FLAVOURS",
			"LAYS GOURMET",
			"LAYS OTHER SUBBRAND",
			"LAYS PAY",
			"LAYS SIN SAL",
			"LAYS STAX",
			"PEHUAMAR ACANALADAS",
			"PEHUAMAR CLASICAS",
			"PEP PALITOS",
		],
		packSize: [
			"01X-MINI",
			"01X-SS XSMALL",
			"02X-JR LINE",
			"04X-SS LARGE",
			"07X-MAX",
			"07X-MEDIUM",
			"09X-GRANDE",
			"09X-JUMBO",
			"11X-BIG",
			"12X-EXTRA BIG",
			"15X-MEGA",
			"16X-SUPER SIZE",
		],
		permutation: [
			"BUN PAP_POTATO_04X-SS LARGE",
			"BUN PAP_POTATO_07X-MAX",
			"BUN PAP_POTATO_07X-MEDIUM",
			"BUN PAP_POTATO_09X-JUMBO",
			"DOR QUE_POTATO_07X-MEDIUM",
			"LAY ACA_POTATO_01X-SS XSMALL",
			"LAY ACA_POTATO_02X-JR LINE",
			"LAY ACA_POTATO_07X-MAX",
			"LAY ACA_POTATO_07X-MEDIUM",
			"LAY ACA_POTATO_09X-GRANDE",
			"LAY BKD_POTATO_01X-SS XSMALL",
			"LAY BKD_POTATO_02X-JR LINE",
			"LAY BKD_POTATO_07X-MEDIUM",
			"LAY CLA_POTATO_01X-MINI",
			"LAY CLA_POTATO_01X-SS XSMALL",
			"LAY CLA_POTATO_02X-JR LINE",
			"LAY CLA_POTATO_04X-SS LARGE",
			"LAY CLA_POTATO_07X-MAX",
			"LAY CLA_POTATO_07X-MEDIUM",
			"LAY CLA_POTATO_09X-GRANDE",
			"LAY CLA_POTATO_09X-JUMBO",
			"LAY CLA_POTATO_11X-BIG",
			"LAY CLA_POTATO_12X-EXTRA BIG",
			"LAY FLV_POTATO_01X-SS XSMALL",
			"LAY FLV_POTATO_02X-JR LINE",
			"LAY FLV_POTATO_04X-SS LARGE",
			"LAY FLV_POTATO_07X-MAX",
			"LAY FLV_POTATO_07X-MEDIUM",
			"LAY FLV_POTATO_11X-BIG",
			"LAY GOU_POTATO_01X-SS XSMALL",
			"LAY GOU_POTATO_02X-JR LINE",
			"LAY GOU_POTATO_04X-SS LARGE",
			"LAY GOU_POTATO_07X-MEDIUM",
			"LAY OTH_POTATO_01X-MINI",
			"LAY OTH_POTATO_01X-SS XSMALL",
			"LAY OTH_POTATO_02X-JR LINE",
			"LAY OTH_POTATO_07X-MAX",
			"LAY OTH_POTATO_07X-MEDIUM",
			"LAY OTH_POTATO_11X-BIG",
			"LAY OTH_POTATO_15X-MEGA",
			"LAY PAY_POTATO_01X-SS XSMALL",
			"LAY PAY_POTATO_07X-MEDIUM",
			"LAY SSL_POTATO_04X-SS LARGE",
			"LAY STX_POTATO_01X-SS XSMALL",
			"LAY STX_POTATO_07X-MAX",
			"LAY STX_POTATO_07X-MEDIUM",
			"PEH ACA_POTATO_01X-SS XSMALL",
			"PEH ACA_POTATO_02X-JR LINE",
			"PEH ACA_POTATO_04X-SS LARGE",
			"PEH ACA_POTATO_07X-MAX",
			"PEH ACA_POTATO_07X-MEDIUM",
			"PEH ACA_POTATO_09X-GRANDE",
			"PEH ACA_POTATO_09X-JUMBO",
			"PEH ACA_POTATO_11X-BIG",
			"PEH ACA_POTATO_12X-EXTRA BIG",
			"PEH CLA_POTATO_01X-SS XSMALL",
			"PEH CLA_POTATO_02X-JR LINE",
			"PEH CLA_POTATO_04X-SS LARGE",
			"PEH CLA_POTATO_07X-MAX",
			"PEH CLA_POTATO_07X-MEDIUM",
			"PEH CLA_POTATO_09X-GRANDE",
			"PEH CLA_POTATO_09X-JUMBO",
			"PEH CLA_POTATO_11X-BIG",
			"PEH CLA_POTATO_12X-EXTRA BIG",
			"PEH CLA_POTATO_15X-MEGA",
			"PEH CLA_POTATO_16X-SUPER SIZE",
			"PEP PAL_POTATO_07X-MEDIUM",
		],
		anchorVendor: [
			"5 HISPANOS",
			"ARCOR",
			"BAGLEY",
			"CEREALKO",
			"CHIL",
			"COTO",
			"CROOPERS",
			"DIA",
			"KELLOGGS",
			"MONDELEZ",
			"NABISCO",
			"NIKITOS",
			"OTHER VENDOR",
			"PEPSICO",
			"PRIVATE LABEL",
			"PRODEMAN",
			"SEMIX",
			"TIA MARUCA",
		],
		anchorCategory: ["SAV-SALTY"],
		anchorSegment: ["SAV-POTATO"],
		anchorBrand: [
			"ANTOJITOS POTATO",
			"BACHILITOS POTATO",
			"BATATAS REGULAR",
			"BIG CHEESE REGULAR",
			"BUBY REGULAR",
			"CHIL PAPAS FRITAS",
			"CRISKEY POTATO",
			"GAUCHITAS REGULAR",
			"GONZALO POTATO",
			"GOOD SHOW POTATO",
			"HITS REGULAR",
			"KRACHITOS POTATO",
			"KRISKI POTATO",
			"KROKANTES POTATO",
			"LLUVIA REGULAR",
			"MARISA REGULAR",
			"MORAN POTATO",
			"NIKITOS POTATO",
			"OASIS POTATO",
			"OTHER SUBBRAND POTATO",
			"PEDRIN REGULAR",
			"PRINGLES REGULAR",
			"PRIVATE LABEL POTATO",
			"QUENTO REGULAR",
			"SALADIX CRAX",
			"SALADIX CROSS",
			"SALADIX HULA HULA",
			"SALADIX PICANTO",
			"SALADIX POTATO",
			"SER SNACKS REGULAR",
			"TIA MARUCA REGULAR",
			"TOTAL O CORP",
			"ZYMMA POTATO",
		],
		anchorSubBrand: [
			"ANTOJITOS POTATO",
			"BACHILITOS POTATO",
			"BATATAS REGULAR",
			"BIG CHEESE REGULAR",
			"BUBY REGULAR",
			"CHIL PAPAS FRITAS",
			"CRISKEY POTATO",
			"GAUCHITAS REGULAR",
			"GONZALO POTATO",
			"GOOD SHOW POTATO",
			"HITS REGULAR",
			"KRACHITOS POTATO",
			"KRISKI POTATO",
			"KROKANTES POTATO",
			"LLUVIA REGULAR",
			"MARISA REGULAR",
			"MORAN POTATO",
			"NIKITOS POTATO",
			"OASIS POTATO",
			"OTHER SUBBRAND POTATO",
			"PEDRIN REGULAR",
			"PRINGLES REGULAR",
			"PRIVATE LABEL POTATO",
			"QUENTO REGULAR",
			"SALADIX CRAX",
			"SALADIX CROSS",
			"SALADIX HULA HULA",
			"SALADIX PICANTO",
			"SALADIX POTATO",
			"SER SNACKS REGULAR",
			"TIA MARUCA REGULAR",
			"TOTAL O CORP",
			"ZYMMA POTATO",
		],
		anchorPackSize: [
			"01X-MINI",
			"01X-SS XSMALL",
			"02X-JR LINE",
			"04X-SS LARGE",
			"07X-MAX",
			"07X-MEDIUM",
			"09X-GRANDE",
			"09X-JUMBO",
			"11X-BIG",
			"12X-EXTRA BIG",
			"15X-MEGA",
			"16X-SUPER SIZE",
		],
		anchorPermutation: [
			"AJT POT_POTATO_04X-SS LARGE",
			"BAC POT_POTATO_02X-JR LINE",
			"BAC POT_POTATO_07X-MAX",
			"BAT REG_POTATO_01X-SS XSMALL",
			"BHS REG_POTATO_02X-JR LINE",
			"BUB REG_POTATO_09X-JUMBO",
			"CHP FTS_POTATO_02X-JR LINE",
			"CHP FTS_POTATO_09X-GRANDE",
			"CKY POT_POTATO_02X-JR LINE",
			"GAU REG_POTATO_01X-SS XSMALL",
			"GAU REG_POTATO_02X-JR LINE",
			"GAU REG_POTATO_07X-MEDIUM",
			"GZL POT_POTATO_07X-MEDIUM",
			"GZL POT_POTATO_09X-GRANDE",
			"GSH POT_POTATO_01X-MINI",
			"GSH POT_POTATO_01X-SS XSMALL",
			"GSH POT_POTATO_02X-JR LINE",
			"GSH POT_POTATO_07X-MAX",
			"GSH POT_POTATO_07X-MEDIUM",
			"GSH POT_POTATO_09X-JUMBO",
			"GSH POT_POTATO_12X-EXTRA BIG",
			"GSH POT_POTATO_16X-SUPER SIZE",
			"HTS REG_POTATO_07X-MAX",
			"HTS REG_POTATO_07X-MEDIUM",
			"KCH POT_POTATO_01X-MINI",
			"KCH POT_POTATO_01X-SS XSMALL",
			"KCH POT_POTATO_02X-JR LINE",
			"KCH POT_POTATO_04X-SS LARGE",
			"KCH POT_POTATO_07X-MAX",
			"KCH POT_POTATO_07X-MEDIUM",
			"KCH POT_POTATO_09X-GRANDE",
			"KCH POT_POTATO_09X-JUMBO",
			"KCH POT_POTATO_11X-BIG",
			"KCH POT_POTATO_12X-EXTRA BIG",
			"KCH POT_POTATO_15X-MEGA",
			"KSK POT_POTATO_16X-SUPER SIZE",
			"KKT POR_POTATO_07X-MEDIUM",
			"LLV REG_POTATO_09X-GRANDE",
			"LLV REG_POTATO_16X-SUPER SIZE",
			"MAI REG_POTATO_01X-SS XSMALL",
			"MAI REG_POTATO_07X-MEDIUM",
			"MAI REG_POTATO_09X-GRANDE",
			"MOA POT_POTATO_07X-MEDIUM",
			"NIK POT_POTATO_01X-MINI",
			"NIK POT_POTATO_01X-SS XSMALL",
			"NIK POT_POTATO_02X-JR LINE",
			"NIK POT_POTATO_07X-MEDIUM",
			"NIK POT_POTATO_09X-GRANDE",
			"NIK POT_POTATO_11X-BIG",
			"NIK POT_POTATO_12X-EXTRA BIG",
			"NIK POT_POTATO_15X-MEGA",
			"NIK POT_POTATO_16X-SUPER SIZE",
			"OAS POT_POTATO_07X-MEDIUM",
			"OAS POT_POTATO_09X-GRANDE",
			"OAS POT_POTATO_16X-SUPER SIZE",
			"OTH POT_POTATO_01X-MINI",
			"OTH POT_POTATO_01X-SS XSMALL",
			"OTH POT_POTATO_02X-JR LINE",
			"OTH POT_POTATO_04X-SS LARGE",
			"OTH POT_POTATO_07X-MAX",
			"OTH POT_POTATO_07X-MEDIUM",
			"OTH POT_POTATO_09X-GRANDE",
			"OTH POT_POTATO_09X-JUMBO",
			"OTH POT_POTATO_11X-BIG",
			"OTH POT_POTATO_12X-EXTRA BIG",
			"OTH POT_POTATO_15X-MEGA",
			"OTH POT_POTATO_16X-SUPER SIZE",
			"PED REG_POTATO_01X-MINI",
			"PED REG_POTATO_01X-SS XSMALL",
			"PED REG_POTATO_02X-JR LINE",
			"PED REG_POTATO_07X-MAX",
			"PED REG_POTATO_07X-MEDIUM",
			"PED REG_POTATO_09X-GRANDE",
			"PED REG_POTATO_12X-EXTRA BIG",
			"PED REG_POTATO_16X-SUPER SIZE",
			"PRI REG_POTATO_01X-SS XSMALL",
			"PRI REG_POTATO_02X-JR LINE",
			"PRI REG_POTATO_07X-MAX",
			"PRI REG_POTATO_07X-MEDIUM",
			"PV POT_POTATO_01X-MINI",
			"PV POT_POTATO_01X-SS XSMALL",
			"PV POT_POTATO_02X-JR LINE",
			"PV POT_POTATO_04X-SS LARGE",
			"PV POT_POTATO_07X-MAX",
			"PV POT_POTATO_07X-MEDIUM",
			"PV POT_POTATO_09X-JUMBO",
			"PV POT_POTATO_12X-EXTRA BIG",
			"PV POT_POTATO_16X-SUPER SIZE",
			"QTO REG_POTATO_01X-SS XSMALL",
			"QTO REG_POTATO_07X-MAX",
			"QTO REG_POTATO_07X-MEDIUM",
			"SDX CRA_POTATO_01X-SS XSMALL",
			"SDX CRA_POTATO_07X-MEDIUM",
			"SDX CRO_POTATO_01X-MINI",
			"SDX CRO_POTATO_01X-SS XSMALL",
			"SDX CRO_POTATO_02X-JR LINE",
			"SDX CRO_POTATO_07X-MEDIUM",
			"SDX HUL_POTATO_01X-SS XSMALL",
			"SDX HUL_POTATO_04X-SS LARGE",
			"SDX HUL_POTATO_07X-MAX",
			"SDX PIC_POTATO_01X-SS XSMALL",
			"SDX PIC_POTATO_02X-JR LINE",
			"SDX PIC_POTATO_07X-MEDIUM",
			"SDX POT_POTATO_01X-SS XSMALL",
			"SDX POT_POTATO_02X-JR LINE",
			"SDX POT_POTATO_07X-MAX",
			"SDX POT_POTATO_07X-MEDIUM",
			"SSS REG_POTATO_01X-MINI",
			"SSS REG_POTATO_01X-SS XSMALL",
			"TMR REG_POTATO_01X-SS XSMALL",
			"TMR REG_POTATO_02X-JR LINE",
			"TMR REG_POTATO_07X-MEDIUM",
			"TMR REG_POTATO_09X-JUMBO",
			"TTL OCP_POTATO_02X-JR LINE",
			"ZYM POT_POTATO_01X-SS XSMALL",
		],
		variable1: "Volume",
		somMeasure: "Category",
		somGroup: "Sales",
		apiUnits: "Volume",
		date: "10/01/2023-10/31/2023",
	},
	{
		country: "BRAZIL",
		geoLevel: ["COUNTRY-TTL"],
		geoLevel2: ["TTL BRAZIL-ALL"],
		channel: ["ALL OUTLET"],
		periodView: ["na"],
		vendor: ["PEPSICO"],
		category: ["SAV-SALTY"],
		segment: ["SAV-POTATO"],
		brand: [
			"ELMA CHIPS STAX",
			"LAYS",
			"LAYS REGULAR",
			"RUFFLES",
			"RUFFLES REGULAR",
			"RUFFLES STAX",
			"SENSACOES",
			"SENSACOES ELMA CHIPS",
			"SENSATIONS",
			"STAX",
			"STAX REGULAR",
		],
		subBrand: [
			"ELMA CHIPS STAX",
			"LAYS",
			"LAYS REGULAR",
			"RUFFLES",
			"RUFFLES REGULAR",
			"RUFFLES STAX",
			"SENSACOES",
			"SENSACOES ELMA CHIPS",
			"SENSATIONS",
			"STAX",
			"STAX REGULAR",
		],
		packSize: [
			"04X-SS LARGE",
			"05P-MULTIPK",
			"07X-MEDIUM",
			"11X-BIG",
			"12X-EXTRA BIG",
			"14X-FAMILY",
			"15P-MULTIPK",
			"HEAVY USER/PRA FESTA",
			"PRA GALERA ENTR. (101G-120G)",
			"PRA GALERA ENTR. (95G-150G)",
			"PRA GALERA REGULAR",
			"PRA GENTE",
			"PRA J",
			"PRA MIM",
		],
		permutation: [
			"ELM STX_POTATO_PRA GALERA REGULAR",
			"LAYPEP_POTATO_PRA GALERA ENTR. (101G-120G)",
			"LAYPEP_POTATO_PRA GALERA ENTR. (95G-150G)",
			"LAYPEP_POTATO_PRA GALERA REGULAR",
			"LAYPEP_POTATO_PRA GENTE",
			"LAYPEP_POTATO_PRA J",
			"LAYPEP_POTATO_PRA MIM",
			"LAY REG_POTATO_04X-SS LARGE",
			"LAY REG_POTATO_07X-MEDIUM",
			"LAY REG_POTATO_11X-BIG",
			"LAY REG_POTATO_14X-FAMILY",
			"LAY REG_POTATO_PRA GALERA ENTR. (101G-120G)",
			"LAY REG_POTATO_PRA GALERA REGULAR",
			"LAY REG_POTATO_PRA GENTE",
			"LAY REG_POTATO_PRA J",
			"LAY REG_POTATO_PRA MIM",
			"RFL PEP_POTATO_HEAVY USER/PRA FESTA",
			"RFL PEP_POTATO_PRA GALERA ENTR. (101G-120G)",
			"RFL PEP_POTATO_PRA GALERA REGULAR",
			"RFL PEP_POTATO_PRA GENTE",
			"RFL PEP_POTATO_PRA J",
			"RFL PEP_POTATO_PRA MIM",
			"RUF REG_POTATO_15P-MULTIPK",
			"RUF REG_POTATO_HEAVY USER/PRA FESTA",
			"RUF REG_POTATO_PRA GALERA ENTR. (101G-120G)",
			"RUF REG_POTATO_PRA GALERA REGULAR",
			"RUF REG_POTATO_PRA GENTE",
			"RUF REG_POTATO_PRA J",
			"RUF REG_POTATO_PRA MIM",
			"RFL STX_POTATO_PRA GALERA REGULAR",
			"RFL STX_POTATO_PRA GENTE",
			"SNO ECH_POTATO_05P-MULTIPK",
			"SNO ECH_POTATO_PRA GALERA REGULAR",
			"SNO ECH_POTATO_PRA GENTE",
			"SNO ECH_POTATO_PRA MIM",
			"SON PEP_POTATO_PRA GALERA REGULAR",
			"SON PEP_POTATO_PRA GENTE",
			"SON PEP_POTATO_PRA MIM",
			"SNS PEP_POTATO_PRA GALERA REGULAR",
			"SNS PEP_POTATO_PRA MIM",
			"STX PEP_POTATO_12X-EXTRA BIG",
			"STX PEP_POTATO_PRA GALERA REGULAR",
			"STX REG_POTATO_PRA GALERA REGULAR",
		],
		anchorVendor: [
			"AGTAL",
			"ANCHIETA",
			"APTI ALIM.",
			"CARAMURU",
			"DORI",
			"ENOVA",
			"GENERAL MILLS",
			"GULOZITOS",
			"KELLOGGS",
			"LIOTECNICA",
			"MAE TERRA",
			"MARATA",
			"MARCA PROPRIA",
			"MONDELEZ",
			"NESTLE",
			"OTHER VENDOR",
			"PEPSICO",
			"SAO BRAZ",
		],
		anchorCategory: ["SAV-SALTY"],
		anchorSegment: ["SAV-POTATO"],
		anchorBrand: ["NA MESA", "OTHER POTATO SUBBRAND", "PRINGLES", "SINHA REGULAR"],
		anchorSubBrand: ["NA MESA", "OTHER POTATO SUBBRAND", "PRINGLES", "SINHA REGULAR"],
		anchorPackSize: [
			"10P-MULTIPK",
			"HEAVY USER/PRA FESTA",
			"PRA GALERA ENTR. (101G-120G)",
			"PRA GALERA REGULAR",
			"PRA GENTE",
			"PRA J",
			"PRA MIM",
			"REGULAR",
			"RESTOCK",
			"UPSIZE",
		],
		anchorPermutation: [
			"NAM CHP_POTATO_HEAVY USER/PRA FESTA",
			"NAM CHP_POTATO_PRA GENTE",
			"NAM CHP_POTATO_REGULAR",
			"NAM CHP_POTATO_RESTOCK",
			"NAM CHP_POTATO_UPSIZE",
			"OHS PTO_POTATO_10P-MULTIPK",
			"OHS PTO_POTATO_HEAVY USER/PRA FESTA",
			"OHS PTO_POTATO_PRA GALERA ENTR. (101G-120G)",
			"OHS PTO_POTATO_PRA GALERA REGULAR",
			"OHS PTO_POTATO_PRA GENTE",
			"OHS PTO_POTATO_PRA J",
			"OHS PTO_POTATO_PRA MIM",
			"OHS PTO_POTATO_UPSIZE",
			"PRN REG_POTATO_PRA GALERA ENTR. (101G-120G)",
			"PRN REG_POTATO_PRA GALERA REGULAR",
			"PRN REG_POTATO_PRA GENTE",
			"PRN REG_POTATO_PRA MIM",
			"SIN REG_POTATO_PRA GALERA REGULAR",
			"SIN REG_POTATO_PRA GENTE",
			"SIN REG_POTATO_PRA MIM",
		],
		variable1: "Volume",
		somMeasure: "Category",
		somGroup: "Sales",
		apiUnits: "Volume",
		date: "06/01/2023-12/31/2023",
	},
];

const pepsiFilterOrder = [
	{
		key: "category",
		order: 1,
	},
	{
		key: "segment",
		order: 2,
	},
	{
		key: "brand",
		order: 3,
	},
	{
		key: "subBrand",
		order: 4,
	},
	{
		key: "packSize",
		order: 5,
	},
	{
		key: "permutation",
		order: 6,
	},
];

const dataObject = {
	primaryFilter,
	productFilter,
	anchorFilter,
	measureFilter,
	APISimulation,
	realESTVolumeTable,
	regressionStatsTable,
	coefficientTable,
	correlationPepsiCoTable,
	correlationAnchorTable,
	crossElasticityFilter,
	monthRangeFilter,
	realESTVolumeIndexTable,
	defaultAPIVolumeFilterConfigurations,
	pepsiFilterOrder,
};

export default dataObject;
