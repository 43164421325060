import { combineReducers } from "redux";
import common from "./common.reducer";
import theme from "./theme.reducer";
import anchorFilter from "./anchorFilter.reducer";
import geoFilter from "./geoFilter.reducer";
import productFilter from "./productFilter.reducer";
import twentySecMarketCheck from "./home/twentySecMarketCheck.reducer";
import ProfitParabolaLinearRegression from "../../pages/ProfitParabolaDashboard/store/ProfitParabolaDashboard.reducer";
import toast from "./toast.reducer";
import SupermarketPepsicoBaseline from "../../pages/SupermarketDashboard/store/SupermarketDashboard.reducer";
import CreateNotification from "./AlertAndNotification/createNotification.reducer";
import DeleteNotification from "./AlertAndNotification/deleteNotification.reducer";
import DismissNotification from "./AlertAndNotification/dismissNotification.reducer";
import GetAllNotification from "./AlertAndNotification/getNotification.reducer";
import User from "./user.reducer";
import TrendingPage from "./YourDashboard/trendingPage.reducer";
import BrandLadderLoader from "../../pages/BrandLadder/store/brandLadder.reducer";
import HistoricPrice from "../../pages/HistoricPriceAndCrossPriceElasticity/store/historicPrice.reducer";
import MacroEconDashboard from "../../pages/MacroEconDashboard/store/macroEcon.reducer";
import PredictivePackEconomics from "../../pages/PredictivePackEconomics/store/PredictivePackEconomics.reducer";
import PricingPotentialLocalFilter from "../../pages/PricingPotential/store/pricingPotential.reducer";
import RevenueMap from "../../pages/RevenueMap/store/revenueMap.reducer";
import SellOutDashboard from "../../pages/SellOutDashboard/store/revenueMap.reducer";
import PriceSettingTool from "../../pages/PriceSettingTool/store/priceSettingTool.reducer";
import ElasticityPricingTrack from "../../pages/ElasticityPricingTrack/store/elasticityPricing.reducer";
import PricePianoDataLoader from "../../pages/PriceRangePiano/store/PriceRangePiano.reducer";
import PriceLadderDataLoader from "../../pages/PriceLadder/store/PriceLadder.reducer";
import ApiPredictive from "../../pages/APIPredictiveDashboard/store/apiPredictive.reducer";
import DashboardConfiguration from "./YourDashboard/dashboard.reducer";
import CountryList from "./countries.reducer";
import dialog from "./dialog.reducer";
import favoriteDashList from "./YourDashboard/favorite.reducer";
import assortmentDetail from "./MnADashboards/assortment.reducer";

const rootReducer = combineReducers({
	theme,
	common,
	toast,
	dialog,
	geoFilter,
	productFilter,
	anchorFilter,
	twentySecMarketCheck,
	ProfitParabolaLinearRegression,
	TrendingPage,
	SupermarketPepsicoBaseline,
	CreateNotification,
	GetAllNotification,
	DismissNotification,
	DeleteNotification,
	User,
	MacroEconDashboard,
	RevenueMap,
	SellOutDashboard,
	HistoricPrice,
	PricingPotentialLocalFilter,
	PredictivePackEconomics,
	BrandLadderLoader,
	PriceSettingTool,
	ElasticityPricingTrack,
	PricePianoDataLoader,
	PriceLadderDataLoader,
	ApiPredictive,
	DashboardConfiguration,
	CountryList,
	favoriteDashList,
	assortmentDetail,
});

export default rootReducer;
