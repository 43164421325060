import React, { useEffect, useState } from "react";
import DashboardActions from "../../components/DashboardActions";
import TopFilters from "./components/TopFilters";
import ShareOfMarketPiano from "./components/ShareOfMarketPiano";
import { pageList } from "../../mocks/common";
import DashboardSourceList from "../../components/DashboardSourceList";
import { removeMMPWFromLocal } from "../../util/helper";
import { RootStateOrAny, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { defaultPRPFilterConfigurations, defaultPRPLocalFilterConfiguration } from "../../mocks/priceRangePianoMock";
import GlobalNotifications from "../../components/GlobalNotificationContainer/GlobalNotificationContainer";

const PriceRangePiano: React.FC<{}> = () => {
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const PricePianoDataLoader = useSelector((state: any) => state.PricePianoDataLoader);
	const [appliedFilters, setAppliedFilters] = useState({} as any);
	const [localFilters, setLocalFilters] = useState<any>(defaultPRPLocalFilterConfiguration);
	const [defaultFilters, setDefaultFilters] = useState<any>({});
	const [disableDownloadButton, setDisableDownloadButton] = useState(true);
	const isDisabled: boolean = false;
	const search = window.location.search;
	const [urlParams] = useState(new URLSearchParams(search));
	const recordId = parseInt(urlParams.get("id"));
	const onApplyFilter = (data) => {
		if (data && data.filters) {
			setDisableDownloadButton(false);
			removeMMPWFromLocal("price-range");
			setAppliedFilters(data.filters);
		}
		setDefaultFilters({});
	};

	const getSaveDashBoardsData = (data) => {
		if (data.filters) {
			let savedDashboardData: any = { filters: JSON.parse(data.filters) };
			localStorage.setItem("periodicity", savedDashboardData.filters.periodicity || "{}");
			setDisableDownloadButton(false);
			setDefaultFilters(savedDashboardData?.filters?.global);
			setAppliedFilters(savedDashboardData?.filters?.global);
			setLocalFilters(savedDashboardData?.filters?.local);
		}
	};

	useEffect(() => {
		if (userData && !recordId) {
			const permissionCountries: string[] = userData.permissionCountry.split(",");
			const permissionCategories: string[] = userData.permissionCategory.split(",");
			const defaultFilterConfiguration = defaultPRPFilterConfigurations.find(
				(item) =>
					permissionCountries.includes(item.country) &&
					item.pepsi.category.every((category) => permissionCategories.includes(category)) &&
					item.competitor.category.every((category) => permissionCategories.includes(category))
			);
			if (defaultFilterConfiguration && Object.keys(defaultFilterConfiguration).length) {
				setDefaultFilters(defaultFilterConfiguration);
				setAppliedFilters(defaultFilterConfiguration);
			}
		}
	}, [userData]);

	return (
		<>
			{userData && (
				<DashboardActions
					title="Price Range Piano"
					disabled={isDisabled}
					data={{
						filters: {
							global: { ...appliedFilters },
							local: { ...localFilters },
						},
						mmpwKey: "price-range",
					}}
					dashboardKey={pageList[13].id}
					callback={getSaveDashBoardsData}
					disableDownloadButton={disableDownloadButton || PricePianoDataLoader.pricePianoDataLoader}
				>
					<Grid container spacing={2} className="m-b-20">
						<GlobalNotifications pageName={pageList[13].value} />
					</Grid>
					<TopFilters callback={onApplyFilter} defaultFilters={defaultFilters} />
					<ShareOfMarketPiano filters={appliedFilters} defaultFilters={defaultFilters} />
					<DashboardSourceList dashboardName={pageList[13].id} appliedFilters={appliedFilters} category={appliedFilters?.pepsi?.category} />
				</DashboardActions>
			)}
		</>
	);
};

export default PriceRangePiano;
