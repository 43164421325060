import { Box, Card, CardContent, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import DatasourceTooltip from "../../../../../components/MnAFilters/DatasourceTooltip/DatasourceTooltip";
import FilterAccordion from "../../../../../components/Filters";
import CommonMnAFilters from "../../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import dataObject from "../../../../../mocks/assortmentTool";
import { IMnAFilterConfigurations } from "../../../../../types/mixAndAssortment";
import { BlackTitle, OrangeBtn, PrimaryBtn, ThemeBtn } from "../../../../../styles/Common.Styled";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { getScenarioDetailSuccess } from "../../../../../store/actions/MnADashboards/assortment.action";
import { getAssortmentOverallFilters } from "../../../../../util/mixAndAssortmentServices";
import { useHistory, useLocation } from "react-router-dom";
const useStyles = makeStyles(() => ({
	textField: {
		borderTopRightRadius: "4px",
		borderBottomRightRadius: "4px",
		borderTopLeftRadius: "0",
		borderBottomLeftRadius: "0",
		width: "200px",
		height: "43px",
		"& .MuiOutlinedInput-root": {
			borderTopLeftRadius: "0",
			borderBottomLeftRadius: "0",
			fontSize: "14px",
		},
		"& .MuiOutlinedInput-input": {
			height: "35px",
			padding: "4px 10px 4px 0",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: 0,
			outline: 0,
		},
	},
	readOnlyTextField: {
		alignItems: "center",
		borderTopLeftRadius: "4px",
		borderBottomLeftRadius: "4px",
		backgroundColor: "#D4D4D4",
		display: "flex",
		fontSize: "14px",
		height: "43px",
		padding: "4px 0 4px 10px",
		width: "auto",
	},
}));

function sortEndTimePeriod(endTimeOptions: string[]): string[] {
	const updatedEndTimeOptions = endTimeOptions.sort((startTime: string, endTime: string) => {
		const startTimeValues = startTime.split("-");
		const endTimeValues = endTime.split("-");
		const startTimeOrderKey = dataObject.monthSortOrder.find((value) => value.key === startTimeValues[0])?.order ?? -1;
		const endTimeOrderKey = dataObject.monthSortOrder.find((value) => value.key === endTimeValues[0])?.order ?? -1;
		if (startTimeValues[1] === endTimeValues[1] && startTimeOrderKey < endTimeOrderKey) {
			return -1;
		} else if (startTimeValues[1] === endTimeValues[1] && startTimeOrderKey >= endTimeOrderKey) {
			return 1;
		} else if (startTimeValues[1] < endTimeValues[1]) {
			return -1;
		} else {
			return 1;
		}
	});
	return updatedEndTimeOptions;
}

function getTimeRangeValues(selectedEndTimePeriod: string, selectedDateTimePeriod: string) {
	let monthValues: number = 0;
	switch (selectedDateTimePeriod) {
		case "3MM":
			monthValues = 3;
			break;
		case "6MM":
			monthValues = 6;
			break;
		case "12MM":
			monthValues = 12;
			break;
	}
	const selectedEndTimePeriodValues: string[] = selectedEndTimePeriod.split("-");
	const monthNumber: number = dataObject.monthSortOrder.find((value) => value.key === selectedEndTimePeriodValues[0])?.order ?? 0;
	let monthDifference: number = monthNumber - monthValues + 1;
	let yearNumber: number = parseFloat(selectedEndTimePeriodValues[1]);
	if (monthDifference <= 0) {
		monthDifference = monthDifference + 12;
		yearNumber--;
	}
	const startMonth: string = dataObject.monthSortOrder.find((value) => value.order === monthDifference)?.key ?? "";
	return startMonth + "-" + yearNumber + " - " + selectedEndTimePeriod;
}

const OverallFilters: FC<{
	selectedFilters: IMnAFilterConfigurations;
	userDescription: string;
	isFilterDisabled: boolean;
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
	setUserDescription: Dispatch<SetStateAction<string>>;
	setShowScenarioLibrary: Dispatch<SetStateAction<boolean>>;
}> = ({ selectedFilters, userDescription, isFilterDisabled, setSelectedFilters, setUserDescription, setShowScenarioLibrary }) => {
	const location = useLocation();
	const history = useHistory();
	const query = new URLSearchParams(location.search);
	const classes = useStyles();
	const dispatch = useDispatch();
	const [overAllFilterData, setOverAllFilterData] = useState(dataObject.overallFilters);
	const [overallFilterOption, setOverallFilterOption] = useState<IMnAFilterConfigurations>(dataObject.overallFilterOptions);
	const [originalOverallFilterData, setOriginalOverallFilterData] = useState<IMnAFilterConfigurations[]>([]);
	const [isClearFilterFlag, setIsClearFilterFlag] = useState(false);
	const [overAllFilterSubTitle, setOverAllFilterSubTitle] = useState("");
	const [overAllFilterTitle, setOverAllFilterTitle] = useState("");
	const fetchOverallFilterData = () =>
		getAssortmentOverallFilters("Assortment").then((resp) => {
			setOriginalOverallFilterData(resp.data);
		});

	useEffect(() => {
		fetchOverallFilterData();
	}, []);

	useEffect(() => {
		setOverAllFilterData({
			...overAllFilterData,
			country: { ...overAllFilterData.country, disabled: isFilterDisabled },
			dateTimePeriod: { ...overAllFilterData.dateTimePeriod, disabled: isFilterDisabled },
			businessUnit: { ...overAllFilterData.businessUnit, disabled: isFilterDisabled },
			endTimePeriod: { ...overAllFilterData.endTimePeriod, disabled: isFilterDisabled },
		});
	}, [isFilterDisabled]);

	useEffect(() => {
		if (originalOverallFilterData.length > 0) {
			let countryFilterOptions: string[] = [];
			countryFilterOptions = Array.from(
				new Set(
					originalOverallFilterData.map((filterOptions) => {
						return filterOptions.country[0];
					})
				)
			);
			let dateTimeFilterOptions: string[] = [];
			let selectedfilterOptions = originalOverallFilterData.filter((filterOptions) => {
				return selectedFilters.country.indexOf(filterOptions.country[0]) !== -1;
			});
			dateTimeFilterOptions = Array.from(
				new Set(
					selectedfilterOptions.map((filterOptions) => {
						return filterOptions.dateTimePeriod[0];
					})
				)
			);
			let buFilterOptions: string[] = [];
			selectedfilterOptions = selectedfilterOptions.filter((filterOptions) => {
				return selectedFilters.dateTimePeriod.indexOf(filterOptions.dateTimePeriod[0]) !== -1;
			});
			buFilterOptions = Array.from(
				new Set(
					selectedfilterOptions.map((filterOptions) => {
						return filterOptions.businessUnit[0];
					})
				)
			);
			let endTimeFilterOptions: string[] = [];
			selectedfilterOptions = selectedfilterOptions.filter((filterOptions) => {
				return selectedFilters.businessUnit.indexOf(filterOptions.businessUnit[0]) !== -1;
			});
			endTimeFilterOptions = Array.from(
				new Set(
					selectedfilterOptions.map((filterOptions) => {
						return filterOptions.endTimePeriod[0];
					})
				)
			);
			endTimeFilterOptions = sortEndTimePeriod(endTimeFilterOptions);
			setOverallFilterOption({
				...overallFilterOption,
				country: countryFilterOptions,
				businessUnit: buFilterOptions,
				dateTimePeriod: dateTimeFilterOptions,
				endTimePeriod: endTimeFilterOptions,
			});
			setOverAllFilterData({
				...overAllFilterData,
				country: { ...overAllFilterData.country, disabled: isFilterDisabled },
				dateTimePeriod: { ...overAllFilterData.dateTimePeriod, disabled: !dateTimeFilterOptions || dateTimeFilterOptions.length === 0 || isFilterDisabled },
				businessUnit: { ...overAllFilterData.businessUnit, disabled: !buFilterOptions || buFilterOptions.length === 0 || isFilterDisabled },
				endTimePeriod: { ...overAllFilterData.endTimePeriod, disabled: !endTimeFilterOptions || endTimeFilterOptions.length === 0 || isFilterDisabled },
			});
		}
	}, [originalOverallFilterData, selectedFilters]);

	useEffect(() => {
		if (!isClearFilterFlag) {
			let isFilterChangeFlag: boolean = false;
			const defaultSelectedFilters: IMnAFilterConfigurations = { ...selectedFilters };
			if (selectedFilters.country.length === 0 && overallFilterOption.country[0]) {
				defaultSelectedFilters.country = [overallFilterOption.country[0]];
				isFilterChangeFlag = true;
			}
			if (isFilterChangeFlag) {
				setSelectedFilters({ ...defaultSelectedFilters });
			}
		}
	}, [overallFilterOption, selectedFilters, isClearFilterFlag]);

	useEffect(() => {
		if (selectedFilters.endTimePeriod[0]) {
			setOverAllFilterSubTitle(getTimeRangeValues(selectedFilters.endTimePeriod[0], selectedFilters.dateTimePeriod[0]));
			setOverAllFilterTitle("Overall Filters:");
		} else {
			setOverAllFilterTitle("Overall Filters");
			setOverAllFilterSubTitle("");
		}
	}, [selectedFilters.endTimePeriod]);
	const onChangeFilter = (key, value) => {
		setIsClearFilterFlag(true);
		let selectedValues = { ...selectedFilters };
		switch (key) {
			case "country": {
				selectedValues = { ...selectedFilters, country: value, dateTimePeriod: [], businessUnit: [], endTimePeriod: [] };
				break;
			}
			case "dateTimePeriod": {
				selectedValues = { ...selectedFilters, dateTimePeriod: value, businessUnit: [], endTimePeriod: [] };
				break;
			}
			case "businessUnit": {
				selectedValues = { ...selectedFilters, businessUnit: value, endTimePeriod: [] };
				break;
			}
			case "endTimePeriod": {
				selectedValues = { ...selectedFilters, endTimePeriod: value };
				break;
			}
		}
		setSelectedFilters(selectedValues);
	};

	const clearOverallFilters = () => {
		setIsClearFilterFlag(true);
		setSelectedFilters({ ...selectedFilters, country: [overallFilterOption.country[0]], dateTimePeriod: [], businessUnit: [], endTimePeriod: [] });
	};
	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<CardContent style={{ padding: "15px 15px 15px" }}>
				<Box className="m-b-20">
					<Stack direction={"row"}>
						<Grid container sx={{ alignItems: "center", marginLeft: "16px" }}>
							<Grid item sx={{ display: "flex", alignItems: "center" }}>
								<Grid>
									<BlackTitle>Scenario Name :</BlackTitle>
								</Grid>
								<Grid className="m-l-5" display={"flex"} alignItems={"center"} style={{ border: "1px solid #D3D3D3", borderRadius: "4px" }}>
									<Grid className={classes.readOnlyTextField}>
										{selectedFilters.country +
											"_" +
											selectedFilters.dateTimePeriod +
											"_" +
											selectedFilters.businessUnit +
											"_" +
											selectedFilters.endTimePeriod +
											"_" +
											(isFilterDisabled ? userDescription : "")}
									</Grid>
									{!isFilterDisabled && (
										<TextField
											className={classes.textField}
											placeholder="Enter description..."
											onChange={(event) => {
												if (event.target) {
													setUserDescription(event.target.value);
												}
											}}
											inputProps={{ maxLength: dataObject.maxUserDescriptionLength }} // Set max characters limit
											value={userDescription}
											autoComplete="off"
										/>
									)}
								</Grid>
							</Grid>
							<Box style={{ marginLeft: "auto" }}>
								<Grid>
									<PrimaryBtn
										onClick={() => {
											query.delete("scenario");
											query.delete("mode");
											history.push({
												pathname: location.pathname,
												search: query.toString(),
											});
											setShowScenarioLibrary(true);
											setSelectedFilters({
												country: [],
												dateTimePeriod: [],
												businessUnit: [],
												endTimePeriod: [],
												region: [],
												channel: [],
												storeSegment: [],
											});
											dispatch(getScenarioDetailSuccess(null, false));
										}}
									>
										Back to Scenario Library
									</PrimaryBtn>
								</Grid>
							</Box>
						</Grid>
					</Stack>
				</Box>
				<DatasourceTooltip dashboardName="Assortment" />
				<Grid style={{ marginTop: "10px" }}>
					<FilterAccordion title={overAllFilterTitle} expandFlag={true} subTitle={overAllFilterSubTitle}>
						<Grid container>
							<Grid item sm={9} className=" MuiGrid-item">
								<CommonMnAFilters
									filterData={overallFilterOption}
									onChange={onChangeFilter}
									data={overAllFilterData}
									defaultFilters={selectedFilters}
									filterOrder={dataObject.assortmentOverallFilterOrder}
								/>
							</Grid>
						</Grid>
					</FilterAccordion>
				</Grid>
				<Grid className="p-l-16">
					<OrangeBtn color="secondary" className="m-r-20" onClick={clearOverallFilters} disabled={isFilterDisabled}>
						Clear Filter
					</OrangeBtn>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default OverallFilters;
