import { GET_GEO_FILTERS, GET_GEO_FILTERS_SUCESS, GET_GEO_FILTERS_FAILURE, CLEAR_GEO_FILTERS, GET_COUNTRIES_SUCESS } from "./constants";
import { API } from "../../util/helper";
import _ from "lodash";
import { DateTime } from "luxon";
import { graphqlAPI } from "../api-constants";
import { DASHBOARD_GEOLEVEL_QUERY } from "../../util/queries/common";

const getGeoFilters = () => {
	return {
		type: GET_GEO_FILTERS,
	};
};

const getGeoFiltersSucess = (payload: any) => {
	return {
		type: GET_GEO_FILTERS_SUCESS,
		payload,
	};
};

const getGeoFiltersFailure = (error) => {
	return {
		type: GET_GEO_FILTERS_FAILURE,
		payload: error,
	};
};

const getGeoFilter = async (userId, dashboardKey) => {
	const graphQLApiUrl: string = await graphqlAPI();
	let queryParameter = DASHBOARD_GEOLEVEL_QUERY(userId, dashboardKey);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = [];
		if (response && response.data) {
			data = response.data.data.dashboardKeygeoLevel2;
		}
		return { data: data };
	});
};

export function fetchGeoFiltersData(userId, dashboardKey?, country?) {
	return (dispatch: any) => {
		const geoLevelSessionData = JSON.parse(sessionStorage.getItem("geoLevelsFilters"));
		const dt1 = DateTime.fromJSDate(new Date());
		const dt2 =
			geoLevelSessionData && geoLevelSessionData.expiresAt ? DateTime.fromJSDate(new Date(geoLevelSessionData.expiresAt)) : DateTime.fromJSDate(new Date());
		if (geoLevelSessionData && _.isArray(geoLevelSessionData.allGeoLevels) && dt1 < dt2) {
			if (country) {
				const data = geoLevelSessionData.allGeoLevels.find((x) => x.label === country);
				if (data) {
					dispatch(getGeoFiltersSucess(data.geoLevels));
				}
			}
			if (dashboardKey) {
				const countries = _.isArray(geoLevelSessionData.allGeoLevels)
					? geoLevelSessionData.allGeoLevels.map((x) => ({
							label: x.label,
							value: x.label,
					  }))
					: [];
				dispatch(getCountriesSucess(countries));
			}
		} else {
			dispatch(getGeoFilters());
			getGeoFilter(userId, dashboardKey)
				.then((response) => {
					const expiresAt = DateTime.fromJSDate(new Date()).plus({ hours: 4 });
					sessionStorage.setItem("geoLevelsFilters", JSON.stringify({ expiresAt, allGeoLevels: response.data }));
					if (country) {
						const data = _.isArray(response.data) ? response.data.find((x) => x.label === country) : null;
						dispatch(getGeoFiltersSucess(data.geoLevels));
					}
					if (dashboardKey) {
						const countries = _.isArray(response.data) ? response.data.map((x) => ({ label: x.label, value: x.label })) : [];
						dispatch(getCountriesSucess(countries));
						dispatch(getGeoFiltersSucess([]));
					}
				})
				.catch((error) => {
					dispatch(getGeoFiltersFailure(error));
				});
		}
	};
}

const getCountriesSucess = (payload: any) => {
	return {
		type: GET_COUNTRIES_SUCESS,
		payload,
	};
};

export const clearGeoFilters = () => {
	return {
		type: CLEAR_GEO_FILTERS,
	};
};
