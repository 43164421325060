import React from "react";
import { CardContent, List, ListItem, Grid, TextField } from "@mui/material";
import { Title, StyledCard, ListItemButton, Item } from "../../MMPW.Styled";
import { CloseButton } from "../../../../styles/Common.Styled";

const VisualFontSize = (props: { onSelectFont: (fontSize: number) => void; onCloseDropdown: () => void }) => {
	const fontSizeList = [8, 10, 12, 14, 16, 18];
	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={12} display="flex" alignContent="center">
						<Title>Visual's Font Size</Title>
						<CloseButton style={{ right: 10, top: 10, height: 20, width: 20 }} onClick={() => props.onCloseDropdown()}>
							x
						</CloseButton>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<List>
								{fontSizeList.map((size) => (
									<ListItem disablePadding onClick={() => props.onSelectFont(size)} key={`font-size-${size}`}>
										<ListItemButton>{size}</ListItemButton>
									</ListItem>
								))}
							</List>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>
	);
};

export default VisualFontSize;
