import { POST_TRENDING, POST_TRENDING_SUCCESS, POST_TRENDING_FAILURE } from "./constant";
import { API } from "../../../util/helper";
import { graphqlAPI } from "../../api-constants";
import { CREATE_TREND_QUERY } from "../../../util/queries/common";

const postTrendingPage = () => {
	return {
		type: POST_TRENDING,
	};
};

const postTrendingPageSuccess = (payload: any) => {
	return {
		type: POST_TRENDING_SUCCESS,
		payload,
	};
};

const postTrendingPageFailure = (error) => {
	return {
		type: POST_TRENDING_FAILURE,
		payload: error,
	};
};

const postTrendingPageData = async (payload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	let queryParameter = CREATE_TREND_QUERY(payload.pageName, payload.type, payload.userId);
	return API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = [];
		if (response && response.data) {
			data = response.data.data.createtrend;
		}
		return { data: data };
	});
};

export function saveTrendingPageData(payload) {
	return (dispatch: any) => {
		dispatch(postTrendingPage());
		postTrendingPageData(payload)
			.then((response) => {
				dispatch(postTrendingPageSuccess(response.data));
			})
			.catch((error) => {
				dispatch(postTrendingPageFailure(error));
			});
	};
}
