import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const Value = styled(Typography)(() => ({
	textAlign: "left",
	fontSize: "18px",
	letterSpacing: "0px",
	color: "#555A60",
	fontWeight: 600,
}));

const Title = styled(Typography)(() => ({
	textAlign: "left",
	marginTop: "20px",
	color: "#c0c0c0",
	fontSize: "12px",
	textTransform: "uppercase",
}));

const Profit = styled(Typography)(() => ({
	color: "green",
	fontSize: "16px",
	fontWeight: 600,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const Loss = styled(Typography)(() => ({
	color: "red",
	fontSize: "16px",
	fontWeight: 600,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const Icon = styled("img")(() => ({
	width: "45px",
	height: "45px",
	borderRadius: "5px",
	display: "block",
}));

export { Value, Profit, Loss, Icon, Title };
