import { Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/CustomTable";
import MultiLevelTable from "../../../components/MultiLevelTable/MultiLevelTable";
import { BlackTitle, NoData } from "../../../styles/Common.Styled";
import { messages } from "../../../util/config";
import { getMultilevelTableData } from "../service";
import { IUsageActivityFilters, TMultiLevelTableData, TMultiLevelTableHierarchy, TUsageActivityQueryFunction } from "../../../types/reporting";

const MultilevelTableContainer: React.FC<{
	headers: { label: string; value: string }[];
	queryFunction: TUsageActivityQueryFunction;
	title: string;
	filters: IUsageActivityFilters | undefined;
	child: string;
}> = ({ headers, queryFunction, title, filters, child }) => {
	const [multiLevelTableData, setMultiLevelTableData] = useState<TMultiLevelTableData>();
	const [skeleton, setSkeleton] = useState(true);
	const [hierarchy, setHierarchy] = useState<TMultiLevelTableHierarchy>();
	const [orderBy, setOrderBy] = useState("name");

	useEffect(() => {
		if (filters) {
			setSkeleton(true);
			getMultilevelTableData(queryFunction, filters)
				.then((response) => {
					setOrderBy(child === "activity" ? "name" : "viewCount");
					formatHierarchy();
					[
						{ parent: "country", child: "user" },
						{ parent: "user", child: child },
					].forEach((d) => {
						response.data[d.child] = response.data[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
					});
					setMultiLevelTableData(response.data);
					setSkeleton(false);
				})
				.catch((e) => {
					setMultiLevelTableData(undefined);
					setSkeleton(false);
				});
		}
	}, [filters, queryFunction]);

	const formatHierarchy = () => {
		child === "activity"
			? setHierarchy({
					country: { responseKeys: headers.map((item) => item.value) },
					user: { filterBy: ["country"], responseKeys: headers.map((item) => item.value) },
					activity: { filterBy: ["country", "user"], responseKeys: headers.map((item) => item.value) },
			  })
			: setHierarchy({
					country: { responseKeys: headers.map((item) => item.value) },
					user: { filterBy: ["country"], responseKeys: headers.map((item) => item.value) },
					dashboard: { filterBy: ["country", "user"], responseKeys: headers.map((item) => item.value) },
			  });
	};

	return (
		<Grid container>
			<Grid item sm={12} className="m-b-20">
				<Card>
					<CardContent>
						<BlackTitle style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>{title}</BlackTitle>
						{skeleton ? (
							<Grid className="m-t-20">
								<CustomTable
									heading={[1, 2, 3, 4, 5, 6]}
									value={[
										[1, 2, 3, 4, 5, 6],
										[1, 2, 3, 4, 5, 6],
										[1, 2, 3, 4, 5, 6],
									]}
									showSkeleton={skeleton}
								/>
							</Grid>
						) : multiLevelTableData && multiLevelTableData.country.length > 0 ? (
							<Grid container spacing={0}>
								<MultiLevelTable
									data={multiLevelTableData}
									tableHeader={headers}
									showSkeleton={skeleton}
									level={"country"}
									hierarchy={hierarchy}
									orderByNum={orderBy}
									style={{ height: 500, overflow: "auto" }}
								/>
							</Grid>
						) : (
							<Grid container display="flex" justifyContent="center" alignItems="center" style={{ height: 400 }}>
								<NoData sx={{ color: "black" }}>{messages.reports}</NoData>
							</Grid>
						)}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default MultilevelTableContainer;
