import Highcharts from "highcharts";
import xrange from "highcharts/modules/xrange";
import draggable from "highcharts/modules/draggable-points";
import more from "highcharts/highcharts-more";
import { formatNumber } from "../../../../util/helper";

if (typeof Highcharts === "object") {
	more(Highcharts);
	draggable(Highcharts);
	xrange(Highcharts);
}

const chartConfig: any = {
	chart: {
		type: "bubble",
		height: window.innerHeight - 100,
		events: {
			load() {
				let chart = this;
				if (!chart.xAxis[0].tickPositions.includes(0)) {
					chart.update({
						xAxis: {
							min: 0,
						},
					});
				}
				if (!chart.yAxis[0].tickPositions.includes(0)) {
					chart.update({
						yAxis: {
							min: 0,
						},
					});
				}
			},
		},
	},
	credits: { enabled: false },
	title: {
		text: "",
	},
	zoomType: "xy",
	tooltip: {
		enable: true,
		formatter: function () {
			return `<Grid >
          <p>Var Description : <b>${this?.key}</b></p>
          <br/>
          <br/>
          <p>Var X : <b>${formatNumber(this?.x.toFixed(2))}</b></p>
          <br/>
          <br/>
          <p>Var Y : <b>${formatNumber(this?.y.toFixed(2))}</b></p>
          <br/>
          <br/>
          <p>Variable Size : <b>${formatNumber(this?.point?.z.toFixed(2))}</b></p>
        </Grid>`;
		},
	},
	legend: {
		enabled: true,
		layout: "horizontal",
		backgroundColor: "transparent",
		align: "center",
		verticalAlign: "top",
		symbolRadius: 0,
		itemStyle: {
			fontSize: "16px",
		},
	},
	plotOptions: {
		series: {
			states: {
				hover: {
					enabled: false,
				},
				inactive: {
					enabled: false,
				},
			},
		},
	},
	xAxis: {
		startOnTick: false,
		endOnTick: false,
		title: {
			enabled: true,
			margin: 30,
			style: {
				fontSize: 18,
				fontWeight: 600,
			},
		},
		labels: {
			style: {
				fontSize: "14px",
			},
		},
		plotLines: [
			{
				id: "x",
				color: "#faa",
				width: 3,
				value: 0,
				zIndex: 3,
			},
		],
	},
	yAxis: {
		startOnTick: false,
		endOnTick: false,
		title: {
			enabled: true,
			margin: 30,
			style: {
				fontSize: 18,
				fontWeight: 600,
			},
		},
		labels: {
			style: {
				fontSize: "14px",
			},
		},
		plotLines: [
			{
				id: "y",
				color: "#faa",
				width: 3,
				value: 0,
				zIndex: 3,
			},
		],
	},

	series: [],
	navigation: {
		buttonOptions: {
			enabled: false,
		},
	},
};

export { chartConfig };
