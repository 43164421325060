import React, { FC, useState } from "react";
import PageContainer from "../../components/PageContainer";
import { pageNames } from "../../mocks/common";
import { Title } from "../../styles/Common.Styled";
import TopFilters from "./components/Filters/TopFilters";
import Overview from "./components/Overview/Overview";
import Portfolio from "./components/Portfolio/Portfolio";
import GeoLevelSummary from "./components/GeoLevelSummary/GeoLevelSummary";
import { IMnAFilterConfigurations } from "../../types/mixAndAssortment";
const ExecutionTracker: FC = () => {
	const [selectedFilters, setSelectedFilters] = useState<IMnAFilterConfigurations>({
		country: [],
		dateTimePeriod: [],
		businessUnit: [],
		endTimePeriod: [],
		region: [],
		channel: [],
		storeSegment: [],
		mixScenario: [],
		assortmentScenario: [],
	});
	const [skeleton, setSkeleton] = useState(true);
	return (
		<PageContainer page={pageNames.executionTracker}>
			<Title className="m-b-20" style={{ fontSize: 24, fontWeight: 600 }}>
				Execution Tracker
			</Title>
			<TopFilters setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} setSkeleton={setSkeleton} />
			<Overview chartSkeleton={skeleton} />
			<Portfolio setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />
			<GeoLevelSummary setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />
		</PageContainer>
	);
};

export default ExecutionTracker;
