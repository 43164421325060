import { Table, TableBody, TableCell, TableContainer, TableHead, Grid } from "@mui/material";
import _ from "lodash";
import React from "react";
import { StyledTableRow } from "../../../../styles/Common.Styled";
import { formatNumber } from "../../../../util/helper";

const WeeklyTable: React.FC<{ heading; data; title?; showPercent?; unitType? }> = ({ heading, data, title, showPercent = false, unitType }) => {
	return (
		<Grid container mt={4}>
			<Grid item sm={12} width={800}>
				<TableContainer
					sx={{
						"&::-webkit-scrollbar": {
							height: 8,
						},
					}}
				>
					<Table>
						<TableHead>
							<StyledTableRow>
								<TableCell className="b-color">{title || null}</TableCell>
								{heading.map((item, i) => (
									<TableCell className="b-color" key={`table-head-cell-${i}`}>
										<span style={{ whiteSpace: "nowrap", fontWeight: 800 }}>{item}</span>
									</TableCell>
								))}
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{_.isArray(data)
								? data.map((item, i) => (
										<StyledTableRow key={`table-row-${i}`}>
											<TableCell className="b-color" style={{ backgroundColor: "#ECECEC", whiteSpace: "nowrap", width: item.width ? item.width : null }}>
												{item.title}
											</TableCell>
											{item.data.map((value, j) => (
												<TableCell className="b-color" style={{ whiteSpace: "nowrap" }} key={`table-row-${i}-cell-${j}`}>
													{_.isNumber(value) ? formatNumber(value.toFixed(2)) : value}
													{`${showPercent ? "%" : ""}`}
												</TableCell>
											))}
										</StyledTableRow>
								  ))
								: null}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export default WeeklyTable;
