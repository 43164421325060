import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { BlackInputTitle } from "../../styles/Common.Styled";

const Title = styled(Typography)(({ theme }) => ({
	fontWeight: 600,
	fontSize: 16,
}));

const DropdownTitle = styled(BlackInputTitle)(({ theme }) => ({
	fontSize: 14,
	fontWeight: 500,
}));

export { Title, DropdownTitle };
