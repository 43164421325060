export const CHANGE_THEME = "change_theme";
export const OPEN_SIDE_MENU = "open_side_menu";
export const LOADER = "open_close_loader";
export const TOAST = "toast";
export const DIALOG = "dialog";
export const ADDREMOVELABEL = "add_remove_label";
export const REMOVELABEL = "remove_label";
export const REMOVESERIESLABEL = "remove_series_label";

//Filters

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCESS = "GET_COUNTRIES_SUCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_GEO_FILTERS = "GET_GEO_FILTERS";
export const GET_GEO_FILTERS_SUCESS = "GET_GEO_FILTERS_SUCESS";
export const GET_GEO_FILTERS_FAILURE = "GET_GEO_FILTERS_FAILURE";
export const CLEAR_GEO_FILTERS = "CLEAR_GEO_FILTERS";

export const GET_PRODUCT_FILTERS = "GET_PRODUCT_FILTERS";
export const GET_PRODUCT_FILTERS_SUCESS = "GET_PRODUCT_FILTERS_SUCESS";
export const GET_PRODUCT_FILTERS_FAILURE = "GET_PRODUCT_FILTERS_FAILURE";
export const ON_CHANGE_PRODUCT_OPTION = "ON_CHANGE_PRODUCT_OPTION";
export const CLEAR_PRODUCT_FILTERS = "CLEAR_PRODUCT_FILTERS";

export const GET_ANCHOR_PRODUCT_FILTERS = "GET_ANCHOR_PRODUCT_FILTERS";
export const GET_ANCHOR_PRODUCT_FILTERS_SUCESS = "GET_ANCHOR_PRODUCT_FILTERS_SUCESS";
export const GET_ANCHOR_PRODUCT_FILTERS_FAILURE = "GET_ANCHOR_PRODUCT_FILTERS_FAILURE";
export const ON_CHANGE_ANCHOR_OPTION = "ON_CHANGE_ANCHOR_OPTION";
export const CLEAR_ANCHOR_PRODUCT_FILTERS = "CLEAR_ANCHOR_PRODUCT_FILTERS";

export const GET_20_SEC_MARKET_CHECK = "GET_20_SEC_MARKET_CHECK";
export const GET_20_SEC_MARKET_CHECK_SUCESS = "GET_20_SEC_MARKET_CHECK_SUCESS";
export const GET_20_SEC_MARKET_CHECK_FAILURE = "GET_20_SEC_MARKET_CHECK_FAILURE";

export const GET_USER = "GET_USER";
export const GET_USER_SUCESS = "GET_USER_SUCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
