import { GET_SCENARIO_DETAIL, GET_SCENARIO_DETAIL_FAILURE, GET_SCENARIO_DETAIL_SUCCESS } from "../../actions/MnADashboards/constants";

let scenarioDetailInitialState = {
	data: null,
	error: null,
	loader: false,
	isReadOnly: false,
};

const assortmentDetail = (state = scenarioDetailInitialState, action) => {
	switch (action.type) {
		case GET_SCENARIO_DETAIL:
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			};
		case GET_SCENARIO_DETAIL_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
				error: null,
				isReadOnly: action.isReadOnly,
			};
		case GET_SCENARIO_DETAIL_FAILURE:
			return {
				...state,
				data: null,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default assortmentDetail;
