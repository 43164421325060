import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Grid, Divider, Popover } from "@mui/material";

import { StyledCard, Title, GreyText, ColorPickerBox } from "../../MMPW.Styled";

import { IShapePickerType } from "../../../../types/common";
import { BlackTextBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import ColorPicker from "../ColorPicker/ColorPicker";
import { convertSVGToBase64 } from "../../../../util/helper";
import { shapes } from "../../config";

const useStyles = makeStyles(() => ({
	blackTitle: { color: "#000 !important" },
	extraLargeIcon: {
		color: "#858C94 !important",
		fontSize: "80px !important",
		cursor: "pointer",
	},
	verticalDivider: {
		borderStyle: "dotted !important",
		borderColor: "#858C94 !important",
		marginRight: "10px !important",
	},
}));

const ShapePicker: React.FC<{ callback: any }> = ({ callback }) => {
	const classes = useStyles();
	const [selectedShape, setSelectedShape] = useState<IShapePickerType>();
	const [color, setColor] = useState("#000");
	const [colorAnchorEl, setColorAnchorEl] = useState(null);

	const handleColorClick = (event) => {
		setColorAnchorEl(event.currentTarget);
	};

	const handleColorClose = () => {
		setColorAnchorEl(null);
	};

	const onAddClick = async () => {
		if (selectedShape) {
			if (selectedShape.type === "Shape") {
				await convertSVGToBase64(selectedShape.id).then((response: any) => {
					callback({ type: "Shape", ...selectedShape, color, ...response });
				});
			} else callback({ type: "Shape", ...selectedShape, color });
		}
	};

	const pickedColor = (color) => {
		setColor(color);
	};

	const colorPickerOpen = Boolean(colorAnchorEl);

	return (
		<StyledCard>
			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<Title className="m-b-10">Select Shapes to Draw</Title>
						<Divider sx={{ borderColor: "#858C94" }} />
					</Grid>
					<Grid item xs={12} className="m-b-10">
						<Grid container>
							<Grid item xs={7}>
								{shapes.map((i) => {
									return (
										<i.Icon
											id={i.id}
											key={i.id}
											style={{
												background: selectedShape && selectedShape.id === i.id && "#EDF5FA",
												height: i.height,
												width: i.width,
												fill: selectedShape && selectedShape.id === i.id ? color : i.fill,
											}}
											className={classes.extraLargeIcon}
											onClick={() => setSelectedShape(i)}
										/>
									);
								})}
							</Grid>
							<Grid item xs={1}>
								<Divider orientation="vertical" className={classes.verticalDivider} />
							</Grid>
							<Grid item xs={4}>
								<Grid container direction="row" spacing={2}>
									<Grid item xs={4}>
										<GreyText>BORDER</GreyText>
										<ColorPickerBox color={color} onClick={handleColorClick} />
										<Popover
											anchorEl={colorAnchorEl}
											open={colorPickerOpen}
											onClose={handleColorClose}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "left",
											}}
										>
											<ColorPicker color={color} callback={pickedColor} />
										</Popover>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<PrimaryBtn variant="contained" onClick={onAddClick} className="m-r-10">
							Add
						</PrimaryBtn>
						<BlackTextBtn
							className="disable"
							onClick={() => {
								callback();
							}}
						>
							Cancel
						</BlackTextBtn>
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>
	);
};

export default ShapePicker;
