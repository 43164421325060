import { GET_NOTIFICATION, GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE } from "./constants";
import { API } from "../../../util/helper";
import { graphqlAPI } from "../../api-constants";
import { NOTIFICATION_QUERY } from "../../../util/queries/notifications";

const getNotification = () => {
	return {
		type: GET_NOTIFICATION,
	};
};

export const getNotificationSuccess = (payload: any) => {
	return {
		type: GET_NOTIFICATION_SUCCESS,
		payload,
	};
};

const getNotificationFailure = (error) => {
	return {
		type: GET_NOTIFICATION_FAILURE,
		payload: error,
	};
};

const getNotificationData = async (userId: string, pageName?: string, sortOrder?: number) => {
	const graphQLApiUrl: string = await graphqlAPI();
	let queryParameter = () => {
		return NOTIFICATION_QUERY(userId, pageName, sortOrder);
	};
	return await API.post(graphQLApiUrl, queryParameter()).then((response) => {
		let data = [];
		if (response && response.data) {
			if (pageName) data = response.data.data.notificationsByPageKey;
			else data = response.data.data.notification;
		}
		return { data: data };
	});
};

export function fetchNotifications(userId: string, pageName?: string, sortOrder?: number) {
	return (dispatch: any) => {
		dispatch(getNotification());
		getNotificationData(userId, pageName, sortOrder)
			.then((response) => {
				dispatch(getNotificationSuccess(response.data));
			})
			.catch((error) => {
				dispatch(getNotificationFailure(error));
			});
	};
}
