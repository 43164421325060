import { GET_USER, GET_USER_SUCESS, GET_USER_FAILURE } from "./constants";
import { API, blobToBase64 } from "../../util/helper";
import { loader } from "./common.action";
import { graphqlAPI, userProfileAPI } from "../api-constants";
import { GET_USER_QUERY } from "../../util/queries/users";

const getUser = () => {
	return {
		type: GET_USER,
	};
};

const getUserSucess = (payload: any) => {
	return {
		type: GET_USER_SUCESS,
		payload,
	};
};

const getUserFailure = (error) => {
	return {
		type: GET_USER_FAILURE,
		payload: error,
	};
};

const getUserDetail = async (emailId: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	let queryParameter = GET_USER_QUERY(0, emailId);
	return API.post(graphQLApiUrl, queryParameter);
};

export function fetchUser(emailId: string) {
	return (dispatch: any) => {
		dispatch(getUser());
		dispatch(loader(true));
		getUserDetail(emailId)
			.then(async (response) => {
				const userData = {
					...response.data.data.getUser[0],
					isDarkMode: response.data.data.getUser[0].isDarkMode === "true",
					profile: await fetchProfileImage(response.data.data.getUser[0].imageUrl),
				};
				dispatch(getUserSucess(userData));
				dispatch(loader(false));
			})
			.catch((error) => {
				dispatch(loader(false));
				dispatch(getUserFailure(error));
			});
	};
}

export const fetchProfileImage = async (profileImageUrl) => {
	const userProfileApiUrl: string = await userProfileAPI();
	let response: any = { status: 404 };
	try {
		if (profileImageUrl) response = await API.get(`${userProfileApiUrl}/${profileImageUrl}`, { responseType: "blob" });
	} catch (error) {}
	if (response?.status === 200) {
		return await blobToBase64(response.data);
	}
};
