import { GridColDef } from "@mui/x-data-grid";
import React from "react";

const filterableColumns = ["segment", "brand", "subBrand", "packSize", "skuName", "flavor", "checkoutSku", "rank", "modelOutput"];

const sortableColumns = [
	"skuName",
	"segment",
	"brand",
	"subBrand",
	"packSize",
	"rank",
	"modelOutput",
	"revenueMix",
	"cumulativeIncrementalRevenueMix",
	"nopbtPercent",
	"cumulativeSalesMix",
	"penetration",
	"totalScore",
	"incrementalRevenueScore",
	"incrementalNoPBTScore",
	"noPBTScore",
	"growthScore",
	"marketSizeScore",
	"capacityConstraintScore",
	"operativeComplexityScore",
	"marketGrowthScore",
	"inventoryTurnScore",
	"salesVelocityPerMonth",
	"manufacturerWalkRate",
	"netIncrementalRevenueMix",
	"incrementalNoPBTMix",
	"sopRevenueMix",
	"sopNopbtMix",
	"netRevenueRy",
	"checkoutSku",
];

const tableColumns: GridColDef[] = [
	{
		headerName: "Segment",
		field: "segment",
		width: 160,
		align: "center",
		filterable: true,
		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
	},
	{
		headerName: "Brand",
		field: "brand",
		width: 140,
		align: "center",
		filterable: true,
		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
	},
	{
		headerName: "Sub-Brand",
		field: "subBrand",
		width: 250,
		align: "center",
		filterable: true,
		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
	},
	{
		headerName: "Pack-Size",
		field: "packSize",
		width: 190,
		align: "center",
		filterable: true,
		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
	},
	{
		headerName: "SKU Name",
		field: "skuName",
		width: 450,
		align: "center",
		filterable: true,
		renderCell: (params) => <div style={{ whiteSpace: "break-spaces", textAlign: "center" }}>{params.value}</div>,
	},
	{
		headerName: "Rank",
		field: "rank",
		width: 150,
		filterable: true,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(0) : "0";
		},
		align: "center",
	},
	{
		headerName: "Model Output",
		field: "modelOutput",
		width: 220,
		align: "center",
		filterable: true,
	},
	{
		headerName: "Checkout SKU",
		field: "checkoutSku",
		width: 210,
		align: "center",
		filterable: true,
		valueFormatter: (params) => {
			return params.value ? `${params.value.toFixed(1)}` : "0.0";
		},
	},
	{
		headerName: `Net Incremental Revenue Mix (%)`,
		field: "netIncrementalRevenueMix",
		width: 280,
		valueFormatter: (params) => {
			return params.value ? `${params.value.toFixed(1)}%` : "0.0%";
		},
		align: "center",
	},
	{
		headerName: "Cumulative Incremental Revenue Mix (%)",
		field: "cumulativeIncrementalRevenueMix",
		width: 340,
		valueFormatter: (params) => {
			return params.value ? `${params.value.toFixed(1)}%` : "0.0%";
		},
		align: "center",
	},
	{
		headerName: "Penetration (Distribution in %)",
		field: "penetration",
		width: 260,
		valueFormatter: (params) => {
			return params.value ? `${params.value.toFixed(1)}%` : "0.0%";
		},
		align: "center",
	},
	{
		headerName: "Manufacturer Walk Rate (%)",
		field: "manufacturerWalkRate",
		width: 250,
		valueFormatter: (params) => {
			return params.value ? `${params.value.toFixed(1)}%` : "0.0%";
		},
		align: "center",
	},
	{
		headerName: "Revenue Mix (%)",
		field: "revenueMix",
		width: 160,
		valueFormatter: (params) => {
			return params.value ? `${params.value.toFixed(1)}%` : "0.0%";
		},
		align: "center",
	},
	{
		headerName: "NOPBT (%)",
		field: "nopbtPercent",
		width: 180,
		valueFormatter: (params) => {
			return params.value ? `${params.value.toFixed(1)}%` : "0.0%";
		},
		align: "center",
	},
	{
		headerName: "Total Score",
		field: "totalScore",
		width: 150,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "Incremental Revenue Score",
		field: "incrementalRevenueScore",
		width: 290,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "Incremental NoPBT Score",
		field: "incrementalNoPBTScore",
		width: 270,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "NOPBT Score",
		field: "noPBTScore",
		width: 165,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "Growth Score",
		field: "growthScore",
		width: 170,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "Market Size Score",
		field: "marketSizeScore",
		width: 200,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "Market Growth Score",
		field: "marketGrowthScore",
		width: 230,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "Capacity Constraint Score",
		field: "capacityConstraintScore",
		width: 260,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "Operative Complexity Score",
		field: "operativeComplexityScore",
		width: 275,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
	{
		headerName: "Inventory Turn Score",
		field: "inventoryTurnScore",
		width: 220,
		valueFormatter: (params) => {
			return params.value ? params.value.toFixed(1) : "0.0";
		},
		align: "center",
	},
];

const columnsToDowloadListTableSKU = [
	{ headerName: "Segment", field: "segment" },
	{ headerName: "Brand", field: "brand" },
	{ headerName: "Sub-Brand", field: "subBrand" },
	{ headerName: "Pack-Size", field: "packSize" },
	{ headerName: "SKU Name", field: "skuName" },
	{ headerName: "Rank", field: "rank" },
	{ headerName: "Model Output", field: "modelOutput" },
	{ headerName: "Checkout SKU", field: "checkoutSku" },
	{ headerName: "Net Incremental Revenue Mix (%)", field: "netIncrementalRevenueMix" },
	{ headerName: "Cumulative Incremental Revenue Mix (%)", field: "cumulativeIncrementalRevenueMix" },
	{ headerName: "Penetration (Distribution in %)", field: "penetration" },
	{ headerName: "Manufacturer Walk Rate (%)", field: "manufacturerWalkRate" },
	{ headerName: "Revenue Mix (%)", field: "revenueMix" },
	{ headerName: "NOPBT (%)", field: "nopbtPercent" },
	{ headerName: "Total Score", field: "totalScore" },
	{ headerName: "Incremental Revenue Score", field: "incrementalRevenueScore" },
	{ headerName: "Incremental NoPBT Score", field: "incrementalNoPBTScore" },
	{ headerName: "NOPBT Score", field: "noPBTScore" },
	{ headerName: "Growth Score", field: "growthScore" },
	{ headerName: "Market Size Score", field: "marketSizeScore" },
	{ headerName: "Market Growth Score", field: "marketGrowthScore" },
	{ headerName: "Capacity Constraint Score", field: "capacityConstraintScore" },
	{ headerName: "Operative Complexity Score", field: "operativeComplexityScore" },
	{ headerName: "Inventory Turn Score", field: "inventoryTurnScore" },
	{ headerName: "SOP Revenue Mix (%)", field: "sopRevenueMix" },
	{ headerName: "SOP NOPBT Mix (%)", field: "sopNopbtMix" },
	{ headerName: "SOP Revenue", field: "sopRevenue" },
	{ headerName: "SOP Profit", field: "sopProfit" },
	{ headerName: "Revenue Metric Growth(capping)", field: "revenueMetricGrowth" },
];

export { filterableColumns, sortableColumns, tableColumns, columnsToDowloadListTableSKU };
