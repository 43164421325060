import React, { useContext, useEffect, useState } from "react";
import MoreVert from "@material-ui/icons/MoreVert";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, Popover } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { trim } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EnvironmentContext } from "../../../App";
import { lightModeLogo, darkModeLogo } from "../../../assets/images";
import { USER_SETTINGS } from "../../../router/CONSTANTS";
import { changeTheme } from "../../../store/actions";
import { loader } from "../../../store/actions/common.action";
import { fetchUser } from "../../../store/actions/user.action";
import { stringAvatar } from "../../../util/helper";
import { AppBar, Avatar, ListItemButton, Toolbar } from "./Appbar.Styled";
import Logout from "./Logout";

const menues = ["User Settings", "Logout"];

const Appbar: React.FC<{}> = () => {
	const userDetail = useSelector((state: any) => state.User);
	const dispatch = useDispatch();
	const environmentVariables = useContext(EnvironmentContext).environmentVariables;
	const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [isFetchUserStarted, setIsFetchUserStarted] = useState(false);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const [userEmail] = useState(environmentVariables.LOGGEDIN_USER);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const profilePopoverOpen = Boolean(anchorEl);

	const logoutModal = () => {
		setShowModal(true);
	};

	const handleYes = async () => {
		setShowModal(false);
		dispatch(loader(true));
		const response = await fetch(`${environmentVariables.BACKEND_BASE_URL}files/deletedatafiles`);
		if (response.status === 200) {
			localStorage.clear();
			window.location.replace("https://web.mypepsico.com");
		} else {
			localStorage.clear();
			history.push("/home");
		}
	};

	const handleNo = () => {
		setShowModal(false);
	};

	useEffect(() => {
		if (userEmail && !isFetchUserStarted && !userDetail.data) {
			setIsFetchUserStarted(true);
			dispatch(fetchUser(userEmail));
		}
	}, [userEmail]);

	useEffect(() => {
		if (userDetail && userDetail.data) {
			setIsFetchUserStarted(false);
			menues[2] = userDetail.data.isDarkMode ? "Light Mode" : "Dark Mode";
			dispatch(changeTheme(userDetail.data.isDarkMode));
		}
	}, [userDetail.data]);

	return (
		<AppBar position="fixed">
			<Toolbar sx={{ display: "flex" }}>
				<Box
					onClick={() => {
						history.push("/home");
					}}
				>
					<img src={theme.darkmode ? darkModeLogo : lightModeLogo} alt={"RevMan"} height="45px" width="180px" />
				</Box>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Typography component={"span"} variant="body1">
						{userDetail.data && userDetail.data.firstName} {userDetail.data && userDetail.data.lastName}
					</Typography>
					<Avatar
						alt={userDetail.data && userDetail.data.firstName.trim() + userDetail.data.lastName.trim()}
						src={userDetail.data && userDetail.data.profile}
						{...(userDetail.data
							? stringAvatar(`${trim(userDetail.data && userDetail.data.firstName)} ${trim(userDetail.data && userDetail.data.lastName)}`)
							: "")}
						sx={{ background: theme.darkmode ? "white" : "" }}
					/>
					<IconButton onClick={handleClick}>
						<Typography>
							<MoreVert />
						</Typography>
					</IconButton>
					<Popover
						open={profilePopoverOpen}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
					>
						<List>
							<ListItem disablePadding>
								<ListItemButton onClick={() => history.push(USER_SETTINGS)}>{menues[0]}</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton onClick={logoutModal}>{menues[1]}</ListItemButton>
							</ListItem>
						</List>
					</Popover>
				</Box>
			</Toolbar>
			<Dialog open={showModal} onClose={setShowModal} aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">{"Logout service"}</DialogTitle>
				<DialogContent>
					<DialogContentText color={theme.darkmode ? "#214e96" : "#858C94"}>Do you want to Logout?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Logout text="Yes" onClick={handleYes} />
					<Button autoFocus onClick={handleNo}>
						<Typography color={theme.darkmode ? "#214e96" : "#858C94"}>No</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		</AppBar>
	);
};

export default Appbar;
