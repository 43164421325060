import React from "react";
import { sidedrawerItems, sidedrawerBottomItems } from "../../../util/menuItems";
import AppMenuItem from "./AppMenuItems";
import { Box, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { DrawerList } from "./AppMenu.Styled";
import { checkPermission } from "../../../util/helper";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
	container: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		flexDirection: "column",
	},
});

const AppMenu: React.FC<{ drawerState }> = ({ drawerState }) => {
	const userData = useSelector((state: any) => state.User.data);
	const classes = useStyles();
	return (
		<DrawerList className={classes.container}>
			<Box>
				{sidedrawerItems.map((item, i) => (
					<AppMenuItem {...item} isAccess={checkPermission(item.key, userData)} key={`main-menu-item${i}`} drawerState={drawerState} />
				))}
				<Divider />
			</Box>
			<Box className={classes.footer}>
				<Box style={{ height: 10 }} />
				{sidedrawerBottomItems.map((item, i) => (
					<AppMenuItem {...item} isAccess={checkPermission(item.key, userData)} key={`bottom-menu-item-${i}`} drawerState={drawerState} />
				))}
			</Box>
		</DrawerList>
	);
};

export default AppMenu;
