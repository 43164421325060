import React, { useEffect, useState } from "react";
import { CardContent, Grid, Card, Popover } from "@mui/material";
import { Title, CancelButton, SaveButton, ColorPickerBox } from "../../MMPW.Styled";
import ColorPicker from "../ColorPicker";
import _ from "lodash";

const SectionColor: React.FC<{ data; callback }> = ({ data, callback }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [sections, setSections] = useState([]);
	const [selectedSection, setSelectedSection] = useState<any>({});

	const handleClick = (event, index) => {
		setAnchorEl(event.currentTarget);
		setSelectedSection({ ...sections[index], index });
	};

	const pickedColor = (color) => {
		setSelectedSection({ ...selectedSection, color });
		const allSections = [...sections];
		allSections[selectedSection.index].color = color;
		setSections(allSections);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setSelectedSection({});
	};
	const colorPickerOpen = Boolean(anchorEl);

	useEffect(() => {
		setSections(data);
	}, [data]);

	const onSave = () => {
		callback({ type: "SectionColor", payload: [...sections] });
	};

	const onCancel = () => {
		callback(null);
	};

	return (
		<Card>
			<CardContent>
				<Grid item xs={12} display="flex" justifyContent="flex-end">
					<SaveButton onClick={onSave}>Save</SaveButton>
					<CancelButton onClick={onCancel}>Cancel</CancelButton>
				</Grid>
				<Grid item xs={12} className="m-b-20">
					<Title>Change Color Sections</Title>
					<hr />
				</Grid>
				<Grid>
					{_.isArray(sections) &&
						sections.map((item, i) => (
							<Grid container display="flex" alignItems="center" className="m-b-10" key={`grid-${i}`}>
								<Grid item xs={10}>
									{item.title}
								</Grid>
								<Grid item xs={2}>
									<ColorPickerBox height={30} width={40} color={item.color} onClick={(e) => handleClick(e, i)} />
								</Grid>
							</Grid>
						))}
					<Popover
						anchorEl={anchorEl}
						open={colorPickerOpen}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
					>
						<ColorPicker color={selectedSection.color} callback={pickedColor} />
					</Popover>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default SectionColor;
