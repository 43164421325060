import { BRAND_LADDER_MODEL, BRAND_LADDER_LOCAL_FILTER } from "./constants";

let intiState = {
	brandLadderDataLoader: false,
	brandLadderLocalFilter: false,
};

const BrandLadderLoader = (state = intiState, action) => {
	switch (action.type) {
		case BRAND_LADDER_MODEL:
			return {
				...state,
				brandLadderDataLoader: action.payload,
			};
		case BRAND_LADDER_LOCAL_FILTER:
			return {
				...state,
				brandLadderLocalFilter: action.payload,
			};
		default:
			return state;
	}
};

export default BrandLadderLoader;
