import React from "react";
import { SecureRoute } from "@okta/okta-react";
import Layout from "../Layout";
import { checkPermission } from "../util/helper";
import { useSelector } from "react-redux";

const PrivateRoute: React.ComponentType<any> = ({ component: Component, uniqueKey, ...rest }) => {
	const userData = useSelector((state: any) => state.User.data);
	return <SecureRoute {...rest} render={(props) => <Layout>{checkPermission(uniqueKey, userData) ? <Component {...props} /> : <></>}</Layout>} />;
};

export default PrivateRoute;
