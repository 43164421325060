import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import { NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import { getUnitVariationExpectedData } from "../../service";
import { unitVariationLoader } from "../../store/elasticityPricing.action";
import ExpansionPanel from "../ExpansionPanel/ExpansionPanel";
import UnitVariationExpected from "./components/UnitVariationExpected";
import { fetchImageURL } from "../../../../util/services";
import { toast } from "../../../../store/actions/toast.action";

const UnitVariationExpectedContainer: React.FC<{ filters }> = ({ filters }) => {
	const [data, setData] = useState<any>([]);
	const [skeleton, setSkeleton] = useState<boolean>(true);
	const dispatch = useDispatch();

	const getUnitVariationExpected = () => {
		getUnitVariationExpectedData(filters).then((res) => {
			if (res.status === 200) {
				let response = [...res.data.data.unitVariationsExpected.brands, res.data.data.unitVariationsExpected.allBrands];
				if (_.isArray(response)) {
					Promise.all(
						response?.map((item, i) => {
							if (item.parent.imageName) {
								item.parent.image = fetchImageURL(filters.country, item.parent.imageName);
							} else {
								item.parent.image = i === response.length - 1 ? fetchImageURL(filters.country, "All Product(Weighted Average)") : "";
							}
							if (_.isArray(item.child)) {
								item.child.forEach((c) => {
									if (c.imageName) {
										c.image = fetchImageURL(filters.country, c.imageName);
									} else {
										c.image = "";
									}
								});
							}
						})
					).then(() => {
						setData([...response]);
						setSkeleton(false);
					});
				}
			} else if (res.data.errors) {
				dispatch(toast("unitVariationsExpected: " + res.data.errors[0].message, true, 2000, "error"));
			} else {
				setSkeleton(false);
			}
			dispatch(unitVariationLoader(false));
		});
	};

	useEffect(() => {
		if (filters && filters.country) {
			setSkeleton(true);
			setData([]);
			getUnitVariationExpected();
		}
	}, [filters]);

	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<CardContent>
				<Title className="ellipsis">Units Variation Expected</Title>
				{!skeleton ? (
					_.isArray(data) && data.length > 0 ? (
						data?.map((item, i) => (
							<ExpansionPanel
								key={`expansion-panel-${i}`}
								parent={{
									icon: item?.parent.image ? item?.parent.image : null,
									imageHeight: 100,
									imageWidth: 100,
									buttonOffset: 37,
									name: item?.parent.name,
									displayName: item?.parent.displayName,
									component:
										item?.parent?.week?.length > 0 ? (
											<UnitVariationExpected id={`elasticity-pricing-track-unit-variation-expected-parent-${i}`} data={item?.parent} />
										) : (
											<Grid container display="flex" justifyContent="center" alignItems="center" height={400}>
												<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
											</Grid>
										),
								}}
								childrens={item?.child?.map((c, j) => {
									return {
										icon: c.image ? c.image : null,
										imageHeight: 100,
										imageWidth: 100,
										height: 1025,
										iconPosition: 200,
										name: c.name,
										displayName: c?.displayName,
										component:
											c.week?.length > 0 ? (
												<UnitVariationExpected id={`elasticity-pricing-track-unit-variation-expected-child-${j}`} data={c} />
											) : (
												<Grid container display="flex" justifyContent="center" alignItems="center" height={400}>
													<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
												</Grid>
											),
									};
								})}
							/>
						))
					) : (
						<Grid container display="flex" justifyContent="center" alignItems="center" height={400}>
							<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
						</Grid>
					)
				) : (
					<Grid container>
						<Grid item sm={12}>
							<Skeleton variant="rectangular" width="auto" height={400} className="m-b-20 m-t-10" />
						</Grid>
						<Grid item sm={12}>
							<Skeleton variant="rectangular" width="1100px" sx={{ margin: "auto" }} height={100} className="m-b-20 m-t-10" />
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	);
};

export default UnitVariationExpectedContainer;
