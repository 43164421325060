import React from "react";
import { Box, CardContent, Grid } from "@mui/material";
import { Title, StyledCard, Item } from "../../MMPW.Styled";
import { FormatBold, FormatItalic, FormatUnderlined } from "@mui/icons-material";

const FontStyle: React.FC<{ style?; callback }> = ({ style = {}, callback }) => {
	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Title>Font Style</Title>
					</Grid>
					<Grid item xs={12}>
						<Item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
							<Box style={{ padding: 5, display: "flex", border: style.fontWeight && "2px solid #005EA6", marginRight: 5 }}>
								<FormatBold
									style={{ color: style.fontWeight ? "#005EA6" : "#a6a3a5" }}
									onClick={() => callback({ type: "fontWeight", fontWeight: style.fontWeight ? "" : 900 })}
								/>
							</Box>
							<Box style={{ padding: 5, display: "flex", border: style.fontStyle && "2px solid #005EA6", fontStyle: "italic", marginRight: 5 }}>
								<FormatItalic
									style={{ color: style.fontStyle ? "#005EA6" : "#a6a3a5" }}
									onClick={() => callback({ type: "fontStyle", fontStyle: style.fontStyle ? "" : "italic" })}
								/>
							</Box>
							<Box style={{ padding: 5, display: "flex", border: style.textDecoration && "2px solid #005EA6" }}>
								<FormatUnderlined
									style={{ color: style.textDecoration ? "#005EA6" : "#a6a3a5" }}
									onClick={() => callback({ type: "textDecoration", textDecoration: style.textDecoration ? "" : "underline" })}
								/>
							</Box>
						</Item>
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>
	);
};

export default FontStyle;
