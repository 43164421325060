import { UNIT_VARIATION_EXPECTED } from "./constants";

let intiState = {
	unitVariationExpected: false,
};

const ElasticityPricingTrack = (state = intiState, action) => {
	if (action.type === UNIT_VARIATION_EXPECTED)
		return {
			...state,
			unitVariationExpected: action.payload,
		};
	else return state;
};

export default ElasticityPricingTrack;
