const measuresFilter = {
	variable1: {
		title: "Variable 1",
		options: [
			{ id: "1", label: "SOM", value: "SOM" },
			{ id: "2", label: "MIX", value: "MIX" },
		],
		defaultOption: { id: "1", value: "SOM" },
		showRadioButton: true,
	},
	SOMGroup: {
		title: "Variables Configurations",
		options: [
			{ id: "1", label: "Sales", value: "Value" },
			{ id: "2", label: "Volume", value: "Volume" },
			{ id: "3", label: "Units", value: "Units" },
		],
		defaultOption: { id: "1", value: "Sales" },
		showRadioButton: true,
	},
	SOMMeasure: {
		title: "",
		options: [
			{ id: "1", label: "Category", value: "Category" },
			{ id: "2", label: "Segment", value: "Segment" },
		],
		defaultOption: { id: "1", value: "Category" },
		showRadioButton: true,
	},
	APIUnits: {
		title: "Sell Out Prices",
		options: [
			{ id: "1", label: "Price Per Unit", value: "Units" },
			{ id: "2", label: "Price Per Volume (KGs)", value: "Volume" },
		],
		defaultOption: { id: "1", value: "Units" },
		showRadioButton: true,
	},
};

const specificFilter = {
	viewSharedByForPiano: {
		title: "View Shared by",
		options: [
			{ id: "1", label: "Absolute Value", value: "AbsoluteValue" },
			{ id: "2", label: "% Vertical", value: "VerticalPercentage" },
			{ id: "3", label: "% Horizontal", value: "HorizontalPercentage" },
		],
		defaultOption: { id: "1", value: "AbsoluteValue" },
		showRadioButton: true,
	},
	currentVsLast: {
		title: "Current Vs Last",
		options: [
			{ id: "1", label: "Current Period", value: "CurrentPeriod" },
			{ id: "2", label: "Last Period", value: "LastPeriod" },
			{
				id: "3",
				label: "Current Period vs. Last Period",
				value: "CurrentVsLast",
			},
		],
		defaultOption: { id: "1", value: "CurrentPeriod" },
		showRadioButton: true,
	},
};

const priceRanges = [
	{
		label: "3",
		value: "3",
	},
	{
		label: "5",
		value: "5",
	},
	{
		label: "10",
		value: "10",
	},
];

const productFilter = {
	category: {
		title: `Category <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		first: true,
		children: "segment",
		defaultSelectAll: false,
	},
	segment: {
		title: `Segment <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "brand",
		defaultSelectAll: false,
	},
	brand: {
		title: `Brand <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "subBrand",
		defaultSelectAll: false,
	},
	subBrand: {
		title: `Sub Brand <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "packSize",
		defaultSelectAll: false,
	},
	packSize: {
		title: `Pack Size <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		children: "permutation",
	},
	permutation: {
		title: `Permutation <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		last: true,
	},
};
const competitorFilter = {
	vendor: {
		title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		first: true,
		defaultSelectAll: false,
		children: "category",
	},
	category: {
		title: `Category <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "segment",
		defaultSelectAll: false,
	},
	segment: {
		title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "brand",
		defaultSelectAll: false,
	},
	brand: {
		title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "subBrand",
		defaultSelectAll: false,
	},
	subBrand: {
		title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "packSize",
		defaultSelectAll: false,
	},
	packSize: {
		title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		children: "permutation",
	},
	permutation: {
		title: `Permutation <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		last: true,
	},
};

const pepsiPricePiano = [
	{
		productName: "Ruffles 4X CHICO",
		productInfo: "",
		priceRangeData: [
			{
				date: "2022-08-03T07:02:46.073Z",
				value: 10,
			},
			{
				date: "2022-08-10T07:02:46.073Z",
				value: 100,
			},
			{
				date: "2022-08-20T07:02:46.073Z",
				value: 20,
			},
			{
				date: "2022-08-11T07:02:46.073Z",
				value: 100,
			},
			{
				date: "2022-08-08T07:02:46.073Z",
				value: 20,
			},
		],
	},
];

const competitorPricePiano = [
	{
		productName: "Chips 4X CHICO",
		productInfo: "",
		priceRangeData: [
			{
				date: "2022-08-03T07:02:46.073Z",
				value: 10,
			},
			{
				date: "2022-08-10T07:02:46.073Z",
				value: 100,
			},
			{
				date: "2022-08-20T07:02:46.073Z",
				value: 20,
			},
		],
	},
];

const pricePionoVisData = [
	{
		productName: "Pack Piano",
		productInfo: "",
		priceRangeData: [
			{
				date: "2022-08-03T07:02:46.073Z",
				value: 10,
			},
			{
				date: "2022-08-10T07:02:46.073Z",
				value: 100,
			},
			{
				date: "2022-08-20T07:02:46.073Z",
				value: 20,
			},
		],
	},
	{
		productName: "Unit Price Piano",
		productInfo: "",
		priceRangeData: [
			{
				date: "2022-08-03T07:02:46.073Z",
				value: 10,
			},
			{
				date: "2022-08-10T07:02:46.073Z",
				value: 100,
			},
			{
				date: "2022-08-20T07:02:46.073Z",
				value: 20,
			},
		],
	},
	{
		productName: "Index Piano",
		productInfo: "",
		priceRangeData: [
			{
				date: "2022-08-03T07:02:46.073Z",
				value: 10,
			},
			{
				date: "2022-08-10T07:02:46.073Z",
				value: 100,
			},
			{
				date: "2022-08-20T07:02:46.073Z",
				value: 20,
			},
		],
	},
];

const priceRangeTableHeading = [
	{ title: "Product" },
	{ title: "" },
	{ title: "Jan" },
	{ title: "Feb" },
	{ title: "Mar" },
	{ title: "Apr" },
	{ title: "May" },
	{ title: "Jun" },
	{ title: "Jul" },
	{ title: "Aug" },
	{ title: "Sep" },
	{ title: "Oct" },
	{ title: "Nov" },
	{ title: "Dec" },
];
const priceRangeVisTableHeading = [{ title: "Product" }, { title: "" }, { title: "Jan" }, { title: "Feb" }];

const pricePianoTableResponseData = [
	{
		productName: "Pepsico",
		productInfo: " ",
		priceRanges: [
			{
				date: "2021-01-01T00:00:00",
				"<$8": 9.13979646,
				"$11-$12": 12.338725221,
				">$12": 11.80315025,
				Total: 23.777,
			},
			{
				date: "2021-02-01T00:00:00",
				"<$8": 9.13011176,
				"$11-$12": 12.325650876,
				">$12": 11.91341655,
				Total: 23.777,
			},
		],
	},
	{
		productName: "Competitor",
		productInfo: " ",
		priceRanges: [
			{
				date: "2021-01-01T00:00:00",
				"<$8": 9.13979646,
				"$11-$12": 12.338725221,
				">$12": 11.80315025,
				Total: 23.777,
			},
			{
				date: "2021-02-01T00:00:00",
				"<$8": 9.13011176,
				"$11-$12": 12.325650876,
				">$12": 11.91341655,
				Total: 23.777,
			},
			{
				date: "2021-03-01T00:00:00",
				"<$8": 9.218832,
				"$11-$12": 12.4454232,
				">$12": 11.96562814,
				Total: 23.777,
			},
			{
				date: "2021-04-01T00:00:00",
				"<$8": 9.48541179,
				"$11-$12": 12.8053059165,
				">$12": 12.18423573,
				Total: 23.777,
			},
			{
				date: "2021-05-01T00:00:00",
				"<$8": 9.57286573,
				"$11-$12": 12.9233687355,
				">$12": 12.32024075,
				Total: 23.777,
			},
			{
				date: "2021-06-01T00:00:00",
				"<$8": 9.83201685,
				"$11-$12": 13.2732227475,
				">$12": 12.46889969,
				Total: 23.777,
			},
			{
				date: "2021-07-01T00:00:00",
				"<$8": 9.93094647,
				"$11-$12": 13.4067777345,
				">$12": 12.65551822,
				Total: 23.777,
			},
			{
				date: "2021-08-01T00:00:00",
				"<$8": 9.8763953,
				"$11-$12": 13.333133655,
				">$12": 12.73222965,
				Total: 23.777,
			},
			{
				date: "2021-09-01T00:00:00",
				"<$8": 9.87380062,
				"$11-$12": 13.329630837,
				">$12": 12.78552299,
				Total: 23.777,
			},
			{
				date: "2021-10-01T00:00:00",
				"<$8": 9.89374532,
				"$11-$12": 13.356556182,
				">$12": 12.81492173,
				Total: 23.777,
			},
			{
				date: "2021-11-01T00:00:00",
				"<$8": 9.9730777,
				"$11-$12": 13.463654895,
				">$12": 12.8489899,
				Total: 23.777,
			},
			{
				date: "2021-12-01T00:00:00",
				"<$8": 9.98284034,
				"$11-$12": 13.476834459,
				">$12": 12.92782083,
				Total: 23.777,
			},
		],
	},
];

const pricePianoTableSkeletonData = [
	["", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
	["", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
	["", 20, 22, 12, 14.5, 20, 22, 12, 14.5, 20, 22, 12, 14.5, 0],
	["", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
	["", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
];

const pricePianoVisSkeletonData = [
	["", 10, 1, 4],
	["", 10, 1, 4],
	["", 10, 1, 4],
	["", 10, 1, 4],
];

const pianoTableResponsePacksize = [
	{
		productName: "Pepsi",
		productInfo: "Pepsi",
		priceRanges: [
			{
				date: "January 2021",
				"<$8": 9.13979646,
				"$11-12": 12.338725221,
			},
			{
				date: "February 2021",
				"$11-12": 20,
				"<$8": 9.13011176,
			},
		],
	},
];

const pianoTableResponsePricerange = [
	{
		priceRange: "1-2",
		years: "2022",
		pespsico: 34334.33,
		compititor: 212121.121,
	},
];

const pianoTableResponseIndexrange = [
	{
		indexRange: "<80",
		years: "2022",
		pespsico: 34334.33,
		compititor: 212121.121,
	},
];

const seriesColor = ["#27bce9", "#00b33e", "#82d12e", "#ff8c26"];

const visTwoColors = ["#d41165", "#598ee3"];

const reviseResponse = [
	{
		packPiano: [
			{
				tag: "04X-CHICO 2021",
				label: "04X-CHICO",
				year: 2021,
				pepsi: 5,
				competitor: 15,
			},
			{
				tag: "11X-COMPARTE 2021",
				label: "11X-COMPARTE",
				year: 2021,
				pepsi: 31,
				competitor: 25,
			},
			{
				tag: "12X-FAMILIAR 2021",
				label: "12X-FAMILIAR",
				year: 2021,
				pepsi: 9,
				competitor: 31,
			},
			{
				tag: "07X-LARGE 2022",
				label: "07X-LARGE",
				year: 2022,
				pepsi: 19,
				competitor: 33,
			},
		],
	},
	{
		unitPricePiano: [
			{
				tag: "04X-CHICO 2021",
				label: "04X-CHICO",
				year: 2021,
				pepsi: 5,
				competitor: 15,
			},
			{
				tag: "11X-COMPARTE 2021",
				label: "11X-COMPARTE",
				year: 2021,
				pepsi: 31,
				competitor: 25,
			},
			{
				tag: "12X-FAMILIAR 2021",
				label: "12X-FAMILIAR",
				year: 2021,
				pepsi: 9,
				competitor: 31,
			},
			{
				tag: "07X-LARGE 2022",
				label: "07X-LARGE",
				year: 2022,
				pepsi: 45,
				competitor: 7,
			},
		],
	},
	{
		indexPiano: [
			{
				tag: "04X-CHICO 2021",
				label: "04X-CHICO",
				year: 2021,
				pepsi: 5,
				competitor: 15,
			},
			{
				tag: "11X-COMPARTE 2021",
				label: "11X-COMPARTE",
				year: 2021,
				pepsi: 31,
				competitor: 25,
			},
			{
				tag: "12X-FAMILIAR 2021",
				label: "12X-FAMILIAR",
				year: 2021,
				pepsi: 9,
				competitor: 31,
			},
			{
				tag: "07X-LARGE 2022",
				label: "07X-LARGE",
				year: 2022,
				pepsi: 30,
				competitor: 60,
			},
		],
	},
];
const pianoPackHeading = [
	{
		heading: [
			{ title: "Pack Size", value: "label" },
			{ title: "Year", value: "year" },
			{ title: "PepsiCo", value: "pepsi", formatNumber: true },
			{ title: "Competitor", value: "competitor", formatNumber: true },
		],
	},
	{
		heading: [
			{ title: "Price Range", value: "label" },
			{ title: "Year", value: "year" },
			{ title: "PepsiCo", value: "pepsi", formatNumber: true },
			{ title: "Competitor", value: "competitor", formatNumber: true },
		],
	},
	{
		heading: [
			{ title: "Index Range", value: "label" },
			{ title: "Year", value: "year" },
			{ title: "PepsiCo", value: "pepsi", formatNumber: true },
			{ title: "Competitor", value: "competitor", formatNumber: true },
		],
	},
];

const firstVisualApiData = {
	pepsi: {
		productName: "Pepsi",
		productInfo: "Pepsi",
		priceRanges: [
			{
				date: "January 2021",
				"<$10": 0.8449343132138927,
				"$10-$20": 21.878843968127235,
				"$20-$30": 7.375083089287904,
				"$30-$40": 16.228164810914972,
				"$40-$50": 5.482355840041101,
				"$50-$60": 1.7691276250784904,
				total: 53.57850964666359,
			},
			{
				date: "February 2021",
				"<$10": 1.3452147062636468,
				"$10-$20": 20.145323076483482,
				"$20-$30": 7.912217383199537,
				"$30-$40": 17.402631711411367,
				"$40-$50": 3.4628559575596656,
				"$50-$60": 1.699701502438474,
				"$60-$70": 1.50088197249657,
				total: 53.468826309852744,
			},
			{
				date: "March 2021",
				"<$10": 1.3891412595980555,
				"$10-$20": 20.048155697084795,
				"$20-$30": 7.255726586569678,
				"$30-$40": 17.074838115909323,
				"$40-$50": 1.7282174690459957,
				"$50-$60": 1.7656117740029884,
				"$60-$70": 2.0985231283282744,
				total: 51.360214030539105,
			},
			{
				date: "April 2021",
				"<$10": 0.9783377600395646,
				"$10-$20": 20.249523456237778,
				"$20-$30": 7.876694398884971,
				"$30-$40": 17.81023025656983,
				"$40-$50": 3.398989265466183,
				"$50-$60": 1.6416798421099086,
				"$60-$70": 2.0013181883444324,
				total: 53.95677316765267,
			},
			{
				date: "May 2021",
				"<$10": 0.907491431043196,
				"$10-$20": 20.726162837436274,
				"$20-$30": 7.605654037537009,
				"$30-$40": 16.67592846984785,
				"$40-$50": 4.037817962514452,
				"$50-$60": 3.0028661680535818,
				"$60-$70": 2.0612718203362173,
				total: 55.01719272676858,
			},
			{
				date: "June 2021",
				"<$10": 0.8932755266736379,
				"$10-$20": 21.662691472963942,
				"$20-$30": 4.930293701959345,
				"$30-$40": 18.084557145964844,
				"$40-$50": 3.038945583106304,
				"$50-$60": 4.583099189605959,
				"$60-$70": 2.0163978126442927,
				total: 55.20926043291833,
			},
			{
				date: "July 2021",
				"<$10": 0.8827037601563417,
				"$10-$20": 22.24484387033651,
				"$20-$30": 6.470150966228487,
				"$30-$40": 17.15006669828867,
				"$40-$50": 2.6181494742793325,
				"$50-$60": 2.4288226001384956,
				"$60-$70": 0.39638098331016935,
				"$70-$80": 1.7949857556383266,
				total: 53.98610410837633,
			},
			{
				date: "August 2021",
				"<$10": 1.08600775158801,
				"$10-$20": 21.222636113403855,
				"$20-$30": 6.44428745418723,
				"$30-$40": 18.28898539228065,
				"$40-$50": 1.62493758063033,
				"$50-$60": 1.7433656152535393,
				"$70-$80": 0.5470783803131991,
				total: 50.95729828765681,
			},
			{
				date: "September 2021",
				"<$10": 0.6953740542845723,
				"$10-$20": 19.9931157833011,
				"$20-$30": 4.181376279736039,
				"$30-$40": 17.7479437293137,
				"$40-$50": 3.723687590812899,
				"$50-$60": 2.5871051839512624,
				"$60-$70": 1.4267570265602731,
				">= $100": 2.0948579259877644,
				total: 52.45021757394761,
			},
			{
				date: "October 2021",
				"<$10": 0.5446986628879292,
				"$10-$20": 19.3961237371932,
				"$20-$30": 4.2117607555767,
				"$30-$40": 17.50664201700623,
				"$40-$50": 3.599105568552473,
				"$50-$60": 4.030545570845068,
				"$60-$70": 0.3744337895220627,
				">= $100": 2.661886512218322,
				total: 52.325196613801985,
			},
			{
				date: "November 2021",
				"<$10": 0.549488587321395,
				"$10-$20": 19.698190535904182,
				"$20-$30": 4.438762267312755,
				"$30-$40": 18.04180763536729,
				"$40-$50": 2.6859469683599566,
				"$50-$60": 3.3657886641620256,
				">= $100": 2.7621738369424813,
				total: 51.54215849537008,
			},
			{
				date: "December 2021",
				"<$10": 0.6159615159764961,
				"$10-$20": 19.021200877142096,
				"$20-$30": 4.179390111021453,
				"$30-$40": 15.192414743497881,
				"$40-$50": 5.757744713631951,
				"$50-$60": 1.6624445084447852,
				"$60-$70": 1.3169763855872676,
				"$80-$90": 3.572901598477213,
				">= $100": 0.5330541619950775,
				total: 51.85208861577422,
			},
		],
	},
	competitors: {
		productName: "Competitor",
		productInfo: "Competitor",
		priceRanges: [
			{
				date: "January 2021",
				"<$10": 2.878022552820952,
				"$10-$20": 25.360705914033204,
				"$20-$30": 1.624639001962547,
				"$30-$40": 16.558122884519705,
				total: 46.42149035333641,
			},
			{
				date: "February 2021",
				"<$10": 2.736479338630951,
				"$10-$20": 25.11207160517671,
				"$20-$30": 2.5014216234942572,
				"$30-$40": 16.18120112284534,
				total: 46.531173690147256,
			},
			{
				date: "March 2021",
				"<$10": 2.319824318829491,
				"$10-$20": 27.974529742617154,
				"$20-$30": 2.9938901336052948,
				"$30-$40": 15.35154177440895,
				total: 48.639785969460895,
			},
			{
				date: "April 2021",
				"<$10": 1.4548859714859441,
				"$10-$20": 26.918792232846567,
				"$20-$30": 3.4222973564599446,
				"$30-$40": 13.206066114551675,
				"$40-$50": 1.0411851570032011,
				total: 46.04322683234733,
			},
			{
				date: "May 2021",
				"<$10": 2.2651146241275435,
				"$10-$20": 25.29170144425571,
				"$20-$30": 4.046557388147697,
				"$30-$40": 13.051551624614577,
				"$40-$50": 0.3278821920858904,
				total: 44.98280727323142,
			},
			{
				date: "June 2021",
				"<$10": 2.4644345108802823,
				"$10-$20": 24.54267030110386,
				"$20-$30": 3.175413337049672,
				"$30-$40": 14.608221418047858,
				total: 44.79073956708167,
			},
			{
				date: "July 2021",
				"<$10": 2.0504723600959487,
				"$10-$20": 24.13439092547775,
				"$20-$30": 2.8176790306202366,
				"$30-$40": 15.944387592101995,
				"$40-$50": 1.0669659833277374,
				total: 46.01389589162367,
			},
			{
				date: "August 2021",
				"<$10": 2.3094645771559863,
				"$10-$20": 24.010523609156234,
				"$20-$30": 3.908621795229094,
				"$30-$40": 18.488900280020335,
				"$40-$50": 0.3251914507815356,
				total: 49.04270171234319,
			},
			{
				date: "September 2021",
				"<$10": 2.1717891745216793,
				"$10-$20": 22.160251621794732,
				"$20-$30": 3.8099192052314956,
				"$30-$40": 17.608308002968585,
				"$40-$50": 1.7995144215358954,
				total: 47.54978242605239,
			},
			{
				date: "October 2021",
				"<$10": 1.7919934434545757,
				"$10-$20": 22.361230310948613,
				"$20-$30": 3.6280127424759807,
				"$30-$40": 16.590866091121285,
				"$40-$50": 3.3027007981975607,
				total: 47.674803386198015,
			},
			{
				date: "November 2021",
				"<$10": 2.0982553616026465,
				"$10-$20": 21.881488200317186,
				"$20-$30": 5.005777652877213,
				"$30-$40": 15.045175395695752,
				"$40-$50": 4.427144894137117,
				total: 48.45784150462992,
			},
			{
				date: "December 2021",
				"<$10": 1.5632513144279296,
				"$10-$20": 21.52155672151938,
				"$20-$30": 5.1661218200873,
				"$30-$40": 12.262879023763794,
				"$40-$50": 6.223141220724823,
				"$60-$70": 1.410961283702548,
				total: 48.14791138422578,
			},
		],
	},
};

const secondVisApiData = {
	packPiano: [
		{
			tag: "01X-MINI 2021",
			label: "01X-MINI",
			year: 2021,
			pepsi: 1.14,
			competitor: 21.44,
		},
		{
			tag: "02X-JR LINE 2021",
			label: "02X-JR LINE",
			year: 2021,
			pepsi: 0.12,
			competitor: 189.55,
		},
		{
			tag: "03X-EPP SIZE 2021",
			label: "03X-EPP SIZE",
			year: 2021,
			pepsi: 13.64,
			competitor: 0,
		},
		{
			tag: "04X-CHICO 2021",
			label: "04X-CHICO",
			year: 2021,
			pepsi: 8985.15,
			competitor: 3359.39,
		},
		{
			tag: "05P-MULTIPK 2021",
			label: "05P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "06X-BOTANOTA 2021",
			label: "06X-BOTANOTA",
			year: 2021,
			pepsi: 3.65,
			competitor: 0,
		},
		{
			tag: "07X-MAX 2021",
			label: "07X-MAX",
			year: 2021,
			pepsi: 0.27,
			competitor: 584.9,
		},
		{
			tag: "08X-REC 2021",
			label: "08X-REC",
			year: 2021,
			pepsi: 1.38,
			competitor: 0,
		},
		{
			tag: "09X-JUMBO 2021",
			label: "09X-JUMBO",
			year: 2021,
			pepsi: 912.17,
			competitor: 0,
		},
		{
			tag: "10P-MULTIPK 2021",
			label: "10P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "10X-AMIGOTE 2021",
			label: "10X-AMIGOTE",
			year: 2021,
			pepsi: 0.16,
			competitor: 0,
		},
		{
			tag: "11X-COMPARTE 2021",
			label: "11X-COMPARTE",
			year: 2021,
			pepsi: 399.88,
			competitor: 519.76,
		},
		{
			tag: "12P-MULTIPK 2021",
			label: "12P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "12X-FAMILIAR 2021",
			label: "12X-FAMILIAR",
			year: 2021,
			pepsi: 925.69,
			competitor: 1334.93,
		},
		{
			tag: "13X-LLEVA2 2021",
			label: "13X-LLEVA2",
			year: 2021,
			pepsi: 26.9,
			competitor: 0,
		},
		{
			tag: "14X-FIESTA 2021",
			label: "14X-FIESTA",
			year: 2021,
			pepsi: 20.88,
			competitor: 0.04,
		},
		{
			tag: "15X-MEGA 2021",
			label: "15X-MEGA",
			year: 2021,
			pepsi: 3.65,
			competitor: 0.11,
		},
		{
			tag: "16X-SUPER SIZE 2021",
			label: "16X-SUPER SIZE",
			year: 2021,
			pepsi: 0.79,
			competitor: 0.65,
		},
		{
			tag: "18X-BOTE GRANDE 2021",
			label: "18X-BOTE GRANDE",
			year: 2021,
			pepsi: 13.89,
			competitor: 1.52,
		},
		{
			tag: "19X-BOTE CHICO 2021",
			label: "19X-BOTE CHICO",
			year: 2021,
			pepsi: 14.81,
			competitor: 203.43,
		},
		{
			tag: "24X-CHICO 2021",
			label: "24X-CHICO",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "24X-ESPEJO 2021",
			label: "24X-ESPEJO",
			year: 2021,
			pepsi: 955.28,
			competitor: 0,
		},
		{
			tag: "25P-MULTIPK 2021",
			label: "25P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "25X-POPULITO 2021",
			label: "25X-POPULITO",
			year: 2021,
			pepsi: 393.91,
			competitor: 0,
		},
		{
			tag: "27P-MULTIPK 2021",
			label: "27P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "30P-GET TOGETHER MIX 2021",
			label: "30P-GET TOGETHER MIX",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "30P-MULTIPK 2021",
			label: "30P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "99X-OTHER 2021",
			label: "99X-OTHER",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "02P-MULTIPK 2021",
			label: "02P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "08P-MULTIPK 2021",
			label: "08P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "17X-BOTE MEDIANO 2021",
			label: "17X-BOTE MEDIANO",
			year: 2021,
			pepsi: 0,
			competitor: 99.56,
		},
		{
			tag: "20P-MULTIPK 2021",
			label: "20P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "24P-MULTIPK 2021",
			label: "24P-MULTIPK",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
	],
	unitPricePiano: [
		{
			tag: "<10 2021",
			label: "<10",
			year: 2021,
			pepsi: 17.67,
			competitor: 267.69,
		},
		{
			tag: "10-20 2021",
			label: "10-20",
			year: 2021,
			pepsi: 11264.68,
			competitor: 4656.46,
		},
		{
			tag: "20-30 2021",
			label: "20-30",
			year: 2021,
			pepsi: 399.62,
			competitor: 282.95,
		},
		{
			tag: "30-40 2021",
			label: "30-40",
			year: 2021,
			pepsi: 832.13,
			competitor: 950.15,
		},
		{
			tag: "40-50 2021",
			label: "40-50",
			year: 2021,
			pepsi: 136.79,
			competitor: 157.45,
		},
		{
			tag: "50-60 2021",
			label: "50-60",
			year: 2021,
			pepsi: 16.1,
			competitor: 0.02,
		},
		{
			tag: "60-70 2021",
			label: "60-70",
			year: 2021,
			pepsi: 6.97,
			competitor: 0.14,
		},
		{
			tag: "70-80 2021",
			label: "70-80",
			year: 2021,
			pepsi: 0.28,
			competitor: 0.04,
		},
		{
			tag: "80-90 2021",
			label: "80-90",
			year: 2021,
			pepsi: 0.27,
			competitor: 0,
		},
		{
			tag: "90-100 2021",
			label: "90-100",
			year: 2021,
			pepsi: 0.29,
			competitor: 0,
		},
		{
			tag: ">100 2021",
			label: ">100",
			year: 2021,
			pepsi: 0.38,
			competitor: 0.69,
		},
	],
	indexPiano: [
		{
			tag: "<80 2021",
			label: "<80",
			year: 2021,
			pepsi: 12673.36,
			competitor: 6315.28,
		},
		{
			tag: "80-95 2021",
			label: "80-95",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "95-105 2021",
			label: "95-105",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: "105-120 2021",
			label: "105-120",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
		{
			tag: ">120 2021",
			label: ">120",
			year: 2021,
			pepsi: 0,
			competitor: 0,
		},
	],
};

const vis2GraphHeading = {
	packPiano: "Pack Piano",
	unitPricePiano: "Unit Price Piano",
	indexPiano: "Index Piano",
};

export const periodView = {
	title: "Period View",
	options: [
		{ label: "NA", value: "na" },
		{ label: "Full Year", value: "FullYear" },
		{ label: "Year to Date", value: "YearToDate" },
		{ id: "3", label: "Rolling Year", value: "RollingYear" },
	],
	placeholder: "Select",
};

export const priceRangeCompetitorFilterOrder = [
	{
		key: "vendor",
		order: 1,
	},
	{
		key: "category",
		order: 2,
	},
	{
		key: "segment",
		order: 3,
	},
	{
		key: "brand",
		order: 4,
	},
	{
		key: "subBrand",
		order: 5,
	},
	{
		key: "packSize",
		order: 6,
	},
	{
		key: "permutation",
		order: 7,
	},
];

export const priceRangePepsiCoFilterOrder = [
	{
		key: "category",
		order: 1,
	},
	{
		key: "segment",
		order: 2,
	},
	{
		key: "brand",
		order: 3,
	},
	{
		key: "subBrand",
		order: 4,
	},
	{
		key: "packSize",
		order: 5,
	},
	{
		key: "permutation",
		order: 6,
	},
];

export const defaultPRPFilterConfigurations = [
	{
		country: "MEXICO",
		geoLevel: ["COUNTRY-SUBCHAN"],
		channel: ["OT"],
		geoLevel2: ["TTL MEXICO-SMKT"],
		periodView: ["na"],
		viewShareby: "valueSales",
		indexes: ["20", "10"],
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO", "SAV-TORTILLA"],
			brand: [
				"BISTRO GOURMET",
				"CASERAS",
				"DORITOS",
				"DORITOS DINAMITA",
				"HAPPY SNACKS",
				"LAYS",
				"MI CARRITO SAL",
				"PIZZEROLAS",
				"POPCORNERS",
				"RANCHERITOS",
				"RECETA CRUJIENTE",
				"RED ROCK DELI",
				"RUFFLES",
				"SABRITAS OTHER",
				"SABRITAS PC",
				"SABRITAS POPPLERS",
				"STAX",
				"TOSTITOS",
			],
			subBrand: [
				"BISTRO GOURMET REGULAR",
				"CASERAS REGULAR",
				"DORITOS BLAST",
				"DORITOS CHILE",
				"DORITOS DINAMITA CHILE Y LIMON",
				"DORITOS DINAMITA FLAMIN HOT",
				"DORITOS FLAMIN HOT",
				"DORITOS HABANERO BITES",
				"DORITOS INCOGNITA",
				"DORITOS NACHO",
				"DORITOS OTHER SUBBRAND",
				"DORITOS PIZZEROLA",
				"DORITOS POPMIX",
				"DORITOS RAINBOW",
				"DORITOS VARIETY",
				"HAPPY SNACKS REGULAR",
				"LAYS REGULAR",
				"MI CARRITO SAL",
				"PIZZEROLAS REGULAR",
				"POPCORNERS REGULAR",
				"RANCHERITOS REGULAR",
				"RECETA CRUJIENTE CHILES ROJOS",
				"RECETA CRUJIENTE FLAMIN HOT",
				"RECETA CRUJIENTE JALAPENO",
				"RECETA CRUJIENTE OTHER SUBBRAND",
				"RECETA CRUJIENTE SAL",
				"RED ROCK DELI REGULAR",
				"RUFFLES HABANERO BITES",
				"RUFFLES MEGA CRUNCH COCTELERAS",
				"RUFFLES MEGA CRUNCH JALAPENO",
				"RUFFLES MEGA CRUNCH OTHER SUBBRAND",
				"RUFFLES MEGA CRUNCH SALSA NEGRA",
				"RUFFLES MEGA CRUNCH SALSA ROJA",
				"RUFFLES OTHER SUBBRAND",
				"RUFFLES QUESO",
				"RUFFLES SAL",
				"RUFFLES ULTRA BLAZIN CHEESE",
				"RUFFLES ULTRA FLAMIN HOT",
				"SABRITAS ACIDULCE",
				"SABRITAS ADOBADAS",
				"SABRITAS BLAZIN CHEESE",
				"SABRITAS CHAMPIONS",
				"SABRITAS CHILE MANZANO",
				"SABRITAS CHORIQUESO",
				"SABRITAS CREMA Y ESPECIAS",
				"SABRITAS FLAMIN HOT",
				"SABRITAS HABANERO",
				"SABRITAS LIMON",
				"SABRITAS OTHER SUBBRAND",
				"SABRITAS PC OTHER SUBBRAND",
				"SABRITAS PC VARIETY",
				"SABRITAS POPPLERS CHILE",
				"SABRITAS POPPLERS REGULAR",
				"SABRITAS SAL",
				"SABRITAS SENSACIONES",
				"STAX BBQ",
				"STAX CHEDDAR",
				"STAX ORIGINAL",
				"STAX OTHER SUBBRAND",
				"STAX PIZZA",
				"STAX SOUR CREAM",
				"TOSTITOS CRONCHOS",
				"TOSTITOS FLAMIN HOT",
				"TOSTITOS NACHOS",
				"TOSTITOS OTHER SUBBRAND",
				"TOSTITOS PIQUIN",
				"TOSTITOS SAL",
				"TOSTITOS SALSA ROJA",
				"TOSTITOS SALSA VERDE",
				"TOSTITOS TOSTADAS",
				"TOSTITOS TOSTIELOTE",
				"TOSTITOS TOSTINAS",
			],
			packSize: [
				"01X-MINI",
				"02X-JR LINE",
				"03P-MULTIPK",
				"03X-EPP SIZE",
				"03X-SHOTS",
				"04X-CHICO",
				"05P-MULTIPK",
				"06P-MULTIPK",
				"07X-MAX",
				"09P-MULTIPK",
				"09X-JUMBO",
				"108P-MULTIPK",
				"10P-MULTIPK",
				"10X-AMIGOTE",
				"10X-LHPP",
				"11X-COMPARTE",
				"12P-MULTIPK",
				"12X-FAMILIAR",
				"13X-LLEVA2",
				"14P-MULTIPK",
				"14X-FIESTA",
				"15X-HPP",
				"15X-MEGA",
				"16X-SUPER SIZE",
				"18X-BOTE GRANDE",
				"19X-BOTE CHICO",
				"20P-MULTIPK",
				"24P-MULTIPK",
				"24P-TOSTIPACK",
				"25P-MULTIPK",
				"25X-POPULITO",
				"27P-MULTIPK",
				"30P-MULTIPK",
				"81P-MULTIPK",
				"99W-SABRIMAYOREO",
				"99X-OTHER",
			],
			vendor: ["PEPSICO"],
			permutation: [
				"BSG REG_POTATO_12X-FAMILIAR",
				"CAS REG_POTATO_04X-CHICO",
				"CAS REG_POTATO_11X-COMPARTE",
				"CAS REG_POTATO_12X-FAMILIAR",
				"DOR BLA_TORTIL_04X-CHICO",
				"DOR BLA_TORTIL_11X-COMPARTE",
				"DOR BLA_TORTIL_12X-FAMILIAR",
				"DOR BLA_TORTIL_99X-OTHER",
				"DOR CHI_TORTIL_04X-CHICO",
				"DOR CHI_TORTIL_09X-JUMBO",
				"DOR CHI_TORTIL_11X-COMPARTE",
				"DOR CHI_TORTIL_13X-LLEVA2",
				"DOR CHI_TORTIL_25X-POPULITO",
				"DIN CHI_TORTIL_03X-SHOTS",
				"DIN CHI_TORTIL_04X-CHICO",
				"DIN CHI_TORTIL_09X-JUMBO",
				"DIN CHI_TORTIL_10X-LHPP",
				"DIN CHI_TORTIL_11X-COMPARTE",
				"DIN CHI_TORTIL_12X-FAMILIAR",
				"DIN CHI_TORTIL_14X-FIESTA",
				"DIN CHI_TORTIL_16X-SUPER SIZE",
				"DIN CHI_TORTIL_25P-MULTIPK",
				"DIN CHI_TORTIL_99X-OTHER",
				"DIN FHT_TORTIL_03X-SHOTS",
				"DIN FHT_TORTIL_04X-CHICO",
				"DIN FHT_TORTIL_09X-JUMBO",
				"DIN FHT_TORTIL_10X-LHPP",
				"DIN FHT_TORTIL_11X-COMPARTE",
				"DIN FHT_TORTIL_12X-FAMILIAR",
				"DIN FHT_TORTIL_25P-MULTIPK",
				"DOR FLA_TORTIL_01X-MINI",
				"DOR FLA_TORTIL_02X-JR LINE",
				"DOR FLA_TORTIL_04X-CHICO",
				"DOR FLA_TORTIL_09X-JUMBO",
				"DOR FLA_TORTIL_10P-MULTIPK",
				"DOR FLA_TORTIL_11X-COMPARTE",
				"DOR FLA_TORTIL_12X-FAMILIAR",
				"DOR FLA_TORTIL_13X-LLEVA2",
				"DOR FLA_TORTIL_15X-MEGA",
				"DOR FLA_TORTIL_16X-SUPER SIZE",
				"DOR FLA_TORTIL_25X-POPULITO",
				"DOR HBB_TORTIL_03X-EPP SIZE",
				"DOR INC_TORTIL_04X-CHICO",
				"DOR INC_TORTIL_09X-JUMBO",
				"DOR INC_TORTIL_11X-COMPARTE",
				"DOR INC_TORTIL_12P-MULTIPK",
				"DOR INC_TORTIL_12X-FAMILIAR",
				"DOR INC_TORTIL_25X-POPULITO",
				"DOR INC_TORTIL_99X-OTHER",
				"DOR NCH_TORTIL_01X-MINI",
				"DOR NCH_TORTIL_02X-JR LINE",
				"DOR NCH_TORTIL_03X-EPP SIZE",
				"DOR NCH_TORTIL_04X-CHICO",
				"DOR NCH_TORTIL_06P-MULTIPK",
				"DOR NCH_TORTIL_09P-MULTIPK",
				"DOR NCH_TORTIL_09X-JUMBO",
				"DOR NCH_TORTIL_10P-MULTIPK",
				"DOR NCH_TORTIL_11X-COMPARTE",
				"DOR NCH_TORTIL_12P-MULTIPK",
				"DOR NCH_TORTIL_12X-FAMILIAR",
				"DOR NCH_TORTIL_13X-LLEVA2",
				"DOR NCH_TORTIL_14X-FIESTA",
				"DOR NCH_TORTIL_15X-MEGA",
				"DOR NCH_TORTIL_16X-SUPER SIZE",
				"DOR NCH_TORTIL_20P-MULTIPK",
				"DOR NCH_TORTIL_25P-MULTIPK",
				"DOR NCH_TORTIL_25X-POPULITO",
				"DOR NCH_TORTIL_27P-MULTIPK",
				"DOR NCH_TORTIL_30P-MULTIPK",
				"DOR NCH_TORTIL_81P-MULTIPK",
				"DOR NCH_TORTIL_99X-OTHER",
				"DOR OTH_TORTIL_01X-MINI",
				"DOR OTH_TORTIL_02X-JR LINE",
				"DOR OTH_TORTIL_03X-EPP SIZE",
				"DOR OTH_TORTIL_04X-CHICO",
				"DOR OTH_TORTIL_09X-JUMBO",
				"DOR OTH_TORTIL_10P-MULTIPK",
				"DOR OTH_TORTIL_10X-LHPP",
				"DOR OTH_TORTIL_11X-COMPARTE",
				"DOR OTH_TORTIL_12X-FAMILIAR",
				"DOR OTH_TORTIL_13X-LLEVA2",
				"DOR OTH_TORTIL_14P-MULTIPK",
				"DOR OTH_TORTIL_15X-MEGA",
				"DOR OTH_TORTIL_16X-SUPER SIZE",
				"DOR OTH_TORTIL_25X-POPULITO",
				"DOR OTH_TORTIL_30P-MULTIPK",
				"DOR OTH_TORTIL_99W-SABRIMAYOREO",
				"DOR OTH_TORTIL_99X-OTHER",
				"DOR PIZ_TORTIL_04X-CHICO",
				"DOR PIZ_TORTIL_09X-JUMBO",
				"DOR PIZ_TORTIL_11X-COMPARTE",
				"DOR PIZ_TORTIL_12X-FAMILIAR",
				"DOR PIZ_TORTIL_13X-LLEVA2",
				"DOR PIZ_TORTIL_25X-POPULITO",
				"DOR POP_TORTIL_11X-COMPARTE",
				"DOR POP_TORTIL_12X-FAMILIAR",
				"DOR RAI_TORTIL_04X-CHICO",
				"DOR RAI_TORTIL_11X-COMPARTE",
				"DOR RAI_TORTIL_13X-LLEVA2",
				"DOR RAI_TORTIL_99X-OTHER",
				"HPY REG_POTATO_16X-SUPER SIZE",
				"LAY REG_POTATO_03X-EPP SIZE",
				"LAY REG_POTATO_09X-JUMBO",
				"LAY REG_POTATO_10X-AMIGOTE",
				"LAY REG_POTATO_11X-COMPARTE",
				"LAY REG_POTATO_12X-FAMILIAR",
				"LAY REG_POTATO_16X-SUPER SIZE",
				"LAY REG_POTATO_25X-POPULITO",
				"CAR SAL_POTATO_04X-CHICO",
				"PIZ REG_TORTIL_04X-CHICO",
				"PIZ REG_TORTIL_07X-MAX",
				"PIZ REG_TORTIL_09X-JUMBO",
				"PIZ REG_TORTIL_12X-FAMILIAR",
				"POP CRN_TORTIL_01X-MINI",
				"POP CRN_TORTIL_07X-MAX",
				"POP CRN_TORTIL_11X-COMPARTE",
				"POP CRN_TORTIL_99X-OTHER",
				"RAN REG_TORTIL_01X-MINI",
				"RAN REG_TORTIL_02X-JR LINE",
				"RAN REG_TORTIL_04X-CHICO",
				"RAN REG_TORTIL_09P-MULTIPK",
				"RAN REG_TORTIL_09X-JUMBO",
				"RAN REG_TORTIL_108P-MULTIPK",
				"RAN REG_TORTIL_10P-MULTIPK",
				"RAN REG_TORTIL_10X-LHPP",
				"RAN REG_TORTIL_11X-COMPARTE",
				"RAN REG_TORTIL_12P-MULTIPK",
				"RAN REG_TORTIL_12X-FAMILIAR",
				"RAN REG_TORTIL_13X-LLEVA2",
				"RAN REG_TORTIL_14X-FIESTA",
				"RAN REG_TORTIL_15X-HPP",
				"RAN REG_TORTIL_99X-OTHER",
				"REC CHI_POTATO_04X-CHICO",
				"REC CHI_POTATO_11X-COMPARTE",
				"REC CHI_POTATO_12X-FAMILIAR",
				"REC CHI_POTATO_15X-HPP",
				"REC FLA_POTATO_01X-MINI",
				"REC FLA_POTATO_04X-CHICO",
				"REC FLA_POTATO_09X-JUMBO",
				"REC FLA_POTATO_10X-AMIGOTE",
				"REC FLA_POTATO_11X-COMPARTE",
				"REC FLA_POTATO_12X-FAMILIAR",
				"REC FLA_POTATO_15X-HPP",
				"REC FLA_POTATO_18X-BOTE GRANDE",
				"REC FLA_POTATO_99X-OTHER",
				"REC JAL_POTATO_04X-CHICO",
				"REC JAL_POTATO_09X-JUMBO",
				"REC JAL_POTATO_10X-AMIGOTE",
				"REC JAL_POTATO_11X-COMPARTE",
				"REC JAL_POTATO_12X-FAMILIAR",
				"REC OTH_POTATO_04X-CHICO",
				"REC OTH_POTATO_09X-JUMBO",
				"REC OTH_POTATO_11X-COMPARTE",
				"REC OTH_POTATO_12X-FAMILIAR",
				"REC OTH_POTATO_99W-SABRIMAYOREO",
				"REC OTH_POTATO_99X-OTHER",
				"REC SAL_POTATO_04X-CHICO",
				"REC SAL_POTATO_09X-JUMBO",
				"REC SAL_POTATO_10X-AMIGOTE",
				"REC SAL_POTATO_11X-COMPARTE",
				"REC SAL_POTATO_12X-FAMILIAR",
				"REC SAL_POTATO_15X-HPP",
				"REC SAL_POTATO_18X-BOTE GRANDE",
				"REC SAL_POTATO_99X-OTHER",
				"RRK DEL_POTATO_12X-FAMILIAR",
				"RRK DEL_POTATO_16X-SUPER SIZE",
				"RUF HBB_POTATO_04X-CHICO",
				"RUF HBB_POTATO_10X-LHPP",
				"RUF MCC_POTATO_04X-CHICO",
				"RUF MCC_POTATO_09X-JUMBO",
				"RUF MCC_POTATO_10P-MULTIPK",
				"RUF MCC_POTATO_10X-AMIGOTE",
				"RUF MCC_POTATO_11X-COMPARTE",
				"RUF MCC_POTATO_12X-FAMILIAR",
				"RUF MCJ_POTATO_04X-CHICO",
				"RUF MCJ_POTATO_10X-AMIGOTE",
				"RUF MCJ_POTATO_11X-COMPARTE",
				"RUF MCJ_POTATO_12X-FAMILIAR",
				"RUF MCO_POTATO_04X-CHICO",
				"RUF MCO_POTATO_09X-JUMBO",
				"RUF MCO_POTATO_10X-AMIGOTE",
				"RUF MCO_POTATO_11X-COMPARTE",
				"RUF MCO_POTATO_12X-FAMILIAR",
				"RUF SNG_POTATO_04X-CHICO",
				"RUF SNG_POTATO_12X-FAMILIAR",
				"RUF SRJ_POTATO_04X-CHICO",
				"RUF SRJ_POTATO_10X-AMIGOTE",
				"RUF SRJ_POTATO_10X-LHPP",
				"RUF SRJ_POTATO_11X-COMPARTE",
				"RUF SRJ_POTATO_12X-FAMILIAR",
				"RUF SRJ_POTATO_99X-OTHER",
				"RUF OTH_POTATO_01X-MINI",
				"RUF OTH_POTATO_02X-JR LINE",
				"RUF OTH_POTATO_04X-CHICO",
				"RUF OTH_POTATO_09X-JUMBO",
				"RUF OTH_POTATO_10X-AMIGOTE",
				"RUF OTH_POTATO_11X-COMPARTE",
				"RUF OTH_POTATO_12X-FAMILIAR",
				"RUF OTH_POTATO_14X-FIESTA",
				"RUF OTH_POTATO_99W-SABRIMAYOREO",
				"RUF OTH_POTATO_99X-OTHER",
				"RUF QUE_POTATO_01X-MINI",
				"RUF QUE_POTATO_02X-JR LINE",
				"RUF QUE_POTATO_03X-EPP SIZE",
				"RUF QUE_POTATO_04X-CHICO",
				"RUF QUE_POTATO_09X-JUMBO",
				"RUF QUE_POTATO_10P-MULTIPK",
				"RUF QUE_POTATO_10X-AMIGOTE",
				"RUF QUE_POTATO_10X-LHPP",
				"RUF QUE_POTATO_11X-COMPARTE",
				"RUF QUE_POTATO_12P-MULTIPK",
				"RUF QUE_POTATO_12X-FAMILIAR",
				"RUF QUE_POTATO_13X-LLEVA2",
				"RUF QUE_POTATO_14X-FIESTA",
				"RUF QUE_POTATO_15X-MEGA",
				"RUF QUE_POTATO_16X-SUPER SIZE",
				"RUF QUE_POTATO_30P-MULTIPK",
				"RUF QUE_POTATO_99W-SABRIMAYOREO",
				"RUF QUE_POTATO_99X-OTHER",
				"RUF SAL_POTATO_01X-MINI",
				"RUF SAL_POTATO_02X-JR LINE",
				"RUF SAL_POTATO_03X-EPP SIZE",
				"RUF SAL_POTATO_04X-CHICO",
				"RUF SAL_POTATO_09X-JUMBO",
				"RUF SAL_POTATO_10P-MULTIPK",
				"RUF SAL_POTATO_10X-AMIGOTE",
				"RUF SAL_POTATO_10X-LHPP",
				"RUF SAL_POTATO_11X-COMPARTE",
				"RUF SAL_POTATO_12P-MULTIPK",
				"RUF SAL_POTATO_12X-FAMILIAR",
				"RUF SAL_POTATO_13X-LLEVA2",
				"RUF SAL_POTATO_15X-MEGA",
				"RUF SAL_POTATO_16X-SUPER SIZE",
				"RUF SAL_POTATO_99X-OTHER",
				"RUF UBC_POTATO_04X-CHICO",
				"RUF UBC_POTATO_09X-JUMBO",
				"RUF UBC_POTATO_11X-COMPARTE",
				"RUF UBC_POTATO_16X-SUPER SIZE",
				"RUF UFH_POTATO_04X-CHICO",
				"RUF UFH_POTATO_09X-JUMBO",
				"RUF UFH_POTATO_10X-LHPP",
				"RUF UFH_POTATO_11X-COMPARTE",
				"RUF UFH_POTATO_12X-FAMILIAR",
				"RUF UFH_POTATO_99X-OTHER",
				"SPC ACI_POTATO_04X-CHICO",
				"SPC ACI_POTATO_09X-JUMBO",
				"SPC ACI_POTATO_10X-AMIGOTE",
				"SPC ADO_POTATO_01X-MINI",
				"SPC ADO_POTATO_02X-JR LINE",
				"SPC ADO_POTATO_04X-CHICO",
				"SPC ADO_POTATO_09X-JUMBO",
				"SPC ADO_POTATO_10P-MULTIPK",
				"SPC ADO_POTATO_10X-AMIGOTE",
				"SPC ADO_POTATO_11X-COMPARTE",
				"SPC ADO_POTATO_12P-MULTIPK",
				"SPC ADO_POTATO_12X-FAMILIAR",
				"SPC ADO_POTATO_13X-LLEVA2",
				"SPC ADO_POTATO_15X-MEGA",
				"SPC BCH_POTATO_04X-CHICO",
				"SPC BCH_POTATO_07X-MAX",
				"SPC BCH_POTATO_10X-AMIGOTE",
				"SPC BCH_POTATO_12X-FAMILIAR",
				"SPC BCH_POTATO_13X-LLEVA2",
				"SPC BCH_POTATO_99W-SABRIMAYOREO",
				"SPC CHA_POTATO_01X-MINI",
				"SPC CHA_POTATO_04X-CHICO",
				"SPC CHA_POTATO_09X-JUMBO",
				"SPC CHA_POTATO_10X-AMIGOTE",
				"SPC CHA_POTATO_12X-FAMILIAR",
				"SPC CHM_POTATO_01X-MINI",
				"SPC CHM_POTATO_04X-CHICO",
				"SPC CHM_POTATO_09X-JUMBO",
				"SPC CHM_POTATO_10X-AMIGOTE",
				"SPC CHO_POTATO_04X-CHICO",
				"SPC CHO_POTATO_09X-JUMBO",
				"SPC CHO_POTATO_10X-AMIGOTE",
				"SPC CYE_POTATO_04X-CHICO",
				"SPC CYE_POTATO_10X-AMIGOTE",
				"SPC CYE_POTATO_11X-COMPARTE",
				"SPC CYE_POTATO_12X-FAMILIAR",
				"SPC CYE_POTATO_13X-LLEVA2",
				"SPC FLA_POTATO_04X-CHICO",
				"SPC FLA_POTATO_09X-JUMBO",
				"SPC FLA_POTATO_10X-AMIGOTE",
				"SPC FLA_POTATO_11X-COMPARTE",
				"SPC FLA_POTATO_12X-FAMILIAR",
				"SPC FLA_POTATO_13X-LLEVA2",
				"SPC FLA_POTATO_15X-MEGA",
				"SPC FLA_POTATO_16X-SUPER SIZE",
				"SPC HAB_POTATO_01X-MINI",
				"SPC HAB_POTATO_04X-CHICO",
				"SPC HAB_POTATO_09X-JUMBO",
				"SPC HAB_POTATO_10X-AMIGOTE",
				"SPC HAB_POTATO_12X-FAMILIAR",
				"SPC HAB_POTATO_13X-LLEVA2",
				"SPC LIM_POTATO_03X-EPP SIZE",
				"SPC LIM_POTATO_04X-CHICO",
				"SPC LIM_POTATO_07X-MAX",
				"SPC LIM_POTATO_09X-JUMBO",
				"SPC LIM_POTATO_10P-MULTIPK",
				"SPC LIM_POTATO_10X-AMIGOTE",
				"SPC LIM_POTATO_11X-COMPARTE",
				"SPC LIM_POTATO_12X-FAMILIAR",
				"SPC LIM_POTATO_15X-MEGA",
				"SAO OTH_POTATO_01X-MINI",
				"SAO OTH_POTATO_04X-CHICO",
				"SAO OTH_POTATO_09X-JUMBO",
				"SAO OTH_POTATO_10X-AMIGOTE",
				"SAO OTH_POTATO_11X-COMPARTE",
				"SAO OTH_POTATO_12X-FAMILIAR",
				"SAO OTH_POTATO_16X-SUPER SIZE",
				"SAO OTH_POTATO_25X-POPULITO",
				"SAO OTH_POTATO_99X-OTHER",
				"SPC OTH_POTATO_01X-MINI",
				"SPC OTH_POTATO_04X-CHICO",
				"SPC OTH_POTATO_05P-MULTIPK",
				"SPC OTH_POTATO_09X-JUMBO",
				"SPC OTH_POTATO_10P-MULTIPK",
				"SPC OTH_POTATO_10X-AMIGOTE",
				"SPC OTH_POTATO_11X-COMPARTE",
				"SPC OTH_POTATO_12X-FAMILIAR",
				"SPC OTH_POTATO_13X-LLEVA2",
				"SPC OTH_POTATO_14X-FIESTA",
				"SPC OTH_POTATO_99W-SABRIMAYOREO",
				"SPC VAR_POTATO_10P-MULTIPK",
				"SPC VAR_POTATO_11X-COMPARTE",
				"SPC VAR_POTATO_30P-MULTIPK",
				"SPC VAR_POTATO_99W-SABRIMAYOREO",
				"SPP CHL_POTATO_01X-MINI",
				"SPP CHL_POTATO_04X-CHICO",
				"SPP CHL_POTATO_07X-MAX",
				"SPP CHL_POTATO_11X-COMPARTE",
				"SPP REG_POTATO_01X-MINI",
				"SPP REG_POTATO_04X-CHICO",
				"SPP REG_POTATO_07X-MAX",
				"SPP REG_POTATO_11X-COMPARTE",
				"SPP REG_POTATO_16X-SUPER SIZE",
				"SPC SAL_POTATO_01X-MINI",
				"SPC SAL_POTATO_02X-JR LINE",
				"SPC SAL_POTATO_03X-EPP SIZE",
				"SPC SAL_POTATO_04X-CHICO",
				"SPC SAL_POTATO_05P-MULTIPK",
				"SPC SAL_POTATO_09X-JUMBO",
				"SPC SAL_POTATO_10P-MULTIPK",
				"SPC SAL_POTATO_10X-AMIGOTE",
				"SPC SAL_POTATO_11X-COMPARTE",
				"SPC SAL_POTATO_12P-MULTIPK",
				"SPC SAL_POTATO_12X-FAMILIAR",
				"SPC SAL_POTATO_13X-LLEVA2",
				"SPC SAL_POTATO_14X-FIESTA",
				"SPC SAL_POTATO_15X-MEGA",
				"SPC SAL_POTATO_16X-SUPER SIZE",
				"SPC SAL_POTATO_25X-POPULITO",
				"SPC SAL_POTATO_30P-MULTIPK",
				"SPC SAL_POTATO_99W-SABRIMAYOREO",
				"SPC SEN_POTATO_10X-AMIGOTE",
				"STX BBQ_POTATO_02X-JR LINE",
				"STX BBQ_POTATO_12X-FAMILIAR",
				"STX BBQ_POTATO_18X-BOTE GRANDE",
				"STX CHE_POTATO_18X-BOTE GRANDE",
				"STX CHE_POTATO_19X-BOTE CHICO",
				"STX ORI_POTATO_18X-BOTE GRANDE",
				"STX ORI_POTATO_19X-BOTE CHICO",
				"STX OTH_POTATO_18X-BOTE GRANDE",
				"STX OTH_POTATO_19X-BOTE CHICO",
				"STX PIZ_POTATO_18X-BOTE GRANDE",
				"STX SCR_POTATO_18X-BOTE GRANDE",
				"STX SCR_POTATO_19X-BOTE CHICO",
				"TOS CRO_TORTIL_04X-CHICO",
				"TOS FLA_TORTIL_04X-CHICO",
				"TOS FLA_TORTIL_09X-JUMBO",
				"TOS FLA_TORTIL_10P-MULTIPK",
				"TOS FLA_TORTIL_10X-LHPP",
				"TOS FLA_TORTIL_11X-COMPARTE",
				"TOS FLA_TORTIL_12X-FAMILIAR",
				"TOS FLA_TORTIL_14X-FIESTA",
				"TOS FLA_TORTIL_15X-MEGA",
				"TOS NCH_TORTIL_04X-CHICO",
				"TOS NCH_TORTIL_09X-JUMBO",
				"TOS NCH_TORTIL_10P-MULTIPK",
				"TOS NCH_TORTIL_10X-LHPP",
				"TOS NCH_TORTIL_11X-COMPARTE",
				"TOS NCH_TORTIL_16X-SUPER SIZE",
				"TOS OTH_TORTIL_04X-CHICO",
				"TOS OTH_TORTIL_10P-MULTIPK",
				"TOS OTH_TORTIL_10X-LHPP",
				"TOS OTH_TORTIL_11X-COMPARTE",
				"TOS OTH_TORTIL_12X-FAMILIAR",
				"TOS OTH_TORTIL_13X-LLEVA2",
				"TOS OTH_TORTIL_16X-SUPER SIZE",
				"TOS PIQ_TORTIL_04X-CHICO",
				"TOS PIQ_TORTIL_11X-COMPARTE",
				"TOS SAL_TORTIL_01X-MINI",
				"TOS SAL_TORTIL_03P-MULTIPK",
				"TOS SAL_TORTIL_04X-CHICO",
				"TOS SAL_TORTIL_09X-JUMBO",
				"TOS SAL_TORTIL_10P-MULTIPK",
				"TOS SAL_TORTIL_11X-COMPARTE",
				"TOS SAL_TORTIL_12X-FAMILIAR",
				"TOS SAL_TORTIL_13X-LLEVA2",
				"TOS SAL_TORTIL_14X-FIESTA",
				"TOS SAL_TORTIL_15X-MEGA",
				"TOS SAL_TORTIL_16X-SUPER SIZE",
				"TOS SAL_TORTIL_24P-TOSTIPACK",
				"TOS SAL_TORTIL_99X-OTHER",
				"TOS ROJ_TORTIL_04X-CHICO",
				"TOS ROJ_TORTIL_10P-MULTIPK",
				"TOS ROJ_TORTIL_11X-COMPARTE",
				"TOS SVE_TORTIL_03P-MULTIPK",
				"TOS SVE_TORTIL_04X-CHICO",
				"TOS SVE_TORTIL_09X-JUMBO",
				"TOS SVE_TORTIL_10P-MULTIPK",
				"TOS SVE_TORTIL_10X-LHPP",
				"TOS SVE_TORTIL_11X-COMPARTE",
				"TOS SVE_TORTIL_12X-FAMILIAR",
				"TOS SVE_TORTIL_13X-LLEVA2",
				"TOS SVE_TORTIL_14X-FIESTA",
				"TOS SVE_TORTIL_16X-SUPER SIZE",
				"TOS SVE_TORTIL_24P-MULTIPK",
				"TOS SVE_TORTIL_24P-TOSTIPACK",
				"TOS SVE_TORTIL_99X-OTHER",
				"TOS TST_TORTIL_09X-JUMBO",
				"TOS TST_TORTIL_11X-COMPARTE",
				"TOS TST_TORTIL_12X-FAMILIAR",
				"TOS TOS_TORTIL_04X-CHICO",
				"TOS TOS_TORTIL_12X-FAMILIAR",
				"TOS TOT_TORTIL_11X-COMPARTE",
				"TOS TOT_TORTIL_12X-FAMILIAR",
			],
		},
		competitor: {
			vendor: [
				"ALARA WHOLEFOODS",
				"BIMBO",
				"BOBS RED MILL",
				"BOKADOS",
				"BOTANAS SOL",
				"BYDSA",
				"COLOMBINA SA",
				"CONAGRA",
				"CUETARA",
				"DONDE",
				"DULCEREL",
				"EL CUERNITO",
				"ESPINOZA",
				"FELIPE GUILLEN",
				"GENERAL MILLS",
				"GOLDEN FOODS",
				"HUGO VICTORIA",
				"IGNACIO MICHEL",
				"KELLOGGS",
				"LA LUPITA",
				"LA MODERNA",
				"MONDELEZ",
				"NATURES PATH FOODS",
				"NESTLE",
				"NISHIKAWA",
				"OTHER VENDOR",
				"PROD VIDA",
				"RIVERA",
				"RIVERO",
				"TOTIS",
				"VERDE VALLE",
				"YUPI",
			],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO", "SAV-TORTILLA"],
			brand: [
				"ANITA NACHOS",
				"AYALA POTATO",
				"BARCEL CHIPOTLES",
				"BARCEL CHIPS",
				"BARCEL DE LA FERIA",
				"BARCEL PAPA FLAT",
				"BARCEL TOREADAS",
				"BARCEL TORTILLA OTHER",
				"BARCEL WAPAS",
				"BETO PAPA",
				"BLANCAS",
				"BOGOS TORTILLA",
				"BOKADOS PAPAS",
				"BOKADOS TORTILLA",
				"BOT DER POTATO",
				"BOT DER TORTILLA",
				"BOTATAS PAPAS",
				"BUFALO NACHOS",
				"BUFALO PAPAS",
				"CACHITOS TORTILLA",
				"CAPED",
				"CARITO",
				"CHARLY PAPAS",
				"CHARRAS",
				"CHECHITOS TORTILLA",
				"CHIHUAHUA TORTILLA",
				"CHIPYDALE TORTILLA",
				"CRUJIMAX NACHOS",
				"CRUJIMAX PAPAS",
				"DE LA",
				"DE MI TIERRA",
				"DESCO POTATO",
				"DESCO TORTILLA",
				"DIANIS",
				"DON SILVIO",
				"DON SIMON",
				"DORIS PAPAS",
				"DULYBOT POTATO",
				"DURAN POTATO",
				"DZ",
				"EL ANGEL POTATO",
				"EL MAICITO",
				"ENCANTO PAPAS",
				"ENCANTO TORTILLA",
				"EXCELL TORTILLA",
				"FCACHURROS POTATO",
				"FIESTA FRITURAS POTATO",
				"FRITURIN POTATO",
				"GAPI",
				"GARCIA",
				"GONAC POTATO",
				"GONAC TORTILLA",
				"GONZALEZ POTATO",
				"GUERRERO POTATO",
				"GUIMORI POTATO BRAND",
				"IGOMEZ TORTILLA",
				"JAVY POT",
				"JHONY",
				"JOMET PAPA",
				"JOMET TORTILLA",
				"JOVI TORTILLA",
				"JULIMOY POTATO",
				"KETTLE",
				"KI SNACKS",
				"KIPI TORTILLA",
				"KOIO PAPAS",
				"KRIS",
				"LA FE TOTOPO RANCHERO",
				"LA LUPITA PAPAS",
				"LA LUPITA TORTILLA",
				"LA MEXICANA PAPAS",
				"LEO PAPAS",
				"LEO TORTILLA",
				"LOS COYOTES POTATO",
				"LOS COYOTES TORTILLA",
				"LOS REYES POTATO",
				"LOS REYES TORTILLA",
				"LOURDES PAPAS",
				"LOVIES TORTILLA",
				"LUZMA TORTILLA",
				"MAMA CONEJA",
				"MANOLITO POTATO",
				"MERZA",
				"MI REINA",
				"MONTERREY",
				"MOY POTATO",
				"MR. SAULO",
				"NACHOS TITO SURTIDO",
				"NUEVO LEON",
				"NUEVO LEON POTATO",
				"OCHOA",
				"OTHER LABEL SAVORY POTATO",
				"OTHER LABEL TORTILLA",
				"OTHER POTATO BRAND",
				"OTHER TORTILLA BRAND",
				"OYUKI RANCHERO",
				"PAPATINAS",
				"PAPATITAS FER",
				"PARIOS CHIPS",
				"PELAYO POTATO",
				"PICOREY POTATO",
				"PIKIS PAPAS",
				"PRINGLES REGULAR",
				"PRINGLES TORTILLA",
				"PROFE",
				"PROPLASTEC",
				"PROVI POTATO",
				"PVL POTATO",
				"PVL TORTILLA",
				"Q PAPAS",
				"RANCHIPS",
				"RANGEL",
				"RAYADITAS",
				"RD PAPAS",
				"REBOTANA POTATO",
				"RICA PAPA",
				"RICOMAIZ",
				"RIVERA PAPAS",
				"ROLIGO POTATO",
				"SOL POTATO",
				"SOL TUBIPAPA",
				"TAKIS",
				"TOLUQUENAS PAPAS",
				"TOSTACHOS",
				"TOSTIDADAS",
				"TOTIS PAPS",
				"TOTIS TORTILLA",
				"VIKINGO PAPAS",
				"VILLA SAN MIGUEL",
				"VIRIDIANA PAPAS",
				"WINNUTS POTATO",
				"WIPO POTATO",
				"WISE CHIPS",
				"YORICA",
				"YUDITH",
				"YUMI",
				"ZORRITOS",
			],
			subBrand: [
				"ANITA NACHO",
				"AYALA REGULAR",
				"BARCEL CHIPOTLES REGULAR",
				"BARCEL CHIPS A LA DIABLA",
				"BARCEL CHIPS ADOBADAS",
				"BARCEL CHIPS CHIPOTLE",
				"BARCEL CHIPS FUEGO",
				"BARCEL CHIPS HABANERO",
				"BARCEL CHIPS JALAPENO",
				"BARCEL CHIPS LIMON",
				"BARCEL CHIPS MIX",
				"BARCEL CHIPS OTHER SUBBRAND",
				"BARCEL CHIPS QUESO",
				"BARCEL CHIPS SAL",
				"BARCEL CHIPS TOREADAS",
				"BARCEL CHIPS VARIETY",
				"BARCEL DE LA FERIA OTHER SUBBRAND",
				"BARCEL PAPA FLAT ADOBO MEXICANO",
				"BARCEL PAPA FLAT FUEGO",
				"BARCEL PAPA FLAT SAL",
				"BARCEL TOREADAS HABANERAS",
				"BARCEL TOREADAS SERRANO",
				"BARCEL TOREADAS TRO PICOSAS",
				"BARCEL TORTILLA OTHER SUBBRAND",
				"BARCEL WAPAS LIMON",
				"BARCEL WAPAS QUESO",
				"BARCEL WAPAS SALSA",
				"BETO PAPA SAL",
				"BLANCAS TOTOPO",
				"BOGOS TORTILLA REGULAR",
				"BOKA ENRE REGULAR",
				"BOKA HUEKOS REGULAR",
				"BOKA TOPITOS GRILL",
				"BOKA TOPITOS JALAPENO",
				"BOKA TOPITOS LINAZA",
				"BOKA TOPITOS OTHER",
				"BOKA TOPITOS PIZZA",
				"BOKA TOPITOS QUESO",
				"BOKA TOPITOS REGULAR",
				"BOKA TOPITOS SALSA VERDE",
				"BOKA TOSTADITA REGULAR",
				"BOKADOS PAPAS REGULAR",
				"BOT DER ENCANTO POTATO",
				"BOT DER REGULAR",
				"BOT DER SALSA",
				"BOTATAS PAPAS ADOBADA",
				"BOTATAS PAPAS ORIGINALES",
				"BUFALO NACHOS CHILE SALVAJE",
				"BUFALO NACHOS QUESO CHILE",
				"BUFALO PAPAS CLASICA SAL",
				"BUFALO PAPAS FUEGO REJILLA",
				"BUFALO PAPAS JALAPENO REJILLA",
				"BUFALO PAPAS MIX",
				"BUFALO PAPAS QUESO REJILLA",
				"BUFALO PAPAS SAL DE MAR",
				"CACHITOS REGULAR TORTILLA",
				"CAPED REGULAR",
				"CARITO NACHO",
				"CHARLY REGULAR",
				"CHARRAS REGULAR",
				"CHECHITOS TORTILLA REGULAR",
				"CHIHUAHUA NACHO",
				"CHIPYDALE RANCHERITOS",
				"CRUJI NACHOS CHARRAS",
				"CRUJIMAX PAPA BRAVO",
				"CRUJIMAX PAPA QUESO",
				"DE LA REGULAR",
				"DE MI TIERRA TOTOPO CON SAL",
				"DESCO BOTANA",
				"DESCO PAPAS SAL DE MAR",
				"DESCO RANCHEVITOS",
				"DESCO TOTOPO",
				"DIANIS P CHILAQUILES",
				"DON SILVIO",
				"DON SIMON REGULAR",
				"DORIS PAPAS REGULAR",
				"DULYBOT PAPA",
				"DURAN PAPAS REGULAR",
				"DZ REGULAR",
				"EL ANGEL POTATO REGULAR",
				"EL MAICITO REGULAR",
				"ENCANTO PAPAS ADOBADAS",
				"ENCANTO PAPAS QUESO",
				"ENCANTO PAPAS REGULAR",
				"ENCANTO TACHOS",
				"EXCELL TORTILLA REGULAR",
				"FCACHURROS PAPAS",
				"FIESTA POTATO REGULAR",
				"FRITURIN PAPAS",
				"GAPI NATURAL",
				"GAPI REGULAR",
				"GARCIA REGULAR",
				"GONAC CHECHI FRANCESA REGULAR",
				"GONAC CHECHI SALSA NEGRA",
				"GONAC CHECHI TORTILLA",
				"GONAC KIUBO CHECHI TOTOPO KIUBO INTENSO",
				"GONAC KIUBO DIPACHOS",
				"GONAC KIUBO PAPA CASERA",
				"GONAC KIUBO PAPA CASERA FUEGO",
				"GONAC KIUBO PAPA CASERA HABANERO",
				"GONAC KIUBO PAPA CASERA MEGA FUEGO",
				"GONAC KIUBO PAPA CASERA MEGA ONDULADAS ZIGZAG QUESO",
				"GONAC KIUBO PAPA CASERA SAL DE MAR",
				"GONAC KIUBO PAPA CASERA SALSA NEGRA",
				"GONAC KIUBO PAPA ONDULADA",
				"GONAC KIUBO PAPAS FUEGO",
				"GONAC KIUBO PAPAS QUESO",
				"GONAC KIUBO PAPAS REGULAR",
				"GONAC KIUBO PAPAS SAL",
				"GONAC KIUBO TOTOPO INTENSO",
				"GONAC KIUBO TOTOPO MEGA EXTREMO",
				"GONAC KIUBO TOTOPO MEGA MEXICANA",
				"GONAC KIUBO TOTOPO MEGA NACHO",
				"GONAC KIUBO TOTOPO REGULAR",
				"GONAC KIUBO TOTOPO SALSA NEGRA",
				"GONAC KIUBO TOTOPO TOSTACOS",
				"GONAC OTHER",
				"GONZALEZ PAPA",
				"GUERRERO PAPAS ORIGINAL",
				"GUIMORI POTATO SUBBRAND",
				"IGOMEZ NACHO",
				"JAVY POTATO",
				"JHONY TOTOPO",
				"JOMET PAPA SAL",
				"JOMET RANCHERITOS",
				"JOVI RANCHERITOS",
				"JULIMOY FUEGO",
				"JULIMOY HABANERO",
				"JULIMOY JALAPENO",
				"JULIMOY LIMON",
				"JULIMOY QUESO",
				"JULIMOY REGULAR",
				"JULIMOY SAL",
				"JULIMOY VARIETY",
				"KETTLE REGULAR",
				"KI SNACKS HABANERO",
				"KI SNACKS MIX",
				"KI SNACKS QUESO",
				"KI SNACKS REGULAR",
				"KI SNACKS SAL",
				"KIPI FUEGO",
				"KIPI REGULAR",
				"KOIO CHILE",
				"KOIO CHIPOTLE",
				"KOIO HABANERO",
				"KOIO QUESO",
				"KRIS TOTOPOS",
				"LA FE TOTOPO RANCHERO DESHIDRATADO",
				"LA LUPITA PAPAS REGULAR",
				"LA LUPITA TOTOPOS",
				"LA MEXICANA PAPAS",
				"LEO PAPAS ADOBADAS",
				"LEO PAPAS ONDULADAS",
				"LEO TORTILLA REGULAR",
				"LOS COYOTES FUEGO",
				"LOS COYOTES HABANERO",
				"LOS COYOTES JALAPENO",
				"LOS COYOTES NACHO",
				"LOS COYOTES NACHO EL REPARO",
				"LOS COYOTES NATURAL",
				"LOS COYOTES PAPA ADOBADA",
				"LOS COYOTES PAPA HABANERAS",
				"LOS COYOTES PAPA MIX",
				"LOS COYOTES PAPA PAPATIAS",
				"LOS COYOTES PAPA RAYADITAS CON QUESO",
				"LOS COYOTES PAPA TAPATIA CASERA",
				"LOS COYOTES QUESO",
				"LOS COYOTES SALSA",
				"LOS REYES NACHOS AL COMAL",
				"LOS REYES PAPA FRITA",
				"LOS REYES TOTOPO NATURAL ENCHILADO",
				"LOURDES REGULAR",
				"LOVIES RANCHERITOS",
				"LUZMA TOTOPO",
				"MAMA CONEJA QUESO",
				"MAMA CONEJA REGULAR",
				"MANOLITO POTATO REGULAR",
				"MERZA PAPAS",
				"MI REINA REGULAR",
				"MONTERREY REGULAR",
				"MOY POTATO REGULAR",
				"MR. SAULO REGULAR",
				"NACHOS TITO VARIETY",
				"NUEVO LEON FRITURAS",
				"NUEVO LEON FRITURAS POTATO",
				"OCHOA TOTOPO",
				"OTHER POTATO SUBBRAND",
				"OTHER SAVORY POTATO",
				"OTHER TORTILLA",
				"OTHER TORTILLA SUBBRAND",
				"OYUKI RANCHERO REGULAR",
				"PAPATINAS CATSUP",
				"PAPATINAS CHILE EN VINAGRE",
				"PAPATINAS CHILIX",
				"PAPATINAS FUEGO",
				"PAPATINAS OTHER SUBBRAND",
				"PAPATITAS",
				"PARIOS CHIPS REGULAR",
				"PELAYO REGULAR",
				"PICOREY PAPA",
				"PIKIS PAPAS NATURAL",
				"PRINGLES BBQ",
				"PRINGLES CHILE Y LIMON",
				"PRINGLES CREMA Y CEBOLLA",
				"PRINGLES EXTRA HOT",
				"PRINGLES JALAPENO",
				"PRINGLES ORIGINAL",
				"PRINGLES OTHER SUBBRAND",
				"PRINGLES PICANTE",
				"PRINGLES PIZZA",
				"PRINGLES QUESO",
				"PRINGLES SPICY QUESO",
				"PRIVATE LABEL POTATO",
				"PRIVATE LABEL TORTILLA",
				"PROFE MIX",
				"PROPLASTEC REGULAR",
				"PROVI POTATO REGULAR",
				"Q PAPAS REGULAR",
				"RANCHIPS SAL",
				"RANGEL TOTOPOS",
				"RAYADITAS REGULAR",
				"RD PAPAS REGULAR",
				"REBOTANA POTATO REGULAR",
				"RICA PAPA NATURAL",
				"RICOMAIZ REGULAR",
				"RIVERA PAPAS REGULAR",
				"ROLIGO REGULAR",
				"SOL POTATO",
				"SOL TUBIPAPA",
				"TAKIS BLUE HEAT",
				"TAKIS COBRA",
				"TAKIS FUEGO",
				"TAKIS HUAKAMOLE",
				"TAKIS LIMON",
				"TAKIS OTHER SUBBRAND",
				"TAKIS REGULAR",
				"TAKIS SALSA BRAVA",
				"TAKIS VERDE",
				"TAKIS ZOMBIE",
				"TOLUQUENAS PAPAS REGULAR",
				"TOSTACHOS REGULAR",
				"TOSTIDADAS REGULAR",
				"TOTIS PAPS ADOBADAS",
				"TOTIS PAPS FRANCESA",
				"TOTIS PAPS HABANERO",
				"TOTIS PAPS HOT CHILI",
				"TOTIS PAPS JALAPENO",
				"TOTIS PAPS ONDULADAS",
				"TOTIS PAPS ONDULADAS QUESO",
				"TOTIS PAPS OTHER",
				"TOTIS PAPS QUESO",
				"TOTIS PAPS SAL",
				"TOTIS PAPS SAL DE MAR",
				"TOTIS PAPS SALSA NEGRA",
				"TOTIS TOTOPOS",
				"VIKINGO PAPAS REGULAR",
				"VILLA SAN MIGUEL TOTOPOS",
				"VIRIDIANA HOJUELA DE PAPA",
				"VIRIDIANA RUFLE PAPA",
				"WINNUTS POTATO REGULAR",
				"WIPO REGULAR",
				"WISE CHIPS HOME STYLE",
				"WISE CHIPS REGULAR",
				"YORICA TOTOPOS",
				"YUDITH TOTOPO",
				"YUMI NATURAL",
				"YUMI QUESO",
				"YUMI RANCHIPS QUESO",
				"YUMI RANCHIPS SAL",
				"YUMI REGULAR",
				"YUMI SAL",
				"ZORRITOS REGULAR",
			],
			packSize: [
				"01X-MINI",
				"02P-MULTIPK",
				"02X-JR LINE",
				"04X-CHICO",
				"07P-MULTIPK",
				"07X-MAX",
				"08P-MULTIPK",
				"08X-REC",
				"10P-MULTIPK",
				"11X-COMPARTE",
				"12X-FAMILIAR",
				"14X-FIESTA",
				"15P-MULTIPK",
				"15X-MEGA",
				"16X-SUPER SIZE",
				"17X-BOTE MEDIANO",
				"18X-BOTE GRANDE",
				"19X-BOTE CHICO",
				"20P-MULTIPK",
				"24P-MULTIPK",
				"25P-MULTIPK",
				"27P-MULTIPK",
				"30P-MULTIPK",
			],
			permutation: [
				"ATIN NAC_TORTIL_16X-SUPER SIZE",
				"AYLP REG_POTATO_15X-MEGA",
				"AYLP REG_POTATO_16X-SUPER SIZE",
				"BCL CHP_TORTIL_04X-CHICO",
				"BCL CHP_TORTIL_07X-MAX",
				"BCL CHP_TORTIL_11X-COMPARTE",
				"BCL CHP_TORTIL_24P-MULTIPK",
				"BCP DIA_POTATO_04X-CHICO",
				"BCP DIA_POTATO_07X-MAX",
				"BCP DIA_POTATO_12X-FAMILIAR",
				"BCP ADO_POTATO_01X-MINI",
				"BCP ADO_POTATO_04X-CHICO",
				"BCP ADO_POTATO_11X-COMPARTE",
				"BCP ADO_POTATO_12X-FAMILIAR",
				"BCP ADO_POTATO_15X-MEGA",
				"BCP CCH_POTATO_04X-CHICO",
				"BCP CCH_POTATO_11X-COMPARTE",
				"BCP CCH_POTATO_12X-FAMILIAR",
				"BCP FUE_POTATO_01X-MINI",
				"BCP FUE_POTATO_02X-JR LINE",
				"BCP FUE_POTATO_04X-CHICO",
				"BCP FUE_POTATO_07X-MAX",
				"BCP FUE_POTATO_11X-COMPARTE",
				"BCP FUE_POTATO_12X-FAMILIAR",
				"BCP FUE_POTATO_14X-FIESTA",
				"BCP FUE_POTATO_15X-MEGA",
				"BCP FUE_POTATO_25P-MULTIPK",
				"BCP HAB_POTATO_01X-MINI",
				"BCP HAB_POTATO_04X-CHICO",
				"BCP HAB_POTATO_12X-FAMILIAR",
				"BCP JAL_POTATO_01X-MINI",
				"BCP JAL_POTATO_04X-CHICO",
				"BCP JAL_POTATO_07X-MAX",
				"BCP JAL_POTATO_11X-COMPARTE",
				"BCP JAL_POTATO_12X-FAMILIAR",
				"BCP JAL_POTATO_14X-FIESTA",
				"BCP JAL_POTATO_15X-MEGA",
				"BCP JAL_POTATO_16X-SUPER SIZE",
				"BCP JAL_POTATO_25P-MULTIPK",
				"BCP VER_POTATO_01X-MINI",
				"BCP VER_POTATO_02X-JR LINE",
				"BCP VER_POTATO_04X-CHICO",
				"BCP VER_POTATO_07X-MAX",
				"BCP VER_POTATO_11X-COMPARTE",
				"BCP VER_POTATO_12X-FAMILIAR",
				"BCP VER_POTATO_14X-FIESTA",
				"BCP MIX_POTATO_01X-MINI",
				"BCP MIX_POTATO_15X-MEGA",
				"BCP MIX_POTATO_16X-SUPER SIZE",
				"BCP OTH_POTATO_04X-CHICO",
				"BCP OTH_POTATO_07X-MAX",
				"BCP OTH_POTATO_11X-COMPARTE",
				"BCP OTH_POTATO_12X-FAMILIAR",
				"BCP OTH_POTATO_15X-MEGA",
				"BCP OTH_POTATO_16X-SUPER SIZE",
				"BCP QUE_POTATO_01X-MINI",
				"BCP QUE_POTATO_04X-CHICO",
				"BCP QUE_POTATO_11X-COMPARTE",
				"BCP QUE_POTATO_12X-FAMILIAR",
				"BCP QUE_POTATO_15X-MEGA",
				"BCP SAL_POTATO_01X-MINI",
				"BCP SAL_POTATO_02X-JR LINE",
				"BCP SAL_POTATO_04X-CHICO",
				"BCP SAL_POTATO_07X-MAX",
				"BCP SAL_POTATO_11X-COMPARTE",
				"BCP SAL_POTATO_12X-FAMILIAR",
				"BCP SAL_POTATO_14X-FIESTA",
				"BCP SAL_POTATO_15X-MEGA",
				"BCP SAL_POTATO_16X-SUPER SIZE",
				"BCP SAL_POTATO_25P-MULTIPK",
				"BCP SAL_POTATO_30P-MULTIPK",
				"BCP TOR_POTATO_07X-MAX",
				"BCP TOR_POTATO_11X-COMPARTE",
				"BCP TOR_POTATO_12X-FAMILIAR",
				"BCP TOR_POTATO_15X-MEGA",
				"BCP VTY_POTATO_25P-MULTIPK",
				"BDL FOTH_POTATO_11X-COMPARTE",
				"BPA FAM_POTATO_04X-CHICO",
				"BPA FAM_POTATO_07X-MAX",
				"BPA FAM_POTATO_12X-FAMILIAR",
				"BPA FAM_POTATO_14X-FIESTA",
				"BPA FAM_POTATO_15X-MEGA",
				"BPA FAF_POTATO_04X-CHICO",
				"BPA FAF_POTATO_07X-MAX",
				"BPA FAF_POTATO_11X-COMPARTE",
				"BPA FAF_POTATO_12X-FAMILIAR",
				"BPA FAF_POTATO_14X-FIESTA",
				"BPA FAS_POTATO_04X-CHICO",
				"BPA FAS_POTATO_07X-MAX",
				"BPA FAS_POTATO_12X-FAMILIAR",
				"BPA FAS_POTATO_14X-FIESTA",
				"BTO HAB_POTATO_04X-CHICO",
				"BTO HAB_POTATO_07X-MAX",
				"BTO HAB_POTATO_11X-COMPARTE",
				"BTO HAB_POTATO_12X-FAMILIAR",
				"BTO HAB_POTATO_15X-MEGA",
				"BTO SER_POTATO_12X-FAMILIAR",
				"BTO TRO_POTATO_04X-CHICO",
				"BATL OTH_TORTIL_11X-COMPARTE",
				"BATL OTH_TORTIL_12X-FAMILIAR",
				"BATL OTH_TORTIL_14X-FIESTA",
				"BATL OTH_TORTIL_15X-MEGA",
				"BATL OTH_TORTIL_16X-SUPER SIZE",
				"BWA LIM_POTATO_01X-MINI",
				"BWA LIM_POTATO_04X-CHICO",
				"BWA LIM_POTATO_07X-MAX",
				"BWA LIM_POTATO_12X-FAMILIAR",
				"BWA QUE_POTATO_01X-MINI",
				"BWA QUE_POTATO_04X-CHICO",
				"BWA QUE_POTATO_07X-MAX",
				"BWA QUE_POTATO_11X-COMPARTE",
				"BWA QUE_POTATO_12X-FAMILIAR",
				"BWA QUE_POTATO_15X-MEGA",
				"BWA SLS_POTATO_01X-MINI",
				"BWA SLS_POTATO_04X-CHICO",
				"BWA SLS_POTATO_07X-MAX",
				"BWA SLS_POTATO_12X-FAMILIAR",
				"BWA SLS_POTATO_15X-MEGA",
				"BTPA SAL_POTATO_16X-SUPER SIZE",
				"BLS TOT_TORTIL_02X-JR LINE",
				"BLS TOT_TORTIL_07X-MAX",
				"BLS TOT_TORTIL_14X-FIESTA",
				"BLS TOT_TORTIL_15X-MEGA",
				"BGTL REG_TORTIL_04X-CHICO",
				"BGTL REG_TORTIL_12X-FAMILIAR",
				"BGTL REG_TORTIL_15X-MEGA",
				"BKTL ENR_TORTIL_01X-MINI",
				"BKTL ENR_TORTIL_02X-JR LINE",
				"BKTL ENR_TORTIL_04X-CHICO",
				"BKTL ENR_TORTIL_07X-MAX",
				"BKTL ENR_TORTIL_11X-COMPARTE",
				"BKTL HKS_TORTIL_01X-MINI",
				"BKTL HKS_TORTIL_02X-JR LINE",
				"BKTL HKS_TORTIL_04X-CHICO",
				"BKTL HKS_TORTIL_07X-MAX",
				"BKTL HKS_TORTIL_11X-COMPARTE",
				"BKTL GLL_TORTIL_01X-MINI",
				"BKTL GLL_TORTIL_04X-CHICO",
				"BKTL GLL_TORTIL_12X-FAMILIAR",
				"BKTL GLL_TORTIL_15X-MEGA",
				"BKTL GLL_TORTIL_16X-SUPER SIZE",
				"BKTL JAL_TORTIL_01X-MINI",
				"BKTL JAL_TORTIL_04X-CHICO",
				"BKTL JAL_TORTIL_11X-COMPARTE",
				"BKTL JAL_TORTIL_12X-FAMILIAR",
				"BKTL JAL_TORTIL_15X-MEGA",
				"BKTL LNZ_TORTIL_04X-CHICO",
				"BKTL LNZ_TORTIL_12X-FAMILIAR",
				"BKTL OTH_TORTIL_04X-CHICO",
				"BKTL OTH_TORTIL_12X-FAMILIAR",
				"BKTL OTH_TORTIL_15X-MEGA",
				"BKTL OTH_TORTIL_16X-SUPER SIZE",
				"BKTL PZZ_TORTIL_04X-CHICO",
				"BKTL PZZ_TORTIL_12X-FAMILIAR",
				"BKTL QSO_TORTIL_02X-JR LINE",
				"BKTL QSO_TORTIL_04X-CHICO",
				"BKTL QSO_TORTIL_07X-MAX",
				"BKTL QSO_TORTIL_11X-COMPARTE",
				"BKTL QSO_TORTIL_12X-FAMILIAR",
				"BKTL QSO_TORTIL_15X-MEGA",
				"BKTL REG_TORTIL_02X-JR LINE",
				"BKTL REG_TORTIL_04X-CHICO",
				"BKTL REG_TORTIL_11X-COMPARTE",
				"BKTL REG_TORTIL_14X-FIESTA",
				"BKTL REG_TORTIL_16X-SUPER SIZE",
				"BKTL SVD_TORTIL_02X-JR LINE",
				"BKTL SVD_TORTIL_04X-CHICO",
				"BKTL SVD_TORTIL_07X-MAX",
				"BKTL SVD_TORTIL_11X-COMPARTE",
				"BKTL SVD_TORTIL_12X-FAMILIAR",
				"BKTL SVD_TORTIL_14X-FIESTA",
				"BKTL SVD_TORTIL_15X-MEGA",
				"BKTL TSD_TORTIL_16X-SUPER SIZE",
				"BKPS REG_POTATO_04X-CHICO",
				"BKPS REG_POTATO_07X-MAX",
				"BKPS REG_POTATO_11X-COMPARTE",
				"BKPS REG_POTATO_12X-FAMILIAR",
				"BDEP ENC_POTATO_16X-SUPER SIZE",
				"BDEP REG_POTATO_14X-FIESTA",
				"BDEP REG_POTATO_16X-SUPER SIZE",
				"BDET SAL_TORTIL_02X-JR LINE",
				"BDET SAL_TORTIL_04X-CHICO",
				"BDET SAL_TORTIL_07X-MAX",
				"BDET SAL_TORTIL_10P-MULTIPK",
				"BDET SAL_TORTIL_12X-FAMILIAR",
				"BDET SAL_TORTIL_15X-MEGA",
				"BDET SAL_TORTIL_16X-SUPER SIZE",
				"BDET SAL_TORTIL_30P-MULTIPK",
				"BTTP PAD_POTATO_16X-SUPER SIZE",
				"BTTP POR_POTATO_16X-SUPER SIZE",
				"BULN NCS_TORTIL_04X-CHICO",
				"BULN NQC_TORTIL_04X-CHICO",
				"BUL PCS_POTATO_04X-CHICO",
				"BUL PFR_POTATO_04X-CHICO",
				"BUL PJR_POTATO_04X-CHICO",
				"BUL PAM_POTATO_30P-MULTIPK",
				"BUL PQR_POTATO_04X-CHICO",
				"BUL PSM_POTATO_04X-CHICO",
				"CCI TOR_TORTIL_10P-MULTIPK",
				"CCI TOR_TORTIL_20P-MULTIPK",
				"CPD REG_POTATO_04X-CHICO",
				"CPD REG_POTATO_14X-FIESTA",
				"CPD REG_POTATO_16X-SUPER SIZE",
				"CRO NAC_TORTIL_16X-SUPER SIZE",
				"CHY REG_POTATO_16X-SUPER SIZE",
				"CAR REG_TORTIL_04X-CHICO",
				"CAR REG_TORTIL_12X-FAMILIAR",
				"CAR REG_TORTIL_14X-FIESTA",
				"CAR REG_TORTIL_15X-MEGA",
				"CAR REG_TORTIL_16X-SUPER SIZE",
				"CHTL REG_TORTIL_01X-MINI",
				"CUUT NAC_TORTIL_16X-SUPER SIZE",
				"CTLA RAN_TORTIL_10P-MULTIPK",
				"CTLA RAN_TORTIL_12X-FAMILIAR",
				"CTLA RAN_TORTIL_16X-SUPER SIZE",
				"CJI NAC_TORTIL_12X-FAMILIAR",
				"CJI NAC_TORTIL_16X-SUPER SIZE",
				"CJIP PAB_POTATO_04X-CHICO",
				"CJIP PAQ_POTATO_04X-CHICO",
				"CJIP PAQ_POTATO_07X-MAX",
				"CJIP PAQ_POTATO_12X-FAMILIAR",
				"CJIP PAQ_POTATO_16X-SUPER SIZE",
				"DLA REG_POTATO_11X-COMPARTE",
				"DLA REG_POTATO_16X-SUPER SIZE",
				"DMI TTS_TORTIL_14X-FIESTA",
				"DEPO BOT_POTATO_11X-COMPARTE",
				"DEPO BOT_POTATO_12X-FAMILIAR",
				"DEPO BOT_POTATO_14X-FIESTA",
				"DEPO BOT_POTATO_16X-SUPER SIZE",
				"DEPO PSM_POTATO_11X-COMPARTE",
				"DEPO PSM_POTATO_16X-SUPER SIZE",
				"DETL RAN_TORTIL_07X-MAX",
				"DETL RAN_TORTIL_12X-FAMILIAR",
				"DETL TOT_TORTIL_12X-FAMILIAR",
				"DETL TOT_TORTIL_14X-FIESTA",
				"DETL TOT_TORTIL_15X-MEGA",
				"DETL TOT_TORTIL_16X-SUPER SIZE",
				"DNS CHI_TORTIL_12X-FAMILIAR",
				"DSV REG_POTATO_04X-CHICO",
				"DSV REG_POTATO_07X-MAX",
				"DSI REG_TORTIL_15X-MEGA",
				"DRSP REG_POTATO_07X-MAX",
				"DRSP REG_POTATO_11X-COMPARTE",
				"DRSP REG_POTATO_12X-FAMILIAR",
				"DYPT PAP_POTATO_16X-SUPER SIZE",
				"DUPO PAS_POTATO_16X-SUPER SIZE",
				"DZ REG_TORTIL_15X-MEGA",
				"ELAP REG_POTATO_11X-COMPARTE",
				"ELAP REG_POTATO_14X-FIESTA",
				"EMC REG_TORTIL_14X-FIESTA",
				"ENPS ADO_POTATO_02X-JR LINE",
				"ENPS ADO_POTATO_04X-CHICO",
				"ENPS ADO_POTATO_07X-MAX",
				"ENPS ADO_POTATO_12X-FAMILIAR",
				"ENPS QSO_POTATO_02X-JR LINE",
				"ENPS QSO_POTATO_04X-CHICO",
				"ENPS QSO_POTATO_07X-MAX",
				"ENPS REG_POTATO_01X-MINI",
				"ENPS REG_POTATO_02X-JR LINE",
				"ENPS REG_POTATO_04X-CHICO",
				"ENPS REG_POTATO_07X-MAX",
				"ENPS REG_POTATO_12X-FAMILIAR",
				"ENTL TAC_TORTIL_01X-MINI",
				"ENTL TAC_TORTIL_02X-JR LINE",
				"ENTL TAC_TORTIL_04X-CHICO",
				"ENTL TAC_TORTIL_07X-MAX",
				"ENTL TAC_TORTIL_11X-COMPARTE",
				"ENTL TAC_TORTIL_12X-FAMILIAR",
				"ENTL TAC_TORTIL_16X-SUPER SIZE",
				"EXCN TOR_TORTIL_02X-JR LINE",
				"EXCN TOR_TORTIL_15X-MEGA",
				"FCPS PPS_POTATO_16X-SUPER SIZE",
				"FIE POT_POTATO_16X-SUPER SIZE",
				"FIPA REG_POTATO_14X-FIESTA",
				"FIPA REG_POTATO_16X-SUPER SIZE",
				"GPI NAT_POTATO_16X-SUPER SIZE",
				"GPI REG_POTATO_16X-SUPER SIZE",
				"GCA REG_POTATO_16X-SUPER SIZE",
				"GOPO FRE_POTATO_01X-MINI",
				"GOPO FRE_POTATO_14X-FIESTA",
				"GOPO SNE_POTATO_14X-FIESTA",
				"GOTL TRT_TORTIL_01X-MINI",
				"GOTL TRT_TORTIL_02X-JR LINE",
				"GOTL TRT_TORTIL_04X-CHICO",
				"GOTL TRT_TORTIL_07X-MAX",
				"GOTL TRT_TORTIL_11X-COMPARTE",
				"GOTL TRT_TORTIL_12X-FAMILIAR",
				"GOTL TRT_TORTIL_15X-MEGA",
				"GOTL TRT_TORTIL_24P-MULTIPK",
				"GOTL TRT_TORTIL_25P-MULTIPK",
				"GOTL CTK_TORTIL_15X-MEGA",
				"GOTL CTK_TORTIL_16X-SUPER SIZE",
				"GOTL DIP_TORTIL_02X-JR LINE",
				"GOTL DIP_TORTIL_14X-FIESTA",
				"GOPO KPC_POTATO_01X-MINI",
				"GOPO KPC_POTATO_02X-JR LINE",
				"GOPO KPC_POTATO_04X-CHICO",
				"GOPO KPC_POTATO_14X-FIESTA",
				"GOPO PCF_POTATO_01X-MINI",
				"GOPO PCF_POTATO_02X-JR LINE",
				"GOPO PCF_POTATO_04X-CHICO",
				"GOPO PCF_POTATO_14X-FIESTA",
				"GOPO PCF_POTATO_15X-MEGA",
				"GOPO PCF_POTATO_16X-SUPER SIZE",
				"GOPO PCH_POTATO_14X-FIESTA",
				"GOPO PCH_POTATO_15X-MEGA",
				"GOPO PCH_POTATO_16X-SUPER SIZE",
				"GOPO PCM_POTATO_07X-MAX",
				"GOPO PCM_POTATO_10P-MULTIPK",
				"GOPO PCM_POTATO_11X-COMPARTE",
				"GOPO PCM_POTATO_12X-FAMILIAR",
				"GOPO PCM_POTATO_15X-MEGA",
				"GOPO PCM_POTATO_16X-SUPER SIZE",
				"GOPO PCO_POTATO_02X-JR LINE",
				"GOPO PCO_POTATO_04X-CHICO",
				"GOPO PCO_POTATO_10P-MULTIPK",
				"GOPO PCO_POTATO_14X-FIESTA",
				"GOPO PCO_POTATO_15X-MEGA",
				"GOPO PCO_POTATO_16X-SUPER SIZE",
				"GOPO PCS_POTATO_02X-JR LINE",
				"GOPO PCS_POTATO_04X-CHICO",
				"GOPO PCS_POTATO_10P-MULTIPK",
				"GOPO PCS_POTATO_11X-COMPARTE",
				"GOPO PCS_POTATO_14X-FIESTA",
				"GOPO PCS_POTATO_15X-MEGA",
				"GOPO PCS_POTATO_16X-SUPER SIZE",
				"GOPO PCS_POTATO_30P-MULTIPK",
				"GOPO PCN_POTATO_02X-JR LINE",
				"GOPO PCN_POTATO_04X-CHICO",
				"GOPO PCN_POTATO_10P-MULTIPK",
				"GOPO PCN_POTATO_14X-FIESTA",
				"GOPO PCN_POTATO_15X-MEGA",
				"GOPO PCN_POTATO_16X-SUPER SIZE",
				"GOPO PON_POTATO_02X-JR LINE",
				"GOPO PON_POTATO_04X-CHICO",
				"GOPO PON_POTATO_14X-FIESTA",
				"GOPO PFU_POTATO_11X-COMPARTE",
				"GOPO PFU_POTATO_14X-FIESTA",
				"GOPO PFU_POTATO_16X-SUPER SIZE",
				"GOPO PQU_POTATO_02X-JR LINE",
				"GOPO PQU_POTATO_14X-FIESTA",
				"GOPO PQU_POTATO_16X-SUPER SIZE",
				"GOPO PRE_POTATO_02X-JR LINE",
				"GOPO PRE_POTATO_04X-CHICO",
				"GOPO PRE_POTATO_11X-COMPARTE",
				"GOPO PRE_POTATO_15X-MEGA",
				"GOPO PRE_POTATO_16X-SUPER SIZE",
				"GOPO PSA_POTATO_02X-JR LINE",
				"GOPO PSA_POTATO_04X-CHICO",
				"GOPO PSA_POTATO_07X-MAX",
				"GOPO PSA_POTATO_14X-FIESTA",
				"GOPO PSA_POTATO_15X-MEGA",
				"GOPO PSA_POTATO_16X-SUPER SIZE",
				"GOTL TIN_TORTIL_02X-JR LINE",
				"GOTL TIN_TORTIL_10P-MULTIPK",
				"GOTL TIN_TORTIL_15X-MEGA",
				"GOTL TME_TORTIL_02X-JR LINE",
				"GOTL TME_TORTIL_10P-MULTIPK",
				"GOTL TME_TORTIL_14X-FIESTA",
				"GOTL TME_TORTIL_16X-SUPER SIZE",
				"GOTL TME_TORTIL_30P-MULTIPK",
				"GOTL TMM_TORTIL_02X-JR LINE",
				"GOTL TMM_TORTIL_15X-MEGA",
				"GOTL TMN_TORTIL_01X-MINI",
				"GOTL TMN_TORTIL_02X-JR LINE",
				"GOTL TMN_TORTIL_04X-CHICO",
				"GOTL TMN_TORTIL_07X-MAX",
				"GOTL TMN_TORTIL_10P-MULTIPK",
				"GOTL TMN_TORTIL_11X-COMPARTE",
				"GOTL TMN_TORTIL_14X-FIESTA",
				"GOTL TMN_TORTIL_16X-SUPER SIZE",
				"GOTL TRE_TORTIL_01X-MINI",
				"GOTL TRE_TORTIL_02X-JR LINE",
				"GOTL TRE_TORTIL_04X-CHICO",
				"GOTL TRE_TORTIL_14X-FIESTA",
				"GOTL TRE_TORTIL_16X-SUPER SIZE",
				"GOTL TSN_TORTIL_02X-JR LINE",
				"GOTL TSN_TORTIL_04X-CHICO",
				"GOTL TSN_TORTIL_07X-MAX",
				"GOTL TSN_TORTIL_10P-MULTIPK",
				"GOTL TSN_TORTIL_11X-COMPARTE",
				"GOTL TSN_TORTIL_14X-FIESTA",
				"GOTL TSN_TORTIL_15X-MEGA",
				"GOTL TTO_TORTIL_04X-CHICO",
				"GOPO OTH_POTATO_02X-JR LINE",
				"GOPO OTH_POTATO_07X-MAX",
				"GOPO OTH_POTATO_11X-COMPARTE",
				"GOPO OTH_POTATO_15X-MEGA",
				"GOPO OTH_POTATO_16X-SUPER SIZE",
				"GNZP PAP_POTATO_11X-COMPARTE",
				"GNZP PAP_POTATO_14X-FIESTA",
				"GUEP PAP_POTATO_11X-COMPARTE",
				"GUEP PAP_POTATO_16X-SUPER SIZE",
				"GIMN POT_POTATO_16X-SUPER SIZE",
				"IGOT NAC_TORTIL_15X-MEGA",
				"JVYV POT_POTATO_16X-SUPER SIZE",
				"JHO TOT_TORTIL_12X-FAMILIAR",
				"JOM PAP_POTATO_07X-MAX",
				"JOT RAN_TORTIL_11X-COMPARTE",
				"JTLA RAN_TORTIL_04X-CHICO",
				"JTLA RAN_TORTIL_12X-FAMILIAR",
				"JULP FGO_POTATO_11X-COMPARTE",
				"JULP HAB_POTATO_11X-COMPARTE",
				"JULP JAL_POTATO_11X-COMPARTE",
				"JULP LMN_POTATO_11X-COMPARTE",
				"JULP QSO_POTATO_11X-COMPARTE",
				"JULP REG_POTATO_07X-MAX",
				"JULP REG_POTATO_11X-COMPARTE",
				"JULP REG_POTATO_12X-FAMILIAR",
				"JULP REG_POTATO_16X-SUPER SIZE",
				"JULP REG_POTATO_25P-MULTIPK",
				"JULP SAL_POTATO_04X-CHICO",
				"JULP SAL_POTATO_11X-COMPARTE",
				"JULP VTY_POTATO_16X-SUPER SIZE",
				"KTT REG_POTATO_04X-CHICO",
				"KTT REG_POTATO_11X-COMPARTE",
				"KTT REG_POTATO_12X-FAMILIAR",
				"KTT REG_POTATO_14X-FIESTA",
				"KSN HAB_POTATO_07X-MAX",
				"KSN HAB_POTATO_11X-COMPARTE",
				"KSN MIX_POTATO_11X-COMPARTE",
				"KSN QUE_POTATO_07X-MAX",
				"KSN QUE_POTATO_11X-COMPARTE",
				"KSN REG_POTATO_07X-MAX",
				"KSN REG_POTATO_11X-COMPARTE",
				"KSN SAL_POTATO_07X-MAX",
				"KSN SAL_POTATO_11X-COMPARTE",
				"KSN SAL_POTATO_16X-SUPER SIZE",
				"KIPT FUE_TORTIL_12X-FAMILIAR",
				"KIPT REG_TORTIL_01X-MINI",
				"KIPT REG_TORTIL_12X-FAMILIAR",
				"KOIP CHL_POTATO_16X-SUPER SIZE",
				"KOIP CHP_POTATO_16X-SUPER SIZE",
				"KOIP HAB_POTATO_16X-SUPER SIZE",
				"KOIP QUE_POTATO_16X-SUPER SIZE",
				"KRT TOT_TORTIL_16X-SUPER SIZE",
				"LFT RAN_TORTIL_11X-COMPARTE",
				"LUPS REG_POTATO_01X-MINI",
				"LUPS REG_POTATO_04X-CHICO",
				"LUPS REG_POTATO_07X-MAX",
				"LUPS REG_POTATO_12X-FAMILIAR",
				"LUPS REG_POTATO_15X-MEGA",
				"LUPS REG_POTATO_16X-SUPER SIZE",
				"LUTL TOP_TORTIL_04X-CHICO",
				"LUTL TOP_TORTIL_12X-FAMILIAR",
				"LUTL TOP_TORTIL_14X-FIESTA",
				"LPPS PPS_POTATO_11X-COMPARTE",
				"LPPS PPS_POTATO_15X-MEGA",
				"LPPS PPS_POTATO_16X-SUPER SIZE",
				"LEPS ADO_POTATO_02X-JR LINE",
				"LEPS ADO_POTATO_04X-CHICO",
				"LEPS ADO_POTATO_07X-MAX",
				"LEPS ADO_POTATO_14X-FIESTA",
				"LEPS OND_POTATO_01X-MINI",
				"LEPS OND_POTATO_02X-JR LINE",
				"LEPS OND_POTATO_04X-CHICO",
				"LEPS OND_POTATO_07X-MAX",
				"LEPS OND_POTATO_12X-FAMILIAR",
				"LETL REG_TORTIL_01X-MINI",
				"LETL REG_TORTIL_02X-JR LINE",
				"LETL REG_TORTIL_04X-CHICO",
				"LETL REG_TORTIL_07X-MAX",
				"LETL REG_TORTIL_11X-COMPARTE",
				"LETL REG_TORTIL_12X-FAMILIAR",
				"LETL REG_TORTIL_16X-SUPER SIZE",
				"LCO FUE_POTATO_02X-JR LINE",
				"LCO FUE_POTATO_04X-CHICO",
				"LCO FUE_POTATO_08P-MULTIPK",
				"LCO FUE_POTATO_10P-MULTIPK",
				"LCO FUE_POTATO_15X-MEGA",
				"LCO FUE_POTATO_20P-MULTIPK",
				"LCO HAB_POTATO_02X-JR LINE",
				"LCO HAB_POTATO_04X-CHICO",
				"LCO HAB_POTATO_20P-MULTIPK",
				"LCO JAL_POTATO_04X-CHICO",
				"LCO JAL_POTATO_15X-MEGA",
				"LCOT NAC_TORTIL_02X-JR LINE",
				"LCOT NAC_TORTIL_04X-CHICO",
				"LCOT NAC_TORTIL_07X-MAX",
				"LCOT NAC_TORTIL_15X-MEGA",
				"LCOT NAC_TORTIL_16X-SUPER SIZE",
				"LCOT NER_TORTIL_01X-MINI",
				"LCOT NER_TORTIL_02X-JR LINE",
				"LCOT NER_TORTIL_04X-CHICO",
				"LCOT NER_TORTIL_07X-MAX",
				"LCOT NER_TORTIL_08P-MULTIPK",
				"LCOT NER_TORTIL_14X-FIESTA",
				"LCOT NER_TORTIL_15X-MEGA",
				"LCOT NER_TORTIL_16X-SUPER SIZE",
				"LCO NAT_POTATO_04X-CHICO",
				"LCO NAT_POTATO_07X-MAX",
				"LCO NAT_POTATO_16X-SUPER SIZE",
				"LCO PAD_POTATO_02X-JR LINE",
				"LCO PAD_POTATO_04X-CHICO",
				"LCO PAD_POTATO_07X-MAX",
				"LCO PAD_POTATO_08P-MULTIPK",
				"LCO PAD_POTATO_10P-MULTIPK",
				"LCO PAD_POTATO_11X-COMPARTE",
				"LCO PAD_POTATO_12X-FAMILIAR",
				"LCO PAD_POTATO_14X-FIESTA",
				"LCO PAD_POTATO_15X-MEGA",
				"LCO PAD_POTATO_16X-SUPER SIZE",
				"LCO PAD_POTATO_20P-MULTIPK",
				"LCO PHA_POTATO_02X-JR LINE",
				"LCO PHA_POTATO_04X-CHICO",
				"LCO PHA_POTATO_15X-MEGA",
				"LCO PMI_POTATO_02X-JR LINE",
				"LCO PMI_POTATO_20P-MULTIPK",
				"LCO PMI_POTATO_30P-MULTIPK",
				"LCO PPA_POTATO_02X-JR LINE",
				"LCO PPA_POTATO_04X-CHICO",
				"LCO PPA_POTATO_07X-MAX",
				"LCO PPA_POTATO_14X-FIESTA",
				"LCO PPA_POTATO_15X-MEGA",
				"LCO PPA_POTATO_20P-MULTIPK",
				"LCO PRQ_POTATO_02X-JR LINE",
				"LCO PRQ_POTATO_04X-CHICO",
				"LCO PRQ_POTATO_15X-MEGA",
				"LCO PRQ_POTATO_16X-SUPER SIZE",
				"LCO PTC_POTATO_01X-MINI",
				"LCO PTC_POTATO_02X-JR LINE",
				"LCO PTC_POTATO_04X-CHICO",
				"LCO PTC_POTATO_11X-COMPARTE",
				"LCO PTC_POTATO_15X-MEGA",
				"LCO PTC_POTATO_16X-SUPER SIZE",
				"LCO QUE_POTATO_02X-JR LINE",
				"LCO QUE_POTATO_04X-CHICO",
				"LCO QUE_POTATO_16X-SUPER SIZE",
				"LCO QUE_POTATO_20P-MULTIPK",
				"LCOT SLS_TORTIL_02X-JR LINE",
				"LCOT SLS_TORTIL_15X-MEGA",
				"LCOT SLS_TORTIL_16X-SUPER SIZE",
				"RYTL NAC_TORTIL_14X-FIESTA",
				"RYSP PFR_POTATO_11X-COMPARTE",
				"RYSP PFR_POTATO_12X-FAMILIAR",
				"RYTL TNE_TORTIL_12X-FAMILIAR",
				"RYTL TNE_TORTIL_14X-FIESTA",
				"LDSP REG_POTATO_04X-CHICO",
				"LDSP REG_POTATO_11X-COMPARTE",
				"LOVT RAN_TORTIL_07X-MAX",
				"LOVT RAN_TORTIL_12X-FAMILIAR",
				"LUZT TOT_TORTIL_12X-FAMILIAR",
				"LUZT TOT_TORTIL_16X-SUPER SIZE",
				"MCJ QUE_POTATO_16X-SUPER SIZE",
				"MCJ REG_POTATO_16X-SUPER SIZE",
				"MNPO REG_POTATO_04X-CHICO",
				"MNPO REG_POTATO_11X-COMPARTE",
				"MNPO REG_POTATO_16X-SUPER SIZE",
				"MZA PAP_POTATO_12X-FAMILIAR",
				"MIR REG_TORTIL_15X-MEGA",
				"MEY REG_TORTIL_12X-FAMILIAR",
				"MOPA REG_POTATO_11X-COMPARTE",
				"MOPA REG_POTATO_25P-MULTIPK",
				"MSU REG_POTATO_16X-SUPER SIZE",
				"NTIS VTY_TORTIL_16X-SUPER SIZE",
				"NVO FRI_TORTIL_12X-FAMILIAR",
				"NVO FRI_TORTIL_15X-MEGA",
				"NVO POT_POTATO_12X-FAMILIAR",
				"OCH TOT_TORTIL_07X-MAX",
				"OCH TOT_TORTIL_12X-FAMILIAR",
				"OCH TOT_TORTIL_15X-MEGA",
				"OCH TOT_TORTIL_16X-SUPER SIZE",
				"OPTO SUB_POTATO_01X-MINI",
				"OPTO SUB_POTATO_02X-JR LINE",
				"OPTO SUB_POTATO_04X-CHICO",
				"OPTO SUB_POTATO_07X-MAX",
				"OPTO SUB_POTATO_11X-COMPARTE",
				"OPTO SUB_POTATO_12X-FAMILIAR",
				"OPTO SUB_POTATO_14X-FIESTA",
				"OPTO SUB_POTATO_15X-MEGA",
				"OPTO SUB_POTATO_16X-SUPER SIZE",
				"OTH SAP_POTATO_01X-MINI",
				"OTH SAP_POTATO_02P-MULTIPK",
				"OTH SAP_POTATO_02X-JR LINE",
				"OTH SAP_POTATO_04X-CHICO",
				"OTH SAP_POTATO_07X-MAX",
				"OTH SAP_POTATO_11X-COMPARTE",
				"OTH SAP_POTATO_12X-FAMILIAR",
				"OTH SAP_POTATO_14X-FIESTA",
				"OTH SAP_POTATO_16X-SUPER SIZE",
				"OTH TOR_TORTIL_01X-MINI",
				"OTH TOR_TORTIL_02X-JR LINE",
				"OTH TOR_TORTIL_04X-CHICO",
				"OTH TOR_TORTIL_07X-MAX",
				"OTH TOR_TORTIL_11X-COMPARTE",
				"OTH TOR_TORTIL_12X-FAMILIAR",
				"OTH TOR_TORTIL_14X-FIESTA",
				"OTH TOR_TORTIL_15X-MEGA",
				"OTH TOR_TORTIL_16X-SUPER SIZE",
				"OTLA REG_TORTIL_01X-MINI",
				"OTLA REG_TORTIL_02X-JR LINE",
				"OTLA REG_TORTIL_04X-CHICO",
				"OTLA REG_TORTIL_07X-MAX",
				"OTLA REG_TORTIL_11X-COMPARTE",
				"OTLA REG_TORTIL_12X-FAMILIAR",
				"OTLA REG_TORTIL_14X-FIESTA",
				"OTLA REG_TORTIL_15X-MEGA",
				"OTLA REG_TORTIL_16X-SUPER SIZE",
				"OYUR REG_TORTIL_25P-MULTIPK",
				"PAP CAT_POTATO_02X-JR LINE",
				"PAP VIN_POTATO_02X-JR LINE",
				"PAP VIN_POTATO_04X-CHICO",
				"PAP VIN_POTATO_07X-MAX",
				"PAP VIN_POTATO_12X-FAMILIAR",
				"PAP VIN_POTATO_14X-FIESTA",
				"PAP CHX_POTATO_02X-JR LINE",
				"PAP CHX_POTATO_04X-CHICO",
				"PAP CHX_POTATO_07X-MAX",
				"PAP CHX_POTATO_12X-FAMILIAR",
				"PAP CHX_POTATO_14X-FIESTA",
				"PAP CHX_POTATO_15X-MEGA",
				"PAP CHX_POTATO_16X-SUPER SIZE",
				"PAP FUE_POTATO_02X-JR LINE",
				"PAP FUE_POTATO_04X-CHICO",
				"PAP OTH_POTATO_07X-MAX",
				"PAP OTH_POTATO_12X-FAMILIAR",
				"PTI REG_POTATO_11X-COMPARTE",
				"PSH REG_POTATO_04X-CHICO",
				"PSH REG_POTATO_07X-MAX",
				"PSH REG_POTATO_11X-COMPARTE",
				"PSH REG_POTATO_12X-FAMILIAR",
				"PYOP REG_POTATO_04X-CHICO",
				"PYOP REG_POTATO_11X-COMPARTE",
				"PCYP PAP_POTATO_04X-CHICO",
				"PCYP PAP_POTATO_12X-FAMILIAR",
				"PKPS NAT_POTATO_11X-COMPARTE",
				"PKPS NAT_POTATO_16X-SUPER SIZE",
				"PRI BBQ_POTATO_17X-BOTE MEDIANO",
				"PRI BBQ_POTATO_18X-BOTE GRANDE",
				"PRI BBQ_POTATO_19X-BOTE CHICO",
				"PRI CHI_POTATO_17X-BOTE MEDIANO",
				"PRI CHI_POTATO_19X-BOTE CHICO",
				"PRI CRE_POTATO_17X-BOTE MEDIANO",
				"PRI CRE_POTATO_18X-BOTE GRANDE",
				"PRI CRE_POTATO_19X-BOTE CHICO",
				"PRI XHT_POTATO_17X-BOTE MEDIANO",
				"PRI XHT_POTATO_18X-BOTE GRANDE",
				"PRI XHT_POTATO_19X-BOTE CHICO",
				"PRI JAL_POTATO_17X-BOTE MEDIANO",
				"PRI JAL_POTATO_18X-BOTE GRANDE",
				"PRI ORI_POTATO_17X-BOTE MEDIANO",
				"PRI ORI_POTATO_19X-BOTE CHICO",
				"PRI ORI_POTATO_18X-BOTE GRANDE",
				"PRI ORI_POTATO_19X-BOTE CHICO",
				"PRI OTH_POTATO_17X-BOTE MEDIANO",
				"PRI OTH_POTATO_18X-BOTE GRANDE",
				"PRI OTH_POTATO_19X-BOTE CHICO",
				"PRI PIC_POTATO_17X-BOTE MEDIANO",
				"PRI PIC_POTATO_18X-BOTE GRANDE",
				"PRI PIZ_POTATO_17X-BOTE MEDIANO",
				"PRI PIZ_POTATO_18X-BOTE GRANDE",
				"PRI PIZ_POTATO_19X-BOTE CHICO",
				"PRI QUE_POTATO_17X-BOTE MEDIANO",
				"PRI QUE_POTATO_18X-BOTE GRANDE",
				"PRI QUE_POTATO_19X-BOTE CHICO",
				"PRIT SQU_TORTIL_11X-COMPARTE",
				"PVL POT_POTATO_01X-MINI",
				"PVL POT_POTATO_02X-JR LINE",
				"PVL POT_POTATO_04X-CHICO",
				"PVL POT_POTATO_07X-MAX",
				"PVL POT_POTATO_11X-COMPARTE",
				"PVL POT_POTATO_12X-FAMILIAR",
				"PVL POT_POTATO_14X-FIESTA",
				"PVL POT_POTATO_15X-MEGA",
				"PVL POT_POTATO_16X-SUPER SIZE",
				"PVL TOR_TORTIL_04X-CHICO",
				"PVL TOR_TORTIL_07X-MAX",
				"PVL TOR_TORTIL_11X-COMPARTE",
				"PVL TOR_TORTIL_12X-FAMILIAR",
				"PVL TOR_TORTIL_14X-FIESTA",
				"PVL TOR_TORTIL_15X-MEGA",
				"PRF MIX_POTATO_16X-SUPER SIZE",
				"PLS REG_POTATO_16X-SUPER SIZE",
				"PRPO REG_POTATO_02X-JR LINE",
				"PRPO REG_POTATO_04X-CHICO",
				"PRPO REG_POTATO_07X-MAX",
				"PRPO REG_POTATO_11X-COMPARTE",
				"PRPO REG_POTATO_12X-FAMILIAR",
				"PRPO REG_POTATO_14X-FIESTA",
				"PRPO REG_POTATO_16X-SUPER SIZE",
				"QPA REG_POTATO_12X-FAMILIAR",
				"RCP SAL_POTATO_16X-SUPER SIZE",
				"RNG TOT_TORTIL_12X-FAMILIAR",
				"RAY REG_POTATO_04X-CHICO",
				"RPS REG_POTATO_16X-SUPER SIZE",
				"BTAP REG_POTATO_11X-COMPARTE",
				"RPP NAT_POTATO_02X-JR LINE",
				"RPP NAT_POTATO_07X-MAX",
				"RPP NAT_POTATO_12X-FAMILIAR",
				"RPP NAT_POTATO_16X-SUPER SIZE",
				"RCZ REG_TORTIL_12X-FAMILIAR",
				"RCZ REG_TORTIL_14X-FIESTA",
				"RRPS REG_POTATO_16X-SUPER SIZE",
				"RGOP REG_POTATO_20P-MULTIPK",
				"SOL POT_POTATO_01X-MINI",
				"SOL POT_POTATO_04X-CHICO",
				"SOL POT_POTATO_07X-MAX",
				"SOL POT_POTATO_16X-SUPER SIZE",
				"SOPO TPP_POTATO_07X-MAX",
				"SOPO TPP_POTATO_11X-COMPARTE",
				"SOPO TPP_POTATO_14X-FIESTA",
				"TAK BHT_TORTIL_04X-CHICO",
				"TAK BHT_TORTIL_07X-MAX",
				"TAK BHT_TORTIL_08X-REC",
				"TAK BHT_TORTIL_10P-MULTIPK",
				"TAK BHT_TORTIL_11X-COMPARTE",
				"TAK BHT_TORTIL_12X-FAMILIAR",
				"TAK BHT_TORTIL_14X-FIESTA",
				"TAK BHT_TORTIL_16X-SUPER SIZE",
				"TAK COB_TORTIL_02X-JR LINE",
				"TAK COB_TORTIL_04X-CHICO",
				"TAK COB_TORTIL_08X-REC",
				"TAK COB_TORTIL_11X-COMPARTE",
				"TAK COB_TORTIL_12X-FAMILIAR",
				"TAK COB_TORTIL_16X-SUPER SIZE",
				"TAK COB_TORTIL_25P-MULTIPK",
				"TAK FUE_TORTIL_01X-MINI",
				"TAK FUE_TORTIL_02X-JR LINE",
				"TAK FUE_TORTIL_04X-CHICO",
				"TAK FUE_TORTIL_07X-MAX",
				"TAK FUE_TORTIL_08X-REC",
				"TAK FUE_TORTIL_10P-MULTIPK",
				"TAK FUE_TORTIL_11X-COMPARTE",
				"TAK FUE_TORTIL_12X-FAMILIAR",
				"TAK FUE_TORTIL_14X-FIESTA",
				"TAK FUE_TORTIL_16X-SUPER SIZE",
				"TAK FUE_TORTIL_25P-MULTIPK",
				"TAK FUE_TORTIL_27P-MULTIPK",
				"TAK HUA_TORTIL_02X-JR LINE",
				"TAK HUA_TORTIL_04X-CHICO",
				"TAK HUA_TORTIL_07X-MAX",
				"TAK HUA_TORTIL_12X-FAMILIAR",
				"TAK LIM_TORTIL_02X-JR LINE",
				"TAK LIM_TORTIL_04X-CHICO",
				"TAK LIM_TORTIL_07X-MAX",
				"TAK LIM_TORTIL_08X-REC",
				"TAK LIM_TORTIL_11X-COMPARTE",
				"TAK LIM_TORTIL_12X-FAMILIAR",
				"TAK LIM_TORTIL_16X-SUPER SIZE",
				"TAK OTH_TORTIL_02X-JR LINE",
				"TAK OTH_TORTIL_04X-CHICO",
				"TAK OTH_TORTIL_07P-MULTIPK",
				"TAK OTH_TORTIL_07X-MAX",
				"TAK OTH_TORTIL_08X-REC",
				"TAK OTH_TORTIL_11X-COMPARTE",
				"TAK OTH_TORTIL_12X-FAMILIAR",
				"TAK OTH_TORTIL_14X-FIESTA",
				"TAK OTH_TORTIL_15P-MULTIPK",
				"TAK OTH_TORTIL_15X-MEGA",
				"TAK OTH_TORTIL_16X-SUPER SIZE",
				"TAK OTH_TORTIL_25P-MULTIPK",
				"TAK REG_TORTIL_04X-CHICO",
				"TAK REG_TORTIL_16X-SUPER SIZE",
				"TAK REG_TORTIL_25P-MULTIPK",
				"TAK BRV_TORTIL_02X-JR LINE",
				"TAK BRV_TORTIL_04X-CHICO",
				"TAK BRV_TORTIL_07X-MAX",
				"TAK BRV_TORTIL_11X-COMPARTE",
				"TAK BRV_TORTIL_12X-FAMILIAR",
				"TAK VER_TORTIL_02X-JR LINE",
				"TAK VER_TORTIL_04X-CHICO",
				"TAK VER_TORTIL_07X-MAX",
				"TAK VER_TORTIL_08X-REC",
				"TAK VER_TORTIL_11X-COMPARTE",
				"TAK VER_TORTIL_12X-FAMILIAR",
				"TAK VER_TORTIL_25P-MULTIPK",
				"TAK VER_TORTIL_27P-MULTIPK",
				"TAK ZOM_TORTIL_02X-JR LINE",
				"TAK ZOM_TORTIL_04X-CHICO",
				"TAK ZOM_TORTIL_08X-REC",
				"TAK ZOM_TORTIL_25P-MULTIPK",
				"TLPS PPS_POTATO_04X-CHICO",
				"TLPS PPS_POTATO_07X-MAX",
				"TLPS PPS_POTATO_11X-COMPARTE",
				"TLPS PPS_POTATO_12X-FAMILIAR",
				"TCH REG_TORTIL_04X-CHICO",
				"TCH REG_TORTIL_07X-MAX",
				"TCH REG_TORTIL_11X-COMPARTE",
				"TCH REG_TORTIL_12X-FAMILIAR",
				"TCH REG_TORTIL_15X-MEGA",
				"TCH REG_TORTIL_16X-SUPER SIZE",
				"TDS REG_TORTIL_01X-MINI",
				"TDS REG_TORTIL_12X-FAMILIAR",
				"TDS REG_TORTIL_16X-SUPER SIZE",
				"TOP ADO_POTATO_01X-MINI",
				"TOP ADO_POTATO_02X-JR LINE",
				"TOP ADO_POTATO_04X-CHICO",
				"TOP ADO_POTATO_07X-MAX",
				"TOP ADO_POTATO_11X-COMPARTE",
				"TOP ADO_POTATO_12X-FAMILIAR",
				"TOP ADO_POTATO_20P-MULTIPK",
				"TOP FRN_POTATO_24P-MULTIPK",
				"TOP HAB_POTATO_01X-MINI",
				"TOP HAB_POTATO_02X-JR LINE",
				"TOP HAB_POTATO_04X-CHICO",
				"TOP HAB_POTATO_12X-FAMILIAR",
				"TOP HCH_POTATO_01X-MINI",
				"TOP HCH_POTATO_04X-CHICO",
				"TOP HCH_POTATO_07X-MAX",
				"TOP HCH_POTATO_12X-FAMILIAR",
				"TOP HCH_POTATO_15X-MEGA",
				"TOP HCH_POTATO_20P-MULTIPK",
				"TOP HCH_POTATO_24P-MULTIPK",
				"TOP PPJ_POTATO_12X-FAMILIAR",
				"TOP OND_POTATO_04X-CHICO",
				"TOP OND_POTATO_12X-FAMILIAR",
				"TOP OQU_POTATO_04X-CHICO",
				"TOP OQU_POTATO_10P-MULTIPK",
				"TOP OQU_POTATO_12X-FAMILIAR",
				"TOP OTH_POTATO_07X-MAX",
				"TOP OTH_POTATO_12X-FAMILIAR",
				"TOP OTH_POTATO_16X-SUPER SIZE",
				"TOP PPQ_POTATO_04X-CHICO",
				"TOP PPQ_POTATO_12X-FAMILIAR",
				"TOP PPS_POTATO_01X-MINI",
				"TOP PPS_POTATO_02X-JR LINE",
				"TOP PPS_POTATO_04X-CHICO",
				"TOP PPS_POTATO_07X-MAX",
				"TOP PPS_POTATO_10P-MULTIPK",
				"TOP PPS_POTATO_11X-COMPARTE",
				"TOP PPS_POTATO_12X-FAMILIAR",
				"TOP PPS_POTATO_20P-MULTIPK",
				"TOP SMR_POTATO_12X-FAMILIAR",
				"TOP PSN_POTATO_12X-FAMILIAR",
				"TOP PSN_POTATO_16X-SUPER SIZE",
				"TOP PSN_POTATO_25P-MULTIPK",
				"TOTL TTP_TORTIL_01X-MINI",
				"TOTL TTP_TORTIL_02X-JR LINE",
				"TOTL TTP_TORTIL_04X-CHICO",
				"TOTL TTP_TORTIL_07X-MAX",
				"TOTL TTP_TORTIL_10P-MULTIPK",
				"TOTL TTP_TORTIL_11X-COMPARTE",
				"TOTL TTP_TORTIL_12X-FAMILIAR",
				"TOTL TTP_TORTIL_14X-FIESTA",
				"TOTL TTP_TORTIL_15X-MEGA",
				"TOTL TTP_TORTIL_16X-SUPER SIZE",
				"VKOP REG_POTATO_12X-FAMILIAR",
				"VKOP REG_POTATO_14X-FIESTA",
				"VSM TOT_TORTIL_12X-FAMILIAR",
				"VIRP HDP_POTATO_16X-SUPER SIZE",
				"VIRP RPA_POTATO_16X-SUPER SIZE",
				"WNNC POT_POTATO_15X-MEGA",
				"WNNC POT_POTATO_16X-SUPER SIZE",
				"WIP REG_POTATO_14X-FIESTA",
				"WIP REG_POTATO_16X-SUPER SIZE",
				"WSCH HMS_POTATO_04X-CHICO",
				"WSCH HMS_POTATO_12X-FAMILIAR",
				"WSCH REG_POTATO_01X-MINI",
				"WSCH REG_POTATO_04X-CHICO",
				"WSCH REG_POTATO_12X-FAMILIAR",
				"YRC TOT_TORTIL_11X-COMPARTE",
				"YRC TOT_TORTIL_15X-MEGA",
				"YRC TOT_TORTIL_16X-SUPER SIZE",
				"YUD TOT_TORTIL_16X-SUPER SIZE",
				"YUM NAT_POTATO_11X-COMPARTE",
				"YUM QUE_POTATO_04X-CHICO",
				"YUM QUE_POTATO_11X-COMPARTE",
				"YUM RQU_POTATO_11X-COMPARTE",
				"YUM RQU_POTATO_16X-SUPER SIZE",
				"YUM RSA_POTATO_11X-COMPARTE",
				"YUM RSA_POTATO_16X-SUPER SIZE",
				"YUM REG_POTATO_04X-CHICO",
				"YUM REG_POTATO_11X-COMPARTE",
				"YUM REG_POTATO_16X-SUPER SIZE",
				"YUM SAL_POTATO_04X-CHICO",
				"YUM SAL_POTATO_07X-MAX",
				"ZOR REG_TORTIL_02X-JR LINE",
				"ZOR REG_TORTIL_20P-MULTIPK",
				"ZOR REG_TORTIL_25P-MULTIPK",
			],
		},
		startDate: "01/01/2024",
		endDate: "03/29/2024",
		variable1: "SOM",
		SOMMeasure: "Category",
		SOMGroup: "Volume",
		APIUnits: "Units",
	},
	{
		country: "GUATEMALA",
		geoLevel: ["COUNTRY-TTL"],
		geoLevel2: ["TTL GUATEMALA-ALL"],
		channel: ["ALL OUTLET"],
		periodView: ["na"],
		viewShareby: "valueSales",
		indexes: ["20", "10"],
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-CORN"],
			brand: [
				"CHURRITO FIESTA",
				"CHURRUMAIS REGULAR",
				"CONCHITAS",
				"DORADITAS REGULAR",
				"FRITOS REGULAR",
				"FRITOS TWIST",
				"KACHITOS REGULAR",
				"SABRIMINIS CHARROS",
				"TORTRIX CHAPIMIX",
				"TORTRIX CORN",
				"TORTRIX TWIST",
			],
			subBrand: [
				"CHURRITO FIESTA",
				"CHURRUMAIS REGULAR",
				"CONCHITAS",
				"DORADITAS REGULAR",
				"FRITOS REGULAR",
				"FRITOS TWIST",
				"KACHITOS REGULAR",
				"SABRIMINIS CHARROS",
				"TORTRIX CHAPIMIX",
				"TORTRIX CORN",
				"TORTRIX TWIST",
			],
			packSize: ["01P-PACK", "01X-SS XSMALL", "02X-SS SMALL", "03X-SS MEDIUM", "04P-PACK", "04X-SS LARGE", "05X-SS XLARGE", "06X-SS XXLARGE"],
			vendor: ["PEPSICO"],
			permutation: [
				"CHR FTA_CORN_01X-SS XSMALL",
				"CHR FTA_CORN_03X-SS MEDIUM",
				"CHU REG_CORN_03X-SS MEDIUM",
				"CON HTS_CORN_03X-SS MEDIUM",
				"DTA REG_CORN_01X-SS XSMALL",
				"DTA REG_CORN_02X-SS SMALL",
				"DTA REG_CORN_04X-SS LARGE",
				"FRI REG_CORN_02X-SS SMALL",
				"FRI REG_CORN_03X-SS MEDIUM",
				"FRI REG_CORN_04X-SS LARGE",
				"FRI REG_CORN_05X-SS XLARGE",
				"FRI TWI_CORN_02X-SS SMALL",
				"KAC REG_CORN_01X-SS XSMALL",
				"KAC REG_CORN_02X-SS SMALL",
				"KAC REG_CORN_04X-SS LARGE",
				"KAC REG_CORN_05X-SS XLARGE",
				"SBM CHR_CORN_01X-SS XSMALL",
				"TTX MIX_CORN_01X-SS XSMALL",
				"TTX MIX_CORN_06X-SS XXLARGE",
				"TOR CRN_CORN_01P-PACK",
				"TOR CRN_CORN_01X-SS XSMALL",
				"TOR CRN_CORN_02X-SS SMALL",
				"TOR CRN_CORN_03X-SS MEDIUM",
				"TOR CRN_CORN_04P-PACK",
				"TOR CRN_CORN_04X-SS LARGE",
				"TOR CRN_CORN_05X-SS XLARGE",
				"TOR CRN_CORN_06X-SS XXLARGE",
				"TTX TWI_CORN_01X-SS XSMALL",
				"TTX TWI_CORN_04X-SS LARGE",
			],
		},
		competitor: {
			vendor: [
				"13 CEREALES",
				"ABIL",
				"AGROINDUSTRIAS ALBAY",
				"ALCSA",
				"ALIMENTOS CAMPESTRES",
				"ALIMENTOS COOK",
				"ALIMENTOS DIANA",
				"ALIMENTOS H & H",
				"ALIMENTOS JACKS",
				"ALIMENTOS SELECTOS",
				"AMERICAN POP CORN",
				"AMERICAN ROLAND FOOD",
				"ARCOR",
				"BEAR NAKED",
				"BLUE DIAMOND GROWERS",
				"BOCADELI",
				"BRIO",
				"BROHDERS",
				"CAMPBELLS",
				"CASA BAZZINI",
				"CASADEMONT",
				"CENTRAL DE ALIMENTOS",
				"CHOCOLATES BEST",
				"COLOMBINA",
				"CONSERVAS LA COSTEÑA",
				"CORPORACION MULTI INVERSIONES",
				"D CARLOS",
				"DANCAKE",
				"DAVIDS",
				"DEL CARMEN",
				"DELIRICE",
				"DERIVADOS DE LECHE LA ESMERALDA",
				"DESOBRY",
				"DIAMOND FOODS",
				"DINANT",
				"DISTRIBUIDORA DE ALIMENTOS Y BEBIDAS GUACHA",
				"DOMITICA",
				"ELLEDI",
				"ENLACES GUATEMALA",
				"EXCELLENT",
				"FABRICA DE GALLETAS GULLON",
				"FERRERO",
				"FORNO BONOMI",
				"GENERAL MILLS",
				"GLOBAL BRANDS",
				"GRUPO BIMBO",
				"GRUPO CAROZZI",
				"GRUPO INDUSTRIAL ALIMENTICIO",
				"GRUPO VIDA",
				"HEBBE",
				"IBERIA FOODS",
				"IDEALSA",
				"INA",
				"INDUSTRIAS HELIOS",
				"INDUSTRIAS MAFAM",
				"INDUSTRIAS UNIDAS",
				"JOHN B SANFILIPPO & SON",
				"KAMOJA",
				"KELLOGGS",
				"KELSEN",
				"KETTLE FOODS",
				"KIDDYLICIOUS",
				"LA CHILERA",
				"LA FLOR BURGALESA",
				"LINAVENA",
				"LOTUS BAKERY",
				"MADA",
				"MAIZITOS",
				"MANITOBA",
				"MOLINO DI FERRO",
				"MOM BRANDS",
				"MONDELEZ",
				"NATURAL HONEY",
				"NATURASOL",
				"NESTLE",
				"NURTURE",
				"NUTRIEVO",
				"ORGRAN",
				"ORIGINAL GOURMET FOOD",
				"OTHER VENDOR",
				"OTROS",
				"PAN SINAI",
				"PANDURATA ALIMENTOS",
				"PASQUIER",
				"PICONAS",
				"POP TIME",
				"POST FOODS",
				"PRODECA",
				"PRODUCTOS ALIMENTICIOS CENTROAMERICANOS",
				"PRODUCTOS ALIMENTICIOS CYP",
				"PRODUCTOS HIDROTERRA",
				"PRODUCTOS KITTY",
				"PRODUCTOS RIQUISIMA",
				"PROSNACKS",
				"QUILALI",
				"SALUVITA",
				"SAN ENRIQUE",
				"SEEBERGER",
				"SNYDERS LANCE",
				"TAIFELDS",
				"TELUMA",
				"THE HAIN CELESTIAL",
				"TIA ANGELITA",
				"TORTI EXPRESS",
				"TOSTADAS CHARRAS",
				"TOTIS",
				"TROPICAL NUT & FRUIT",
				"TUTO SNACK",
				"UNILEVER",
				"WALMART",
				"WEAVER POPCORN",
				"YOGI SUPERFOODS",
				"YUMUS",
			],
			category: ["SAV-SALTY"],
			segment: ["SAV-CORN"],
			brand: [
				"BOCADELI CORN REGULAR",
				"DIANA CORN CHIPS",
				"GENERAL MILLS CORN CHIPS",
				"HERRS CORN REGULAR",
				"KITTY CORN CHIP REGULAR",
				"OTHER SUBBRAND CORN",
				"RICKS CORN REGULAR",
				"TORRADITOS REGULAR",
				"TORTICHIPS REGULAR",
				"TOTIS CORN REGULAR",
				"TRIKOS CORN REGULAR",
				"TRONIX CORN REGULAR",
			],
			subBrand: [
				"BOCADELI CORN REGULAR",
				"DIANA CORN CHIPS",
				"GENERAL MILLS CORN CHIPS",
				"HERRS CORN REGULAR",
				"KITTY CORN CHIP REGULAR",
				"OTHER SUBBRAND CORN",
				"RICKS CORN REGULAR",
				"TORRADITOS REGULAR",
				"TORTICHIPS REGULAR",
				"TOTIS CORN REGULAR",
				"TRIKOS CORN REGULAR",
				"TRONIX CORN REGULAR",
			],
			packSize: ["01X-SS XSMALL", "02X-SS SMALL", "03X-SS MEDIUM", "04X-SS LARGE", "05X-SS XLARGE", "06X-SS XXLARGE", "09X-XXXLARGE"],
			permutation: [
				"BCD COR_CORN_01X-SS XSMALL",
				"BCD COR_CORN_04X-SS LARGE",
				"BCD COR_CORN_05X-SS XLARGE",
				"DAN CRN_CORN_01X-SS XSMALL",
				"DAN CRN_CORN_02X-SS SMALL",
				"DAN CRN_CORN_04X-SS LARGE",
				"GNR CRN_CORN_05X-SS XLARGE",
				"HRS COR_CORN_05X-SS XLARGE",
				"KTY CRN_CORN_04X-SS LARGE",
				"KTY CRN_CORN_05X-SS XLARGE",
				"OSB CRN_CORN_01X-SS XSMALL",
				"OSB CRN_CORN_02X-SS SMALL",
				"OSB CRN_CORN_03X-SS MEDIUM",
				"OSB CRN_CORN_04X-SS LARGE",
				"OSB CRN_CORN_05X-SS XLARGE",
				"OSB CRN_CORN_06X-SS XXLARGE",
				"RKS COR_CORN_01X-SS XSMALL",
				"RKS COR_CORN_04X-SS LARGE",
				"RKS COR_CORN_09X-XXXLARGE",
				"TRR REG_CORN_01X-SS XSMALL",
				"TRR REG_CORN_02X-SS SMALL",
				"TRR REG_CORN_03X-SS MEDIUM",
				"TRR REG_CORN_04X-SS LARGE",
				"TCP REG_CORN_01X-SS XSMALL",
				"TTS COR_CORN_01X-SS XSMALL",
				"TTS COR_CORN_02X-SS SMALL",
				"TKS COR_CORN_05X-SS XLARGE",
				"TKS COR_CORN_09X-XXXLARGE",
				"TNX CRN_CORN_01X-SS XSMALL",
			],
		},
		startDate: "01/01/2024",
		endDate: "03/29/2024",
		variable1: "SOM",
		SOMMeasure: "Category",
		SOMGroup: "Volume",
		APIUnits: "Units",
	},
	{
		country: "COLOMBIA",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL COLOMBIA-DTS"],
		channel: ["DTS"],
		periodView: ["na"],
		viewShareby: "volumeSales",
		indexes: ["20", "10"],
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-SALTY MIX", "SAV-TORTILLA"],
			brand: [
				"DE TODITO MANANITA",
				"DE TODITO REGULAR",
				"DORITOS DINAMITA",
				"DORITOS DIPPAS",
				"DORITOS REGULAR",
				"FRITO LAY PROMOTIONAL MIX",
				"FRITO LAY PROMOTIONAL TORTILLA",
				"JACKS SALTY MIX",
				"TOSTITOS OTHER SUBBRAND",
			],
			subBrand: [
				"DE TODITO MANANITA",
				"DE TODITO REGULAR",
				"DORITOS DINAMITA",
				"DORITOS DIPPAS",
				"DORITOS REGULAR",
				"FRITO LAY PROMOTIONAL MIX",
				"FRITO LAY PROMOTIONAL TORTILLA",
				"JACKS SALTY MIX",
				"TOSTITOS OTHER SUBBRAND",
			],
			packSize: ["01X-MINI", "04X-SMALL", "07X-MEDIUM", "11X-BIG", "12X-EXTRA BIG"],
			vendor: ["PEPSICO"],
			permutation: [
				"DET MAN_SALTY MIX_01X-MINI",
				"DET MAN_SALTY MIX_04X-SMALL",
				"DET MAN_SALTY MIX_12X-EXTRA BIG",
				"DET REG_SALTY MIX_01X-MINI",
				"DET REG_SALTY MIX_04X-SMALL",
				"DET REG_SALTY MIX_07X-MEDIUM",
				"DET REG_SALTY MIX_11X-BIG",
				"DET REG_SALTY MIX_12X-EXTRA BIG",
				"DOR DIN_TORTIL_01X-MINI",
				"DOR DIN_TORTIL_04X-SMALL",
				"DOR DIN_TORTIL_11X-BIG",
				"DOR DIP_TORTIL_11X-BIG",
				"DOR REG_TORTIL_01X-MINI",
				"DOR REG_TORTIL_04X-SMALL",
				"DOR REG_TORTIL_07X-MEDIUM",
				"DOR REG_TORTIL_11X-BIG",
				"DOR REG_TORTIL_12X-EXTRA BIG",
				"PRO MIX_SALTY MIX_12X-EXTRA BIG",
				"PRO TOR_TORTIL_01X-MINI",
				"PRO TOR_TORTIL_11X-BIG",
				"JCK SMX_SALTY MIX_07X-MEDIUM",
				"TOS OTH_TORTIL_11X-BIG",
			],
		},
		competitor: {
			vendor: [
				"BIMBO",
				"CIA NAL CHOCOLATES",
				"COLOMBINA SA",
				"COMESTIBLES RICOS",
				"DIPSA",
				"FRITO MIX",
				"KELLOGGS",
				"LA VICTORIA",
				"MARS",
				"MEKATO",
				"OTHER VENDOR",
				"PRIVATE LABEL",
				"PRODUCTOS RAMO",
				"QUALA",
				"YUPI",
			],
			category: ["SAV-SALTY"],
			segment: [
				"SAV-CORN",
				"SAV-EXTRUDED",
				"SAV-HLTHY SNACK",
				"SAV-PELLET",
				"SAV-POPCORN-RTE",
				"SAV-PORK RINDS",
				"SAV-POTATO",
				"SAV-SALTY MIX",
				"SAV-TORTILLA",
				"SAV-VARIETY PK",
				"SAV-WHEAT",
			],
			brand: [
				"CHIDOS",
				"CHIRICO CHIRIQUITO",
				"CHIRICO CHIRIQUITO PORK RIND",
				"COLOMBINA POPCORN",
				"FRITO MIX EXTRUDED",
				"FRITO MIX HEALTHY",
				"FRITO MIX LISSITAZ",
				"FRITO MIX MEGA ONDITAS",
				"FRITO MIX PELLET",
				"FRITO MIX PELLET MEGAFRITOS",
				"FRITO MIX PORKRIND",
				"FRITO MIX POTATO",
				"FRITO MIX POTATO MEGAFRITOS",
				"FRITO MIX RIZZZ",
				"FRITO MIX ROSQUISS",
				"FRITO MIX RULASS",
				"FRITO MIX RULITASS",
				"FRITO MIX RULIZZ",
				"FRITO MIX TORTILLA",
				"FRITO MIX TOZIMIEL",
				"FRITO MIX TOZIMIX",
				"FRITO MIX WHEAT",
				"FRITO SALTY MEGAFRITOS DEL VALLE",
				"FRITO SALTY MIX",
				"GUADALUPE",
				"LA ESPECIAL CORN",
				"LA ESPECIAL EXTRUDED",
				"LA ESPECIAL HEALTHY SNACKS",
				"LA ESPECIAL TORTILLA",
				"LA ESPECIAL VARIETY PK",
				"LA ESPECIAL WHEAT",
				"LA VICTORIA BESI",
				"LA VICTORIA CHICHARRON",
				"LA VICTORIA EXTRUDED",
				"LA VICTORIA EXTRUDED CHISSTOZO",
				"LA VICTORIA HEALTHY",
				"LA VICTORIA MIX",
				"LA VICTORIA MIX PLATANUDO",
				"LA VICTORIA POTATO",
				"LA VICTORIA POTATO CHISSTOZO",
				"LA VICTORIA POTATO OREADA",
				"LA VICTORIA TORTILLA",
				"LA VICTORIA VARIETY",
				"LA VICTORIA WHEAT",
				"LA VICTORIA WHEAT BESI",
				"LALO",
				"MAMA INES",
				"MANITOBA CORN",
				"MEKATO EXTRUDED",
				"MEKATO HLTHY SNACK",
				"MEKATO REGULAR",
				"MEKATO SALTY MIX",
				"MEKATO TORTILLA",
				"MEKATO VARIETY PK",
				"OTHER SUBBRAND EXTRUDED",
				"OTHER SUBBRAND HEALTHY",
				"OTHER SUBBRAND MIX",
				"OTHER SUBBRAND PELLET",
				"OTHER SUBBRAND POPCORN",
				"OTHER SUBBRAND PORKRIND",
				"OTHER SUBBRAND POTATO",
				"OTHER SUBBRAND TORTILLA",
				"OTHER SUBBRAND VARIETY",
				"OTHER SUBBRAND WHEAT",
				"PA MI GENTE",
				"POPETAS MIX",
				"PRINGLES REGULAR",
				"PRIVATE LABEL EXTRUDED",
				"PRIVATE LABEL HEALTHY",
				"PRIVATE LABEL MIX",
				"PRIVATE LABEL PELLET",
				"PRIVATE LABEL POPCORN",
				"PRIVATE LABEL POTATO",
				"PRIVATE LABEL TORTILLA",
				"PRIVATE LABEL VARIETY",
				"PRIVATE LABEL WHEAT",
				"RAMO CASERITAS",
				"RAMO PATACON",
				"RAMO POPCORN",
				"RAMO PORK RIND",
				"RAMO TORTILLA",
				"RAMO VARIETY",
				"SANISSIMO",
				"SUPER RICAS CORN",
				"SUPER RICAS EXTRUDED",
				"SUPER RICAS PELLET",
				"SUPER RICAS PORK RIND",
				"SUPER RICAS POTATO",
				"SUPER RICAS TAJADITAS",
				"SUPER RICAS VARIETY",
				"SUPER RICAS WHEAT",
				"TAKIS REGULAR",
				"TODO RICO",
				"YUPI CHIPS",
				"YUPI EL GOLPE",
				"YUPI EXTRUDED",
				"YUPI HEALTHY",
				"YUPI PELLET",
				"YUPI PORCORN",
				"YUPI POTATO",
				"YUPI RIZADAS",
				"YUPI TOSTI",
				"YUPI VARIETY",
				"YUPI WHEAT",
			],
			subBrand: [
				"CHIDOS",
				"CHIRICO CHIRIQUITO",
				"CHIRICO CHIRIQUITO PORK RIND",
				"COLOMBINA POPCORN",
				"FRITO MIX EXTRUDED",
				"FRITO MIX HEALTHY",
				"FRITO MIX LISSITAZ",
				"FRITO MIX MEGA ONDITAS",
				"FRITO MIX PELLET",
				"FRITO MIX PELLET MEGAFRITOS",
				"FRITO MIX PORKRIND",
				"FRITO MIX POTATO",
				"FRITO MIX POTATO MEGAFRITOS",
				"FRITO MIX RIZZZ",
				"FRITO MIX ROSQUISS",
				"FRITO MIX RULASS",
				"FRITO MIX RULITASS",
				"FRITO MIX RULIZZ",
				"FRITO MIX TORTILLA",
				"FRITO MIX TOZIMIEL",
				"FRITO MIX TOZIMIX",
				"FRITO MIX WHEAT",
				"FRITO SALTY MEGAFRITOS DEL VALLE",
				"FRITO SALTY MIX",
				"GUADALUPE",
				"LA ESPECIAL CORN",
				"LA ESPECIAL EXTRUDED",
				"LA ESPECIAL HEALTHY SNACKS",
				"LA ESPECIAL TORTILLA",
				"LA ESPECIAL VARIETY PK",
				"LA ESPECIAL WHEAT",
				"LA VICTORIA BESI",
				"LA VICTORIA CHICHARRON",
				"LA VICTORIA EXTRUDED",
				"LA VICTORIA EXTRUDED CHISSTOZO",
				"LA VICTORIA HEALTHY",
				"LA VICTORIA MIX",
				"LA VICTORIA MIX PLATANUDO",
				"LA VICTORIA POTATO",
				"LA VICTORIA POTATO CHISSTOZO",
				"LA VICTORIA POTATO OREADA",
				"LA VICTORIA TORTILLA",
				"LA VICTORIA VARIETY",
				"LA VICTORIA WHEAT",
				"LA VICTORIA WHEAT BESI",
				"LALO",
				"MAMA INES",
				"MANITOBA CORN",
				"MEKATO EXTRUDED",
				"MEKATO HLTHY SNACK",
				"MEKATO REGULAR",
				"MEKATO SALTY MIX",
				"MEKATO TORTILLA",
				"MEKATO VARIETY PK",
				"OTHER SUBBRAND EXTRUDED",
				"OTHER SUBBRAND HEALTHY",
				"OTHER SUBBRAND MIX",
				"OTHER SUBBRAND PELLET",
				"OTHER SUBBRAND POPCORN",
				"OTHER SUBBRAND PORKRIND",
				"OTHER SUBBRAND POTATO",
				"OTHER SUBBRAND TORTILLA",
				"OTHER SUBBRAND VARIETY",
				"OTHER SUBBRAND WHEAT",
				"PA MI GENTE",
				"POPETAS MIX",
				"PRINGLES REGULAR",
				"PRIVATE LABEL EXTRUDED",
				"PRIVATE LABEL HEALTHY",
				"PRIVATE LABEL MIX",
				"PRIVATE LABEL PELLET",
				"PRIVATE LABEL POPCORN",
				"PRIVATE LABEL POTATO",
				"PRIVATE LABEL TORTILLA",
				"PRIVATE LABEL VARIETY",
				"PRIVATE LABEL WHEAT",
				"RAMO CASERITAS",
				"RAMO PATACON",
				"RAMO POPCORN",
				"RAMO PORK RIND",
				"RAMO TORTILLA",
				"RAMO VARIETY",
				"SANISSIMO",
				"SUPER RICAS CORN",
				"SUPER RICAS EXTRUDED",
				"SUPER RICAS PELLET",
				"SUPER RICAS PORK RIND",
				"SUPER RICAS POTATO",
				"SUPER RICAS TAJADITAS",
				"SUPER RICAS VARIETY",
				"SUPER RICAS WHEAT",
				"TAKIS REGULAR",
				"TODO RICO",
				"YUPI CHIPS",
				"YUPI EL GOLPE",
				"YUPI EXTRUDED",
				"YUPI HEALTHY",
				"YUPI PELLET",
				"YUPI PORCORN",
				"YUPI POTATO",
				"YUPI RIZADAS",
				"YUPI TOSTI",
				"YUPI VARIETY",
				"YUPI WHEAT",
			],
			packSize: ["01X-MINI", "04X-SMALL", "07X-MEDIUM", "11X-BIG", "12X-EXTRA BIG"],
			permutation: [
				"CHD TOR_TORTIL_01X-MINI",
				"CHD TOR_TORTIL_11X-BIG",
				"CRC CHI_EXTRUD_01X-MINI",
				"CRC CHI_EXTRUD_04X-SMALL",
				"CRC CHI_EXTRUD_07X-MEDIUM",
				"CRC PRK_PORK RIND_04X-SMALL",
				"CLM POP_CORN_01X-MINI",
				"CLM POP_CORN_04X-SMALL",
				"CLM POP_CORN_11X-BIG",
				"FRT EXT_EXTRUD_01X-MINI",
				"FRT EXT_EXTRUD_04X-SMALL",
				"FRT EXT_EXTRUD_07X-MEDIUM",
				"FRT HTHY_HLTHY SNK_01X-MINI",
				"FRT HTHY_HLTHY SNK_04X-SMALL",
				"FRT LIS_POTATO_01X-MINI",
				"FRT OND_POTATO_01X-MINI",
				"FRT PLT_PELLET_01X-MINI",
				"FRT PLT_PELLET_04X-SMALL",
				"FRT PMFR_PELLET_01X-MINI",
				"FRT PMFR_PELLET_04X-SMALL",
				"FRT PRKR_PORK RIND_04X-SMALL",
				"FRT POT_POTATO_01X-MINI",
				"FRT POT_POTATO_04X-SMALL",
				"FRT POT_POTATO_11X-BIG",
				"FRT MFR_POTATO_01X-MINI",
				"FRT RIZ_POTATO_04X-SMALL",
				"FRT RQS_WHEAT_01X-MINI",
				"FRT RLS_POTATO_01X-MINI",
				"FRT RUL_POTATO_01X-MINI",
				"FRT RLZ_POTATO_04X-SMALL",
				"FRT TOR_TORTIL_01X-MINI",
				"FRT TOZ_PELLET_04X-SMALL",
				"FRT TZM_PELLET_04X-SMALL",
				"FRT WHT_WHEAT_01X-MINI",
				"FRT WHT_WHEAT_04X-SMALL",
				"FRT MGV_SALTY MIX_01X-MINI",
				"FRT MGV_SALTY MIX_04X-SMALL",
				"FRT SAL_SALTY MIX_01X-MINI",
				"FRT SAL_SALTY MIX_04X-SMALL",
				"FRT SAL_SALTY MIX_11X-BIG",
				"FRT SAL_SALTY MIX_12X-EXTRA BIG",
				"GDP WHT_WHEAT_01X-MINI",
				"LES CRN_CORN_01X-MINI",
				"LES CRN_CORN_04X-SMALL",
				"LES CRN_CORN_11X-BIG",
				"LES EXT_EXTRUD_04X-SMALL",
				"LES EXT_EXTRUD_11X-BIG",
				"LES HTHY_HLTHY SNK_01X-MINI",
				"LES TOR_TORTIL_01X-MINI",
				"LES TOR_TORTIL_11X-BIG",
				"LES VTY_VRTY PK_04X-SMALL",
				"LES WHT_WHEAT_04X-SMALL",
				"LES WHT_WHEAT_12X-EXTRA BIG",
				"LVC BES_EXTRUD_04X-SMALL",
				"LVC CHI_EXTRUD_01X-MINI",
				"LVC CHI_EXTRUD_04X-SMALL",
				"LVC CHI_EXTRUD_07X-MEDIUM",
				"LVC CHI_EXTRUD_11X-BIG",
				"LVC CHI_EXTRUD_12X-EXTRA BIG",
				"LVC EXT_EXTRUD_01X-MINI",
				"LVC EXT_EXTRUD_04X-SMALL",
				"LVC EXT_EXTRUD_07X-MEDIUM",
				"LVC CSTZ_EXTRUD_01X-MINI",
				"LVC CSTZ_EXTRUD_04X-SMALL",
				"LVC CSTZ_EXTRUD_07X-MEDIUM",
				"LVC HTY_HLTHY SNK_04X-SMALL",
				"LVC HTY_HLTHY SNK_07X-MEDIUM",
				"LVC HTY_HLTHY SNK_11X-BIG",
				"LVC MIX_SALTY MIX_01X-MINI",
				"LVC MIX_SALTY MIX_04X-SMALL",
				"LVC MIX_SALTY MIX_07X-MEDIUM",
				"LVC MIX_SALTY MIX_11X-BIG",
				"LVC MIX_SALTY MIX_12X-EXTRA BIG",
				"LVC MPLT_SALTY MIX_01X-MINI",
				"LVC MPLT_SALTY MIX_11X-BIG",
				"LVC POT_POTATO_01X-MINI",
				"LVC POT_POTATO_04X-SMALL",
				"LVC POT_POTATO_11X-BIG",
				"LVC POT_POTATO_12X-EXTRA BIG",
				"LVC TOR_TORTIL_01X-MINI",
				"LVC TOR_TORTIL_04X-SMALL",
				"LVC TOR_TORTIL_07X-MEDIUM",
				"LVC VAR_VRTY PK_01X-MINI",
				"LVC WHT_WHEAT_01X-MINI",
				"LVC WHT_WHEAT_04X-SMALL",
				"LVC WHT_WHEAT_07X-MEDIUM",
				"LVC WHT_WHEAT_12X-EXTRA BIG",
				"LVC BSI_WHEAT_04X-SMALL",
				"LAL WHT_WHEAT_01X-MINI",
				"MIN WHT_WHEAT_01X-MINI",
				"MEK EXT_EXTRUD_01X-MINI",
				"MEK EXT_EXTRUD_04X-SMALL",
				"MEK HTY_HLTHY SNK_04X-SMALL",
				"MEK REG_POTATO_01X-MINI",
				"MEK REG_POTATO_04X-SMALL",
				"MEK REG_POTATO_11X-BIG",
				"MEK SMX_SALTY MIX_01X-MINI",
				"MEK SMX_SALTY MIX_11X-BIG",
				"MEK TOR_TORTIL_01X-MINI",
				"MEK VAR_VRTY PK_01X-MINI",
				"OTH EXT_EXTRUD_01X-MINI",
				"OTH EXT_EXTRUD_04X-SMALL",
				"OTH EXT_EXTRUD_07X-MEDIUM",
				"OTH EXT_EXTRUD_11X-BIG",
				"OTH EXT_EXTRUD_12X-EXTRA BIG",
				"OTH HTY_HLTHY SNK_01X-MINI",
				"OTH HTY_HLTHY SNK_04X-SMALL",
				"OTH HTY_HLTHY SNK_07X-MEDIUM",
				"OTH HTY_HLTHY SNK_11X-BIG",
				"OTH HTY_HLTHY SNK_12X-EXTRA BIG",
				"OTH MIX_SALTY MIX_01X-MINI",
				"OTH MIX_SALTY MIX_04X-SMALL",
				"OTH MIX_SALTY MIX_07X-MEDIUM",
				"OTH MIX_SALTY MIX_11X-BIG",
				"OTH MIX_SALTY MIX_12X-EXTRA BIG",
				"OTH PEL_PELLET_01X-MINI",
				"OTH PEL_PELLET_04X-SMALL",
				"OTH PEL_PELLET_07X-MEDIUM",
				"OTH POP_PCORN RTE_01X-MINI",
				"OTH POP_PCORN RTE_04X-SMALL",
				"OTH POP_PCORN RTE_07X-MEDIUM",
				"OTH POP_PCORN RTE_11X-BIG",
				"OTH PRK_PORK RIND_01X-MINI",
				"OTH PRK_PORK RIND_04X-SMALL",
				"OTH PRK_PORK RIND_07X-MEDIUM",
				"OTH PRK_PORK RIND_11X-BIG",
				"OTH PRK_PORK RIND_12X-EXTRA BIG",
				"OTH POT_POTATO_01X-MINI",
				"OTH POT_POTATO_04X-SMALL",
				"OTH POT_POTATO_07X-MEDIUM",
				"OTH POT_POTATO_11X-BIG",
				"OTH POT_POTATO_12X-EXTRA BIG",
				"OTH TOR_TORTIL_01X-MINI",
				"OTH TOR_TORTIL_04X-SMALL",
				"OTH TOR_TORTIL_07X-MEDIUM",
				"OTH TOR_TORTIL_11X-BIG",
				"OTH TOR_TORTIL_12X-EXTRA BIG",
				"OTH VAR_VRTY PK_01X-MINI",
				"OTH VAR_VRTY PK_04X-SMALL",
				"OTH VAR_VRTY PK_07X-MEDIUM",
				"OTH VAR_VRTY PK_12X-EXTRA BIG",
				"OTH WHT_WHEAT_01X-MINI",
				"OTH WHT_WHEAT_04X-SMALL",
				"OTH WHT_WHEAT_07X-MEDIUM",
				"OTH WHT_WHEAT_11X-BIG",
				"OTH WHT_WHEAT_12X-EXTRA BIG",
				"PMG WHT_WHEAT_01X-MINI",
				"PMG WHT_WHEAT_04X-SMALL",
				"PMG WHT_WHEAT_12X-EXTRA BIG",
				"POE MIX_CORN_01X-MINI",
				"POE MIX_CORN_04X-SMALL",
				"POE MIX_CORN_07X-MEDIUM",
				"POE MIX_CORN_11X-BIG",
				"POE MIX_CORN_12X-EXTRA BIG",
				"PRI REG_POTATO_01X-MINI",
				"PRI REG_POTATO_04X-SMALL",
				"PRI REG_POTATO_07X-MEDIUM",
				"PRI REG_POTATO_11X-BIG",
				"PRI REG_POTATO_12X-EXTRA BIG",
				"PVL EXT_EXTRUD_01X-MINI",
				"PVL EXT_EXTRUD_04X-SMALL",
				"PVL EXT_EXTRUD_07X-MEDIUM",
				"PVL EXT_EXTRUD_11X-BIG",
				"PVL EXT_EXTRUD_12X-EXTRA BIG",
				"PVL HTY_HLTHY SNK_01X-MINI",
				"PVL HTY_HLTHY SNK_04X-SMALL",
				"PVL HTY_HLTHY SNK_07X-MEDIUM",
				"PVL HTY_HLTHY SNK_11X-BIG",
				"PVL HTY_HLTHY SNK_12X-EXTRA BIG",
				"PVL MIX_SALTY MIX_01X-MINI",
				"PVL MIX_SALTY MIX_04X-SMALL",
				"PVL MIX_SALTY MIX_11X-BIG",
				"PVL MIX_SALTY MIX_12X-EXTRA BIG",
				"PVL PEL_PELLET_04X-SMALL",
				"PVL POP_PCORN RTE_11X-BIG",
				"PV POT_POTATO_01X-MINI",
				"PV POT_POTATO_04X-SMALL",
				"PV POT_POTATO_11X-BIG",
				"PV POT_POTATO_12X-EXTRA BIG",
				"PVL TOR_TORTIL_01X-MINI",
				"PVL TOR_TORTIL_07X-MEDIUM",
				"PVL TOR_TORTIL_11X-BIG",
				"PVL VTY_VRTY PK_01X-MINI",
				"PVL VTY_VRTY PK_04X-SMALL",
				"PVL VTY_VRTY PK_07X-MEDIUM",
				"PVL WHT_WHEAT_01X-MINI",
				"PVL WHT_WHEAT_04X-SMALL",
				"PVL WHT_WHEAT_11X-BIG",
				"PVL WHT_WHEAT_12X-EXTRA BIG",
				"RMO CAS_POTATO_01X-MINI",
				"RMO CAS_POTATO_04X-SMALL",
				"RMO CAS_POTATO_11X-BIG",
				"RMO PAT_HLTHY SNK_01X-MINI",
				"RMO PAT_HLTHY SNK_04X-SMALL",
				"RMO PAT_HLTHY SNK_11X-BIG",
				"RMO POP_PCORN RTE_01X-MINI",
				"RMO POP_PCORN RTE_11X-BIG",
				"RMO PRK_PORK RIND_04X-SMALL",
				"RMO TOR_TORTIL_01X-MINI",
				"RMO TOR_TORTIL_04X-SMALL",
				"RMO TOR_TORTIL_11X-BIG",
				"RMO TOR_TORTIL_12X-EXTRA BIG",
				"RMO VTY_VRTY PK_01X-MINI",
				"SNS WHT_WHEAT_04X-SMALL",
				"SPI CRN_CORN_04X-SMALL",
				"SPI EXT_EXTRUD_04X-SMALL",
				"SPI PEL_PELLET_01X-MINI",
				"SPI PRK_PORK RIND_01X-MINI",
				"SPI PRK_PORK RIND_04X-SMALL",
				"SPI POT_POTATO_01X-MINI",
				"SPI POT_POTATO_04X-SMALL",
				"SPI POT_POTATO_11X-BIG",
				"SPI POT_POTATO_12X-EXTRA BIG",
				"SRI TAJ_HLTHY SNK_01X-MINI",
				"SRI TAJ_HLTHY SNK_04X-SMALL",
				"SRI TAJ_HLTHY SNK_11X-BIG",
				"SPI VTY_VRTY PK_01X-MINI",
				"SPI VTY_VRTY PK_04X-SMALL",
				"SPI WHT_WHEAT_01X-MINI",
				"SPI WHT_WHEAT_04X-SMALL",
				"SPI WHT_WHEAT_07X-MEDIUM",
				"SPI WHT_WHEAT_12X-EXTRA BIG",
				"TAK REG_TORTIL_01X-MINI",
				"TAK REG_TORTIL_04X-SMALL",
				"TAK REG_TORTIL_11X-BIG",
				"TDO RCO_SALTY MIX_04X-SMALL",
				"TDO RCO_SALTY MIX_07X-MEDIUM",
				"TDO RCO_SALTY MIX_11X-BIG",
				"TDO RCO_SALTY MIX_12X-EXTRA BIG",
				"YUP GPE_SALTY MIX_01X-MINI",
				"YUP GPE_SALTY MIX_04X-SMALL",
				"YUP GPE_SALTY MIX_07X-MEDIUM",
				"YUP GPE_SALTY MIX_11X-BIG",
				"YUP GPE_SALTY MIX_12X-EXTRA BIG",
				"YUP EXT_EXTRUD_01X-MINI",
				"YUP EXT_EXTRUD_04X-SMALL",
				"YUP EXT_EXTRUD_07X-MEDIUM",
				"YUP EXT_EXTRUD_11X-BIG",
				"YUP HTY_HLTHY SNK_01X-MINI",
				"YUP HTY_HLTHY SNK_04X-SMALL",
				"YUP PLT_PELLET_01X-MINI",
				"YUP PLT_PELLET_04X-SMALL",
				"YUP PLT_PELLET_11X-BIG",
				"YUP POP_PCORN RTE_01X-MINI",
				"YUP POP_PCORN RTE_04X-SMALL",
				"YUP POP_PCORN RTE_11X-BIG",
				"YUP POT_POTATO_01X-MINI",
				"YUP POT_POTATO_04X-SMALL",
				"YUP POT_POTATO_07X-MEDIUM",
				"YUP POT_POTATO_11X-BIG",
				"YUP POT_POTATO_12X-EXTRA BIG",
				"YUP TOS_TORTIL_01X-MINI",
				"YUP TOS_TORTIL_04X-SMALL",
				"YUP TOS_TORTIL_11X-BIG",
				"YUP VTY_VRTY PK_01X-MINI",
				"YUP VTY_VRTY PK_04X-SMALL",
				"YUP VTY_VRTY PK_11X-BIG",
				"YUP WHT_WHEAT_01X-MINI",
				"YUP WHT_WHEAT_04X-SMALL",
			],
		},
		startDate: "01/01/2024",
		endDate: "03/29/2024",
		variable1: "SOM",
		SOMMeasure: "Category",
		SOMGroup: "Volume",
		APIUnits: "Units",
	},
	{
		country: "CHILE",
		geoLevel: ["COUNTRY-SUBCHAN"],
		geoLevel2: ["TTL CHILE-SMKT"],
		channel: ["OT"],
		periodView: ["na"],
		viewShareby: "unitSales",
		indexes: ["20", "10"],
		pepsi: {
			category: ["BIS-COOKIES", "BIS-CRACKERS", "FDS-BARS", "FDS-OATS", "FDS-RTE CEREAL", "SAV-DIPS & SALSA", "SAV-NUTS & SEEDS", "SAV-SALTY"],
			segment: [
				"CKY-BREAKFAST",
				"CKY-CHOC CHIP",
				"CKY-CHOC COVER",
				"CKY-FILLED",
				"CKY-OAT_QUAKER",
				"CKY-SANDWICH",
				"CRK-BAKED",
				"FDS-BARS",
				"FDS-CEREAL ADULT",
				"FDS-CEREAL GRANOLA",
				"FDS-OATS INSTANT",
				"FDS-OATS MULTISEED",
				"FDS-OATS TRAD",
				"SAV-EXTRUDED",
				"SAV-NUTS",
				"SAV-POPCORN-RTE",
				"SAV-POTATO",
				"SAV-RTE DIPS",
				"SAV-SALTY MIX",
				"SAV-TORTILLA",
				"SAV-VARIETY PK",
				"SAV-WHEAT",
			],
			brand: [
				"CABRITAS POPCORN REGULAR",
				"CHEETOS MIX",
				"CHEETOS POPCORN",
				"CHEETOS PUFF",
				"CHEETOS REGULAR",
				"CHEETOS SUFLE",
				"CHEETOS WOOW",
				"CHEEZELS REGULAR",
				"CHIS POP REGULAR",
				"CHOCO CRACS REGULAR",
				"DORITOS HEATWAVE",
				"DORITOS REGULAR",
				"DORITOS RULETA",
				"EVERCRISP NUTS JAPONES",
				"EVERCRISP NUTS MIXED",
				"EVERCRISP NUTS PREMIUM",
				"EVERCRISP NUTS SAL",
				"EVERCRISP NUTS SIN SAL",
				"EVERCRISP POTATO",
				"EVERCRISP VARIETY",
				"GALLETAS CONSEN",
				"GATOLATE REGULAR",
				"LAYS ARTESANAS",
				"LAYS CLASSIC",
				"LAYS FLAVOURS",
				"LAYS HILO",
				"LAYS JUNTEMONOS",
				"LAYS MAXX",
				"LAYS MEDITERRANEAS",
				"LAYS SENSATIONS",
				"LAYS VARIETY",
				"LAYS WAVY",
				"MAFER",
				"MOMS CASERAS",
				"MOMS CHAPARRITOS",
				"MOMS CHOCLITOS",
				"MOMS RAMITAS",
				"MOMS SUFLES",
				"NUTBAR REGULAR",
				"QUAKER BARS COLLECTION",
				"QUAKER BARS MULTISEED",
				"QUAKER BARS REGULAR",
				"QUAKER CEREAL FLAKES",
				"QUAKER CEREAL GRANOLA",
				"QUAKER CEREAL HEARTS",
				"QUAKER CEREAL QUADRITOS",
				"QUAKER GALLETAS MINI",
				"QUAKER GALLETAS REGULAR",
				"QUAKER GALLETAS RELLENA",
				"QUAKER OATS INSTANT",
				"QUAKER OATS MULTISEMILLAS",
				"QUAKER OATS TRAD",
				"RAICES CHILENAS",
				"RAMITAS EVERCRISP",
				"RUFFLES ORIGINAL",
				"SNACK MIX REGULAR",
				"STAX REGULAR",
				"TODDY BLACK",
				"TODDY BLOCK",
				"TODDY CHOKOMAX",
				"TODDY CLASICA",
				"TODDY EXTRA",
				"TODDY MINI",
				"TOSTITOS DIPS REGULAR",
				"TOSTITOS REGULAR",
				"TRAGA TRAGA REGULAR",
				"TWISTOS INTEGRALES",
				"TWISTOS MINITOSTADITAS",
				"TWISTOS RICE SNACK",
			],
			subBrand: [
				"CABRITAS POPCORN REGULAR",
				"CHEETOS MIX",
				"CHEETOS POPCORN",
				"CHEETOS PUFF",
				"CHEETOS REGULAR",
				"CHEETOS SUFLE",
				"CHEETOS WOOW",
				"CHEEZELS REGULAR",
				"CHIS POP REGULAR",
				"CHOCO CRACS REGULAR",
				"DORITOS HEATWAVE",
				"DORITOS REGULAR",
				"DORITOS RULETA",
				"EVERCRISP NUTS JAPONES",
				"EVERCRISP NUTS MIXED",
				"EVERCRISP NUTS PREMIUM",
				"EVERCRISP NUTS SAL",
				"EVERCRISP NUTS SIN SAL",
				"EVERCRISP POTATO",
				"EVERCRISP VARIETY",
				"GALLETAS CONSEN",
				"GATOLATE REGULAR",
				"LAYS ARTESANAS",
				"LAYS CLASSIC",
				"LAYS FLAVOURS",
				"LAYS HILO",
				"LAYS JUNTEMONOS",
				"LAYS MAXX",
				"LAYS MEDITERRANEAS",
				"LAYS SENSATIONS",
				"LAYS VARIETY",
				"LAYS WAVY",
				"MAFER",
				"MOMS CASERAS",
				"MOMS CHAPARRITOS",
				"MOMS CHOCLITOS",
				"MOMS RAMITAS",
				"MOMS SUFLES",
				"NUTBAR REGULAR",
				"QUAKER BARS COLLECTION",
				"QUAKER BARS MULTISEED",
				"QUAKER BARS REGULAR",
				"QUAKER CEREAL FLAKES",
				"QUAKER CEREAL GRANOLA",
				"QUAKER CEREAL HEARTS",
				"QUAKER CEREAL QUADRITOS",
				"QUAKER GALLETAS MINI",
				"QUAKER GALLETAS REGULAR",
				"QUAKER GALLETAS RELLENA",
				"QUAKER OATS INSTANT",
				"QUAKER OATS MULTISEMILLAS",
				"QUAKER OATS TRAD",
				"RAICES CHILENAS",
				"RAMITAS EVERCRISP",
				"RUFFLES ORIGINAL",
				"SNACK MIX REGULAR",
				"STAX REGULAR",
				"TODDY BLACK",
				"TODDY BLOCK",
				"TODDY CHOKOMAX",
				"TODDY CLASICA",
				"TODDY EXTRA",
				"TODDY MINI",
				"TOSTITOS DIPS REGULAR",
				"TOSTITOS REGULAR",
				"TRAGA TRAGA REGULAR",
				"TWISTOS INTEGRALES",
				"TWISTOS MINITOSTADITAS",
				"TWISTOS RICE SNACK",
			],
			packSize: [
				"01X-MINI",
				"01X-SS XSMALL",
				"02P-MULTIPK",
				"02X-JR LINE",
				"04P-MULTIPK",
				"04X-SS LARGE",
				"05P-MULTIPK",
				"06P-MULTIPK",
				"07X-MAX",
				"07X-MEDIUM",
				"08P-MULTIPK",
				"09X-GRANDE",
				"09X-JUMBO",
				"10X-MANGA",
				"11X-BIG",
				"12P-MULTIPK",
				"12X-EXTRA BIG",
				"15P-MULTIPK",
				"15X-MEGA",
				"16X-SUPER SIZE",
				"20P-MULTIPK",
				"22P-MULTIPK",
				"30P-MULTIPK",
				"36P-MULTIPK",
				"53P-MULTIPK",
				"96P-MULTIPK",
			],
			vendor: ["PEPSICO"],
			permutation: [
				"CAB REG_PCORN RTE_01X-SS XSMALL",
				"CAB REG_PCORN RTE_07X-MEDIUM",
				"CAB REG_PCORN RTE_09X-GRANDE",
				"CTS MIX_EXTRUD_01X-SS XSMALL",
				"CTS MIX_EXTRUD_09X-GRANDE",
				"CTS POP_PCORN RTE_01X-MINI",
				"CTS POP_PCORN RTE_01X-SS XSMALL",
				"CTS POP_PCORN RTE_02X-JR LINE",
				"CTS POP_PCORN RTE_04X-SS LARGE",
				"CTS POP_PCORN RTE_09X-JUMBO",
				"CTS PUF_EXTRUD_01X-SS XSMALL",
				"CTS PUF_EXTRUD_04X-SS LARGE",
				"CTS PUF_EXTRUD_09X-JUMBO",
				"CTS REG_EXTRUD_01X-MINI",
				"CTS REG_EXTRUD_01X-SS XSMALL",
				"CTS REG_EXTRUD_02X-JR LINE",
				"CTS REG_EXTRUD_04X-SS LARGE",
				"CTS REG_EXTRUD_07X-MAX",
				"CTS REG_EXTRUD_07X-MEDIUM",
				"CTS REG_EXTRUD_09X-GRANDE",
				"CTS REG_EXTRUD_09X-JUMBO",
				"CTS REG_EXTRUD_10X-MANGA",
				"CTS REG_EXTRUD_11X-BIG",
				"CTS REG_EXTRUD_12X-EXTRA BIG",
				"CTS REG_EXTRUD_15X-MEGA",
				"CTS SFL_EXTRUD_04X-SS LARGE",
				"CTS SFL_EXTRUD_09X-JUMBO",
				"CTS WOO_EXTRUD_01X-SS XSMALL",
				"CTS WOO_EXTRUD_09X-GRANDE",
				"CHZ REG_EXTRUD_01X-SS XSMALL",
				"CHZ REG_EXTRUD_02X-JR LINE",
				"CHZ REG_EXTRUD_04X-SS LARGE",
				"CHZ REG_EXTRUD_07X-MAX",
				"CHZ REG_EXTRUD_07X-MEDIUM",
				"CHZ REG_EXTRUD_09X-GRANDE",
				"CHZ REG_EXTRUD_09X-JUMBO",
				"CHZ REG_EXTRUD_10X-MANGA",
				"CHZ REG_EXTRUD_11X-BIG",
				"CPP REG_EXTRUD_01X-SS XSMALL",
				"CPP REG_EXTRUD_04X-SS LARGE",
				"CPP REG_EXTRUD_07X-MAX",
				"CPP REG_EXTRUD_07X-MEDIUM",
				"CPP REG_EXTRUD_09X-GRANDE",
				"CPP REG_EXTRUD_09X-JUMBO",
				"CHO CRA_CHOC CVR CKY_01X-SS XSMALL",
				"CHO CRA_CHOC CVR CKY_07X-MEDIUM",
				"DOR HEA_TORTIL_01X-SS XSMALL",
				"DOR HEA_TORTIL_02X-JR LINE",
				"DOR HEA_TORTIL_07X-MEDIUM",
				"DOR REG_TORTIL_01X-MINI",
				"DOR REG_TORTIL_01X-SS XSMALL",
				"DOR REG_TORTIL_02X-JR LINE",
				"DOR REG_TORTIL_04X-SS LARGE",
				"DOR REG_TORTIL_07X-MAX",
				"DOR REG_TORTIL_07X-MEDIUM",
				"DOR REG_TORTIL_09X-GRANDE",
				"DOR REG_TORTIL_09X-JUMBO",
				"DOR REG_TORTIL_10X-MANGA",
				"DOR REG_TORTIL_11X-BIG",
				"DOR REG_TORTIL_12X-EXTRA BIG",
				"DOR REG_TORTIL_16X-SUPER SIZE",
				"DOR RUL_TORTIL_01X-SS XSMALL",
				"DOR RUL_TORTIL_04X-SS LARGE",
				"DOR RUL_TORTIL_07X-MAX",
				"DOR RUL_TORTIL_07X-MEDIUM",
				"EVE JPN_NUTS_01X-SS XSMALL",
				"EVE JPN_NUTS_04X-SS LARGE",
				"EVE JPN_NUTS_07X-MAX",
				"EVE JPN_NUTS_07X-MEDIUM",
				"EVE JPN_NUTS_09X-JUMBO",
				"EVE JPN_NUTS_12X-EXTRA BIG",
				"EVE MXD_NUTS_07X-MEDIUM",
				"EVE MXD_NUTS_09X-JUMBO",
				"EVE PRE_NUTS_02X-JR LINE",
				"EVE SAL_NUTS_01X-MINI",
				"EVE SAL_NUTS_01X-SS XSMALL",
				"EVE SAL_NUTS_02X-JR LINE",
				"EVE SAL_NUTS_04X-SS LARGE",
				"EVE SAL_NUTS_07X-MAX",
				"EVE SAL_NUTS_07X-MEDIUM",
				"EVE SAL_NUTS_09X-JUMBO",
				"EVE SAL_NUTS_10X-MANGA",
				"EVE SAL_NUTS_11X-BIG",
				"EVE SAL_NUTS_12X-EXTRA BIG",
				"EVE SAL_NUTS_15P-MULTIPK",
				"EVE SSL_NUTS_07X-MEDIUM",
				"EVE SSL_NUTS_11X-BIG",
				"EVE SSL_NUTS_12X-EXTRA BIG",
				"EVE POT_POTATO_02P-MULTIPK",
				"EVE POT_POTATO_09X-GRANDE",
				"EVE VAR_VRTY PK_04P-MULTIPK",
				"EVE VAR_VRTY PK_06P-MULTIPK",
				"EVE VAR_VRTY PK_08P-MULTIPK",
				"EVE VAR_VRTY PK_10X-MANGA",
				"EVE VAR_VRTY PK_12P-MULTIPK",
				"EVE VAR_VRTY PK_12X-EXTRA BIG",
				"EVE VAR_VRTY PK_20P-MULTIPK",
				"EVE VAR_VRTY PK_22P-MULTIPK",
				"EVE VAR_VRTY PK_36P-MULTIPK",
				"EVE VAR_VRTY PK_53P-MULTIPK",
				"EVE VAR_VRTY PK_96P-MULTIPK",
				"GLL CON_SNDWCH CKY_04X-SS LARGE",
				"GAT REG_EXTRUD_01X-SS XSMALL",
				"GAT REG_EXTRUD_04X-SS LARGE",
				"GAT REG_EXTRUD_07X-MAX",
				"GAT REG_EXTRUD_09X-GRANDE",
				"GAT REG_EXTRUD_09X-JUMBO",
				"GAT REG_EXTRUD_10X-MANGA",
				"LAY ART_POTATO_01X-SS XSMALL",
				"LAY ART_POTATO_04X-SS LARGE",
				"LAY ART_POTATO_07X-MAX",
				"LAY ART_POTATO_07X-MEDIUM",
				"LAY ART_POTATO_09X-JUMBO",
				"LAY CLA_POTATO_01X-SS XSMALL",
				"LAY CLA_POTATO_02X-JR LINE",
				"LAY CLA_POTATO_04X-SS LARGE",
				"LAY CLA_POTATO_07X-MAX",
				"LAY CLA_POTATO_07X-MEDIUM",
				"LAY CLA_POTATO_09X-GRANDE",
				"LAY CLA_POTATO_10X-MANGA",
				"LAY CLA_POTATO_11X-BIG",
				"LAY FLV_POTATO_01X-SS XSMALL",
				"LAY FLV_POTATO_02X-JR LINE",
				"LAY FLV_POTATO_04X-SS LARGE",
				"LAY FLV_POTATO_07X-MAX",
				"LAY FLV_POTATO_07X-MEDIUM",
				"LAY FLV_POTATO_09X-GRANDE",
				"LAY FLV_POTATO_11X-BIG",
				"LAY HIL_POTATO_09X-JUMBO",
				"LYS JNT_POTATO_05P-MULTIPK",
				"LAY MAX_POTATO_01X-SS XSMALL",
				"LAY MAX_POTATO_07X-MAX",
				"LAY MAX_POTATO_07X-MEDIUM",
				"LAY MED_POTATO_01X-SS XSMALL",
				"LAY MED_POTATO_02X-JR LINE",
				"LAY MED_POTATO_07X-MAX",
				"LAY MED_POTATO_07X-MEDIUM",
				"LAY MED_POTATO_09X-GRANDE",
				"LAY MED_POTATO_09X-JUMBO",
				"LAY MED_POTATO_10X-MANGA",
				"LAY MED_POTATO_11X-BIG",
				"LAY SEN_POTATO_07X-MAX",
				"LAY VRTY_VRTY PK_02P-MULTIPK",
				"LAY VRTY_VRTY PK_04P-MULTIPK",
				"LAY VRTY_VRTY PK_16X-SUPER SIZE",
				"LAY WAV_POTATO_01X-MINI",
				"LAY WAV_POTATO_01X-SS XSMALL",
				"LAY WAV_POTATO_02P-MULTIPK",
				"LAY WAV_POTATO_02X-JR LINE",
				"LAY WAV_POTATO_04X-SS LARGE",
				"LAY WAV_POTATO_07X-MAX",
				"LAY WAV_POTATO_07X-MEDIUM",
				"LAY WAV_POTATO_09X-GRANDE",
				"LAY WAV_POTATO_09X-JUMBO",
				"LAY WAV_POTATO_10X-MANGA",
				"LAY WAV_POTATO_11X-BIG",
				"LAY WAV_POTATO_12X-EXTRA BIG",
				"LAY WAV_POTATO_20P-MULTIPK",
				"MAF_NUTS_04X-SS LARGE",
				"MOM CAS_POTATO_01X-MINI",
				"MOM CAS_POTATO_04X-SS LARGE",
				"MOM CAS_POTATO_07X-MAX",
				"MOM CAS_POTATO_07X-MEDIUM",
				"MOM CAS_POTATO_09X-GRANDE",
				"MOM CAS_POTATO_09X-JUMBO",
				"MOM CAS_POTATO_10X-MANGA",
				"MOM CAS_POTATO_11X-BIG",
				"MOM CAS_POTATO_12X-EXTRA BIG",
				"MOM CHP_CORN_01X-SS XSMALL",
				"MOM CHP_CORN_10X-MANGA",
				"MOM CHO_EXTRUD_09X-GRANDE",
				"MOM RAM_WHEAT_01X-MINI",
				"MOM RAM_WHEAT_10X-MANGA",
				"MOM SFL_EXTRUD_01X-MINI",
				"MOM SFL_EXTRUD_07X-MAX",
				"MOM SFL_EXTRUD_09X-GRANDE",
				"MOM SFL_EXTRUD_09X-JUMBO",
				"MOM SFL_EXTRUD_10X-MANGA",
				"NTB REG_FDS BAR_06P-MULTIPK",
				"QKB COL_FDS BAR_01X-MINI",
				"QKB COL_FDS BAR_01X-SS XSMALL",
				"QKB COL_FDS BAR_04X-SS LARGE",
				"QKB COL_FDS BAR_06P-MULTIPK",
				"QKB COL_FDS BAR_07X-MAX",
				"QKB COL_FDS BAR_08P-MULTIPK",
				"QKB COL_FDS BAR_09X-JUMBO",
				"QKB COL_FDS BAR_12X-EXTRA BIG",
				"QKB COL_FDS BAR_15P-MULTIPK",
				"QKB COL_FDS BAR_20P-MULTIPK",
				"QKB MUL_FDS BAR_05P-MULTIPK",
				"QKB MUL_FDS BAR_06P-MULTIPK",
				"QKB MUL_FDS BAR_12P-MULTIPK",
				"QKB REG_FDS BAR_01X-MINI",
				"QKB REG_FDS BAR_04X-SS LARGE",
				"QKB REG_FDS BAR_06P-MULTIPK",
				"QKB REG_FDS BAR_08P-MULTIPK",
				"QKB REG_FDS BAR_12P-MULTIPK",
				"QKC FLA_ADLT-CRL_09X-JUMBO",
				"QKC FLA_ADLT-CRL_11X-BIG",
				"QKC GRA_GRAN-CRL_01X-SS XSMALL",
				"QKC GRA_GRAN-CRL_09X-JUMBO",
				"QKC GRA_GRAN-CRL_11X-BIG",
				"QKC HEA_ADLT-CRL_09X-JUMBO",
				"QKC HEA_ADLT-CRL_11X-BIG",
				"QKC QUA_ADLT-CRL_01X-SS XSMALL",
				"QKC QUA_ADLT-CRL_07X-MAX",
				"QKC QUA_ADLT-CRL_11X-BIG",
				"QKC QUA_ADLT-CRL_12X-EXTRA BIG",
				"QKC QUA_ADLT-CRL_16X-SUPER SIZE",
				"QGL MIN_BKFST CKY_01X-SS XSMALL",
				"QGL REG_OAT CKY_01X-SS XSMALL",
				"QGL REG_OAT CKY_02X-JR LINE",
				"QGL REG_OAT CKY_06P-MULTIPK",
				"QGL REG_OAT CKY_07X-MAX",
				"QGL REG_OAT CKY_11X-BIG",
				"QGL REL_SNDWCH CKY_04X-SS LARGE",
				"QUO INS_INST OAT_01X-SS XSMALL",
				"QUO INS_INST OAT_07X-MAX",
				"QUO INS_INST OAT_12X-EXTRA BIG",
				"QUO INS_INST OAT_16X-SUPER SIZE",
				"QUO MUL_MLTI OAT_11X-BIG",
				"QUO MUL_MLTI OAT_12P-MULTIPK",
				"QUO MUL_MLTI OAT_12X-EXTRA BIG",
				"QUO MUL_MLTI OAT_15X-MEGA",
				"QUO MUL_MLTI OAT_16X-SUPER SIZE",
				"QUO TRA_TRAD OAT_02P-MULTIPK",
				"QUO TRA_TRAD OAT_12X-EXTRA BIG",
				"QUO TRA_TRAD OAT_16X-SUPER SIZE",
				"RAC CHI_POTATO_07X-MAX",
				"RAC CHI_POTATO_09X-JUMBO",
				"RMS EVE_WHEAT_01X-SS XSMALL",
				"RMS EVE_WHEAT_02X-JR LINE",
				"RMS EVE_WHEAT_04X-SS LARGE",
				"RMS EVE_WHEAT_07X-MAX",
				"RMS EVE_WHEAT_07X-MEDIUM",
				"RMS EVE_WHEAT_09X-GRANDE",
				"RMS EVE_WHEAT_09X-JUMBO",
				"RMS EVE_WHEAT_10X-MANGA",
				"RMS EVE_WHEAT_11X-BIG",
				"RMS EVE_WHEAT_12X-EXTRA BIG",
				"RUF ORI_POTATO_07X-MEDIUM",
				"SNM REG_SALTY MIX_01X-SS XSMALL",
				"SNM REG_SALTY MIX_02P-MULTIPK",
				"SNM REG_SALTY MIX_02X-JR LINE",
				"SNM REG_SALTY MIX_04X-SS LARGE",
				"SNM REG_SALTY MIX_07X-MAX",
				"SNM REG_SALTY MIX_07X-MEDIUM",
				"SNM REG_SALTY MIX_09X-GRANDE",
				"SNM REG_SALTY MIX_09X-JUMBO",
				"SNM REG_SALTY MIX_10X-MANGA",
				"SNM REG_SALTY MIX_11X-BIG",
				"SNM REG_SALTY MIX_12X-EXTRA BIG",
				"STX REG_POTATO_01X-SS XSMALL",
				"STX REG_POTATO_02P-MULTIPK",
				"STX REG_POTATO_07X-MEDIUM",
				"STX REG_POTATO_10X-MANGA",
				"STX REG_POTATO_20P-MULTIPK",
				"TOD BLA_CHOC CHIP CKY_02X-JR LINE",
				"TOD BLA_CHOC CHIP CKY_09X-GRANDE",
				"TOD BLO_SNDWCH CKY_02X-JR LINE",
				"TOD BLO_SNDWCH CKY_04X-SS LARGE",
				"TOD BLO_SNDWCH CKY_09X-GRANDE",
				"TOD BLO_SNDWCH CKY_30P-MULTIPK",
				"TOD CKM_FILL CKY_02X-JR LINE",
				"TOD CLA_CHOC CHIP CKY_01X-SS XSMALL",
				"TOD CLA_CHOC CHIP CKY_02X-JR LINE",
				"TOD CLA_CHOC CHIP CKY_04X-SS LARGE",
				"TOD CLA_CHOC CHIP CKY_07X-MAX",
				"TOD CLA_CHOC CHIP CKY_07X-MEDIUM",
				"TOD CLA_CHOC CHIP CKY_09X-GRANDE",
				"TOD CLA_CHOC CHIP CKY_11X-BIG",
				"TOD CLA_CHOC CHIP CKY_12X-EXTRA BIG",
				"TOD EXT_CHOC CVR CKY_02X-JR LINE",
				"TOD EXT_CHOC CVR CKY_04X-SS LARGE",
				"TOD EXT_CHOC CVR CKY_07X-MEDIUM",
				"TOD MIN_CHOC CHIP CKY_01X-SS XSMALL",
				"TDP REG_DIP RTE_07X-MAX",
				"TDP REG_DIP RTE_11X-BIG",
				"TDP REG_DIP RTE_12X-EXTRA BIG",
				"TOS REG_TORTIL_01X-SS XSMALL",
				"TOS REG_TORTIL_07X-MEDIUM",
				"TOS REG_TORTIL_09X-JUMBO",
				"TOS REG_TORTIL_11X-BIG",
				"TOS REG_TORTIL_12X-EXTRA BIG",
				"TRG REG_EXTRUD_01X-SS XSMALL",
				"TRG REG_EXTRUD_07X-MAX",
				"TRG REG_EXTRUD_09X-GRANDE",
				"TRG REG_EXTRUD_09X-JUMBO",
				"TRG REG_EXTRUD_11X-BIG",
				"TWI INT_FLAV CRK_01X-SS XSMALL",
				"TWI INT_FLAV CRK_04X-SS LARGE",
				"TWI INT_FLAV CRK_12X-EXTRA BIG",
				"TWI MIN_BAKED CRK_01X-SS XSMALL",
				"TWI MIN_BAKED CRK_04X-SS LARGE",
				"TWI MIN_BAKED CRK_06P-MULTIPK",
				"TWI MIN_BAKED CRK_09X-JUMBO",
				"TWI RIC_RICE CRK_01X-SS XSMALL",
				"TWI RIC_RICE CRK_02X-JR LINE",
			],
		},
		competitor: {
			vendor: [
				"BARCEL",
				"CAFE DO BRASIL",
				"CARLOS ORTIZ",
				"COMERCIAL BUENA MESA",
				"DE LA ROSA",
				"FRUTISA",
				"GRUPO NUTRESA",
				"KELLOGGS",
				"MILLANTU",
				"OTHER VENDOR",
				"PAILLAPEN",
				"PANCHO VILLA",
				"PRIMOR",
				"PRIVATE LABEL",
				"TRENDY",
				"VELARDE",
				"ZE FARMS",
			],
			category: ["SAV-NUTS & SEEDS", "SAV-SALTY"],
			segment: ["SAV-EXTRUDED", "SAV-HLTHY SNACK", "SAV-NUTS", "SAV-OTHER", "SAV-POTATO", "SAV-SALTY MIX", "SAV-TORTILLA", "SAV-WHEAT"],
			brand: [
				"ARCOR MIXED",
				"BUKA",
				"CRUGN BKN EXTRUDED",
				"CRUGN BKN HEALTHY",
				"CRUGN BKN POTATO",
				"CURRITOS REGULAR",
				"DE LA ROSA",
				"EL ARRIERO REGULAR",
				"FRUTISA MIXED",
				"FRUTISA PREMIUM",
				"FRUTISA SAL",
				"GRANUTS",
				"INFERNO",
				"KAZAI",
				"KRYZPO REGULAR",
				"MARCO POLO CASERAS",
				"MARCO POLO CORTE AMERICANO",
				"MARCO POLO CRUNCHIS",
				"MARCO POLO HEALTHY",
				"MARCO POLO MIX",
				"MARCO POLO MIXED",
				"MARCO POLO NUTS",
				"MARCO POLO OTHER",
				"MARCO POLO POTATO",
				"MARCO POLO PREMIUM",
				"MARCO POLO RAMITAS",
				"MARCO POLO RUSTICAS",
				"MARCO POLO SABORES",
				"MARCO POLO SAL",
				"MARCO POLO SNACK MIX",
				"MARCO POLO TORTILLA",
				"MILLANTU FLAVORED",
				"MILLANTU JAPONES",
				"MILLANTU MIXED",
				"MILLANTU PREMIUM",
				"MILLANTU SAL",
				"MILLANTU SIN SAL",
				"OTHER SUBBRAND EXTRUDED",
				"OTHER SUBBRAND NUTS",
				"OTHER SUBBRAND OTHER",
				"OTHER SUBBRAND POTATO",
				"OTHER SUBBRAND WHEAT",
				"PANCHO VILLA JAPONES",
				"PANCHO VILLA NACHOS",
				"PANCHO VILLA PANCHITOS",
				"PANCHO VILLA PAPAS",
				"PATPOT",
				"PRIMOR REGULAR",
				"PRINGLES REGULAR",
				"PRIVATE LABEL EXTRUDED",
				"PRIVATE LABEL NUTS",
				"PRIVATE LABEL PREMIUM",
				"PRIVATE LABEL TORTILLA",
				"PRIVATE LABEL WHEAT",
				"ROOTS REGULAR",
				"SABU MIXED",
				"SABU SAL",
				"SABU SIN SAL",
				"TAKIS FUEGO",
				"TAKIS OTHER SUBBRAND",
				"TAKIS REGULAR",
				"TIKA ARTESAN CHIPS",
				"TIKA CHIPS",
				"TIKA NATIVE",
				"TIKA TORTIKA",
				"TIM EXTRUDED",
				"TIM OTHER",
				"TIM POTATO",
				"TIM SAL",
				"TIM WHEAT",
				"VIVO FRUTOS MIXED",
			],
			subBrand: [
				"ARCOR MIXED",
				"BUKA",
				"CRUGN BKN EXTRUDED",
				"CRUGN BKN HEALTHY",
				"CRUGN BKN POTATO",
				"CURRITOS REGULAR",
				"DE LA ROSA",
				"EL ARRIERO REGULAR",
				"FRUTISA MIXED",
				"FRUTISA PREMIUM",
				"FRUTISA SAL",
				"GRANUTS",
				"INFERNO",
				"KAZAI",
				"KRYZPO REGULAR",
				"MARCO POLO CASERAS",
				"MARCO POLO CORTE AMERICANO",
				"MARCO POLO CRUNCHIS",
				"MARCO POLO HEALTHY",
				"MARCO POLO MIX",
				"MARCO POLO MIXED",
				"MARCO POLO NUTS",
				"MARCO POLO OTHER",
				"MARCO POLO POTATO",
				"MARCO POLO PREMIUM",
				"MARCO POLO RAMITAS",
				"MARCO POLO RUSTICAS",
				"MARCO POLO SABORES",
				"MARCO POLO SAL",
				"MARCO POLO SNACK MIX",
				"MARCO POLO TORTILLA",
				"MILLANTU FLAVORED",
				"MILLANTU JAPONES",
				"MILLANTU MIXED",
				"MILLANTU PREMIUM",
				"MILLANTU SAL",
				"MILLANTU SIN SAL",
				"OTHER SUBBRAND EXTRUDED",
				"OTHER SUBBRAND NUTS",
				"OTHER SUBBRAND OTHER",
				"OTHER SUBBRAND POTATO",
				"OTHER SUBBRAND WHEAT",
				"PANCHO VILLA JAPONES",
				"PANCHO VILLA NACHOS",
				"PANCHO VILLA PANCHITOS",
				"PANCHO VILLA PAPAS",
				"PATPOT",
				"PRIMOR REGULAR",
				"PRINGLES REGULAR",
				"PRIVATE LABEL EXTRUDED",
				"PRIVATE LABEL NUTS",
				"PRIVATE LABEL PREMIUM",
				"PRIVATE LABEL TORTILLA",
				"PRIVATE LABEL WHEAT",
				"ROOTS REGULAR",
				"SABU MIXED",
				"SABU SAL",
				"SABU SIN SAL",
				"TAKIS FUEGO",
				"TAKIS OTHER SUBBRAND",
				"TAKIS REGULAR",
				"TIKA ARTESAN CHIPS",
				"TIKA CHIPS",
				"TIKA NATIVE",
				"TIKA TORTIKA",
				"TIM EXTRUDED",
				"TIM OTHER",
				"TIM POTATO",
				"TIM SAL",
				"TIM WHEAT",
				"VIVO FRUTOS MIXED",
			],
			packSize: [
				"01X-MINI",
				"01X-SS XSMALL",
				"02P-MULTIPK",
				"02X-JR LINE",
				"03P-MULTIPK",
				"04P-MULTIPK",
				"04X-SS LARGE",
				"06P-MULTIPK",
				"07X-MAX",
				"07X-MEDIUM",
				"09X-GRANDE",
				"09X-JUMBO",
				"10X-MANGA",
				"11X-BIG",
				"12X-EXTRA BIG",
				"15X-MEGA",
				"16X-SUPER SIZE",
				"20P-MULTIPK",
				"50P-MULTIPK",
			],
			permutation: [
				"ACR MIX_NUTS_01X-SS XSMALL",
				"ACR MIX_NUTS_02X-JR LINE",
				"BUKA_POTATO_04X-SS LARGE",
				"BUKA_POTATO_07X-MAX",
				"BUKA_POTATO_07X-MEDIUM",
				"CRG EXT_EXTRUD_01X-MINI",
				"CRG EXT_EXTRUD_07X-MAX",
				"CRG EXT_EXTRUD_09X-GRANDE",
				"CRG EXT_EXTRUD_09X-JUMBO",
				"CRG HTY_HLTHY SNK_01X-MINI",
				"CRG POT_POTATO_01X-MINI",
				"CRG POT_POTATO_01X-SS XSMALL",
				"CRG POT_POTATO_07X-MAX",
				"CRG POT_POTATO_09X-GRANDE",
				"CRG POT_POTATO_09X-JUMBO",
				"CIS REG_OTH SAV_02X-JR LINE",
				"CIS REG_OTH SAV_04X-SS LARGE",
				"DLA ROS_NUTS_01X-SS XSMALL",
				"DLA ROS_NUTS_04X-SS LARGE",
				"DLA ROS_NUTS_07X-MAX",
				"DLA ROS_NUTS_07X-MEDIUM",
				"DLA ROS_NUTS_12X-EXTRA BIG",
				"DLA ROS_NUTS_16X-SUPER SIZE",
				"ELA REG_OTH SAV_01X-MINI",
				"ELA REG_OTH SAV_01X-SS XSMALL",
				"ELA REG_OTH SAV_02X-JR LINE",
				"ELA REG_OTH SAV_04X-SS LARGE",
				"ELA REG_OTH SAV_07X-MAX",
				"ELA REG_OTH SAV_07X-MEDIUM",
				"ELA REG_OTH SAV_09X-GRANDE",
				"ELA REG_OTH SAV_12X-EXTRA BIG",
				"FRS MIX_NUTS_01X-SS XSMALL",
				"FRS MIX_NUTS_02X-JR LINE",
				"FRS MIX_NUTS_04X-SS LARGE",
				"FRS MIX_NUTS_07X-MAX",
				"FRS MIX_NUTS_07X-MEDIUM",
				"FRS MIX_NUTS_09X-JUMBO",
				"FRS PRE_NUTS_02X-JR LINE",
				"FRS PRE_NUTS_04X-SS LARGE",
				"FRS PRE_NUTS_07X-MEDIUM",
				"FRS PRE_NUTS_09X-JUMBO",
				"FRS SAL_NUTS_01X-SS XSMALL",
				"FRS SAL_NUTS_07X-MAX",
				"FRS SAL_NUTS_07X-MEDIUM",
				"FRS SAL_NUTS_09X-JUMBO",
				"GR NUT_NUTS_01X-SS XSMALL",
				"GR NUT_NUTS_02X-JR LINE",
				"GR NUT_NUTS_04X-SS LARGE",
				"GR NUT_NUTS_07X-MEDIUM",
				"INFR_TORTIL_04X-SS LARGE",
				"INFR_TORTIL_07X-MAX",
				"INFR_TORTIL_07X-MEDIUM",
				"INFR_TORTIL_09X-GRANDE",
				"KAZ_NUTS_02X-JR LINE",
				"KAZ_NUTS_04X-SS LARGE",
				"KAZ_NUTS_07X-MAX",
				"KAZ_NUTS_12X-EXTRA BIG",
				"KRY REG_POTATO_01X-SS XSMALL",
				"KRY REG_POTATO_02P-MULTIPK",
				"KRY REG_POTATO_02X-JR LINE",
				"KRY REG_POTATO_04P-MULTIPK",
				"KRY REG_POTATO_04X-SS LARGE",
				"KRY REG_POTATO_06P-MULTIPK",
				"KRY REG_POTATO_07X-MAX",
				"KRY REG_POTATO_07X-MEDIUM",
				"KRY REG_POTATO_09X-JUMBO",
				"KRY REG_POTATO_10X-MANGA",
				"KRY REG_POTATO_12X-EXTRA BIG",
				"MRP CAS_POTATO_01X-MINI",
				"MRP CAS_POTATO_01X-SS XSMALL",
				"MRP CAS_POTATO_04X-SS LARGE",
				"MRP CAS_POTATO_07X-MAX",
				"MRP CAS_POTATO_07X-MEDIUM",
				"MRP CAS_POTATO_09X-GRANDE",
				"MRP CAS_POTATO_09X-JUMBO",
				"MRP CAS_POTATO_11X-BIG",
				"MRP CAS_POTATO_12X-EXTRA BIG",
				"MRP COR_POTATO_01X-SS XSMALL",
				"MRP COR_POTATO_04X-SS LARGE",
				"MRP COR_POTATO_07X-MAX",
				"MRP COR_POTATO_07X-MEDIUM",
				"MRP COR_POTATO_09X-GRANDE",
				"MRP COR_POTATO_09X-JUMBO",
				"MRP COR_POTATO_11X-BIG",
				"MRP COR_POTATO_12X-EXTRA BIG",
				"MRP CRU_EXTRUD_01X-SS XSMALL",
				"MRP CRU_EXTRUD_04X-SS LARGE",
				"MRP CRU_EXTRUD_07X-MEDIUM",
				"MRP CRU_EXTRUD_09X-GRANDE",
				"MRP CRU_EXTRUD_09X-JUMBO",
				"MRP CRU_EXTRUD_11X-BIG",
				"MRP CRU_EXTRUD_12X-EXTRA BIG",
				"MRP HTY_HLTHY SNK_07X-MAX",
				"MRP MIX_SALTY MIX_01X-SS XSMALL",
				"MRP MIX_SALTY MIX_04X-SS LARGE",
				"MRP MIX_SALTY MIX_09X-JUMBO",
				"MRP MIX_SALTY MIX_11X-BIG",
				"MRP MIX_NUTS_02X-JR LINE",
				"MRP MIX_NUTS_04X-SS LARGE",
				"MRP MIX_NUTS_07X-MAX",
				"MRP MIX_NUTS_11X-BIG",
				"MRP NUT_NUTS_02X-JR LINE",
				"MRP NUT_NUTS_03P-MULTIPK",
				"MRP NUT_NUTS_04X-SS LARGE",
				"MRP NUT_NUTS_07X-MAX",
				"MRP NUT_NUTS_07X-MEDIUM",
				"MRP NUT_NUTS_09X-GRANDE",
				"MRP NUT_NUTS_09X-JUMBO",
				"MRP NUT_NUTS_11X-BIG",
				"MRP NUT_NUTS_12X-EXTRA BIG",
				"MRP OTH_OTH SAV_07X-MAX",
				"MRP OTH_OTH SAV_07X-MEDIUM",
				"MRP OTH_OTH SAV_09X-GRANDE",
				"MRP POT_POTATO_04X-SS LARGE",
				"MRP POT_POTATO_07X-MEDIUM",
				"MRP PRE_NUTS_02X-JR LINE",
				"MRP PRE_NUTS_04X-SS LARGE",
				"MRP RAM_WHEAT_01X-SS XSMALL",
				"MRP RAM_WHEAT_07X-MEDIUM",
				"MRP RAM_WHEAT_09X-GRANDE",
				"MRP RAM_WHEAT_09X-JUMBO",
				"MRP RAM_WHEAT_11X-BIG",
				"MRP RUS_POTATO_01X-SS XSMALL",
				"MRP RUS_POTATO_07X-MAX",
				"MRP RUS_POTATO_07X-MEDIUM",
				"MRP RUS_POTATO_09X-JUMBO",
				"MRP RUS_POTATO_11X-BIG",
				"MRP SAB_POTATO_01X-MINI",
				"MRP SAB_POTATO_01X-SS XSMALL",
				"MRP SAB_POTATO_07X-MAX",
				"MRP SAL_NUTS_01X-SS XSMALL",
				"MRP SAL_NUTS_02X-JR LINE",
				"MRP SAL_NUTS_04X-SS LARGE",
				"MRP SAL_NUTS_07X-MAX",
				"MRP SAL_NUTS_07X-MEDIUM",
				"MRP SAL_NUTS_09X-GRANDE",
				"MRP SAL_NUTS_09X-JUMBO",
				"MRP SAL_NUTS_12X-EXTRA BIG",
				"MRP SNA_SALTY MIX_01X-SS XSMALL",
				"MRP SNA_SALTY MIX_04X-SS LARGE",
				"MRP TOR_TORTIL_01X-SS XSMALL",
				"MRP TOR_TORTIL_04X-SS LARGE",
				"MRP TOR_TORTIL_07X-MAX",
				"MRP TOR_TORTIL_07X-MEDIUM",
				"MRP TOR_TORTIL_09X-GRANDE",
				"MRP TOR_TORTIL_09X-JUMBO",
				"MRP TOR_TORTIL_11X-BIG",
				"MLL FLV_NUTS_04X-SS LARGE",
				"MLL JPN_NUTS_04X-SS LARGE",
				"MLL MIX_NUTS_02X-JR LINE",
				"MLL MIX_NUTS_04X-SS LARGE",
				"MLL MIX_NUTS_07X-MAX",
				"MLL PRE_NUTS_02X-JR LINE",
				"MLL PRE_NUTS_07X-MAX",
				"MLL PRE_NUTS_07X-MEDIUM",
				"MLL SAL_NUTS_02X-JR LINE",
				"MLL SAL_NUTS_04X-SS LARGE",
				"MLL SAL_NUTS_07X-MAX",
				"MLL SAL_NUTS_07X-MEDIUM",
				"MLL SSL_NUTS_07X-MAX",
				"OTH EXT_EXTRUD_01X-MINI",
				"OTH EXT_EXTRUD_01X-SS XSMALL",
				"OTH EXT_EXTRUD_02X-JR LINE",
				"OTH EXT_EXTRUD_04X-SS LARGE",
				"OTH EXT_EXTRUD_07X-MAX",
				"OTH EXT_EXTRUD_07X-MEDIUM",
				"OTH EXT_EXTRUD_09X-GRANDE",
				"OTH EXT_EXTRUD_09X-JUMBO",
				"OTH EXT_EXTRUD_11X-BIG",
				"OTH EXT_EXTRUD_12X-EXTRA BIG",
				"OTH EXT_EXTRUD_20P-MULTIPK",
				"OTH HTY_NUTS_01X-MINI",
				"OTH HTY_NUTS_01X-SS XSMALL",
				"OTH HTY_NUTS_02X-JR LINE",
				"OTH HTY_NUTS_04X-SS LARGE",
				"OTH HTY_NUTS_07X-MAX",
				"OTH HTY_NUTS_07X-MEDIUM",
				"OTH HTY_NUTS_09X-GRANDE",
				"OTH HTY_NUTS_09X-JUMBO",
				"OTH HTY_NUTS_11X-BIG",
				"OTH HTY_NUTS_12X-EXTRA BIG",
				"OTH HTY_NUTS_16X-SUPER SIZE",
				"OTH OTH_OTH SAV_01X-MINI",
				"OTH OTH_OTH SAV_01X-SS XSMALL",
				"OTH OTH_OTH SAV_02X-JR LINE",
				"OTH OTH_OTH SAV_04X-SS LARGE",
				"OTH OTH_OTH SAV_07X-MAX",
				"OTH OTH_OTH SAV_07X-MEDIUM",
				"OTH OTH_OTH SAV_09X-GRANDE",
				"OTH POT_POTATO_01X-SS XSMALL",
				"OTH POT_POTATO_02X-JR LINE",
				"OTH POT_POTATO_04X-SS LARGE",
				"OTH POT_POTATO_07X-MAX",
				"OTH POT_POTATO_07X-MEDIUM",
				"OTH POT_POTATO_09X-GRANDE",
				"OTH POT_POTATO_09X-JUMBO",
				"OTH POT_POTATO_11X-BIG",
				"OTH POT_POTATO_12X-EXTRA BIG",
				"OTH WHT_WHEAT_04X-SS LARGE",
				"OTH WHT_WHEAT_07X-MEDIUM",
				"OTH WHT_WHEAT_09X-GRANDE",
				"OTH WHT_WHEAT_11X-BIG",
				"PNV JPN_NUTS_04X-SS LARGE",
				"PNV JPN_NUTS_07X-MEDIUM",
				"PNV NAC_TORTIL_01X-SS XSMALL",
				"PNV NAC_TORTIL_07X-MAX",
				"PNV NAC_TORTIL_11X-BIG",
				"PNV PAN_TORTIL_02X-JR LINE",
				"PNV PAN_TORTIL_11X-BIG",
				"PAV PAP_POTATO_07X-MEDIUM",
				"PATP_POTATO_07X-MEDIUM",
				"PRM REG_POTATO_07X-MAX",
				"PRM REG_POTATO_09X-GRANDE",
				"PRI REG_POTATO_04X-SS LARGE",
				"PRI REG_POTATO_07X-MAX",
				"PRI REG_POTATO_07X-MEDIUM",
				"PVL EXT_EXTRUD_01X-SS XSMALL",
				"PVL EXT_EXTRUD_02X-JR LINE",
				"PVL EXT_EXTRUD_04X-SS LARGE",
				"PVL EXT_EXTRUD_07X-MAX",
				"PVL EXT_EXTRUD_07X-MEDIUM",
				"PVL EXT_EXTRUD_09X-GRANDE",
				"PVL EXT_EXTRUD_09X-JUMBO",
				"PVL EXT_EXTRUD_10X-MANGA",
				"PVL EXT_EXTRUD_11X-BIG",
				"PVL EXT_EXTRUD_12X-EXTRA BIG",
				"PVL EXT_EXTRUD_15X-MEGA",
				"PVL EXT_EXTRUD_16X-SUPER SIZE",
				"PVL EXT_EXTRUD_50P-MULTIPK",
				"PVL NUT_NUTS_04X-SS LARGE",
				"PVL NUT_NUTS_07X-MAX",
				"PVL NUT_NUTS_07X-MEDIUM",
				"PVL NUT_NUTS_09X-JUMBO",
				"PVL NUT_NUTS_11X-BIG",
				"PVL NUT_NUTS_12X-EXTRA BIG",
				"PVL NUT_NUTS_16X-SUPER SIZE",
				"PV PRE_NUTS_07X-MAX",
				"PV PRE_NUTS_09X-GRANDE",
				"PV PRE_NUTS_11X-BIG",
				"PVL TOR_TORTIL_09X-JUMBO",
				"PVL WHT_WHEAT_04P-MULTIPK",
				"PVL WHT_WHEAT_07X-MAX",
				"PVL WHT_WHEAT_09X-GRANDE",
				"PVL WHT_WHEAT_12X-EXTRA BIG",
				"PVL WHT_WHEAT_16X-SUPER SIZE",
				"ROO REG_OTH SAV_09X-GRANDE",
				"SBU MIX_NUTS_12X-EXTRA BIG",
				"SBU SAL_NUTS_02X-JR LINE",
				"SBU SAL_NUTS_07X-MAX",
				"SBU SAL_NUTS_12X-EXTRA BIG",
				"SBU SSL_NUTS_02X-JR LINE",
				"SBU SSL_NUTS_04X-SS LARGE",
				"SBU SSL_NUTS_07X-MAX",
				"SBU SSL_NUTS_07X-MEDIUM",
				"TAK FUE_TORTIL_02X-JR LINE",
				"TAK FUE_TORTIL_07X-MAX",
				"TAK FUE_TORTIL_09X-JUMBO",
				"TAK OTH_TORTIL_02X-JR LINE",
				"TAK OTH_TORTIL_04X-SS LARGE",
				"TAK OTH_TORTIL_07X-MAX",
				"TAK OTH_TORTIL_07X-MEDIUM",
				"TAK OTH_TORTIL_09X-JUMBO",
				"TAK REG_TORTIL_01X-SS XSMALL",
				"TAK REG_TORTIL_02X-JR LINE",
				"TAK REG_TORTIL_04X-SS LARGE",
				"TAK REG_TORTIL_07X-MAX",
				"TAK REG_TORTIL_07X-MEDIUM",
				"TAK REG_TORTIL_09X-JUMBO",
				"TIK ART_OTH SAV_04P-MULTIPK",
				"TIK ART_OTH SAV_07X-MAX",
				"TIK ART_OTH SAV_07X-MEDIUM",
				"TIK CHI_HLTHY SNK_01X-SS XSMALL",
				"TIK CHI_HLTHY SNK_04X-SS LARGE",
				"TIK CHI_HLTHY SNK_07X-MAX",
				"TIK CHI_HLTHY SNK_07X-MEDIUM",
				"TIK CHI_HLTHY SNK_09X-GRANDE",
				"TIK NAT_POTATO_01X-SS XSMALL",
				"TIK NAT_POTATO_04X-SS LARGE",
				"TIK NAT_POTATO_07X-MAX",
				"TIK NAT_POTATO_07X-MEDIUM",
				"TIK NAT_POTATO_09X-GRANDE",
				"TIK NAT_POTATO_12X-EXTRA BIG",
				"TIK TOR_TORTIL_07X-MAX",
				"TIM EXT_EXTRUD_09X-JUMBO",
				"TIM OTH_OTH SAV_01X-SS XSMALL",
				"TIM POT_POTATO_01X-MINI",
				"TIM POT_POTATO_01X-SS XSMALL",
				"TIM POT_POTATO_02X-JR LINE",
				"TIM POT_POTATO_07X-MAX",
				"TIM POT_POTATO_07X-MEDIUM",
				"TIM POT_POTATO_09X-GRANDE",
				"TIM POT_POTATO_09X-JUMBO",
				"TIM POT_POTATO_11X-BIG",
				"TIM POT_POTATO_12X-EXTRA BIG",
				"TIM SAL_NUTS_01X-MINI",
				"TIM SAL_NUTS_07X-MAX",
				"TIM SAL_NUTS_09X-GRANDE",
				"TIM SAL_NUTS_09X-JUMBO",
				"TIM SAL_NUTS_11X-BIG",
				"TIM WHT_WHEAT_01X-MINI",
				"TIM WHT_WHEAT_09X-GRANDE",
				"TIM WHT_WHEAT_09X-JUMBO",
				"TIM WHT_WHEAT_11X-BIG",
				"VVF MIX_NUTS_01X-SS XSMALL",
				"VVF MIX_NUTS_02X-JR LINE",
			],
		},
		startDate: "01/01/2024",
		endDate: "03/29/2024",
		variable1: "SOM",
		SOMMeasure: "Category",
		SOMGroup: "Volume",
		APIUnits: "Units",
	},
	{
		country: "ARGENTINA",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL ARGENTINA-DTS"],
		channel: ["DTS"],
		periodView: ["na"],
		viewShareby: "valueSales",
		indexes: ["20", "10"],
		pepsi: {
			category: ["BIS-COOKIES", "BIS-CRACKERS", "FDS-BARS", "FDS-OATS", "FDS-RTE CEREAL", "SAV-NUTS & SEEDS", "SAV-SALTY"],
			segment: [
				"CKY-BREAKFAST",
				"CKY-CHOC CHIP",
				"CKY-CHOC COVER",
				"CKY-OAT_QUAKER",
				"CKY-OTHER",
				"CKY-SANDWICH",
				"CRK-BAKED",
				"CRK-FLAVOR",
				"CRK-OTHER",
				"CRK-PLAIN",
				"CRK-RICE",
				"FDS-BARS CHEWY",
				"FDS-BARS NUTR",
				"FDS-BARS OAT",
				"FDS-CEREAL ADULT",
				"FDS-CEREAL GRANOLA",
				"FDS-CEREAL KIDS",
				"FDS-OATS INSTANT",
				"FDS-OATS MILD_PWD",
				"FDS-OATS TRAD",
				"SAV-EXTRUDED",
				"SAV-HLTHY SNACK",
				"SAV-NUTS",
				"SAV-OTHER",
				"SAV-PELLET",
				"SAV-POPCORN-RTE",
				"SAV-POTATO",
				"SAV-SALTY MIX",
				"SAV-TORTILLA",
				"SAV-WHEAT",
			],
			brand: [
				"3DS REGULAR",
				"ARGENTITAS REGULAR",
				"ARGENTITAS SIN SAL",
				"BUN PAPAS",
				"BUN REGULAR",
				"CHEETOS REGULAR",
				"DALE REGULAR",
				"DE TODITO MIX 3DS",
				"DE TODITO MIX DORITOS",
				"DE TODITO REGULAR",
				"DORITOS DIPPAS",
				"DORITOS FLAVOURS",
				"DORITOS QUESO",
				"GATORADE BARS",
				"KESBUN PIZZA",
				"KESBUN REGULAR",
				"LAYS ACANALADAS",
				"LAYS BAKED",
				"LAYS CLASSIC",
				"LAYS FLAVOURS",
				"LAYS GOURMET",
				"LAYS OTHER SUBBRAND",
				"LAYS PAY",
				"LAYS SIN SAL",
				"LAYS STAX",
				"MABEL COOKIE",
				"MABEL SALTY",
				"MAGICA POLENTA",
				"NATUCHIPS REGULAR",
				"PALI CHIPS",
				"PEHUAMAR ACANALADAS",
				"PEHUAMAR CLASICAS",
				"PEHUAMAR MAICITOS",
				"PEHUAMAR MAICITOS REJILLAS",
				"PEHUAMAR MANI CON PIEL",
				"PEHUAMAR MANI JAPONES",
				"PEHUAMAR MANI PELADO",
				"PEHUAMAR MANI SALADO",
				"PEHUAMAR MIX",
				"PEHUAMAR PALITOS",
				"PEP PALITOS",
				"PEP RAMITAS",
				"PEP RUEDITAS",
				"POP KORN REGULAR",
				"QUAKER BARS CEREAL",
				"QUAKER CEREAL GRANOLA",
				"QUAKER CEREAL HONEY GRAHAM",
				"QUAKER CEREAL HONEY NUTS",
				"QUAKER CEREAL QUADRITOS",
				"QUAKER CRACKER REGULAR",
				"QUAKER GALLETAS MINI",
				"QUAKER GALLETAS MOLDE",
				"QUAKER GALLETAS RELLENA",
				"QUAKER GALLETAS WC",
				"QUAKER OATS EXTRA-FINA",
				"QUAKER OATS INSTANT",
				"QUAKER OATS REGULAR",
				"SUNBITES REGULAR",
				"TODDY CHISPAS",
				"TODDY CHISPAS BLACK",
				"TODDY CHISPAS DDL",
				"TODDY CHOCOLAT",
				"TODDY COCOA BLAST",
				"TODDY EXTRA",
				"TODDY ONE",
				"TODDY RELLENAS",
				"TOSTITOS REGULAR",
				"TWISTOS CRACKERS",
				"TWISTOS MINITOSTADITAS",
				"TWISTOS MULTICEREAL",
				"TWISTOS RICE SNACK",
				"ZUCOA REGULAR",
			],
			subBrand: [
				"3DS REGULAR",
				"ARGENTITAS REGULAR",
				"ARGENTITAS SIN SAL",
				"BUN PAPAS",
				"BUN REGULAR",
				"CHEETOS REGULAR",
				"DALE REGULAR",
				"DE TODITO MIX 3DS",
				"DE TODITO MIX DORITOS",
				"DE TODITO REGULAR",
				"DORITOS DIPPAS",
				"DORITOS FLAVOURS",
				"DORITOS QUESO",
				"GATORADE BARS",
				"KESBUN PIZZA",
				"KESBUN REGULAR",
				"LAYS ACANALADAS",
				"LAYS BAKED",
				"LAYS CLASSIC",
				"LAYS FLAVOURS",
				"LAYS GOURMET",
				"LAYS OTHER SUBBRAND",
				"LAYS PAY",
				"LAYS SIN SAL",
				"LAYS STAX",
				"MABEL COOKIE",
				"MABEL SALTY",
				"MAGICA POLENTA",
				"NATUCHIPS REGULAR",
				"PALI CHIPS",
				"PEHUAMAR ACANALADAS",
				"PEHUAMAR CLASICAS",
				"PEHUAMAR MAICITOS",
				"PEHUAMAR MAICITOS REJILLAS",
				"PEHUAMAR MANI CON PIEL",
				"PEHUAMAR MANI JAPONES",
				"PEHUAMAR MANI PELADO",
				"PEHUAMAR MANI SALADO",
				"PEHUAMAR MIX",
				"PEHUAMAR PALITOS",
				"PEP PALITOS",
				"PEP RAMITAS",
				"PEP RUEDITAS",
				"POP KORN REGULAR",
				"QUAKER BARS CEREAL",
				"QUAKER CEREAL GRANOLA",
				"QUAKER CEREAL HONEY GRAHAM",
				"QUAKER CEREAL HONEY NUTS",
				"QUAKER CEREAL QUADRITOS",
				"QUAKER CRACKER REGULAR",
				"QUAKER GALLETAS MINI",
				"QUAKER GALLETAS MOLDE",
				"QUAKER GALLETAS RELLENA",
				"QUAKER GALLETAS WC",
				"QUAKER OATS EXTRA-FINA",
				"QUAKER OATS INSTANT",
				"QUAKER OATS REGULAR",
				"SUNBITES REGULAR",
				"TODDY CHISPAS",
				"TODDY CHISPAS BLACK",
				"TODDY CHISPAS DDL",
				"TODDY CHOCOLAT",
				"TODDY COCOA BLAST",
				"TODDY EXTRA",
				"TODDY ONE",
				"TODDY RELLENAS",
				"TOSTITOS REGULAR",
				"TWISTOS CRACKERS",
				"TWISTOS MINITOSTADITAS",
				"TWISTOS MULTICEREAL",
				"TWISTOS RICE SNACK",
				"ZUCOA REGULAR",
			],
			packSize: [
				"01X-MINI",
				"01X-SS XSMALL",
				"02X-JR LINE",
				"04P-MULTIPK",
				"04X-SS LARGE",
				"07X-MAX",
				"07X-MEDIUM",
				"09X-GRANDE",
				"09X-JUMBO",
				"11X-BIG",
				"12X-EXTRA BIG",
				"15X-MEGA",
				"16X-SUPER SIZE",
			],
			vendor: ["PEPSICO"],
			permutation: [
				"3DS REG_PELLET_01X-MINI",
				"3DS REG_PELLET_01X-SS XSMALL",
				"3DS REG_PELLET_02X-JR LINE",
				"3DS REG_PELLET_04X-SS LARGE",
				"3DS REG_PELLET_07X-MAX",
				"3DS REG_PELLET_07X-MEDIUM",
				"AGN REG_PLAIN CRK_11X-BIG",
				"AGN SSL_PLAIN CRK_12X-EXTRA BIG",
				"BUN PAP_POTATO_04X-SS LARGE",
				"BUN PAP_POTATO_07X-MAX",
				"BUN PAP_POTATO_07X-MEDIUM",
				"BUN PAP_POTATO_09X-JUMBO",
				"BUN REG_EXTRUD_04X-SS LARGE",
				"BUN REG_EXTRUD_07X-MAX",
				"BUN REG_EXTRUD_12X-EXTRA BIG",
				"CTS REG_EXTRUD_01X-MINI",
				"CTS REG_EXTRUD_01X-SS XSMALL",
				"CTS REG_EXTRUD_02X-JR LINE",
				"CTS REG_EXTRUD_04X-SS LARGE",
				"CTS REG_EXTRUD_07X-MAX",
				"CTS REG_EXTRUD_07X-MEDIUM",
				"CTS REG_TORTIL_07X-MEDIUM",
				"CTS REG_EXTRUD_09X-GRANDE",
				"DLE REG_SNDWCH CKY_04X-SS LARGE",
				"DLE REG_SNDWCH CKY_07X-MAX",
				"DLE REG_SNDWCH CKY_07X-MEDIUM",
				"DLE REG_SNDWCH CKY_09X-GRANDE",
				"DLE REG_SNDWCH CKY_09X-JUMBO",
				"DLE REG_SNDWCH CKY_12X-EXTRA BIG",
				"DET M3D_SALTY MIX_02X-JR LINE",
				"DET M3D_SALTY MIX_04X-SS LARGE",
				"DET M3D_SALTY MIX_07X-MAX",
				"DET M3D_SALTY MIX_07X-MEDIUM",
				"DET M3D_SALTY MIX_09X-GRANDE",
				"DET M3D_SALTY MIX_11X-BIG",
				"DET MDO_SALTY MIX_02X-JR LINE",
				"DET MDO_SALTY MIX_04X-SS LARGE",
				"DET MDO_SALTY MIX_07X-MAX",
				"DET MDO_SALTY MIX_07X-MEDIUM",
				"DET REG_SALTY MIX_01X-SS XSMALL",
				"DET REG_SALTY MIX_02X-JR LINE",
				"DET REG_SALTY MIX_04X-SS LARGE",
				"DET REG_SALTY MIX_07X-MAX",
				"DET REG_SALTY MIX_07X-MEDIUM",
				"DET REG_SALTY MIX_09X-GRANDE",
				"DET REG_SALTY MIX_09X-JUMBO",
				"DET REG_SALTY MIX_11X-BIG",
				"DET REG_SALTY MIX_12X-EXTRA BIG",
				"DOR DIP_TORTIL_07X-MEDIUM",
				"DOR FVR_TORTIL_01X-SS XSMALL",
				"DOR FVR_TORTIL_02X-JR LINE",
				"DOR FVR_TORTIL_04X-SS LARGE",
				"DOR FVR_TORTIL_07X-MEDIUM",
				"DOR QUE_TORTIL_01X-MINI",
				"DOR QUE_TORTIL_01X-SS XSMALL",
				"DOR QUE_TORTIL_02X-JR LINE",
				"DOR QUE_TORTIL_04X-SS LARGE",
				"DOR QUE_TORTIL_07X-MAX",
				"DOR QUE_POTATO_07X-MEDIUM",
				"DOR QUE_TORTIL_07X-MEDIUM",
				"DOR QUE_TORTIL_09X-GRANDE",
				"DOR QUE_TORTIL_09X-JUMBO",
				"DOR QUE_TORTIL_11X-BIG",
				"DOR QUE_TORTIL_12X-EXTRA BIG",
				"GRD BAR_NUTR BAR_01X-SS XSMALL",
				"KSB PIZ_FLAV CRK_07X-MEDIUM",
				"KSB REG_FLAV CRK_07X-MEDIUM",
				"LAY ACA_POTATO_01X-SS XSMALL",
				"LAY ACA_POTATO_02X-JR LINE",
				"LAY ACA_POTATO_07X-MAX",
				"LAY ACA_POTATO_07X-MEDIUM",
				"LAY ACA_POTATO_09X-GRANDE",
				"LAY BKD_POTATO_01X-SS XSMALL",
				"LAY BKD_POTATO_02X-JR LINE",
				"LAY BKD_POTATO_07X-MEDIUM",
				"LAY CLA_POTATO_01X-MINI",
				"LAY CLA_POTATO_01X-SS XSMALL",
				"LAY CLA_POTATO_02X-JR LINE",
				"LAY CLA_POTATO_04X-SS LARGE",
				"LAY CLA_POTATO_07X-MAX",
				"LAY CLA_POTATO_07X-MEDIUM",
				"LAY CLA_POTATO_09X-GRANDE",
				"LAY CLA_POTATO_09X-JUMBO",
				"LAY CLA_POTATO_11X-BIG",
				"LAY CLA_POTATO_12X-EXTRA BIG",
				"LAY FLV_POTATO_01X-SS XSMALL",
				"LAY FLV_POTATO_02X-JR LINE",
				"LAY FLV_POTATO_04X-SS LARGE",
				"LAY FLV_POTATO_07X-MAX",
				"LAY FLV_POTATO_07X-MEDIUM",
				"LAY FLV_POTATO_11X-BIG",
				"LAY GOU_POTATO_01X-SS XSMALL",
				"LAY GOU_POTATO_02X-JR LINE",
				"LAY GOU_POTATO_04X-SS LARGE",
				"LAY GOU_POTATO_07X-MEDIUM",
				"LAY OTH_POTATO_01X-MINI",
				"LAY OTH_POTATO_01X-SS XSMALL",
				"LAY OTH_POTATO_02X-JR LINE",
				"LAY OTH_POTATO_07X-MAX",
				"LAY OTH_POTATO_07X-MEDIUM",
				"LAY OTH_POTATO_11X-BIG",
				"LAY OTH_POTATO_15X-MEGA",
				"LAY PAY_POTATO_01X-SS XSMALL",
				"LAY PAY_POTATO_07X-MEDIUM",
				"LAY SSL_POTATO_04X-SS LARGE",
				"LAY STX_POTATO_01X-SS XSMALL",
				"LAY STX_POTATO_07X-MAX",
				"LAY STX_POTATO_07X-MEDIUM",
				"MAB COK_OTH CKY_07X-MEDIUM",
				"MAB SLT_OTH SAV_07X-MEDIUM",
				"MAG POL_ADLT-CRL_12X-EXTRA BIG",
				"NAT REG_HLTHY SNK_07X-MAX",
				"PLI CHI_EXTRUD_16X-SUPER SIZE",
				"PEH ACA_POTATO_01X-SS XSMALL",
				"PEH ACA_POTATO_02X-JR LINE",
				"PEH ACA_POTATO_04X-SS LARGE",
				"PEH ACA_POTATO_07X-MAX",
				"PEH ACA_POTATO_07X-MEDIUM",
				"PEH ACA_POTATO_09X-GRANDE",
				"PEH ACA_POTATO_09X-JUMBO",
				"PEH ACA_POTATO_11X-BIG",
				"PEH ACA_POTATO_12X-EXTRA BIG",
				"PEH CLA_POTATO_01X-SS XSMALL",
				"PEH CLA_POTATO_02X-JR LINE",
				"PEH CLA_POTATO_04X-SS LARGE",
				"PEH CLA_POTATO_07X-MAX",
				"PEH CLA_POTATO_07X-MEDIUM",
				"PEH CLA_TORTIL_07X-MEDIUM",
				"PEH CLA_POTATO_09X-GRANDE",
				"PEH CLA_POTATO_09X-JUMBO",
				"PEH CLA_POTATO_11X-BIG",
				"PEH CLA_POTATO_12X-EXTRA BIG",
				"PEH CLA_POTATO_15X-MEGA",
				"PEH CLA_POTATO_16X-SUPER SIZE",
				"PEH MAI_EXTRUD_02X-JR LINE",
				"PEH MAI_EXTRUD_07X-MEDIUM",
				"PEH MAI_EXTRUD_09X-JUMBO",
				"PEH MAI_EXTRUD_11X-BIG",
				"PEH MRE_EXTRUD_02X-JR LINE",
				"PEH MRE_EXTRUD_07X-MEDIUM",
				"PEH MRE_EXTRUD_11X-BIG",
				"PEH MAN_NUTS_01X-SS XSMALL",
				"PEH MAN_NUTS_04X-SS LARGE",
				"PEH MAN_NUTS_07X-MAX",
				"PEH MAN_NUTS_07X-MEDIUM",
				"PEH MJA_NUTS_01X-SS XSMALL",
				"PEH MJA_NUTS_07X-MEDIUM",
				"PEH MJA_NUTS_12X-EXTRA BIG",
				"PEH MPE_NUTS_01X-SS XSMALL",
				"PEH MPE_NUTS_04X-SS LARGE",
				"PEH MPE_NUTS_07X-MAX",
				"PEH MPE_NUTS_07X-MEDIUM",
				"PEH MPE_NUTS_09X-GRANDE",
				"PEH MPE_NUTS_11X-BIG",
				"PEH MPE_NUTS_12X-EXTRA BIG",
				"PEH MSA_NUTS_01X-SS XSMALL",
				"PEH MSA_NUTS_04X-SS LARGE",
				"PEH MSA_NUTS_07X-MAX",
				"PEH MSA_NUTS_07X-MEDIUM",
				"PEH MSA_NUTS_12X-EXTRA BIG",
				"PEH MIX_NUTS_07X-MEDIUM",
				"PEH PAL_WHEAT_04X-SS LARGE",
				"PEH PAL_WHEAT_07X-MAX",
				"PEH PAL_WHEAT_07X-MEDIUM",
				"PEH PAL_WHEAT_09X-GRANDE",
				"PEH PAL_WHEAT_15X-MEGA",
				"PEH PAL_WHEAT_16X-SUPER SIZE",
				"PEP PAL_WHEAT_01X-SS XSMALL",
				"PEP PAL_WHEAT_02X-JR LINE",
				"PEP PAL_WHEAT_04X-SS LARGE",
				"PEP PAL_WHEAT_07X-MAX",
				"PEP PAL_POTATO_07X-MEDIUM",
				"PEP PAL_WHEAT_07X-MEDIUM",
				"PEP PAL_WHEAT_09X-GRANDE",
				"PEP RAM_WHEAT_01X-SS XSMALL",
				"PEP RAM_WHEAT_02X-JR LINE",
				"PEP RAM_WHEAT_04X-SS LARGE",
				"PEP RAM_WHEAT_07X-MEDIUM",
				"PEP RUE_WHEAT_01X-MINI",
				"PEP RUE_WHEAT_01X-SS XSMALL",
				"PEP RUE_WHEAT_02X-JR LINE",
				"PEP RUE_WHEAT_04X-SS LARGE",
				"PEP RUE_WHEAT_07X-MAX",
				"PEP RUE_WHEAT_07X-MEDIUM",
				"PKN REG_PCORN RTE_02X-JR LINE",
				"QKB CER_OAT BAR_01X-MINI",
				"QKB CER_OAT BAR_01X-SS XSMALL",
				"QKC GRA_GRAN-CRL_09X-JUMBO",
				"QKC HGR_ADLT-CRL_07X-MAX",
				"QKC HGR_ADLT-CRL_09X-GRANDE",
				"QKC HNU_ADLT-CRL_07X-MAX",
				"QKC HNU_ADLT-CRL_09X-GRANDE",
				"QKC QUA_ADLT-CRL_07X-MAX",
				"QKC QUA_ADLT-CRL_09X-JUMBO",
				"QUA CRR_PLAIN CRK_09X-GRANDE",
				"QUA CRR_PLAIN CRK_15X-MEGA",
				"QGL MIN_BKFST CKY_01X-SS XSMALL",
				"QGL MOL_OAT CKY_01X-SS XSMALL",
				"QGL MOL_OAT CKY_07X-MAX",
				"QGL MOL_OAT CKY_09X-GRANDE",
				"QGL MOL_OAT CKY_09X-JUMBO",
				"QGL REL_SNDWCH CKY_07X-MAX",
				"QGL REL_SNDWCH CKY_07X-MEDIUM",
				"QGL REL_SNDWCH CKY_11X-BIG",
				"QGL WC_OAT CKY_01X-SS XSMALL",
				"QGL WC_OAT CKY_07X-MAX",
				"QGL WC_OAT CKY_09X-GRANDE",
				"QGL WC_OAT CKY_11X-BIG",
				"QUO EFI_PWD OAT_12X-EXTRA BIG",
				"QUO INS_INST OAT_09X-JUMBO",
				"QUO INS_INST OAT_11X-BIG",
				"QUO INS_INST OAT_12X-EXTRA BIG",
				"QUO INS_INST OAT_15X-MEGA",
				"QUO INS_INST OAT_16X-SUPER SIZE",
				"QUO REG_TRAD OAT_09X-JUMBO",
				"QUO REG_TRAD OAT_12X-EXTRA BIG",
				"QUO REG_TRAD OAT_16X-SUPER SIZE",
				"SBT REG_RICE CRK_01X-SS XSMALL",
				"SBT REG_RICE CRK_07X-MEDIUM",
				"TOD CHI_CHOC CHIP CKY_01X-MINI",
				"TOD CHI_CHOC CHIP CKY_04X-SS LARGE",
				"TOD CHI_CHOC CHIP CKY_07X-MAX",
				"TOD CHI_CHOC CHIP CKY_07X-MEDIUM",
				"TOD CHI_CHOC CHIP CKY_09X-GRANDE",
				"TOD CHI_CHOC CHIP CKY_11X-BIG",
				"TOD CHB_CHOC CHIP CKY_04X-SS LARGE",
				"TOD CHB_CHOC CHIP CKY_07X-MAX",
				"TOD CHB_CHOC CHIP CKY_07X-MEDIUM",
				"TOD CHB_CHOC CHIP CKY_09X-GRANDE",
				"TOD CDD_CHOC CHIP CKY_01X-SS XSMALL",
				"TOD CDD_CHOC CHIP CKY_07X-MAX",
				"TOD CDD_CHOC CHIP CKY_07X-MEDIUM",
				"TOD CDD_CHOC CHIP CKY_09X-GRANDE",
				"TOD CHC_SNDWCH CKY_04X-SS LARGE",
				"TOD CHC_SNDWCH CKY_07X-MAX",
				"TOD CHC_SNDWCH CKY_07X-MEDIUM",
				"TOD CHC_SNDWCH CKY_11X-BIG",
				"TOD CBS_KIDS-CRL_09X-GRANDE",
				"TOD EXT_CHOC CVR CKY_04X-SS LARGE",
				"TOD EXT_CHOC CVR CKY_07X-MEDIUM",
				"TOD ONE_CHWY BAR_01X-SS XSMALL",
				"TOD RLL_CHOC CHIP CKY_07X-MAX",
				"TOS REG_TORTIL_04X-SS LARGE",
				"TOS REG_TORTIL_07X-MAX",
				"TOS REG_TORTIL_07X-MEDIUM",
				"TOS REG_TORTIL_09X-GRANDE",
				"TOS REG_TORTIL_11X-BIG",
				"TWI CRA_FLAV CRK_04P-MULTIPK",
				"TWI CRA_FLAV CRK_07X-MEDIUM",
				"TWI MIN_BAKED CRK_01X-MINI",
				"TWI MIN_BAKED CRK_01X-SS XSMALL",
				"TWI MIN_BAKED CRK_07X-MAX",
				"TWI MIN_BAKED CRK_07X-MEDIUM",
				"TWI MUL_OTH CRK_01X-SS XSMALL",
				"TWI MUL_OTH CRK_07X-MEDIUM",
				"TWI RIC_RICE CRK_01X-MINI",
				"TWI RIC_RICE CRK_01X-SS XSMALL",
				"TWI RIC_RICE CRK_07X-MEDIUM",
				"ZUC REG_OTH CKY_07X-MAX",
				"ZUC REG_OTH CKY_07X-MEDIUM",
			],
		},
		competitor: {
			vendor: [
				"5 HISPANOS",
				"ARCOR",
				"BAGLEY",
				"CEREALKO",
				"CHIL",
				"COTO",
				"CROOPERS",
				"DIA",
				"KELLOGGS",
				"MONDELEZ",
				"NABISCO",
				"NIKITOS",
				"OTHER VENDOR",
				"PRIVATE LABEL",
				"PRODEMAN",
				"SEMIX",
				"TIA MARUCA",
			],
			category: ["BIS-COOKIES", "BIS-CRACKERS", "SAV-NUTS & SEEDS", "SAV-POPCORN-MW", "SAV-POPCORN-RTE", "SAV-SALTY"],
			segment: [
				"CKY-BREAKFAST",
				"CKY-CHOC CHIP",
				"CKY-CHOC COVER",
				"CKY-FILLED",
				"CKY-MARIAS",
				"CKY-OTHER",
				"CKY-RING",
				"CKY-SANDWICH",
				"CKY-TRAD",
				"CKY-WAFER",
				"CRK-PLAIN",
				"CRK-RICE",
				"SAV-CORN",
				"SAV-EXTRUDED",
				"SAV-HLTHY SNACK",
				"SAV-NUTS",
				"SAV-OTHER",
				"SAV-PELLET",
				"SAV-POPCORN-MW",
				"SAV-POPCORN-RTE",
				"SAV-POTATO",
				"SAV-SALTY MIX",
				"SAV-TORTILLA",
				"SAV-WHEAT",
			],
			brand: [
				"9 DE ORO BIZCOCHITOS",
				"9 DE ORO PEPAS",
				"9 DE ORO REGULAR",
				"9 DE ORO SCONS",
				"A RODRIGUEZ REGULAR",
				"ACT II REGULAR",
				"ALICANTE REGULAR",
				"ALICORP REGULAR",
				"ALSEVI REGULAR",
				"ALSICA RIERA",
				"AM DAME REGULAR",
				"ANTOJITOS POTATO",
				"ANTOJITOS TORTILLA",
				"ARCOR ABUELA",
				"ARCOR AMOR",
				"ARCOR AZUCARADAS",
				"ARCOR CEREAL MIX",
				"ARCOR CHISPAS",
				"ARCOR CHOCOLINAS",
				"ARCOR CINDOR",
				"ARCOR COOKIE",
				"ARCOR COQUITAS",
				"ARCOR CRIOLLITAS",
				"ARCOR FORMIS",
				"ARCOR MACUCAS",
				"ARCOR MANA",
				"ARCOR NUTS",
				"ARCOR VARIETY",
				"AVENTURA REGULAR",
				"BACHILITOS CORN",
				"BACHILITOS NUTS",
				"BACHILITOS POTATO",
				"BACHILITOS WHEAT",
				"BAGLEY MELITAS",
				"BAGLEY MERCI",
				"BAGLEY MERENGADAS",
				"BAGLEY OPERA",
				"BAGLEY PANCHITAS",
				"BAGLEY POLVORITA",
				"BAGLEY RUMBA",
				"BAGLEY SER",
				"BAGLEY SONRISAS",
				"BAGLEY TENTACIONES",
				"BAGLEY VOCACION",
				"BAHLSEN REGULAR",
				"BATATAS REGULAR",
				"BAUDUCCO BREAKFAST",
				"BAUDUCCO REGULAR",
				"BAUDUCCO WAFER",
				"BIG CHEESE REGULAR",
				"BIMBO REGULAR",
				"BONAFIDE REGULAR",
				"BUBY REGULAR",
				"CALLIERI REGULAR",
				"CASCARONES REGULAR",
				"CEREALITAS REGULAR",
				"CERRITO HEALTHY",
				"CERRITO NUTS",
				"CHALITAS REGULAR",
				"CHEVALIER REGULAR",
				"CHIL PAPAS FRITAS",
				"CHIL SLICES INFLADITOS",
				"CIRUELA REGULAR",
				"CIUDAD DEL LAGO REGULAR",
				"CONOSUR NUTS",
				"CONOSUR SALTY MIX",
				"COP NUTS",
				"CORY HIPOPO",
				"COSTA CEREAL",
				"COSTA CHIPS",
				"CRACKETS REGULAR",
				"CRISKEY CORN",
				"CRISKEY POTATO",
				"CROOPERS MIX",
				"CROOPERS REGULAR",
				"CUYOAROMAS MIX",
				"CUYOAROMAS REGULAR",
				"DAMASCOS REGULAR",
				"DIA CRACKERS",
				"DICOMERE REGULAR",
				"DON MIGUEL REGULAR",
				"DON SATUR REGULAR",
				"DR HAVANNA REGULAR",
				"DULCOR REGULAR",
				"EL CACHAFAZ REGULAR",
				"EL MALLIN REGULAR",
				"EL PEONCITO CORN",
				"EL PEONCITO REGULAR",
				"EL TRIGAL BRIDGE",
				"EL TRIGAL LECHE",
				"FANTOCHE ESPUMITA",
				"FANTOCHE HOROSCOPO",
				"FANTOCHE MARMOLADAS",
				"FANTOCHE REGULAR",
				"FANTOCHE TAPITAS",
				"FRUGLACE REGULAR",
				"FRUIT ME UP REGULAR",
				"GALLETITAS REGULAR",
				"GAONA CRUNCHIPS",
				"GAUCHITAS REGULAR",
				"GONZALO EXTRUDED",
				"GONZALO NUTS",
				"GONZALO POPCORN",
				"GONZALO POTATO",
				"GONZALO REGULAR",
				"GOOD SHOW CORN",
				"GOOD SHOW EXTRUDED",
				"GOOD SHOW NUTS",
				"GOOD SHOW OTHER",
				"GOOD SHOW PELLET",
				"GOOD SHOW POTATO",
				"GOOD SHOW REGULAR",
				"GRANDIET MIX",
				"GRANIX FRUTIGRAN",
				"GRANIX REGULAR",
				"HERRS TORTILLA",
				"HIGOS DE SMIRNA REGULAR",
				"HITS REGULAR",
				"HOJALMAR CAPRI",
				"HOJALMAR REGULAR",
				"IRINA REGULAR",
				"JULI CROC CORN",
				"JULI CROC POPCORN",
				"JUMALA REGULAR",
				"JUNIN REGULAR",
				"KING MANI",
				"KING REGULAR",
				"KOKITO REGULAR",
				"KRACHITOS CORN",
				"KRACHITOS EXTRUDED",
				"KRACHITOS MIX",
				"KRACHITOS NUTS",
				"KRACHITOS OTHER",
				"KRACHITOS PELLET",
				"KRACHITOS POTATO",
				"KRACHITOS TORTILLA",
				"KRACHITOS WHEAT",
				"KRAFT REGULAR",
				"KRISKI NUTS",
				"KRISKI PELLET",
				"KRISKI POTATO",
				"KRISKI REGULAR",
				"KRISKI WHEAT",
				"KROKANTES EXTRUDED",
				"KROKANTES NUTS",
				"KROKANTES OTHER",
				"KROKANTES POTATO",
				"LA ABUNDANCIA REGULAR",
				"LA CAMPINA REGULAR",
				"LA EGIPCIANA REGULAR",
				"LA PARMESANA REGULAR",
				"LA REINA REGULAR",
				"LAS BRISAS REGULAR",
				"LLUVIA REGULAR",
				"LOS CAROLINOS REGULAR",
				"LOS RUSITOS REGULAR",
				"MACRITAS REGULAR",
				"MADZ ANDINO REGULAR",
				"MAN REGULAR",
				"MANI JA REGULAR",
				"MANI MINGO REGULAR",
				"MANI REPEL REGULAR",
				"MANI SABORZ REGULAR",
				"MANISUR CORN",
				"MANISUR REGULAR",
				"MANITOON REGULAR",
				"MARIPOSA REGULAR",
				"MARISA NUTS",
				"MARISA REGULAR",
				"MARLOMS REGULAR",
				"MAURI REGULAR",
				"MELBA TRAD",
				"MILKA REGULAR",
				"MOLTO REGULAR",
				"MORAN EXTRUDED",
				"MORAN OTHER",
				"MORAN POTATO",
				"NACHO CERAL REGULAR",
				"NATURAL BREAK REGULAR",
				"NIKITOS CORN",
				"NIKITOS EXTRUDED",
				"NIKITOS NUTS",
				"NIKITOS OTHER",
				"NIKITOS PELLET",
				"NIKITOS POPCORN",
				"NIKITOS POTATO",
				"NIKITOS WHEAT",
				"NUCETE REGULAR",
				"NUECES REGULAR",
				"OASIS CORN",
				"OASIS NUTS",
				"OASIS PELLET",
				"OASIS POTATO",
				"OASIS WHEAT",
				"OKEBON LECHE",
				"OKEBON MOLINO",
				"OKEBON REGULAR",
				"OREO DUO",
				"OREO GOLDEN",
				"OREO MINI",
				"OREO REGULAR",
				"OREO TRIO",
				"OTHER SUBBRAND COOKIE",
				"OTHER SUBBRAND CORN",
				"OTHER SUBBRAND EXTRUDED",
				"OTHER SUBBRAND HEALTHY",
				"OTHER SUBBRAND MIX",
				"OTHER SUBBRAND NUTS",
				"OTHER SUBBRAND OTHER",
				"OTHER SUBBRAND PELLET",
				"OTHER SUBBRAND POPCORN",
				"OTHER SUBBRAND POTATO",
				"OTHER SUBBRAND TORTILLA",
				"OTHER SUBBRAND TRADICIONAL",
				"OTHER SUBBRAND WAFER",
				"OTHER SUBBRAND WHEAT",
				"PARNOR PITUSAS",
				"PASAS DE UVA REGULAR",
				"PEDRIN CORN",
				"PEDRIN EXTRUDED",
				"PEDRIN NUTS",
				"PEDRIN OTHER",
				"PEDRIN PELLET",
				"PEDRIN REGULAR",
				"PEDRIN WHEAT",
				"PEPITOS CHISPAS",
				"PEPITOS MINI",
				"PEPITOS RELLENOS",
				"PEPITOS STICKS",
				"PEPITOS TORTITAS",
				"PEPITOS TRAD",
				"PEPITOS WHITE",
				"PINDY REGULAR",
				"PIPAS REGULAR",
				"POSTREFINO REGULAR",
				"POZO REGULAR",
				"PRINGLES REGULAR",
				"PRINGLES TORTILLA CHIPS",
				"PRIVATE LABEL",
				"PRIVATE LABEL CORN",
				"PRIVATE LABEL MIX",
				"PRIVATE LABEL NUTS",
				"PRIVATE LABEL POTATO",
				"PRIVATE LABEL TORTILLA",
				"PRIVATE LABEL WHEAT",
				"QUENTO HEALTHY",
				"QUENTO MIX",
				"QUENTO NUTS",
				"QUENTO REGULAR",
				"QUENTO TORTILLA",
				"QUENTO WHEAT",
				"SALADIX BACONZITOS",
				"SALADIX BACONZITOS EXTRUDED",
				"SALADIX CRAX",
				"SALADIX CROSS",
				"SALADIX CROSS WHEAT",
				"SALADIX HULA HULA",
				"SALADIX KESITOS",
				"SALADIX MILANESITAS",
				"SALADIX OTHER",
				"SALADIX PANCHITOS",
				"SALADIX PICANTO",
				"SALADIX PIZZITAS",
				"SALADIX POTATO",
				"SALADIX QUESO",
				"SALADIX RINGS",
				"SALADIX STICKS",
				"SALADIX TORTILLA",
				"SANJUANITA MIX",
				"SANJUANITA REGULAR",
				"SEMIX MANI",
				"SEMIX MIX",
				"SEMIX POPCORN",
				"SEMIX REGULAR",
				"SER SNACKS REGULAR",
				"SNACK REGULAR",
				"SNYDERS REGULAR",
				"SOLITAS REGULAR",
				"SORIANA REGULAR",
				"TEREPIN REGULAR",
				"TERRA MIA REGULAR",
				"TERRAB VARIETY",
				"TERRABUSI ANILLOS",
				"TERRABUSI BOCA DAMA",
				"TERRABUSI CHAMPAGNE",
				"TERRABUSI DUQUESA",
				"TERRABUSI LINCOLN",
				"TERRABUSI MANON",
				"TERRABUSI MARIAS",
				"TERRABUSI ROCOCO",
				"TERRASANA REGULAR",
				"TIA MARUCA ANILLOS",
				"TIA MARUCA AZUCARADAS",
				"TIA MARUCA COOKIE",
				"TIA MARUCA CORAZON",
				"TIA MARUCA CORN",
				"TIA MARUCA JUNGLITAS",
				"TIA MARUCA NUTS",
				"TIA MARUCA PALMERITAS",
				"TIA MARUCA PEPITAS",
				"TIA MARUCA REGULAR",
				"TIA MARUCA ROSQUELLAS",
				"TIA MARUCA TORTITAS",
				"TIA MARUCA TRIANGULITOS",
				"TIA MARUCA VARIETY",
				"TIA MARUCA WHEAT",
				"TOSTINAS REGULAR",
				"TOTAL O CORP",
				"TRAJANO REGULAR",
				"TRIO PEPAS",
				"TRIO REGULAR",
				"TRIO VARIETY",
				"VALENTE REGULAR",
				"VILLA RICA REGULAR",
				"WONDERFUL PISTACHIOS",
				"YARIGUARENDA CORN",
				"YUKITAS REGULAR",
				"ZAFRAN REGULAR",
				"ZYMMA POTATO",
				"ZYMMA REGULAR",
			],
			subBrand: [
				"9 DE ORO BIZCOCHITOS",
				"9 DE ORO PEPAS",
				"9 DE ORO REGULAR",
				"9 DE ORO SCONS",
				"A RODRIGUEZ REGULAR",
				"ACT II REGULAR",
				"ALICANTE REGULAR",
				"ALICORP REGULAR",
				"ALSEVI REGULAR",
				"ALSICA RIERA",
				"AM DAME REGULAR",
				"ANTOJITOS POTATO",
				"ANTOJITOS TORTILLA",
				"ARCOR ABUELA",
				"ARCOR AMOR",
				"ARCOR AZUCARADAS",
				"ARCOR CEREAL MIX",
				"ARCOR CHISPAS",
				"ARCOR CHOCOLINAS",
				"ARCOR CINDOR",
				"ARCOR COOKIE",
				"ARCOR COQUITAS",
				"ARCOR CRIOLLITAS",
				"ARCOR FORMIS",
				"ARCOR MACUCAS",
				"ARCOR MANA",
				"ARCOR NUTS",
				"ARCOR VARIETY",
				"AVENTURA REGULAR",
				"BACHILITOS CORN",
				"BACHILITOS NUTS",
				"BACHILITOS POTATO",
				"BACHILITOS WHEAT",
				"BAGLEY MELITAS",
				"BAGLEY MERCI",
				"BAGLEY MERENGADAS",
				"BAGLEY OPERA",
				"BAGLEY PANCHITAS",
				"BAGLEY POLVORITA",
				"BAGLEY RUMBA",
				"BAGLEY SER",
				"BAGLEY SONRISAS",
				"BAGLEY TENTACIONES",
				"BAGLEY VOCACION",
				"BAHLSEN REGULAR",
				"BATATAS REGULAR",
				"BAUDUCCO BREAKFAST",
				"BAUDUCCO REGULAR",
				"BAUDUCCO WAFER",
				"BIG CHEESE REGULAR",
				"BIMBO REGULAR",
				"BONAFIDE REGULAR",
				"BUBY REGULAR",
				"CALLIERI REGULAR",
				"CASCARONES REGULAR",
				"CEREALITAS REGULAR",
				"CERRITO HEALTHY",
				"CERRITO NUTS",
				"CHALITAS REGULAR",
				"CHEVALIER REGULAR",
				"CHIL PAPAS FRITAS",
				"CHIL SLICES INFLADITOS",
				"CIRUELA REGULAR",
				"CIUDAD DEL LAGO REGULAR",
				"CONOSUR NUTS",
				"CONOSUR SALTY MIX",
				"COP NUTS",
				"CORY HIPOPO",
				"COSTA CEREAL",
				"COSTA CHIPS",
				"CRACKETS REGULAR",
				"CRISKEY CORN",
				"CRISKEY POTATO",
				"CROOPERS MIX",
				"CROOPERS REGULAR",
				"CUYOAROMAS MIX",
				"CUYOAROMAS REGULAR",
				"DAMASCOS REGULAR",
				"DIA CRACKERS",
				"DICOMERE REGULAR",
				"DON MIGUEL REGULAR",
				"DON SATUR REGULAR",
				"DR HAVANNA REGULAR",
				"DULCOR REGULAR",
				"EL CACHAFAZ REGULAR",
				"EL MALLIN REGULAR",
				"EL PEONCITO CORN",
				"EL PEONCITO REGULAR",
				"EL TRIGAL BRIDGE",
				"EL TRIGAL LECHE",
				"FANTOCHE ESPUMITA",
				"FANTOCHE HOROSCOPO",
				"FANTOCHE MARMOLADAS",
				"FANTOCHE REGULAR",
				"FANTOCHE TAPITAS",
				"FRUGLACE REGULAR",
				"FRUIT ME UP REGULAR",
				"GALLETITAS REGULAR",
				"GAONA CRUNCHIPS",
				"GAUCHITAS REGULAR",
				"GONZALO EXTRUDED",
				"GONZALO NUTS",
				"GONZALO POPCORN",
				"GONZALO POTATO",
				"GONZALO REGULAR",
				"GOOD SHOW CORN",
				"GOOD SHOW EXTRUDED",
				"GOOD SHOW NUTS",
				"GOOD SHOW OTHER",
				"GOOD SHOW PELLET",
				"GOOD SHOW POTATO",
				"GOOD SHOW REGULAR",
				"GRANDIET MIX",
				"GRANIX FRUTIGRAN",
				"GRANIX REGULAR",
				"HERRS TORTILLA",
				"HIGOS DE SMIRNA REGULAR",
				"HITS REGULAR",
				"HOJALMAR CAPRI",
				"HOJALMAR REGULAR",
				"IRINA REGULAR",
				"JULI CROC CORN",
				"JULI CROC POPCORN",
				"JUMALA REGULAR",
				"JUNIN REGULAR",
				"KING REGULAR",
				"KOKITO REGULAR",
				"KRACHITOS CORN",
				"KRACHITOS EXTRUDED",
				"KRACHITOS MIX",
				"KRACHITOS NUTS",
				"KRACHITOS OTHER",
				"KRACHITOS PELLET",
				"KRACHITOS POTATO",
				"KRACHITOS TORTILLA",
				"KRACHITOS WHEAT",
				"KRAFT REGULAR",
				"KRISKI NUTS",
				"KRISKI PELLET",
				"KRISKI POTATO",
				"KRISKI REGULAR",
				"KRISKI WHEAT",
				"KROKANTES EXTRUDED",
				"KROKANTES NUTS",
				"KROKANTES OTHER",
				"KROKANTES POTATO",
				"LA ABUNDANCIA REGULAR",
				"LA CAMPINA REGULAR",
				"LA EGIPCIANA REGULAR",
				"LA PARMESANA REGULAR",
				"LA REINA REGULAR",
				"LAS BRISAS REGULAR",
				"LLUVIA REGULAR",
				"LOS CAROLINOS REGULAR",
				"LOS RUSITOS REGULAR",
				"MACRITAS REGULAR",
				"MADZ ANDINO REGULAR",
				"MAN REGULAR",
				"MANI JA REGULAR",
				"MANI MINGO REGULAR",
				"MANI REPEL REGULAR",
				"MANI SABORZ REGULAR",
				"MANISUR CORN",
				"MANISUR REGULAR",
				"MANITOON REGULAR",
				"MARIPOSA REGULAR",
				"MARISA NUTS",
				"MARISA REGULAR",
				"MARLOMS REGULAR",
				"MAURI REGULAR",
				"MELBA TRAD",
				"MILKA REGULAR",
				"MOLTO REGULAR",
				"MORAN EXTRUDED",
				"MORAN OTHER",
				"MORAN POTATO",
				"NACHO CERAL REGULAR",
				"NATURAL BREAK REGULAR",
				"NIKITOS CORN",
				"NIKITOS EXTRUDED",
				"NIKITOS NUTS",
				"NIKITOS OTHER",
				"NIKITOS PELLET",
				"NIKITOS POPCORN",
				"NIKITOS POTATO",
				"NIKITOS WHEAT",
				"NUCETE REGULAR",
				"NUECES REGULAR",
				"OASIS CORN",
				"OASIS NUTS",
				"OASIS PELLET",
				"OASIS POTATO",
				"OASIS WHEAT",
				"OKEBON LECHE",
				"OKEBON MOLINO",
				"OKEBON REGULAR",
				"OREO DUO",
				"OREO GOLDEN",
				"OREO MINI",
				"OREO REGULAR",
				"OREO TRIO",
				"OTHER SUBBRAND COOKIE",
				"OTHER SUBBRAND CORN",
				"OTHER SUBBRAND EXTRUDED",
				"OTHER SUBBRAND HEALTHY",
				"OTHER SUBBRAND MIX",
				"OTHER SUBBRAND NUTS",
				"OTHER SUBBRAND OTHER",
				"OTHER SUBBRAND PELLET",
				"OTHER SUBBRAND POPCORN",
				"OTHER SUBBRAND POTATO",
				"OTHER SUBBRAND TORTILLA",
				"OTHER SUBBRAND TRADICIONAL",
				"OTHER SUBBRAND WAFER",
				"OTHER SUBBRAND WHEAT",
				"PARNOR PITUSAS",
				"PASAS DE UVA REGULAR",
				"PEDRIN CORN",
				"PEDRIN EXTRUDED",
				"PEDRIN NUTS",
				"PEDRIN OTHER",
				"PEDRIN PELLET",
				"PEDRIN REGULAR",
				"PEDRIN WHEAT",
				"PEPITOS CHISPAS",
				"PEPITOS MINI",
				"PEPITOS RELLENOS",
				"PEPITOS STICKS",
				"PEPITOS TORTITAS",
				"PEPITOS TRAD",
				"PEPITOS WHITE",
				"PINDY REGULAR",
				"PIPAS REGULAR",
				"POSTREFINO REGULAR",
				"POZO REGULAR",
				"PRINGLES REGULAR",
				"PRINGLES TORTILLA CHIPS",
				"PRIVATE LABEL",
				"PRIVATE LABEL CORN",
				"PRIVATE LABEL MIX",
				"PRIVATE LABEL NUTS",
				"PRIVATE LABEL POTATO",
				"PRIVATE LABEL TORTILLA",
				"PRIVATE LABEL WHEAT",
				"QUENTO HEALTHY",
				"QUENTO MIX",
				"QUENTO NUTS",
				"QUENTO REGULAR",
				"QUENTO TORTILLA",
				"QUENTO WHEAT",
				"SALADIX BACONZITOS",
				"SALADIX BACONZITOS EXTRUDED",
				"SALADIX CRAX",
				"SALADIX CROSS",
				"SALADIX CROSS WHEAT",
				"SALADIX HULA HULA",
				"SALADIX KESITOS",
				"SALADIX MILANESITAS",
				"SALADIX OTHER",
				"SALADIX PANCHITOS",
				"SALADIX PICANTO",
				"SALADIX PIZZITAS",
				"SALADIX POTATO",
				"SALADIX QUESO",
				"SALADIX RINGS",
				"SALADIX STICKS",
				"SALADIX TORTILLA",
				"SANJUANITA MIX",
				"SANJUANITA REGULAR",
				"SEMIX MANI",
				"SEMIX MIX",
				"SEMIX POPCORN",
				"SEMIX REGULAR",
				"SER SNACKS REGULAR",
				"SNACK REGULAR",
				"SNYDERS REGULAR",
				"SOLITAS REGULAR",
				"SORIANA REGULAR",
				"TEREPIN REGULAR",
				"TERRA MIA REGULAR",
				"TERRAB VARIETY",
				"TERRABUSI ANILLOS",
				"TERRABUSI BOCA DAMA",
				"TERRABUSI CHAMPAGNE",
				"TERRABUSI DUQUESA",
				"TERRABUSI LINCOLN",
				"TERRABUSI MANON",
				"TERRABUSI MARIAS",
				"TERRABUSI ROCOCO",
				"TERRASANA REGULAR",
				"TIA MARUCA ANILLOS",
				"TIA MARUCA AZUCARADAS",
				"TIA MARUCA COOKIE",
				"TIA MARUCA CORAZON",
				"TIA MARUCA CORN",
				"TIA MARUCA JUNGLITAS",
				"TIA MARUCA NUTS",
				"TIA MARUCA PALMERITAS",
				"TIA MARUCA PEPITAS",
				"TIA MARUCA REGULAR",
				"TIA MARUCA ROSQUELLAS",
				"TIA MARUCA TORTITAS",
				"TIA MARUCA TRIANGULITOS",
				"TIA MARUCA VARIETY",
				"TIA MARUCA WHEAT",
				"TOSTINAS REGULAR",
				"TOTAL O CORP",
				"TRAJANO REGULAR",
				"TRIO PEPAS",
				"TRIO REGULAR",
				"TRIO VARIETY",
				"VALENTE REGULAR",
				"VILLA RICA REGULAR",
				"WONDERFUL PISTACHIOS",
				"YARIGUARENDA CORN",
				"YUKITAS REGULAR",
				"ZAFRAN REGULAR",
				"ZYMMA POTATO",
				"ZYMMA REGULAR",
			],
			packSize: [
				"01X-MINI",
				"01X-SS XSMALL",
				"02X-JR LINE",
				"04X-SS LARGE",
				"07X-MAX",
				"07X-MEDIUM",
				"09X-GRANDE",
				"09X-JUMBO",
				"11X-BIG",
				"12X-EXTRA BIG",
				"15X-MEGA",
				"16X-SUPER SIZE",
			],
			permutation: [
				"9DE BIZ_RICE CRK_07X-MEDIUM",
				"9DE BIZ_RICE CRK_09X-GRANDE",
				"9DE BIZ_RICE CRK_12X-EXTRA BIG",
				"9DE PEP_FILL CKY_04X-SS LARGE",
				"9DE PEP_FILL CKY_09X-GRANDE",
				"9DE PEP_FILL CKY_11X-BIG",
				"9DE ORO_TRAD CKY_07X-MAX",
				"9DE SCO_TRAD CKY_07X-MAX",
				"9DE SCO_TRAD CKY_09X-GRANDE",
				"ARD REG_NUTS_07X-MEDIUM",
				"ACT REG_PCORN MW_07X-MAX",
				"ACT REG_PCORN MW_09X-GRANDE",
				"ACN REG_NUTS_04X-SS LARGE",
				"ACN REG_NUTS_07X-MEDIUM",
				"ALI REG_OTH SAV_07X-MEDIUM",
				"ASV REG_RING CKY_01X-SS XSMALL",
				"ASV REG_RING CKY_09X-GRANDE",
				"ASV REG_RING CKY_11X-BIG",
				"ALC RIE_PLAIN CRK_04X-SS LARGE",
				"ALC RIE_PLAIN CRK_09X-GRANDE",
				"AMD REG_NUTS_09X-GRANDE",
				"AJT POT_POTATO_04X-SS LARGE",
				"AJT TOR_TORTIL_07X-MAX",
				"AJT TOR_TORTIL_07X-MEDIUM",
				"AJT TOR_TORTIL_09X-GRANDE",
				"ACR ABU_PLAIN CRK_07X-MEDIUM",
				"ACR ABU_PLAIN CRK_09X-GRANDE",
				"ACR ABU_PLAIN CRK_09X-JUMBO",
				"ACR ABU_PLAIN CRK_12X-EXTRA BIG",
				"ACR AMO_PLAIN CRK_07X-MEDIUM",
				"ACR AMO_PLAIN CRK_11X-BIG",
				"ACR AZU_PLAIN CRK_04X-SS LARGE",
				"ACR AZU_PLAIN CRK_07X-MAX",
				"ACR CMX_BKFST CKY_01X-SS XSMALL",
				"ACR CMX_BKFST CKY_07X-MAX",
				"ACR CMX_BKFST CKY_09X-GRANDE",
				"ACR CHI_CHOC CHIP CKY_07X-MEDIUM",
				"ACR CHO_TRAD CKY_07X-MAX",
				"ACR CHO_TRAD CKY_07X-MEDIUM",
				"ACR CHO_TRAD CKY_09X-GRANDE",
				"ACR CIN_OTH CKY_07X-MEDIUM",
				"ARC COK_OTH CKY_01X-MINI",
				"ARC COK_OTH CKY_04X-SS LARGE",
				"ARC COK_OTH CKY_07X-MAX",
				"ARC COK_OTH CKY_07X-MEDIUM",
				"ARC COK_OTH CKY_09X-GRANDE",
				"ARC COK_OTH CKY_09X-JUMBO",
				"ARC COK_OTH CKY_12X-EXTRA BIG",
				"ACR COQ_OTH CKY_07X-MAX",
				"ACR COQ_OTH CKY_09X-GRANDE",
				"ACR CRI_PLAIN CRK_09X-GRANDE",
				"ACR FOR_FILL CKY_02X-JR LINE",
				"ACR FOR_FILL CKY_04X-SS LARGE",
				"ACR FOR_FILL CKY_07X-MEDIUM",
				"ACR FOR_FILL CKY_11X-BIG",
				"ACR MAC_SNDWCH CKY_07X-MAX",
				"ACR MAC_SNDWCH CKY_07X-MEDIUM",
				"ACR MAC_SNDWCH CKY_11X-BIG",
				"ACR MAN_MARIA CKY_07X-MAX",
				"ACR MAN_MARIA CKY_07X-MEDIUM",
				"ACR MAN_MARIA CKY_11X-BIG",
				"ACR MAN_MARIA CKY_12X-EXTRA BIG",
				"ARC NUT_NUTS_01X-MINI",
				"ARC NUT_NUTS_01X-SS XSMALL",
				"ARC NUT_NUTS_02X-JR LINE",
				"ARC NUT_NUTS_04X-SS LARGE",
				"ARC NUT_NUTS_07X-MEDIUM",
				"ACR VAR_OTH CKY_09X-JUMBO",
				"ACR VAR_OTH CKY_12X-EXTRA BIG",
				"AVR REG_OTH CKY_07X-MEDIUM",
				"BAC COR_CORN_04X-SS LARGE",
				"BAC NUT_NUTS_07X-MAX",
				"BAC POT_POTATO_02X-JR LINE",
				"BAC POT_POTATO_07X-MAX",
				"BAC WHT_WHEAT_07X-MAX",
				"BAC WHT_WHEAT_09X-GRANDE",
				"BGL MEL_TRAD CKY_07X-MAX",
				"BGL MEL_TRAD CKY_07X-MEDIUM",
				"BGL MEL_TRAD CKY_09X-GRANDE",
				"BGL MEL_TRAD CKY_11X-BIG",
				"BGL MRC_OTH CKY_07X-MAX",
				"BGL MRN_SNDWCH CKY_07X-MEDIUM",
				"BGL MRN_SNDWCH CKY_09X-JUMBO",
				"BGL OPE_WFR CKY_02X-JR LINE",
				"BGL OPE_WFR CKY_04X-SS LARGE",
				"BGL OPE_WFR CKY_07X-MEDIUM",
				"BGL OPE_WFR CKY_09X-GRANDE",
				"BGL OPE_WFR CKY_09X-JUMBO",
				"BGL PAN_SNDWCH CKY_07X-MEDIUM",
				"BGL PAN_SNDWCH CKY_11X-BIG",
				"BGL POL_SNDWCH CKY_04X-SS LARGE",
				"BGL POL_SNDWCH CKY_07X-MAX",
				"BGL POL_SNDWCH CKY_07X-MEDIUM",
				"BGL RUM_SNDWCH CKY_07X-MEDIUM",
				"BGL RUM_SNDWCH CKY_11X-BIG",
				"BGL SER_CHOC CHIP CKY_04X-SS LARGE",
				"BGL SER_CHOC CHIP CKY_07X-MEDIUM",
				"BGL SER_CHOC CHIP CKY_09X-GRANDE",
				"BGL SON_SNDWCH CKY_02X-JR LINE",
				"BGL SON_SNDWCH CKY_04X-SS LARGE",
				"BGL SON_SNDWCH CKY_07X-MEDIUM",
				"BGL SON_SNDWCH CKY_11X-BIG",
				"BGL TEN_FILL CKY_04X-SS LARGE",
				"BGL TEN_FILL CKY_07X-MEDIUM",
				"BGL VOC_PLAIN CRK_07X-MAX",
				"BGL VOC_PLAIN CRK_12X-EXTRA BIG",
				"BAH REG_CHOC CVR CKY_07X-MEDIUM",
				"BAT REG_POTATO_01X-SS XSMALL",
				"BAU BRK_BKFST CKY_07X-MAX",
				"BAU REG_TRAD CKY_07X-MEDIUM",
				"BAU REG_TRAD CKY_11X-BIG",
				"BAU WAF_WFR CKY_01X-SS XSMALL",
				"BAU WAF_WFR CKY_02X-JR LINE",
				"BAU WAF_WFR CKY_07X-MAX",
				"BAU WAF_WFR CKY_07X-MEDIUM",
				"BHS REG_POTATO_02X-JR LINE",
				"BMB REG_OTH CKY_07X-MAX",
				"BMB REG_OTH CKY_12X-EXTRA BIG",
				"BFD REG_OTH CKY_01X-SS XSMALL",
				"BFD REG_OTH CKY_07X-MAX",
				"BFD REG_OTH CKY_07X-MEDIUM",
				"BUB REG_POTATO_09X-JUMBO",
				"CIE REG_HLTHY SNK_01X-MINI",
				"CIE REG_HLTHY SNK_02X-JR LINE",
				"CAC REG_PELLET_12X-EXTRA BIG",
				"CLT REG_BKFST CKY_01X-SS XSMALL",
				"CLT REG_BKFST CKY_07X-MEDIUM",
				"CLT REG_BKFST CKY_09X-GRANDE",
				"CEI HTY_HLTHY SNK_07X-MEDIUM",
				"CEI NUT_NUTS_07X-MEDIUM",
				"CEI NUT_NUTS_09X-GRANDE",
				"CTA REG_PELLET_01X-SS XSMALL",
				"CTA REG_PELLET_09X-JUMBO",
				"CHV REG_NUTS_01X-SS XSMALL",
				"CHV REG_NUTS_07X-MAX",
				"CHV REG_NUTS_07X-MEDIUM",
				"CHV REG_NUTS_09X-GRANDE",
				"CHP FTS_POTATO_02X-JR LINE",
				"CHP FTS_WHEAT_09X-GRANDE",
				"CHS IFD_WHEAT_07X-MEDIUM",
				"CIR REG_NUTS_09X-GRANDE",
				"CDL REG_PLAIN CRK_11X-BIG",
				"CSU NUT_NUTS_07X-MAX",
				"CSU NUT_NUTS_07X-MEDIUM",
				"CSU NUT_NUTS_09X-GRANDE",
				"CSU NUT_NUTS_12X-EXTRA BIG",
				"CSU MIX_SALTY MIX_07X-MAX",
				"CSU MIX_SALTY MIX_07X-MEDIUM",
				"CSU MIX_SALTY MIX_09X-GRANDE",
				"COP NUT_NUTS_09X-GRANDE",
				"COP NUT_NUTS_12X-EXTRA BIG",
				"CRY HIP_SNDWCH CKY_04X-SS LARGE",
				"CRY HIP_SNDWCH CKY_07X-MEDIUM",
				"CST CER_OTH CKY_07X-MEDIUM",
				"CST CHI_OTH CKY_07X-MEDIUM",
				"CKT REG_CORN_07X-MEDIUM",
				"CKY COR_CORN_09X-GRANDE",
				"CKY POT_POTATO_02X-JR LINE",
				"CPR MIX_SALTY MIX_01X-SS XSMALL",
				"CPR MIX_NUTS_04X-SS LARGE",
				"CPR MIX_SALTY MIX_07X-MEDIUM",
				"CPR REG_NUTS_01X-MINI",
				"CPR REG_NUTS_01X-SS XSMALL",
				"CPR REG_NUTS_04X-SS LARGE",
				"CPR REG_NUTS_07X-MAX",
				"CPR REG_NUTS_07X-MEDIUM",
				"CPR REG_NUTS_09X-JUMBO",
				"CPR REG_NUTS_11X-BIG",
				"CPR REG_NUTS_12X-EXTRA BIG",
				"CPR REG_NUTS_16X-SUPER SIZE",
				"CUY MIX_SALTY MIX_09X-GRANDE",
				"CUY MIX_SALTY MIX_12X-EXTRA BIG",
				"CUY REG_NUTS_09X-GRANDE",
				"CUY REG_NUTS_12X-EXTRA BIG",
				"DAO REG_NUTS_09X-GRANDE",
				"DIA CRA_PLAIN CRK_07X-MEDIUM",
				"DIA CRA_PLAIN CRK_09X-GRANDE",
				"DIA CRA_PLAIN CRK_12X-EXTRA BIG",
				"DIC REG_SALTY MIX_04X-SS LARGE",
				"DIC REG_SALTY MIX_09X-GRANDE",
				"DMG REG_NUTS_07X-MAX",
				"DMG REG_NUTS_07X-MEDIUM",
				"DMG REG_NUTS_09X-GRANDE",
				"DST REG_PLAIN CRK_01X-SS XSMALL",
				"DST REG_PLAIN CRK_09X-GRANDE",
				"DRH REG_FILL CKY_09X-JUMBO",
				"DRH REG_FILL CKY_11X-BIG",
				"DRH REG_FILL CKY_12X-EXTRA BIG",
				"DUO REG_NUTS_01X-MINI",
				"ECZ REG_OTH CKY_09X-GRANDE",
				"EMA REG_NUTS_07X-MAX",
				"EMA REG_NUTS_09X-GRANDE",
				"EPE COR_CORN_07X-MEDIUM",
				"EPE REG_NUTS_01X-SS XSMALL",
				"EPE REG_NUTS_04X-SS LARGE",
				"EPE REG_NUTS_07X-MAX",
				"EPE REG_NUTS_07X-MEDIUM",
				"EPE REG_NUTS_09X-GRANDE",
				"EPE REG_NUTS_11X-BIG",
				"EPE REG_NUTS_12X-EXTRA BIG",
				"ETR BRI_SNDWCH CKY_07X-MEDIUM",
				"ETR LEC_TRAD CKY_07X-MAX",
				"ETR LEC_TRAD CKY_09X-GRANDE",
				"FTC ESP_OTH CKY_01X-SS XSMALL",
				"FTC ESP_OTH CKY_07X-MAX",
				"FTC ESP_OTH CKY_09X-JUMBO",
				"FTC ESP_OTH CKY_11X-BIG",
				"FTC HOR_WFR CKY_09X-GRANDE",
				"FTC HOR_WFR CKY_09X-JUMBO",
				"FTC HOR_WFR CKY_12X-EXTRA BIG",
				"FTC MAR_OTH CKY_01X-SS XSMALL",
				"FTC MAR_OTH CKY_07X-MAX",
				"FTC MAR_OTH CKY_11X-BIG",
				"FOE REG_CHOC CHIP CKY_01X-SS XSMALL",
				"FOE REG_CHOC CHIP CKY_07X-MAX",
				"FOE REG_CHOC CHIP CKY_09X-GRANDE",
				"FOE REG_CHOC CHIP CKY_09X-JUMBO",
				"FOE REG_CHOC CHIP CKY_11X-BIG",
				"FOC TAP_SNDWCH CKY_07X-MAX",
				"FOC TAP_SNDWCH CKY_07X-MEDIUM",
				"FOC TAP_SNDWCH CKY_11X-BIG",
				"FOC TAP_SNDWCH CKY_12X-EXTRA BIG",
				"FGC REG_NUTS_07X-MEDIUM",
				"FMU REG_NUTS_07X-MEDIUM",
				"GLE REG_OTH SAV_09X-GRANDE",
				"GAO CRU_CHOC CHIP CKY_07X-MAX",
				"GAO CRU_CHOC CHIP CKY_07X-MEDIUM",
				"GAU REG_POTATO_01X-SS XSMALL",
				"GAU REG_POTATO_02X-JR LINE",
				"GAU REG_POTATO_07X-MEDIUM",
				"GZL EXT_EXTRUD_07X-MEDIUM",
				"GZL NTS_NUTS_09X-GRANDE",
				"GZL POP_PCORN RTE_07X-MEDIUM",
				"GZL POT_POTATO_07X-MEDIUM",
				"GZL POT_POTATO_09X-GRANDE",
				"GZL REG_OTH SAV_07X-MEDIUM",
				"GZL REG_OTH SAV_09X-GRANDE",
				"GSH COR_CORN_09X-JUMBO",
				"GSH EXT_EXTRUD_01X-MINI",
				"GSH EXT_EXTRUD_07X-MEDIUM",
				"GSH EXT_EXTRUD_09X-JUMBO",
				"GSH NUT_NUTS_07X-MAX",
				"GSH NUT_NUTS_07X-MEDIUM",
				"GSH OTH_OTH SAV_01X-MINI",
				"GSH OTH_OTH SAV_07X-MEDIUM",
				"GSH PEL_PELLET_11X-BIG",
				"GSH POT_POTATO_01X-MINI",
				"GSH POT_POTATO_01X-SS XSMALL",
				"GSH POT_POTATO_02X-JR LINE",
				"GSH POT_POTATO_07X-MAX",
				"GSH POT_POTATO_07X-MEDIUM",
				"GSH POT_POTATO_09X-JUMBO",
				"GSH POT_POTATO_12X-EXTRA BIG",
				"GSH POT_POTATO_16X-SUPER SIZE",
				"GSH REG_WHEAT_01X-MINI",
				"GSH REG_WHEAT_07X-MAX",
				"GSH REG_WHEAT_07X-MEDIUM",
				"GSH REG_WHEAT_16X-SUPER SIZE",
				"GDE MIX_SALTY MIX_07X-MEDIUM",
				"GNX FRU_OTH CKY_04X-SS LARGE",
				"GNX FRU_OTH CKY_07X-MAX",
				"GNX FRU_OTH CKY_07X-MEDIUM",
				"GNX FRU_OTH CKY_09X-GRANDE",
				"GNX FRU_OTH CKY_09X-JUMBO",
				"GNX FRU_OTH CKY_12X-EXTRA BIG",
				"GNX REG_OTH CKY_07X-MEDIUM",
				"GNX REG_OTH CKY_09X-GRANDE",
				"GNX REG_OTH CKY_11X-BIG",
				"HRR TOR_TORTIL_09X-GRANDE",
				"HDS REG_NUTS_07X-MAX",
				"HTS REG_POTATO_07X-MAX",
				"HTS REG_POTATO_07X-MEDIUM",
				"HOJ CAP_OTH CKY_07X-MAX",
				"HOJ CAP_OTH CKY_07X-MEDIUM",
				"HOJ CAP_OTH CKY_11X-BIG",
				"HOJ REG_OTH CKY_07X-MAX",
				"HOJ REG_OTH CKY_09X-GRANDE",
				"IIN REG_NUTS_01X-SS XSMALL",
				"JCC COR_CORN_01X-SS XSMALL",
				"JCC COR_CORN_02X-JR LINE",
				"JCC COR_CORN_12X-EXTRA BIG",
				"JCC POP_PCORN RTE_01X-SS XSMALL",
				"JML REG_NUTS_07X-MEDIUM",
				"JUN REG_NUTS_07X-MEDIUM",
				"KIN REG_NUTS_04X-SS LARGE",
				"KIN REG_NUTS_07X-MEDIUM",
				"KIN REG_NUTS_11X-BIG",
				"KIN REG_NUTS_12X-EXTRA BIG",
				"KIN REG_NUTS_16X-SUPER SIZE",
				"KKO REG_NUTS_01X-SS XSMALL",
				"KCH COR_CORN_01X-SS XSMALL",
				"KCH COR_CORN_02X-JR LINE",
				"KCH COR_CORN_07X-MAX",
				"KCH COR_CORN_09X-JUMBO",
				"KCH COR_CORN_12X-EXTRA BIG",
				"KCH EXT_EXTRUD_01X-SS XSMALL",
				"KCH EXT_EXTRUD_02X-JR LINE",
				"KCH EXT_EXTRUD_07X-MAX",
				"KCH EXT_EXTRUD_07X-MEDIUM",
				"KCH EXT_EXTRUD_09X-JUMBO",
				"KCH EXT_EXTRUD_11X-BIG",
				"KCH EXT_EXTRUD_12X-EXTRA BIG",
				"KCH MIX_SALTY MIX_01X-SS XSMALL",
				"KCH MIX_SALTY MIX_07X-MEDIUM",
				"KCH NUT_NUTS_01X-MINI",
				"KCH NUT_NUTS_01X-SS XSMALL",
				"KCH NUT_NUTS_02X-JR LINE",
				"KCH NUT_NUTS_07X-MEDIUM",
				"KCH NUT_NUTS_12X-EXTRA BIG",
				"KCH NUT_NUTS_16X-SUPER SIZE",
				"KCH OTH_OTH SAV_01X-MINI",
				"KCH OTH_OTH SAV_01X-SS XSMALL",
				"KCH OTH_OTH SAV_02X-JR LINE",
				"KCH OTH_OTH SAV_07X-MEDIUM",
				"KCH OTH_OTH SAV_11X-BIG",
				"KCH OTH_OTH SAV_12X-EXTRA BIG",
				"KCH PEL_PELLET_01X-SS XSMALL",
				"KCH PEL_PELLET_02X-JR LINE",
				"KCH PEL_PELLET_07X-MEDIUM",
				"KCH PEL_PELLET_09X-GRANDE",
				"KCH POT_POTATO_01X-MINI",
				"KCH POT_POTATO_01X-SS XSMALL",
				"KCH POT_POTATO_02X-JR LINE",
				"KCH POT_POTATO_04X-SS LARGE",
				"KCH POT_POTATO_07X-MAX",
				"KCH POT_POTATO_07X-MEDIUM",
				"KCH POT_POTATO_09X-GRANDE",
				"KCH POT_POTATO_09X-JUMBO",
				"KCH POT_POTATO_11X-BIG",
				"KCH POT_POTATO_12X-EXTRA BIG",
				"KCH POT_POTATO_15X-MEGA",
				"KCH TOR_TORTIL_02X-JR LINE",
				"KCH TOR_TORTIL_04X-SS LARGE",
				"KCH TOR_TORTIL_07X-MAX",
				"KCH WHT_WHEAT_01X-MINI",
				"KCH WHT_WHEAT_02X-JR LINE",
				"KCH WHT_WHEAT_07X-MEDIUM",
				"KCH WHT_WHEAT_12X-EXTRA BIG",
				"KCH WHT_WHEAT_16X-SUPER SIZE",
				"KFT REG_NUTS_11X-BIG",
				"KSK NUT_NUTS_16X-SUPER SIZE",
				"KSK PEL_PELLET_16X-SUPER SIZE",
				"KSK POT_POTATO_16X-SUPER SIZE",
				"KSK REG_CORN_09X-GRANDE",
				"KSK REG_CORN_16X-SUPER SIZE",
				"KSK WHT_WHEAT_16X-SUPER SIZE",
				"KKT EXT_EXTRUD_07X-MEDIUM",
				"KKT NUT_NUTS_09X-GRANDE",
				"KKT OTH_OTH SAV_07X-MEDIUM",
				"KKT POR_POTATO_07X-MEDIUM",
				"LAA REG_NUTS_07X-MAX",
				"CPN REG_NUTS_09X-GRANDE",
				"EGI REG_NUTS_02X-JR LINE",
				"EGI REG_NUTS_04X-SS LARGE",
				"EGI REG_NUTS_07X-MEDIUM",
				"EGI REG_NUTS_09X-GRANDE",
				"PAR REG_NUTS_07X-MEDIUM",
				"PAR REG_NUTS_09X-GRANDE",
				"LRN REG_NUTS_09X-GRANDE",
				"LBR REG_NUTS_07X-MEDIUM",
				"LLV REG_POTATO_09X-GRANDE",
				"LLV REG_POTATO_16X-SUPER SIZE",
				"LCS REG_NUTS_07X-MEDIUM",
				"RUS REG_WHEAT_16X-SUPER SIZE",
				"MCS REG_TORTIL_02X-JR LINE",
				"MCS REG_TORTIL_07X-MAX",
				"MCS REG_TORTIL_07X-MEDIUM",
				"MCS REG_TORTIL_09X-GRANDE",
				"MAD REG_CORN_07X-MEDIUM",
				"MAN REG_NUTS_16X-SUPER SIZE",
				"MJA REG_NUTS_12X-EXTRA BIG",
				"MMI REG_NUTS_04X-SS LARGE",
				"MRE REG_NUTS_09X-GRANDE",
				"MSZ REG_NUTS_12X-EXTRA BIG",
				"MSZ REG_NUTS_16X-SUPER SIZE",
				"MSR COR_CORN_04X-SS LARGE",
				"MSR COR_CORN_09X-GRANDE",
				"MSR REG_NUTS_01X-MINI",
				"MSR REG_NUTS_01X-SS XSMALL",
				"MSR REG_NUTS_09X-GRANDE",
				"MSR REG_NUTS_12X-EXTRA BIG",
				"MSR REG_NUTS_16X-SUPER SIZE",
				"MTO REG_NUTS_02X-JR LINE",
				"MTO REG_NUTS_07X-MAX",
				"MTO REG_NUTS_07X-MEDIUM",
				"MTO REG_NUTS_12X-EXTRA BIG",
				"MTO REG_NUTS_16X-SUPER SIZE",
				"MAP REG_NUTS_04X-SS LARGE",
				"MAI NUT_NUTS_07X-MAX",
				"MAI NUT_NUTS_07X-MEDIUM",
				"MAI NUT_NUTS_09X-GRANDE",
				"MAI REG_POTATO_01X-SS XSMALL",
				"MAI REG_POTATO_07X-MEDIUM",
				"MAI REG_POTATO_09X-GRANDE",
				"MLS REG_PCORN MW_07X-MEDIUM",
				"MLS REG_PCORN MW_09X-JUMBO",
				"MAU REG_OTH CKY_01X-MINI",
				"MAU REG_OTH CKY_04X-SS LARGE",
				"MAU REG_OTH CKY_07X-MAX",
				"MAU REG_OTH CKY_07X-MEDIUM",
				"MAU REG_OTH CKY_11X-BIG",
				"MAU REG_OTH CKY_16X-SUPER SIZE",
				"MEB TRA_PLAIN CRK_07X-MAX",
				"MEB TRA_PLAIN CRK_07X-MEDIUM",
				"MEB TRA_PLAIN CRK_11X-BIG",
				"MLK REG_FILL CKY_02X-JR LINE",
				"MLK REG_FILL CKY_07X-MAX",
				"MLK REG_FILL CKY_07X-MEDIUM",
				"MOL REG_NUTS_04X-SS LARGE",
				"MOA EXT_EXTRUD_07X-MEDIUM",
				"MOA OTH_OTH SAV_07X-MEDIUM",
				"MOA POT_POTATO_07X-MEDIUM",
				"NCR REG_TORTIL_07X-MEDIUM",
				"NBK REG_NUTS_01X-MINI",
				"NIK COR_CORN_01X-MINI",
				"NIK COR_CORN_04X-SS LARGE",
				"NIK COR_CORN_07X-MAX",
				"NIK COR_CORN_09X-JUMBO",
				"NIK COR_CORN_16X-SUPER SIZE",
				"NIK EXT_EXTRUD_01X-MINI",
				"NIK EXT_EXTRUD_01X-SS XSMALL",
				"NIK EXT_EXTRUD_04X-SS LARGE",
				"NIK EXT_EXTRUD_07X-MEDIUM",
				"NIK EXT_EXTRUD_09X-JUMBO",
				"NIK NUT_NUTS_07X-MEDIUM",
				"NIK OTH_OTH SAV_01X-MINI",
				"NIK OTH_OTH SAV_01X-SS XSMALL",
				"NIK OTH_OTH SAV_02X-JR LINE",
				"NIK OTH_OTH SAV_04X-SS LARGE",
				"NIK OTH_OTH SAV_07X-MEDIUM",
				"NIK OTH_OTH SAV_12X-EXTRA BIG",
				"NIK PEL_PELLET_12X-EXTRA BIG",
				"NIK POP_PCORN RTE_01X-MINI",
				"NIK POP_PCORN RTE_04X-SS LARGE",
				"NIK POP_PCORN RTE_07X-MEDIUM",
				"NIK POP_PCORN RTE_12X-EXTRA BIG",
				"NIK POT_POTATO_01X-MINI",
				"NIK POT_POTATO_01X-SS XSMALL",
				"NIK POT_POTATO_02X-JR LINE",
				"NIK POT_POTATO_07X-MEDIUM",
				"NIK POT_POTATO_09X-GRANDE",
				"NIK POT_POTATO_11X-BIG",
				"NIK POT_POTATO_12X-EXTRA BIG",
				"NIK POT_POTATO_15X-MEGA",
				"NIK POT_POTATO_16X-SUPER SIZE",
				"NIK WHT_WHEAT_01X-MINI",
				"NIK WHT_WHEAT_04X-SS LARGE",
				"NIK WHT_WHEAT_07X-MEDIUM",
				"NIK WHT_WHEAT_12X-EXTRA BIG",
				"NIK WHT_WHEAT_16X-SUPER SIZE",
				"NUC REG_NUTS_07X-MEDIUM",
				"NUE REG_NUTS_07X-MEDIUM",
				"OAS COR_CORN_15X-MEGA",
				"OAS NUT_NUTS_07X-MEDIUM",
				"OAS NUT_NUTS_09X-GRANDE",
				"OAS PEL_PELLET_01X-SS XSMALL",
				"OAS PEL_PELLET_04X-SS LARGE",
				"OAS POT_POTATO_07X-MEDIUM",
				"OAS POT_POTATO_09X-GRANDE",
				"OAS POT_POTATO_16X-SUPER SIZE",
				"OAS WHE_WHEAT_07X-MEDIUM",
				"OKE LEC_TRAD CKY_02X-JR LINE",
				"OKE LEC_TRAD CKY_07X-MAX",
				"OKE LEC_TRAD CKY_07X-MEDIUM",
				"OKE LEC_TRAD CKY_09X-JUMBO",
				"OKE LEC_TRAD CKY_11X-BIG",
				"OKE MOL_OTH CKY_02X-JR LINE",
				"OKE MOL_OTH CKY_07X-MAX",
				"OKE MOL_OTH CKY_07X-MEDIUM",
				"OKE MOL_OTH CKY_09X-GRANDE",
				"OKE MOL_OTH CKY_09X-JUMBO",
				"OKE REG_OTH CKY_07X-MAX",
				"OKE REG_OTH CKY_07X-MEDIUM",
				"OKE REG_OTH CKY_09X-GRANDE",
				"OKE REG_OTH CKY_09X-JUMBO",
				"ORO DUO_SNDWCH CKY_01X-SS XSMALL",
				"ORO DUO_SNDWCH CKY_07X-MEDIUM",
				"ORO GOL_SNDWCH CKY_07X-MEDIUM",
				"ORO GOL_SNDWCH CKY_11X-BIG",
				"ORO MIN_SNDWCH CKY_01X-SS XSMALL",
				"ORO MIN_SNDWCH CKY_07X-MAX",
				"ORE REG_SNDWCH CKY_07X-MAX",
				"ORE REG_SNDWCH CKY_07X-MEDIUM",
				"ORE REG_SNDWCH CKY_11X-BIG",
				"ORO TRI_SNDWCH CKY_04X-SS LARGE",
				"ORO TRI_SNDWCH CKY_07X-MEDIUM",
				"OTH COK_OTH CKY_01X-MINI",
				"OTH COK_OTH CKY_01X-SS XSMALL",
				"OTH COK_OTH CKY_04X-SS LARGE",
				"OTH COK_OTH CKY_07X-MAX",
				"OTH COK_OTH CKY_07X-MEDIUM",
				"OTH COK_OTH CKY_09X-GRANDE",
				"OTH COK_OTH CKY_09X-JUMBO",
				"OTH COK_OTH CKY_11X-BIG",
				"OTH COK_OTH CKY_12X-EXTRA BIG",
				"OTH COR_CORN_01X-MINI",
				"OTH COR_CORN_01X-SS XSMALL",
				"OTH COR_CORN_02X-JR LINE",
				"OTH COR_CORN_04X-SS LARGE",
				"OTH COR_CORN_07X-MAX",
				"OTH COR_CORN_07X-MEDIUM",
				"OTH COR_CORN_09X-GRANDE",
				"OTH COR_CORN_09X-JUMBO",
				"OTH COR_CORN_11X-BIG",
				"OTH COR_CORN_12X-EXTRA BIG",
				"OTH COR_CORN_15X-MEGA",
				"OTH COR_CORN_16X-SUPER SIZE",
				"OTH EXT_EXTRUD_01X-MINI",
				"OTH EXT_EXTRUD_01X-SS XSMALL",
				"OTH EXT_EXTRUD_02X-JR LINE",
				"OTH EXT_EXTRUD_04X-SS LARGE",
				"OTH EXT_EXTRUD_07X-MAX",
				"OTH EXT_EXTRUD_07X-MEDIUM",
				"OTH EXT_EXTRUD_09X-GRANDE",
				"OTH EXT_EXTRUD_09X-JUMBO",
				"OTH EXT_EXTRUD_11X-BIG",
				"OTH EXT_EXTRUD_12X-EXTRA BIG",
				"OTH HTY_HLTHY SNK_01X-MINI",
				"OTH HTY_HLTHY SNK_01X-SS XSMALL",
				"OTH HTY_HLTHY SNK_02X-JR LINE",
				"OTH HTY_HLTHY SNK_04X-SS LARGE",
				"OTH HTY_HLTHY SNK_07X-MAX",
				"OTH HTY_HLTHY SNK_07X-MEDIUM",
				"OTH HTY_SALTY MIX_09X-GRANDE",
				"OTH HTY_HLTHY SNK_11X-BIG",
				"OTH HTY_HLTHY SNK_12X-EXTRA BIG",
				"OTH HTY_SALTY MIX_16X-SUPER SIZE",
				"OTH MIX_SALTY MIX_01X-MINI",
				"OTH MIX_SALTY MIX_01X-SS XSMALL",
				"OTH MIX_SALTY MIX_07X-MAX",
				"OTH MIX_SALTY MIX_07X-MEDIUM",
				"OTH MIX_SALTY MIX_09X-GRANDE",
				"OTH MIX_SALTY MIX_09X-JUMBO",
				"OTH MIX_SALTY MIX_12X-EXTRA BIG",
				"OTH MIX_SALTY MIX_16X-SUPER SIZE",
				"OTH NTS_NUTS_01X-MINI",
				"OTH NTS_NUTS_01X-SS XSMALL",
				"OTH NTS_OTH SAV_01X-SS XSMALL",
				"OTH NTS_NUTS_02X-JR LINE",
				"OTH NTS_OTH SAV_02X-JR LINE",
				"OTH NTS_NUTS_04X-SS LARGE",
				"OTH NTS_OTH SAV_04X-SS LARGE",
				"OTH NTS_NUTS_07X-MAX",
				"OTH NTS_OTH SAV_07X-MAX",
				"OTH NTS_NUTS_07X-MEDIUM",
				"OTH NTS_OTH SAV_07X-MEDIUM",
				"OTH NTS_NUTS_09X-GRANDE",
				"OTH NTS_OTH SAV_09X-GRANDE",
				"OTH NTS_NUTS_09X-JUMBO",
				"OTH NTS_NUTS_11X-BIG",
				"OTH NTS_NUTS_12X-EXTRA BIG",
				"OTH NTS_NUTS_15X-MEGA",
				"OTH NTS_NUTS_16X-SUPER SIZE",
				"OTH OTH_OTH SAV_01X-MINI",
				"OTH OTH_OTH SAV_01X-SS XSMALL",
				"OTH OTH_OTH SAV_04X-SS LARGE",
				"OTH OTH_OTH SAV_07X-MAX",
				"OTH OTH_OTH SAV_07X-MEDIUM",
				"OTH OTH_OTH SAV_09X-GRANDE",
				"OTH OTH_OTH SAV_09X-JUMBO",
				"OTH OTH_OTH SAV_11X-BIG",
				"OTH OTH_OTH SAV_12X-EXTRA BIG",
				"OTH PEL_PELLET_01X-MINI",
				"OTH PEL_PELLET_01X-SS XSMALL",
				"OTH PEL_PELLET_02X-JR LINE",
				"OTH PEL_PELLET_04X-SS LARGE",
				"OTH PEL_PELLET_07X-MAX",
				"OTH PEL_PELLET_07X-MEDIUM",
				"OTH PEL_PELLET_09X-GRANDE",
				"OTH PEL_PELLET_09X-JUMBO",
				"OTH PEL_PELLET_11X-BIG",
				"OTH PEL_PELLET_12X-EXTRA BIG",
				"OTH PEL_PELLET_16X-SUPER SIZE",
				"OTH POP_PCORN RTE_01X-SS XSMALL",
				"OTH POP_PCORN RTE_02X-JR LINE",
				"OTH POP_PCORN RTE_04X-SS LARGE",
				"OTH POP_PCORN RTE_07X-MAX",
				"OTH POP_PCORN RTE_07X-MEDIUM",
				"OTH POP_PCORN RTE_09X-GRANDE",
				"OTH POP_PCORN RTE_09X-JUMBO",
				"OTH POP_PCORN RTE_12X-EXTRA BIG",
				"OTH POT_POTATO_01X-MINI",
				"OTH POT_POTATO_01X-SS XSMALL",
				"OTH POT_POTATO_02X-JR LINE",
				"OTH POT_POTATO_04X-SS LARGE",
				"OTH POT_POTATO_07X-MAX",
				"OTH POT_POTATO_07X-MEDIUM",
				"OTH POT_POTATO_09X-GRANDE",
				"OTH POT_POTATO_09X-JUMBO",
				"OTH POT_POTATO_11X-BIG",
				"OTH POT_POTATO_12X-EXTRA BIG",
				"OTH POT_POTATO_15X-MEGA",
				"OTH POT_POTATO_16X-SUPER SIZE",
				"OTH TOR_TORTIL_01X-SS XSMALL",
				"OTH TOR_TORTIL_04X-SS LARGE",
				"OTH TOR_TORTIL_07X-MEDIUM",
				"OTH TOR_TORTIL_09X-GRANDE",
				"OTH TOR_TORTIL_09X-JUMBO",
				"OTH TOR_TORTIL_11X-BIG",
				"OTH TOR_TORTIL_12X-EXTRA BIG",
				"OTH TRA_TRAD CKY_01X-MINI",
				"OTH TRA_TRAD CKY_01X-SS XSMALL",
				"OTH TRA_TRAD CKY_04X-SS LARGE",
				"OTH TRA_TRAD CKY_07X-MAX",
				"OTH TRA_TRAD CKY_07X-MEDIUM",
				"OTH TRA_TRAD CKY_09X-GRANDE",
				"OTH TRA_TRAD CKY_09X-JUMBO",
				"OTH TRA_TRAD CKY_11X-BIG",
				"OTH TRA_TRAD CKY_12X-EXTRA BIG",
				"OTH TRA_TRAD CKY_15X-MEGA",
				"OTH TRA_TRAD CKY_16X-SUPER SIZE",
				"OTH WAF_WFR CKY_01X-MINI",
				"OTH WAF_WFR CKY_01X-SS XSMALL",
				"OTH WAF_WFR CKY_07X-MAX",
				"OTH WAF_WFR CKY_07X-MEDIUM",
				"OTH WAF_WFR CKY_09X-GRANDE",
				"OTH WAF_WFR CKY_09X-JUMBO",
				"OTH WAF_WFR CKY_11X-BIG",
				"OTH WHT_WHEAT_01X-MINI",
				"OTH WHT_WHEAT_01X-SS XSMALL",
				"OTH WHT_WHEAT_02X-JR LINE",
				"OTH WHT_WHEAT_04X-SS LARGE",
				"OTH WHT_WHEAT_07X-MAX",
				"OTH WHT_WHEAT_07X-MEDIUM",
				"OTH WHT_WHEAT_09X-GRANDE",
				"OTH WHT_WHEAT_09X-JUMBO",
				"OTH WHT_WHEAT_11X-BIG",
				"OTH WHT_WHEAT_12X-EXTRA BIG",
				"OTH WHT_WHEAT_16X-SUPER SIZE",
				"PAR PIT_SNDWCH CKY_01X-SS XSMALL",
				"PAR PIT_SNDWCH CKY_07X-MAX",
				"PAR PIT_SNDWCH CKY_07X-MEDIUM",
				"PAR PIT_SNDWCH CKY_09X-JUMBO",
				"PUV REG_NUTS_01X-SS XSMALL",
				"PED COR_CORN_02X-JR LINE",
				"PED COR_CORN_04X-SS LARGE",
				"PED COR_CORN_07X-MAX",
				"PED EXT_EXTRUD_01X-SS XSMALL",
				"PED EXT_EXTRUD_04X-SS LARGE",
				"PED EXT_EXTRUD_07X-MAX",
				"PED EXT_EXTRUD_09X-GRANDE",
				"PED EXT_EXTRUD_09X-JUMBO",
				"PED NUT_NUTS_07X-MAX",
				"PED NUT_NUTS_07X-MEDIUM",
				"PED NUT_NUTS_16X-SUPER SIZE",
				"PED OTH_OTH SAV_04X-SS LARGE",
				"PED OTH_OTH SAV_07X-MAX",
				"PED PEL_PELLET_02X-JR LINE",
				"PED PEL_PELLET_04X-SS LARGE",
				"PED PEL_PELLET_07X-MEDIUM",
				"PED PEL_PELLET_15X-MEGA",
				"PED REG_POTATO_01X-MINI",
				"PED REG_POTATO_01X-SS XSMALL",
				"PED REG_POTATO_02X-JR LINE",
				"PED REG_POTATO_07X-MAX",
				"PED REG_POTATO_07X-MEDIUM",
				"PED REG_POTATO_09X-GRANDE",
				"PED REG_POTATO_12X-EXTRA BIG",
				"PED REG_POTATO_16X-SUPER SIZE",
				"PED WHT_WHEAT_04X-SS LARGE",
				"PED WHT_WHEAT_07X-MAX",
				"PED WHT_WHEAT_16X-SUPER SIZE",
				"PPT CHI_OTH CKY_02X-JR LINE",
				"PPT CHI_OTH CKY_09X-GRANDE",
				"PPT MIN_OTH CKY_01X-SS XSMALL",
				"PPT MIN_OTH CKY_07X-MAX",
				"PEP REL_OTH CKY_07X-MEDIUM",
				"PEP REL_OTH CKY_11X-BIG",
				"PEP STI_OTH CKY_02X-JR LINE",
				"PEP STI_OTH CKY_07X-MAX",
				"PEP TOR_OTH CKY_07X-MEDIUM",
				"PEP TRA_OTH CKY_07X-MEDIUM",
				"PEP TRA_OTH CKY_11X-BIG",
				"PEP WHI_OTH CKY_07X-MEDIUM",
				"PEP WHI_OTH CKY_11X-BIG",
				"PND REG_PLAIN CRK_11X-BIG",
				"PIA REG_NUTS_01X-MINI",
				"PIA REG_NUTS_01X-SS XSMALL",
				"PIA REG_NUTS_07X-MAX",
				"PIA REG_NUTS_07X-MEDIUM",
				"PIA REG_NUTS_09X-GRANDE",
				"PFN REG_NUTS_02X-JR LINE",
				"PFN REG_NUTS_07X-MEDIUM",
				"PFN REG_NUTS_09X-GRANDE",
				"PFN REG_NUTS_12X-EXTRA BIG",
				"PFN REG_NUTS_16X-SUPER SIZE",
				"POZ REG_FILL CKY_01X-SS XSMALL",
				"POZ REG_FILL CKY_04X-SS LARGE",
				"POZ REG_FILL CKY_07X-MAX",
				"POZ REG_FILL CKY_09X-GRANDE",
				"POZ REG_FILL CKY_11X-BIG",
				"POZ REG_FILL CKY_12X-EXTRA BIG",
				"POZ REG_FILL CKY_16X-SUPER SIZE",
				"PRI REG_POTATO_01X-SS XSMALL",
				"PRI REG_POTATO_02X-JR LINE",
				"PRI REG_POTATO_07X-MAX",
				"PRI REG_POTATO_07X-MEDIUM",
				"PRI TOR_TORTIL_07X-MAX",
				"PVL_OTH SAV_01X-SS XSMALL",
				"PVL_OTH SAV_04X-SS LARGE",
				"PVL_OTH SAV_07X-MAX",
				"PVL_OTH SAV_07X-MEDIUM",
				"PVL_OTH SAV_09X-JUMBO",
				"PVL COR_CORN_02X-JR LINE",
				"PVL COR_CORN_07X-MAX",
				"PVL COR_CORN_11X-BIG",
				"PVL MIX_SALTY MIX_01X-MINI",
				"PVL MIX_SALTY MIX_01X-SS XSMALL",
				"PVL MIX_SALTY MIX_09X-GRANDE",
				"PVL NUT_NUTS_04X-SS LARGE",
				"PVL NUT_NUTS_07X-MAX",
				"PVL NUT_NUTS_07X-MEDIUM",
				"PVL NUT_NUTS_09X-GRANDE",
				"PVL NUT_NUTS_09X-JUMBO",
				"PVL NUT_NUTS_12X-EXTRA BIG",
				"PVL NUT_NUTS_16X-SUPER SIZE",
				"PV POT_POTATO_01X-MINI",
				"PV POT_POTATO_01X-SS XSMALL",
				"PV POT_POTATO_02X-JR LINE",
				"PV POT_POTATO_04X-SS LARGE",
				"PV POT_POTATO_07X-MAX",
				"PV POT_POTATO_07X-MEDIUM",
				"PV POT_POTATO_09X-JUMBO",
				"PV POT_POTATO_12X-EXTRA BIG",
				"PV POT_POTATO_16X-SUPER SIZE",
				"PVL TOR_TORTIL_01X-SS XSMALL",
				"PVL TOR_TORTIL_04X-SS LARGE",
				"PVL TOR_TORTIL_07X-MAX",
				"PVL TOR_TORTIL_07X-MEDIUM",
				"PVL TOR_TORTIL_09X-GRANDE",
				"PVL TOR_TORTIL_12X-EXTRA BIG",
				"PVL WHT_WHEAT_07X-MEDIUM",
				"PVL WHT_WHEAT_11X-BIG",
				"PVL WHT_WHEAT_16X-SUPER SIZE",
				"QTO HTY_HLTHY SNK_04X-SS LARGE",
				"QTO HTY_HLTHY SNK_07X-MAX",
				"QTO MIX_SALTY MIX_07X-MEDIUM",
				"QTO NUT_NUTS_07X-MAX",
				"QTO REG_POTATO_01X-SS XSMALL",
				"QTO REG_POTATO_07X-MAX",
				"QTO REG_POTATO_07X-MEDIUM",
				"QTO TOR_TORTIL_07X-MEDIUM",
				"QTO WHT_WHEAT_07X-MEDIUM",
				"SDX BAC_CORN_01X-MINI",
				"SDX BAC_CORN_02X-JR LINE",
				"SDX BAE_EXTRUD_01X-MINI",
				"SDX BAE_EXTRUD_02X-JR LINE",
				"SDX CRA_POTATO_01X-SS XSMALL",
				"SDX CRA_POTATO_07X-MEDIUM",
				"SDX CRO_POTATO_01X-MINI",
				"SDX CRO_POTATO_01X-SS XSMALL",
				"SDX CRO_POTATO_02X-JR LINE",
				"SDX CRO_POTATO_07X-MEDIUM",
				"SDX CRW_WHEAT_01X-MINI",
				"SDX CRW_WHEAT_01X-SS XSMALL",
				"SDX CRW_WHEAT_02X-JR LINE",
				"SDX CRW_WHEAT_07X-MAX",
				"SDX CRW_WHEAT_07X-MEDIUM",
				"SDX HUL_POTATO_01X-SS XSMALL",
				"SDX HUL_POTATO_04X-SS LARGE",
				"SDX HUL_POTATO_07X-MAX",
				"SDX KES_CORN_01X-SS XSMALL",
				"SDX KES_CORN_02X-JR LINE",
				"SDX KES_CORN_04X-SS LARGE",
				"SDX KES_CORN_07X-MAX",
				"SDX KES_CORN_07X-MEDIUM",
				"SDX MIL_CORN_01X-SS XSMALL",
				"SDX MIL_CORN_02X-JR LINE",
				"SDX OTH_OTH SAV_01X-SS XSMALL",
				"SDX OTH_OTH SAV_02X-JR LINE",
				"SDX PAN_CORN_01X-SS XSMALL",
				"SDX PAN_CORN_02X-JR LINE",
				"SDX PIC_POTATO_01X-SS XSMALL",
				"SDX PIC_POTATO_02X-JR LINE",
				"SDX PIC_POTATO_07X-MEDIUM",
				"SDX PIZ_CORN_01X-SS XSMALL",
				"SDX PIZ_CORN_02X-JR LINE",
				"SDX POT_POTATO_01X-SS XSMALL",
				"SDX POT_POTATO_02X-JR LINE",
				"SDX POT_POTATO_07X-MAX",
				"SDX POT_POTATO_07X-MEDIUM",
				"SDX QUE_EXTRUD_01X-MINI",
				"SDX QUE_EXTRUD_07X-MEDIUM",
				"SDX RIN_CORN_01X-SS XSMALL",
				"SDX RIN_CORN_02X-JR LINE",
				"SDX RIN_CORN_07X-MEDIUM",
				"SDX STI_CORN_01X-SS XSMALL",
				"SDX STI_CORN_04X-SS LARGE",
				"SDX STI_CORN_07X-MEDIUM",
				"SDX TOR_TORTIL_01X-MINI",
				"SJT MIX_SALTY MIX_07X-MAX",
				"SJT MIX_SALTY MIX_12X-EXTRA BIG",
				"SJT REG_NUTS_07X-MEDIUM",
				"SJT REG_NUTS_09X-GRANDE",
				"SJT REG_NUTS_12X-EXTRA BIG",
				"SJT REG_NUTS_16X-SUPER SIZE",
				"SMX MN_NUTS_07X-MEDIUM",
				"SMX MN_NUTS_16X-SUPER SIZE",
				"SMX MIX_SALTY MIX_01X-SS XSMALL",
				"SMX MIX_SALTY MIX_02X-JR LINE",
				"SMX POP_PCORN RTE_04X-SS LARGE",
				"SMX REG_NUTS_07X-MEDIUM",
				"SSS REG_POTATO_01X-MINI",
				"SSS REG_POTATO_01X-SS XSMALL",
				"SCK REG_CORN_01X-MINI",
				"SCK REG_CORN_04X-SS LARGE",
				"SNY REG_OTH SAV_07X-MEDIUM",
				"SNY REG_OTH SAV_09X-GRANDE",
				"SNY REG_OTH SAV_09X-JUMBO",
				"SNY REG_OTH SAV_11X-BIG",
				"STS REG_OTH CKY_01X-SS XSMALL",
				"STS REG_OTH CKY_04X-SS LARGE",
				"STS REG_OTH CKY_07X-MAX",
				"STS REG_OTH CKY_07X-MEDIUM",
				"STS REG_OTH CKY_09X-JUMBO",
				"STS REG_OTH CKY_11X-BIG",
				"STS REG_OTH CKY_12X-EXTRA BIG",
				"STS REG_OTH CKY_16X-SUPER SIZE",
				"SOI REG_SNDWCH CKY_07X-MEDIUM",
				"SOI REG_SNDWCH CKY_09X-GRANDE",
				"SOI REG_SNDWCH CKY_12X-EXTRA BIG",
				"TPN REG_FILL CKY_01X-SS XSMALL",
				"TPN REG_FILL CKY_04X-SS LARGE",
				"TPN REG_FILL CKY_07X-MAX",
				"TPN REG_FILL CKY_09X-GRANDE",
				"TPN REG_FILL CKY_09X-JUMBO",
				"TPN REG_FILL CKY_11X-BIG",
				"TPN REG_FILL CKY_12X-EXTRA BIG",
				"TPN REG_FILL CKY_16X-SUPER SIZE",
				"TRA MRE_NUTS_07X-MEDIUM",
				"TRB VTY_OTH CKY_09X-JUMBO",
				"TRB VTY_OTH CKY_12X-EXTRA BIG",
				"TRB VTY_OTH CKY_15X-MEGA",
				"TBS ANI_OTH CKY_07X-MAX",
				"TBS BDA_OTH CKY_07X-MAX",
				"TBS BDA_OTH CKY_09X-GRANDE",
				"TBS CHA_WFR CKY_02X-JR LINE",
				"TBS CHA_WFR CKY_04X-SS LARGE",
				"TBS CHA_WFR CKY_07X-MEDIUM",
				"TBS DUQ_SNDWCH CKY_07X-MAX",
				"TBS DUQ_SNDWCH CKY_07X-MEDIUM",
				"TBS LIN_TRAD CKY_07X-MAX",
				"TBS LIN_TRAD CKY_12X-EXTRA BIG",
				"TBS MAN_TRAD CKY_07X-MAX",
				"TBS MAN_TRAD CKY_07X-MEDIUM",
				"TBS MAR_MARIA CKY_07X-MEDIUM",
				"TBS ROC_OTH CKY_07X-MAX",
				"TBS ROC_OTH CKY_09X-GRANDE",
				"TEA REG_NUTS_01X-SS XSMALL",
				"TMR ANI_OTH CKY_09X-GRANDE",
				"TMR ANI_OTH CKY_09X-JUMBO",
				"TMR AZU_OTH CKY_09X-GRANDE",
				"TMR AZU_OTH CKY_09X-JUMBO",
				"TMR COK_OTH CKY_01X-SS XSMALL",
				"TMR COK_OTH CKY_07X-MAX",
				"TMR COK_OTH CKY_07X-MEDIUM",
				"TMR COK_OTH CKY_09X-GRANDE",
				"TMR COK_OTH CKY_09X-JUMBO",
				"TMR COK_OTH CKY_12X-EXTRA BIG",
				"TMR CRZ_OTH CKY_09X-GRANDE",
				"TMR COR_CORN_02X-JR LINE",
				"TMR JUN_OTH CKY_09X-GRANDE",
				"TMR JUN_OTH CKY_09X-JUMBO",
				"TMR NUT_NUTS_07X-MEDIUM",
				"TMR PAL_OTH CKY_09X-GRANDE",
				"TMR PEP_FILL CKY_09X-GRANDE",
				"TMR PEP_FILL CKY_09X-JUMBO",
				"TMR PEP_FILL CKY_11X-BIG",
				"TMR PEP_FILL CKY_12X-EXTRA BIG",
				"TMR REG_POTATO_01X-SS XSMALL",
				"TMR REG_POTATO_02X-JR LINE",
				"TMR REG_POTATO_07X-MEDIUM",
				"TMR REG_POTATO_09X-JUMBO",
				"TMR ROS_OTH CKY_09X-GRANDE",
				"TMR TOR_PLAIN CRK_09X-GRANDE",
				"TMR TOR_PLAIN CRK_09X-JUMBO",
				"TMR TRI_PLAIN CRK_09X-GRANDE",
				"TMR TRI_PLAIN CRK_09X-JUMBO",
				"TMR VTY_OTH CKY_07X-MAX",
				"TMR VTY_OTH CKY_09X-GRANDE",
				"TMR WHT_WHEAT_07X-MEDIUM",
				"TSI REG_PELLET_07X-MEDIUM",
				"TTL OCP_POTATO_02X-JR LINE",
				"TJN REG_NUTS_01X-SS XSMALL",
				"TJN REG_NUTS_09X-GRANDE",
				"TIO PEP_FILL CKY_01X-SS XSMALL",
				"TIO PEP_FILL CKY_07X-MAX",
				"TIO PEP_FILL CKY_09X-GRANDE",
				"TIO PEP_FILL CKY_09X-JUMBO",
				"TIO PEP_FILL CKY_11X-BIG",
				"TIO PEP_FILL CKY_12X-EXTRA BIG",
				"TIO PEP_FILL CKY_16X-SUPER SIZE",
				"TIO REG_FILL CKY_07X-MAX",
				"TIO REG_FILL CKY_09X-GRANDE",
				"TIO REG_FILL CKY_09X-JUMBO",
				"TIO REG_FILL CKY_11X-BIG",
				"TIO REG_FILL CKY_12X-EXTRA BIG",
				"TIO REG_FILL CKY_16X-SUPER SIZE",
				"TIO VTY_FILL CKY_07X-MAX",
				"TIO VTY_FILL CKY_09X-JUMBO",
				"TIO VTY_FILL CKY_11X-BIG",
				"TIO VTY_FILL CKY_12X-EXTRA BIG",
				"TIO VTY_FILL CKY_15X-MEGA",
				"VNT REG_OTH CKY_04X-SS LARGE",
				"VNT REG_OTH CKY_07X-MAX",
				"VNT REG_OTH CKY_11X-BIG",
				"VNT REG_OTH CKY_12X-EXTRA BIG",
				"VRI REG_NUTS_01X-SS XSMALL",
				"WFL PIS_NUTS_09X-GRANDE",
				"YGR COR_CORN_02X-JR LINE",
				"YTS REG_HLTHY SNK_02X-JR LINE",
				"YTS REG_HLTHY SNK_04X-SS LARGE",
				"ZAF REG_NUTS_01X-MINI",
				"ZYM POT_POTATO_01X-SS XSMALL",
				"ZYM REG_NUTS_07X-MAX",
				"ZYM REG_OTH SAV_07X-MAX",
				"ZYM REG_NUTS_07X-MEDIUM",
				"ZYM REG_OTH SAV_09X-GRANDE",
				"ZYM REG_NUTS_09X-JUMBO",
			],
		},
		startDate: "01/01/2024",
		endDate: "03/29/2024",
		variable1: "SOM",
		SOMMeasure: "Category",
		SOMGroup: "Volume",
		APIUnits: "Units",
	},
	{
		country: "BRAZIL",
		geoLevel: ["COUNTRY-TTL"],
		geoLevel2: ["TTL BRAZIL-ALL"],
		channel: ["ALL OUTLET"],
		periodView: ["na"],
		viewShareby: "valueSales",
		indexes: ["20", "10"],
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO", "SAV-WHEAT"],
			brand: [
				"AGITO",
				"BACONZITOS",
				"BACONZITOS REGULAR",
				"ELMA CHIPS STAX",
				"ELMA CHIPS STIKSY",
				"LAYS",
				"LAYS REGULAR",
				"MILHOBOM REGULAR",
				"PINGO D OURO",
				"PINGO DOURO REGULAR",
				"RUFFLES",
				"RUFFLES REGULAR",
				"RUFFLES STAX",
				"SENSACOES",
				"SENSACOES ELMA CHIPS",
				"SENSATIONS",
				"STAX",
				"STAX REGULAR",
				"STIKSY REGULAR",
				"TORCIDA",
				"TORCIDA JR",
				"TORCIDA REGULAR",
				"TORCIDA SNACK BAR",
				"ZAMBINOS REGULAR",
			],
			subBrand: [
				"AGITO",
				"BACONZITOS",
				"BACONZITOS REGULAR",
				"ELMA CHIPS STAX",
				"ELMA CHIPS STIKSY",
				"LAYS",
				"LAYS REGULAR",
				"MILHOBOM REGULAR",
				"PINGO D OURO",
				"PINGO DOURO REGULAR",
				"RUFFLES",
				"RUFFLES REGULAR",
				"RUFFLES STAX",
				"SENSACOES",
				"SENSACOES ELMA CHIPS",
				"SENSATIONS",
				"STAX",
				"STAX REGULAR",
				"STIKSY REGULAR",
				"TORCIDA",
				"TORCIDA JR",
				"TORCIDA REGULAR",
				"TORCIDA SNACK BAR",
				"ZAMBINOS REGULAR",
			],
			packSize: [
				"04X-SS LARGE",
				"05P-MULTIPK",
				"07X-MEDIUM",
				"11X-BIG",
				"12X-EXTRA BIG",
				"14X-FAMILY",
				"15P-MULTIPK",
				"15X-MEGA",
				"16X-SUPER SIZE",
				"HEAVY USER/PRA FESTA",
				"PRA GALERA ENTR. (101G-120G)",
				"PRA GALERA ENTR. (95G-150G)",
				"PRA GALERA REGULAR",
				"PRA GALERA UPPER (200G-250G)",
				"PRA GENTE",
				"PRA J",
				"PRA MIM",
			],
			vendor: ["PEPSICO"],
			permutation: [
				"AGT PEP_WHEAT_PRA GENTE",
				"AGT PEP_WHEAT_PRA MIM",
				"BZT PEP_WHEAT_PRA GALERA REGULAR",
				"BZT PEP_WHEAT_PRA GENTE",
				"BZT PEP_WHEAT_PRA J",
				"BZT PEP_WHEAT_PRA MIM",
				"BZT REG_WHEAT_PRA GALERA ENTR. (95G-150G)",
				"BZT REG_WHEAT_PRA GALERA REGULAR",
				"BZT REG_WHEAT_PRA GENTE",
				"BZT REG_WHEAT_PRA J",
				"BZT REG_WHEAT_PRA MIM",
				"ELM STX_POTATO_PRA GALERA REGULAR",
				"ELM STK_WHEAT_PRA GALERA ENTR. (95G-150G)",
				"ELM STK_WHEAT_PRA MIM",
				"LAYPEP_POTATO_PRA GALERA ENTR. (95G-150G)",
				"LAYPEP_POTATO_PRA GALERA REGULAR",
				"LAYPEP_POTATO_PRA GENTE",
				"LAYPEP_POTATO_PRA J",
				"LAYPEP_POTATO_PRA MIM",
				"LAY REG_POTATO_04X-SS LARGE",
				"LAY REG_POTATO_07X-MEDIUM",
				"LAY REG_POTATO_11X-BIG",
				"LAY REG_POTATO_14X-FAMILY",
				"LAY REG_POTATO_PRA GALERA ENTR. (101G-120G)",
				"LAY REG_POTATO_PRA GALERA REGULAR",
				"LAY REG_POTATO_PRA GENTE",
				"LAY REG_POTATO_PRA J",
				"LAY REG_POTATO_PRA MIM",
				"MBM REG_WHEAT_PRA MIM",
				"PNG PEP_WHEAT_04X-SS LARGE",
				"PNG PEP_WHEAT_PRA GALERA ENTR. (95G-150G)",
				"PNG PEP_WHEAT_PRA GENTE",
				"PNG PEP_WHEAT_PRA MIM",
				"PDO REG_WHEAT_PRA GALERA ENTR. (95G-150G)",
				"PDO REG_WHEAT_PRA GENTE",
				"PDO REG_WHEAT_PRA MIM",
				"RFL PEP_POTATO_HEAVY USER/PRA FESTA",
				"RFL PEP_POTATO_PRA GALERA ENTR. (101G-120G)",
				"RFL PEP_POTATO_PRA GALERA REGULAR",
				"RFL PEP_POTATO_PRA GENTE",
				"RFL PEP_POTATO_PRA J",
				"RFL PEP_POTATO_PRA MIM",
				"RUF REG_POTATO_15P-MULTIPK",
				"RUF REG_POTATO_HEAVY USER/PRA FESTA",
				"RUF REG_POTATO_PRA GALERA ENTR. (101G-120G)",
				"RUF REG_POTATO_PRA GALERA REGULAR",
				"RUF REG_POTATO_PRA GENTE",
				"RUF REG_POTATO_PRA J",
				"RUF REG_POTATO_PRA MIM",
				"RFL STX_POTATO_PRA GALERA REGULAR",
				"RFL STX_POTATO_PRA GENTE",
				"SNO ECH_POTATO_05P-MULTIPK",
				"SNO ECH_POTATO_PRA GALERA REGULAR",
				"SNO ECH_POTATO_PRA GENTE",
				"SNO ECH_POTATO_PRA MIM",
				"SON PEP_POTATO_PRA GENTE",
				"SON PEP_POTATO_PRA MIM",
				"SNS PEP_POTATO_PRA GALERA REGULAR",
				"SNS PEP_POTATO_PRA MIM",
				"STX PEP_POTATO_12X-EXTRA BIG",
				"STX PEP_POTATO_PRA GALERA REGULAR",
				"STX REG_POTATO_PRA GALERA REGULAR",
				"SKS REG_WHEAT_PRA GALERA ENTR. (95G-150G)",
				"SKS REG_WHEAT_PRA GENTE",
				"SKS REG_WHEAT_PRA MIM",
				"TRC PEP_WHEAT_04X-SS LARGE",
				"TRC PEP_WHEAT_07X-MEDIUM",
				"TRC PEP_WHEAT_11X-BIG",
				"TRC PEP_WHEAT_12X-EXTRA BIG",
				"TRC PEP_WHEAT_14X-FAMILY",
				"TRC PEP_WHEAT_15X-MEGA",
				"TRC PEP_WHEAT_16X-SUPER SIZE",
				"TRC JR_WHEAT_04X-SS LARGE",
				"TRC JR_WHEAT_07X-MEDIUM",
				"TRC PEP_WHEAT_PRA GALERA ENTR. (95G-150G)",
				"TRC PEP_WHEAT_PRA GENTE",
				"TRC PEP_WHEAT_PRA MIM",
				"TRC REG_WHEAT_04X-SS LARGE",
				"TRC REG_WHEAT_07X-MEDIUM",
				"TRC REG_WHEAT_11X-BIG",
				"TRC REG_WHEAT_12X-EXTRA BIG",
				"TRC REG_WHEAT_16X-SUPER SIZE",
				"TRC REG_WHEAT_PRA GALERA ENTR. (95G-150G)",
				"TRC REG_WHEAT_PRA GALERA REGULAR",
				"TRC REG_WHEAT_PRA GALERA UPPER (200G-250G)",
				"TRC REG_WHEAT_PRA GENTE",
				"TRC REG_WHEAT_PRA MIM",
				"TRC SNB_WHEAT_04X-SS LARGE",
				"TRC SNB_WHEAT_07X-MEDIUM",
				"ZMB NOS_WHEAT_PRA GENTE",
				"ZMB NOS_WHEAT_PRA MIM",
			],
		},
		competitor: {
			vendor: [
				"AGTAL",
				"ANCHIETA",
				"APTI ALIM.",
				"CARAMURU",
				"DORI",
				"ENOVA",
				"GENERAL MILLS",
				"GULOZITOS",
				"KELLOGGS",
				"LIOTECNICA",
				"MAE TERRA",
				"MARATA",
				"MARCA PROPRIA",
				"MONDELEZ",
				"NESTLE",
				"OTHER VENDOR",
				"SAO BRAZ",
			],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO", "SAV-WHEAT"],
			brand: ["NA MESA", "OTHER POTATO SUBBRAND", "OTHER WHEAT SUBBRAND", "PRINGLES", "SANTA HELENA REGULAR", "SINHA REGULAR"],
			subBrand: ["NA MESA", "OTHER POTATO SUBBRAND", "OTHER WHEAT SUBBRAND", "PRINGLES", "SANTA HELENA REGULAR", "SINHA REGULAR"],
			packSize: [
				"10P-MULTIPK",
				"HEAVY USER/PRA FESTA",
				"PRA GALERA ENTR. (101G-120G)",
				"PRA GALERA ENTR. (95G-150G)",
				"PRA GALERA REGULAR",
				"PRA GALERA UPPER (200G-250G)",
				"PRA GALERA UPPER (400G-750G)",
				"PRA GENTE",
				"PRA J",
				"PRA MIM",
				"REGULAR",
				"RESTOCK",
				"UPSIZE",
			],
			permutation: [
				"NAM CHP_POTATO_HEAVY USER/PRA FESTA",
				"NAM CHP_POTATO_PRA GENTE",
				"NAM CHP_POTATO_REGULAR",
				"NAM CHP_POTATO_RESTOCK",
				"NAM CHP_POTATO_UPSIZE",
				"OHS PTO_POTATO_10P-MULTIPK",
				"OHS PTO_POTATO_HEAVY USER/PRA FESTA",
				"OHS PTO_POTATO_PRA GALERA ENTR. (101G-120G)",
				"OHS PTO_POTATO_PRA GALERA REGULAR",
				"OHS PTO_POTATO_PRA GENTE",
				"OHS PTO_POTATO_PRA J",
				"OHS PTO_POTATO_PRA MIM",
				"OHS PTO_POTATO_UPSIZE",
				"OHS WET_WHEAT_PRA GALERA ENTR. (95G-150G)",
				"OHS WET_WHEAT_PRA GALERA REGULAR",
				"OHS WET_WHEAT_PRA GALERA UPPER (200G-250G)",
				"OHS WET_WHEAT_PRA GALERA UPPER (400G-750G)",
				"OHS WET_WHEAT_PRA GENTE",
				"OHS WET_WHEAT_PRA J",
				"OHS WET_WHEAT_PRA MIM",
				"PRN REG_POTATO_PRA GALERA ENTR. (101G-120G)",
				"PRN REG_POTATO_PRA GALERA REGULAR",
				"PRN REG_POTATO_PRA GENTE",
				"PRN REG_POTATO_PRA MIM",
				"SIN REG_POTATO_PRA GALERA REGULAR",
				"SIN REG_POTATO_PRA GENTE",
				"SIN REG_POTATO_PRA MIM",
			],
		},
		startDate: "01/01/2024",
		endDate: "03/29/2024",
		variable1: "SOM",
		SOMMeasure: "Category",
		SOMGroup: "Volume",
		APIUnits: "Units",
	},
];

export const defaultPRPLocalFilterConfiguration = { viewSharedByForPiano: "AbsoluteValue", currentVsLast: "CurrentPeriod" };

const dataObject = {
	productFilter,
	competitorFilter,
	measuresFilter,
	priceRanges,
	priceRangeTableHeading,
	pricePianoTableResponseData,
	specificFilter,
	pepsiPricePiano,
	competitorPricePiano,
	pricePionoVisData,
	pianoTableResponsePacksize,
	pianoTableResponsePricerange,
	pianoTableResponseIndexrange,
	pricePianoTableSkeletonData,
	seriesColor,
	visTwoColors,
	reviseResponse,
	pianoPackHeading,
	firstVisualApiData,
	pricePianoVisSkeletonData,
	priceRangeVisTableHeading,
	secondVisApiData,
	vis2GraphHeading,
};
export default dataObject;
