import { Box, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IStats } from "../../../../types/common";
import StatCard from "../../../../components/StatCard";
import dataObject from "../../../../mocks/executionTracker";
import { convertToInternationalCurrencySingleChar } from "../../../../util/helper";
const OverviewKPI: React.FC<{ data; chartSkeleton?: boolean }> = ({ data, chartSkeleton }) => {
	const [kpiData, setKpiData] = useState<IStats[]>([]);

	const formatKPIData = (response) => {
		const kpi = [...dataObject.KPIData];
		kpi[0].value = response.netRevenue[0].netRevenueValue ? convertToInternationalCurrencySingleChar("$", response.netRevenue[0].netRevenueValue) : "0";
		kpi[0].raise = response.netRevenue[0].percentageChange ? Number(response.netRevenue[0].percentageChange) >= 0 : false;
		kpi[0].change = response.netRevenue[0].percentageChange ? `${convertToInternationalCurrencySingleChar("", response.netRevenue[0].percentageChange)}%` : "0";
		kpi[1].value = response.profit[0].profitPercent ? convertToInternationalCurrencySingleChar("$", response.profit[0].profitPercent) : "0";
		kpi[1].raise = response.profit[0].percentageChange ? Number(response.profit[0].percentageChange) >= 0 : false;
		kpi[1].change = response.profit[0].percentageChange ? `${convertToInternationalCurrencySingleChar("", response.profit[0].percentageChange)}%` : "0";
		kpi[2].value = response.volume[0].percentageChange ? convertToInternationalCurrencySingleChar("$", response.volume[0].percentageChange) : "0";
		kpi[2].raise = response.volume[0].percentageChange ? Number(response.volume[0].percentageChange) >= 0 : false;
		kpi[2].change = response.volume[0].percentageChange ? `${convertToInternationalCurrencySingleChar("", response.volume[0].percentageChange)}%` : "0";
		setKpiData(kpi);
	};

	useEffect(() => {
		formatKPIData(data);
	}, []);
	return !chartSkeleton ? (
		<Box sx={{ background: "#005EA6", width: "auto", borderRadius: "5px", padding: 2 }}>
			<Grid container spacing={2} justifyContent="space-around">
				{kpiData.map((item: IStats, i) => (
					<Grid item xs={3} key={`set-1-stat-card-${i}`} className={item.className}>
						<StatCard data={item} showSkeleton={false} />
					</Grid>
				))}
			</Grid>
		</Box>
	) : (
		<Grid item xs={12}>
			<Skeleton variant="rectangular" height={140} />
		</Grid>
	);
};
export default OverviewKPI;
