import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, Grid, Tooltip } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import Dashboard from "../../../../components/Dashboard";
import IncrementDecrement from "../../../../components/IncrementDecrement";
import dataObject from "../../../../mocks/priceRangePianoMock";
import { loader, removeLabels } from "../../../../store/actions/common.action";
import { toast } from "../../../../store/actions/toast.action";
import { NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import { fetchPricePianoData } from "../../service";
import { pricePianoDataLoader } from "../../store/PriceRangePiano.action";
import PriceRangeGraph from "./components/PriceRangeGraph";
import HierarchicalTable from "./components/PriceRangeGraph/HierarchicalTable/HierarchicalTable";
import { ISOMTableDataObject, ISOMVendorDataObject } from "../../../../types/priceRangePiano";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import { getDistinctKeys } from "../../../../util/helper";

const useStyles = makeStyles(() => ({
	hide: {
		opacity: 0,
		"&:hover": {
			opacity: 1,
		},
	},
}));

function sortByPriceRanges(priceRangeData) {
	let sortedPriceRangeData = priceRangeData;
	if (priceRangeData.length > 0) {
		sortedPriceRangeData = priceRangeData.sort((aData, bData) => {
			if (aData.name.includes("<") || bData.name.includes(">")) {
				return -1;
			} else if (bData.name.includes("<") || aData.name.includes(">")) {
				return 1;
			} else {
				const aKeyStartingRange: number = parseInt(aData.name.replace(/\$/g, "").split("-")[0]);
				const bKeyStartingRange: number = parseInt(bData.name.replace(/\$/g, "").split("-")[0]);
				if (aKeyStartingRange < bKeyStartingRange) {
					return -1;
				} else {
					return 1;
				}
			}
		});
	}
	return sortedPriceRangeData;
}

function getChildObject(tableObject, value, date, totalValue, level) {
	const index: number = tableObject.children.findIndex((childObject) => childObject.name === value);
	if (index === -1) {
		tableObject.children.push({
			name: value,
			children: [],
			isCollapsed: false,
			data: [
				{
					label: date,
					value: totalValue,
				},
			],
			level: level,
		});
	} else {
		tableObject.children[index].data.push({
			label: date,
			value: totalValue,
		});
	}
	return tableObject;
}

function formatVendorLevelData(item, iterator, tableObject) {
	tableObject.push({
		name: item.productName,
		children: [],
		isCollapsed: false,
		data: [],
		level: 0,
	});
	item.priceRanges.map((itemPriceRange) => {
		const date: string = itemPriceRange.date;
		const productIndex: number = tableObject.findIndex((childObject) => childObject.name === item.productName);
		tableObject[productIndex].data.push({
			label: date,
			value: itemPriceRange.total,
		});
		Object.keys(itemPriceRange).map((rangeValue: string) => {
			if (rangeValue !== "total" && rangeValue !== "date") {
				tableObject[iterator] = getChildObject(tableObject[iterator], rangeValue, date, itemPriceRange[rangeValue].total, 1);
				const subRanges = itemPriceRange[rangeValue].subranges;
				const updatedRangeIndex = tableObject[iterator].children.findIndex((childObject) => childObject.name === rangeValue);
				if (subRanges) {
					Object.keys(subRanges)?.map((subRangeValue: string) => {
						tableObject[iterator].children[updatedRangeIndex] = getChildObject(
							tableObject[iterator].children[updatedRangeIndex],
							subRangeValue,
							date,
							subRanges[subRangeValue].total,
							2
						);
						const brands = subRanges[subRangeValue]?.brands;
						const updatedSubRangeIndex = tableObject[iterator].children[updatedRangeIndex].children.findIndex(
							(childObject) => childObject.name === subRangeValue
						);
						if (brands) {
							Object.keys(brands)?.map((brandValue: string) => {
								tableObject[iterator].children[updatedRangeIndex].children[updatedSubRangeIndex] = getChildObject(
									tableObject[iterator].children[updatedRangeIndex].children[updatedSubRangeIndex],
									brandValue,
									date,
									brands[brandValue].total,
									3
								);
								const subBrands = brands[brandValue]?.subBrands;
								const updatedBrandIndex: number = tableObject[iterator].children[updatedRangeIndex].children[updatedSubRangeIndex].children.findIndex(
									(childObject) => childObject.name === brandValue
								);
								if (subBrands) {
									Object.keys(subBrands)?.map((subBrandValue: string) => {
										tableObject[iterator].children[updatedRangeIndex].children[updatedSubRangeIndex].children[updatedBrandIndex] = getChildObject(
											tableObject[iterator].children[updatedRangeIndex].children[updatedSubRangeIndex].children[updatedBrandIndex],
											subBrandValue,
											date,
											subBrands[subBrandValue].total,
											4
										);
										const packSizes = subBrands[subBrandValue]?.packSize;
										const updatedSubBrandIndex: number = tableObject[iterator].children[updatedRangeIndex].children[updatedSubRangeIndex].children[
											updatedBrandIndex
										].children.findIndex((childObject) => childObject.name === subBrandValue);
										if (packSizes) {
											Object.keys(packSizes)?.map((packSizeValue: string) => {
												tableObject[iterator].children[updatedRangeIndex].children[updatedSubRangeIndex].children[updatedBrandIndex].children[
													updatedSubBrandIndex
												] = getChildObject(
													tableObject[iterator].children[updatedRangeIndex].children[updatedSubRangeIndex].children[updatedBrandIndex].children[
														updatedSubBrandIndex
													],
													packSizeValue,
													date,
													packSizes[packSizeValue],
													5
												);
											});
										}
									});
								}
							});
						}
					});
					tableObject[iterator].children[updatedRangeIndex].children = sortByPriceRanges(tableObject[iterator].children[updatedRangeIndex].children);
				}
			}
		});
		tableObject[iterator].children = sortByPriceRanges(tableObject[iterator].children);
	});
	return tableObject;
}

function formatCustomTableData(data) {
	let pepsiTableData: ISOMVendorDataObject[] = [];
	pepsiTableData = formatVendorLevelData(data.pepsi, 0, pepsiTableData);
	let competitorTableData: ISOMVendorDataObject[] = [];
	data.competitors.map((item, iterator) => {
		competitorTableData = formatVendorLevelData(item, iterator, competitorTableData);
	});
	return { pepsi: pepsiTableData, competitor: competitorTableData };
}

const ShareOfMarketPiano: React.FC<{ filters; defaultFilters }> = ({ filters, defaultFilters }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [skeleton, setSkeleton] = useState(true);
	const [isShowDataFlag, setIsShowDataFlag] = useState(true);
	const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);

	const [tableHeadings, setTableHeadings] = useState([]);
	const [customTableData, setCustomTableData] = useState<ISOMTableDataObject>({
		pepsi: [],
		competitor: [],
	});
	const [selectedResponse, setSelectedResponse] = useState({
		pepsi: null,
		competitors: null,
		pepsi_graph: null,
		competitors_graph: null,
	});
	const [graphKeys, setGraphKeys] = useState<string[]>([]);
	const [originalResponse, setOriginalResponse] = useState({});

	useEffect(() => {
		if (!_.isEmpty(selectedResponse.pepsi) || !_.isEmpty(selectedResponse.competitors)) {
			formatTableData(selectedResponse);
		}
		if (!_.isEmpty(selectedResponse.pepsi_graph) || !_.isEmpty(selectedResponse.competitors_graph)) {
			const pepsiKeys = getDistinctKeys(selectedResponse.pepsi_graph?.priceRanges);
			const competitorKeys = getDistinctKeys(selectedResponse.competitors_graph?.priceRanges);
			let distinctKey = Array.from(new Set([...pepsiKeys, ...competitorKeys])).filter((key) => key !== "date" && key !== "total");
			setGraphKeys(distinctKey);
		}
	}, [selectedResponse]);

	const getShareOfMarketPiano = (payload) => {
		setSkeleton(true);
		const requestPayload = { ...payload };
		if (requestPayload.periodView[0] === "na") {
			requestPayload.periodView = "null";
		} else {
			requestPayload.periodView = requestPayload.periodView[0].toString();
		}
		fetchPricePianoData(requestPayload)
			.then((response) => {
				if (response && response.data) {
					setOriginalResponse(response.data);
					if (
						response.data.yearly &&
						response.data.yearly.pepsi &&
						response.data.yearly.pepsi.priceRanges &&
						response.data.yearly.pepsi.priceRanges.length > 0
					) {
						setIsShowDataFlag(true);
					} else {
						setIsShowDataFlag(false);
					}
				} else {
					dispatch(toast("priceRangeGraph: " + response.error, true, 2000, "error"));
					setSkeleton(false);
				}
				dispatch(pricePianoDataLoader(false));
			})
			.catch((e) => {
				setSkeleton(false);
				dispatch(pricePianoDataLoader(false));
			});
	};
	useEffect(() => {
		if (filters && filters.geoLevel2) {
			setCustomTableData({
				pepsi: [],
				competitor: [],
			});
			setSelectedResponse({
				pepsi: null,
				competitors: null,
				competitors_graph: null,
				pepsi_graph: null,
			});
			setOriginalResponse({});
			setTimeout(() => {
				getShareOfMarketPiano(filters);
			}, 0);
		}
	}, [filters]);

	const formatTableData = (data) => {
		if (Object.keys(data).length > 0) {
			const customTableHeadings: any = [{ label: "Manufacturer", value: "Manufacturer" }];
			data.pepsi.priceRanges.map((item) => {
				customTableHeadings.push({ label: item.date, value: item.date });
			});
			setTableHeadings(customTableHeadings);
			const tableData = formatCustomTableData(data);
			setCustomTableData({
				pepsi: tableData.pepsi,
				competitor: tableData.competitor,
			});
		}
	};

	const onChangePeriodicity = (res) => {
		if (res && Object.keys(res).length > 0) {
			dispatch(loader(true));
			setTimeout(() => {
				if (res) setSelectedResponse(res);
			}, 100);
		}
		setGraphKeys([]);
		setSkeleton(false);
		dispatch(removeLabels("price-range-share-of-market-piano-1"));
		dispatch(removeLabels("price-range-share-of-market-piano-2"));
	};

	return (
		<Card className="m-b-20">
			<CardContent>
				<Dashboard
					title={null}
					showSkeleton={skeleton}
					chartRef={() => {}}
					showActionButton={true}
					actionButtons={{
						mmpw: false,
						screenshot: true,
						editMedia: false,
					}}
					id={null}
				>
					<Grid container style={{ width: "100%" }} className="m-b-20">
						<Grid item xs={12} sm={6} style={{ position: "relative" }}>
							<Grid
								style={{
									position: "absolute",
									bottom: 10,
									width: 100,
									zIndex: 2,
								}}
								className={classes.hide}
							>
								{originalResponse && Object.keys(originalResponse).length > 0 && (
									<IncrementDecrement
										data={originalResponse}
										selectedResponse={onChangePeriodicity}
										show={selectedResponse && selectedResponse.pepsi && Object.keys(selectedResponse.pepsi).length > 0}
										defaultOption={
											(localStorage.getItem("periodicity") && JSON.parse(localStorage.getItem("periodicity"))["price-range-share-of-market-piano"]?.item) ||
											null
										}
										id="price-range-share-of-market-piano"
									/>
								)}
							</Grid>
							<PriceRangeGraph
								data={selectedResponse.pepsi_graph}
								skeleton={skeleton}
								isShowDataFlag={isShowDataFlag}
								title="Share of Market at Price Point | <span style='color:#0093CD;'>PepsiCo</span>"
								tooltip="Share of Market at Price Point | PepsiCo"
								id="price-range-share-of-market-piano-1"
								type="pepsi"
								graphKeys={graphKeys}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<PriceRangeGraph
								data={selectedResponse.competitors_graph}
								skeleton={skeleton}
								isShowDataFlag={isShowDataFlag}
								title="Share of Market at Price Point | <span style='color:#0093CD;'>Competitor</span>"
								tooltip="Share of Market at Price Point | Competitor"
								id="price-range-share-of-market-piano-2"
								type="competitor"
								graphKeys={graphKeys}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} sm={12}>
							{skeleton ? (
								<Grid className="m-t-20">
									<Grid item xs={12} sm={12} md={12}>
										<CustomTable heading={dataObject.priceRangeTableHeading} value={dataObject.pricePianoTableSkeletonData} showSkeleton={skeleton} />
									</Grid>
								</Grid>
							) : isShowDataFlag && customTableData.pepsi.length > 0 ? (
								<>
									<Grid
										container
										className="m-b-20"
										style={{
											maxWidth: sidebarOpen ? `calc(100vw - 323px)` : "calc(100vw - 156px)",
										}}
									>
										<Grid item xs={12} sm={6} md={8} className="m-b-10">
											<Grid container>
												<Tooltip title={"PepsiCo Piano"}>
													<Box className="ellipsis">
														<Title className="ellipsis">
															<span dangerouslySetInnerHTML={{ __html: "PepsiCo Piano" }}></span>
														</Title>
													</Box>
												</Tooltip>
											</Grid>
										</Grid>
										<HierarchicalTable
											data={customTableData.pepsi}
											tableHeader={tableHeadings}
											showSkeleton={false}
											style={{ height: 500, overflow: "auto" }}
										/>
									</Grid>
								</>
							) : (
								<Box style={{ height: 200 }} display="flex" justifyContent="center" alignItems="center">
									<NoData>{messages.noData}</NoData>
								</Box>
							)}
						</Grid>
						<Grid item xs={12} sm={12}>
							{skeleton ? (
								<Grid className="m-t-20">
									<Grid item xs={12} sm={12} md={12}>
										<CustomTable heading={dataObject.priceRangeTableHeading} value={dataObject.pricePianoTableSkeletonData} showSkeleton={skeleton} />
									</Grid>
								</Grid>
							) : isShowDataFlag && customTableData.competitor.length > 0 ? (
								<>
									<Grid
										container
										className="m-b-20"
										style={{
											maxWidth: sidebarOpen ? `calc(100vw - 323px)` : "calc(100vw - 156px)",
										}}
									>
										<Grid item xs={12} sm={6} md={8} className="m-b-10">
											<Grid container>
												<Tooltip title={"Competitor Piano"}>
													<Box className="ellipsis">
														<Title className="ellipsis">
															<span dangerouslySetInnerHTML={{ __html: "Competitor Piano" }}></span>
														</Title>
													</Box>
												</Tooltip>
											</Grid>
										</Grid>
										<HierarchicalTable
											data={customTableData.competitor}
											tableHeader={tableHeadings}
											showSkeleton={false}
											style={{ height: 500, overflow: "auto" }}
										/>
									</Grid>
								</>
							) : (
								<Box style={{ height: 200 }} display="flex" justifyContent="center" alignItems="center">
									<NoData>{messages.noData}</NoData>
								</Box>
							)}
						</Grid>
					</Grid>
				</Dashboard>
			</CardContent>
		</Card>
	);
};

export default ShareOfMarketPiano;
