const measuresFilter = {
	somUnit: "$",
	somValueType: {
		title: "SOM",
		options: [
			{ id: "1", label: "Sales", value: "sales" },
			{ id: "2", label: "Volume", value: "volume" },
			{ id: "3", label: "Units", value: "units" },
		],
		defaultOption: { id: "1", value: "sales" },
		showRadioButton: true,
	},
	somValueBy: {
		title: "",
		options: [
			{ id: "1", label: "Category", value: "category" },
			{ id: "2", label: "Segment", value: "segment" },
		],
		defaultOption: { id: "1", value: "category" },
		showRadioButton: true,
	},
	somValueByCatOnly: {
		title: "",
		options: [
			{ id: "1", label: "Category", value: "category" },
			{ id: "2", label: "Segment", value: "segment", disabled: true },
		],
		defaultOption: { id: "1", value: "category" },
		showRadioButton: true,
	},
	apiFilter: {
		title: "API",
		options: [
			{ id: "1", label: "KGs (in `000s)", value: "kgs" },
			{ id: "2", label: "Units (in `000s)", value: "units" },
		],
		defaultOption: { id: "1", value: "kgs" },
		showRadioButton: true,
	},
	measuresFilterSOP: {
		title: "SOP",
		options: [
			{ id: "1", label: "Average of Period Selected", value: "avg" },
			{ id: "2", label: "Minimum of Period Selected", value: "min" },
			{ id: "3", label: "Maximum of Period Selected", value: "max" },
			{ id: "4", label: "Average of Last 3 Months", value: "avglast3month" },
		],
		defaultOption: { id: "1", value: "avg" },
		showRadioButton: true,
	},
};

const productFilterBy = {
	comparisonLevel: {
		title: "Comparison Level",
		options: [
			{ id: "1", label: "Brand Size", value: "brandsize" },
			{ id: "2", label: "Sub-brand Size", value: "subbrandsize" },
		],
		optionsNonMexico: [{ id: "1", label: "Brand Size", value: "brandsize" }],
		defaultOption: { id: "1", value: "brandsize" },
		showRadioButton: true,
	},
	priceLadderBy: {
		title: "Price Ladder By",
		options: [
			{ id: "1", label: "Suggested Retail Price", value: "suggestedretailprice" },
			{ id: "2", label: "Main Competitor", value: "maincompetitor" },
		],
		defaultOption: { id: "1", value: "suggestedretailprice" },
		showRadioButton: true,
	},
};
const productFilter = {
	category: {
		title: `Category <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		first: true,
		children: "segment",
		defaultSelectAll: false,
		display: true,
	},
	segment: {
		title: `Segment <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "brand",
		defaultSelectAll: false,
		display: true,
	},
	brand: {
		title: `Brand <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "packSize",
		defaultSelectAll: false,
		display: true,
	},
	packSize: {
		title: `Pack Size <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "permutationComputation",
		defaultSelectAll: false,
		display: true,
	},
	permutationComputation: {
		title: `Permutation`,
		options: [],
		placeholder: "Select",
		all: true,
		defaultSelectAll: false,
		multiple: true,
		display: true,
		last: true,
	},
};
const productFilterSubBrand = {
	category: {
		title: `Category <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		first: true,
		children: "segment",
		defaultSelectAll: false,
		display: true,
	},
	segment: {
		title: `Segment <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "brand",
		defaultSelectAll: false,
		display: true,
	},
	brand: {
		title: `Brand <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "subBrand",
		defaultSelectAll: false,
		display: true,
	},
	subBrand: {
		title: `Sub Brand <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "packSize",
		defaultSelectAll: false,
		display: true,
	},
	packSize: {
		title: `Pack Size <span style="color: #858c94;">(PepsiCo)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "permutationComputation",
		defaultSelectAll: false,
		display: true,
	},
	permutationComputation: {
		title: `Permutation`,
		options: [],
		placeholder: "Select",
		all: true,
		defaultSelectAll: false,
		multiple: true,
		display: true,
		last: true,
	},
};
const competitorFilter = {
	vendor: {
		title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		first: true,
		defaultSelectAll: false,
		children: "category",
		display: true,
	},
	category: {
		title: `Category <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "segment",
		defaultSelectAll: false,
		display: true,
	},
	segment: {
		title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		display: true,
		all: true,
		multiple: true,
		children: "brand",
		defaultSelectAll: false,
	},
	brand: {
		title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "packSize",
		defaultSelectAll: false,
		display: true,
	},
	packSize: {
		title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "permutationComputation",
		defaultSelectAll: false,
		display: true,
	},
	permutationComputation: {
		title: `Permutation`,
		options: [],
		placeholder: "Select",
		all: true,
		defaultSelectAll: false,
		multiple: true,
		display: true,
		last: true,
	},
};

const competitorFilterSubBrand = {
	vendor: {
		title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		first: true,
		defaultSelectAll: false,
		children: "category",
		display: true,
	},
	category: {
		title: `Category <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "segment",
		defaultSelectAll: false,
		display: true,
	},
	segment: {
		title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		display: true,
		all: true,
		multiple: true,
		children: "brand",
		defaultSelectAll: false,
	},
	brand: {
		title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "subBrand",
		defaultSelectAll: false,
		display: true,
	},
	subBrand: {
		title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "packSize",
		defaultSelectAll: false,
		display: true,
	},
	packSize: {
		title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		children: "permutationComputation",
		defaultSelectAll: false,
		display: true,
	},
	permutationComputation: {
		title: `Permutation`,
		options: [],
		placeholder: "Select",
		all: true,
		defaultSelectAll: false,
		multiple: true,
		display: true,
		last: true,
	},
};

export const periodView = {
	title: "Period View",
	options: [
		{ label: "NA", value: "na" },
		{ label: "Full Year", value: "FullYear" },
		{ label: "Year to Date", value: "YearToDate" },
		{ id: "3", label: "Rolling Year", value: "RollingYear" },
	],
	placeholder: "Select",
};

const currencySymbols = {
	ARGENTINA: "$",
	BRAZIL: "R$",
	CHILE: "$",
	COLOMBIA: "$",
	GUATEMALA: "$Q",
	MEXICO: "$",
};

const priceLadderProductFilterOrder = [
	{
		key: "category",
		order: 1,
	},
	{
		key: "segment",
		order: 2,
	},
	{
		key: "brand",
		order: 3,
	},
	{
		key: "packSize",
		order: 4,
	},
	{
		key: "permutationComputation",
		order: 5,
	},
];

const priceLadderCompetitorFilterOrder = [
	{
		key: "vendor",
		order: 1,
	},
	{
		key: "category",
		order: 2,
	},
	{
		key: "segment",
		order: 3,
	},
	{
		key: "brand",
		order: 4,
	},
	{
		key: "packSize",
		order: 5,
	},
	{
		key: "permutationComputation",
		order: 6,
	},
];

const priceLadderSubBrandProductFilterOrder = [
	{
		key: "category",
		order: 1,
	},
	{
		key: "segment",
		order: 2,
	},
	{
		key: "brand",
		order: 3,
	},
	{
		key: "subBrand",
		order: 4,
	},
	{
		key: "packSize",
		order: 5,
	},
	{
		key: "permutationComputation",
		order: 6,
	},
];

const priceLadderSubBrandCompetitorFilterOrder = [
	{
		key: "vendor",
		order: 1,
	},
	{
		key: "category",
		order: 2,
	},
	{
		key: "segment",
		order: 3,
	},
	{
		key: "brand",
		order: 4,
	},
	{
		key: "subBrand",
		order: 5,
	},
	{
		key: "packSize",
		order: 6,
	},
	{
		key: "permutationComputation",
		order: 7,
	},
];

const defaultPriceLadderFilterConfigurations = [
	{
		country: "MEXICO",
		geoLevel: ["REGION-CHAN"],
		geoLevel2: ["MX NORTH-DTS"],
		channel: ["DTS"],
		periodView: ["na"],
		somValueType: "sales",
		somValueBy: "category",
		apiFilter: "kgs",
		measuresFilterSOP: "avg",
		markup: "16.8",
		priceInterval: "1",
		taxes: "8",
		priceLadderBy: "suggestedretailprice",
		permutationOptions: [],
		comparisonLevel: "brandsize",
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO", "SAV-TORTILLA"],
			brand: ["DORITOS", "SABRITAS PC"],
			packSize: ["04X-CHICO", "09X-JUMBO", "11X-COMPARTE", "12X-FAMILIAR"],
			permutationComputation: [
				"DOR_TORTIL_04X-CHICO",
				"DOR_TORTIL_09X-JUMBO",
				"DOR_TORTIL_11X-COMPARTE",
				"DOR_TORTIL_12X-FAMILIAR",
				"SPC_POTATO_04X-CHICO",
				"SPC_POTATO_09X-JUMBO",
				"SPC_POTATO_11X-COMPARTE",
				"SPC_POTATO_12X-FAMILIAR",
			],
			vendor: ["PEPSICO"],
		},
		competitor: {
			vendor: ["BOKADOS", "BIMBO"],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO", "SAV-TORTILLA"],
			brand: ["BARCEL CHIPS", "TAKIS"],
			packSize: ["04X-CHICO", "12X-FAMILIAR"],
			permutationComputation: ["BCP_POTATO_04X-CHICO", "BCP_POTATO_12X-FAMILIAR", "TAK_TORTIL_04X-CHICO", "TAK_TORTIL_12X-FAMILIAR"],
		},
		sellInStartDate: "01/01/2023",
		sellInEndDate: "06/30/2023",
		sellOutStartDate: "07/01/2023",
		sellOutEndDate: "09/30/2023",
	},
	{
		country: "GUATEMALA",
		geoLevel: ["COUNTRY-TTL"],
		geoLevel2: ["TTL GUATEMALA-ALL"],
		channel: ["ALL OUTLET"],
		periodView: ["na"],
		somValueType: "sales",
		somValueBy: "category",
		apiFilter: "kgs",
		measuresFilterSOP: "avg",
		markup: "16.8",
		taxes: "8",
		priceInterval: "1",
		priceLadderBy: "suggestedretailprice",
		permutationOptions: [],
		comparisonLevel: "brandsize",
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: [
				"FILLERS POTATO",
				"HULA HOOPS REGULAR",
				"LAYS",
				"LAYS BAKED",
				"LAYS KETTLE",
				"LAYS MAXX",
				"LAYS STAX",
				"OTHER PEPSICO POTATO SUBBRAND",
				"RECETA CRUJIENTE SAL",
				"RUFFLES REGULAR",
				"SABRITAS REGULAR",
			],
			packSize: ["01X-SS XSMALL", "02X-SS SMALL", "03X-SS MEDIUM", "04X-SS LARGE", "05X-SS XLARGE", "06X-SS XXLARGE"],
			permutationComputation: [
				"FLR POT_POTATO_01X-SS XSMALL",
				"FLR POT_POTATO_04X-SS LARGE",
				"HOP REG_POTATO_01X-SS XSMALL",
				"HOP REG_POTATO_04X-SS LARGE",
				"LAY REG_POTATO_01X-SS XSMALL",
				"LAY REG_POTATO_02X-SS SMALL",
				"LAY REG_POTATO_03X-SS MEDIUM",
				"LAY REG_POTATO_04X-SS LARGE",
				"LAY REG_POTATO_05X-SS XLARGE",
				"LAY REG_POTATO_06X-SS XXLARGE",
				"LAY BKD_POTATO_02X-SS SMALL",
				"LAY KET_POTATO_03X-SS MEDIUM",
				"LAY KET_POTATO_04X-SS LARGE",
				"LAY MAX_POTATO_01X-SS XSMALL",
				"LAY MAX_POTATO_02X-SS SMALL",
				"LAY MAX_POTATO_03X-SS MEDIUM",
				"LAY MAX_POTATO_04X-SS LARGE",
				"LAY MAX_POTATO_05X-SS XLARGE",
				"LAY MAX_POTATO_06X-SS XXLARGE",
				"LAY STX_POTATO_04X-SS LARGE",
				"LAY STX_POTATO_05X-SS XLARGE",
				"OTP POT_POTATO_03X-SS MEDIUM",
				"REC SAL_POTATO_04X-SS LARGE",
				"RUF REG_POTATO_02X-SS SMALL",
				"RUF REG_POTATO_03X-SS MEDIUM",
				"SBA REG_POTATO_02X-SS SMALL",
			],
			vendor: ["PEPSICO"],
		},
		competitor: {
			vendor: ["ALIMENTOS DIANA", "CENTRAL DE ALIMENTOS"],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: ["DIANA CRAKS SAVORY", "SENORIAL RUFITAS"],
			packSize: ["01X-SS XSMALL", "02X-SS SMALL", "03X-SS MEDIUM", "04X-SS LARGE"],
			permutationComputation: [
				"DAN CRKS_POTATO_01X-SS XSMALL",
				"DAN CRKS_POTATO_03X-SS MEDIUM",
				"SRL RUF_POTATO_01X-SS XSMALL",
				"SRL RUF_POTATO_02X-SS SMALL",
				"SRL RUF_POTATO_03X-SS MEDIUM",
				"SRL RUF_POTATO_04X-SS LARGE",
			],
		},
		sellInStartDate: "01/01/2024",
		sellInEndDate: "02/02/2024",
		sellOutStartDate: "01/01/2024",
		sellOutEndDate: "02/02/2024",
	},
	{
		country: "COLOMBIA",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL COLOMBIA-DTS"],
		channel: ["DTS"],
		periodView: ["na"],
		somValueType: "sales",
		somValueBy: "category",
		apiFilter: "kgs",
		measuresFilterSOP: "avg",
		markup: "16.8",
		taxes: "8",
		priceInterval: "1",
		priceLadderBy: "maincompetitor",
		permutationOptions: [
			{
				label: "RAMO CASERITAS 01X-MINI",
				value: "RMO CAS_POTATO_01X-MINI",
			},
			{
				label: "RAMO CASERITAS 04X-SMALL",
				value: "RMO CAS_POTATO_04X-SMALL",
			},
			{
				label: "RAMO CASERITAS 11X-BIG",
				value: "RMO CAS_POTATO_11X-BIG",
			},
			{
				label: "SUPER RICAS POTATO 01X-MINI",
				value: "SPI POT_POTATO_01X-MINI",
			},
			{
				label: "SUPER RICAS POTATO 04X-SMALL",
				value: "SPI POT_POTATO_04X-SMALL",
			},
			{
				label: "SUPER RICAS POTATO 11X-BIG",
				value: "SPI POT_POTATO_11X-BIG",
			},
			{
				label: "SUPER RICAS POTATO 12X-EXTRA BIG",
				value: "SPI POT_POTATO_12X-EXTRA BIG",
			},
			{
				label: "YUPI POTATO 04X-SMALL",
				value: "YUP POT_POTATO_04X-SMALL",
			},
			{
				label: "YUPI POTATO 11X-BIG",
				value: "YUP POT_POTATO_11X-BIG",
			},
			{
				label: "YUPI POTATO 01X-MINI",
				value: "YUP POT_POTATO_01X-MINI",
			},
			{
				label: "YUPI POTATO 07X-MEDIUM",
				value: "YUP POT_POTATO_07X-MEDIUM",
			},
			{
				label: "YUPI POTATO 12X-EXTRA BIG",
				value: "YUP POT_POTATO_12X-EXTRA BIG",
			},
			{
				label: "YUPI RIZADAS 01X-MINI",
				value: "YUP RZD_POTATO_01X-MINI",
			},
			{
				label: "YUPI RIZADAS 04X-SMALL",
				value: "YUP RZD_POTATO_04X-SMALL",
			},
			{
				label: "YUPI RIZADAS 11X-BIG",
				value: "YUP RZD_POTATO_11X-BIG",
			},
			{
				label: "YUPI RIZADAS 12X-EXTRA BIG",
				value: "YUP RZD_POTATO_12X-EXTRA BIG",
			},
		],
		comparisonLevel: "brandsize",
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: ["MARGARITA FOSFORITOS", "MARGARITA KETTEL", "MARGARITA REGULAR", "MARGARITA SENSATIONS", "MARGARITA WAVY", "STAX REGULAR"],
			packSize: ["01X-MINI", "04X-SMALL", "07X-MEDIUM", "11X-BIG", "12X-EXTRA BIG"],
			permutationComputation: [
				"MAR FOS_POTATO_04X-SMALL",
				"MAR FOS_POTATO_11X-BIG",
				"MAR KTL_POTATO_04X-SMALL",
				"MAR KTL_POTATO_11X-BIG",
				"MAR KTL_POTATO_12X-EXTRA BIG",
				"MAR REG_POTATO_01X-MINI",
				"MAR REG_POTATO_04X-SMALL",
				"MAR REG_POTATO_07X-MEDIUM",
				"MAR REG_POTATO_11X-BIG",
				"MAR REG_POTATO_12X-EXTRA BIG",
				"MAR SEN_POTATO_11X-BIG",
				"MAR SEN_POTATO_12X-EXTRA BIG",
				"MAR WAV_POTATO_04X-SMALL",
				"MAR WAV_POTATO_07X-MEDIUM",
				"MAR WAV_POTATO_11X-BIG",
				"STX REG_POTATO_11X-BIG",
			],
			vendor: ["PEPSICO"],
		},
		competitor: {
			vendor: ["COMESTIBLES RICOS", "PRODUCTOS RAMO", "YUPI"],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: ["RAMO CASERITAS", "SUPER RICAS POTATO", "YUPI POTATO", "YUPI RIZADAS"],
			packSize: ["01X-MINI", "04X-SMALL", "07X-MEDIUM", "11X-BIG", "12X-EXTRA BIG"],
			permutationComputation: [
				"RMO CAS_POTATO_01X-MINI",
				"RMO CAS_POTATO_04X-SMALL",
				"RMO CAS_POTATO_11X-BIG",
				"SPI POT_POTATO_01X-MINI",
				"SPI POT_POTATO_04X-SMALL",
				"SPI POT_POTATO_11X-BIG",
				"SPI POT_POTATO_12X-EXTRA BIG",
				"YUP POT_POTATO_01X-MINI",
				"YUP POT_POTATO_04X-SMALL",
				"YUP POT_POTATO_07X-MEDIUM",
				"YUP POT_POTATO_11X-BIG",
				"YUP POT_POTATO_12X-EXTRA BIG",
				"YUP RZD_POTATO_01X-MINI",
				"YUP RZD_POTATO_04X-SMALL",
				"YUP RZD_POTATO_11X-BIG",
				"YUP RZD_POTATO_12X-EXTRA BIG",
			],
		},
		sellInStartDate: "06/01/2023",
		sellInEndDate: "12/31/2023",
		sellOutStartDate: "06/01/2023",
		sellOutEndDate: "12/31/2023",
	},
	{
		country: "CHILE",
		geoLevel: ["COUNTRY-SUBCHAN"],
		geoLevel2: ["TTL CHILE-SMKT"],
		channel: ["OT"],
		periodView: ["na"],
		somValueType: "sales",
		somValueBy: "category",
		apiFilter: "kgs",
		measuresFilterSOP: "avg",
		markup: "16.8",
		taxes: "8",
		priceInterval: "1",
		priceLadderBy: "maincompetitor",
		permutationOptions: [
			{
				label: "KRYZPO REGULAR 01X-SS XSMALL",
				value: "KRY REG_POTATO_01X-SS XSMALL",
			},
			{
				label: "KRYZPO REGULAR 02P-MULTIPK",
				value: "KRY REG_POTATO_02P-MULTIPK",
			},
			{
				label: "KRYZPO REGULAR 02X-JR LINE",
				value: "KRY REG_POTATO_02X-JR LINE",
			},
			{
				label: "KRYZPO REGULAR 04P-MULTIPK",
				value: "KRY REG_POTATO_04P-MULTIPK",
			},
			{
				label: "KRYZPO REGULAR 04X-SS LARGE",
				value: "KRY REG_POTATO_04X-SS LARGE",
			},
			{
				label: "KRYZPO REGULAR 06P-MULTIPK",
				value: "KRY REG_POTATO_06P-MULTIPK",
			},
			{
				label: "KRYZPO REGULAR 07X-MAX",
				value: "KRY REG_POTATO_07X-MAX",
			},
			{
				label: "KRYZPO REGULAR 07X-MEDIUM",
				value: "KRY REG_POTATO_07X-MEDIUM",
			},
			{
				label: "KRYZPO REGULAR 09X-JUMBO",
				value: "KRY REG_POTATO_09X-JUMBO",
			},
			{
				label: "KRYZPO REGULAR 10X-MANGA",
				value: "KRY REG_POTATO_10X-MANGA",
			},
			{
				label: "KRYZPO REGULAR 12X-EXTRA BIG",
				value: "KRY REG_POTATO_12X-EXTRA BIG",
			},
			{
				label: "PANCHO VILLA PAPAS 07X-MEDIUM",
				value: "PAV PAP_POTATO_07X-MEDIUM",
			},
			{
				label: "PRINGLES REGULAR 04X-SS LARGE",
				value: "PRI REG_POTATO_04X-SS LARGE",
			},
			{
				label: "PRINGLES REGULAR 07X-MAX",
				value: "PRI REG_POTATO_07X-MAX",
			},
			{
				label: "PRINGLES REGULAR 07X-MEDIUM",
				value: "PRI REG_POTATO_07X-MEDIUM",
			},
		],
		comparisonLevel: "brandsize",
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: [
				"EVERCRISP POTATO",
				"LAYS ARTESANAS",
				"LAYS CLASSIC",
				"LAYS FLAVOURS",
				"LAYS HILO",
				"LAYS JUNTEMONOS",
				"LAYS MAXX",
				"LAYS MEDITERRANEAS",
				"LAYS SENSATIONS",
				"LAYS WAVY",
				"MOMS CASERAS",
				"RAICES CHILENAS",
				"RUFFLES ORIGINAL",
				"STAX REGULAR",
			],
			packSize: [
				"01X-MINI",
				"01X-SS XSMALL",
				"02P-MULTIPK",
				"02X-JR LINE",
				"04X-SS LARGE",
				"05P-MULTIPK",
				"07X-MAX",
				"07X-MEDIUM",
				"09X-GRANDE",
				"09X-JUMBO",
				"10X-MANGA",
				"11X-BIG",
				"12X-EXTRA BIG",
				"20P-MULTIPK",
			],
			permutationComputation: [
				"EVE POT_POTATO_02P-MULTIPK",
				"EVE POT_POTATO_09X-GRANDE",
				"LAY ART_POTATO_01X-SS XSMALL",
				"LAY ART_POTATO_04X-SS LARGE",
				"LAY ART_POTATO_07X-MAX",
				"LAY ART_POTATO_07X-MEDIUM",
				"LAY ART_POTATO_09X-JUMBO",
				"LAY CLA_POTATO_01X-SS XSMALL",
				"LAY CLA_POTATO_02X-JR LINE",
				"LAY CLA_POTATO_04X-SS LARGE",
				"LAY CLA_POTATO_07X-MAX",
				"LAY CLA_POTATO_07X-MEDIUM",
				"LAY CLA_POTATO_09X-GRANDE",
				"LAY CLA_POTATO_10X-MANGA",
				"LAY CLA_POTATO_11X-BIG",
				"LAY FLV_POTATO_01X-SS XSMALL",
				"LAY FLV_POTATO_02X-JR LINE",
				"LAY FLV_POTATO_04X-SS LARGE",
				"LAY FLV_POTATO_07X-MAX",
				"LAY FLV_POTATO_07X-MEDIUM",
				"LAY FLV_POTATO_09X-GRANDE",
				"LAY FLV_POTATO_11X-BIG",
				"LAY HIL_POTATO_09X-JUMBO",
				"LYS JNT_POTATO_05P-MULTIPK",
				"LAY MAX_POTATO_01X-SS XSMALL",
				"LAY MAX_POTATO_07X-MAX",
				"LAY MAX_POTATO_07X-MEDIUM",
				"LAY MED_POTATO_01X-SS XSMALL",
				"LAY MED_POTATO_02X-JR LINE",
				"LAY MED_POTATO_07X-MAX",
				"LAY MED_POTATO_07X-MEDIUM",
				"LAY MED_POTATO_09X-JUMBO",
				"LAY MED_POTATO_10X-MANGA",
				"LAY MED_POTATO_11X-BIG",
				"LAY SEN_POTATO_01X-SS XSMALL",
				"LAY SEN_POTATO_07X-MAX",
				"LAY SEN_POTATO_09X-GRANDE",
				"LAY WAV_POTATO_01X-MINI",
				"LAY WAV_POTATO_01X-SS XSMALL",
				"LAY WAV_POTATO_02P-MULTIPK",
				"LAY WAV_POTATO_02X-JR LINE",
				"LAY WAV_POTATO_04X-SS LARGE",
				"LAY WAV_POTATO_07X-MAX",
				"LAY WAV_POTATO_07X-MEDIUM",
				"LAY WAV_POTATO_09X-GRANDE",
				"LAY WAV_POTATO_09X-JUMBO",
				"LAY WAV_POTATO_10X-MANGA",
				"LAY WAV_POTATO_11X-BIG",
				"LAY WAV_POTATO_12X-EXTRA BIG",
				"LAY WAV_POTATO_20P-MULTIPK",
				"MOM CAS_POTATO_01X-MINI",
				"MOM CAS_POTATO_04X-SS LARGE",
				"MOM CAS_POTATO_07X-MAX",
				"MOM CAS_POTATO_07X-MEDIUM",
				"MOM CAS_POTATO_09X-GRANDE",
				"MOM CAS_POTATO_09X-JUMBO",
				"MOM CAS_POTATO_10X-MANGA",
				"MOM CAS_POTATO_11X-BIG",
				"MOM CAS_POTATO_12X-EXTRA BIG",
				"RAC CHI_POTATO_07X-MAX",
				"RAC CHI_POTATO_09X-JUMBO",
				"RUF ORI_POTATO_07X-MEDIUM",
				"STX REG_POTATO_01X-SS XSMALL",
				"STX REG_POTATO_02P-MULTIPK",
				"STX REG_POTATO_07X-MEDIUM",
				"STX REG_POTATO_10X-MANGA",
				"STX REG_POTATO_20P-MULTIPK",
			],
			vendor: ["PEPSICO"],
		},
		competitor: {
			vendor: ["COMERCIAL BUENA MESA", "GRUPO NUTRESA", "KELLOGGS", "PANCHO VILLA"],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: ["KRYZPO REGULAR", "PANCHO VILLA PAPAS", "PRINGLES REGULAR"],
			packSize: [
				"01X-SS XSMALL",
				"02P-MULTIPK",
				"02X-JR LINE",
				"04P-MULTIPK",
				"04X-SS LARGE",
				"06P-MULTIPK",
				"07X-MAX",
				"07X-MEDIUM",
				"09X-JUMBO",
				"10X-MANGA",
				"12X-EXTRA BIG",
			],
			permutationComputation: [
				"KRY REG_POTATO_01X-SS XSMALL",
				"KRY REG_POTATO_02P-MULTIPK",
				"KRY REG_POTATO_02X-JR LINE",
				"KRY REG_POTATO_04P-MULTIPK",
				"KRY REG_POTATO_04X-SS LARGE",
				"KRY REG_POTATO_06P-MULTIPK",
				"KRY REG_POTATO_07X-MAX",
				"KRY REG_POTATO_07X-MEDIUM",
				"KRY REG_POTATO_09X-JUMBO",
				"KRY REG_POTATO_10X-MANGA",
				"KRY REG_POTATO_12X-EXTRA BIG",
				"PAV PAP_POTATO_07X-MEDIUM",
				"PRI REG_POTATO_04X-SS LARGE",
				"PRI REG_POTATO_07X-MAX",
				"PRI REG_POTATO_07X-MEDIUM",
			],
		},
		sellInStartDate: "06/01/2023",
		sellInEndDate: "12/31/2023",
		sellOutStartDate: "06/01/2023",
		sellOutEndDate: "12/31/2023",
	},
	{
		country: "ARGENTINA",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL ARGENTINA-OT"],
		channel: ["OT"],
		periodView: ["na"],
		somValueType: "sales",
		somValueBy: "category",
		apiFilter: "kgs",
		measuresFilterSOP: "avg",
		markup: "16.8",
		taxes: "8",
		priceInterval: "1",
		priceLadderBy: "suggestedretailprice",
		permutationOptions: [],
		comparisonLevel: "brandsize",
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: [
				"BUN PAPAS",
				"DORITOS QUESO",
				"LAYS ACANALADAS",
				"LAYS BAKED",
				"LAYS CLASSIC",
				"LAYS FLAVOURS",
				"LAYS GOURMET",
				"LAYS OTHER SUBBRAND",
				"LAYS PAY",
				"LAYS SIN SAL",
				"LAYS STAX",
				"PEHUAMAR ACANALADAS",
				"PEHUAMAR CLASICAS",
				"PEP PALITOS",
			],
			packSize: [
				"01X-MINI",
				"01X-SS XSMALL",
				"02X-JR LINE",
				"04X-SS LARGE",
				"07X-MAX",
				"07X-MEDIUM",
				"09X-GRANDE",
				"09X-JUMBO",
				"11X-BIG",
				"12X-EXTRA BIG",
				"15X-MEGA",
				"16X-SUPER SIZE",
			],
			permutationComputation: [
				"BUN PAP_POTATO_04X-SS LARGE",
				"BUN PAP_POTATO_07X-MAX",
				"BUN PAP_POTATO_07X-MEDIUM",
				"BUN PAP_POTATO_09X-JUMBO",
				"DOR QUE_POTATO_07X-MEDIUM",
				"LAY ACA_POTATO_01X-SS XSMALL",
				"LAY ACA_POTATO_02X-JR LINE",
				"LAY ACA_POTATO_07X-MAX",
				"LAY ACA_POTATO_07X-MEDIUM",
				"LAY ACA_POTATO_09X-GRANDE",
				"LAY BKD_POTATO_01X-SS XSMALL",
				"LAY BKD_POTATO_02X-JR LINE",
				"LAY BKD_POTATO_07X-MEDIUM",
				"LAY CLA_POTATO_01X-MINI",
				"LAY CLA_POTATO_01X-SS XSMALL",
				"LAY CLA_POTATO_02X-JR LINE",
				"LAY CLA_POTATO_04X-SS LARGE",
				"LAY CLA_POTATO_07X-MAX",
				"LAY CLA_POTATO_07X-MEDIUM",
				"LAY CLA_POTATO_09X-GRANDE",
				"LAY CLA_POTATO_09X-JUMBO",
				"LAY CLA_POTATO_11X-BIG",
				"LAY CLA_POTATO_12X-EXTRA BIG",
				"LAY FLV_POTATO_01X-SS XSMALL",
				"LAY FLV_POTATO_02X-JR LINE",
				"LAY FLV_POTATO_04X-SS LARGE",
				"LAY FLV_POTATO_07X-MAX",
				"LAY FLV_POTATO_07X-MEDIUM",
				"LAY FLV_POTATO_11X-BIG",
				"LAY GOU_POTATO_01X-SS XSMALL",
				"LAY GOU_POTATO_02X-JR LINE",
				"LAY GOU_POTATO_04X-SS LARGE",
				"LAY GOU_POTATO_07X-MEDIUM",
				"LAY OTH_POTATO_01X-MINI",
				"LAY OTH_POTATO_01X-SS XSMALL",
				"LAY OTH_POTATO_02X-JR LINE",
				"LAY OTH_POTATO_07X-MAX",
				"LAY OTH_POTATO_07X-MEDIUM",
				"LAY OTH_POTATO_11X-BIG",
				"LAY OTH_POTATO_15X-MEGA",
				"LAY PAY_POTATO_01X-SS XSMALL",
				"LAY PAY_POTATO_07X-MEDIUM",
				"LAY SSL_POTATO_04X-SS LARGE",
				"LAY STX_POTATO_01X-SS XSMALL",
				"LAY STX_POTATO_07X-MAX",
				"LAY STX_POTATO_07X-MEDIUM",
				"PEH ACA_POTATO_01X-SS XSMALL",
				"PEH ACA_POTATO_02X-JR LINE",
				"PEH ACA_POTATO_04X-SS LARGE",
				"PEH ACA_POTATO_07X-MAX",
				"PEH ACA_POTATO_07X-MEDIUM",
				"PEH ACA_POTATO_09X-GRANDE",
				"PEH ACA_POTATO_09X-JUMBO",
				"PEH ACA_POTATO_11X-BIG",
				"PEH ACA_POTATO_12X-EXTRA BIG",
				"PEH CLA_POTATO_01X-SS XSMALL",
				"PEH CLA_POTATO_02X-JR LINE",
				"PEH CLA_POTATO_04X-SS LARGE",
				"PEH CLA_POTATO_07X-MAX",
				"PEH CLA_POTATO_07X-MEDIUM",
				"PEH CLA_POTATO_09X-GRANDE",
				"PEH CLA_POTATO_09X-JUMBO",
				"PEH CLA_POTATO_11X-BIG",
				"PEH CLA_POTATO_12X-EXTRA BIG",
				"PEH CLA_POTATO_15X-MEGA",
				"PEH CLA_POTATO_16X-SUPER SIZE",
				"PEP PAL_POTATO_07X-MEDIUM",
			],
			vendor: ["PEPSICO"],
		},
		competitor: {
			vendor: ["KELLOGGS"],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: ["PRINGLES REGULAR"],
			packSize: ["01X-SS XSMALL", "02X-JR LINE", "07X-MAX", "07X-MEDIUM"],
			permutationComputation: ["PRI REG_POTATO_01X-SS XSMALL", "PRI REG_POTATO_02X-JR LINE", "PRI REG_POTATO_07X-MAX", "PRI REG_POTATO_07X-MEDIUM"],
		},
		sellInStartDate: "01/01/2024",
		sellInEndDate: "02/02/2024",
		sellOutStartDate: "01/01/2024",
		sellOutEndDate: "02/02/2024",
	},
	{
		country: "BRAZIL",
		geoLevel: ["COUNTRY-TTL"],
		geoLevel2: ["TTL BRAZIL-ALL"],
		channel: ["ALL OUTLET"],
		periodView: ["na"],
		somValueType: "sales",
		somValueBy: "category",
		apiFilter: "kgs",
		measuresFilterSOP: "avg",
		markup: "16.8",
		taxes: "8",
		priceInterval: "1",
		priceLadderBy: "maincompetitor",
		permutationOptions: [
			{
				label: "PRINGLES PRA GALERA ENTR. (101G-120G)",
				value: "PRN REG_POTATO_PRA GALERA ENTR. (101G-120G)",
			},
			{
				label: "PRINGLES PRA GALERA REGULAR",
				value: "PRN REG_POTATO_PRA GALERA REGULAR",
			},
			{
				label: "PRINGLES PRA GENTE",
				value: "PRN REG_POTATO_PRA GENTE",
			},
			{
				label: "PRINGLES PRA MIM",
				value: "PRN REG_POTATO_PRA MIM",
			},
		],
		comparisonLevel: "brandsize",
		pepsi: {
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: ["RUFFLES REGULAR", "LAYS REGULAR", "STAX REGULAR"],
			packSize: [
				"04X-SS LARGE",
				"07X-MEDIUM",
				"11X-BIG",
				"14X-FAMILY",
				"15P-MULTIPK",
				"HEAVY USER/PRA FESTA",
				"PRA GALERA ENTR. (101G-120G)",
				"PRA GALERA REGULAR",
				"PRA GENTE",
				"PRA J",
				"PRA MIM",
			],
			permutationComputation: [
				"LAY REG_POTATO_04X-SS LARGE",
				"LAY REG_POTATO_07X-MEDIUM",
				"LAY REG_POTATO_11X-BIG",
				"LAY REG_POTATO_14X-FAMILY",
				"LAY REG_POTATO_PRA GALERA ENTR. (101G-120G)",
				"LAY REG_POTATO_PRA GALERA REGULAR",
				"LAY REG_POTATO_PRA GENTE",
				"LAY REG_POTATO_PRA J",
				"LAY REG_POTATO_PRA MIM",
				"RUF REG_POTATO_15P-MULTIPK",
				"RUF REG_POTATO_HEAVY USER/PRA FESTA",
				"RUF REG_POTATO_PRA GALERA ENTR. (101G-120G)",
				"RUF REG_POTATO_PRA GALERA REGULAR",
				"RUF REG_POTATO_PRA GENTE",
				"RUF REG_POTATO_PRA J",
				"RUF REG_POTATO_PRA MIM",
				"STX REG_POTATO_PRA GALERA REGULAR",
			],
			vendor: ["PEPSICO"],
		},
		competitor: {
			vendor: ["KELLOGGS"],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: ["PRINGLES"],
			packSize: ["PRA GALERA ENTR. (101G-120G)", "PRA GALERA REGULAR", "PRA GENTE", "PRA MIM"],
			permutationComputation: [
				"PRN REG_POTATO_PRA GALERA ENTR. (101G-120G)",
				"PRN REG_POTATO_PRA GALERA REGULAR",
				"PRN REG_POTATO_PRA GENTE",
				"PRN REG_POTATO_PRA MIM",
			],
		},
		sellInStartDate: "06/01/2023",
		sellInEndDate: "12/31/2023",
		sellOutStartDate: "06/01/2023",
		sellOutEndDate: "12/31/2023",
	},
];

const dataObject = {
	productFilter,
	productFilterSubBrand,
	productFilterBy,
	competitorFilter,
	competitorFilterSubBrand,
	measuresFilter,
	currencySymbols,
	priceLadderProductFilterOrder,
	priceLadderCompetitorFilterOrder,
	priceLadderSubBrandProductFilterOrder,
	priceLadderSubBrandCompetitorFilterOrder,
	defaultPriceLadderFilterConfigurations,
};
export default dataObject;
