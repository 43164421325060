import React, { FC, useEffect, useState } from "react";
import { Avatar, Box, Grid, IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useDebounce } from "use-debounce";
import { PrimaryBtn, Title } from "../../../styles/Common.Styled";
import { deleteUser, getUserList } from "../../User/service";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { USER_CREATE } from "../../../router/CONSTANTS";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../../store/actions/toast.action";
import { stringAvatar } from "../../../util/helper";
import { loader } from "../../../store/actions/common.action";
import PageContainer from "../../../components/PageContainer";
import { pageNames } from "../../../mocks/common";
import { fetchProfileImage } from "../../../store/actions/user.action";
import { IUserList } from "../../../types/common";
import { clearServerCache } from "../service";
import SearchBar from "../../../components/SearchBar/SearchBar";

const OFFSET = 30;

const UserList: FC = () => {
	const dispatch = useDispatch();
	const userDetail = useSelector((state: any) => state.User.data);
	const [data, setData] = useState<IUserList[]>([]);
	const [paginatedData, setPaginatedData] = useState<IUserList[]>([]);
	const [recordCount, setRecordCount] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [searchValue] = useDebounce(searchQuery, 1000); // it will wait for 1 second after user enters anything in search box before updating searchValue
	const [pageNo, setPageNo] = useState(1);
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const [queryPageNo, setQueryPageNo] = useState(parseInt(query.get("pageNo") || ""));
	const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
	const noRowsOverlay = () => (
		<Stack height="100%" alignItems="center" justifyContent="center">
			No users available
		</Stack>
	);

	useEffect(() => {
		if (data.length === 0) {
			getUserListData();
		}
	}, []);

	useEffect(() => {
		if (data.length > 0) {
			let userListData = data;
			if (searchValue !== "") {
				userListData = userListData.filter((userProperty: any) => {
					if (
						userProperty.user.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
						userProperty.user.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
						userProperty.user.emailAddress.toLowerCase().includes(searchValue.toLowerCase()) ||
						userProperty.role.toLowerCase().includes(searchValue.toLowerCase()) ||
						userProperty.defaultCategory.toLowerCase().includes(searchValue.toLowerCase()) ||
						userProperty.defaultCountry.toLowerCase().includes(searchValue.toLowerCase())
					) {
						return userProperty;
					}
				});
			}
			setRecordCount(userListData.length);
			userListData = userListData.slice((pageNo - 1) * OFFSET, pageNo * OFFSET);
			setPaginatedData(userListData);
		}
	}, [data, pageNo, searchValue]);

	const getUserListData = () => {
		dispatch(loader(true));
		getUserList()
			.then(async (res: any) => {
				if (_.isArray(res.data.users)) {
					setRecordCount(res.data.totalUsers);
					if (queryPageNo) {
						if (queryPageNo !== pageNo) {
							if (queryPageNo * OFFSET <= res.data.totalUsers) {
								setPageNo(queryPageNo);
							} else {
								const newPageNumber = Math.ceil(res.data.totalUsers / OFFSET);
								setPageNo(newPageNumber);
								setQueryPageNo(newPageNumber);
								window.history.replaceState(null, "", `?pageNo=${newPageNumber}`);
							}
						} else if (queryPageNo * OFFSET > res.data.totalUsers) {
							const newPageNumber = Math.ceil(res.data.totalUsers / OFFSET);
							setPageNo(newPageNumber);
							setQueryPageNo(newPageNumber);
							window.history.replaceState(null, "", `?pageNo=${newPageNumber}`);
						}
					} else {
						setQueryPageNo(pageNo);
						window.history.replaceState(null, "", `?pageNo=${pageNo}`);
					}
					const payload: IUserList[] = [];
					await Promise.all(
						res.data.users.map(async (item) => {
							payload.push({
								id: item.id,
								user: {
									firstName: item.firstName,
									lastName: item.lastName,
									emailAddress: item.emailAddress,
									imageUrl: item.imageUrl,
									profile: item.imageUrl ? await fetchProfileImage(item.imageUrl) : null,
								},
								defaultCountry: item.defaultCountry,
								defaultCategory: item.defaultCategory,
								role: item.role.charAt(0).toUpperCase() + item.role.slice(1),
							});
						})
					);
					setData(payload);
				}
				dispatch(loader(false));
			})
			.catch((e) => {
				if (pageNo - 1 >= 0) {
					setQueryPageNo(pageNo - 1);
					window.history.replaceState(null, "", `?pageNo=${pageNo - 1}`);
					getUserListData();
				}
			});
	};

	const history = useHistory();
	const onEdit = (id) => {
		history.push(`user/${id}`);
	};

	const onDelete = (id) => {
		dispatch(loader(true));
		deleteUser(id, userDetail.id)
			.then((res) => {
				dispatch(toast("User deleted successfully!", true, 2000, "success"));
				getUserListData();
				dispatch(loader(false));
			})
			.catch((e) => {
				dispatch(loader(false));
			});
	};

	const columns: GridColDef[] = [
		{
			field: "user",
			headerName: "User",
			renderCell: (params: any) => {
				return (
					<Box className="p-10">
						<Grid
							container
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Grid item className="m-r-10">
								<Box>
									<Avatar
										alt={params.value.firstName + params.value.lastName}
										src={params.value.profile}
										{...stringAvatar(`${params.value.firstName.trim()} ${params.value.lastName.trim()}`)}
										sx={{
											width: 50,
											height: 50,
											background: theme.darkmode ? "#005393" : "",
											color: theme.darkmode ? "white" : "",
										}}
									/>
								</Box>
							</Grid>
							<Grid item>
								<Box>
									{params.value.firstName} {params.value.lastName}
								</Box>
								<Box>{params.value.emailAddress}</Box>
							</Grid>
						</Grid>
					</Box>
				);
			},
			flex: 30,
			sortable: false,
		},
		{
			field: "defaultCountry",
			headerName: "Default Country",
			flex: 20,
			sortable: false,
		},
		{
			field: "defaultCategory",
			headerName: "Default Category",
			flex: 30,
			sortable: false,
		},
		{ field: "role", headerName: "Roles", flex: 10, sortable: false },
		{
			field: "action",
			headerName: "Actions",
			renderCell: (params) => (
				<div>
					<IconButton component="span" onClick={() => onEdit(params.id)}>
						<EditIcon />
					</IconButton>
					<IconButton component="span" onClick={() => onDelete(params.id)}>
						<DeleteIcon />
					</IconButton>
				</div>
			),
			sortable: false,
		},
	];

	const clearCache = () => {
		clearServerCache()
			.then((res) => {
				if (res.status === 200) {
					dispatch(toast("Server cache cleared successfully!", true, 2000, "success"));
				} else {
					dispatch(toast("Failed to clear server cache!", true, 2000, "error"));
				}
			})
			.finally(() => {
				getUserListData();
			});
	};

	return (
		<PageContainer page={pageNames.userList}>
			<Box>
				<Grid container display="flex" alignItems="center" justifyContent="space-between" className="m-b-20">
					<Grid item>
						<Title>User Management </Title>
					</Grid>
					<Stack direction="row" spacing={2} alignItems="center">
						<Grid item>
							<SearchBar searchQuery={searchQuery} callBack={setSearchQuery} width={250} />
						</Grid>
						<Grid item>
							<PrimaryBtn color="primary" onClick={clearCache}>
								Clear Server Cache
							</PrimaryBtn>
						</Grid>
						<Grid item>
							<PrimaryBtn color="primary" onClick={() => history.push(USER_CREATE)}>
								Add New User
							</PrimaryBtn>
						</Grid>
					</Stack>
				</Grid>
				<Box sx={{ background: "#EDF5FA" }}>
					<div style={{ height: "75vh", width: "100%" }}>
						<DataGrid
							rowHeight={65}
							pagination={true}
							rows={paginatedData}
							rowCount={recordCount}
							columns={columns}
							checkboxSelection={false}
							disableColumnMenu={true}
							paginationMode="server"
							components={{ NoRowsOverlay: noRowsOverlay }}
							pageSize={OFFSET}
							onPageChange={(no) => {
								window.history.replaceState(null, "", `?pageNo=${no + 1}`);
								setQueryPageNo(no + 1);
								setPageNo(no + 1);
							}}
							rowsPerPageOptions={[10]}
							page={pageNo - 1}
						/>
					</div>
				</Box>
			</Box>
		</PageContainer>
	);
};

export default UserList;
