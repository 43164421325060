import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import FilterAccordion from "../../../../components/Filters";
import { useDispatch, useSelector } from "react-redux";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import PeriodViewFilter from "../PeriodViewFilter";
import Indicator from "../../../../components/Loader";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import { initialState } from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import dataObject, { periodView } from "../../../../mocks/priceLadderMock";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { dataFormat, enableCompetitorFilter } from "../../../../util/helper";
import MeasuresFilters from "../MeasuresFilters";
import { priceLadderDataLoader } from "../../store/PriceLadder.action";
import { CustomDropDownTitle } from "../../../../components/ProductFilters-v3/ProductFilters-v3";
import Dropdown from "../../../../components/Dropdown";
import { dateRangeSellIn, dateRangeSellOut } from "../PeriodViewFilter/PeriodViewFilter";
import { PRICE_LADDER_PRODUCT_FILTER_QUERY } from "../../../../util/queries/priceLadder";
import CommonProductFilters from "../../../../components/CommonProductFilters";
import { fetchFiltersData } from "../../../../util/services";
import { IProductFilterConfigurations } from "../../../../types/common";
import "../priceLadder.css";

const TopFilters: React.FC<{ callback; topClearCallBack; defaultFilters? }> = ({ callback, topClearCallBack, defaultFilters }) => {
	const userDetail = useSelector((state: any) => state.User.data);
	const commonLoader = useSelector((state: any) => state.common.loader);
	const PriceLadderDataLoader = useSelector((state: any) => state.PriceLadderDataLoader);
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [clearGeoFilterFlag, setClearGeoFilterFlag] = useState(false);
	const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
	const [selectedPepsicoFilters, setSelectedPepsicoFilters] = useState<any>({});
	const [defaultPepsicoFilters, setDefaultPepsicoFilters] = useState({});
	const [selectedCompetitorFilters, setSelectedCompetitorFilters] = useState<any>({});
	const [defaultCompetitorFilters, setDefaultCompetitorFilters] = useState({});
	const [productFiltersComparisonLevel, setProductFiltersComparisonLevel] = useState({
		comparisonLevel: dataObject.productFilterBy.comparisonLevel.defaultOption.value,
	});
	const [selectedMeasureFilters, setSelectedMeasureFilters] = useState({
		somValueType: dataObject.measuresFilter.somValueType.defaultOption.value,
		somValueBy: dataObject.measuresFilter.somValueBy.defaultOption.value,
		apiFilter: dataObject.measuresFilter.apiFilter.defaultOption.value,
		measuresFilterSOP: dataObject.measuresFilter.measuresFilterSOP.defaultOption.value,
		markup: "",
		taxes: "",
		priceInterval: "1",
	});
	const [isManuallyUpdateFilters, setIsManuallyUpdateFilters] = useState(false);
	const [isFirstLoadPepsiFlag, setIsFirstLoadPepsiFlag] = useState(true);
	const [isFirstLoadCompetitorFlag, setIsFirstLoadCompetitorFlag] = useState(true);
	const [isCountryManuallyChangedFlag, setIsCountryManuallyChangedFlag] = useState(false);
	const [selectedSellInDate, setSelectedSellInDate] = useState(
		`${dataFormat(dateRangeSellIn[0], "MM/dd/yyyy")}-${dataFormat(dateRangeSellIn[1], "MM/dd/yyyy")}`
	);

	const [selectedSellOutDate, setSelectedSellOutDate] = useState(
		`${dataFormat(dateRangeSellOut[0], "MM/dd/yyyy")}-${dataFormat(dateRangeSellOut[1], "MM/dd/yyyy")}`
	);

	const [productFiltersPriceLadderBy, setProductFiltersPriceLadderBy] = useState({
		priceLadderBy: dataObject.productFilterBy.priceLadderBy.defaultOption.value,
	});

	const [productFilterOptions, setProductFilterOptions] = useState<IProductFilterConfigurations[]>([]);
	const [competitorFilterOptions, setCompetitorFilterOptions] = useState<IProductFilterConfigurations[]>([]);
	const [productMockData, setProductMockData] = useState<any>({});
	const [competitorMockData, setCompetitorMockData] = useState<any>({});

	const handleCompetitorSelections = (selectedValues) => {
		setIsManuallyUpdateFilters(true);
		setIsFirstLoadCompetitorFlag(false);
		setSelectedCompetitorFilters(selectedValues);
	};

	const handlePepsiSelections = (selectedValues) => {
		setIsManuallyUpdateFilters(true);
		setIsFirstLoadPepsiFlag(false);
		setSelectedPepsicoFilters(selectedValues);
	};
	const clearFilter = () => {
		topClearCallBack();
		setIsManuallyUpdateFilters(true);
		setClearGeoFilterFlag(true);
		setSelectedGeoFilters({ ...initialState });
		setSelectedMeasureFilters({
			somValueType: dataObject.measuresFilter.somValueType.defaultOption.value,
			somValueBy: dataObject.measuresFilter.somValueBy.defaultOption.value,
			apiFilter: dataObject.measuresFilter.apiFilter.defaultOption.value,
			measuresFilterSOP: dataObject.measuresFilter.measuresFilterSOP.defaultOption.value,
			markup: "",
			taxes: "",
			priceInterval: "1",
		});

		setProductFiltersPriceLadderBy({
			priceLadderBy: dataObject.productFilterBy.priceLadderBy.defaultOption.value,
		});

		setProductFiltersComparisonLevel({
			comparisonLevel: dataObject.productFilterBy.comparisonLevel.defaultOption.value,
		});
		setSelectedPepsicoFilters({});
		setSelectedCompetitorFilters({});
		setProductFilterOptions([]);
		setCompetitorFilterOptions([]);
	};

	const setSelectedGeoFiltersLogic = (e) => {
		if (isCountryManuallyChangedFlag) {
			setIsManuallyUpdateFilters(true);
			setProductFiltersComparisonLevel({ comparisonLevel: defaultFilters.comparisonLevel || "brandsize" });
		}

		setSelectedGeoFilters({ ...e });

		if (clearGeoFilterFlag) {
			setClearGeoFilterFlag(false);
		}
		const isEnableCompetitorFilter = enableCompetitorFilter(selectedGeoFilters);
		if (!isEnableCompetitorFilter) {
			setSelectedCompetitorFilters(defaultFilters.competitor);
		}
	};

	const onApplyFilter = async () => {
		dispatch(priceLadderDataLoader(true));
		let PcOptions = [];
		if (productFiltersComparisonLevel.comparisonLevel === "brandsize") {
			PcOptions = dataObject.competitorFilter.permutationComputation.options;
		} else if (productFiltersComparisonLevel.comparisonLevel === "subbrandsize") {
			PcOptions = dataObject.competitorFilterSubBrand.permutationComputation.options;
		}

		let PcOptionsSelected = selectedCompetitorFilters?.permutationComputation;
		const tempArr = PcOptions.filter((item) => PcOptionsSelected?.indexOf(item.value) !== -1);
		callback({
			filters: {
				...selectedGeoFilters,
				...selectedMeasureFilters,
				...productFiltersPriceLadderBy,
				...productFiltersComparisonLevel,
				permutationOptions: [...tempArr],
				pepsi: { ...selectedPepsicoFilters, vendor: ["PEPSICO"] },
				competitor: { ...selectedCompetitorFilters },
				sellInStartDate: selectedSellInDate.split("-")[0],
				sellInEndDate: selectedSellInDate.split("-")[1],
				sellOutStartDate: selectedSellOutDate.split("-")[0],
				sellOutEndDate: selectedSellOutDate.split("-")[1],
			},
		});
	};

	const disableApplyFilter = () => {
		const flag =
			!selectedGeoFilters.country ||
			!selectedGeoFilters.geoLevel2 ||
			!selectedPepsicoFilters.packSize ||
			!selectedPepsicoFilters.permutationComputation ||
			(enableCompetitorFilter(selectedGeoFilters) && !selectedCompetitorFilters?.permutationComputation);
		return flag;
	};

	useEffect(() => {
		if (productFiltersComparisonLevel.comparisonLevel === "brandsize") {
			setProductMockData({ ...dataObject.productFilter });
			setCompetitorMockData({ ...dataObject.competitorFilter });
			productFilterOptions.forEach(
				(filterOptions: IProductFilterConfigurations) =>
					(filterOptions.permutationComputation = {
						label: filterOptions.brand.label + " " + filterOptions.packSize.label,
						value: filterOptions.brandSize?.value ?? "",
					})
			);
			competitorFilterOptions.forEach(
				(filterOptions: IProductFilterConfigurations) =>
					(filterOptions.permutationComputation = {
						label: filterOptions.brand.label + " " + filterOptions.packSize.label,
						value: filterOptions.brandSize?.value ?? "",
					})
			);
		} else if (productFiltersComparisonLevel.comparisonLevel === "subbrandsize") {
			setProductMockData({ ...dataObject.productFilterSubBrand });
			setCompetitorMockData({ ...dataObject.competitorFilterSubBrand });
			productFilterOptions.forEach(
				(filterOptions: IProductFilterConfigurations) =>
					(filterOptions.permutationComputation = {
						label: filterOptions.subBrand.label + " " + filterOptions.packSize.label,
						value: filterOptions.subBrandSize?.value ?? "",
					})
			);
			competitorFilterOptions.forEach(
				(filterOptions: IProductFilterConfigurations) =>
					(filterOptions.permutationComputation = {
						label: filterOptions.subBrand.label + " " + filterOptions.packSize.label,
						value: filterOptions.subBrandSize?.value ?? "",
					})
			);
		}
		setProductFilterOptions(productFilterOptions);
		setCompetitorFilterOptions(competitorFilterOptions);
		setIsFirstLoadPepsiFlag(true);
		setIsFirstLoadCompetitorFlag(true);
		if (isManuallyUpdateFilters) {
			setSelectedPepsicoFilters({});
			setSelectedCompetitorFilters({});
		}
	}, [productFiltersComparisonLevel.comparisonLevel]);

	useEffect(() => {
		if (Object.keys(defaultFilters).length > 0) {
			setIsManuallyUpdateFilters(false);
			setProductFiltersComparisonLevel({ comparisonLevel: defaultFilters.comparisonLevel });
			setSelectedMeasureFilters({
				somValueType: defaultFilters.somValueType,
				somValueBy: defaultFilters.somValueBy,
				apiFilter: defaultFilters.apiFilter,
				measuresFilterSOP: defaultFilters.measuresFilterSOP,
				markup: defaultFilters.markup,
				taxes: defaultFilters.taxes,
				priceInterval: defaultFilters.priceInterval,
			});
			setProductFiltersPriceLadderBy({ priceLadderBy: defaultFilters.priceLadderBy });
			setSelectedPepsicoFilters({
				...selectedPepsicoFilters,
				category: defaultFilters.pepsi.category,
				segment: defaultFilters.pepsi.segment,
				brand: defaultFilters.pepsi.brand,
				subBrand: defaultFilters.pepsi.subBrand,
				packSize: defaultFilters.pepsi.packSize,
				permutationComputation: defaultFilters.pepsi.permutationComputation,
			});
			setDefaultPepsicoFilters({
				...defaultPepsicoFilters,
				category: defaultFilters.pepsi.category,
				segment: defaultFilters.pepsi.segment,
				brand: defaultFilters.pepsi.brand,
				subBrand: defaultFilters.pepsi.subBrand,
				packSize: defaultFilters.pepsi.packSize,
				permutationComputation: defaultFilters.pepsi.permutationComputation,
			});
			setSelectedCompetitorFilters({
				...selectedCompetitorFilters,
				vendor: defaultFilters.competitor.vendor,
				category: defaultFilters.competitor.category,
				segment: defaultFilters.competitor.segment,
				brand: defaultFilters.competitor.brand,
				subBrand: defaultFilters.competitor.subBrand,
				packSize: defaultFilters.competitor.packSize,
				permutationComputation: defaultFilters.competitor.permutationComputation,
			});
			setDefaultCompetitorFilters({
				...defaultCompetitorFilters,
				vendor: defaultFilters.competitor.vendor,
				category: defaultFilters.competitor.category,
				segment: defaultFilters.competitor.segment,
				brand: defaultFilters.competitor.brand,
				subBrand: defaultFilters.competitor.subBrand,
				packSize: defaultFilters.competitor.packSize,
				permutationComputation: defaultFilters.competitor.permutationComputation,
			});
			setSelectedSellInDate(`${defaultFilters.sellInStartDate}-${defaultFilters.sellInEndDate}`);
			setSelectedSellOutDate(`${defaultFilters.sellOutStartDate}-${defaultFilters.sellOutEndDate}`);
		}
	}, [defaultFilters]);

	useEffect(() => {
		if (selectedGeoFilters && selectedGeoFilters.country !== null && userDetail?.id) {
			fetchFilters({ country: selectedGeoFilters.country });
		}
	}, [selectedGeoFilters.country, userDetail?.id]);

	const fetchFilters = (payload) => {
		if (isCountryManuallyChangedFlag) {
			setSelectedPepsicoFilters({});
			setSelectedCompetitorFilters({});
		}
		if (selectedGeoFilters.country) {
			setLoader(true);
			payload = {
				...payload,
			};
			fetchFiltersData(
				"graphql",
				{
					country: selectedGeoFilters.country,
					...payload,
				},
				userDetail.id,
				PRICE_LADDER_PRODUCT_FILTER_QUERY
			)
				.then((response) => {
					if (response && response.data) {
						const productFilterConfig: IProductFilterConfigurations[] = [];
						const competitorFilterConfig: IProductFilterConfigurations[] = [];
						const filterObject = JSON.parse(response.data);
						const isBrandSizeSelected = productFiltersComparisonLevel.comparisonLevel === "brandsize";
						filterObject
							.filter((object) => object.Manufacturer === "PEPSICO")
							.map((object) => {
								productFilterConfig.push({
									category: {
										label: object.category,
										value: object.category,
									},
									segment: {
										label: object.segment,
										value: object.segment,
									},
									brand: {
										label: object.brand,
										value: object.brand,
									},
									subBrand: {
										label: object.subbrand,
										value: object.subbrand,
									},
									packSize: {
										label: object.packsize,
										value: object.packsize,
									},
									permutationComputation: {
										label: (isBrandSizeSelected ? object.brand : object.subbrand) + " " + object.packsize,
										value: isBrandSizeSelected ? object.brandsize : object.subbrandsize,
									},
									vendor: {
										label: object.Manufacturer,
										value: object.Manufacturer,
									},
									brandSize: {
										label: object.brand + " " + object.packsize,
										value: object.brandsize,
									},
									subBrandSize: {
										label: object.subbrand + " " + object.packsize,
										value: object.subbrandsize,
									},
								});
							});

						filterObject
							.filter((object) => object.Manufacturer !== "PEPSICO")
							.map((object) => {
								competitorFilterConfig.push({
									category: {
										label: object.category,
										value: object.category,
									},
									segment: {
										label: object.segment,
										value: object.segment,
									},
									brand: {
										label: object.brand,
										value: object.brand,
									},
									subBrand: {
										label: object.subbrand,
										value: object.subbrand,
									},
									packSize: {
										label: object.packsize,
										value: object.packsize,
									},
									permutationComputation: {
										label: (isBrandSizeSelected ? object.brand : object.subbrand) + " " + object.packsize,
										value: isBrandSizeSelected ? object.brandsize : object.subbrandsize,
									},
									vendor: {
										label: object.Manufacturer,
										value: object.Manufacturer,
									},
									brandSize: {
										label: object.brand + " " + object.packsize,
										value: object.brandsize,
									},
									subBrandSize: {
										label: object.subbrand + " " + object.packsize,
										value: object.subbrandsize,
									},
								});
							});
						setProductFilterOptions(productFilterConfig);
						setCompetitorFilterOptions(competitorFilterConfig);
						setLoader(false);
					}
				})
				.catch((e) => {
					setLoader(false);
				});
		}
	};
	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<Indicator position="absolute" show={!commonLoader && (loader || PriceLadderDataLoader.priceLadderDataLoader)} />
			<CardContent>
				<>
					<FilterAccordion title="Geo-Filters">
						<Grid container spacing={2} columns={16}>
							<Grid item sm={9} className="geo_filters_left">
								<GeoFiltersV2
									data={geoFilterV2Config}
									onChangeDate={(dt) => {
										setClearGeoFilterFlag(false);
									}}
									onSelectFilters={(e) => setSelectedGeoFiltersLogic(e)}
									clearFilter={clearGeoFilterFlag}
									apiPath="PriceLadder"
									showLoader={setLoader}
									defaultFilters={defaultFilters}
									showDatePicker={false}
									setIsCountryManuallyChangedFlag={setIsCountryManuallyChangedFlag}
								/>
							</Grid>
						</Grid>
					</FilterAccordion>

					<FilterAccordion title="Products Filters">
						<Grid container display="flex" alignItems="center" justifyContent="space-between">
							<Grid container spacing={3} xs={12} sm={8}>
								<Grid item xs={12} sm={4}>
									<Typography variant="subtitle2">Comparison Level</Typography>
									<Grid>
										<RadioButtonGroup
											color="#000"
											showButton={dataObject.productFilterBy.comparisonLevel.showRadioButton}
											data={
												selectedGeoFilters.country === "MEXICO" || !selectedGeoFilters.country
													? dataObject.productFilterBy.comparisonLevel.options
													: dataObject.productFilterBy.comparisonLevel.optionsNonMexico
											}
											defaultOption={productFiltersComparisonLevel.comparisonLevel}
											select={(data) => {
												setIsManuallyUpdateFilters(true);
												setProductFiltersComparisonLevel({ comparisonLevel: data });
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography variant="subtitle2">Price Ladder By</Typography>
									<Grid>
										<RadioButtonGroup
											color="#000"
											showButton={dataObject.productFilterBy.priceLadderBy.showRadioButton}
											data={dataObject.productFilterBy.priceLadderBy.options}
											defaultOption={productFiltersPriceLadderBy.priceLadderBy}
											select={(data) => {
												setProductFiltersPriceLadderBy({ priceLadderBy: data });
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid container spacing={2} columns={Object.keys(productMockData).length + 1}>
								<Grid item xs={1} className="productFilterLevels">
									<>
										<CustomDropDownTitle title={'Vendor <span style="color: #858c94;">(Pepsico)</span>'} />
										<Dropdown disabled={true} data={[{ label: "PEPSICO", value: "PEPSICO" }]} defaultOption={["PEPSICO"]} placeholder="Select" />
									</>
								</Grid>
								<Grid item xs={Object.keys(productMockData).length}>
									<CommonProductFilters
										filterData={productFilterOptions}
										onChange={handlePepsiSelections}
										data={productMockData}
										defaultFilters={selectedPepsicoFilters ?? {}}
										filterOrder={
											productFiltersComparisonLevel.comparisonLevel === "brandsize"
												? dataObject.priceLadderProductFilterOrder
												: dataObject.priceLadderSubBrandProductFilterOrder
										}
										isAnchorFlag={false}
										isFirstLoadFlag={isFirstLoadPepsiFlag}
									/>
								</Grid>
							</Grid>
							<Grid xs={12} sm={12}>
								<CommonProductFilters
									filterData={competitorFilterOptions}
									onChange={(selectedValues) => {
										handleCompetitorSelections(selectedValues);
									}}
									data={competitorMockData}
									defaultFilters={selectedCompetitorFilters ?? {}}
									filterOrder={
										productFiltersComparisonLevel.comparisonLevel === "brandsize"
											? dataObject.priceLadderCompetitorFilterOrder
											: dataObject.priceLadderSubBrandCompetitorFilterOrder
									}
									isAnchorFlag={false}
									isFirstLoadFlag={isFirstLoadCompetitorFlag}
								/>
							</Grid>
							<Grid xs={12} sm={12}>
								<b>Note:</b> Selecting more than 10 products for PEPSICO and Competitors, will impact the dashboard performance and the quality of the
								visualization.
							</Grid>
						</Grid>
					</FilterAccordion>
					<FilterAccordion title="Date Filters">
						<Grid sx={{ mb: 4 }}>
							<PeriodViewFilter
								data={{ ...geoFilterV2Config, periodView }}
								selectedGeoFilters={selectedGeoFilters.geoLevel2 !== null ? selectedGeoFilters : {}}
								onChangeDateSellIn={(dt) => {
									setClearGeoFilterFlag(false);
									setSelectedSellInDate(dt);
								}}
								onChangeDateSellOut={(dt) => {
									setClearGeoFilterFlag(false);
									setSelectedSellOutDate(dt);
								}}
								defaultFilters={defaultFilters}
								clearFilter={clearGeoFilterFlag}
								apiPath="PriceLadder"
								showLoader={setLoader}
								selectedProductFilters={selectedPepsicoFilters}
							/>
						</Grid>
					</FilterAccordion>
					<FilterAccordion title="Measure Filters">
						<MeasuresFilters
							callback={setSelectedMeasureFilters}
							selectedMeasuresFilters={selectedMeasureFilters}
							priceLadderBy={productFiltersPriceLadderBy.priceLadderBy}
							selectedPepsicoFilters={selectedPepsicoFilters}
							selectedCompetitorFilters={selectedCompetitorFilters}
						/>
					</FilterAccordion>
					<Grid className="p-l-16">
						<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
							Clear Filter
						</OrangeBtn>
						<PrimaryBtn disabled={disableApplyFilter()} color="primary" onClick={onApplyFilter}>
							Apply Filter
						</PrimaryBtn>
					</Grid>
				</>
			</CardContent>
		</Card>
	);
};

export default TopFilters;
