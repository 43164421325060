import { getFavoriteDashboards } from "../../../util/services";
import { GET_FAVORITE_DASH, POST_FAVORITE, POST_FAVORITE_FAILURE } from "./constant";

const getFavoriteDashboard = (payload: string) => {
	return {
		type: GET_FAVORITE_DASH,
		payload,
	};
};

const postFavDash = () => {
	return {
		type: POST_FAVORITE,
	};
};

const postFavDashFailure = (error) => {
	return {
		type: POST_FAVORITE_FAILURE,
		payload: error,
	};
};

export function fetchFavoriteDashboardList(payload) {
	return (dispatch: any) => {
		dispatch(postFavDash());
		getFavoriteDashboards(payload.userId)
			.then((response) => {
				dispatch(getFavoriteDashboard(response.data));
			})
			.catch((error) => {
				dispatch(postFavDashFailure(error));
			});
	};
}
