export const geoFilterV2Config = {
	country: {
		title: "Country",
		options: [],
	},
	geoLevel: {
		title: "Geo-Level",
		options: [],
		placeholder: "Select",
		all: false,
		multiple: false,
	},
	geoLevel2: {
		title: "Geo-Level 2",
		options: [],
		placeholder: "Select",
		all: false,
		multiple: false,
		defaultSelectAll: false,
	},
	channel: {
		title: "Channel",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: true,
	},
};
