import React from "react";
import { Grid, Typography } from "@mui/material";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import TextField from "@mui/material/TextField";
import dataObject from "../../../../mocks/priceLadderMock";
import { inputRegex } from "../../../../util/helper";

const MeasuresFilters: React.FC<{ selectedMeasuresFilters; callback; priceLadderBy; selectedPepsicoFilters; selectedCompetitorFilters }> = ({
	selectedMeasuresFilters,
	callback,
	priceLadderBy,
	selectedPepsicoFilters,
	selectedCompetitorFilters,
}) => {
	selectedMeasuresFilters.somValueBy =
		selectedPepsicoFilters?.segment?.length > 1 || selectedCompetitorFilters?.segment?.length > 1 ? "category" : selectedMeasuresFilters.somValueBy;

	return (
		<Grid container display="flex" alignItems="center" justifyContent="space-between" style={{ alignItems: "baseline" }}>
			<Grid container alignItems="center" spacing={3} xs={12} sm={12}>
				<Grid item xs={1}>
					<Typography variant="subtitle2">{dataObject.measuresFilter.somValueType.title}</Typography>
				</Grid>
				<Grid container alignItems="center" item xs={8} sm={8}>
					<Typography>
						<RadioButtonGroup
							color="#000"
							showButton={dataObject.measuresFilter.somValueType.showRadioButton}
							data={dataObject.measuresFilter.somValueType.options}
							defaultOption={selectedMeasuresFilters.somValueType}
							select={(data) => callback({ ...selectedMeasuresFilters, somValueType: data })}
							direction={"row"}
						/>
					</Typography>
					<Typography style={{ marginLeft: "15px", marginRight: "30px" }}>|</Typography>
					<Typography>
						<RadioButtonGroup
							color="#000"
							showButton={dataObject.measuresFilter.somValueBy.showRadioButton}
							data={
								selectedPepsicoFilters?.segment?.length > 1 || selectedCompetitorFilters?.segment?.length > 1
									? dataObject.measuresFilter.somValueByCatOnly.options
									: dataObject.measuresFilter.somValueBy.options
							}
							defaultOption={selectedMeasuresFilters.somValueBy}
							select={(data) => callback({ ...selectedMeasuresFilters, somValueBy: data })}
							direction={"row"}
						/>
					</Typography>
				</Grid>
			</Grid>

			<Grid container alignItems="top" spacing={1} xs={12} sm={12}>
				<Grid item xs={1}>
					<Typography style={{ paddingTop: "10px" }} variant="subtitle2">
						{dataObject.measuresFilter.apiFilter.title}
					</Typography>
				</Grid>
				<Grid item xs={4} sm={4}>
					<RadioButtonGroup
						color="#000"
						disabled={priceLadderBy !== "maincompetitor"}
						showButton={dataObject.measuresFilter.apiFilter.showRadioButton}
						data={dataObject.measuresFilter.apiFilter.options}
						defaultOption={selectedMeasuresFilters.apiFilter}
						select={(data) => callback({ ...selectedMeasuresFilters, apiFilter: data })}
						direction={"row"}
					/>
				</Grid>
			</Grid>

			<Grid container alignItems="top" spacing={3} xs={12} sm={12}>
				<Grid item xs={1}>
					<Typography style={{ paddingTop: "10px" }} variant="subtitle2">
						{dataObject.measuresFilter.measuresFilterSOP.title}
					</Typography>
				</Grid>
				<Grid item xs={11} sm={11}>
					<RadioButtonGroup
						color="#000"
						showButton={dataObject.measuresFilter.measuresFilterSOP.showRadioButton}
						data={dataObject.measuresFilter.measuresFilterSOP.options}
						defaultOption={selectedMeasuresFilters.measuresFilterSOP}
						select={(data) => callback({ ...selectedMeasuresFilters, measuresFilterSOP: data })}
						direction={"row"}
					/>
				</Grid>
			</Grid>

			<Grid container mt={1} mb={1} spacing={2} sm={12}>
				<Grid item xs={2} sm={2}>
					<Typography variant="subtitle2">Price Interval</Typography>
					<Grid display="flex" alignItems="center">
						<TextField
							id="priceInterval"
							type="number"
							inputProps={{
								autoComplete: "off",
							}}
							placeholder="0"
							onChange={(e) => {
								callback({ ...selectedMeasuresFilters, priceInterval: e.target.value });
							}}
							value={selectedMeasuresFilters.priceInterval}
							InputProps={{ inputProps: { min: "0", step: "1" }, autoComplete: "off" }}
							onKeyDown={inputRegex}
						/>
					</Grid>
				</Grid>
				<Grid item xs={2} sm={2}>
					<Typography variant="subtitle2">Markup(%)</Typography>
					<Grid display="flex" alignItems="center">
						<TextField
							id="markup"
							type="number"
							inputProps={{
								autoComplete: "off",
							}}
							placeholder="0%"
							onChange={(e) => {
								callback({ ...selectedMeasuresFilters, markup: e.target.value });
							}}
							value={selectedMeasuresFilters.markup}
							InputProps={{ inputProps: { min: "0", step: "1" }, autoComplete: "off" }}
							onKeyDown={inputRegex}
						/>
					</Grid>
				</Grid>
				<Grid item xs={2} sm={2}>
					<Typography variant="subtitle2">Taxes(%)</Typography>
					<Grid display="flex" alignItems="center">
						<TextField
							id="taxes"
							type="number"
							inputProps={{
								autoComplete: "off",
							}}
							placeholder="0%"
							onChange={(e) => {
								callback({ ...selectedMeasuresFilters, taxes: e.target.value });
							}}
							value={selectedMeasuresFilters.taxes}
							InputProps={{ inputProps: { min: "0", step: "1" }, autoComplete: "off" }}
							onKeyDown={inputRegex}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MeasuresFilters;
