import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, CardContent } from "@mui/material";
import { Typography } from "@material-ui/core";
import { BugReportIcon, SystemAlertIcon, DashboardCreatedIcon, DataUpdatingIcon } from "../../assets/icons/notification";
import { Title } from "../../pages/AlertAndNotification/components/NotificationListCard/NotificationListCard.Styled";
import { CloseButton } from "../../styles/Common.Styled";

const useStyles = makeStyles(() => ({
	icon: {
		width: "70px",
		borderRadius: "6px",
	},
	title: {
		fontSize: 16,
		color: "#000",
	},
	content: {
		fontSize: 14,
		color: "#000",
	},
	moreInfo: {
		textDecorationLine: "underline",
		cursor: "pointer",
	},
	bugTypo: { color: "#FF721E !important" },
	alertTypo: { color: "#CE0065 !important" },
	newTypo: { color: "#0093CD !important" },
	updateTypo: { color: "#00AA52 !important" },
}));

const NotificationCard: React.FC<{ data; callback }> = ({ data, callback }) => {
	const classes = useStyles();
	const { type, title, subTitle } = data;

	const notificationIconComponent = (type) => {
		switch (type) {
			case "Bug":
				return <img src={BugReportIcon} alt="icon" className={classes.icon} />;
			case "System_Alert":
				return <img src={SystemAlertIcon} alt="icon" className={classes.icon} />;
			case "New_Dashboard_Created":
				return <img src={DashboardCreatedIcon} alt="icon" className={classes.icon} />;
			default:
				return <img src={DataUpdatingIcon} alt="icon" className={classes.icon} />;
		}
	};
	const getTitleClass = (type) => {
		switch (type) {
			case "Bug":
				return classes.bugTypo;
			case "System_Alert":
				return classes.alertTypo;
			case "New_Dashboard_Created":
				return classes.newTypo;
			default:
				return classes.updateTypo;
		}
	};

	return (
		<Card data-testid="notification-container">
			<CardContent style={{ position: "relative" }}>
				<Grid container display="flex" alignItems="center" spacing={2}>
					<Grid item xs={4} sm={2}>
						{notificationIconComponent(type)}
					</Grid>
					<Grid item xs={12} sm={10}>
						<Title className={getTitleClass(type)}>{title}</Title>
						<Typography className={classes.title}>{subTitle}</Typography>
						<Typography className={classes.content}>{data.message}</Typography>
					</Grid>
				</Grid>
				<CloseButton style={{ right: 10, top: 10, padding: 5, height: 30, width: 30 }} onClick={() => callback({ type: "dismiss" })}>
					X
				</CloseButton>
			</CardContent>
		</Card>
	);
};
export default NotificationCard;
