import React, { FC, useEffect, useState } from "react";
import { Box, CardContent, Grid, Stack } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
	SrpSopPrice,
	SomBlk,
	SrpSopBlk,
	SrpSopBlueSec,
	SrpSopPinkSecRight,
	SrpSopBlueSecLeft,
	SopPinkList,
	SrpBlueList,
	SrpSopImageSec,
	SrpSopImageName,
	SrpSopContentSec,
	SrpSopContenttitle,
	toolTipButton,
	greenSmallText,
	redSmallText,
	graySmallText,
	labeSmallText,
	cursorMove,
} from "../priceLadder.Styled";
import { BlackTitle, OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import VendorsList from "../VendorsList";
import dataObject from "../../../../mocks/priceLadderMock";
import { valueBasedOnColor } from "../../../../util/helper";
import { fetchImageURL } from "../../../../util/services";
import { IPLFilterObject, IPLVendorListObject, IPriceLadderFigureLevels, ISRPDataObject } from "../../../../types/priceLadder";

const styleNew = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "25%",
	height: "inherit",
	backgroundColor: "#fff",
	border: "1px solid #000",
	boxShadow: 24,
	p: 3,
	borderRadius: "8px",
	padding: "20px",
};

const useStyles = makeStyles(() => ({
	listItem: {
		position: "relative",
		"&:hover $hoverIconContainer": {
			display: "flex",
		},
	},
	hoverIconContainer: {
		borderRadius: "4px",
		display: "none",
		fontSize: "20px",
		position: "absolute",
		textAlign: "right",
		top: 0,
		zIndex: 10,
	},
	imgIcon: {
		height: "30px",
		width: "30px",
	},
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#fff",
		color: "#000",
		maxWidth: 240,
		border: "1px solid #dadde9",
		fontSize: theme.typography.pxToRem(15),
		borderRadius: "6px",
		boxShadow: `2px 2px 6px rgba(0, 0, 0, 0.28)`,
	},
}));

const PriceLadderSRP: FC<{
	filters: IPLFilterObject;
	vendors: IPLVendorListObject[];
	SRPData: ISRPDataObject[];
	setSRPData: React.Dispatch<React.SetStateAction<ISRPDataObject[]>>;
}> = ({ filters, vendors, SRPData, setSRPData }) => {
	const classes = useStyles();
	const [figerLevels, setFigerLevels] = useState<IPriceLadderFigureLevels>({
		volume: true,
		ppv: true,
		somChange: true,
		sop: true,
	});
	const [vendorData, setVendorData] = useState(vendors);
	const [openNew, setOpenNew] = useState(false);
	const [priceTierError, setPriceTierError] = useState("");
	const [newPriceTierValue, setNewPriceTierValue] = useState<string>();

	const addNewPriceTier = () => {
		if (newPriceTierValue === null || newPriceTierValue === undefined || newPriceTierValue === "") {
			setPriceTierError("Enter price tier value");
			return false;
		}

		const found = SRPData.find((obj) => {
			return obj.srp === parseFloat(newPriceTierValue) ? obj.srp : null;
		});
		if (found) {
			setPriceTierError("Price tier already exists!");
			return false;
		} else {
			setPriceTierError("");
		}

		setSRPData([
			...SRPData,
			{
				srp: parseFloat(newPriceTierValue),
				grcompetitor: 0,
				sompepsico: 0,
				sompepsicopp: 0,
				somcompetitor: 0,
				somcompetitorpp: 0,
				products: [],
			},
		]);
	};

	const handleLevels = (e) => {
		setFigerLevels({ ...figerLevels, [e.target.value]: e.target.checked });
	};

	const handleOpenNew = () => setOpenNew(true);
	const handleCloseNew = () => setOpenNew(false);

	useEffect(() => {
		if (newPriceTierValue) {
			const numAscending = [...SRPData].sort((a, b) => b.srp - a.srp);
			setSRPData(numAscending);
			setNewPriceTierValue("");
			setOpenNew(false);
		}
	}, [SRPData]);

	const onDragStart = (ev, productName, rowIndex, productIndex, productType) => {
		ev.dataTransfer.setData("productName", productName);
		ev.dataTransfer.setData("productType", productType);
		ev.dataTransfer.setData("rowIndex", rowIndex);
		ev.dataTransfer.setData("productIndex", productIndex);
		let el = document.getElementsByClassName("ToolTopText");
		el[0].style.position = "unset";
	};

	const onDragOver = (ev) => {
		ev.preventDefault();
	};

	const onDrop = async (ev, indx) => {
		let productType = ev.dataTransfer.getData("productType");
		let rowIndex = ev.dataTransfer.getData("rowIndex");
		let productIndex = ev.dataTransfer.getData("productIndex");

		let transferedProduct = SRPData[rowIndex].products[productIndex];

		if (productType === "pepsi") {
			// Set SRP value
			transferedProduct.srp = SRPData[indx].srp;

			// SOM deduction from
			SRPData[rowIndex].sompepsico = parseFloat(
				(parseFloat(SRPData[rowIndex].sompepsico.toString()) - parseFloat(transferedProduct.som.toString())).toFixed(2)
			);
			SRPData[rowIndex].sompepsicopp = parseFloat(
				(parseFloat(SRPData[rowIndex].sompepsicopp.toString()) - parseFloat(transferedProduct.ppvpp.toString())).toFixed(2)
			);

			// SOM addition
			SRPData[indx].sompepsico = parseFloat((parseFloat(SRPData[indx].sompepsico.toString()) + parseFloat(transferedProduct.som.toString())).toFixed(2));
			SRPData[indx].sompepsicopp = parseFloat((parseFloat(SRPData[indx].sompepsicopp.toString()) + parseFloat(transferedProduct.ppvpp.toString())).toFixed(2));
		} else {
			// Set SOP value
			transferedProduct.sop = SRPData[indx].srp;

			// SOM deduction from
			SRPData[rowIndex].somcompetitor = parseFloat(
				(parseFloat(SRPData[rowIndex].somcompetitor.toString()) - parseFloat(transferedProduct.som.toString())).toFixed(2)
			);
			SRPData[rowIndex].somcompetitorpp = parseFloat(
				(parseFloat(SRPData[rowIndex].somcompetitorpp.toString()) - parseFloat(transferedProduct.ppvpp.toString())).toFixed(2)
			);

			// SOM addition
			SRPData[indx].somcompetitor = parseFloat((parseFloat(SRPData[indx].somcompetitor.toString()) + parseFloat(transferedProduct.som.toString())).toFixed(2));
			SRPData[indx].somcompetitorpp = parseFloat(
				(parseFloat(SRPData[indx].somcompetitorpp.toString()) + parseFloat(transferedProduct.ppvpp.toString())).toFixed(2)
			);
		}

		// Set PPV value
		if (transferedProduct.gr > 0) {
			transferedProduct.ppv = parseFloat(((parseFloat(SRPData[indx].srp.toString()) / transferedProduct.gr) * 1000).toFixed(2));
		} else {
			transferedProduct.ppv = 0;
		}

		SRPData[indx].products.push(transferedProduct);

		// Remove products from previous tier
		const tempProducts = SRPData[rowIndex].products?.filter((el, indx) => {
			return indx.toString() !== productIndex.toString();
		});

		SRPData[rowIndex].products = tempProducts;
		setSRPData([...SRPData]);
	};

	const removeProduct = async (ev, rowIndex, productIndex, productType) => {
		let removedProduct = SRPData[rowIndex].products[productIndex];
		const vendorsDataObject = vendorData;
		let vendorIndex = -1;
		if (productType === "PEPSICO") {
			// SOM deduction from products
			SRPData[rowIndex].sompepsico = parseFloat((parseFloat(SRPData[rowIndex].sompepsico.toString()) - parseFloat(removedProduct.som.toString())).toFixed(2));
			SRPData[rowIndex].sompepsicopp = parseFloat(
				(parseFloat(SRPData[rowIndex].sompepsicopp.toString()) - parseFloat(removedProduct.ppvpp.toString())).toFixed(2)
			);
			vendorIndex = vendorsDataObject.findIndex((vendor) => {
				return "PEPSICO" == vendor.vendor;
			});
		} else {
			// SOM deduction from products
			SRPData[rowIndex].somcompetitor = parseFloat(
				(parseFloat(SRPData[rowIndex].somcompetitor.toString()) - parseFloat(removedProduct.som.toString())).toFixed(2)
			);
			SRPData[rowIndex].somcompetitorpp = parseFloat(
				(parseFloat(SRPData[rowIndex].somcompetitorpp.toString()) - parseFloat(removedProduct.ppvpp.toString())).toFixed(2)
			);
			vendorIndex = vendorsDataObject.findIndex((vendor) => {
				return removedProduct.vendor == vendor.vendor;
			});
		}
		// SOM deduction from vendors
		vendorsDataObject[vendorIndex].selected = parseFloat(
			(parseFloat(vendorsDataObject[vendorIndex].selected.toString()) - parseFloat(removedProduct.som.toString())).toFixed(2)
		);
		vendorsDataObject[vendorIndex].selectedpp = parseFloat(
			(parseFloat(vendorsDataObject[vendorIndex].selectedpp.toString()) - parseFloat(removedProduct.ppvpp.toString())).toFixed(2)
		);
		const tempProducts = SRPData[rowIndex].products?.filter((el, indx) => {
			return indx.toString() !== productIndex.toString();
		});
		SRPData[rowIndex].products = tempProducts;
		setVendorData([...vendorsDataObject]);
		setSRPData([...SRPData]);
	};

	const removePriceTier = (data, srpValue) => {
		const newSRPData = SRPData.filter((el) => {
			return el.srp !== srpValue;
		});
		setSRPData(newSRPData);
	};

	// Handle missing images
	const handleMissingImages = (event) => {
		const brandName = event.target.nextElementSibling;
		event.target.style.display = "none";
		brandName.style.display = "block";
	};

	const resetChanges = async () => {
		const originalData = JSON.parse(localStorage.getItem("srpGraphData") || "{}");
		originalData.forEach((g) => {
			g.products.forEach((p) => {
				p.imagBlob = fetchImageURL(filters.country, p.img.split("/")[p.img.split("/").length - 1]);
			});
		});
		setSRPData(originalData);
		const vendorsList = JSON.parse(localStorage.getItem("vendors") || "{}");
		setVendorData(vendorsList);
	};

	// Set SOM unit according to filter options
	let somUnit = "";
	if (filters.somValueType === "sales") {
		somUnit = filters.country ? dataObject.currencySymbols[filters.country] : "$";
	} else if (filters.somValueType === "volume") {
		somUnit = "GR";
	} else if (filters.somValueType === "units") {
		somUnit = "Units";
	}

	return (
		<>
			{vendorData.length > 0 && <VendorsList vendors={vendorData} />}
			{vendors.length > 0 && (
				<Grid container spacing={0} minHeight={60}>
					<Grid xs display="flex" justifyContent="flex-start" alignItems="center" className="m-b-10">
						<BlackTitle fontSize={18} fontWeight={600}>
							SOM ({somUnit})
						</BlackTitle>
					</Grid>
					<Grid xs display="flex" justifyContent="center" alignItems="center" className="m-b-10">
						<Grid alignItems="center" display="flex" justifyContent="flex-end">
							<KeyboardBackspaceIcon />
						</Grid>
						<Grid alignItems="center">
							<BlackTitle fontSize={18} fontWeight={600}>
								SRP / SOP
							</BlackTitle>
						</Grid>
						<Grid alignItems="center" display="flex" justifyContent="flex-start">
							<EastRoundedIcon />
						</Grid>
					</Grid>
					<Grid xs display="flex" justifyContent="flex-end" alignItems="center" className="m-b-10">
						<BlackTitle fontSize={18} fontWeight={600}>
							SOM ({somUnit})
						</BlackTitle>
					</Grid>
					<Grid container spacing={0}>
						{SRPData.map((e, i) => {
							return (
								<Grid item xs={12} key={`price-tier-${i}`}>
									<Grid sx={{ display: "flex", width: "100%", flexDirection: "row", mb: "5px" }}>
										<Grid style={SrpSopBlk}>
											<BlackTitle sx={{ width: "60px" }}>
												<SomBlk sx={{ marginLeft: "0" }}>
													<BlackTitle sx={{ fontWeight: "600" }}>{e.sompepsico}</BlackTitle>
													{
														<BlackTitle
															sx={{
																fontWeight: "normal",
																fontSize: "12px",
																color: valueBasedOnColor(parseFloat(e.sompepsicopp.toString())),
															}}
														>
															{parseFloat(e.sompepsicopp.toString()) > 0
																? "+" + (parseFloat(e.sompepsicopp.toString()).toFixed(2) + "pp")
																: "" + (parseFloat(e.sompepsicopp.toString()).toFixed(2) + "pp")}
														</BlackTitle>
													}
												</SomBlk>
											</BlackTitle>

											<BlackTitle style={SrpSopBlueSec} className="srpLeft" flex={1}>
												<List
													style={{ ...SrpSopBlueSecLeft, flexWrap: "wrap" }}
													onDragOver={(e) => {
														onDragOver(e);
													}}
													onDrop={(e) => {
														onDrop(e, i);
													}}
												>
													{e.products.map((p, ip) => {
														if (p.ispepsico) {
															return (
																<ListItem
																	key={`pepsi-card-${ip}`}
																	style={SrpBlueList}
																	className={classes.listItem}
																	alignItems="center"
																	onDragStart={(e) => {
																		onDragStart(e, p.name, i, ip, "pepsi");
																	}}
																	draggable
																>
																	<Grid className={classes.hoverIconContainer}>
																		<CloseIcon
																			fontSize="large"
																			style={{ cursor: "pointer" }}
																			onClick={(e) => {
																				removeProduct(e, i, ip, "PEPSICO");
																			}}
																		/>
																	</Grid>
																	<HtmlTooltip
																		placement="top"
																		arrow
																		className="ToolTopText"
																		title={
																			<Grid container>
																				<Grid xs={12} style={labeSmallText} color="inherit">
																					{filters.comparisonLevel === "brandsize" ? "Brand" : "Sub Brand"} = {p.name}​
																				</Grid>
																				<Grid xs={12} style={labeSmallText} color="inherit">
																					PackSize = {p.packsize}
																				</Grid>
																				<Grid xs={12} style={labeSmallText} color="inherit">
																					SRP = ${p.srp}
																				</Grid>
																				<Grid xs={12} style={labeSmallText} color="inherit">
																					Volume = {p.gr.toFixed(2)} GR​
																				</Grid>
																				<Grid xs={12} style={labeSmallText} color="inherit">
																					PPV = ${p.ppv}
																				</Grid>
																				<Grid xs={12} style={labeSmallText} color="inherit">
																					SOM = {p.som}
																				</Grid>
																				<Grid container style={labeSmallText} color="inherit">
																					<Grid item>% change in SOM = </Grid>
																					<Grid
																						item
																						style={labeSmallText}
																						m={
																							parseFloat(p.ppvpp.toString()) > 0
																								? greenSmallText
																								: parseFloat(p.ppvpp.toString()) < 0
																								? redSmallText
																								: graySmallText
																						}
																					>
																						{p.ppvpp > 0 ? "+" + parseFloat(p.ppvpp.toString()).toFixed(2) : parseFloat(p.ppvpp.toString()).toFixed(2)}pp
																					</Grid>
																				</Grid>
																				<Grid xs={12} style={labeSmallText} color="inherit">
																					SOP = ${p.sop}
																				</Grid>
																			</Grid>
																		}
																	>
																		<Button data-rowIndex={i} data-productIndex={ip} style={{ ...toolTipButton, ...cursorMove }}>
																			<CardContent style={{ ...SrpSopImageSec, flexDirection: "column" }}>
																				{p.imagBlob ? (
																					<CardMedia draggable={false} component="img" sx={{ width: 40 }} image={p.imagBlob} />
																				) : (
																					<Typography fontSize={"8px"} style={SrpSopImageName} color="text.primery">
																						{p.name}
																					</Typography>
																				)}
																				<Typography fontSize={"8px"} style={SrpSopImageName} color="text.primery">
																					{p.packsize}
																				</Typography>
																			</CardContent>
																			<CardContent className="pro_con" style={{ ...SrpSopContentSec, flexDirection: "column" }}>
																				{figerLevels.volume && <Typography style={SrpSopContenttitle}>{p.gr.toFixed(2)} GR</Typography>}
																				{figerLevels.ppv && <Typography style={SrpSopContenttitle}>${p.ppv}</Typography>}
																				{figerLevels.somChange && (
																					<Typography
																						style={SrpSopContenttitle}
																						m={parseFloat(p.ppvpp.toString()) > 0 ? greenSmallText : parseFloat(p.ppvpp.toString()) < 0 ? redSmallText : ""}
																					>
																						{p.ppvpp > 0 ? "+" + parseFloat(p.ppvpp.toString()).toFixed(2) : parseFloat(p.ppvpp.toString()).toFixed(2)}pp
																					</Typography>
																				)}
																				{figerLevels.sop && <Typography style={SrpSopContenttitle}>${p.sop}</Typography>}
																			</CardContent>
																		</Button>
																	</HtmlTooltip>
																</ListItem>
															);
														}
													})}
												</List>
											</BlackTitle>

											<Typography component="div" className="srpSopPrice">
												<SrpSopPrice>
													{e.products.length === 0 && (
														<Grid style={{ color: "#fff", position: "absolute", top: "0", left: "3px", cursor: "pointer" }}>
															<CloseIcon style={{ width: "0.77em" }} onClick={(data) => removePriceTier(data, e.srp)} />
														</Grid>
													)}
													${e.srp}
												</SrpSopPrice>
											</Typography>

											<Typography style={SrpSopBlueSec} component="div" className="sopRight" flex={1}>
												<List
													style={{ ...SrpSopPinkSecRight, flexWrap: "wrap" }}
													onDragOver={(e) => {
														onDragOver(e);
													}}
													onDrop={(e) => {
														onDrop(e, i);
													}}
												>
													{e.products &&
														e.products.map((p, ip) => {
															if (!p.ispepsico) {
																return (
																	<ListItem
																		key={`competitor-card-${ip}`}
																		style={SopPinkList}
																		className={classes.listItem}
																		alignItems="center"
																		onDragStart={(e) => {
																			onDragStart(e, p.name, i, ip, "competitor");
																		}}
																		draggable
																	>
																		<Grid className={classes.hoverIconContainer}>
																			<CloseIcon
																				fontSize="large"
																				style={{ cursor: "pointer" }}
																				onClick={(e) => {
																					removeProduct(e, i, ip, "COMPETITOR");
																				}}
																			/>
																		</Grid>
																		<HtmlTooltip
																			placement="top"
																			arrow
																			className="ToolTopText"
																			title={
																				<Grid container>
																					<Grid xs={12} style={labeSmallText} color="inherit">
																						{filters.comparisonLevel === "brandsize" ? "Brand" : "Sub Brand"} = {p.name}​
																					</Grid>
																					<Grid xs={12} style={labeSmallText} color="inherit">
																						PackSize = {p.packsize}
																					</Grid>
																					<Grid xs={12} style={labeSmallText} color="inherit">
																						Volume = {p.gr.toFixed(2)} GR​
																					</Grid>
																					<Grid xs={12} style={labeSmallText} color="inherit">
																						PPV = ${p.ppv}
																					</Grid>
																					<Grid xs={12} style={labeSmallText} color="inherit">
																						SOM = {p.som}
																					</Grid>
																					<Grid container style={labeSmallText} color="inherit">
																						<Grid item>% change in SOM = </Grid>
																						<Grid
																							item
																							style={labeSmallText}
																							m={
																								parseFloat(p.ppvpp.toString()) > 0
																									? greenSmallText
																									: parseFloat(p.ppvpp.toString()) < 0
																									? redSmallText
																									: graySmallText
																							}
																						>
																							{p.ppvpp > 0 ? "+" + parseFloat(p.ppvpp.toString()).toFixed(2) : parseFloat(p.ppvpp.toString()).toFixed(2)}pp
																						</Grid>
																						​
																					</Grid>
																					<Grid xs={12} style={labeSmallText} color="inherit">
																						SOP = ${p.sop}
																					</Grid>
																				</Grid>
																			}
																		>
																			<Button style={{ ...toolTipButton, ...cursorMove }}>
																				<CardContent style={{ ...SrpSopImageSec, flexDirection: "column" }}>
																					<Typography color="text.primery">
																						{p.imagBlob ? (
																							<CardMedia
																								draggable={false}
																								component="img"
																								sx={{ width: 40 }}
																								image={p.imagBlob}
																								onError={(event) => handleMissingImages(event)}
																							/>
																						) : (
																							<Typography fontSize={"8px"} style={SrpSopImageName} color="text.primery">
																								{p.name}
																							</Typography>
																						)}

																						<Typography fontSize={"8px"} style={SrpSopImageName} color="text.primery">
																							{p.packsize}
																						</Typography>
																					</Typography>
																				</CardContent>
																				<CardContent className="pro_con" style={{ ...SrpSopContentSec, flexDirection: "column" }}>
																					{figerLevels.volume && <Typography style={SrpSopContenttitle}>{p.gr.toFixed(2)} GR</Typography>}
																					{figerLevels.ppv && <Typography style={SrpSopContenttitle}>${p.ppv}</Typography>}
																					{figerLevels.somChange && (
																						<Typography
																							style={SrpSopContenttitle}
																							m={parseFloat(p.ppvpp.toString()) > 0 ? greenSmallText : parseFloat(p.ppvpp.toString()) < 0 ? redSmallText : ""}
																						>
																							{p.ppvpp > 0 ? "+" + parseFloat(p.ppvpp.toString()).toFixed(2) : parseFloat(p.ppvpp.toString()).toFixed(2)}pp
																						</Typography>
																					)}
																					{figerLevels.sop && <Typography style={SrpSopContenttitle}>${p.sop}</Typography>}
																				</CardContent>
																			</Button>
																		</HtmlTooltip>
																	</ListItem>
																);
															}
														})}
												</List>
											</Typography>

											<BlackTitle sx={{ width: "60px" }}>
												<SomBlk sx={{ marginRight: "0" }}>
													<BlackTitle sx={{ fontWeight: "600" }}>{e.somcompetitor}</BlackTitle>
													{
														<BlackTitle
															sx={{
																fontWeight: "normal",
																fontSize: "12px",
																color: valueBasedOnColor(parseFloat(e.somcompetitorpp.toString())),
															}}
														>
															{parseFloat(e.somcompetitorpp.toString()) > 0
																? "+" + (parseFloat(e.somcompetitorpp.toString()).toFixed(2) + "pp")
																: "" + (parseFloat(e.somcompetitorpp.toString()).toFixed(2) + "pp")}
														</BlackTitle>
													}
												</SomBlk>
											</BlackTitle>
										</Grid>
									</Grid>
								</Grid>
							);
						})}
					</Grid>

					{/* Add button section start */}
					<Grid item xs={12} display="flex" justifyContent="center">
						<Box sx={{ "& > :not(style)": { m: 1 } }}>
							<Fab color="secondary" aria-label="add" onClick={handleOpenNew}>
								<AddIcon />
							</Fab>
						</Box>
						<Modal open={openNew} onClose={handleCloseNew} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
							<Box m={styleNew} className="modal" height={"inherit"}>
								<BlackTitle display={"flex"} justifyContent={"space-between"}>
									<BlackTitle id="modal-modal-title" variant="h5">
										Add New Price Tier
									</BlackTitle>
									<IconButton aria-label="Close" onClick={handleCloseNew}>
										<CloseIcon />
									</IconButton>
								</BlackTitle>
								<BlackTitle className="modal-body" id="modal-modal-description" sx={{ mt: 1 }}>
									<BlackTitle>
										<TextField
											id="outlined-number"
											placeholder="SRP/SOP"
											onChange={(e) => setNewPriceTierValue(e.target.value)}
											value={newPriceTierValue}
											type="number"
											InputLabelProps={{ shrink: true }}
										/>
									</BlackTitle>
									<BlackTitle sx={{ color: "#fb1111" }}>{priceTierError}</BlackTitle>
									<BlackTitle mt={1}>
										<PrimaryBtn onClick={addNewPriceTier}>Add</PrimaryBtn>
									</BlackTitle>
								</BlackTitle>
							</Box>
						</Modal>
					</Grid>
					{/* Add button section end */}

					{/* Checkbox section start */}
					<Grid item xs={12} display="flex" justifyContent="center">
						<FormControl component="fieldset">
							<FormGroup aria-label="position" row>
								<FormControlLabel
									className="checkbox_icon"
									value="volume"
									control={
										<Checkbox
											onChange={(e) => {
												handleLevels(e);
											}}
											color="primary"
											defaultChecked
										/>
									}
									label={<span style={{ color: "black" }}>Volume</span>}
									labelPlacement="end"
								/>

								<FormControlLabel
									className="checkbox_icon"
									value="ppv"
									control={
										<Checkbox
											onChange={(e) => {
												handleLevels(e);
											}}
											defaultChecked
										/>
									}
									label={<span style={{ color: "black" }}>PPV</span>}
									labelPlacement="end"
								/>

								<FormControlLabel
									className="checkbox_icon"
									value="somChange"
									control={
										<Checkbox
											onChange={(e) => {
												handleLevels(e);
											}}
											defaultChecked
										/>
									}
									label={<span style={{ color: "black" }}>% change in SOM</span>}
									labelPlacement="end"
								/>

								<FormControlLabel
									className="checkbox_icon"
									value="sop"
									control={
										<Checkbox
											onChange={(e) => {
												handleLevels(e);
											}}
											defaultChecked
										/>
									}
									label={<span style={{ color: "black" }}>SOP</span>}
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>
					</Grid>
					{/* Checkbox section end */}

					<Grid item xs={12} display="flex" justifyContent="flex-end">
						<Stack direction="row" spacing={1}>
							<OrangeBtn onClick={resetChanges}>Reset Changes</OrangeBtn>
						</Stack>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default PriceLadderSRP;
