import { DIALOG } from "./constants";

export const dialog = (msg: string, title: string, isError: boolean, open?: boolean) => {
	return {
		type: DIALOG,
		payload: {
			msg,
			title,
			open,
			isError,
		},
	};
};
