import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../../../../../components/CustomTable/CustomTable.Styled";
import { formatNumber } from "../../../../../../util/helper";

const RegionElasticitiesTable: React.FC<{
	data;
}> = ({ data }) => {
	const [headings, setHeadings] = useState<any>([]);
	const [tableData, setTableData] = useState<any>([]);

	useEffect(() => {
		if (data) {
			setHeadings(["Regions", "Mix Sales", `Real Elasticities`, "R2", "PVALUE", "MAPE", "Model Type *"]);
			const regionsData: Array<any> = data.regionCalculation?.map((item) => {
				return {
					region: item.region,
					mix: `${formatNumber(item.mix * 100)}%`,
					elasticity: item.elasticity,
					r2Value: item.r2Value,
					pValue: item.pValue,
					mapeValue: item.mapeValue,
					modelType: item.modelType,
				};
			});
			if (regionsData.length > 0) {
				const weightedAvg = {};
				const orderedWeightedAvg = {
					title: data.weightedAvg["title"],
					mixSales: data.weightedAvg["mixSales"],
					elasticity: data.weightedAvg["elasticity"],
					r2Value: data.weightedAvg["r2Value"],
					pValue: data.weightedAvg["pValue"],
					mapeValue: data.weightedAvg["mapeValue"],
				};
				Object.entries(orderedWeightedAvg).map(([key, value]) => {
					weightedAvg[key] = {
						value,
						colorCode: "#cec8c8",
					};
				});
				setTableData([
					...regionsData,
					{
						region: null,
						mix: null,
						elasticity: null,
						r2Value: null,
						pValue: null,
						mapeValue: null,
						modelType: null,
					},
					weightedAvg,
				]);
			}
		}
	}, [data]);

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{headings &&
							headings.map((mHead, i) => (
								<TableCell
									style={{
										color: "white",
										textAlign: "center",
										backgroundColor: "#005ea6",
									}}
									className="b-color"
									align="center"
									width="50"
									key={`table-header-cell-${i}`}
								>
									{mHead}
								</TableCell>
							))}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableData.map((row, i) => (
						<TableRow key={`table-row-${i}`} data-testid="tableBodyRows">
							{Object.keys(row).map((key, j) => {
								let cellData = row[key];
								return (
									<StyledTableCell
										style={{
											backgroundColor: _.isObject(cellData) && cellData.colorCode,
											...(cellData === null ? { borderWidth: "0px" } : {}),
										}}
										align="center"
										key={`table-row-${i}-cell-${j}`}
									>
										{_.isObject(cellData)
											? _.isNumber(cellData.value)
												? formatNumber(cellData.value)
												: cellData.value
											: _.isNumber(cellData)
											? formatNumber(cellData)
											: cellData}
									</StyledTableCell>
								);
							})}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default RegionElasticitiesTable;
