import { GET_COUNTRIES } from "../actions/constants";

let dasboardInitialState = {
	data: [],
};

const CountryList = (state = dasboardInitialState, action) => {
	if (action.type === GET_COUNTRIES) {
		return {
			...state,
			data: action.payload,
		};
	} else {
		return state;
	}
};
export default CountryList;
