import { baseApi, graphqlAPI } from "../../store/api-constants";
import { API } from "../../util/helper";
import { BRAND_LADDER_MAXDATE_QUERY } from "../../util/queries/brandLadder";

export const fetchBrandLadderAndTableData = async (data) => {
	try {
		const baseApiUrl: string = await baseApi();
		return await API.post(`${baseApiUrl}dashboards/brandladder/getbranddata`, data);
	} catch (e) {
		return e;
	}
};

/* Creates a GraphQL API to get max date for brand ladder dashboard.
 * @param {any} data - The object contains filter request payload object.
 * @returns The brand ladder max date.
 */
export const fetchFilterMonthData = async (data) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		data.category = data.category !== null ? '"' + data.category.join('", "') + '"' : null;
		data.geoLevel = data.geoLevel !== null ? '"' + data.geoLevel.join('", "') + '"' : null;
		data.geoLevel2 = data.geoLevel2 !== null ? '"' + data.geoLevel2.join('", "') + '"' : null;
		let queryParameter = BRAND_LADDER_MAXDATE_QUERY(data);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getBrandLadderMaxDate;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};
