import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Grid } from "@mui/material";

import { StyledCard } from "../../MMPW.Styled";
import { Title, PrimaryBtn, BlackTextBtn } from "../../../../styles/Common.Styled";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

const useStyles = makeStyles(() => ({
	blackTitle: { color: "#000 !important" },
	extraLargeIcon: { fontSize: "50px !important" },
}));

const UploadIconPicker: React.FC<{ callback? }> = ({ callback }) => {
	const classes = useStyles();
	const inputFile = useRef(null);
	const [selectedIcon, setSelectedIcon] = useState(null);
	const id = `custom-icon-${Math.floor(Math.random() * 31) + 50}`;
	const onBrowseClick = () => {
		inputFile.current.click();
	};

	const onUploadImage = (event) => {
		if (event.target.files && event.target.files[0]) {
			setSelectedIcon(event.target.files[0]);
		}
	};

	const onAddClick = async () => {
		if (selectedIcon) {
			callback({ type: "CustomIcon", id, file: selectedIcon, height: 40, width: 40 });
		}
	};

	const CustomIcon = (props) => (
		<svg id={props.id} xmlns="http://www.w3.org/2000/svg" width="80" viewBox="0 0 40 38">
			<image height="40" width="40" href={URL.createObjectURL(props.icon)} />
		</svg>
	);

	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid container>
						<Title className={classes.blackTitle}>Upload Icon</Title>
					</Grid>
					<Grid container>
						{!selectedIcon ? <InsertDriveFileOutlinedIcon className={classes.extraLargeIcon} /> : <CustomIcon id={id} icon={selectedIcon} />}
					</Grid>
					{!selectedIcon ? (
						<Grid item xs={12}>
							<PrimaryBtn variant="contained" onClick={onBrowseClick}>
								Upload Image
								<FileUploadOutlinedIcon fontSize="small" />
							</PrimaryBtn>
							<input onChange={onUploadImage} type="file" accept="image/svg+xml" hidden ref={inputFile} />
						</Grid>
					) : (
						<Grid item xs={12}>
							<PrimaryBtn variant="contained" onClick={onAddClick} className="m-r-10">
								Add
							</PrimaryBtn>
							<BlackTextBtn
								className="disable"
								onClick={() => {
									callback();
								}}
							>
								Cancel
							</BlackTextBtn>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</StyledCard>
	);
};

export default UploadIconPicker;
