import React, { useEffect, useRef, useState } from "react";
import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "../../../../components/Dashboard";
import dataObject from "../../../../mocks/apiPredictiveDashboard";
import { loader } from "../../../../store/actions/common.action";
import { toast } from "../../../../store/actions/toast.action";
import { GraphLabel, NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import { setCorrelationColor } from "../../../../util/helper";
import { correlationGraphConfig } from "../../chartsConfig";
import { getCorrelationChartData, getCorrelationTableData } from "../../service";
import CorrelationTable from "../CorrelationTable";
import HighchartContainer from "../../../../components/HighchartContainer";
import IncrementDecrement from "../../../../components/IncrementDecrement";
import { correlationChartDataLoader, correlationTableDataLoader } from "../../store/apiPredictive.action";

const defaultPeriodicity = "weekly";
const visualId = "api-predictive-dash-correlation-chart-container";
const CorrelationChart: React.FC<{ filters }> = ({ filters }) => {
	const dispatch = useDispatch();
	const chartRef = useRef<any>(null);
	const [correlation, setCorrelation] = useState(0);
	const [correlationTableSkeleton, setCorrelationTableSkeleton] = useState(true);
	const [correlationChartSkeleton, setCorrelationChartSkeleton] = useState(true);
	const [originalCorrelationTableResponse, setOriginalCorrelationTableResponse] = useState<any>({});
	const [correlationTableData, setCorrelationTableData] = useState<any>([]);
	const [originalCorrelationChartResponse, setOriginalCorrelationChartResponse] = useState<any>({});
	const [correlationChartData, setCorrelationChartData] = useState<any>({});
	const [correlationPepsiTableData, setCorrelationPepsiTableData] = useState(dataObject.correlationPepsiCoTable);
	const [correlationAnchorTableData, setCorrelationAnchorTableData] = useState(dataObject.correlationAnchorTable);
	const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
	const correlationReduxState = useSelector((state: any) => state.ApiPredictive);

	const setCorrelationTitle = (filters) => {
		if (filters.apiUnits && filters.variable1) {
			const apiConfig = filters.apiUnits === "Volume" ? "Volume" : "Unit";
			return "Correlation API Price per " + apiConfig + " vs " + filters.variable1;
		} else return "Correlation Details";
	};

	const setLabels = (chart) => {
		if (filters && filters.variable1) {
			chart.yAxis[0].setTitle({ text: filters.variable1 });
			chart.yAxis[1].setTitle({ text: "API" });
			chart.legend.allItems[0].update({ name: filters.variable1 });
			chart.legend.allItems[1].update({ name: "API" });
			chart.legend.allItems[2].update({ name: "API Max" });
			chart.legend.allItems[3].update({ name: "API Avg" });
			chart.legend.allItems[4].update({ name: "API Min" });
		}
	};

	const addPlotLines = (chart, apiData) => {
		const apiMax = Math.max(...apiData.map((o) => o.value));
		const apiAvg = apiData.reduce((partialSum, o) => partialSum + o.value, 0) / apiData.length || 0;
		const apiMin = Math.min(...apiData.map((o) => o.value));

		chart.series[2].setData(apiData.map(() => apiMax));
		chart.series[3].setData(apiData.map(() => apiAvg));
		chart.series[4].setData(apiData.map(() => apiMin));
	};

	const formatCorrelationChartData = (data) => {
		if (data.variable1 && data.variable2) {
			setCorrelation(Math.round(data.correlation));
			const dates = data.variable1.map((item) => item.date);
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;

				setLabels(chart);
				chart.xAxis[0].setCategories(dates);
				chart.series[0].setData(data.variable1.map((item) => item.value));
				chart.series[1].setData(data.variable2.map((item) => item.value));
				addPlotLines(chart, data.variable2);
			}
		}
	};

	const formatCorrelationTableData = (data, tableData) => {
		tableData.map((p) => {
			p.values = [];
		});
		if (data && _.isArray(data) && data.length > 0) {
			data.map((item) => {
				let colSpan = 0;
				item.packSizeData.map((subItem) => {
					colSpan += _.isArray(subItem.grammage) ? subItem.grammage.length : 0;
					tableData[1].values.push({
						filed: subItem.name,
						colSpan: _.isArray(subItem.grammage) ? subItem.grammage.length : 0,
					});
					tableData[2].values = [...tableData[2].values, ...subItem.grammage.map((g) => ({ filed: g }))];
					tableData[3].values = [...tableData[3].values, ...subItem.grammagePercentage.map((g) => ({ filed: g }))];
				});
				tableData[0].values.push({
					filed: item.dateString, //dataFormat(item.date, "MMM/yy"),
					colSpan,
				});
			});
		}
		return tableData;
	};

	const onChangePeriodicity = (data) => {
		if (data.item && !_.isEmpty(originalCorrelationChartResponse)) {
			setCorrelationChartData(originalCorrelationChartResponse[data.item]);
		}
		if (data.item && !_.isEmpty(originalCorrelationTableResponse)) {
			const payload = {
				pepsico: originalCorrelationTableResponse.pepsico[data.item],
				anchor: originalCorrelationTableResponse.anchor[data.item],
				avg: originalCorrelationTableResponse.avg[data.item],
			};
			setCorrelationTableData(payload);
		}
	};

	const resetData = () => {
		setCorrelation(0);
		setCorrelationTableSkeleton(true);
		setCorrelationChartSkeleton(true);
		setOriginalCorrelationTableResponse({});
		setCorrelationTableData([]);
		setOriginalCorrelationChartResponse({});
		setCorrelationChartData({});
		setCorrelationPepsiTableData(dataObject.correlationPepsiCoTable);
		setCorrelationAnchorTableData(dataObject.correlationAnchorTable);
	};

	useEffect(() => {
		if (correlationTableData && Object.keys(correlationTableData).length) {
			const pepsiCorrelationTable = formatCorrelationTableData(correlationTableData.pepsico, [...correlationPepsiTableData]);
			setCorrelationPepsiTableData(pepsiCorrelationTable);
			const anchorCorrelationTable = formatCorrelationTableData(correlationTableData.anchor, [...correlationAnchorTableData]);
			setCorrelationAnchorTableData(anchorCorrelationTable);
		}
	}, [correlationTableData]);

	useEffect(() => {
		if (correlationChartData?.variable1) {
			formatCorrelationChartData(correlationChartData);
		}
	}, [correlationChartData]);

	useEffect(() => {
		if (filters && filters.country) {
			dispatch(correlationChartDataLoader(true));
			resetData();
			getCorrelationChartData(filters)
				.then((response) => {
					setCorrelationChartSkeleton(false);
					if (response && response.data) {
						setOriginalCorrelationChartResponse(response.data);
						setCorrelationChartSkeleton(false);
						setCorrelationChartData(response.data[defaultPeriodicity]);
					}
					if (response && response.error) {
						dispatch(toast("somcorrelation: " + response.error, true, 2000, "error"));
					}
					dispatch(correlationChartDataLoader(false));
				})
				.catch((e) => {
					setCorrelationChartSkeleton(false);
					dispatch(correlationChartDataLoader(false));
				});
			dispatch(correlationTableDataLoader(true));
			getCorrelationTableData(filters)
				.then((response) => {
					setCorrelationTableSkeleton(false);
					if (response && response.data) {
						setOriginalCorrelationTableResponse(response.data);
						const payload = {
							pepsico: response.data.pepsico[defaultPeriodicity],
							anchor: response.data.anchor[defaultPeriodicity],
							avg: response.data.avg[defaultPeriodicity],
						};
						setCorrelationTableData(payload);
					} else if (response && response.error) {
						dispatch(toast("somCorrelationDataTable: " + response.error, true, 2000, "error"));
					}
					dispatch(correlationTableDataLoader(false));
				})
				.catch((e) => {
					dispatch(correlationTableDataLoader(false));
					setCorrelationTableSkeleton(false);
					dispatch(loader(false));
				});
		}
	}, [filters]);

	return (
		<Card className="m-b-20">
			<CardContent>
				<Dashboard title={setCorrelationTitle(filters)} showSkeleton={correlationChartSkeleton} chartRef={chartRef} id={visualId}>
					{correlationChartSkeleton ? (
						<>
							<Grid item xs={12}>
								<Skeleton variant="rectangular" height={350} />
							</Grid>
							<Grid container justifyContent="center" className="m-b-20">
								<Skeleton variant="rectangular" width={150} height={40} className="m-t-20" />
							</Grid>
						</>
					) : !_.isEmpty(correlationChartData) && !_.isEmpty(correlationChartData.variable1) && !_.isEmpty(correlationChartData.variable2) ? (
						<>
							<HighchartContainer chartRef={chartRef} id={visualId}>
								<HighchartsReact highcharts={Highcharts} options={correlationGraphConfig} ref={chartRef} />
								<Grid style={{ position: "relative", bottom: 50, left: 30, zIndex: 2, width: "100%" }}>
									<IncrementDecrement
										callback={onChangePeriodicity}
										show={!correlationReduxState.correlationChartDataLoader && !correlationReduxState.correlationTableDataLoader}
										id={visualId}
										defaultOption={
											(localStorage.getItem("periodicity") &&
												JSON.parse(localStorage.getItem("periodicity") || "{}")[visualId] &&
												JSON.parse(localStorage.getItem("periodicity") || "{}")[visualId]?.item) ||
											null
										}
									/>
								</Grid>
							</HighchartContainer>

							<Grid container justifyContent="center" className="m-b-20">
								<GraphLabel {...setCorrelationColor(correlation)}>Correlation {correlation}%</GraphLabel>
							</Grid>
						</>
					) : (
						<Box style={{ height: 400 }} display="flex" justifyContent="center" alignItems="center">
							<NoData>{messages.noData}</NoData>
						</Box>
					)}
					{correlationTableSkeleton ||
					(!correlationTableSkeleton && correlationTableData && correlationTableData.anchor && correlationTableData.pepsico && correlationTableData.avg) ? (
						<Grid
							container
							style={{
								minWidth: "200px",
								maxWidth: sidebarOpen ? "78vw" : "89vw",
							}}
						>
							<Grid item xs={12} className="m-b-20">
								<CorrelationTable data={correlationPepsiTableData} showSkeleton={correlationTableSkeleton} />
							</Grid>
							<Grid item xs={12}>
								<CorrelationTable data={correlationAnchorTableData} showSkeleton={correlationTableSkeleton} isFormatNumber={false} />
							</Grid>
						</Grid>
					) : (
						""
					)}
				</Dashboard>
			</CardContent>
		</Card>
	);
};

export default CorrelationChart;
