import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

function getActualKeyValue(object) {
	let entries = Object.keys(object);
	if (entries.indexOf("total") === -1) {
		return parseFloat(object);
	} else {
		return parseFloat(object.total);
	}
}

function getMaxGraphWidth(data) {
	let entries = Object.keys(data);
	let maxWidth = 0;
	entries.map((item) => {
		const value = getActualKeyValue(data[item]);
		if (maxWidth < value) {
			maxWidth = value;
		}
	});
	return maxWidth;
}

const TooltipContent: FC<{ data; color; category; subCategory; fontSize }> = ({ data, color, category, subCategory, fontSize }) => {
	const entries = Object.keys(data);
	const maxWidth = getMaxGraphWidth(data);
	const fontSizeValue = fontSize ?? "12px";
	return (
		<Grid container spacing={2} justifyContent="space-between" style={{ width: "auto" }}>
			<Grid item xs={12} sm={12}>
				<Typography fontSize={fontSizeValue} fontWeight={600}>
					{category}
				</Typography>
				<Typography fontSize={fontSizeValue}>{subCategory}</Typography>
				<Grid item xs={12} sm={12}>
					<Stack direction={"row"}>
						<Stack direction={"column"}>
							{entries.map((dataItem) => {
								return (
									<Grid item xs={8} sm={8} sx={{ padding: "2px 5px 2px 0" }}>
										<Typography fontSize={fontSizeValue} sx={{ textWrap: "nowrap" }}>
											{dataItem}
										</Typography>
									</Grid>
								);
							})}
						</Stack>
						<Stack direction={"column"}>
							{entries.map((dataItem) => {
								return (
									<Grid item xs={12} sm={12} sx={{ padding: "2px 0" }}>
										<Stack direction={"row"}>
											<Box sx={{ color: color, backgroundColor: color, width: `${(getActualKeyValue(data[dataItem]) / maxWidth) * 100}px` }}>{}</Box>
											<Typography fontSize={fontSizeValue} paddingLeft="5px" paddingRight="5px" fontWeight={600}>
												{getActualKeyValue(data[dataItem]).toFixed(1)}%
											</Typography>
										</Stack>
									</Grid>
								);
							})}
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default TooltipContent;
