import { formatNumber } from "../../../../../../util/helper";

export const columnChartConfig = {
	chart: {
		type: "column",
	},
	title: {
		align: "left",
		text: "",
	},
	data: {
		table: "datatable",
	},
	exporting: {
		enabled: false,
	},
	xAxis: {
		categories: [],
	},
	yAxis: {
		title: {
			enabled: false,
		},
		labels: {
			formatter: function () {
				return `${this.value}%`;
			},
		},
	},
	credits: {
		enabled: false,
	},
	legend: {
		symbolRadius: 3,
		symbolHeight: 15,
		align: "center",
		verticalAlign: "top",
	},
	series: [
		{
			name: "Real",
			color: "#0093CD",
			data: [],
			tooltip: {
				pointFormatter: function () {
					return `${formatNumber(this.y)}%`;
				},
			},
		},
		{
			name: "Expected",
			color: " #FC9E66",
			data: [],
			tooltip: {
				pointFormatter: function () {
					return `${formatNumber(this.y)}%`;
				},
			},
		},
	],
};
