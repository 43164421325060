import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import NotificationListCard from "../NotificationListCard";
import { INotificationCardType } from "../../../../types/common";
import { CstmBtn } from "../../AlertAndNotification.Styled";
import { NotificationTitle } from "./NotificationsListContainer.Styled";
import dataObject from "../../../../mocks/alertAndNotification";
import { CreateNotificationSubtitle } from "../CreateNotification/CreateNotification.Styled";
import Dropdown from "../../../../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "../../../../store/actions/AlertAndNotification/getNotification.action";
import { loader } from "../../../../store/actions/common.action";
import { deleteNotification } from "../../../../store/actions/AlertAndNotification/deleteNotification.action";
import { dissmissNotification } from "../../../../store/actions/AlertAndNotification/dismissNotification.action";

const NotificationsListContainer: React.FC<{ callback }> = ({ callback }) => {
	const dispatch = useDispatch();
	const userDetail = useSelector((state: any) => state.User.data);
	const [notifications, setNotifications] = useState<INotificationCardType[]>([]);
	const [selectedSort, setSelectedSort] = useState(3); //setting default sort as Newest
	const notificationData = useSelector((state: any) => state.GetAllNotification);
	const { sortNotification } = dataObject;
	const createNotification = useSelector((state: any) => state.CreateNotification);
	const deleteNotificationData = useSelector((state: any) => state.DeleteNotification);
	const dismisssNotification = useSelector((state: any) => state.DismissNotification);

	const loadMore = (notifications: INotificationCardType[]) => {
		const allNotifications: INotificationCardType[] = notificationData.data || [];
		if (allNotifications.length) {
			const filterNotification = allNotifications.splice(0, 10);
			setNotifications([...notifications, ...filterNotification]);
		}
	};

	const dropdownChangeHandler = (value) => {
		const sortOrder: number = value[0];
		setSelectedSort(sortOrder);
		dispatch(fetchNotifications(userDetail.id, undefined, sortOrder));
	};

	const onNotificationAction = (payload) => {
		if (payload.type === "edit") {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			callback({ ...payload.item });
		} else if (payload.type === "delete") {
			dispatch(loader(true));
			dispatch(deleteNotification(payload.item));
		} else if (payload.type === "markAsRead") {
			dispatch(loader(true));
			dispatch(dissmissNotification(userDetail.id, payload.item.id));
		}
	};

	useEffect(() => {
		if (userDetail && userDetail.id) {
			dispatch(loader(true));
			dispatch(fetchNotifications(userDetail.id, undefined, selectedSort));
		}
	}, [dismisssNotification, createNotification, userDetail]);

	useEffect(() => {
		if (deleteNotificationData && deleteNotificationData.data) {
			dispatch(loader(false));
			const newNotifications = notifications.filter((x) => x.id !== deleteNotificationData.data.id);
			setNotifications(newNotifications);
		} else if (deleteNotificationData && deleteNotificationData.error) {
			dispatch(loader(false));
		}
	}, [deleteNotificationData]);

	useEffect(() => {
		if (notificationData && notificationData.data) {
			loadMore([]);
			dispatch(loader(false));
		} else if (notificationData.error) {
			dispatch(loader(false));
		}
	}, [notificationData]);

	return (
		<div className="m-b-40">
			<Grid container justifyContent="space-between" alignItems="center" className="m-b-20">
				<Grid>
					<NotificationTitle>All Notifications</NotificationTitle>
				</Grid>
				<Grid item>
					<Grid container display="flex" alignItems="center">
						<Grid item className="m-r-20">
							<CreateNotificationSubtitle>Sorting Notification</CreateNotificationSubtitle>
						</Grid>
						<Grid item width={200}>
							<Dropdown
								data={sortNotification}
								sort={{ enable: false }}
								keyText="sort-notification"
								defaultOption={sortNotification[2].value}
								onChange={dropdownChangeHandler}
								search={{ enable: false }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{notifications.map((item: INotificationCardType, i: number) => (
				<NotificationListCard key={`notification-list-card-${i}`} data={item} callback={(e) => onNotificationAction({ ...e, item })} />
			))}
			{notificationData.data && notificationData.data.length > 0 && (
				<Box display="flex" justifyContent="flex-start" alignItems="flex-start">
					<CstmBtn onClick={() => loadMore(notifications)}>Load More</CstmBtn>
				</Box>
			)}
		</div>
	);
};

export default NotificationsListContainer;
