import { styled } from "@mui/material/styles";
import { Box, Button, TableRow, Typography, TypographyProps } from "@mui/material";

const Title = styled(Typography)(() => ({
	fontSize: 24,
	fontWeight: 600,
}));
const Icon = styled("img")(() => ({
	width: 35,
	cursor: "pointer",
	marginLeft: 10,
}));
const BlackInputTitle = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	color: theme.palette.common.black,
}));
const PrimaryBtn = styled(Button)`
	background: #005ea6;
	color: #fff;
	&:hover {
		color: #fff;
		background-color: #005ea6;
	}
`;
const OrangeBtn = styled(Button)`
	background: #f77b36;
	color: #fff;
	&:hover {
		color: #fff;
		background-color: grey;
	}
`;

const ThemeBtn = styled(Button)(({ theme }) => ({
	background: theme.palette.secondary.main,
	color: theme.palette.primary.main,
	"&:hover": {
		color: theme.palette.primary.main,
		background: theme.palette.secondary.main,
		fontWeight: 600,
	},
	"&.Mui-disabled": {
		color: "#FF0000",
	},
}));
const BlackTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.common.black,
}));
const DarkGreyTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.common.darkGrey,
}));
interface IGraphLabel extends TypographyProps {
	color?: string;
	bgcolor?: string;
	padding?: string;
}
const GraphLabel = styled(Typography)<IGraphLabel>`
	padding: ${(props) => props.padding || "8px"};
	color: ${(props) => props.color};
	fontsize: "12px";
	backgroundcolor: ${(props) => props.bgcolor};
`;
const BlackTextBtn = styled(Button)(({ theme }) => ({
	textTransform: "inherit",
	fontSize: 14,
	color: theme.palette.common.black,
}));
const CloseButton = styled(Box)`
	font-size: 12px;
	border-radius: 50%;
	background: #fff;
	color: #858c94;
	border: 1px solid #858c94;
	text-align: center;
	position: absolute;
	right: -5px;
	top: -5px;
	cursor: pointer;
`;
const NoData = styled(Typography)(({ theme }) => ({
	fontSize: 20,
	fontWeight: 500,
	color: theme.palette.common.black,
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(even)": {
		backgroundColor: theme.palette.action.hover,
	},
}));

export {
	Icon,
	BlackInputTitle,
	Title,
	PrimaryBtn,
	ThemeBtn,
	BlackTitle,
	DarkGreyTitle,
	GraphLabel,
	OrangeBtn,
	BlackTextBtn,
	CloseButton,
	NoData,
	StyledTableRow,
};
