import { GridColDef } from "@mui/x-data-grid";
import { formatNumber } from "../../../../util/helper";

const filterableColumns = ["segments", "brand", "subBrand", "packSize", "sku", "flavor", "checkoutSku"];

const sortableColumns = [
	"avgStorePenetration",
	"mixScore",
	"volumeMixResult",
	"revenueMixResult",
	"profitMixResult",
	"revenuMix",
	"assortmentOverall",
	"netRevenueSOP",
	"nonPBT_SOP",
	"priceScore",
	"shelfAndMerchandizingScore",
	"executionScore",
	"mustHaveDistributionScore",
	"goodToHaveDistributionScore",
	"priceChangeVsRestOfTheMarketScore",
	"netPriceRealisationScore",
	"priceChangeVsInflationScore",
	"exhibitionScore",
	"shelfSpaceScore",
];

// const tableColumns: GridColDef[] = [
// 	{
// 		headerName: "Segment",
// 		field: "segment",
// 		width: 150,
// 		align: "center",
// 		filterable: true,
// 		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
// 	},
// 	{
// 		headerName: "Brand",
// 		field: "brand",
// 		width: 130,
// 		align: "center",
// 		filterable: true,
// 		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
// 	},
// 	{
// 		headerName: "Sub Brand",
// 		field: "subBrand",
// 		width: 250,
// 		align: "center",
// 		filterable: true,
// 		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
// 	},
// 	{
// 		headerName: "Flavor",
// 		field: "flavor",
// 		width: 180,
// 		align: "center",
// 		filterable: true,
// 		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
// 	},
// 	{
// 		headerName: "Pack Size",
// 		field: "packSize",
// 		width: 180,
// 		align: "center",
// 		filterable: true,
// 		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
// 	},
// 	{
// 		headerName: "SKU",
// 		field: "sku",
// 		width: 450,
// 		align: "center",
// 		filterable: true,
// 		renderCell: (params) => <div style={{ whiteSpace: "break-spaces", textAlign: "center" }}>{params.value}</div>,
// 	},
// 	{
// 		headerName: "Checkout SKU",
// 		field: "checkoutSku",
// 		width: 190,
// 		align: "center",
// 		filterable: true,
// 		renderCell: (params) => <div style={{ whiteSpace: "normal", textAlign: "center" }}>{params.value}</div>,
// 	},
// 	{
// 		headerName: "Revenue Mix",
// 		field: "revenuMix",
// 		width: 120,
// 		filterable: true,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(0) : "0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "SOP Revenue",
// 		field: "netRevenueSOP",
// 		width: 210,
// 		align: "center",
// 		filterable: true,
// 	},

// 	{
// 		headerName: `SOP NOPBT`,
// 		field: "nonPBT_SOP",
// 		width: 260,
// 		valueFormatter: (params) => {
// 			return params.value ? `${params.value.toFixed(1)} %` : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Penetration (Distribution)",
// 		field: "avgStorePenetration",
// 		width: 240,
// 		valueFormatter: (params) => {
// 			return params.value ? `${params.value.toFixed(1)} %` : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Execution Score",
// 		field: "executionScore",
// 		width: 230,
// 		valueFormatter: (params) => {
// 			return params.value ? `${params.value.toFixed(1)} %` : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Must have distribution score",
// 		field: "mustHaveDistributionScore",
// 		width: 140,
// 		valueFormatter: (params) => {
// 			return params.value ? `${params.value.toFixed(1)} %` : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Good to have distribution score",
// 		field: "goodToHaveDistributionScore",
// 		width: 140,
// 		valueFormatter: (params) => {
// 			return params.value ? `${params.value.toFixed(1)} %` : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Assortment Score",
// 		field: "assortmentOverall",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Volume Score",
// 		field: "mixScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Revenue Score",
// 		field: "revenueMixResult",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "NOPBT score",
// 		field: "profitMixResult",
// 		width: 160,
// 		valueFormatter: (params) => {
// 			return params.value ? `${params.value.toFixed(1)} %` : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Mix Score",
// 		field: "mixScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Net price realisation score",
// 		field: "netPriceRealisationScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Price change Vs Rest of the market score",
// 		field: "priceChangeVsRestOfTheMarketScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Price change Vs Inflation score",
// 		field: "priceChangeVsInflationScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Price score",
// 		field: "priceScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Exhibition score",
// 		field: "exhibitionScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Shelf space score",
// 		field: "shelfSpaceScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// 	{
// 		headerName: "Shelf & Merchandise score",
// 		field: "shelfAndMerchandizingScore",
// 		width: 130,
// 		valueFormatter: (params) => {
// 			return params.value ? params.value.toFixed(1) : "0.0";
// 		},
// 		align: "center",
// 	},
// ];

const portfolioColumns: GridColDef[] = [
	// {
	//   "field": "bussiness_unit",
	//   "headerName": "Bussiness unit",
	//   "minWidth": 250,
	//   "align": "center",
	//   "flex": 1,
	//   "headerAlign": "center",
	//   "type": "string"
	// },
	// {
	//   "field": "category",
	//   "headerName": "Category",
	//   "minWidth": 250,
	//   "align": "center",
	//   "flex": 1,
	//   "headerAlign": "center",
	//   "type": "string",
	// },
	{
		field: "segments",
		headerName: "Segment",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "brand",
		headerName: "Brand",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "subBrand",
		headerName: "Sub Brand",
		minWidth: 230,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "flavor",
		headerName: "Flavor",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "packSize",
		headerName: "Pack Size",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "sku",
		headerName: "SKU",
		minWidth: 450,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "checkoutSku",
		headerName: "Checkout SKU",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "revenuMix",
		headerName: "Revenue Mix",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "parseFloat",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
	},
	{
		field: "netRevenueSOP",
		headerName: "SOP Revenue",
		minWidth: 160,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "nonPBT_SOP",
		headerName: "SOP NOPBT",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "avgStorePenetration",
		headerName: "Penetration(Distribution)",
		minWidth: 230,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
	},
	{
		field: "executionScore",
		headerName: "Execution Score",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "mustHaveDistributionScore",
		headerName: "Must have distribution score",
		minWidth: 270,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "goodToHaveDistributionScore",
		headerName: "Good to have distribution score",
		minWidth: 280,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "assortmentOverall",
		headerName: "Assortment Score",
		minWidth: 180,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	//
	{
		field: "volumeMixResult",
		headerName: "Volume score",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "revenueMixResult",
		headerName: "Revenue score",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "profitMixResult",
		headerName: "NOPBT score",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	//
	{
		field: "mixScore",
		headerName: "Mix score",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},

	// {
	//   "field": "promotionScore",
	//   "headerName": "Promotion Score",
	//   "minWidth": 150,
	//   "align": "center",
	//   "flex": 1,
	//   "headerAlign": "center",
	//   "type": "number",
	//   valueFormatter: ({ value }) => !isNaN(value) ? `${parseFloat((parseFloat(value)?.toFixed(2)))}%` : value
	// },
	{
		field: "netPriceRealisationScore",
		headerName: "Net price realisation score",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "priceChangeVsRestOfTheMarketScore",
		headerName: "Price change Vs Rest of the market score",
		minWidth: 350,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "priceChangeVsInflationScore",
		headerName: "Price change Vs Inflation score",
		minWidth: 280,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "priceScore",
		headerName: "Price Score",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "exhibitionScore",
		headerName: "Exhibition score",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "shelfSpaceScore",
		headerName: "Shelf space score",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "shelfAndMerchandizingScore",
		headerName: "Shelf & Merchandise score",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
];

const geoLevelSummaryColumns: GridColDef[] = [
	{
		field: "chnl",
		headerName: "Channel",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "place",
		headerName: "Region",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "segment",
		headerName: "Store Segment",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
	},
	{
		field: "revenuMix",
		headerName: "Revenue Mix",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "parseFloat",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
	},
	{
		field: "netRevenueSOP",
		headerName: "SOP Revenue",
		minWidth: 160,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "nonPBT_SOP",
		headerName: "SOP NOPBT",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "avgStorePenetration",
		headerName: "Penetration(Distribution)",
		minWidth: 230,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
	},
	{
		field: "executionScore",
		headerName: "Execution Score",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "mustHaveDistributionScore",
		headerName: "Must have distribution score",
		minWidth: 270,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "goodToHaveDistributionScore",
		headerName: "Good to have distribution score",
		minWidth: 280,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "assortmentOverAll",
		headerName: "Assortment Score",
		minWidth: 180,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	//
	{
		field: "volumeMixResult",
		headerName: "Volume score",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "revenueMixResult",
		headerName: "Revenue score",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "profitMixResult",
		headerName: "NOPBT score",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	//
	{
		field: "mixScore",
		headerName: "Mix score",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	// {
	//   "field": "promotionScore",
	//   "headerName": "Promotion Score",
	//   "minWidth": 150,
	//   "align": "center",
	//   "flex": 1,
	//   "headerAlign": "center",
	//   "type": "number",
	//   valueFormatter: ({ value }) => !isNaN(value) ? `${parseFloat((parseFloat(value)?.toFixed(2)))}%` : value
	// },
	{
		field: "netPriceRealisationScore",
		headerName: "Net price realisation score",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "priceChangeVsRestOfTheMarketScore",
		headerName: "Price change Vs Rest of the market score",
		minWidth: 350,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "priceChangeVsInflationScore",
		headerName: "Price change Vs Inflation score",
		minWidth: 280,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "priceScore",
		headerName: "Price Score",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "exhibitionScore",
		headerName: "Exhibition score",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "shelfSpaceScore",
		headerName: "Shelf space score",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
	{
		field: "shelfAndMerchandizingScore",
		headerName: "Shelf & Merchandise score",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
	},
];

const columnsToDowloadListTableSKU = [
	{ headerName: "Segment", field: "segment" },
	{ headerName: "Brand", field: "brand" },
	{ headerName: "Sub-Brand", field: "subBrand" },
	{ headerName: "Pack-Size", field: "packSize" },
	{ headerName: "SKU Name", field: "skuName" },
	{ headerName: "Rank", field: "rank" },
	{ headerName: "Model Output", field: "modelOutput" },
	{ headerName: "Net Incremental Revenue Mix (%)", field: "netIncrementalRevenueMix" },
	{ headerName: "Cumulative Incremental Revenue Mix (%)", field: "cumulativeNetIncrementalRevenueMix" },
	{ headerName: "Penetration (Distribution in %)", field: "penetration" },
	{ headerName: "Manufacturer Walk Rate (%)", field: "manufacturerWalkRate" },
	{ headerName: "Revenue Mix (%)", field: "revenueMix" },
	{ headerName: "NOPBT (%)", field: "nopbtPercent" },
	{ headerName: "Total Score", field: "totalScore" },
	{ headerName: "Incremental Revenue Score", field: "incrementalRevenueScore" },
	{ headerName: "Incremental NoPBT Score", field: "incrementalNoPBTScore" },
	{ headerName: "NOPBT Score", field: "noPBTScore" },
	{ headerName: "Growth Score", field: "growthScore" },
	{ headerName: "Market Size Score", field: "marketSizeScore" },
	{ headerName: "Market Growth Score", field: "marketGrowthScore" },
	{ headerName: "Capacity Constraint Score", field: "capacityConstraintScore" },
	{ headerName: "Operative Complexity Score", field: "operativeComplexityScore" },
	{ headerName: "Inventory Turn Score", field: "inventoryTurnScore" },
	{ headerName: "SOP Revenue Mix (%)", field: "sopRevenueMix" },
	{ headerName: "SOP NOPBT Mix (%)", field: "sopNopbtMix" },
	{ headerName: "SOP Revenue", field: "sopRevenue" },
	{ headerName: "SOP Profit", field: "sopProfit" },
	{ headerName: "Revenue Metric Growth(capping)", field: "revenueMetricGrowth" },
];

export { filterableColumns, sortableColumns, portfolioColumns, geoLevelSummaryColumns, columnsToDowloadListTableSKU };
