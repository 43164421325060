import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import dataObject from "../../../../mocks/revenueMap";
import { Box } from "@mui/system";
import Dropdown from "../../../../components/Dropdown";
import { DropdownTitle, Title } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import { NoData, OrangeBtn, PrimaryBtn, StyledTableRow } from "../../../../styles/Common.Styled";
import { fetchViewTypeData } from "../../service";
import _ from "lodash";
import Indicator from "../../../../components/Loader";
import { formatNumber } from "../../../../util/helper";
import Dashboard from "../../../../components/Dashboard";
import { messages } from "../../../../util/config";
import { useSelector } from "react-redux";

const RevenueMapByType: React.FC<{ filters; callback; defaultFilters }> = ({ filters, callback, defaultFilters }) => {
	const commonLoader = useSelector((state: any) => state.common.loader);
	const [head, setHead] = useState([]);
	const [subHead, setSubHead] = useState([]);
	const [mValues, setMValues] = useState([]);
	const [periodView, setPeriodView] = useState<any>([]);
	const [variables, setVariables] = useState<any>([]);
	const [loader, setLoader] = useState(false);
	const [variableData, setVariableData] = useState<any>([]);
	const [title, setTitle] = useState("");
	const [tableSkeleton, setTableSkeleton] = useState(true);
	const [skeleton, setSkeleton] = useState(true);

	const getTableData = () => {
		if (_.isArray(periodView) && !_.isEmpty(periodView) && _.isArray(variables) && !_.isEmpty(variables)) {
			setLoader(true);
			const payload = {
				...filters,
				views: periodView,
				variables,
			};
			delete payload["monthRange"];
			fetchViewTypeData(payload)
				.then((res) => {
					res.data.map((item) => {
						Object.entries(item).forEach(([k, v]) => {
							if (_.isObject(v)) {
								Object.keys(v).map((key) => {
									if (v[key] === null) {
										delete v[key];
									}
								});
							} else if (v === null) {
								delete item[k];
							}
						});
					});
					formatTableData(res.data);
					//convert periodCompare to percent values
					if (_.isArray(res.data)) {
						res.data.map((x) => {
							if (x.periodCompare) {
								const periodCompare = {};
								Object.entries(x.periodCompare).map(([key, val]: any) => {
									if (val && key !== "somSalesAmount" && key !== "somSalesUnits" && key !== "somSalesVolume") {
										periodCompare[key] = parseFloat(val) * 100;
									} else periodCompare[key] = val;
								});
								x.periodCompare = periodCompare;
							}
						});
					}
					setMValues(res.data);
					setLoader(false);
					setTableSkeleton(false);
				})
				.catch((e) => {
					setLoader(false);
					setTableSkeleton(false);
				});
		}
	};
	const formatTableData = (data) => {
		if (_.isArray(data) && data.length > 0) {
			const allMainHeading = Object.keys(data[0]);
			const index = allMainHeading.findIndex((x) => x === "viewType");
			allMainHeading[index] = "";
			const result: any = allMainHeading.map((x) => dataObject.HeadingData.find((y) => y.value === x)).filter((x) => !_.isUndefined(x));
			setHead(result);
			if (allMainHeading.length > 1) {
				const allSubHeadings = Object.keys(data[0][allMainHeading[1]]);
				const result: any = allSubHeadings.map((x) => variableData.find((y: any) => y.value === x)).filter((x) => !_.isUndefined(x));
				setSubHead(result);
			}
		}
	};

	const onApplyFilter = () => {
		setHead([]);
		setSubHead([]);
		setMValues([]);
		setTableSkeleton(true);
		getTableData();
		callback({
			...filters,
			views: periodView,
			variables,
		});
	};

	const clearFilter = () => {
		setPeriodView([]);
		setVariables([]);
	};

	useEffect(() => {
		if (filters && filters.country) {
			setSkeleton(false);
			if (defaultFilters && defaultFilters.views && defaultFilters.variables) {
				setPeriodView([]);
				setVariables([]);
			}
			setVariableData(dataObject.sellVariableData);

			if (filters.viewType) {
				const title = dataObject.viewType.find((x) => x.value === filters.viewType);
				if (title) setTitle(title.label);
			}
			setHead([]);
			setSubHead([]);
			setMValues([]);
			setTableSkeleton(true);
		} else {
			setSkeleton(true);
		}
	}, [filters]);

	useEffect(() => {
		if (defaultFilters && Object.keys(defaultFilters).length > 0 && defaultFilters?.views && defaultFilters?.variables && variableData.length > 0) {
			setPeriodView(defaultFilters.views);
			setVariables(defaultFilters.variables);
		}
	}, [variableData]);

	useEffect(() => {
		if (defaultFilters && Object.keys(defaultFilters).length > 0 && defaultFilters?.views && defaultFilters?.variables) {
			setHead([]);
			setSubHead([]);
			setMValues([]);
			setTableSkeleton(true);
			getTableData();
		}
	}, [variables]);

	return (
		<Card style={{ position: "relative", marginBottom: "20px" }}>
			<Indicator position="absolute" show={!commonLoader && loader} />
			<CardContent>
				<Title className="m-b-20" style={{ fontSize: "20px", color: "#000" }}>
					Revenue Map By {title}
				</Title>
				{!skeleton ? (
					<Grid container spacing={2} mt={2} marginBottom={5}>
						<Grid item sm={3}>
							<Box>
								<DropdownTitle>Period View</DropdownTitle>
								<Dropdown
									keyText="table-heading"
									data={dataObject.HeadingData}
									multiple={true}
									placeholder="Select"
									onChange={(data) => setPeriodView(data)}
									allOption={true}
									defaultOption={periodView}
									search={{ enable: false }}
								/>
							</Box>
						</Grid>
						<Grid item sm={3}>
							<Box>
								<DropdownTitle>Variables</DropdownTitle>
								<Dropdown
									keyText="table-sub-heading"
									data={variableData}
									multiple={true}
									onChange={(sHead) => setVariables(sHead)}
									placeholder="Select"
									selectionLimit={5}
									defaultOption={variables}
									sort={{ enable: false }}
								/>
							</Box>
						</Grid>
						<Grid className="p-l-16" marginBottom={5} mt={5}>
							<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
								Clear Filter
							</OrangeBtn>
							<PrimaryBtn disabled={periodView.length < 1 || variables.length < 1} color="primary" onClick={onApplyFilter}>
								Apply Filter
							</PrimaryBtn>
						</Grid>
					</Grid>
				) : (
					<Grid container spacing={2} mt={2} marginBottom={5}>
						<Grid item></Grid>
						<Box className="m-r-20">
							<Skeleton height={100} width={400}></Skeleton>
						</Box>
						<Box className="m-r-20">
							<Skeleton height={100} width={400}></Skeleton>
						</Box>
						<Box className="m-r-20">
							<Skeleton height={100} width={250}></Skeleton>
						</Box>
						<Box className="m-r-20">
							<Skeleton height={100} width={250}></Skeleton>
						</Box>
					</Grid>
				)}
				{tableSkeleton ? (
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Skeleton variant="rectangular" height={400} />
					</Grid>
				) : mValues && mValues.length > 0 ? (
					<Dashboard
						title={null}
						showSkeleton={tableSkeleton}
						chartRef={() => {}}
						showActionButton={true}
						actionButtons={{
							mmpw: false,
							screenshot: true,
							editMedia: false,
						}}
						id={null}
					>
						<Grid container mt={4}>
							<Grid item sm={12} width={800}>
								<TableContainer sx={{ maxHeight: 600 }}>
									<Table stickyHeader>
										<TableHead style={{ position: "sticky", top: 0 }}>
											<TableRow>
												<TableCell className="b-color" align="center" style={{ color: "#005393", backgroundColor: "#ECECEC" }}>
													{title}
												</TableCell>
												{head.map((mHead, i) => {
													return (
														<TableCell
															key={`table-mhead-cell-${i}`}
															style={{ color: "#005393", textAlign: "center", backgroundColor: "#ECECEC" }}
															className="b-color"
															align="center"
															colSpan={subHead.length}
														>
															{mHead.label}
														</TableCell>
													);
												})}
											</TableRow>
											{/* Table subheading */}
											<TableRow>
												<TableCell className="b-color" style={{ backgroundColor: "#ECECEC" }}></TableCell>
												{head.map(() =>
													subHead.map((sHead: any, i) => (
														<TableCell key={`table-shead-cell-${i}`} className="b-color" style={{ backgroundColor: "#ECECEC" }} align="center">
															{sHead.label}
														</TableCell>
													))
												)}
											</TableRow>
										</TableHead>
										<TableBody>
											{mValues &&
												mValues.map((item, i) => {
													return (
														<StyledTableRow key={`table-row-${i}`}>
															{Object.keys(item).map((key, j) => {
																return !_.isObject(item[key])
																	? item && item[key] && (
																			<TableCell className="b-color" align="center" key={`table-row-${i}-cell-${j}`}>
																				{_.isNumber(item[key]) ? formatNumber(parseFloat(item[key])) : item[key]}
																			</TableCell>
																	  )
																	: Object.keys(item[key]).map((childKey, k) => {
																			return (
																				item &&
																				item[key] &&
																				!_.isNull(item[key][childKey]) && (
																					<TableCell className="b-color" align="center" key={`table-row-${i}-cell-${j}-subcell-${k}`}>
																						{_.isNumber(item[key][childKey]) ? formatNumber(parseFloat(item[key][childKey])) : item[key][childKey]}
																					</TableCell>
																				)
																			);
																	  });
															})}
														</StyledTableRow>
													);
												})}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</Dashboard>
				) : (
					<Grid container display="flex" justifyContent="center" alignItems="center" style={{ height: 400 }}>
						<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
					</Grid>
				)}
			</CardContent>
		</Card>
	);
};
export default RevenueMapByType;
