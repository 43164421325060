import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { LoadMoreBtn } from "../Dashboard.Styled";
import { Grid } from "@mui/material";
import NoDashboard from "../../../../components/NoDashboard/NoDashboard";
import _ from "lodash";
import DashboardPreviewCard from "../../../../components/DashboardPreviewCard";
import { dashboardCardMock } from "../../../../mocks/common";
import { getFavoriteDashboards } from "../../../../util/services";
import { sortData } from "../../../../util/helper";
import { RootStateOrAny, useSelector } from "react-redux";
const FavoritedDashboard: React.FC<{ sortOrder }> = ({ sortOrder }) => {
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const [allDashboards, setAllDashboards] = useState([]);
	const [dashboards, setDashboards] = useState([]);
	const [skeleton, setSkeleton] = useState(true);

	const fetchFavDashboards = async (userId) => {
		setSkeleton(true);
		const response = await getFavoriteDashboards(userId);
		if (response && _.isArray(response.data)) {
			sortDashboards(response.data);
		}
		setSkeleton(false);
	};

	const sortDashboards = (data) => {
		const sortedData = sortData(sortOrder, data);
		setAllDashboards(sortedData);
	};

	useEffect(() => {
		sortDashboards(allDashboards);
	}, [sortOrder]);

	useEffect(() => {
		if (userData && userData.id) {
			fetchFavDashboards(userData.id);
		}
	}, [userData]);

	useEffect(() => {
		if (_.isArray(allDashboards) && allDashboards.length) {
			setDashboards([..._.take(allDashboards, 4)]);
		}
	}, [allDashboards]);

	const loadMore = () => {
		setDashboards([...allDashboards]);
	};

	return (
		<Grid>
			{skeleton ? (
				<Grid container spacing={2} className="m-b-15">
					{dashboardCardMock.map((item, i) => (
						<Grid item xs={12} sm={3} md={3} key={`favorite-dashboard-card-${i}-skeleton`}>
							<DashboardPreviewCard data={item} skeleton={true} />
						</Grid>
					))}
				</Grid>
			) : (
				<>
					{allDashboards.length > 0 ? (
						<>
							<Grid container spacing={2} className="m-b-15">
								{dashboards.map((item, i) => (
									<Grid item xs={12} sm={3} md={3} key={`favorite-dashboard-card-${i}`}>
										<DashboardPreviewCard data={item} onToggleFavorite={() => fetchFavDashboards(userData.id)} showViewCount={false} />
									</Grid>
								))}
							</Grid>
							<Box className="m-b-15" display="flex" justifyContent="center" alignItems="center">
								{_.isArray(allDashboards) && dashboards.length !== allDashboards.length ? <LoadMoreBtn onClick={loadMore}>Load More</LoadMoreBtn> : null}
							</Box>
						</>
					) : (
						<NoDashboard heading="No dashboards have been favorited"></NoDashboard>
					)}
				</>
			)}
		</Grid>
	);
};
export default FavoritedDashboard;
