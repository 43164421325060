import React, { FC, useEffect, useState } from "react";
import DashboardActions from "../../components/DashboardActions";
import PriceLadderGraph from "./components/PriceLadderGraph";
import TopFilters from "./components/TopFilters";
import { pageList } from "../../mocks/common";
import DashboardSourceList from "../../components/DashboardSourceList";
import { RootStateOrAny, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import dataObject from "../../mocks/priceLadderMock";
import GlobalNotifications from "../../components/GlobalNotificationContainer/GlobalNotificationContainer";
import _ from "lodash";

const PriceLadder: FC<{}> = () => {
	const PriceLadderDataLoader = useSelector((state: any) => state.PriceLadderDataLoader);
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const [appliedFilters, setAppliedFilters] = useState({} as any);
	const [defaultFilters, setDefaultFilters] = useState<any>({});
	const [graphRendering, setGraphRendering] = useState(true);
	const [updatedGraphData, setUpdatedGraphData] = useState([]);
	const [disableDownloadButton, setDisableDownloadButton] = useState(true);
	const isDisabled: boolean = false;
	const search = window.location.search;
	const [urlParams] = useState(new URLSearchParams(search));
	const recordId = parseInt(urlParams.get("id"));

	const onApplyFilter = (data) => {
		if (data && data.filters) {
			setDisableDownloadButton(false);
			setAppliedFilters(data.filters);
			setGraphRendering(true);
			setUpdatedGraphData([]);
			setDefaultFilters({});
		}
	};
	const topClearCallBack = () => {
		setGraphRendering(false);
	};

	const getSaveDashBoardsData = (data) => {
		if (data.filters) {
			let savedDashboardData: any = { filters: JSON.parse(data.filters) };
			let savedDashboardDataPepsico: any = { filters: JSON.parse(data.filters) };
			setDisableDownloadButton(false);
			setUpdatedGraphData(savedDashboardData?.filters?.updatedGraphData);
			setDefaultFilters(savedDashboardDataPepsico?.filters?.global);
			setAppliedFilters(savedDashboardData?.filters?.global);
		}
	};

	useEffect(() => {
		if (userData && !recordId) {
			const permissionCountries: string[] = userData.permissionCountry.split(",");
			const permissionCategories: string[] = userData.permissionCategory.split(",");
			const defaultFilterConfiguration = dataObject.defaultPriceLadderFilterConfigurations.find(
				(item) =>
					permissionCountries.includes(item.country) &&
					item.pepsi.category.every((category) => permissionCategories.includes(category)) &&
					item.competitor.category.every((category) => permissionCategories.includes(category))
			);
			if (defaultFilterConfiguration && Object.keys(defaultFilterConfiguration).length) {
				setDefaultFilters(defaultFilterConfiguration);
				setAppliedFilters(defaultFilterConfiguration);
			}
		}
	}, [userData, dataObject.defaultPriceLadderFilterConfigurations]);

	return (
		<>
			{userData && (
				<DashboardActions
					title="Price Ladder Dashboard"
					disabled={isDisabled}
					data={{
						filters: {
							global: { ...appliedFilters },
							updatedGraphData:
								updatedGraphData && updatedGraphData.length > 0
									? _.cloneDeep(updatedGraphData)?.map((g, i) => {
											return g?.products?.map((p, j) => {
												delete p?.imagBlob;
												return p;
											});
									  })
									: [],
						},
					}}
					dashboardKey={pageList[14].id}
					callback={getSaveDashBoardsData}
					disableDownloadButton={disableDownloadButton || PriceLadderDataLoader.priceLadderDataLoader}
				>
					<Grid container spacing={2} className="m-b-20">
						<GlobalNotifications pageName={pageList[14].value} />
					</Grid>
					<TopFilters callback={onApplyFilter} defaultFilters={defaultFilters} topClearCallBack={topClearCallBack} />
					{graphRendering && (
						<>
							<PriceLadderGraph filters={appliedFilters} defaultFilters={defaultFilters} setUpdatedGraphData={setUpdatedGraphData} />
							<DashboardSourceList dashboardName={pageList[14].id} appliedFilters={appliedFilters} category={appliedFilters?.pepsi?.category} />
						</>
					)}
				</DashboardActions>
			)}
		</>
	);
};
export default PriceLadder;
