import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import Dropdown from "../Dropdown";
import { DropdownTitle } from "../DashboardFilters/DashboardFilters.Styled";
import { fetchFiltersData } from "../../util/services";
import { mapFilterOptions, selectAllOptions, travelChild } from "../../util/helper";
import _ from "lodash";
import { IDefaultFiltersAPIPayload } from "../../types/common";
import { useSelector } from "react-redux";

export const CustomDropDownTitle: React.FC<{ title: string }> = ({ title }) => {
	return <DropdownTitle dangerouslySetInnerHTML={{ __html: title }} />;
};

const ProductFiltersV3: React.FC<{
	data;
	onChange?;
	showSkeleton?: boolean;
	defaultFilters?;
	disabledFilters?;
	onChangeLoader?;
	selectedGeoFilters;
	clearFilter;
	apiURL?;
	type?;
	queryFunction?;
	params?;
}> = ({
	data,
	onChange,
	showSkeleton = false,
	defaultFilters,
	disabledFilters,
	onChangeLoader,
	selectedGeoFilters,
	clearFilter,
	apiURL = "graphql",
	type = "",
	queryFunction,
	params,
}) => {
	const userDetail = useSelector((state: any) => state.User.data);
	const [filterConfig, setFilterConfig] = useState({});
	const [filterInitialState, setFilterInitialState] = useState({});
	const [disableInitialState, setDisableInitialState] = useState({});

	const [selectedFilters, setSelectedFilters] = useState({});
	const [disableFilters, setDisableFilters] = useState({});

	const [defaultProductFilterAPICalls, setDefaultProductFilterAPICalls] = useState<IDefaultFiltersAPIPayload[]>([]);
	useEffect(() => {
		if (data && userDetail?.id) {
			Object.keys(data).map((key) => {
				filterInitialState[key] = null;
				disableInitialState[key] = true;
			});
			setFilterInitialState({ ...filterInitialState });
			setDisableInitialState({ ...disableInitialState });
			setSelectedFilters({ ...filterInitialState });
			setDisableFilters({ ...disableInitialState });
			setFilterConfig({ ...data });
		}
	}, [data, userDetail]);

	const onChangeFilter = (key, value) => {
		const result = travelChild(filterConfig, key, value, { ...selectedFilters }, { ...disableFilters });
		const selectedValues = { ...result.selectedValues, [key]: value };
		setSelectedFilters(selectedValues);
		if (value && value.length > 0) {
			if (!filterConfig[key].last && ((_.isArray(value) && value.length > 0) || (_.isString(value) && value !== null))) {
				fetchFilters({ ...result.selectedValues }, key, value, false);
			} else {
				setDisableFilters({ ...result.disableFilters });
				onChange(selectedValues);
			}
		} else {
			setDisableFilters({ ...result.disableFilters });
			onChange(selectedValues);
		}
	};

	useEffect(() => {
		if (defaultFilters && _.isObject(defaultFilters) && Object.keys(defaultFilters).length > 0 && defaultProductFilterAPICalls.length === 0) {
			let defaultFilterAPIPayload: IDefaultFiltersAPIPayload[] = [];
			const keys = Object.keys(defaultFilters);
			keys.forEach((key, i) => {
				if (defaultFilters[key]?.length > 0) {
					defaultFilterAPIPayload.push({
						key,
						value: defaultFilters[key],
						apiCalled: i === keys.length - 1,
					});
				}
			});
			setDefaultProductFilterAPICalls(defaultFilterAPIPayload);
		}
	}, [defaultFilters]);

	useEffect(() => {
		if (disabledFilters && Object.keys(disabledFilters).length > 0) {
			setDisableFilters(disabledFilters);
		}
	}, [disabledFilters]);

	useEffect(() => {
		if (selectedGeoFilters && selectedGeoFilters.country !== null && filterConfig && Object.keys(filterConfig).length) {
			clearFilterData();
		}
	}, [selectedGeoFilters.country]);

	useEffect(() => {
		if (clearFilter) {
			clearFilterData();
		}
	}, [clearFilter]);

	const clearFilterData = () => {
		Object.keys(filterConfig).map((key) => (filterConfig[key].options = []));
		setFilterConfig({ ...filterConfig });
		const firstKey = Object.keys(filterConfig)[0];
		setSelectedFilters({ ...filterInitialState });
		setDisableFilters({ ...disableInitialState, [firstKey]: !selectedGeoFilters.country });
		setDefaultProductFilterAPICalls([]);
		setTimeout(() => {
			fetchFilters({ ...filterInitialState, country: selectedGeoFilters.country }, firstKey, null, false, {
				selectedValues: { ...filterInitialState },
			});
		}, 0);
	};

	const fetchFilters = (payload, key, value, defaultFilterFlag = true, previousValues?) => {
		if (selectedGeoFilters.country) {
			onChangeLoader(true);
			payload = {
				...payload,
				[key]: value,
				type: type,
			};
			fetchFiltersData(
				apiURL,
				{
					country: selectedGeoFilters.country,
					...payload,
					...(params ?? {}),
				},
				userDetail.id,
				queryFunction
			)
				.then((response) => {
					if (response && response.data) {
						//Set filters op
						let filterConfiguration = mapFilterOptions(response, { ...filterConfig });

						setFilterConfig(filterConfiguration);
						const previousSelectedValues = previousValues && previousValues.selectedValues ? { ...previousValues.selectedValues } : { ...selectedFilters };
						const previousDisabledValues = previousValues && previousValues.disableFilters ? { ...previousValues.disableFilters } : { ...disableFilters };
						const result = travelChild(filterConfig, key, value, previousSelectedValues, { ...previousDisabledValues, [key]: false });

						let selectedValues = { ...result.selectedValues };

						if (filterConfig[key].all) {
							selectedValues = { ...selectedValues, ...selectAllOptions(response, filterConfig) };
						}
						const productFilterAPICallsObject = defaultProductFilterAPICalls;
						const index = productFilterAPICallsObject.findIndex((x) => !x.apiCalled);
						if (index !== -1) {
							productFilterAPICallsObject[index].apiCalled = true;
							setDefaultProductFilterAPICalls(productFilterAPICallsObject);
							const key = productFilterAPICallsObject[index].key;
							const value = productFilterAPICallsObject[index]["value"];
							fetchFilters(payload, key, value, true, { selectedValues: { ...selectedValues }, disableFilters: { ...result.disableFilters } });
						} else {
							if (productFilterAPICallsObject.length > 0 && index === -1 && defaultFilterFlag) {
								const lastFilter = productFilterAPICallsObject[productFilterAPICallsObject.length - 1];
								selectedValues[lastFilter.key] = lastFilter.value;
							}

							setSelectedFilters(selectedValues);
							setDisableFilters({ ...result.disableFilters });
							onChange(selectedValues);
							onChangeLoader(false);
						}
					}
				})
				.catch((e) => {
					onChangeLoader(false);
				});
		}
	};

	return (
		<Grid>
			{!showSkeleton ? (
				<Grid container spacing={2} columns={Object.keys(filterConfig).length} className="m-b-20">
					{Object.keys(filterConfig).map((key, i) => (
						<Grid item xs={12} sm={1} key={`grid-${i}`}>
							<CustomDropDownTitle title={filterConfig[key].title} />
							<Dropdown
								disabled={disableFilters[key]}
								data={filterConfig[key].options}
								onChange={(data) => onChangeFilter(key, data)}
								defaultOption={(selectedFilters && selectedFilters[key]) || ["empty"]}
								placeholder={filterConfig[key].placeholder}
								multiple={filterConfig[key].multiple}
								allOption={filterConfig[key].all}
								selectionLimit={filterConfig[key].selectionLimit ? filterConfig[key].selectionLimit : null}
							/>
						</Grid>
					))}
				</Grid>
			) : (
				<Grid container spacing={2} columns={Object.keys(filterConfig).length} className="m-b-20">
					{[...Array(Object.keys(filterConfig).length).keys()].map((i) => (
						<Grid item xs={12} sm={1} key={`grid-${i}-skeleton`}>
							<Skeleton height={22} />
							<Skeleton variant="rectangular" height={45} />
						</Grid>
					))}
				</Grid>
			)}
		</Grid>
	);
};

export default ProductFiltersV3;
