import { CREATE_NOTIFICATION, CREATE_NOTIFICATION_SUCCESS, CREATE_NOTIFICATION_FAILURE } from "./constants";
import { API } from "../../../util/helper";
import { graphqlAPI } from "../../api-constants";
import { INotificationCardType, IUserDetails } from "../../../types/common";
import { CREATE_NOTIFICATION_QUERY } from "../../../util/queries/notifications";

const CreateNotification = () => {
	return {
		type: CREATE_NOTIFICATION,
	};
};

const CreateNotificationSuccess = (payload: any) => {
	return {
		type: CREATE_NOTIFICATION_SUCCESS,
		payload,
	};
};

const CreateNotificationFailure = (error) => {
	return {
		type: CREATE_NOTIFICATION_FAILURE,
		payload: error,
	};
};

const CreateNotificationData = async (user: IUserDetails, notificationDetails: INotificationCardType) => {
	const graphQLApiUrl: string = await graphqlAPI();
	return await API.post(graphQLApiUrl, CREATE_NOTIFICATION_QUERY(user, notificationDetails)).then((response) => {
		let data = [];
		if (response && response.data) {
			data = response.data.data.notificationsByPageKey;
		}
		return { data: data };
	});
};

export function postCreateNotification(user: IUserDetails, notificationDetails: INotificationCardType) {
	return (dispatch: any) => {
		dispatch(CreateNotification());
		CreateNotificationData(user, notificationDetails)
			.then((response) => {
				dispatch(CreateNotificationSuccess(response.data));
			})
			.catch((error) => {
				dispatch(CreateNotificationFailure(error));
			});
	};
}
