import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import dataObject from "../../../../mocks/executionTracker";
import SimulationCard from "./SimulationCard";
import { TopHeading } from "../../../ElasticityPricingTrack/ElasticityPricingTrack.Styled";
import SizeOfPrize from "./SizeOfPrize";
import OverviewKPI from "./OverviewKPI";
const Overview: React.FC<{ chartSkeleton: boolean }> = ({ chartSkeleton }) => {
	return (
		<>
			<Card className="m-b-20">
				<CardContent>
					<Title className="ellipsis m-b-20">Overview</Title>
					<Grid container spacing={2} marginTop={1} className="m-b-20" display="flex" alignItems="center">
						<Grid item sm={12}>
							<OverviewKPI data={dataObject.mockRestOverviewData} chartSkeleton={chartSkeleton} />
						</Grid>
					</Grid>
					<Grid container spacing={2} marginTop={1}>
						<Grid item sm={9}>
							<TopHeading>Execution Score</TopHeading>
						</Grid>
						<Grid item sm={3}>
							<TopHeading>Size of Prize (Avg. Monthly)</TopHeading>
						</Grid>
					</Grid>
					<Grid container spacing={2} marginTop={1}>
						<Grid item sm={9} sx={{ paddingLeft: "16px" }}>
							<Grid display={"flex"} sx={{ border: "3px solid #CAD0D8", borderRadius: "10px", padding: "20px" }}>
								<SimulationCard data={dataObject.mockSimulationData} chartSkeleton={chartSkeleton} />
							</Grid>
						</Grid>
						<Grid item sm={3} sx={{ paddingLeft: "16px" }}>
							<Grid sx={{ border: "3px solid #CAD0D8", borderRadius: "10px", padding: "20px" }}>
								<SizeOfPrize data={dataObject.mockOpportunitiesData} chartSkeleton={chartSkeleton} />
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};

export default Overview;
