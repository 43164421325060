export const GET_TRENDING_DASH = "GET_TRENDING_DASH";
export const GET_TRENDING_DASH_SUCCESS = "GET_TRENDING_DASH_SUCCESS";
export const GET_TRENDING_DASH_FAILURE = "GET_TRENDING_DASH_FAILURE";
export const POST_TRENDING = "POST_TRENDING";
export const POST_TRENDING_SUCCESS = "POST_TRENDING_SUCCESS";
export const POST_TRENDING_FAILURE = "POST_TRENDING_FAILURE";
export const GET_DASHBOARDS_LIST = "GET_DASHBOARDS_LIST";
export const UPDATE_DASHBOARDS_FETCH_FLAG = "UPDATE_DASHBOARDS_FETCH_FLAG";
export const GET_FAVORITE_DASH = "GET_FAVORITE_DASH";
export const POST_FAVORITE = "POST_FAVORITE";
export const POST_FAVORITE_FAILURE = "POST_FAVORITE_FAILURE";
