import React, { createContext, useEffect, useState } from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { BrowserRouter } from "react-router-dom";

import rootReducer from "./store/reducers";
import AuthenticationModel from "./router/AuthenticationModel";
import Indicator from "./components/Loader";
import Toast from "./components/Toast";
import ErrorBoundary from "./components/ErrorBoundary";
import { IEnvironmentVariables } from "./types/common";
import DialogBox from "./components/Dialog";
import "./index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const defaultEnvironmentVariables: IEnvironmentVariables = {
	BACKEND_BASE_URL: "",
	AUTH_URL: "",
	IDENTITY_CLIENT_ID: "",
	LOGGEDIN_USER: "",
};

const store = createStore(rootReducer, applyMiddleware(thunk));

export const EnvironmentContext = createContext<{
	environmentVariables: IEnvironmentVariables;
	setEnvironmentVariables: React.Dispatch<React.SetStateAction<IEnvironmentVariables>>;
}>({
	environmentVariables: defaultEnvironmentVariables,
	setEnvironmentVariables: () => undefined,
});

const App: React.FC<{}> = () => {
	const [environmentVariables, setEnvironmentVariables] = useState(defaultEnvironmentVariables);

	useEffect(() => {
		const res = fetch("/env/env.json").then((res) => res.json());
		res.then((res: IEnvironmentVariables) => {
			setEnvironmentVariables(res);
		});
	}, []);

	return (
		<EnvironmentContext.Provider value={{ environmentVariables, setEnvironmentVariables }}>
			<BrowserRouter basename={""}>
				<Provider store={store}>
					<Toast />
					<DialogBox />
					<Indicator />
					<ErrorBoundary>{environmentVariables.AUTH_URL ? <AuthenticationModel /> : null}</ErrorBoundary>
				</Provider>
			</BrowserRouter>
		</EnvironmentContext.Provider>
	);
};

export default App;
