import React from "react";
import { Grid, Typography } from "@mui/material";
import { sadBot, onlyTextLogo } from "../../assets/images";
import { makeStyles } from "@material-ui/core/styles";
import { accessInfo, requestInfo } from "../../util/GlobalConstant";

const styles = makeStyles(() => ({
	onlyTextLogo: {
		height: "7.813vw",
		width: "21.875vw",
	},
	sadBot: {
		height: "20.833vw",
		width: "17.344vw",
	},
}));

function AccessBoundary() {
	const classes = styles();
	return (
		<Grid
			alignItems="center"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			textAlign="center"
			sx={{ backgroundColor: "#335492", width: "100vw", height: "100vh" }}
		>
			<Typography color="#ffffff" fontWeight="600" sx={{ fontSize: "2.604vw" }}>
				{accessInfo}
			</Typography>
			<Typography color="#ffffff" fontWeight="600" sx={{ fontSize: "2.604vw", marginBottom: 5 }}>
				{requestInfo}
			</Typography>
			<Grid className={classes.onlyTextLogo}>
				<img src={onlyTextLogo} alt="RevMan" height="100%" width="100%" />
			</Grid>
			<Grid className={classes.sadBot}>
				<img src={sadBot} alt="Sad Bot" height="100%" width="100%" />
			</Grid>
		</Grid>
	);
}

export default AccessBoundary;
