import React, { FC, useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { DropdownTitle } from "../../DashboardFilters/DashboardFilters.Styled";
import Dropdown from "../../Dropdown";
import _ from "lodash";
import { useSelector } from "react-redux";

const CustomDropDownTitle: React.FC<{ title: string }> = ({ title }) => {
	return <DropdownTitle dangerouslySetInnerHTML={{ __html: title }} />;
};

const CommonMnAFilters: FC<{
	filterData;
	data;
	onChange: (key: string, value: string[]) => void;
	showSkeleton?: boolean;
	defaultFilters?;
	filterOrder;
	disableAll?: boolean;
}> = ({ filterData, data, onChange, showSkeleton = false, defaultFilters, filterOrder, disableAll = false }) => {
	const userDetail = useSelector((state: any) => state.User.data);
	const [filterConfig, setFilterConfig] = useState({});
	const itemColumnSize = Math.floor(12 / Object.keys(data).length) || 1;

	useEffect(() => {
		if (filterData && data && userDetail?.id) {
			filterOrder.map((orderKey) => {
				const optionValues: string[] = _.isArray(filterData[orderKey.key]) ? filterData[orderKey.key] : [];
				data[orderKey.key].options = optionValues.map((keyValue: string) => {
					return { key: keyValue, value: keyValue };
				});
			});
			setFilterConfig({ ...data });
		}
	}, [filterData, data, userDetail]);

	return (
		<Grid>
			<Grid container spacing={2} className="m-b-20">
				{!showSkeleton
					? Object.keys(filterConfig).map((key) => (
							<Grid item xs={itemColumnSize} key={filterConfig[key].title}>
								<CustomDropDownTitle title={filterConfig[key].title} />
								<Dropdown
									disabled={filterConfig[key].disabled || disableAll}
									data={filterConfig[key].options}
									onChange={(data) => onChange(key, data)}
									defaultOption={(defaultFilters && defaultFilters[key]) || ["empty"]}
									placeholder={filterConfig[key].placeholder}
									multiple={filterConfig[key].multiple}
									allOption={filterConfig[key].all}
									sort={filterConfig[key].sort}
									selectionLimit={filterConfig[key].selectionLimit ? filterConfig[key].selectionLimit : null}
								/>
							</Grid>
					  ))
					: [...Array(Object.keys(filterConfig).length).keys()].map((i) => (
							<Grid item xs={itemColumnSize} key={`filter-${i}-skeleton`}>
								<Skeleton height={24} />
								<Skeleton variant="rectangular" height={40} />
							</Grid>
					  ))}
			</Grid>
		</Grid>
	);
};

export default CommonMnAFilters;
