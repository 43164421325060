import React from "react";
import CategoryDashboardList from "../../components/CategoryDashboardList/CategoryDashboardList";
import PageContainer from "../../components/PageContainer";
import { pageList, pageNames } from "../../mocks/common";

const TradeManagement: React.FC = () => {
	return (
		<PageContainer page={pageNames.tradeManagement}>
			<CategoryDashboardList title={"Promotions & Trade Management"} categoryKey={"promo_and_trade_management"} pageName={pageList[15].value} />
		</PageContainer>
	);
};
export default TradeManagement;
