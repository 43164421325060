import { GET_20_SEC_MARKET_CHECK, GET_20_SEC_MARKET_CHECK_SUCESS, GET_20_SEC_MARKET_CHECK_FAILURE } from "../constants";
import { API } from "../../../util/helper";
import { graphqlAPI } from "../../api-constants";
import { MARKET_CHECK_QUERY } from "../../../util/queries/home";

const get20secMarketCheck = () => {
	return {
		type: GET_20_SEC_MARKET_CHECK,
	};
};

const get20secMarketCheckSucess = (payload: any) => {
	return {
		type: GET_20_SEC_MARKET_CHECK_SUCESS,
		payload,
	};
};

const get20secMarketCheckFailure = () => {
	return {
		type: GET_20_SEC_MARKET_CHECK_FAILURE,
	};
};

interface I20SecMarketCheckPayload {
	countries: string[];
	categories: string[];
	period: string;
	autoCall: boolean;
}

/* Calls the API endpoint to get the market check KPIs.
 * @param {I20SecMarketCheckPayload} payload - The payload to be sent to the MARKET_CHECK_QUERY function to get the GraphQL query.
 * @returns The response data abd status.
 */
const get20SecMarketCheckData = async (payload: I20SecMarketCheckPayload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	return await API.post(graphQLApiUrl, MARKET_CHECK_QUERY(payload.countries, payload.categories, payload.period, payload.autoCall)).then((response) => {
		let data: any = [];
		if (response && response.data) {
			data = response.data.data.marketCheck;
		}
		return { status: response.status, data: data };
	});
};

/* Calls the get20SecMarketCheckData function and dispatches the response data to the reducer.
 * @param {I20SecMarketCheckPayload} payload - The payload to be sent to the get20SecMarketCheckData function.
 * @returns The dispatch action.
 */
export function fecth20SecMarketCheckData(payload: I20SecMarketCheckPayload) {
	return (dispatch: any) => {
		dispatch(get20secMarketCheck());
		get20SecMarketCheckData(payload)
			.then((response) => {
				if (response.status !== 200) {
					const response = {
						sales: { value: 0, change: 0 },
						volume: { value: 0, change: 0 },
						units: { value: 0, change: 0 },
						nrr: { value: 0, change: 0 },
						shareSales: { value: 0, change: 0 },
						shareVolume: { value: 0, change: 0 },
						shareUnits: { value: 0, change: 0 },
					};
					dispatch(get20secMarketCheckSucess(response));
				} else {
					dispatch(get20secMarketCheckSucess(response.data));
				}
			})
			.catch((error) => {
				console.error(error.message);
				dispatch(get20secMarketCheckFailure());
			});
	};
}
