import { Button, Card, CardContent, DialogActions, DialogContent, Grid, Slider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Cancel } from "@mui/icons-material";
import CropIcon from "@mui/icons-material/Crop";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import { BlackTitle } from "../../../../styles/Common.Styled";

const CropEasy = ({ imgUrl, setProfilePic, setOpenCrop, setImgUrl, apiCallback }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [rotation, setRotation] = useState(0);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const cropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const cropImage = async () => {
		try {
			const { file, url } = await getCroppedImg(imgUrl, croppedAreaPixels, rotation);
			setImgUrl(url);
			setProfilePic(url);
			setOpenCrop(false);
			apiCallback(file);
		} catch (e) {}
	};

	return (
		<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
			<Card>
				<CardContent>
					<Grid item>
						<DialogContent
							dividers
							sx={{
								backgroud: "#333",
								position: "relative",
								height: 400,
								width: "auto",
								minWidth: { sm: 500 },
							}}
						>
							<Cropper
								image={imgUrl}
								crop={crop}
								zoom={zoom}
								rotation={rotation}
								aspect={1}
								showGrid={true}
								onZoomChange={setZoom}
								onRotaionChange={setRotation}
								onCropChange={setCrop}
								onCropComplete={cropComplete}
								cropShape="round"
							/>
						</DialogContent>
						<DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
							<Box sx={{ width: "100%", mb: 1 }}>
								<Box>
									<BlackTitle>Rotaion: {rotation}</BlackTitle>
									<Slider valueLabelDisplay="auto" min={0} max={360} value={rotation} onChange={(e, rotation) => setRotation(rotation)} />
								</Box>
							</Box>
							<Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
								<Button variant="contained" startIcon={<Cancel />} onClick={() => setOpenCrop(false)}>
									Cancel
								</Button>
								<Button variant="contained" startIcon={<CropIcon />} onClick={cropImage}>
									Save
								</Button>
							</Box>
						</DialogActions>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default CropEasy;

const zoomPercent = (value) => {
	return `${Math.round(value * 100)}%`;
};
