import React, { useEffect, useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import Dropdown from "../../components/Dropdown/Dropdown";
import CustomDateRangePicker from "../../components/UI-components/DateRangePicker/CustomDateRangePicker";
import { periodicities } from "../../mocks/common";
import dataObject from "../../mocks/reportingMock";
import { PrimaryBtn, Title } from "../../styles/Common.Styled";
import Report from "./Components/Report";
import StatCard from "./StatCard";
import { convertToInternationalCurrencySingleChar, dataFormat } from "../../util/helper";
import { getKpiData } from "./service";
import { getAllUsers } from "../User/service";
import { getCountries } from "../UserReport2/service";
import { loader } from "../../store/actions/common.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getCountryList } from "../../store/actions/coutries.action";
const Reporting: React.FC<{}> = () => {
	const [selectedCountries, setSelectedCountries] = useState([]);
	const [kpiData, setKpiData] = useState(dataObject.KPIData1);
	const [kpiSkeleton, setKpiSkeleton] = useState(true);
	const [users, setUsers] = useState<any>([]);
	const [filters, setFilters] = useState({});
	const [selectedPriodicity, setSelectedPriodicity] = useState(null);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
	const [countries, setCountries] = useState([]);
	const [isCountryAPICall, setIsCountryAPICall] = useState(false);
	const dispatch = useDispatch();
	const countryData = useSelector((store: RootStateOrAny) => store.CountryList.data);

	const onChangeCountry = (data) => {
		setSelectedCountries(data);
		setSelectedUsers([]);
		getAllUsers(JSON.stringify(data))
			.then((res) => {
				setUsers(
					res.data.map((item) => ({
						...item,
						label: item.fullName,
						value: item.emailAddress,
					}))
				);
			})
			.catch((e) => {});
	};

	const disableApplyFilter = () => {
		if (selectedPriodicity && countries.length > 0) {
			return false;
		}
		return true;
	};

	const onApplyFilter = () => {
		let userIds = selectedUsers.map((item) => {
			return String(users.filter((x) => x.value === item)[0].id);
		});
		const payload = {
			country: selectedCountries,
			date: `${dataFormat(selectedDate[0], "MM/dd/yyyy")}-${dataFormat(selectedDate[1], "MM/dd/yyyy")}`,
			periodicity: selectedPriodicity,
			users: userIds,
		};
		setFilters(payload);
	};

	const onChangeUser = (data) => {
		setSelectedUsers(data);
	};

	useEffect(() => {
		if (filters && filters?.country && filters?.country.length > 0) {
			getKpiData(filters)
				.then((res) => {
					const newKpiData = { ...kpiData };
					Object.keys(newKpiData).map((key) => {
						newKpiData[key].value = res?.data[key].value ? res?.data[key].value : "0";
						newKpiData[key].raise = res?.data[key].change ? res?.data[key].change >= 0 : null;
						newKpiData[key].change = res?.data[key].change ? `${convertToInternationalCurrencySingleChar("", res?.data[key].change)}%` : "0";
					});
					setKpiData(newKpiData);
					setKpiSkeleton(false);
				})
				.catch((e) => {});
		}
	}, [filters]);

	useEffect(() => {
		if (countryData.length === 0 && !isCountryAPICall) {
			dispatch(loader(true));
			setIsCountryAPICall(true);
			getCountries()
				.then((response) => {
					formatCountries(response.data);
					dispatch(getCountryList(response.data));
					dispatch(loader(false));
				})
				.catch((e) => {
					dispatch(loader(false));
				});
		} else {
			formatCountries(countryData);
		}
	}, []);

	const formatCountries = (data) => {
		const newarr = data.map((item, i) => {
			return {
				id: i,
				label: item,
				value: item,
			};
		});
		setCountries(newarr);
	};

	return (
		<>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item xs={12} sm={12} md={4}>
					<Tooltip title={"Net Revenue Realization Dashboard Reports"} placement="top">
						<Title data-testid="main-title" className="ellipsis">
							Usage Count Report
						</Title>
					</Tooltip>
				</Grid>
				<Grid item xs={12} sm={12} md={8}>
					<Grid container alignItems={"center"} justifyContent={"space-between"}>
						<Grid item md={2.5} sm={2.5}>
							<Dropdown
								keyText="report-country-dropdown"
								data={countries}
								multiple={true}
								multipleSelectionMsg="Multiple"
								placeholder="Select Country"
								allOption={true}
								defaultOption={selectedCountries}
								onChange={onChangeCountry}
							/>
						</Grid>
						<Grid item md={2} sm={2}>
							<Dropdown
								keyText="report-user-dropdown"
								data={users}
								multiple={true}
								multipleSelectionMsg="Multiple"
								placeholder="Select Users"
								allOption={true}
								defaultOption={selectedUsers}
								onChange={(data) => {
									onChangeUser(data);
								}}
							/>
						</Grid>
						<Grid item md={2.5} sm={2.5}>
							<Dropdown
								keyText="report-periodicity-dropdown"
								data={periodicities}
								multiple={false}
								placeholder="Select Periodicity"
								defaultOption={selectedPriodicity}
								onChange={(data) => {
									setSelectedPriodicity(data[0]);
								}}
							/>
						</Grid>
						<Grid item md={3} sm={3}>
							<CustomDateRangePicker defaultDate={selectedDate} callback={setSelectedDate} />
						</Grid>
						<Grid item md={1.5} sm={1.5} textAlign={"end"}>
							<PrimaryBtn disabled={disableApplyFilter()} color="primary" onClick={onApplyFilter}>
								Apply Filter
							</PrimaryBtn>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item md={12} className="m-t-20">
					<Grid container spacing={2} justifyContent={"start"}>
						{Object.keys(kpiData).map((key, i) => (
							<Grid item key={`stat-card-${i}`} md={2.4}>
								<StatCard data={kpiData[key]} showSkeleton={kpiSkeleton} />
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
			{dataObject.series.map((item, i) => (
				<Report seriesData={{ item, i }} filters={filters} key={`report-${i}`} />
			))}
		</>
	);
};

export default Reporting;
