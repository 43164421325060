import { CHANGE_THEME } from "../actions/constants";

let intiState = { darkmode: false };

const theme = (state = intiState, action) => {
	if (action.type === CHANGE_THEME) return { ...state, darkmode: action.payload };
	else return state;
};

export default theme;
