import { DELETE_NOTIFICATION, DELETE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_FAILURE } from "./constants";
import { API } from "../../../util/helper";
import { graphqlAPI } from "../../api-constants";
import { DELETE_NOTIFICATION_QUERY } from "../../../util/queries/notifications";

const resetNotification = () => {
	return {
		type: DELETE_NOTIFICATION,
	};
};

const deleteNotificationSuccess = (payload: any) => {
	return {
		type: DELETE_NOTIFICATION_SUCCESS,
		payload,
	};
};

const deleteNotificationFailure = (error) => {
	return {
		type: DELETE_NOTIFICATION_FAILURE,
		payload: error,
	};
};

const deleteNotificationData = async (payload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	return await API.post(graphQLApiUrl, DELETE_NOTIFICATION_QUERY(payload.id)).then((response) => {
		return response;
	});
};

export function deleteNotification(payload) {
	return (dispatch: any) => {
		dispatch(resetNotification());
		deleteNotificationData(payload)
			.then((response) => {
				dispatch(deleteNotificationSuccess(payload));
			})
			.catch((error) => {
				dispatch(deleteNotificationFailure(error));
			});
	};
}
