import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import CustomDateRangePicker from "../../../../components/UI-components/DateRangePicker";
import { dataFormat } from "../../../../util/helper";
import _ from "lodash";
import { DateTime } from "luxon";
import { fetchSellPeriodViewDates } from "../../../../util/services";
import { useDispatch } from "react-redux";
import { defaultCalendarDates } from "../../../../util/config";
import { dialog } from "../../../../store/actions/dialog.action";

export const dateRangeSellIn = [defaultCalendarDates.startDate, defaultCalendarDates.endDate];
export const dateRangeSellOut = [defaultCalendarDates.startDateSellOut, defaultCalendarDates.endDateSellOut];
const PeriodViewFilter: React.FC<{
	data;
	selectedGeoFilters;
	onChangeDateSellIn?;
	onChangeDateSellOut?;
	defaultFilters?;
	clearFilter?: boolean;
	apiPath?;
	showLoader?;
	selectedProductFilters?;
}> = ({ data, selectedGeoFilters, onChangeDateSellIn, onChangeDateSellOut, defaultFilters, clearFilter, apiPath, showLoader, selectedProductFilters }) => {
	const dispatch = useDispatch();
	const [selectedDateSellIn, setSelectedDateSellIn] = useState(dateRangeSellIn);
	const [selectedDateSellOut, setSelectedDateSellOut] = useState(dateRangeSellOut);

	const [selectedPeriodView, setSelectedPeriodView] = useState(
		defaultFilters && defaultFilters.periodView ? (_.isArray(defaultFilters.periodView) ? defaultFilters.periodView : [defaultFilters.periodView]) : ["na"]
	);

	useEffect(() => {
		if (clearFilter) {
			setSelectedDateSellIn(dateRangeSellIn);
			setSelectedDateSellOut(dateRangeSellOut);
		}
	}, [clearFilter]);

	useEffect(() => {
		if (selectedGeoFilters.country) {
			selectedGeoFilters.periodView = selectedGeoFilters.periodView || defaultFilters.periodView || false;
			setSelectedPeriodView(selectedGeoFilters.periodView);
		}
	}, [selectedGeoFilters]);

	useEffect(() => {
		if (defaultFilters && defaultFilters.country) {
			const periodView = defaultFilters.periodView ? (_.isArray(defaultFilters.periodView) ? defaultFilters.periodView : [defaultFilters.periodView]) : ["na"];
			setSelectedPeriodView(periodView);
		}
		if (defaultFilters && defaultFilters.sellInStartDate && defaultFilters.sellInEndDate && defaultFilters.sellOutStartDate && defaultFilters.sellOutEndDate) {
			setSelectedDateSellIn([new Date(defaultFilters.sellInStartDate), new Date(defaultFilters.sellInEndDate)]);
			setSelectedDateSellOut([new Date(defaultFilters.sellOutStartDate), new Date(defaultFilters.sellOutEndDate)]);
		}
	}, [defaultFilters]);

	const onSelectDateSrp = (date) => {
		if (date.length === 2) {
			setSelectedDateSellIn(date);
			const formattedDate = [];
			for (let dateValue of date) {
				if (dateValue) formattedDate.push(dataFormat(dateValue, "MM/dd/yyyy"));
			}
			onChangeDateSellIn(formattedDate.join("-"));
		}
	};

	const onSelectDateSop = (date) => {
		if (date.length === 2) {
			setSelectedDateSellOut(date);
			const formattedDate = [];
			for (let dateValue of date) {
				if (dateValue) formattedDate.push(dataFormat(dateValue, "MM/dd/yyyy"));
			}
			onChangeDateSellOut(formattedDate.join("-"));
		}
	};

	useEffect(() => {
		onChangePeriodView();
	}, [selectedProductFilters.category, selectedPeriodView]);

	const onChangePeriodView = () => {
		selectedGeoFilters.periodView = selectedPeriodView[0];
		if (selectedPeriodView[0] !== "na") {
			showLoader(true);
			const payload = {
				period: selectedPeriodView[0],
				country: selectedGeoFilters.country || defaultFilters.country,
				geoLevel: selectedGeoFilters.geoLevel || defaultFilters.geoLevel,
				geoLevel2: selectedGeoFilters.geoLevel2 || defaultFilters.geoLevel2,
				category: selectedProductFilters.category || defaultFilters.category,
			};
			fetchSellPeriodViewDates(apiPath, payload)
				.then((res) => {
					const response = res.data;
					if (res.status === 200 && response?.sellInStartDate && response?.sellInEndDate) {
						const startDate = DateTime.fromString(response.sellInStartDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");
						const endDate = DateTime.fromString(response.sellInEndDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");

						const startDateSellOut = DateTime.fromString(response.sellOutStartDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");
						const endDateSellOut = DateTime.fromString(response.sellOutEndDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");

						setSelectedDateSellIn([startDate, endDate]);
						setSelectedDateSellOut([startDateSellOut, endDateSellOut]);

						onChangeDateSellIn(`${startDate}-${endDate}`);
						onChangeDateSellOut(`${startDateSellOut}-${endDateSellOut}`);
					} else {
						dispatch(dialog("Data is not available for selected Geo and Product filters!", "Period View", true, true));
					}
					showLoader(false);
				})
				.catch((e) => {
					dispatch(dialog(e.message, "Period View", true, true));
					showLoader(false);
				});
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid className="periodViewBlk" item xs={12} md={2}>
				<DropdownTitle>{data.periodView.title}</DropdownTitle>
				<Dropdown
					disabled={!selectedGeoFilters || (selectedGeoFilters && !selectedGeoFilters.country)}
					data={data.periodView.options}
					onChange={(data) => setSelectedPeriodView(data)}
					defaultOption={selectedPeriodView || ["empty"]}
					placeholder={data.periodView.placeholder}
					sort={{ enable: false }}
				/>
			</Grid>
			<Grid className="dateBlk" item xs={12} md={4} lg={3} xl={2}>
				<DropdownTitle>Sell In Date Range</DropdownTitle>
				<CustomDateRangePicker defaultDate={selectedDateSellIn} callback={onSelectDateSrp} disabled={selectedPeriodView[0] !== "na"} />
			</Grid>
			<Grid className="dateBlk" item xs={12} md={4} lg={3} xl={2}>
				<DropdownTitle>Sell Out Date Range</DropdownTitle>
				<CustomDateRangePicker defaultDate={selectedDateSellOut} callback={onSelectDateSop} disabled={selectedPeriodView[0] !== "na"} />
			</Grid>
		</Grid>
	);
};
export default PeriodViewFilter;
