import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Tooltip } from "@mui/material";
import _ from "lodash";
import CustomTable from "../../components/CustomTable";
import Dropdown from "../../components/Dropdown";
import CustomDateRangePicker from "../../components/UI-components/DateRangePicker";
import { BlackTitle, NoData, PrimaryBtn, Title } from "../../styles/Common.Styled";
import { messages } from "../../util/config";
import { dataFormat } from "../../util/helper";
import MultilevelTableContainer from "./MultilevelTableContainer";
import { getCountries, getCountryAccess } from "./service";
import { loader } from "../../store/actions/common.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../User/service";
import { USAGE_ACTIVITY_COUNTRY_ACTIVITY_QUERY, USAGE_ACTIVITY_USER_ACTIVITY_QUERY } from "../../util/queries/reporting";
import { ICountryAccessData, IUsageActivityFilters } from "../../types/reporting";
import { ICountryData, IUserDetails } from "../../types/common";

const multiLevelTableConfig = [
	{
		title: "Country Activity",
		queryFunction: USAGE_ACTIVITY_COUNTRY_ACTIVITY_QUERY,
		headers: [
			{ label: "Name", value: "name" },
			{ label: "View Count", value: "viewCount" },
			{ label: "Save Count", value: "saveCount" },
			{ label: "Shared Count", value: "sharedCount" },
		],
		child: "dashboard",
	},
	{
		title: "User Activity",
		queryFunction: USAGE_ACTIVITY_USER_ACTIVITY_QUERY,
		headers: [
			{ label: "Name", value: "name" },
			{ label: "Dashboard", value: "dashboard" },
			{ label: "Date", value: "date" },
		],
		child: "activity",
	},
];

const UsageReport2: React.FC = () => {
	const [countries, setCountries] = useState<ICountryData[]>([]);
	const [tableHeadings, setTableHeadings] = useState<{ title: string; key: string }[]>([]);
	const [tableData, setTableData] = useState<ICountryAccessData[]>([]);
	const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
	const [filters, setFilters] = useState<IUsageActivityFilters>();
	const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
	const [skeleton, setSkeleton] = useState(true);
	const [users, setUsers] = useState<(IUserDetails & { value: string })[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const countryData = useSelector((store: RootStateOrAny) => store.CountryList.data);
	const [isCountryAPICall, setIsCountryAPICall] = useState(false);
	const dispatch = useDispatch();

	const countryList = () => {
		dispatch(loader(true));
		getCountries()
			.then((response) => {
				formatCountries(response.data);
				dispatch(loader(false));
			})
			.catch((e) => {
				dispatch(loader(false));
			});
	};

	const onChangeCountry = (data) => {
		setSelectedCountry(data);
		setSelectedUsers([]);
		getAllUsers(JSON.stringify(data))
			.then((res) => {
				setUsers(
					res.data.map((item) => ({
						...item,
						label: item.fullName,
						value: item.emailAddress,
					}))
				);
			})
			.catch((e) => {});
	};

	const getCountryAccessData = (userIds) => {
		dispatch(loader(true));
		setSkeleton(true);
		getCountryAccess({
			country: selectedCountry,
			users: userIds,
		})
			.then((response) => {
				const tableHead = response.data[0].countries.map((item) => {
					return { title: item.country, key: item.country };
				});
				setTableHeadings([{ title: "User Name", key: "fullName" }, ...tableHead]);
				const values = response.data.map((item) => {
					const obj = {};
					if (_.isArray(response.data)) {
						item.countries.map((val) => {
							obj[val.country] = val.isAccessible === true ? "yes" : "No";
						});
					}
					return { fullName: item.fullName, ...obj };
				});
				setTableData(values);
				setSkeleton(false);
				dispatch(loader(false));
			})
			.catch((e) => {
				dispatch(loader(false));
				setSkeleton(false);
			});
	};

	const onApplyFilter = () => {
		let userIds = selectedUsers.map((item) => {
			return String(users.filter((x) => x.value === item)[0].id);
		});
		const startDate = dataFormat(selectedDate[0], "MM/dd/yyyy");
		const endDate = dataFormat(selectedDate[1], "MM/dd/yyyy");
		setFilters({
			country: selectedCountry,
			date: startDate + "-" + endDate,
			users: userIds,
		});
		getCountryAccessData(userIds);
	};

	const disableApplyFilter = () => {
		if (selectedCountry.length > 0) {
			return false;
		}
		return true;
	};

	const formatCountries = (data) => {
		const newarr = data.map((item, i) => {
			return {
				id: i,
				label: item,
				value: item,
				default: false,
			};
		});
		setCountries(newarr);
	};

	const onChangeUser = (data) => {
		setSelectedUsers(data);
	};

	useEffect(() => {
		if (countryData.length === 0 && !isCountryAPICall) {
			setIsCountryAPICall(true);
			countryList();
		} else {
			formatCountries(countryData);
		}
	}, []);

	return (
		<Grid container>
			<Grid item md={12} className="m-b-20">
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item xs={12} sm={12} md={5}>
						<Tooltip title={"Net Revenue Realization Dashboard Reports"} placement="top">
							<Title data-testid="main-title" className="ellipsis">
								Usage Activity Report
							</Title>
						</Tooltip>
					</Grid>
					<Grid item xs={12} sm={12} md={7}>
						<Grid container alignItems={"center"} justifyContent={"space-between"}>
							<Grid item md={3} sm={3}>
								<Dropdown
									keyText="Report-country-dropdown"
									data={countries}
									multiple={true}
									multipleSelectionMsg="Multiple"
									placeholder="Select Country"
									allOption={true}
									onChange={(data) => {
										onChangeCountry(data);
									}}
								/>
							</Grid>
							<Grid item md={3} sm={3}>
								<Dropdown
									keyText="report-user-dropdown"
									data={users}
									multiple={true}
									multipleSelectionMsg="Multiple"
									placeholder="Select Users"
									allOption={true}
									defaultOption={selectedUsers}
									onChange={(data) => {
										onChangeUser(data);
									}}
								/>
							</Grid>
							<Grid item md={3} sm={3}>
								<CustomDateRangePicker defaultDate={selectedDate} callback={setSelectedDate} />
							</Grid>
							<Grid item md={2} sm={2} textAlign={"end"}>
								<PrimaryBtn disabled={disableApplyFilter()} color="primary" onClick={onApplyFilter}>
									Apply Filter
								</PrimaryBtn>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={12} className="m-b-20">
				<Card>
					<CardContent>
						<BlackTitle style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>Country Access</BlackTitle>
						{skeleton ? (
							<Grid className="m-t-20">
								<CustomTable
									heading={[1, 2, 3, 4, 5, 6]}
									value={[
										[1, 2, 3, 4, 5, 6],
										[1, 2, 3, 4, 5, 6],
										[1, 2, 3, 4, 5, 6],
									]}
									showSkeleton={skeleton}
								/>
							</Grid>
						) : tableData?.length > 0 ? (
							<Grid container spacing={2}>
								<Grid item md={12} width={"50vw"}>
									<CustomTable heading={tableHeadings} value={tableData} stickyHeader={true} maxHeight={400} />
								</Grid>
							</Grid>
						) : (
							<Grid container display="flex" justifyContent="center" alignItems="center" style={{ height: 400 }}>
								<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
							</Grid>
						)}
					</CardContent>
				</Card>
			</Grid>
			<Grid item md={12}>
				{multiLevelTableConfig.map((item, i) => (
					<MultilevelTableContainer
						headers={item.headers}
						queryFunction={item.queryFunction}
						title={item.title}
						filters={filters}
						child={item.child}
						key={`multi-level-table-${i}`}
					/>
				))}
			</Grid>
		</Grid>
	);
};

export default UsageReport2;
