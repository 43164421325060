import {
	IFilterPayload,
	IGetRealVsExpectedPayload,
	IGetWeeklyMonthlyAccumulatedDataPayload,
	IGetWeeksPayload,
	IInaseFilters,
	ISelectedFilters,
	IUnitVariationExpectedPayload,
	SimulationDetailsPayload,
} from "../../types/common";

/* Creates a GraphQL query to inasefilters values based on country , geoLevel , channel and geoLevel2.
 * @param {IInaseFilters} payload - The object contains inase filter request payload.
 * @returns The GraphQL query.
 */
export const GET_INASE_QUERY = (payload: IInaseFilters) => {
	return {
		query: `{
      inaseFilters(
        country: "${payload.country}"
        geoLevel: ${JSON.stringify(payload.geoLevel)}
        channel: ${JSON.stringify(payload.channel)}
        geoLevel2: ${payload.geoLevel2 ? `"${payload.geoLevel2}"` : null}
      ) {
        label
        value
      }
    }`,
	};
};

/* Creates a GraphQL query to update unit variation expected dashboard records.
 * @param {IUnitVariationExpectedPayload} payload - The object contains unit variation expected dashboard request payload.
 * @returns The GraphQL query.
 */
export const UNIT_VARIATIONS_EXPECTED_QUERY = (payload: IUnitVariationExpectedPayload) => {
	return {
		query: `{
      unitVariationsExpected(
        country: "${payload.country}"
        geoLevel: ${JSON.stringify(payload.geoLevel)}
        channel: ${JSON.stringify(payload.channel)}
        dataSource: "${payload.dataSource}"
        regions: ${JSON.stringify(payload.regions)}
        category: ${JSON.stringify(payload.category)}
        segment: ${JSON.stringify(payload.segment)}
        brand: ${JSON.stringify(payload.brand)}
        subBrand: ${JSON.stringify(payload.subBrand)}
        packSize: ${JSON.stringify(payload.packSize)}
        measureFilters: ${payload.measureFilters ? formatFilterList(payload.measureFilters) : null}
        date: "${payload.date}"
      )
			{
				brands {
					parent {
						parentName
						name
						imageName
						displayName
						week {
							priceEffect
							gramEffect
							totalEffect
							date
						}
						fullYear {
							priceEffect
							gramEffect
							totalEffect
							date
						}
					}
					child {
						parentName
						name
						imageName
						displayName
						week {
							priceEffect
							gramEffect
							totalEffect
							date
						}
						fullYear {
							priceEffect
							gramEffect
							totalEffect
							date
						}
					}
				}
				allBrands {
					parent {
						parentName
						name
						imageName
						displayName
						week {
							priceEffect
							gramEffect
							totalEffect
							date
						}
						fullYear {
							priceEffect
							gramEffect
							totalEffect
							date
						}
					}
					child {
						parentName
						name
						imageName
						displayName
						week {
							priceEffect
							gramEffect
							totalEffect
							date
						}
						fullYear {
							priceEffect
							gramEffect
							totalEffect
							date
						}
					}
				}
			}
		}
    `,
	};
};

/* Creates a GraphQL query to fetch list of adjustable filters
 * @param selectedFilters - Holds values of selections made from the filters
 * @returns The GraphQL query.
 */
export const ADJUSTABLE_FILTERS = (selectedFilters: ISelectedFilters) => {
	return {
		query: `{
      adjustablefilters(
        dataSource: "${selectedFilters.dataSource}"
        date: "${selectedFilters.date}"
        country: "${selectedFilters.country}"
        geoLevel: ${JSON.stringify(selectedFilters.geoLevel)}
        channel: ${JSON.stringify(selectedFilters.channel)}
        geoLevel2: ${JSON.stringify(selectedFilters.geoLevel2)}
        category: ${JSON.stringify(selectedFilters.category)}
        segment: ${JSON.stringify(selectedFilters.segment)}
        brand: ${JSON.stringify(selectedFilters.brand)}
        packSize: ${JSON.stringify(selectedFilters.packSize)}
        permutation: ${JSON.stringify(selectedFilters.permutation)}
        regions: ${JSON.stringify(selectedFilters.regions)}
        subBrand: ${JSON.stringify(selectedFilters.subBrand)}
      ) {
        productName
        productInfo
        subBrandName
        packSize
        displayName
        pricePerUnitFrom
        pricePerUnitTo
        pricePerUnitVar
        gramPerUnitFrom
        gramPerUnitTo
        gramPerUnitVar
        priceElasticityExpected
        gramsElasticityExpected
        modelPriceElasticity
        modelGramsElasticityCurve
        date
        mixSellIn
        mixSellInBrandLevel
      } }
    `,
	};
};

/* Creates a GraphQL query to get Inase Max Date.
 * @returns The GraphQL query.
 */
export const GET_INASE_MAX_DATE_QUERY = () => {
	return {
		query: `{
      getInaseMaxDate {
        lastRefreshDate
      }
    }`,
	};
};

/* Creates a key value pair against array of objects.
 * @returns key value pair.
 */
function formatFilterList(arr: any[]): string {
	let result = "[";
	for (const obj of arr) {
		result += "  {";
		for (const key of Object.keys(obj)) {
			const value = obj[key];
			const formattedValue = typeof value === "string" ? `"${value}"` : value;
			result += `${key}: ${formattedValue},`;
		}
		result += "},";
	}
	result += "]";
	return result;
}

/* Creates a GraphQL query to get weeks data for elasticity pricing track records.
 * @param {IGetWeeksPayload} payload - The object contains get weeks dashboard request payload.
 * @returns The GraphQL query.
 */
export const GET_WEEKS_DATA_QUERY = (payload: IGetWeeksPayload) => {
	return {
		query: `{
      getWeeks
      (
        country: "${payload.country}"
        geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
        channel: ${payload.channel ? JSON.stringify(payload.channel) : null}
        geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
        dataSource: "${payload.dataSource}"
        regions: ${JSON.stringify(payload.regions)}
        category: ${payload.category ? JSON.stringify(payload.category) : null}
        segment: ${payload.segment ? JSON.stringify(payload.segment) : null}
        brand: ${payload.brand ? JSON.stringify(payload.brand) : null}
        subBrand: ${payload.subBrand ? JSON.stringify(payload.subBrand) : null}
        packSize: ${payload.packSize ? JSON.stringify(payload.packSize) : null}
        permutation: ${payload.permutation ? JSON.stringify(payload.permutation) : null}
        measureFilters: ${payload.measureFilters ? formatFilterList(payload.measureFilters) : null}
        date: "${payload.date}"
        pricingDate: "${payload.pricingDate}"
      ) {
        id
        value
      }
    }`,
	};
};

/* Creates a GraphQL query to get Real vs Expected data.
 * @param {IGetRealVsExpectedPayload} payload - Holds values of selections made from the filters
 * @returns The GraphQL query.
 */
export const GET_REAL_VS_EXPECTED_DATA = (payload: IGetRealVsExpectedPayload) => {
	return {
		query: ` {
			realVsExpected(
				variationType: "${payload.variationType}"
				measureFilters: ${payload.measureFilters ? formatFilterList(payload.measureFilters) : null}
				dataSource: "${payload.dataSource}"
				regions: ${JSON.stringify(payload.regions)}
				permutation: ${JSON.stringify(payload.permutation)}
				category: ${JSON.stringify(payload.category)}
				segment: ${JSON.stringify(payload.segment)}
				brand: ${JSON.stringify(payload.brand)}
				subBrand: ${JSON.stringify(payload.subBrand)}
				packSize: ${JSON.stringify(payload.packSize)}
				date: "${payload.date}"
				country: "${payload.country}"
				channel: ${JSON.stringify(payload.channel)}
				geoLevel: ${JSON.stringify(payload.geoLevel)}
				geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
			) {
				brands {
					parent {
						parentName
						name
						imageName
						displayName
						accumulatedResult {
							real
							expected
							date
						}
						weekData1 {
							real
							expected
							date
						}
						monthly: Monthly {
							real
							expected
							date
						}
						quarterly: Quarterly {
							real
							expected
							date
						}
						yearly: Yearly {
							real
							expected
							date
						}
					}
					child {
						parentName
						name
						imageName
						displayName
						accumulatedResult {
							real
							expected
							date
						}
						weekData1 {
							real
							expected
							date
						}
						monthly: Monthly {
							real
							expected
							date
						}
						quarterly: Quarterly {
							real
							expected
							date
						}
						yearly: Yearly {
							real
							expected
							date
						}
					}
				}
				allBrands {
					parent {
						parentName
						name
						imageName
						displayName
						accumulatedResult {
							real
							expected
							date
						}
						weekData1 {
							real
							expected
							date
						}
						monthly: Monthly {
							real
							expected
							date
						}
						quarterly: Quarterly {
							real
							expected
							date
						}
						yearly: Yearly {
							real
							expected
							date
						}
					}
					child {
						parentName
						name
						imageName
						displayName
						accumulatedResult {
							real
							expected
							date
						}
						weekData1 {
							real
							expected
							date
						}
						monthly: Monthly {
							real
							expected
							date
						}
						quarterly: Quarterly {
							real
							expected
							date
						}
						yearly: Yearly {
							real
							expected
							date
						}
					}
				}
			}
		}
		`,
	};
};

/* Creates a GraphQL query to get Weekly or Monthly Accumulated Data.
 * @param {IGetWeeklyMonthlyAccumulatedDataPayload} payload - Holds values of selections made from the filters
 * @returns The GraphQL query.
 */
export const GET_WEEKLY_MONTHLY_ACCUMULATED_DATA_QUERY = (payload: IGetWeeklyMonthlyAccumulatedDataPayload) => {
	return {
		query: `{
			weeklyOrMonthlyAccumulated(
				filtervisual2units: ${JSON.stringify(payload.filtervisual2units)}
				filtervisual2dates: ${JSON.stringify(payload.filtervisual2dates)}
				measureFilters: ${payload.measureFilters ? formatFilterList(payload.measureFilters) : null}
				dataSource: "${payload.dataSource}"
				regions: ${JSON.stringify(payload.regions)}
				permutation: ${JSON.stringify(payload.permutation)}
				category: ${JSON.stringify(payload.category)}
				segment: ${JSON.stringify(payload.segment)}
				brand: ${JSON.stringify(payload.brand)}
				subBrand: ${JSON.stringify(payload.subBrand)}
				packSize: ${JSON.stringify(payload.packSize)}
				date: "${payload.date}"
				startDate: ${payload.startDate ? `"${payload.startDate}"` : null}
				endDate: ${payload.endDate ? `"${payload.endDate}"` : null}
				country: "${payload.country}"
				channel: ${JSON.stringify(payload.channel)}
				geoLevel: ${JSON.stringify(payload.geoLevel)}
				geoLevel2: ${payload.geoLevel2 ? `"${payload.geoLevel2}"` : null}
			) {
				brands {
					parent {
						parentName
						name
						mixSales
						imageName
						displayName
						weekly {
							salesIndex {
								absoluteMM
								vsYAGO
							}
							unitsIndex {
								absoluteMM
								vsYAGO
							}
							volumeIndex {
								absoluteMM
								vsYAGO
							}
							ppuPricing
							realUnitsVariation
							realElasticity
							expectedElasticity
							expectedUnitsVariation
						}
						accumulated {
							salesIndex {
								absoluteMM
								vsYAGO
							}
							unitsIndex {
								absoluteMM
								vsYAGO
							}
							volumeIndex {
								absoluteMM
								vsYAGO
							}
							ppuPricing
							realUnitsVariation
							realElasticity
							expectedElasticity
							expectedUnitsVariation
						}
					}
					child {
						parentName
						name
						mixSales
						imageName
						displayName
						weekly {
							salesIndex {
								absoluteMM
								vsYAGO
							}
							unitsIndex {
								absoluteMM
								vsYAGO
							}
							volumeIndex {
								absoluteMM
								vsYAGO
							}
							ppuPricing
							realUnitsVariation
							realElasticity
							expectedElasticity
							expectedUnitsVariation
						}
						accumulated {
							salesIndex {
								absoluteMM
								vsYAGO
							}
							unitsIndex {
								absoluteMM
								vsYAGO
							}
							volumeIndex {
								absoluteMM
								vsYAGO
							}
							ppuPricing
							realUnitsVariation
							realElasticity
							expectedElasticity
							expectedUnitsVariation
						}
					}
				}
			}
		}`,
	};
};

/* Creates a GraphQL query to fetch api simulation KPI and chart data.
 * @param {SimulationDetailsPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const SIMULATION_DETAILS_QUERY = (payload: SimulationDetailsPayload) => {
	return {
		query: `{
			simulationDetails(
				country: "${payload.country}"
				geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
				channel: ${payload.channel ? JSON.stringify(payload.channel) : null}
				geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
				dataSource: "${payload.dataSource}"
				regions: ${payload.regions ? JSON.stringify(payload.regions) : null}
				category: ${payload.category ? JSON.stringify(payload.category) : null}
				segment: ${payload.segment ? JSON.stringify(payload.segment) : null}
				brand: ${payload.brand ? JSON.stringify(payload.brand) : null}
				subBrand: ${payload.subBrand ? JSON.stringify(payload.subBrand) : null}
				packSize: ${payload.packSize ? JSON.stringify(payload.packSize) : null}
				permutation: ${payload.permutation ? JSON.stringify(payload.permutation) : null}
				measureFilters: ${payload.measureFilters ? formatFilterList(payload.measureFilters) : null}
				date: "${payload.date}"
				filtervisual4units: "${payload.filtervisual4units}"
			) {
				brands {
					parentName: ParentName
					name: Name
					imageName: ImageName
					displayName: DisplayName
					pricingDate: PricingDate
					weekly {
						modelInput: ModelInput
						real: Real
						noPricingSimulation: NoPricingSimulation
						baseLine: BaseLine
						date: Date
					}
					monthly {
						modelInput: ModelInput
						real: Real
						noPricingSimulation: NoPricingSimulation
						baseLine: BaseLine
						date: Date
					}
					quarterly {
						modelInput: ModelInput
						real: Real
						noPricingSimulation: NoPricingSimulation
						baseLine: BaseLine
						date: Date
					}
					yearly {
						modelInput: ModelInput
						real: Real
						noPricingSimulation: NoPricingSimulation
						baseLine: BaseLine
						date: Date
					}
				}
				allBrands {
					parentName: ParentName
					name: Name
					imageName: ImageName
					displayName: DisplayName
					pricingDate: PricingDate
				}
				variableTrends {
					parentName: ParentName
					name: Name
					imageName: ImageName
					displayName: DisplayName
					pricingDate: PricingDate
					rSquare: rSquare
					weekly {
						inflationRate: InflationRate {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidPositiveRate: HtCovidPositiveRate {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansPersonalAndMicroCreditsind: LoansPersonalAndMicroCreditsind {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalCases: HtCovidTotalCases {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						cciIndicator: CciIndicator {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						minWageNatPesosday: MinWageNatPesosday {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalDeaths: HtCovidTotalDeaths {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansCreditCards: LoansCreditCards {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						mbtyRetailRecreationPercentchangeFromBaseline: MbtyRetailRecreationPercentchangeFromBaseline {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansPayroll: LoansPayroll {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiPrimaryActivities: PpiPrimaryActivities {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						wrAvgMoneyorders: WrAvgMoneyorders {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						minWageNatIndex: MinWageNatIndex {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiTotalProduction: PpiTotalProduction {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolinePremium: GasolinePremium {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						cpiPriceToConsumer: CpiPriceToConsumer {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						unemploymentRateTrendCycle: UnemploymentRateTrendCycle {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalVaccinations: HtCovidTotalVaccinations {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineRegular: GasolineRegular {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineDiesel: GasolineDiesel {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						mbtyGroceryPharmacyPercentchangeFromBaseline: MbtyGroceryPharmacyPercentchangeFromBaseline {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						unemploymentRateSznadj: UnemploymentRateSznadj {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiTotalProductionWithoutPetrol: PpiTotalProductionWithoutPetrol {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidNewCases: HtCovidNewCases {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						vpu: Vpu {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						distribution: Distribution {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						seasonality: Seasonality {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						trend: Trend {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						totalHoliday: TotalHoliday {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineAverage: GasolineAverage {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppu: Ppu {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						edrp: Edrp {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
					}
					monthly {
						inflationRate: InflationRate {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidPositiveRate: HtCovidPositiveRate {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansPersonalAndMicroCreditsind: LoansPersonalAndMicroCreditsind {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalCases: HtCovidTotalCases {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						cciIndicator: CciIndicator {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						minWageNatPesosday: MinWageNatPesosday {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalDeaths: HtCovidTotalDeaths {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansCreditCards: LoansCreditCards {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						mbtyRetailRecreationPercentchangeFromBaseline: MbtyRetailRecreationPercentchangeFromBaseline {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansPayroll: LoansPayroll {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiPrimaryActivities: PpiPrimaryActivities {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						wrAvgMoneyorders: WrAvgMoneyorders {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						minWageNatIndex: MinWageNatIndex {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiTotalProduction: PpiTotalProduction {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolinePremium: GasolinePremium {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						cpiPriceToConsumer: CpiPriceToConsumer {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						unemploymentRateTrendCycle: UnemploymentRateTrendCycle {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalVaccinations: HtCovidTotalVaccinations {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineRegular: GasolineRegular {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineDiesel: GasolineDiesel {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						mbtyGroceryPharmacyPercentchangeFromBaseline: MbtyGroceryPharmacyPercentchangeFromBaseline {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						unemploymentRateSznadj: UnemploymentRateSznadj {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiTotalProductionWithoutPetrol: PpiTotalProductionWithoutPetrol {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidNewCases: HtCovidNewCases {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						vpu: Vpu {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						distribution: Distribution {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						seasonality: Seasonality {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						trend: Trend {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						totalHoliday: TotalHoliday {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineAverage: GasolineAverage {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppu: Ppu {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						edrp: Edrp {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
					}
					quarterly {
						inflationRate: InflationRate {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidPositiveRate: HtCovidPositiveRate {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansPersonalAndMicroCreditsind: LoansPersonalAndMicroCreditsind {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalCases: HtCovidTotalCases {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						cciIndicator: CciIndicator {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						minWageNatPesosday: MinWageNatPesosday {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalDeaths: HtCovidTotalDeaths {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansCreditCards: LoansCreditCards {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						mbtyRetailRecreationPercentchangeFromBaseline: MbtyRetailRecreationPercentchangeFromBaseline {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansPayroll: LoansPayroll {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiPrimaryActivities: PpiPrimaryActivities {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						wrAvgMoneyorders: WrAvgMoneyorders {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						minWageNatIndex: MinWageNatIndex {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiTotalProduction: PpiTotalProduction {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolinePremium: GasolinePremium {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						cpiPriceToConsumer: CpiPriceToConsumer {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						unemploymentRateTrendCycle: UnemploymentRateTrendCycle {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalVaccinations: HtCovidTotalVaccinations {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineRegular: GasolineRegular {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineDiesel: GasolineDiesel {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						mbtyGroceryPharmacyPercentchangeFromBaseline: MbtyGroceryPharmacyPercentchangeFromBaseline {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						unemploymentRateSznadj: UnemploymentRateSznadj {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiTotalProductionWithoutPetrol: PpiTotalProductionWithoutPetrol {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidNewCases: HtCovidNewCases {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						vpu: Vpu {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						distribution: Distribution {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						seasonality: Seasonality {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						trend: Trend {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						totalHoliday: TotalHoliday {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineAverage: GasolineAverage {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppu: Ppu {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						edrp: Edrp {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
					}
					yearly {
						inflationRate: InflationRate {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidPositiveRate: HtCovidPositiveRate {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansPersonalAndMicroCreditsind: LoansPersonalAndMicroCreditsind {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalCases: HtCovidTotalCases {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						cciIndicator: CciIndicator {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						minWageNatPesosday: MinWageNatPesosday {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalDeaths: HtCovidTotalDeaths {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansCreditCards: LoansCreditCards {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						mbtyRetailRecreationPercentchangeFromBaseline: MbtyRetailRecreationPercentchangeFromBaseline {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						loansPayroll: LoansPayroll {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiPrimaryActivities: PpiPrimaryActivities {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						wrAvgMoneyorders: WrAvgMoneyorders {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						minWageNatIndex: MinWageNatIndex {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiTotalProduction: PpiTotalProduction {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolinePremium: GasolinePremium {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						cpiPriceToConsumer: CpiPriceToConsumer {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						unemploymentRateTrendCycle: UnemploymentRateTrendCycle {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidTotalVaccinations: HtCovidTotalVaccinations {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineRegular: GasolineRegular {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineDiesel: GasolineDiesel {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						mbtyGroceryPharmacyPercentchangeFromBaseline: MbtyGroceryPharmacyPercentchangeFromBaseline {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						unemploymentRateSznadj: UnemploymentRateSznadj {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppiTotalProductionWithoutPetrol: PpiTotalProductionWithoutPetrol {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						htCovidNewCases: HtCovidNewCases {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						vpu: Vpu {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						distribution: Distribution {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						seasonality: Seasonality {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						trend: Trend {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						totalHoliday: TotalHoliday {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						gasolineAverage: GasolineAverage {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						ppu: Ppu {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
						edrp: Edrp {
							date: Date
							value: Value
							outlier: Outlier
							outlierLabel: OutlierLabel
						}
					}
					units: Units {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					sales: Sales {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					volume: Volume {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					vpu: Vpu {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					edrp: Edrp {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					ppu: Ppu {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					distribution: Distribution {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					seasonality: Seasonality {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					trend: Trend {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					holidays: Holidays {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					inflationRate: InflationRate {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					htCovidPositiveRate: HtCovidPositiveRate {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					loansPersonalAndMicroCreditsind: LoansPersonalAndMicroCreditsind {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					htCovidTotalCases: HtCovidTotalCases {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					cciIndicator: CciIndicator {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					minWageNatPesosday: MinWageNatPesosday {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					htCovidTotalDeaths: HtCovidTotalDeaths {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					loansCreditCards: LoansCreditCards {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					mbtyRetailRecreationPercentchangeFromBaseline: MbtyRetailRecreationPercentchangeFromBaseline {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					loansPayroll: LoansPayroll {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					ppiPrimaryActivities: PpiPrimaryActivities {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					wrAvgMoneyorders: WrAvgMoneyorders {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					minWageNatIndex: MinWageNatIndex {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					ppiTotalProduction: PpiTotalProduction {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					gasolinePremium: GasolinePremium {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					cpiPriceToConsumer: CpiPriceToConsumer {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					unemploymentRateTrendCycle: UnemploymentRateTrendCycle {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					htCovidTotalVaccinations: HtCovidTotalVaccinations {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					gasolineRegular: GasolineRegular {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					gasolineDiesel: GasolineDiesel {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					mbtyGroceryPharmacyPercentchangeFromBaseline: MbtyGroceryPharmacyPercentchangeFromBaseline {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					unemploymentRateSznadj: UnemploymentRateSznadj {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					ppiTotalProductionWithoutPetrol: PpiTotalProductionWithoutPetrol {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					htCovidNewCases: HtCovidNewCases {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					totalHoliday: TotalHoliday {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
					gasolineAverage: GasolineAverage {
						label: Label
						avgBeforePricing: AvgBeforePricing
						avgAfterPricing: AvgAfterPricing
						varAbs: VarAbs
						varPercentage: VarPercentage
						variableContribution: VariableContribution
					}
				}
				productdcElasticities {
					productName: ProductName
					dcElasticities {
						key: key
						value: value
					}
				}
				regionElasticities {
					productName: productName
					model: model
					regionCalculation {
						modelType: modelType
						region: region
						mix: mix
						elasticity: elasticity
						r2Value {
							value: value
							colorCode: colorCode
						}
						pValue {
							value: value
							colorCode: colorCode
						}
						mapeValue {
							value: value
							colorCode: colorCode
						}
					}
					weightedAvg {
						title: title
						mixSales: mixSales
						elasticity: elasticity
						r2Value: r2Value
						pValue: pValue
						mapeValue: mapeValue
					}
				}
				simulationKeyEventsResponses {
					weeklyKeyEvents {
						start: start
						end: end
						overlap: overlap
						name: name
					}
					monthlyKeyEvents {
						start: start
						end: end
						overlap: overlap
						name: name
					}
					quarterlyKeyEvents {
						start: start
						end: end
						overlap: overlap
						name: name
					}
					yearlyKeyEvents {
						start: start
						end: end
						overlap: overlap
						name: name
					}
				}
			}
		}`,
	};
};

/* Creates a GraphQL query to get common anchor product filters for EPT dashboard.
 * @param {IFilterPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const EPT_PRODUCT_FILTER_QUERY = (payload: IFilterPayload, userId: string) => {
	return {
		query: `{
      elasticityPriceTrackingFilters(country: "${payload.country}"
      UserId: "${userId}"
			)
		}`,
	};
};
