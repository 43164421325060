import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const EditBtn = styled(Button)(({ theme }) => ({
	color: theme.palette.secondary.light,
	fontSize: "12px",
}));

const PrimaryBtn = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.light,
	color: theme.palette.primary.main,
	fontSize: "12px",
	padding: "10px 20px",
	height: "40px",
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.light,
	},
}));

const DeleteBtn = styled(Button)(({ theme }) => ({
	color: theme.palette.secondary.light,
	fontSize: "14px",
}));

export { EditBtn, PrimaryBtn, DeleteBtn };
