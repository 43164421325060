import React from "react";
import { CardContent, List, ListItem, Grid } from "@mui/material";
import { Title, StyledCard, ListItemButton, Item } from "../../MMPW.Styled";
import { CloseButton } from "../../../../styles/Common.Styled";

const FontFamily: React.FC<{ fontFamily?; callback? }> = ({ fontFamily, callback }) => {
	const fontFamilyList = ["Arial", "Georgia", "Garamond", "Helvetica", "monospace", "Roboto", "Verdana", "Trebuchet MS", "Tahoma", "Times New Roman"];
	const onSelectFontFamily = (fontFamily) => {
		callback({ type: "fontFamily", fontFamily });
	};
	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={12} display="flex" alignContent="center">
						<Title>Font Family</Title>
						<CloseButton style={{ right: 10, top: 10, height: 20, width: 20 }} onClick={() => onSelectFontFamily(fontFamily)}>
							x
						</CloseButton>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<List>
								{fontFamilyList.map((family, i) =>
									family === fontFamily ? (
										<Item key={`selected-font-family-${i}`}>{fontFamily}</Item>
									) : (
										<ListItem key={`font-family-${i}`} disablePadding onClick={() => onSelectFontFamily(family)}>
											<ListItemButton>{family}</ListItemButton>
										</ListItem>
									)
								)}
							</List>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>
	);
};

export default FontFamily;
