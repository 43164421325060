import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";

const Indicator: React.FC<{
	position?;
	show?;
}> = ({ position = "fixed", show = null }) => {
	const [showLoader, setShowLoader] = useState();
	const reduxState = useSelector((state: any) => state);

	useEffect(() => {
		if (show === null) {
			setShowLoader(reduxState.common.loader);
		} else {
			setShowLoader(show);
		}
	}, [show, reduxState.common.loader]);

	return (
		<>
			{showLoader && (
				<Box
					style={{
						position,
						height: "100%",
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(0,0,0,0.5)",
						zIndex: 999,
					}}
				>
					<Loader type="Puff" color="#00BFFF" height={100} width={100} visible={true} />
				</Box>
			)}
		</>
	);
};

export default Indicator;
