import { baseApi, graphqlAPI } from "../../store/api-constants";
import { SomCorrelationPayload } from "../../types/common";
import { API } from "../../util/helper";
import { MULTIPLE_API_PRODUCT_QUERY, SOM_CORRELATION_DATA_TABLE_QUERY, SOM_CORRELATION_QUERY } from "../../util/queries/apiPredictiveDashboard";

export const getProductFilters = async (data) => {
	try {
		const baseApiUrl: string = await baseApi();
		return await API.post(`${baseApiUrl}filter/products`, data);
	} catch (e) {
		return e;
	}
};

/* Calls a GraphQL API to get multiple API product filter for API Predictive dashboard.
 * @param {number} data - The object contains userId.
 * @param {any} data - The object contains filter request payload object.
 * @param {string} type - The object contains type of filters.
 * @returns The brand ladder max date.
 */
export const getMultipleApiProducts = async (userId, data) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = MULTIPLE_API_PRODUCT_QUERY(data, userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.multipleapiproducts;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};

/* Calls a GraphQL API to get correlation chart data for API Predictive dashboard.
 * @param {SomCorrelationPayload} payload - The object contains filter request payload.
 * @returns correlation chart data for API Predictive dashboard.
 */
export const getCorrelationChartData = async (payload: SomCorrelationPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = SOM_CORRELATION_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.somcorrelation;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

/* Calls a GraphQL API to get correlation table data for API Predictive dashboard.
 * @param {SomCorrelationPayload} payload -  The object contains filter request payload.
 * @returns correlation table data for API Predictive dashboard.
 */
export const getCorrelationTableData = async (payload: SomCorrelationPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = SOM_CORRELATION_DATA_TABLE_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.somCorrelationDataTable;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

export const getAPIOptimizationChartData = async (payload) => {
	const baseApiUrl: string = await baseApi();
	return API.post(`${baseApiUrl}dashboards/api/optimization`, payload);
};

export const getG5ChartData = async (payload) => {
	const baseApiUrl: string = await baseApi();
	return API.post(`${baseApiUrl}dashboards/api/g5`, payload);
};

export const getAPISimulationChartData = async (payload) => {
	const baseApiUrl: string = await baseApi();
	return API.post(`${baseApiUrl}dashboards/api/simulationSaleOut`, payload);
};

export const getAPISimulationValuesChartData = async (payload) => {
	const baseApiUrl: string = await baseApi();
	return API.post(`${baseApiUrl}dashboards/api/simulationvalues`, payload);
};

export const getMultipleAPIChartData = async (payload) => {
	const baseApiUrl: string = await baseApi();
	return API.post(`${baseApiUrl}dashboards/api/multipleApis`, payload);
};
