import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import CustomTable from "../CustomTable";
import { tableHeading, tableSkeletonData } from "./config";
import { getDashboardSource } from "./service";
import { BlackTitle, NoData } from "../../styles/Common.Styled";
import { messages } from "../../util/config";
import _ from "lodash";

const DashboardSourceList: React.FC<{
	appliedFilters;
	dashboardName: string;
	category;
	sellInSellOutData?: string;
	title?: string;
}> = ({ appliedFilters, dashboardName, category, sellInSellOutData = null, title = "Data Sources" }) => {
	const [skeleton, setSkeleton] = useState(true);
	const [values, setValues] = useState([]);
	useEffect(() => {
		if (appliedFilters.country && dashboardName) {
			setValues([]);
			setSkeleton(true);

			let dashboardSourceData = getDashboardSource({
				country: appliedFilters.country,
				geoLevel1: appliedFilters.geoLevel,
				geoLevel2: appliedFilters.geoLevel2,
				categories: category,
				dashboardName,
				...(sellInSellOutData ? getSellInSellOutString(sellInSellOutData) : { measureFilter: false }),
			});
			dashboardSourceData
				.then((response) => {
					setSkeleton(false);
					if (_.isArray(response.data)) {
						const keys = tableHeading.map((x) => x.key);
						const values = [...response.data].map((r) => {
							const obj = {};
							keys.map((k) => {
								obj[k] = r[k];
							});
							return obj;
						});
						setValues(values);
					}
				})
				.catch(() => {
					setSkeleton(false);
				});
		}
	}, [sellInSellOutData, appliedFilters, dashboardName, category]);

	const getSellInSellOutString = (key) => {
		if (key.toLowerCase() === "sell-in" || key.toLowerCase() === "sellin") {
			return { data: "Sell-In", measureFilter: true };
		} else if (key.toLowerCase() === "sell-out" || key.toLowerCase() === "sellout") {
			return { data: "Sell-Out", measureFilter: true };
		} else if (key.toLowerCase() === "inase") {
			return { data: "INASE", measureFilter: true };
		} else {
			return { measureFilter: false };
		}
	};

	return (
		<Card style={{ marginTop: 24 }}>
			<CardContent>
				<BlackTitle style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>{title}</BlackTitle>
				<>
					{skeleton ? (
						<Grid>
							<CustomTable heading={tableHeading} value={[...tableSkeletonData]} showSkeleton={skeleton} />
						</Grid>
					) : (
						<>
							{values.length > 0 ? (
								<>
									<Grid>
										<CustomTable heading={tableHeading} value={[...values]} showSkeleton={skeleton} />
									</Grid>
									<Grid className="m-t-10">
										<span>
											<p>NOTE: </p>
											<p>Refresh Date - The date on which the data pipelines were last run</p>
											<p>Max Date - Reflects the most current date by data source for which data is available in RevM 2.0</p>
											<p>Dashboard Use Date - The least common date across all data sources (for the week ending Friday) that is reflected in the dashboard.</p>
										</span>
									</Grid>
								</>
							) : (
								<Box style={{ height: 200 }} display="flex" justifyContent="center" alignItems="center">
									<NoData>{messages.noData}</NoData>
								</Box>
							)}
						</>
					)}
				</>
			</CardContent>
		</Card>
	);
};

export default DashboardSourceList;
