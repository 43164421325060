import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, Grid, InputBase, Paper, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import MixConstraint from "./MixConstraint";
import { MixPopupContext } from "../../MixOptimizer";
import Indicator from "../../../../components/Loader";
import { toast } from "../../../../store/actions/toast.action";
import { PrimaryBtn } from "../../../../styles/Common.Styled";
import FilterAccordion from "../../../../components/Filters";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import Dropdown from "../../../../components/Dropdown";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import {
	IMixConstraintData,
	IMixFilterConfigurations,
	IMixGeoFilterConfigurations,
	IMixGeoFilterOptions,
	IMixSelectedFilters,
	IMnAFilterConfigurations,
} from "../../../../types/mixAndAssortment";
import {
	mixGeoFilters,
	mixGeoFilterOrder,
	mixGoalOptions,
	mixOverallFilterOrder,
	mixOverallFilters,
	mixSelectedFiltersObject,
} from "../../../../mocks/mixOptimizer";
import { getMixAssortmentScenarios, getMixGeoFilters, getMixOverallFilters, saveMixScenario } from "../../../../util/mixAndAssortmentServices";

const generatePrefix = (country: string, dateTimePeriod: string, businessUnit: string, endTimePeriod: string) => {
	let prefix = "";
	if (country) prefix += country + "_";
	if (dateTimePeriod) prefix += dateTimePeriod + "_";
	if (businessUnit) prefix += businessUnit + "_";
	if (endTimePeriod) prefix += endTimePeriod + "_";
	return prefix;
};

/* Fetch geo filter options based on selected filter values.
 * @param {IMixSelectedFilters} selectedFilters - The object of selected filter values.
 * @param {IMixFilterConfigurations} mixFilterOptions - The object contains filter option values in hierarchical form.
 * @returns the object of filter option for geo filters.
 */
function fetchGeoFilterOptions(selectedFilters: IMixSelectedFilters, hierarchicalFilterOptions: IMixFilterConfigurations): IMnAFilterConfigurations {
	let levelOptions: string[] = [];
	let channelOptions: string[] = [];
	let regionOptions: string[] = [];
	let storeSegmentOptions: string[] = [];
	if (selectedFilters.country && selectedFilters.dateTimePeriod && selectedFilters.businessUnit && selectedFilters.endTimePeriod) {
		levelOptions = Array.from(new Set(hierarchicalFilterOptions.level));
		if (selectedFilters.level) {
			channelOptions = Array.from(
				new Set(
					hierarchicalFilterOptions.channel
						.filter((optionValues: IMixGeoFilterConfigurations) => {
							return selectedFilters.level === optionValues.level;
						})
						.map((optionValues: IMixGeoFilterConfigurations) => {
							return optionValues.value;
						})
				)
			);
		}
		if (selectedFilters.channel) {
			regionOptions = Array.from(
				new Set(
					hierarchicalFilterOptions.region
						.filter((optionValues: IMixGeoFilterConfigurations) => {
							return selectedFilters.level === optionValues.level && selectedFilters.channel === optionValues.channel;
						})
						.map((optionValues: IMixGeoFilterConfigurations) => {
							return optionValues.value;
						})
				)
			);
		}
		if (selectedFilters.region.length > 0) {
			storeSegmentOptions = Array.from(
				new Set(
					hierarchicalFilterOptions.storeSegment
						.filter((optionValues: IMixGeoFilterConfigurations) => {
							return (
								selectedFilters.level === optionValues.level &&
								selectedFilters.channel === optionValues.channel &&
								optionValues.region &&
								selectedFilters.region.indexOf(optionValues.region) !== -1
							);
						})
						.map((optionValues: IMixGeoFilterConfigurations) => {
							return optionValues.value;
						})
				)
			);
		}
	}
	const filterOptions: IMnAFilterConfigurations = {
		...hierarchicalFilterOptions,
		level: levelOptions,
		channel: channelOptions,
		region: regionOptions,
		storeSegment: storeSegmentOptions,
	};
	return filterOptions;
}

const TopFilters: FC<{
	selectedFilters: IMixSelectedFilters;
	setSelectedFilters: React.Dispatch<React.SetStateAction<IMixSelectedFilters>>;
	selectedScenario: string | null;
	isNewScenarioMode: boolean;
	isEditMode: boolean;
	closeScenario: () => void;
}> = ({ selectedFilters, setSelectedFilters, selectedScenario, isNewScenarioMode, isEditMode, closeScenario }) => {
	const dispatch = useDispatch();
	const setPopup = useContext(MixPopupContext).setPopup;
	const theme = useTheme();
	const userDetail = useSelector((state: any) => state.User.data);
	const linkedAssortmentTitle = "Linked Assortment Scenario";
	const [scenarioName, setScenarioName] = useState("");
	const [scenarioNamePrefix, setScenarioNamePrefix] = useState("");
	const [scenarioDates, setScenarioDates] = useState("");
	const [hierarchicalFilterOptions, setHierarchicalFilterOptions] = useState<IMixFilterConfigurations>();
	const [filterOptions, setFilterOptions] = useState<IMnAFilterConfigurations>();
	const [showLoader, setShowLoader] = useState(true);
	const [showGeoSkeleton, setShowGeoSkeleton] = useState(false);
	const [linkedAssortmentScenarios, setLinkedAssortmentScenarios] = useState<{ key: string; value: string }[] | undefined>([]);
	const [mixConstraints, setMixConstraints] = useState<IMixConstraintData[]>([]);

	const canSaveScenario =
		((isNewScenarioMode && scenarioName.length > 0) || (isEditMode && selectedScenario)) &&
		selectedFilters.country.length > 0 &&
		selectedFilters.dateTimePeriod.length > 0 &&
		selectedFilters.businessUnit.length > 0 &&
		selectedFilters.endTimePeriod.length > 0 &&
		selectedFilters.level.length > 0 &&
		selectedFilters.channel.length > 0 &&
		selectedFilters.region.length > 0 &&
		selectedFilters.storeSegment.length > 0 &&
		selectedFilters.goal.length > 0 &&
		selectedFilters.assortmentScenario.length > 0 &&
		mixConstraints.length > 0;

	const onFilterChange = (key: string, value: string[]) => {
		let filtersToUpdate = { [key]: ["region", "storeSegment"].includes(key) ? value : value[0] };
		// Resetting the cascading geo filters when the parent filter is changed. No break statements so that all child filters are reset.
		switch (key) {
			case "level":
				filtersToUpdate["channel"] = "";
			case "channel":
				filtersToUpdate["region"] = [];
			case "region":
				filtersToUpdate["storeSegment"] = [];
			default:
				break;
		}
		setSelectedFilters((prevState) => ({ ...prevState, ...filtersToUpdate }));
	};

	const saveScenario = (bypassDuplicateCheck: boolean) => {
		if (canSaveScenario) {
			setShowLoader(true);
			saveMixScenario(
				selectedScenario ?? scenarioNamePrefix + scenarioName,
				isNewScenarioMode,
				bypassDuplicateCheck,
				(userDetail.firstName + " " + userDetail.lastName).trim(),
				selectedFilters,
				mixConstraints
			)
				.then((resp) => {
					if (resp.error) setPopup({ show: true, title: "Error", message: "Encountered an error while saving the scenario. Please try again" });
					else if (resp.data) {
						if (resp.data.success) {
							setPopup({ show: true, title: "Success", message: `Successfully ${isNewScenarioMode ? "created" : "updated"} scenario ${resp.data.message}` });
							setSelectedFilters(mixSelectedFiltersObject);
							closeScenario();
						} else if (resp.data.duplicate) {
							setPopup({
								show: true,
								title: "Duplicate Scenario",
								message: `Scenario ${resp.data.message} has the same filter combination as this scenario. Do you want to proceed with creating this scenario?`,
								bypass: () => saveScenario(true),
							});
						} else {
							setPopup({ show: true, title: "Error", message: resp.data.message });
						}
					}
				})
				.finally(() => setShowLoader(false));
		}
	};

	useEffect(() => {
		getMixOverallFilters().then((resp) => {
			if (resp.error) dispatch(toast("Error in fetching overall filters options", true, 2000, "error"));
			else if (resp.data) setHierarchicalFilterOptions({ ...resp.data, level: [], channel: [], region: [], storeSegment: [] });
		});
	}, []);

	useEffect(() => {
		if (hierarchicalFilterOptions === undefined) setShowLoader(true);
		else setShowLoader(false);
	}, [hierarchicalFilterOptions]);

	useEffect(() => {
		if (filterOptions && isNewScenarioMode) {
			if (selectedFilters.goal === "") onFilterChange("goal", [mixGoalOptions[0].value]);
			if (filterOptions.country.length > 0 && !filterOptions.country.includes(selectedFilters.country)) onFilterChange("country", [filterOptions.country[0]]);
		}
	}, [filterOptions, isNewScenarioMode]);

	useEffect(() => {
		if (hierarchicalFilterOptions) setFilterOptions(fetchGeoFilterOptions(selectedFilters, hierarchicalFilterOptions));
	}, [selectedFilters, hierarchicalFilterOptions]);

	useEffect(() => {
		if (selectedFilters.dateTimePeriod && selectedFilters.endTimePeriod) {
			const months = (parseInt(selectedFilters.dateTimePeriod.replace("MM", "")) || 0) - 1;
			const monthString = "JanFebMarAprMayJunJulAugSepOctNovDec";
			const [endTimeMonth, endTimeYear] = selectedFilters.endTimePeriod.split("-");
			let startYear = parseInt(endTimeYear) || 0;
			let startMonth = monthString.indexOf(endTimeMonth) / 3 + 1 - months;
			if (startMonth <= 0) {
				startMonth += 12;
				startYear--;
			}
			const startTimeMonth = monthString.slice((startMonth - 1) * 3, startMonth * 3);
			const startTimeYear = startYear.toString();
			if (startTimeMonth && startTimeYear && endTimeMonth && endTimeYear)
				setScenarioDates(`${startTimeMonth} ${startTimeYear} - ${endTimeMonth} ${endTimeYear}`);
		} else setScenarioDates("");
	}, [selectedFilters.dateTimePeriod, selectedFilters.endTimePeriod]);

	useEffect(() => {
		setScenarioNamePrefix(generatePrefix(selectedFilters.country, selectedFilters.dateTimePeriod, selectedFilters.businessUnit, selectedFilters.endTimePeriod));
		if (
			(!isNewScenarioMode || hierarchicalFilterOptions) &&
			selectedFilters.country &&
			selectedFilters.dateTimePeriod &&
			selectedFilters.businessUnit &&
			selectedFilters.endTimePeriod
		) {
			setShowGeoSkeleton(true);
			// Resetting the geo filters when the overall filters are changed. Resetting the level filter will reset all child filters.
			if (isNewScenarioMode) onFilterChange("level", [""]);
			let geoFilters: IMixGeoFilterOptions = { level: [], channel: [], region: [], storeSegment: [] };
			getMixGeoFilters(selectedFilters.country, selectedFilters.dateTimePeriod, selectedFilters.businessUnit, selectedFilters.endTimePeriod)
				.then((resp) => {
					if (resp.error) dispatch(toast("Error in fetching geo filters options", true, 2000, "error"));
					else if (resp.data) geoFilters = resp.data;
				})
				.finally(() => {
					setHierarchicalFilterOptions((prevState) => {
						if (prevState) return { ...prevState, ...geoFilters };
					});
					setShowGeoSkeleton(false);
				});
		}
	}, [isNewScenarioMode, selectedFilters.country, selectedFilters.dateTimePeriod, selectedFilters.businessUnit, selectedFilters.endTimePeriod]);

	useEffect(() => {
		if ((isNewScenarioMode || isEditMode) && selectedFilters.businessUnit && selectedFilters.country) {
			setLinkedAssortmentScenarios(undefined);
			if (isNewScenarioMode) onFilterChange("assortmentScenario", [""]);
			getMixAssortmentScenarios(selectedFilters.country, selectedFilters.businessUnit).then((resp) => {
				if (resp.error) dispatch(toast("Error in fetching linked assortment scenarios", true, 2000, "error"));
				else if (resp.data) setLinkedAssortmentScenarios(resp.data);
			});
		}
	}, [isNewScenarioMode, selectedFilters.businessUnit, selectedFilters.country]);

	return (
		<Card className={"m-b-20"} sx={{ position: "relative" }}>
			<Indicator position="absolute" show={showLoader} />
			<CardContent style={{ padding: "20px 30px" }}>
				<Stack direction={"column"} gap={2}>
					<Stack direction={"row"} sx={{ alignItems: "center", marginLeft: "16px" }}>
						<Typography fontSize={16} color={theme.palette.common.black}>
							Scenario Name:
						</Typography>
						<Box className="m-l-5">
							{isNewScenarioMode ? (
								<>
									<Paper sx={{ display: "flex", alignItems: "center", border: "1px solid #E3E6EB", backgroundColor: "#FFFFFF" }}>
										{scenarioNamePrefix && (
											<Grid
												sx={{
													alignItems: "center",
													backgroundColor: "#D4D4D4",
													display: "flex",
													height: "43px",
													width: "auto",
													padding: "4px 0 4px 10px",
													fontSize: "14px",
												}}
											>
												{scenarioNamePrefix}
											</Grid>
										)}
										<InputBase
											placeholder={" Enter description"}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												setScenarioName(event.target.value);
											}}
											value={scenarioName}
											inputProps={{ maxLength: 15 }}
											sx={{ fontSize: "14px" }}
										/>
									</Paper>
									<Typography variant={"subtitle2"} color={"warning.main"}>
										{scenarioName.length >= 15 ? "Description has reached it's maximum limit of 15 characters" : ""}
									</Typography>
								</>
							) : (
								<Typography fontSize={14} fontWeight={600} color={theme.palette.common.black}>
									{selectedScenario}
								</Typography>
							)}
						</Box>
						<PrimaryBtn color={"primary"} onClick={closeScenario} sx={{ ml: "auto" }}>
							Back to Scenario Library
						</PrimaryBtn>
					</Stack>
					<FilterAccordion title={"Overall Filters"} subTitle={scenarioDates} expandFlag={true}>
						<Box sx={{ width: "720px" }}>
							<CommonMnAFilters
								data={mixOverallFilters}
								filterData={filterOptions}
								onChange={onFilterChange}
								defaultFilters={selectedFilters}
								filterOrder={mixOverallFilterOrder}
								disableAll={!isNewScenarioMode}
							/>
						</Box>
					</FilterAccordion>
					<FilterAccordion title={"Geo Filters"} expandFlag={true}>
						<Box sx={{ width: "720px" }}>
							<CommonMnAFilters
								data={mixGeoFilters}
								filterData={filterOptions}
								onChange={onFilterChange}
								defaultFilters={selectedFilters}
								filterOrder={mixGeoFilterOrder}
								disableAll={!(isNewScenarioMode || isEditMode)}
								showSkeleton={showGeoSkeleton}
							/>
						</Box>
					</FilterAccordion>
					{(isNewScenarioMode || isEditMode) && (
						<>
							<FilterAccordion title={"Goal"} expandFlag={true}>
								<RadioButtonGroup
									data={mixGoalOptions}
									color={theme.palette.common.black}
									direction={"row"}
									select={(selection) => onFilterChange("goal", [selection])}
									defaultOption={selectedFilters?.goal}
									disabled={!isNewScenarioMode}
								/>
							</FilterAccordion>
							<FilterAccordion title={"Decision Variables"} expandFlag={true}>
								<Box sx={{ width: "240px" }}>
									{linkedAssortmentScenarios ? (
										<>
											<DropdownTitle>{linkedAssortmentTitle}</DropdownTitle>
											<Dropdown
												disabled={false}
												data={linkedAssortmentScenarios}
												onChange={(selection) => onFilterChange("assortmentScenario", selection)}
												defaultOption={selectedFilters.assortmentScenario}
												placeholder={linkedAssortmentTitle}
											/>
										</>
									) : (
										<>
											<Skeleton height={24} />
											<Skeleton variant="rectangular" height={40} />
										</>
									)}
								</Box>
							</FilterAccordion>
							<FilterAccordion title={"Mix Constraint"} expandFlag={true}>
								<MixConstraint selectedScenario={selectedScenario} selectedFilters={selectedFilters} setMixConstraints={setMixConstraints} />
							</FilterAccordion>
							<PrimaryBtn color={"primary"} onClick={() => saveScenario(false)} sx={{ ml: "auto" }} disabled={!canSaveScenario}>
								Save Scenario & Run Simulation
							</PrimaryBtn>
						</>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default TopFilters;
