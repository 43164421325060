import React from "react";
import { Grid, Typography } from "@mui/material";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import TextField from "@mui/material/TextField";
import Dropdown from "../../../../components/Dropdown";
import dataObject from "../../../../mocks/priceRangePianoMock";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";

const gap = 10;

const MeasuresFilters: React.FC<{
	selectedMeasuresFilters;
	callback;
}> = ({ selectedMeasuresFilters, callback }) => {
	const onChangeValue = (val, i) => {
		const indexes = [...selectedMeasuresFilters.indexes];
		if (i <= indexes.length - 1) {
			indexes[i].end = val || " ";
			if (i !== 0) indexes[i - 1].start = val || " ";
		}
		callback({ ...selectedMeasuresFilters, indexes });
	};

	return (
		<Grid container display="flex" alignItems="center" justifyContent="space-between" style={{ alignItems: "baseline" }}>
			<Grid container style={{ marginBottom: "10px" }}>
				<Grid item xs={12} sm={2}>
					<DropdownTitle>{dataObject.measuresFilter.variable1.title}</DropdownTitle>
					<RadioButtonGroup
						color="#000"
						showButton={dataObject.measuresFilter.variable1.showRadioButton}
						data={dataObject.measuresFilter.variable1.options}
						defaultOption={selectedMeasuresFilters.variable1}
						select={(data) => callback({ ...selectedMeasuresFilters, variable1: data })}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<DropdownTitle>Variables Configuration</DropdownTitle>
					<Grid container>
						<Grid className="m-r-10 b-r-1">
							<RadioButtonGroup
								data={dataObject.measuresFilter.SOMGroup.options}
								color="#000"
								direction="row"
								select={(data) => callback({ ...selectedMeasuresFilters, SOMGroup: data })}
								defaultOption={selectedMeasuresFilters.SOMGroup}
							/>
						</Grid>
						<Grid>
							<RadioButtonGroup
								color="#000"
								data={dataObject.measuresFilter.SOMMeasure.options}
								select={(data) => callback({ ...selectedMeasuresFilters, SOMMeasure: data })}
								direction="row"
								disabled={selectedMeasuresFilters.variable1 !== "SOM"}
								defaultOption={selectedMeasuresFilters.SOMMeasure}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={4}>
					<DropdownTitle>{dataObject.measuresFilter.APIUnits.title}</DropdownTitle>
					<RadioButtonGroup
						color="#000"
						direction="row"
						data={dataObject.measuresFilter.APIUnits.options}
						defaultOption={selectedMeasuresFilters.APIUnits}
						select={(data) => callback({ ...selectedMeasuresFilters, APIUnits: data })}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3} xs={12} sm={8}>
				<Grid item xs={12} sm={3}>
					<Typography variant="subtitle2" style={{ marginTop: 10 }}>
						# Price Ranges
					</Typography>
					<Grid display="flex" alignItems="center">
						<Dropdown
							data={dataObject.priceRanges}
							onChange={(data) => {
								const indexes = generateIndexTextControl(data[0]);
								callback({ ...selectedMeasuresFilters, indexes });
							}}
							sort={{ enable: false }}
							search={{ enable: false }}
							defaultOption={[`${selectedMeasuresFilters.indexes.length}`]}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={8} style={{ marginTop: 30 }}>
					<Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
						{selectedMeasuresFilters.indexes.map((item, index) => (
							<>
								{item.start ? (
									<Grid item xs={12} sm={6} key={`larger-value-${index}`}>
										<TextField InputProps={{ autoComplete: "off" }} type="number" size="small" id="start" value={item.start} disabled={true} />
										<span
											style={{
												fontSize: "24px",
												marginLeft: 10,
												marginTop: 5,
											}}
										>
											{">="}
										</span>
									</Grid>
								) : (
									<Grid item xs={12} sm={6} display="flex" justifyContent="center"></Grid>
								)}
								{item.end ? (
									<Grid item xs={12} sm={6} key={`smaller-value-${index}`}>
										<span
											style={{
												fontSize: "24px",
												marginRight: 10,
												marginTop: 5,
											}}
										>
											{"<"}
										</span>
										<TextField
											InputProps={{ autoComplete: "off" }}
											type="number"
											size="small"
											id="end"
											value={item.end}
											onChange={(e) => onChangeValue(e.target.value, index)}
										/>
									</Grid>
								) : (
									<Grid item xs={12} sm={6} display="flex" justifyContent="center"></Grid>
								)}
							</>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MeasuresFilters;

export const generateIndexTextControl = (n) => {
	let indexes: any = [];
	for (let i = n - 1; i >= 0; i--) {
		indexes.push({
			start: i > 0 ? `${gap * i}` : null,
			end: i !== n - 1 ? `${gap * (i + 1)}` : null,
		});
	}
	return indexes;
};
