import { PricePianoPayload, PriceRangeGraphPayload, IFilterPayload } from "../../types/common";

/* Creates a GraphQL query to price range piano based on specific filter values.
 * @param {PRICE_PIANO_PAYLOAD} payload - The object contains get price piano request payload.
 * @returns The GraphQL query.
 */
export const GET_PRICE_PIANO_QUERY = (payload: PricePianoPayload) => {
	return {
		query: `{
      getPricePiano(
        country: "${payload.country}"
        geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
        geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
        channel: ${payload.channel ? JSON.stringify(payload.channel) : null}
        periodView: "${payload.periodView}"
        viewShareby: "${payload.SOMGroup}"
        indexes: ${payload.indexes ? JSON.stringify(payload.indexes) : null}
        pepsi: {
          category: ${payload.pepsi.category ? JSON.stringify(payload.pepsi.category) : null}
          segment: ${payload.pepsi.segment ? JSON.stringify(payload.pepsi.segment) : null}
          brand: ${payload.pepsi.brand ? JSON.stringify(payload.pepsi.brand) : null}
          subBrand: ${payload.pepsi.subBrand ? JSON.stringify(payload.pepsi.subBrand) : null}
          packSize: ${payload.pepsi.packSize ? JSON.stringify(payload.pepsi.packSize) : null}
          vendor: ${payload.pepsi.vendor ? JSON.stringify(payload.pepsi.vendor) : null}
          permutationComputation: ${payload.pepsi.permutation ? JSON.stringify(payload.pepsi.permutation) : null}
        }
        competitor: {
          vendor: ${payload.competitor.vendor ? JSON.stringify(payload.competitor.vendor) : null}
          category: ${payload.competitor.category ? JSON.stringify(payload.competitor.category) : null}
          segment: ${payload.competitor.segment ? JSON.stringify(payload.competitor.segment) : null}
          brand: ${payload.competitor.brand ? JSON.stringify(payload.competitor.brand) : null}
          subBrand: ${payload.competitor.subBrand ? JSON.stringify(payload.competitor.subBrand) : null}
          packSize: ${payload.competitor.packSize ? JSON.stringify(payload.competitor.packSize) : null}
          permutationComputation: ${payload.competitor.permutation ? JSON.stringify(payload.competitor.permutation) : null}
        }
        startDate: "${new Date(payload.startDate).toISOString()}"
        endDate: "${new Date(payload.endDate).toISOString()}"
        viewSharedByForPiano: "${payload.viewSharedByForPiano}"
        currentVsLast: "${payload.currentVsLast}"
      ) {
        packPiano {
          label: Label
          year: Year
          pepsi
          competitor
          tag
        }
        unitPricePiano {
          label: Label
          year: Year
          pepsi
          competitor
          tag
        }
        indexPiano {
          label: Label
          year: Year
          pepsi
          competitor
          tag
        }
      }
    }
    `,
	};
};

/* Creates a GraphQL query to price range graph based on specific filter values.
 * @param {PriceRangeGraphPayload} payload - The object contains price range graph request payload.
 * @returns The GraphQL query.
 */
export const PRICE_RANGE_GRAPH_QUERY = (payload: PriceRangeGraphPayload) => {
	return {
		query: `{
      priceRangeGraph(
        country: "${payload.country}"
        geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
        geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
        channel: ${payload.channel ? JSON.stringify(payload.channel) : null}
        periodView: "${payload.periodView}"
        indexes: ${payload.indexes ? JSON.stringify(payload.indexes) : null}
        pepsi: {
          category: ${payload.pepsi.category ? JSON.stringify(payload.pepsi.category) : null}
          segment: ${payload.pepsi.segment ? JSON.stringify(payload.pepsi.segment) : null}
          brand: ${payload.pepsi.brand ? JSON.stringify(payload.pepsi.brand) : null}
          subBrand: ${payload.pepsi.subBrand ? JSON.stringify(payload.pepsi.subBrand) : null}
          packSize: ${payload.pepsi.packSize ? JSON.stringify(payload.pepsi.packSize) : null}
          vendor: ${payload.pepsi.vendor ? JSON.stringify(payload.pepsi.vendor) : null}
          permutationComputation: ${payload.pepsi.permutation ? JSON.stringify(payload.pepsi.permutation) : null}
        }
        competitor: {
          vendor: ${payload.competitor.vendor ? JSON.stringify(payload.competitor.vendor) : null}
          category: ${payload.competitor.category ? JSON.stringify(payload.competitor.category) : null}
          segment: ${payload.competitor.segment ? JSON.stringify(payload.competitor.segment) : null}
          brand: ${payload.competitor.brand ? JSON.stringify(payload.competitor.brand) : null}
          subBrand: ${payload.competitor.subBrand ? JSON.stringify(payload.competitor.subBrand) : null}
          packSize: ${payload.competitor.packSize ? JSON.stringify(payload.competitor.packSize) : null}
          permutationComputation: ${payload.competitor.permutation ? JSON.stringify(payload.competitor.permutation) : null}
        }
        startDate: "${new Date(payload.startDate).toISOString()}"
        endDate: "${new Date(payload.endDate).toISOString()}"
        variable1: "${payload.variable1}"
        somMeasure: "${payload.SOMMeasure}"
        viewShareby: "${payload.SOMGroup}"
        apiUnits: "${payload.APIUnits}"
      ) {
        weekly {
          pepsi {
            productName
            productInfo
            priceRanges
          }
          competitors {
            productName
            productInfo
            priceRanges
          }
          competitors_graph {
            productName
            productInfo
            priceRanges
          }
          pepsi_graph {
            productName
            productInfo
            priceRanges
          }  
        }
        monthly {
          pepsi {
            productName
            productInfo
            priceRanges
          }
          competitors {
            productName
            productInfo
            priceRanges
          }
          competitors_graph {
            productName
            productInfo
            priceRanges
          }
          pepsi_graph {
            productName
            productInfo
            priceRanges
          }  
        }
        quarterly {
          pepsi {
            productName
            productInfo
            priceRanges
          }
          competitors {
            productName
            productInfo
            priceRanges
          }
          competitors_graph {
            productName
            productInfo
            priceRanges
          }
          pepsi_graph {
            productName
            productInfo
            priceRanges
          }  
        }
        yearly {
          pepsi {
            productName
            productInfo
            priceRanges
          }
          competitors {
            productName
            productInfo
            priceRanges
          }
          competitors_graph {
            productName
            productInfo
            priceRanges
          }
          pepsi_graph {
            productName
            productInfo
            priceRanges
          }  
        }
      }
    }`,
	};
};

/* Creates a GraphQL query to get common product filters for Price range piano dashboard.
 * @param {IFilterPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const PRICE_PIANO_PRODUCT_FILTER_QUERY = (payload: IFilterPayload, userId: string) => {
	return {
		query: `{
      priceRangePianoFilter(country: "${payload.country}"
      UserId: "${userId}"
			)
		}`,
	};
};
