import React, { FC, useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Grid, Typography } from "@mui/material";
import { barChartConfig } from "./chartConfig";
import ReactDOMServer from "react-dom/server";
import TooltipContent from "./TooltipComponent";
import { addRemoveLabel } from "../../../../../../../store/actions/common.action";
import { useDispatch, useSelector } from "react-redux";

function sortBrandsData(brandsData) {
	const sortedKeys = Object.keys(brandsData).sort((aValue: string, bValue: string) => {
		if (parseFloat(brandsData[aValue].total) < parseFloat(brandsData[bValue].total)) {
			return 1;
		} else {
			return -1;
		}
	});
	const sortedObj = {};

	sortedKeys.forEach((key) => {
		sortedObj[key] = brandsData[key];
	});

	return sortedObj;
}

const formatData = (payload, category) => {
	const categories: any = [];
	const series: any = [];
	let entries = Object.keys(payload);
	entries.map((item) => {
		let itemKeys = Object.keys(payload[item]);
		categories.push(item);
		if (itemKeys.indexOf("brands") !== -1) {
			const newItem = { ...sortBrandsData(payload[item]?.brands) };
			series.push({ y: parseFloat(parseFloat(payload[item].total).toFixed(1)), customData: newItem, parentCategory: category });
		} else {
			series.push({ y: parseFloat(parseFloat(payload[item].total).toFixed(1)), customData: {}, parentCategory: category });
		}
	});
	return { categories, series };
};

const BarChartInTooltip: FC<{ data; color; category; subCategory; id; parentId; fontSize }> = ({
	data,
	color,
	category,
	subCategory,
	id,
	parentId,
	fontSize,
}) => {
	const barChartRef = useRef(null);
	const [clickEventData, setClickEventData] = useState<any>({});
	const tooltips = useSelector((state: any) => state.common.highChartTooltips);
	const [chartOptions, setChartOptions] = useState(barChartConfig);
	const dispatch = useDispatch();
	useEffect(() => {
		if (data) {
			if (barChartRef && barChartRef.current && barChartRef.current.chart) {
				const chart = barChartRef.current.chart;
				const result = formatData(data, category);
				while (chart && chart.series.length) {
					chart.series[0].remove();
				}
				// chart.xAxis?.forEach((x) =>
				// 	x.update({
				// 		labels: {
				// 			style: {
				// 				fontSize: fontSize,
				// 			},
				// 		},
				// 	})
				// );
				// chart.yAxis?.forEach((y) =>
				// 	y.update({
				// 		labels: {
				// 			style: {
				// 				fontSize: fontSize,
				// 			},
				// 		},
				// 	})
				// );
				const affectedTopPosition = (parseInt(fontSize.slice(0, -2)) - 12) * 3 * result.categories.length;
				const chartHeight = 40 + result.categories.length * 20 + affectedTopPosition;
				setChartOptions({
					...chartOptions,
					chart: {
						...chartOptions.chart,
						height: chartHeight > 150 ? chartHeight : 150,
					},
					xAxis: {
						...chartOptions.xAxis,
						categories: result.categories,
						labels: {
							style: {
								fontSize: fontSize,
							},
						},
					},
					yAxis: {
						...chartOptions.yAxis,
						title: {
							...chartOptions.yAxis.title,
							style: {
								fontSize: fontSize,
							},
						},
					},
					series: [
						{
							data: result.series,
							name: subCategory,
							type: "column",
							color: color,
							zIndex: 6,
							lineWidth: 4,
							dataLabels: {
								allowOverlap: true,
								enabled: true,
								formatter: function () {
									return this.y === 0 ? null : `${this.y}%`;
								},
								style: {
									fontSize: fontSize,
								},
							},
						},
					],
					tooltip: {
						...chartOptions.tooltip,
						formatter: function () {
							if (Object.keys(this.point.customData).length === 0) {
								return `<b>${subCategory}</b><br/>${this.point.category}: ${this.y}%`;
							} else {
								// Render TooltipContent to a string
								const content = ReactDOMServer.renderToString(
									<TooltipContent data={this.point.customData} category={category} color={this.color} subCategory={this.point.category} fontSize={fontSize} />
								);
								return content;
							}
						},
						style: {
							fontSize: fontSize,
						},
					},
					plotOptions: {
						series: {
							cursor: "pointer",
							point: {
								events: {
									click: function () {
										setClickEventData(Object.keys(this.customData).length > 0 ? { ...this } : {});
									},
								},
							},
						},
					},
				});
			}
		}
	}, [data]);
	useEffect(() => {
		if (Object.keys(clickEventData).length > 0) {
			if (barChartRef && barChartRef.current && barChartRef.current.chart) {
				const chart = barChartRef.current.chart;
				dispatch(addRemoveLabel([clickEventData], id, tooltips, chart, undefined, parentId));
				setClickEventData({});
			}
		}
	}, [clickEventData]);
	return (
		<Grid item xs={12} sm={12} style={{ width: "300px" }}>
			<Typography fontSize={fontSize} fontWeight={600} color="#1E1E1F">
				{category}
			</Typography>
			<Typography fontSize={fontSize} color="#1E1E1F">
				{subCategory}
			</Typography>
			<HighchartsReact highcharts={Highcharts} options={chartOptions} ref={barChartRef} />
		</Grid>
	);
};

export default BarChartInTooltip;
