import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import FilterAccordion from "../../../../components/Filters";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import dataObject from "../../../../mocks/apiPredictiveDashboard";
import _ from "lodash";
import MeasureFilter from "../MeasureFilters";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import Indicator from "../../../../components/Loader";
import { dataFormat } from "../../../../util/helper";
import { fetchFiltersData } from "../../../../util/services";
import { IProductFilterConfigurations } from "../../../../types/common";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import { initialState, dateRange } from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import CommonProductFilters from "../../../../components/CommonProductFilters";
import { API_VOLUME_PRODUCT_FILTER_QUERY } from "../../../../util/queries/apiPredictiveDashboard";
import { filterOrder } from "../../../../mocks/common";
import { useSelector } from "react-redux";
import { CustomDropDownTitle } from "../../../../components/ProductFilters-v3/ProductFilters-v3";
import Dropdown from "../../../../components/Dropdown";
const TopFilters: React.FC<{
	data;
	callback;
	defaultFilters;
	showSkeleton?: boolean;
}> = ({ data, callback, showSkeleton, defaultFilters }) => {
	const reduxState = useSelector((state: any) => state);
	const userDetail = useSelector((state: any) => state.User.data);
	const [selectedMeasureFilters, setSelectedMeasureFilters] = useState({
		variable1: data.measureFilter.variable1.defaultOption.value || undefined,
		somMeasure: data.measureFilter.SOM.defaultOption.value || undefined,
		somGroup: data.measureFilter.SOMVarConfig.defaultOption.value || undefined,
		apiUnits: data.measureFilter.measure.defaultOption.value || undefined,
	});
	const [loader, setLoader] = useState(false);
	const [selectedDate, setSelectedDate] = useState(`${dataFormat(dateRange[0], "MM/dd/yyyy")}-${dataFormat(dateRange[1], "MM/dd/yyyy")}`);
	const [isCountryManuallyChangedFlag, setIsCountryManuallyChangedFlag] = useState(false);
	const [clearFilterFlag, setClearFilterFlag] = useState(false);
	const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
	const [selectedAnchorFilters, setSelectedAnchorFilters] = useState({});
	const [selectedProductFilters, setSelectedProductFilters] = useState({});

	const [productFilterOptions, setProductFilterOptions] = useState<IProductFilterConfigurations[]>([]);
	const [anchorFilterOptions, setAnchorFilterOptions] = useState<IProductFilterConfigurations[]>([]);

	const [isFirstLoadPepsiFlag, setIsFirstLoadPepsiFlag] = useState(true);
	const [isFirstLoadCompetitorFlag, setIsFirstLoadCompetitorFlag] = useState(true);
	useEffect(() => {
		if (selectedGeoFilters && selectedGeoFilters.country !== null && userDetail?.id) {
			fetchFilters({ country: selectedGeoFilters.country }, API_VOLUME_PRODUCT_FILTER_QUERY, isCountryManuallyChangedFlag);
		}
	}, [selectedGeoFilters.country, userDetail?.id]);

	const disableApplyFilter = () => {
		const flag: boolean =
			(selectedMeasureFilters.variable1
				? selectedMeasureFilters.variable1 === "SOM"
					? selectedMeasureFilters.somGroup === "" || selectedMeasureFilters.somMeasure === ""
					: selectedMeasureFilters.variable1 === "API"
					? selectedMeasureFilters.apiUnits === ""
					: false
				: true) ||
			!selectedMeasureFilters.apiUnits ||
			selectedGeoFilters.country === "" ||
			selectedGeoFilters.country === null ||
			selectedGeoFilters.geoLevel2 === "" ||
			selectedGeoFilters.geoLevel2 === null;
		return flag;
	};

	const onApplyFilter = () => {
		const measures = {
			...selectedMeasureFilters,
			apiUnits: selectedMeasureFilters.apiUnits === "Price per Volume" ? "Volume" : "Units",
		};
		const payload = {
			...selectedGeoFilters,
			vendor: ["PEPSICO"],
			...selectedProductFilters,
			...selectedAnchorFilters,
			...measures,
			date: selectedDate,
		};
		callback(payload);
	};

	const clearFilter = () => {
		setClearFilterFlag(true);
		setSelectedGeoFilters({ ...initialState });
		setSelectedMeasureFilters({
			variable1: undefined,
			somMeasure: undefined,
			somGroup: undefined,
			apiUnits: undefined,
		});
		data.measureFilter.variable1.options.map((item) => {
			item.disabled = false;
		});
		setSelectedProductFilters({});
		setSelectedAnchorFilters({});
		setProductFilterOptions([]);
		setAnchorFilterOptions([]);
	};
	useEffect(() => {
		if (defaultFilters && _.isObject(defaultFilters) && Object.keys(defaultFilters).length > 0) {
			setSelectedMeasureFilters({
				variable1: defaultFilters?.variable1 || undefined,
				somMeasure: defaultFilters?.somMeasure || undefined,
				somGroup: defaultFilters?.somGroup || undefined,
				apiUnits: defaultFilters?.apiUnits === "Volume" ? "Price per Volume" : "Price per Unit",
			});
			setSelectedProductFilters({
				...selectedProductFilters,
				category: defaultFilters.category,
				segment: defaultFilters.segment,
				brand: defaultFilters.brand,
				subBrand: defaultFilters.subBrand,
				packSize: defaultFilters.packSize,
				permutation: defaultFilters.permutation,
			});
			setSelectedAnchorFilters({
				...selectedAnchorFilters,
				anchorVendor: defaultFilters.anchorVendor,
				anchorCategory: defaultFilters.anchorCategory,
				anchorSegment: defaultFilters.anchorSegment,
				anchorBrand: defaultFilters.anchorBrand,
				anchorSubBrand: defaultFilters.anchorSubBrand,
				anchorPackSize: defaultFilters.anchorPackSize,
				anchorPermutation: defaultFilters.anchorPermutation,
			});
			setSelectedDate(defaultFilters.date);
		}
	}, [defaultFilters]);

	const fetchFilters = (payload, queryFunction, isCountryManuallyChangedFlag) => {
		if (isCountryManuallyChangedFlag) {
			setSelectedProductFilters({});
			setSelectedAnchorFilters({});
		}
		if (selectedGeoFilters.country) {
			setLoader(true);
			payload = {
				...payload,
			};
			fetchFiltersData(
				"graphql",
				{
					country: selectedGeoFilters.country,
					...payload,
				},
				userDetail.id,
				queryFunction
			)
				.then((response) => {
					if (response && response.data) {
						const filterObject = JSON.parse(response.data);
						const productFilterConfig: IProductFilterConfigurations[] = [];
						const anchorFilterConfig: IProductFilterConfigurations[] = [];
						filterObject
							.filter((object) => object.Manufacturer === "PEPSICO")
							.map((object) => {
								productFilterConfig.push({
									category: {
										label: object.category,
										value: object.category,
									},
									segment: {
										label: object.segment,
										value: object.segment,
									},
									brand: {
										label: object.brand,
										value: object.brand,
									},
									subBrand: {
										label: object.subbrand,
										value: object.subbrand,
									},
									packSize: {
										label: object.packsize,
										value: object.packsize,
									},
									permutation: {
										label: object.permutation,
										value: object.subbrandsize,
									},
								});
							});

						filterObject.map((object) => {
							anchorFilterConfig.push({
								category: {
									label: object.category,
									value: object.category,
								},
								segment: {
									label: object.segment,
									value: object.segment,
								},
								brand: {
									label: object.brand,
									value: object.brand,
								},
								subBrand: {
									label: object.subbrand,
									value: object.subbrand,
								},
								packSize: {
									label: object.packsize,
									value: object.packsize,
								},
								permutation: {
									label: object.permutation,
									value: object.subbrandsize,
								},
								vendor: {
									label: object.Manufacturer,
									value: object.Manufacturer,
								},
							});
						});
						setProductFilterOptions(productFilterConfig);
						setAnchorFilterOptions(anchorFilterConfig);
						setLoader(false);
					}
				})
				.catch((e) => {
					setLoader(false);
				});
		}
	};

	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<Indicator
				position="absolute"
				show={
					!reduxState.common.loader &&
					(loader ||
						reduxState.ApiPredictive.correlationChartDataLoader ||
						reduxState.ApiPredictive.correlationTableDataLoader ||
						reduxState.ApiPredictive.optimizationChartData ||
						reduxState.ApiPredictive.g5ChartData ||
						reduxState.ApiPredictive.simulationDataLoader ||
						reduxState.ApiPredictive.simulationValueLoader ||
						reduxState.ApiPredictive.multipleAPIDataLoader)
				}
			/>
			<CardContent>
				{!showSkeleton ? (
					<>
						<FilterAccordion title="Geo-Filters">
							<GeoFiltersV2
								data={geoFilterV2Config}
								onChangeDate={(dt) => {
									setClearFilterFlag(false);
									setSelectedDate(dt);
								}}
								onSelectFilters={(geoData) => {
									setClearFilterFlag(false);
									setSelectedGeoFilters(geoData);
								}}
								clearFilter={clearFilterFlag}
								apiPath="ApiPredictive"
								showLoader={setLoader}
								defaultFilters={defaultFilters}
								defaultDate={selectedDate ? selectedDate.split("-") : null}
								setIsCountryManuallyChangedFlag={setIsCountryManuallyChangedFlag}
							/>
						</FilterAccordion>
						<FilterAccordion title="Product Filters">
							<Grid container display="flex" alignItems="center" justifyContent="space-between">
								<Grid container spacing={2} columns={Object.keys(dataObject.productFilter).length + 1}>
									<Grid item xs={1}>
										<>
											<CustomDropDownTitle title={'Vendor <span style="color: #858c94;">(Pepsico)</span>'} />
											<Dropdown
												disabled={true}
												data={[{ lable: "PEPSICO", value: "PEPSICO" }]}
												defaultOption={["PEPSICO"]}
												placeholder="Select"
												search={{ enable: false }}
											/>
										</>
									</Grid>
									<Grid item xs={Object.keys(dataObject.productFilter).length}>
										<CommonProductFilters
											filterData={productFilterOptions}
											onChange={(e) => {
												setIsFirstLoadPepsiFlag(false);
												setSelectedProductFilters(e);
											}}
											data={dataObject.productFilter}
											showSkeleton={showSkeleton}
											defaultFilters={selectedProductFilters ?? null}
											isAnchorFlag={false}
											filterOrder={dataObject.pepsiFilterOrder}
											isFirstLoadFlag={isFirstLoadPepsiFlag}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Typography color={"#858c94"}>Anchor - Index 100</Typography>
							<CommonProductFilters
								filterData={anchorFilterOptions}
								onChange={(e) => {
									setIsFirstLoadCompetitorFlag(false);
									setSelectedAnchorFilters(e);
								}}
								data={dataObject.anchorFilter}
								defaultFilters={selectedAnchorFilters ?? null}
								showSkeleton={showSkeleton}
								isAnchorFlag={true}
								filterOrder={filterOrder}
								isFirstLoadFlag={isFirstLoadCompetitorFlag}
							/>
						</FilterAccordion>
						<FilterAccordion title="Measure Filters">
							<MeasureFilter data={data.measureFilter} callback={(e) => setSelectedMeasureFilters(e)} selectedMeasureFilters={selectedMeasureFilters} />
						</FilterAccordion>
						<Grid className="p-l-16">
							<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
								Clear Filter
							</OrangeBtn>
							<PrimaryBtn disabled={disableApplyFilter()} color="primary" onClick={onApplyFilter}>
								Apply Filter
							</PrimaryBtn>
						</Grid>
					</>
				) : (
					<>
						<FilterAccordion showSkeleton>
							<Grid container spacing={2}>
								{[...Array(Object.keys(geoFilterV2Config).length).keys()].map((i) => (
									<Grid item xs={12} sm={2} key={`filter-${i}-skeleton`}>
										<Skeleton height={22} />
										<Skeleton variant="rectangular" width={210} height={45} />
									</Grid>
								))}
							</Grid>
						</FilterAccordion>
						<Grid className="m-b-20">
							<FilterAccordion showSkeleton>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={2}>
										<DropdownTitle>
											<Skeleton height={22} />
										</DropdownTitle>
									</Grid>
								</Grid>
							</FilterAccordion>
						</Grid>
						<Grid className="p-l-16" container spacing={2}>
							<Skeleton variant="rectangular" className="m-r-20" width={99} height={45} />
							<Skeleton variant="rectangular" className="m-r-20" width={110} height={45} />
						</Grid>
					</>
				)}
			</CardContent>
		</Card>
	);
};

export default TopFilters;
