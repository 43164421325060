import { PRICE_LADDER_MODEL } from "./constant";

let initiaState = {
	priceLadderDataLoader: false,
};

const PriceLadderDataLoader = (state = initiaState, action) => {
	if (action.type === PRICE_LADDER_MODEL)
		return {
			...state,
			priceLadderDataLoader: action.payload,
		};
	else return state;
};

export default PriceLadderDataLoader;
