import { Grid, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { formatNumber } from "../../../../util/helper";
import { addRemoveLabel } from "../../../../store/actions/common.action";
import { useDispatch, useSelector } from "react-redux";
import { IQuadIntensityChildData } from "../../../../types/revenueMap";

const useStyles = makeStyles(() => ({
	addBtn: {
		cursor: "pointer",
		position: "fixed",
		bottom: "0",
		right: "0",
	},
}));
const BubbleChartTooltip: FC<{
	chart;
	id: string;
	parentId: string;
	name: string;
	x: number;
	y: number;
	varSize: number;
	color: string;
	isOpened: boolean;
	childData: IQuadIntensityChildData[];
}> = ({ chart, id, parentId, name, x, y, varSize, color, isOpened = false, childData }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const tooltips = useSelector((state: any) => state.common.highChartTooltips);
	const handleOnClick = () => {
		dispatch(addRemoveLabel([chart], id, tooltips, chart, undefined, parentId));
	};
	return (
		<Stack direction={"column"} spacing={1}>
			<Grid>
				Var Description : <b>{name}</b>
			</Grid>
			<Grid>
				Var X : <b>{formatNumber(x)}</b>
			</Grid>
			<Grid>
				Var Y : <b>{formatNumber(y)}</b>
			</Grid>
			<Stack direction={"row"} spacing={4}>
				<Grid style={{ marginRight: "40px" }}>
					Variable Size : <b>{formatNumber(varSize)}</b>
				</Grid>
				{childData && (
					<IconButton className={classes.addBtn} id={`${parentId}-tooltip-0-expandBtn`} onClick={() => handleOnClick()}>
						{isOpened ? <RemoveCircleIcon sx={{ color: { color } }} /> : <AddCircleIcon sx={{ color: { color } }} />}
					</IconButton>
				)}
			</Stack>
		</Stack>
	);
};
export default BubbleChartTooltip;
