import React, { useEffect, useRef, useState } from "react";
import { Avatar, Typography, Box, Divider, Grid, Modal, Switch } from "@mui/material";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Title } from "../../../styles/Common.Styled";
import { DeleteBtn, PrimaryBtn } from "./UserSetting.Styled";
import UserInfo from "./UserInfo";
import { changeTheme } from "../../../store/actions";
import CropEasy from "./Crop/CropEasy";
import { deleteUserProfile, createOrUpdateUser, updateUserProfile, updateDarkTheme } from "../service";
import { stringAvatar } from "../../../util/helper";
import { IUserDetails } from "../../../types/common";
import { toast } from "../../../store/actions/toast.action";
import { loader } from "../../../store/actions/common.action";
import { fetchUser } from "../../../store/actions/user.action";
import { getCountries } from "../../Admin/service";
import PageContainer from "../../../components/PageContainer";
import { pageNames } from "../../../mocks/common";
import { getCountryList } from "../../../store/actions/coutries.action";

const initialState: IUserDetails = {
	firstName: "",
	lastName: "",
	emailAddress: "",
	phoneNumber: "",
	phoneNumberCountryCode: "",
	address: "",
	role: "",
	defaultCountry: "",
	defaultCategory: "",
	permissionCountry: "",
	permissionCategory: "",
	imageUrl: "",
	isDarkMode: false,
	country: "",
};

const useStyles = makeStyles((theme) => ({
	flexCenter: {
		display: "flex",
		alignItems: "center",
		gap: "15px",
	},
	sectionTitle: {
		marginBottom: "30px !important",
		fontWeight: 600,
		fontSize: "18px !important",
	},
	toggleTheme: {
		fontSize: "1.2rem",
	},
}));

const UserSettings: React.FC = () => {
	const classes = useStyles();
	const inputFile = useRef(null);
	const [imgUrl, setImgUrl] = useState(null);
	const [oldUserDetails, setOldUserDetails] = useState<IUserDetails>({
		...initialState,
	});
	const [userDetails, setUserDetails] = useState<IUserDetails>({
		...initialState,
	});

	const [profilePic, setProfilePic] = useState(null);
	const [openCrop, setOpenCrop] = useState(false);
	const [data, setData] = useState(null);

	const dispatch = useDispatch();
	const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
	const countryData = useSelector((store: RootStateOrAny) => store.CountryList.data);
	const userDetailRedux = useSelector((state: any) => state.User);
	const [isCountryAPICall, setIsCountryAPICall] = useState(false);

	const toggleTheme = async () => {
		setUserDetails({ ...userDetails, isDarkMode: !userDetails.isDarkMode });
		await updateDarkTheme(!userDetails.isDarkMode, userDetails.id ?? -1)
			.then((response) => {
				if (response.data === "Success") dispatch(toast("Theme updated successfully!", true, 2000, "success"));
				else toast("Something went wrong", true, 2000, "error");
			})
			.catch((error) => {
				dispatch(toast("Something went wrong", true, 2000, "error"));
			});
		dispatch(fetchUser(userDetails.emailAddress));
	};

	const handleClose = () => {
		setOpenCrop(false);
	};

	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			setOpenCrop(true);
			setImgUrl(URL.createObjectURL(file));
		}
	};

	const updateUserHandler = (data) => {
		if (data?.key) {
			userDetails[data.key] = data.value;
			setUserDetails({
				...userDetails,
				firstName: userDetails.firstName,
				lastName: userDetails.lastName,
			});
		}
	};

	const apiCallback = async (croppedFile) => {
		if (userDetails.id) {
			const formData = new FormData();
			const imageFile = new File([croppedFile], `UserProfile_${userDetails.id}.jpeg`, { type: "image/jpeg" });
			formData.append("file", imageFile);
			updateUserProfile(formData).then(() => {
				dispatch(fetchUser(userDetails.emailAddress));
			});
		}
	};

	const saveUserHandler = (newUserDetails = userDetails) => {
		setOldUserDetails({ ...newUserDetails });
		dispatch(loader(true));
		createOrUpdateUser(
			{
				...newUserDetails,
				country: newUserDetails.country,
				firstName: newUserDetails.firstName.trim(),
				lastName: newUserDetails.lastName.trim(),
			},
			userDetailRedux.data.id
		)
			.then((res) => {
				if (res.status === 200 || res.status === 204) {
					dispatch(toast("User data updated successfully!", true, 2000, "success"));
					dispatch(fetchUser(newUserDetails.emailAddress));
				}
			})
			.catch((e) => {
				dispatch(toast("Something went wrong", true, 2000, "error"));
			})
			.finally(() => dispatch(loader(false)));
	};

	const cancelUserHandler = () => {
		setUserDetails({ ...oldUserDetails });
	};

	const onBrowseClick = () => {
		inputFile.current.value = null;
		inputFile.current.click();
	};

	const onDelete = () => {
		if (profilePic || userDetails.imageUrl) {
			const imageUrl = userDetails.imageUrl;
			setUserDetails({ ...userDetails, imageUrl: null, profile: undefined });
			inputFile.current.value = null;
			setProfilePic(null);
			deleteUserProfile(imageUrl).then(() => {
				dispatch(fetchUser(userDetails.emailAddress));
			});
		}
	};

	useEffect(() => {
		if (userDetailRedux && userDetailRedux.data) {
			setOldUserDetails({ ...userDetailRedux.data });
			setUserDetails(userDetailRedux.data);
			dispatch(changeTheme(userDetailRedux.data.isDarkMode));
			dispatch(loader(false));
		}
	}, [userDetailRedux]);

	useEffect(() => {
		if (countryData.length === 0 && !isCountryAPICall) {
			setIsCountryAPICall(true);
			getCountries()
				.then((res) => {
					dispatch(getCountryList(res.data));
					const countries = res.data.map((item) => ({
						label: item,
						value: item,
					}));
					setData(countries);
				})
				.catch(() => {
					dispatch(toast("Something went wrong", true, 2000, "error"));
				});
		} else {
			const countries = countryData.map((item) => ({
				label: item,
				value: item,
			}));
			setData(countries);
		}
		if (userDetailRedux.data) {
			dispatch(loader(false));
		} else {
			dispatch(loader(true));
		}
	}, []);

	return (
		<PageContainer page={pageNames.userSettings}>
			<Box>
				<Title className="m-b-20"> User Settings </Title>
				<Box className={classes.flexCenter}>
					<Avatar
						alt={oldUserDetails.firstName.trim() + oldUserDetails.lastName.trim()}
						src={profilePic || userDetails.profile}
						{...stringAvatar(`${oldUserDetails.firstName.trim()} ${oldUserDetails.lastName.trim()}`)}
						sx={{
							width: 80,
							height: 80,
							background: theme.darkmode ? "white" : "",
						}}
					/>
					<Grid container display="flex" alignItems="center">
						<Grid item className="m-r-10">
							<PrimaryBtn variant="contained" onClick={onBrowseClick}>
								Upload New Picture
							</PrimaryBtn>
							<input onChange={onImageChange} type="file" accept="image/png, image/gif, image/jpeg" hidden ref={inputFile} />
						</Grid>
						<Grid item xs={12} sm={2}>
							<DeleteBtn onClick={onDelete}>Delete</DeleteBtn>
						</Grid>
					</Grid>
				</Box>
				<Divider className="m-b-40 m-t-10" />
				<Typography className={classes.sectionTitle}>Account Information</Typography>
				<UserInfo
					updateHandler={updateUserHandler}
					saveUserHandler={saveUserHandler}
					cancelUserHandler={cancelUserHandler}
					data={{
						label: "Name",
						displayValue: `${userDetails.firstName} ${userDetails.lastName}`,
						data: [
							{
								type: "text",
								key: "firstName",
								label: "First Name",
								value: userDetails.firstName,
							},
							{
								type: "text",
								key: "lastName",
								label: "Last Name",
								value: userDetails.lastName,
							},
						],
					}}
				/>
				<UserInfo
					updateHandler={updateUserHandler}
					saveUserHandler={saveUserHandler}
					cancelUserHandler={cancelUserHandler}
					data={{
						label: "Email Address",
						displayValue: userDetails.emailAddress,
						data: [
							{
								type: "text",
								key: "emailAddress",
								label: "Email Address",
								value: userDetails.emailAddress,
							},
						],
					}}
				/>
				<Typography className={classes.sectionTitle}>My Information</Typography>
				<UserInfo
					updateHandler={updateUserHandler}
					saveUserHandler={saveUserHandler}
					cancelUserHandler={cancelUserHandler}
					data={{
						label: "Phone Number",
						displayValue: `${userDetails.phoneNumberCountryCode + " " + userDetails.phoneNumber}`,
						data: [
							{
								type: "phone",
								key: ["phoneNumberCountryCode", "phoneNumber"],
								label: "Phone Number",
								value: userDetails.phoneNumberCountryCode,
								value2: userDetails.phoneNumber,
							},
						],
					}}
				/>
				<UserInfo
					updateHandler={updateUserHandler}
					saveUserHandler={saveUserHandler}
					cancelUserHandler={cancelUserHandler}
					data={{
						label: "Address",
						displayValue: userDetails.address,
						data: [
							{
								type: "text",
								label: "Address",
								key: "address",
								value: userDetails.address,
							},
						],
					}}
				/>
				<UserInfo
					updateHandler={updateUserHandler}
					saveUserHandler={saveUserHandler}
					cancelUserHandler={cancelUserHandler}
					data={{
						label: "Country",
						displayValue: userDetails.country,
						data: [
							{
								type: "auto-complete",
								label: "Country",
								key: "country",
								availableValues: data,
								value: userDetails.country,
							},
						],
					}}
				/>
				<Typography className={classes.sectionTitle}>System Settings</Typography>
				<UserInfo
					data={{
						label: "User's Role",
						displayValue: userDetails.role,

						data: [
							{
								type: "text",
								label: "User's Role",
								key: "role",
								value: userDetails.role,
							},
						],
					}}
				/>
				<UserInfo
					data={{
						label: "Country",
						displayValue: userDetails.permissionCountry,
						data: [
							{
								type: "text",
								label: "Country",
								key: "permissionCountry",
								value: userDetails.permissionCountry,
							},
						],
					}}
				/>
				<Box marginTop="50px" className={classes.flexCenter}>
					<Typography className={classes.toggleTheme} fontWeight="bold">
						Dark Mode
					</Typography>
					<Box sx={{ minWidth: 120 }}>
						<Switch
							color="default"
							onChange={toggleTheme}
							value={theme.darkmode ? "Dark" : "Light"}
							defaultValue={userDetails.isDarkMode ? "Dark" : "Light"}
							checked={theme.darkmode}
						/>
					</Box>
				</Box>
				<Typography marginTop="20px" maxWidth="1211px" marginBottom="100px" color={theme.darkmode ? " #E3EFF8" : "#858C94"}>
					Dark themes reduce the luminance emitted by device screens, while still meeting minimum color contrast ratios. They help improve visual ergonomics by
					reducing eye strain, adjusting brightness to current lighting conditions, and facilitating screen use in dark environments – all while conserving
					battery power.
				</Typography>
			</Box>

			<Modal open={openCrop} onClose={handleClose}>
				<CropEasy
					{...{
						imgUrl,
						setProfilePic,
						setOpenCrop,
						setImgUrl,
						apiCallback,
					}}
				/>
			</Modal>
		</PageContainer>
	);
};

export default UserSettings;
