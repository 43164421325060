import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
import _ from "lodash";
import { TextField, Tooltip } from "@mui/material";
const useStyles = makeStyles(() => ({
	select: {
		height: "46px",
		width: "100%",
	},
	searchInput: {
		"& .MuiOutlinedInput-root": {
			"&.Mui-focused fieldset": {
				border: "2px solid #0000003b",
			},
		},
	},
	searchMenuItem: {
		backgroundColor: "#fff !important",
	},
}));
function getStyles(item, selectOption, theme) {
	let fontWeight;
	if (_.isArray(selectOption)) {
		if (selectOption.indexOf(item) === -1) {
			fontWeight = theme.typography.fontWeightRegular;
		} else {
			fontWeight = theme.typography.fontWeightMedium;
		}
	} else if (item === selectOption) {
		fontWeight = theme.typography.fontWeightMedium;
	} else {
		fontWeight = theme.typography.fontWeightRegular;
	}
	return {
		fontWeight: fontWeight,
	};
}
const Dropdown: React.FC<{
	data;
	multiple?;
	multipleSelectionMsg?;
	placeholder?;
	defaultOption?;
	keyText?;
	onChange?;
	onClose?;
	disabled?: boolean;
	allOption?: boolean;
	allOptionText?: string;
	defaultSelectAll?: boolean;
	selectionLimit?: number;
	sort?: { enable: boolean; order?: "asc" | "desc" };
	maxWidth?;
	search?: { enable: boolean; placeholder?: string; style?: Object };
	onChangeSearchText?;
}> = ({
	data,
	multiple = false,
	multipleSelectionMsg,
	placeholder,
	defaultOption,
	keyText,
	onChange,
	onClose,
	disabled = false,
	allOption = false,
	allOptionText = "All",
	defaultSelectAll = false,
	selectionLimit,
	sort = { enable: true, order: "asc" },
	maxWidth = "100%",
	search = { enable: true, placeholder: "Search...", style: {} },
	onChangeSearchText,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const [selectedOption, setSelectedOption] = useState<string[] | string>(multiple ? [] : "");
	const [showError, setShowError] = useState(false);
	const [options, setOptions] = useState([]);
	const [originalOptions, setOriginalOptions] = useState([]);
	const [searchText, setSearchText] = useState(null);
	useEffect(() => {
		if (selectionLimit) {
			setShowError(selectedOption.length > selectionLimit);
		}
	}, [selectedOption]);
	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		const val = typeof value === "string" && multiple ? value.split(",") : value;
		const index = _.isArray(val) && val.findIndex((x) => x === allOptionText);
		if (index === -1) {
			if (selectionLimit) {
				if (val.length <= selectionLimit) {
					setSelectedOption(val);
					if (onChange && !multiple) {
						onChange(val);
					}
					setShowError(false);
				} else {
					setShowError(true);
				}
			} else {
				setSelectedOption(val);
				if (onChange && !multiple) {
					onChange(val);
				}
			}
		} else if (_.isString(val)) {
			setSelectedOption(val);
			if (onChange && !multiple) {
				onChange([val]);
			}
		}
	};
	const handleClose = () => {
		if (multiple) {
			const withoutEmpty = _.isArray(defaultOption) ? defaultOption.filter((d) => d !== "empty") : [];
			if (_.isArray(selectedOption)) {
				const diff = _.difference(selectedOption, withoutEmpty);
				if ((_.isArray(diff) && diff.length > 0) || selectedOption.length !== withoutEmpty.length) {
					onChange(selectedOption);
				}
			}
		}
		setTimeout(() => {
			setSearchText(null);
			setOptions([...originalOptions]);
		}, 100);
	};
	const onSelectAll = (event) => {
		if (event.target.checked) {
			const allOptions = options.map((x) => x.value);
			setSelectedOption(allOptions);
			onChange(allOptions);
		} else {
			setSelectedOption(multiple ? [] : "");
			onChange(multiple ? [] : "");
		}
		event.stopPropagation();
	};
	const allOptionRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (defaultOption) {
			if (_.isArray(defaultOption)) {
				if (defaultOption.length === 1 && defaultOption[0] === "empty") {
					setSelectedOption([]);
				} else {
					setSelectedOption(multiple ? defaultOption : defaultOption[0]);
				}
			} else if (_.isString(defaultOption) && multiple) {
				setSelectedOption([defaultOption]);
			} else if (_.isString(defaultOption)) {
				setSelectedOption(defaultOption);
			}
		}
	}, [defaultOption]);
	useEffect(() => {
		if (data && data.length > 0) {
			if (sort && sort.enable) {
				//if there is label key then sort based on label
				if (data.filter((x) => x.label).length > 0) data = _.orderBy(data, ["label"], [sort.order]);
				else data = _.orderBy(data, ["value"], [sort.order]);
			}
			setOptions(searchText ? searchOptions(data, searchText) : [...data]);
			setOriginalOptions(data);
			if (defaultSelectAll && selectionLimit && selectionLimit > 0) {
				setSelectedOption(data.filter((x, i) => i < selectionLimit).map((x) => x.value));
			} else if (allOption && defaultSelectAll) {
				setSelectedOption(data.map((x) => x.value));
			}
		} else {
			setOptions(searchText ? searchOptions(data, searchText) : [...data]);
			setOriginalOptions(data);
		}
	}, [data]);
	const onSearch = (e) => {
		const val = e.target.value;
		if (onChangeSearchText) {
			onChangeSearchText(val);
		}
		if (val) {
			setSearchText(val);
			setOptions([...searchOptions(originalOptions, val)]);
		} else {
			setSearchText(null);
			setOptions([...originalOptions]);
		}
	};
	const searchOptions = (data, val) => {
		return data.filter((x) => {
			if (x.label) {
				return x.label.toLowerCase().includes(val);
			} else {
				return x.value.toLowerCase().includes(val);
			}
		});
	};
	return (
		<Select
			disabled={disabled}
			multiple={multiple}
			displayEmpty
			value={selectedOption}
			onChange={handleChange}
			onClose={handleClose}
			input={<OutlinedInput />}
			MenuProps={{ autoFocus: false }}
			renderValue={(selected) => {
				if (_.isArray(selected)) {
					if (selected.length > 1) {
						return <span>{allOption && selectedOption.length === options.length ? allOptionText : multipleSelectionMsg ?? "Multiple"}</span>;
					} else if (selected.length === 0 && placeholder) {
						return <span style={{ color: "#a6a6a6" }}>{placeholder}</span>;
					}
					if (multiple) {
						const result = options.filter((x) => selected.findIndex((y) => y === x.value) !== -1).map((x) => x.label || x.value);
						return result.join(", ");
					}
				} else if (selected && selected !== "Empty") {
					const result = options.find((x) => x.value === selected);
					if (result) return result.label || result.value;
				} else {
					return <span style={{ color: "#a6a6a6" }}>{placeholder}</span>;
				}
			}}
			className={classes.select}
			style={{ maxWidth }}
		>
			{search && search.enable && originalOptions.length > 0 ? (
				<MenuItem classes={{ focusVisible: classes.searchMenuItem }} onKeyDown={(e) => e.stopPropagation()}>
					<TextField
						InputProps={{ autoComplete: "off" }}
						id="dropdown-search"
						className={classes.searchInput}
						placeholder={search.placeholder}
						type="text"
						variant="outlined"
						onChange={onSearch}
						onClick={(e) => e.stopPropagation()}
						autoComplete="off"
						value={searchText}
						style={{ width: "100%", ...search.style }}
					/>
				</MenuItem>
			) : null}
			<MenuItem style={{ display: "none" }} value="" />
			{placeholder && (
				<MenuItem disabled value="">
					<em>{placeholder}</em>
				</MenuItem>
			)}
			{showError && <span style={{ color: "red", padding: "0 10px" }}>{`Maximum ${selectionLimit} Selections Allowed`}</span>}
			{allOption && options.length > 0 && !searchText && (
				<MenuItem value={allOptionText} style={getStyles(allOptionText, selectedOption, theme)} onClick={() => allOptionRef.current?.click()}>
					<Box className="ellipsis" style={{ maxWidth: maxWidth }}>
						<Checkbox
							className={selectedOption.length === options.length && "mui-checkbox"}
							disabled={options.length === 0}
							checked={selectedOption.length === options.length}
							onClick={onSelectAll}
							inputRef={allOptionRef}
						/>
						<span>{allOptionText}</span>
					</Box>
				</MenuItem>
			)}
			{options.length > 0 &&
				options.map((item) => (
					<MenuItem
						key={`${keyText}-${item.id}-${item.value}`}
						value={item.value}
						style={getStyles(item.value, selectedOption, theme)}
						disabled={item?.disable || false}
					>
						{multiple ? (
							<Box className="ellipsis" style={{ maxWidth }}>
								<Checkbox
									className={selectedOption.indexOf(item.value) > -1 && "mui-checkbox"}
									checked={selectedOption.indexOf(item.value) > -1}
									disabled={item?.disable || false}
								/>
								<Tooltip title={item.label || item.value} placement="left-end">
									<span>{item.label || item.value}</span>
								</Tooltip>
							</Box>
						) : (
							<Box className="ellipsis" style={{ maxWidth }}>
								<Tooltip title={item.label || item.value} placement="left-end">
									<span>{item.label || item.value}</span>
								</Tooltip>
							</Box>
						)}
					</MenuItem>
				))}
		</Select>
	);
};
export default Dropdown;
