import { styled } from "@mui/material/styles";
import { TextareaAutosize } from "@material-ui/core";
import { TextField, Typography } from "@mui/material";

const CreateNotificationTitle = styled(Typography)`
	font-size: 16px;
	margin: 20px 0px;
`;

const CreateNotificationSubtitle = styled(Typography)`
	font-size: 16px;
`;

const Text = styled(TextField)`
	width: 100%;
`;

const EditTemplateTextarea = styled(TextareaAutosize)(({ theme }) => ({
	fontSize: "1rem",
	padding: "10px",
	width: "100%",
	borderRadius: "4px",
	"&:hover": {
		border: "1px solid black !important",
	},
	"&:focus": {
		outline: "none !important",
		border: "none !important",
	},
}));

export { CreateNotificationSubtitle, CreateNotificationTitle, Text, EditTemplateTextarea };
