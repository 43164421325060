import React, { FC } from "react";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";

const ConfirmationPopUp: FC<{ isDelete: boolean; callback: (flag: boolean) => void; suffix: string }> = ({ isDelete, callback, suffix = "" }) => {
	const handleClose = (actionFlag) => {
		callback(actionFlag);
	};

	return (
		<Dialog open={true} onClose={handleClose}>
			<DialogContent style={{ padding: "25px" }}>
				<DialogContent style={{ padding: "10px 24px 20px" }}>
					<DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
						<Typography color={"#000000"}>{isDelete ? "Are you sure you want to delete" + (suffix && " " + suffix + " ") + "?" : ""}</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ justifyContent: "center" }}>
					<Button
						style={{ background: "#F1F5FE", border: "1px solid #08509A", color: "#08509A", margin: "0 20px" }}
						onClick={() => handleClose(false)}
						autoFocus
					>
						No
					</Button>
					<Button
						style={{ background: "#08509A", border: "1px solid #08509A", color: "#FFFFFF", margin: "0 20px" }}
						onClick={() => handleClose(true)}
						autoFocus
					>
						Yes
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmationPopUp;
