import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
	darkText: {
		color: "#000000 !important",
	},
	errorText: {
		color: "red",
	},
	nameInput: {
		"& fieldset": {
			border: "1px solid #000000 !important",
		},
	},
}));
const SaveDashboardPopup: React.FC<{
	open?: boolean;
	onSaveHandler;
	onCloseHandler;
	value?;
}> = ({ open = true, onCloseHandler, onSaveHandler, value }) => {
	const classes = useStyles();
	const [name, setName] = useState(value || "");
	const [error, setError] = useState("");
	const onNameChange = (e) => {
		if (error) setError("");
		setName(e.target.value);
	};
	const onSave = () => {
		if (name) {
			onSaveHandler(name);
		} else {
			setError("Please enter a valid name.");
		}
	};
	return (
		<div>
			<Dialog open={open} onClose={onCloseHandler} style={{ zIndex: 1 }}>
				<DialogTitle>Save Dashboard</DialogTitle>
				<DialogContent>
					<TextField
						InputProps={{ autoComplete: "off" }}
						autoFocus
						id="name"
						placeholder="Enter a Dashboard Name"
						type="text"
						variant="outlined"
						onChange={onNameChange}
						className={classes.nameInput}
						autoComplete="off"
						fullWidth
						style={{ width: 240 }}
						value={name}
					/>
					{error && <p className={classes.errorText}>{error}</p>}
				</DialogContent>
				<DialogActions>
					<Button onClick={onCloseHandler} className={classes.darkText}>
						Close
					</Button>
					<Button onClick={onSave} className={classes.darkText}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default SaveDashboardPopup;
