import React from "react";
import CategoryDashboardList from "../../components/CategoryDashboardList/CategoryDashboardList";
import PageContainer from "../../components/PageContainer";
import { pageList, pageNames } from "../../mocks/common";

const MixManagement: React.FC = () => {
	return (
		<PageContainer page={pageNames.mixManagement}>
			<CategoryDashboardList title={"Mix Management"} categoryKey={"mix_management"} pageName={pageList[16].value} />
		</PageContainer>
	);
};
export default MixManagement;
