import {
	AnchorProductsPayload,
	IFilterPayload,
	ISavedDashboardsPayload,
	PeriodViewPayload,
	PortalFeatureDashboardPayload,
	SellPeriodViewPayload,
	SharedDashboardsPayload,
} from "../../types/common";

// Creates a GraphQL query to get a list of countries.
export const COUNTRIES_QUERY = {
	query: "{getcountries}",
};

export const DASHBOARD_QUERY = {
	query: `{dashboards {
    countries
    id
    isFavorite
    name
    route
    thumbnailUrl
    type
    viewCount
  }}`,
};

/* Creates a GraphQL query to post trending page data.
 * @param {string} pageName - The page name.
 * @param {string} type - The page type.
 * @param {number} userId - The user id.
 * @returns The GraphQL query.
 */
export const CREATE_TREND_QUERY = (pageName: string, type: string, userId: string) => {
	return {
		query: `{createtrend(pageName: "${pageName}", type:"${type}", UserId:"${userId}"){
      country: Country
      createdBy: CreatedBy
      createdOn: CreatedOn
      date: Date
      id: Id
      isDisabled: IsDisabled
      modifiedBy: ModifiedBy
      modifiedOn: ModifiedOn
      route: Route
      screenName: ScreenName
      thumbnailUrl: ThumbnailUrl
      type: Type
      userId: UserId
    }}`,
	};
};

/* Creates a GraphQL query to get category data.
 * @param {number} userId - The user id.
 * @param {string} key - The category Key.
 * @returns The GraphQL query.
 */
export const CATEGORIES_DASHBOARD_QUERY = (userId: string, key: string) => {
	return {
		query: `{categories(UserId:"${userId}", key: "${key}"){
      countries: Countries
      createdOn: CreatedOn
      id: Id
      isFavorite: IsFavorite
      name: Name
      route: Route
      thumbnailUrl: ThumbnailUrl
      type: Type
      viewCount: ViewCount
    }}`,
	};
};

/* Creates a GraphQL query to update saved dashboard records.
 * @param {number} userId - The user id.
 * @param {ISavedDashboardsPayload} payload - The object contains saved dashboard request payload.
 * @returns The GraphQL query.
 */
export const UPDATE_SAVED_DASHBOARD = (userId: number, payload: ISavedDashboardsPayload) => {
	return {
		query: `mutation{updateSavedDashboard(
      UserId:"${userId}"
      DashboardId: "${payload.dashboardId}",
      Name: "${payload.name}",
      Version: "${payload.version}",
      Mmpw: "${payload.mmpw ? payload.mmpw : ""}",
      Filters: "${payload.filters}",
      Other: "${payload.other}",
      Country: "${payload.country}",
      Category: "${payload.category}"
      ${payload.id ? `Id: ${payload.id}` : ""}
      IsShare: "${payload.isShared}"
    ){
      id: Id
      userId: UserId
      dashboardId: DashboardId
      name: Name
      version: Version
      mmpw: Mmpw
      filters: Filters
      other: Other
      isDeleted: IsDeleted
      createdOn: CreatedOn
      modifiedOn: ModifiedOn
      isShare: IsShare
      country: Country
      dashboard: Dashboard
      user: User {
        address: Address
        country: Country
        defaultCountry: DefaultCountry
        defaultCategory: DefaultCategory
        permissionCountry: PermissionCountry
        permissionCategory: PermissionCategory
        emailAddress: EmailAddress
        firstName: FirstName
        lastName: LastName
        phoneNumber: PhoneNumber
        phoneNumberCountryCode: PhoneNumberCountryCode
        id: Id
        imageUrl: ImageUrl
        isAdmin: IsAdmin
        isDarkMode: IsDarkMode
        role: Role
      }
      sharedDashboards: SharedDashboards
      }
    }`,
	};
};

/* Creates a GraphQL query to geo level filter values based on user id and dashboard key.
 * @param {number} userId - The user id.
 * @param {string} dashboardKey - Value of dashboardKey.
 * @returns The GraphQL query.
 */
export const DASHBOARD_GEOLEVEL_QUERY = (userId: number, dashboardKey: string) => {
	return {
		query: `{dashboardKeygeoLevel2(dashboardKey: "${dashboardKey}", UserId: "${userId}") {
        label: Label
        geoLevels: GeoLevels {
          label
          value
          defaultVal
          channels {
            label
            value
            defaultVal
          }
          geoLevel2s {
            label
            value
            defaultVal
            parentField
            parent
          }
        }
      }
    }`,
	};
};

/* Creates a GraphQL query to get categories for the filters.
 * @param {string[]} countries - The list of selected countries.
 * @param {string} userId - The user id.
 * For create user page, userId is not passed. We get all the categories for the mentioned countries.
 * @returns The GraphQL query.
 */

export const FILTER_CATEGORY_QUERY = (countries: string[], userId?: string) => {
	return {
		query: `{
      filterCategories(
        ${userId ? `UserId:"${userId}"` : ""}
        countries: ${JSON.stringify(countries)}
      ){
        value: Value
        defaultVal: DefaultVal
        label: Label
      }
    }`,
	};
};

/* Creates a GraphQL query to portalFeatureDashboardData values based on geo filter selections.
 * @param {PortalFeatureDashboardPayload} payload- The object contains portal feature dashboard request payload.
 * @returns The GraphQL query.
 */
export const PORTAL_FEATURE_DASHBOARD_DATA_QUERY = (payload: PortalFeatureDashboardPayload) => {
	return {
		query: `{portalFeatureDashboardData(
			country: "${payload.country}"
			geoLevel: ${payload.geoLevel1 ? JSON.stringify(payload.geoLevel1) : null}
			geoLevel2:${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
			category: ${payload.categories ? JSON.stringify(payload.categories) : null}
			dashboardName: "${payload.dashboardName}"
			data: ${payload.data ? JSON.stringify(payload.data) : null}
			measureFilter: ${payload.measureFilter}
		) {
			dashboardName
			country
			sourceType
			sourceName
			maxDate
			refreshedDate
			dashboardUsedDate
		}}`,
	};
};

/* Creates a GraphQL query to get product filters for dashboards.
 * @param {IFilterPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const PRODUCT_FILTER_QUERY = (payload: IFilterPayload) => {
	return {
		query: `{products(
        brand: ${payload.brand ? JSON.stringify(payload.brand) : null},
        category: ${payload.category ? JSON.stringify(payload.category) : null},
        country: "${payload.country ?? null}"
        packSize: ${payload.packSize ? JSON.stringify(payload.packSize) : null}
        segment: ${payload.segment ? JSON.stringify(payload.segment) : null}
        subBrand: ${payload.subBrand ? JSON.stringify(payload.subBrand) : null}
      ){
        category {
          id
          value
        }
        segment {
          id
          value
        }
        brand {
          id
          value
        }
        subBrand {
          id
          value
        }
        packSize {
          id
          value
        }
        anchorCategory {
          id
          value
        }
        anchorSegment {
          id
          value
        }
        anchorBrand {
          id
          value
        }
        anchorSubBrand {
          id
          value
        }
        anchorPackSize {
          id
          value
        }
      }
    }
    `,
	};
};

/* Creates a GraphQL query to update shared dashboard records.
 * @param {number} userId - The user id.
 * @param {SharedDashboardsPayload} payload - The object contains share dashboard request payload.
 * @returns The GraphQL query.
 */
export const SHARE_DASHBOARD_QUERY = (userId: number, payload: SharedDashboardsPayload) => {
	return {
		query: `mutation {
      updateShares(
        emailAddresses: ${payload.emailAddresses ? JSON.stringify(payload.emailAddresses) : null}
        id: ${payload.id}
        dashboardId: ${payload.dashboardId}
        name: "${payload.name}"
        version: "${payload.version}"
        mmpw: "${payload.mmpw ? payload.mmpw : ""}",
        filters: "${payload.filters}"
        other: "${payload.other}"
        isShared: "${payload.isShared}"
        country: "${payload.country}"
        category: "${payload.category}"
        key: "${payload.key}"
        userId: "${userId}"
      )
    }`,
	};
};

/* Creates a GraphQL query to anchor products values based on geo filter selections.
 * @param {AnchorProductsPayload} payload- The object contains anchor products request payload.
 * @returns The GraphQL query.
 */
export const ANCHOR_PRODUCTS_QUERY = (payload: AnchorProductsPayload, userId: number) => {
	return {
		query: `{
      anchorproducts(
        userId: "${userId}"
        country: "${payload.country}"
        anchorCategory: ${payload.anchorCategory ? JSON.stringify(payload.anchorCategory) : null}
        anchorSegment: ${payload.anchorSegment ? JSON.stringify(payload.anchorSegment) : null}
        anchorBrand: ${payload.anchorBrand ? JSON.stringify(payload.anchorBrand) : null}
        anchorSubBrand: ${payload.anchorSubBrand ? JSON.stringify(payload.anchorSubBrand) : null}
        anchorPackSize: ${payload.anchorPackSize ? JSON.stringify(payload.anchorPackSize) : null}
      ) {
        category {
          id
          value
        }
        segment {
          id
          value
        }
        brand {
          id
          value
        }
        subBrand {
          id
          value
        }
        packSize {
          id
          value
        }
        anchorCategory {
          id
          value
        }
        anchorSegment {
          id
          value
        }
        anchorBrand {
          id
          value
        }
        anchorSubBrand {
          id
          value
        }
        anchorPackSize {
          id
          value
        }
      }
    }`,
	};
};

/* Creates a GraphQL query for period view filter selection.
 * @param {string} dashboardKey - The dashboard key.
 * @param {PeriodViewPayload} payload - The object contains period view request payload.
 * @returns The GraphQL query.
 */
export const PERIOD_VIEW_QUERY = (dashboardKey: string, payload: PeriodViewPayload) => {
	return {
		query: `{
      periodView(
        Dashoboardkey: "${dashboardKey}"
        period: "${payload.period}"
        country: "${payload.country}"
        geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
        geoLevel2:${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
        category: ${payload.category ? JSON.stringify(payload.category) : null}
      ) {
        startDate: StartDate
        endDate: EndDate
      }
    }`,
	};
};

/* Creates a GraphQL query for sell period filter selection.
 * @param {string} dashboardKey -The dashboard key.
 * @param {SellPeriodViewPayload} payload - The object contains sell period view request payload.
 * @returns The GraphQL query.
 */
export const SELL_PERIOD_VIEW_QUERY = (dashboardKey: string, payload: SellPeriodViewPayload) => {
	return {
		query: `{
      sellPeriodView(
        Dashoboardkey: "${dashboardKey}"
        period: "${payload.period}"
        country: "${payload.country}"
        geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
        geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
        category: ${payload.category ? JSON.stringify(payload.category) : null}
      ) {
        sellInStartDate: SellInStartDate
        sellInEndDate: SellInEndDate
        sellOutStartDate: SellOutStartDate
        sellOutEndDate: SellOutEndDate
      }
    }`,
	};
};
