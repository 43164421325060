import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup/RadioButtonGroup";
import dataObject from "../../../../mocks/executionTracker";

const formatNumber = (value, decimalPoints) => {
	let absValue = Math.abs(value);
	const suffixes = ["", "K", "M", "B", "T"];
	let suffixIndex = 0;

	while (absValue >= 1000 && suffixIndex < suffixes.length - 1) {
		absValue /= 1000;
		suffixIndex++;
	}
	const formattedValue = value >= 0 ? absValue : -absValue;
	const suffix = suffixes[suffixIndex];

	return `${formattedValue.toFixed(decimalPoints)} ${suffix}`;
};
const SizeOfPrize: React.FC<{ data; chartSkeleton?: boolean }> = ({ data, chartSkeleton = true }) => {
	const [selectedOpportunity, setSelectedOpportunity] = useState(dataObject.sizeOfPrizeRadio.defaultOption.value);
	const [opportunityData, setOpportunityData] = useState<any>();

	useEffect(() => {
		setOpportunityData(data[selectedOpportunity]);
	}, [data, selectedOpportunity]);
	return (
		<>
			{!chartSkeleton ? (
				<>
					<Grid container>
						<RadioButtonGroup
							direction="row"
							color="#000"
							showButton={true}
							showSkeleton={false}
							data={dataObject.sizeOfPrizeRadio.options}
							defaultOption={selectedOpportunity}
							select={(data) => setSelectedOpportunity(data)}
						/>
					</Grid>
					<List>
						{Object.keys(opportunityData).map((item) => {
							return (
								<>
									<ListItem alignItems="flex-start">
										<ListItemAvatar>
											<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
										</ListItemAvatar>
										<ListItemText
											primary={item.toUpperCase()}
											primaryTypographyProps={{
												sx: { color: "#7A7A7A" }, // Change the color here
											}}
											secondary={
												<React.Fragment>
													<Stack>
														<Stack direction="row" alignItems="center" justifyContent="space-between">
															<Typography component="span" variant="body2" sx={{ color: "text.primary", display: "inline" }}>
																SOP Revenue :
															</Typography>
															<Typography color="#335899">{formatNumber(opportunityData[item].sop, 1)}</Typography>
														</Stack>
														<Stack direction="row" alignItems="center" justifyContent="space-between">
															<Typography component="span" variant="body2" sx={{ color: "text.primary", display: "inline" }}>
																SOP NOPBT
															</Typography>
															<Typography color="#335899">{formatNumber(opportunityData[item].nopbt, 1)}</Typography>
														</Stack>
													</Stack>
												</React.Fragment>
											}
										/>
									</ListItem>
									<Divider variant="inset" component="li" />
								</>
							);
						})}
					</List>
				</>
			) : (
				<Grid item xs={12}>
					<Skeleton variant="rectangular" height={330} />
				</Grid>
			)}
		</>
	);
};

export default SizeOfPrize;
