import React, { useEffect, useRef, useState } from "react";
import Dashboard from "../../../../../../components/Dashboard";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { columnChartConfig } from "./columnChartConfig";
import { Grid } from "@mui/material";
import _ from "lodash";
import WeeklyTable from "../../../WeeklyTable";
import HighchartContainer from "../../../../../../components/HighchartContainer";
import { formatNumber } from "../../../../../../util/helper";

const PriceElasticityRealVsExpectedComponent: React.FC<{
	id;
	data;
	unitType;
}> = ({ id, data, unitType }) => {
	const chartRef1 = useRef(null);
	const chartRef2 = useRef(null);
	const [table1Headings, setTable1Headings] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
	const [table1Values, setTable1Values] = useState([]);
	const [table2Headings, setTable2Headings] = useState([1]);
	const [table2Values, setTable2Values] = useState([]);

	useEffect(() => {
		if (_.isObject(data) && Object.keys(data).length > 0) {
			if (chartRef1 && chartRef1.current && chartRef1.current.chart) {
				const chart = chartRef1.current.chart;
				const series1: any = [],
					series2: any = [],
					xAxis: any = [],
					tableHeadings: any = [];
				data.weekData1.map((x, i) => {
					series1.push(x.real);
					series2.push(x.expected);
					tableHeadings.push(x.date);
					xAxis.push(x.date);
				});
				chart.xAxis[0].setCategories(xAxis);
				chart.series[0].setData(series1);
				chart.series[1].setData(series2);
				chart.yAxis[0].update({
					labels: {
						formatter: function () {
							return `${formatNumber(this.value)}${unitType === "unit" ? "%" : ""}`;
						},
					},
				});
				chart.series[0].update({
					tooltip: {
						pointFormatter: function () {
							return `${formatNumber(this.y)}${unitType === "unit" ? "%" : ""}`;
						},
					},
				});
				chart.series[1].update({
					tooltip: {
						pointFormatter: function () {
							return `${formatNumber(this.y)}${unitType === "unit" ? "%" : ""}`;
						},
					},
				});
				const tableData = [];
				tableData.push({ title: "Real", width: 200, data: series1 });
				tableData.push({ title: "Expected", width: 200, data: series2 });
				setTable1Headings(tableHeadings);
				setTable1Values(tableData);
			}

			if (chartRef2 && chartRef2.current && chartRef2.current.chart) {
				const chart = chartRef2.current.chart;
				const series1 = [],
					series2 = [],
					tableHeadings = [];
				[data.accumulatedResult].map((x, i) => {
					series1.push(x.real);
					series2.push(x.expected);
					tableHeadings.push("Accumulated");
				});
				chart.series[0].setData(series1);
				chart.series[1].setData(series2);
				chart.xAxis[0].setTitle({
					text: "Accumulated",
					style: { fontSize: 18 },
				});
				chart.xAxis[0].update({ labels: { enabled: false } });
				chart.yAxis[0].update({
					labels: {
						formatter: function () {
							return `${formatNumber(this.value)}${unitType === "unit" ? "%" : ""}`;
						},
					},
				});
				chart.xAxis[0].setCategories([""]);
				chart.series[0].update({
					tooltip: {
						pointFormatter: function () {
							return `${formatNumber(this.y)}${unitType === "unit" ? "%" : ""}`;
						},
					},
				});
				chart.series[1].update({
					tooltip: {
						pointFormatter: function () {
							return `${formatNumber(this.y)}${unitType === "unit" ? "%" : ""}`;
						},
					},
				});

				const tableData = [];
				tableData.push({ title: "Real", width: 200, data: series1 });
				tableData.push({ title: "Expected", width: 200, data: series2 });
				setTable2Headings(tableHeadings);
				setTable2Values(tableData);
			}
		}
	}, [data]);

	return (
		<Grid container spacing={2} columns={18} className={"m-b-20"}>
			<Grid item sm={13}>
				<Dashboard title={data.displayName} id={id} chartRef={chartRef1}>
					<Grid
						style={{
							border: "3px solid #cad0d8",
							borderRadius: 10,
							padding: 20,
						}}
					>
						<HighchartContainer chartRef={chartRef1} id={id}>
							<HighchartsReact highcharts={Highcharts} options={columnChartConfig} ref={chartRef1} />
						</HighchartContainer>
						<WeeklyTable heading={table1Headings} data={table1Values} showPercent={unitType === "unit"} unitType={unitType} />
					</Grid>
				</Dashboard>
			</Grid>
			<Grid item sm={5}>
				<Dashboard id={`${id}-2`} chartRef={chartRef2}>
					<Grid
						style={{
							border: "3px solid #cad0d8",
							borderRadius: 10,
							padding: 20,
							paddingBottom: 26,
						}}
					>
						<HighchartContainer chartRef={chartRef2} id={`${id}-2`}>
							<HighchartsReact highcharts={Highcharts} options={columnChartConfig} ref={chartRef2} />
						</HighchartContainer>
						<WeeklyTable heading={table2Headings} data={table2Values} showPercent={unitType === "unit"} unitType={unitType} />
					</Grid>
				</Dashboard>
			</Grid>
		</Grid>
	);
};

export default PriceElasticityRealVsExpectedComponent;
