import { MACRO_ECON_VAR_DATA_SRC, MACRO_ECON_DATA, MACRO_ECON_PRODUCT_FILTER, MACRO_ECON_CHECK_DATA } from "./constants";

let intiState = {
	varDataSrcLoader: false,
	macroEconLoader: false,
	productFilterLoader: false,
	macroEconCheckLoader: false,
};

const MacroEconDashboard = (state = intiState, action) => {
	switch (action.type) {
		case MACRO_ECON_VAR_DATA_SRC:
			return {
				...state,
				varDataSrcLoader: action.payload,
			};
		case MACRO_ECON_DATA:
			return {
				...state,
				macroEconLoader: action.payload,
			};
		case MACRO_ECON_PRODUCT_FILTER:
			return {
				...state,
				productFilterLoader: action.payload,
			};
		case MACRO_ECON_CHECK_DATA:
			return {
				...state,
				macroEconCheckLoader: action.payload,
			};
		default:
			return state;
	}
};

export default MacroEconDashboard;
