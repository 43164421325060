import React, { useEffect, useState } from "react";
import { CardContent, Grid, Card, Checkbox, MenuItem } from "@mui/material";
import { Title } from "../../MMPW.Styled";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveLabel } from "../../../../store/actions/common.action";
import { ISectionColorPayload } from "../../../../types/common";

const AddLabel: React.FC<{ chart; data; id }> = ({ chart, data, id }) => {
	const [series, setSeries] = useState<ISectionColorPayload[]>([]);
	const dispatch = useDispatch();
	const tooltips = useSelector((state: any) => state.common.highChartTooltips);

	useEffect(() => {
		setSeries(data);
	}, [data]);

	const onChecked = (event, lineIndex) => {
		dispatch(addRemoveLabel([...chart.series[lineIndex].data], id, tooltips, chart, event.target.checked));
	};

	return (
		<Card>
			<CardContent>
				<Grid item xs={12} className="m-b-20">
					<Title>Add Label</Title>
					<hr />
				</Grid>
				<Grid>
					{_.isArray(series) &&
						series.map((item, i) => (
							<Grid container display="flex" alignItems="center" className="m-b-10" key={`grid-${i}`}>
								<MenuItem onClick={(event) => onChecked(event, item.lineIndex)}>
									<Checkbox
										checked={
											!_.isEmpty(tooltips[id]) && tooltips[id] && item.lineIndex
												? tooltips[id].filter((x) => x.seriesIndex === item.lineIndex).length ===
												  [...chart.series[item.lineIndex].data.filter((d) => d.y !== null)].length
												: false
										}
										color="secondary"
									/>
									{item.title}
								</MenuItem>
							</Grid>
						))}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default AddLabel;
