import { PREDICTIVE_PACK_ECONOMICS, PREDICTIVE_PACK_ECONOMICS_DETAILS } from "./constants";

let intiState = {
	predictivePackEconomics: false,
	predictivePackEconomicsDetails: false,
};

const PredictivePackEconomics = (state = intiState, action) => {
	switch (action.type) {
		case PREDICTIVE_PACK_ECONOMICS:
			return {
				...state,
				predictivePackEconomics: action.payload,
			};
		case PREDICTIVE_PACK_ECONOMICS_DETAILS:
			return {
				...state,
				predictivePackEconomicsDetails: action.payload,
			};
		default:
			return state;
	}
};

export default PredictivePackEconomics;
