/* API URLs */
export const baseApi = async () => {
	const res = (await fetch("/env/env.json")).json();
	return res.then((obj) => obj.BACKEND_BASE_URL);
};
export const graphqlAPI = async () => {
	const res = (await fetch("/env/env.json")).json();
	return res.then((obj) => obj.BACKEND_GRAPHQL_URL + "graphql");
};

export const userProfileAPI = async () => {
	const res = (await fetch("/env/env.json")).json();
	return res.then((obj) => obj.BACKEND_GRAPHQL_URL + "profiles");
};

export const clearServerCacheAPI = async () => {
	const res = (await fetch("/env/env.json")).json();
	return res.then((obj) => obj.BACKEND_GRAPHQL_URL + "cache/clear");
};
