import { GET_NOTIFICATION, GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE } from "../../actions/AlertAndNotification/constants";

let getNotificationInitialState = {
	data: null,
	loading: true,
	error: null,
};

const GetAllNotification = (state = getNotificationInitialState, action) => {
	switch (action.type) {
		case GET_NOTIFICATION:
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			};
		case GET_NOTIFICATION_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
				error: null,
			};
		case GET_NOTIFICATION_FAILURE:
			return {
				...state,
				data: null,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default GetAllNotification;
