import { Box, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { formatDecimal } from "../../util/helper";

const useStyles = makeStyles(() => ({
	progressBar: {
		"& .MuiLinearProgress-bar": {
			backgroundColor: "#00bfff",
			borderTopRightRadius: 14,
			borderBottomRightRadius: 14,
		},
	},
}));

const ProgressBarChart: FC<{ value: number; maxValue: number }> = ({ value, maxValue }) => {
	const classes = useStyles();
	return (
		<Box sx={{ width: "100%", position: "relative" }}>
			<LinearProgress
				className={classes.progressBar}
				variant="determinate"
				value={maxValue ? (value / maxValue) * 100 : 0}
				sx={{ background: "transparent", height: 28 }}
			/>
			<Box
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typography color={"#000000"} fontSize={14}>{`${formatDecimal(value, 1)}%`}</Typography>
			</Box>
		</Box>
	);
};
export default ProgressBarChart;
