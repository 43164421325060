import { IFilterPayload, SomCorrelationPayload } from "../../types/common";
/* Creates a GraphQL query to get multiple API products filters for API vs Volume dashboard.
 * @param {IFilterPayload} payload - The object contains filter request payload.
 * @param {number} userId - The user id.
 * @returns The GraphQL query.
 */
export const MULTIPLE_API_PRODUCT_QUERY = (payload: IFilterPayload, userId: number) => {
	return {
		query: `{multipleapiproducts(
        UserId: "${userId}"
        type: "${payload.type}"
        country: "${payload.country ?? null}"
        vendor: ${payload.vendor ? JSON.stringify(payload.vendor) : null}
        category: ${payload.category ? JSON.stringify(payload.category) : null}
        segment: ${payload.segment ? JSON.stringify(payload.segment) : null}
        brand: ${payload.brand ? JSON.stringify(payload.brand) : null}
        subBrand: ${payload.subBrand ? JSON.stringify(payload.subBrand) : null}
        packSize: ${payload.packSize ? JSON.stringify(payload.packSize) : null}
      ){
        category{
          id
          value
        }
        vendor{
          id
          value
        }
        segment{
          id
          value
        }
        brand{
          id
          value
        }
        subBrand{
          id
          value
        }
        packSize{
          id
          value
        } 
      }
    }
    `,
	};
};

/* Creates a GraphQL query to get correlation chart data for API vs Volume dashboard.
 * @param {SomCorrelationPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const SOM_CORRELATION_QUERY = (payload: SomCorrelationPayload) => {
	return {
		query: `{
      somcorrelation(
        country: "${payload.country}"
        geoLevel: ${JSON.stringify(payload.geoLevel)}
        geoLevel2: ${JSON.stringify(payload.geoLevel2)}
        channel: ${JSON.stringify(payload.channel)}
        periodView: "${payload.periodView}"
        vendor: ${JSON.stringify(payload.vendor)}
        category: ${JSON.stringify(payload.category)}
        segment: ${payload.segment ? JSON.stringify(payload.segment) : null}
        brand: ${payload.brand ? JSON.stringify(payload.brand) : null}
        subBrand: ${payload.subBrand ? JSON.stringify(payload.subBrand) : null}
        packSize: ${payload.packSize ? JSON.stringify(payload.packSize) : null}
        permutationComputation: ${payload.permutation ? JSON.stringify(payload.permutation) : null}
        anchorVendor: ${JSON.stringify(payload.anchorVendor)}
        anchorCategory: ${JSON.stringify(payload.anchorCategory)}
        anchorSegment: ${payload.anchorSegment ? JSON.stringify(payload.anchorSegment) : null}
        anchorBrand: ${payload.anchorBrand ? JSON.stringify(payload.anchorBrand) : null}
        anchorSubBrand: ${payload.anchorSubBrand ? JSON.stringify(payload.anchorSubBrand) : null}
        anchorPackSize: ${payload.anchorPackSize ? JSON.stringify(payload.anchorPackSize) : null}
        anchorPermutation: ${payload.anchorPermutation ? JSON.stringify(payload.anchorPermutation) : null}
        variable1: "${payload.variable1}"
        variable2: "API"
        somMeasure: "${payload.somMeasure}"
        somGroup: "${payload.somGroup}"
        apiUnits: "${payload.apiUnits}"
        date: "${payload.date}"
      ) {
        weekly {
          correlation
          variable1 {
            date
            value
          }
          variable2 {
            date
            value
          }
          productName
          productInfo
          anchorProductName
          anchorProductInfo
        }
        monthly {
          correlation
          variable1 {
            date
            value
          }
          variable2 {
            date
            value
          }
          productName
          productInfo
          anchorProductName
          anchorProductInfo
        }
        quarterly {
          correlation
          variable1 {
            date
            value
          }
          variable2 {
            date
            value
          }
          productName
          productInfo
          anchorProductName
          anchorProductInfo
        }
        yearly {
          correlation
          variable1 {
            date
            value
          }
          variable2 {
            date
            value
          }
          productName
          productInfo
          anchorProductName
          anchorProductInfo
        }
      }
    }`,
	};
};

/* Creates a GraphQL query to get correlation table data for API vs Volume dashboard.
 * @param {SomCorrelationPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const SOM_CORRELATION_DATA_TABLE_QUERY = (payload: SomCorrelationPayload) => {
	return {
		query: `{
      somCorrelationDataTable(
        country: "${payload.country}"
        geoLevel: ${JSON.stringify(payload.geoLevel)}
        geoLevel2: ${JSON.stringify(payload.geoLevel2)}
        channel: ${JSON.stringify(payload.channel)}
        periodView: "${payload.periodView}"
        vendor: ${JSON.stringify(payload.vendor)}
        category: ${JSON.stringify(payload.category)}
        segment: ${payload.segment ? JSON.stringify(payload.segment) : null}
        brand: ${payload.brand ? JSON.stringify(payload.brand) : null}
        subBrand: ${payload.subBrand ? JSON.stringify(payload.subBrand) : null}
        packSize: ${payload.packSize ? JSON.stringify(payload.packSize) : null}
        permutationComputation: ${payload.permutation ? JSON.stringify(payload.permutation) : null}
        anchorVendor: ${JSON.stringify(payload.anchorVendor)}
        anchorCategory: ${JSON.stringify(payload.anchorCategory)}
        anchorSegment: ${payload.anchorSegment ? JSON.stringify(payload.anchorSegment) : null}
        anchorBrand: ${payload.anchorBrand ? JSON.stringify(payload.anchorBrand) : null}
        anchorSubBrand: ${payload.anchorSubBrand ? JSON.stringify(payload.anchorSubBrand) : null}
        anchorPackSize: ${payload.anchorPackSize ? JSON.stringify(payload.anchorPackSize) : null}
        anchorPermutation: ${payload.anchorPermutation ? JSON.stringify(payload.anchorPermutation) : null}
        variable1: "${payload.variable1}"
        variable2: "API"
        somMeasure: "${payload.somMeasure}"
        somGroup: "${payload.somGroup}"
        apiUnits: "${payload.apiUnits}"
        date: "${payload.date}"
      ) {
        pepsico {
          weekly {
            dateString
            date
            packSizeData {
              name
              grammage
              grammagePercentage
            }
          }
          monthly {
            dateString
            date
            packSizeData {
              name
              grammage
              grammagePercentage
            }
          }
          quarterly {
            dateString
            date
            packSizeData {
              name
              grammage
              grammagePercentage
            }
          }
          yearly {
            dateString
            date
            packSizeData {
              name
              grammage
              grammagePercentage
            }
          }
        }
        anchor {
          weekly {
            dateString
            date
            packSizeData {
              name
              grammage
              grammagePercentage
            }
          }
          monthly {
            dateString
            date
            packSizeData {
              name
              grammage
              grammagePercentage
            }
          }
          quarterly {
            dateString
            date
            packSizeData {
              name
              grammage
              grammagePercentage
            }
          }
          yearly {
            dateString
            date
            packSizeData {
              name
              grammage
              grammagePercentage
            }
          }
        }
        avg {
          weekly {
            year
            avgSelloutPepsi
            growthAvgSelloutPepsi
            avgSelloutAnchor
            growthAvgSelloutAnchor
            salesAmountPepsi
            growthSalesAmountPepsi
            pepsicoSaleVolumekg
            growthPepsicoSaleVolumekg
            pepsicoSaleVolumeunit
            growthPepsicoSaleVolumeunit
          }
          monthly {
            year
            avgSelloutPepsi
            growthAvgSelloutPepsi
            avgSelloutAnchor
            growthAvgSelloutAnchor
            salesAmountPepsi
            growthSalesAmountPepsi
            pepsicoSaleVolumekg
            growthPepsicoSaleVolumekg
            pepsicoSaleVolumeunit
            growthPepsicoSaleVolumeunit
          }
          quarterly {
            year
            avgSelloutPepsi
            growthAvgSelloutPepsi
            avgSelloutAnchor
            growthAvgSelloutAnchor
            salesAmountPepsi
            growthSalesAmountPepsi
            pepsicoSaleVolumekg
            growthPepsicoSaleVolumekg
            pepsicoSaleVolumeunit
            growthPepsicoSaleVolumeunit
          }
          yearly {
            year
            avgSelloutPepsi
            growthAvgSelloutPepsi
            avgSelloutAnchor
            growthAvgSelloutAnchor
            salesAmountPepsi
            growthSalesAmountPepsi
            pepsicoSaleVolumekg
            growthPepsicoSaleVolumekg
            pepsicoSaleVolumeunit
            growthPepsicoSaleVolumeunit
          }
        }
      }
    }`,
	};
};

/* Creates a GraphQL query to get common product filters for API vs Volume dashboard.
 * @param {IFilterPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const API_VOLUME_PRODUCT_FILTER_QUERY = (payload: IFilterPayload, userId: string) => {
	return {
		query: `{
      apiVsVolumeProductFilters(country: "${payload.country}"
      UserId: "${userId}")
    }`,
	};
};
