import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import FilterAccordion from "../../../../components/Filters";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import dataObject from "../../../../mocks/executionTracker";
import { IAssortmentPenetrationLimit, IMnAFilterConfigurations } from "../../../../types/mixAndAssortment";

const UserInputFilters: React.FC<{
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
	selectedFilters: IMnAFilterConfigurations;
	onChangeFilter;
	selectedPenetrationLimit: IAssortmentPenetrationLimit;
	setSelectedPenetrationLimit: Dispatch<SetStateAction<IAssortmentPenetrationLimit>>;
}> = ({ setSelectedFilters, selectedFilters, onChangeFilter, selectedPenetrationLimit, setSelectedPenetrationLimit }) => {
	return (
		<FilterAccordion title="User Inputs">
			<Grid container>
				<Grid item sm={9} className="geo_filters_left">
					<Typography color="#7E7E7E" fontWeight={600} marginBottom={2}>
						Decision Variables
					</Typography>
					<CommonMnAFilters
						filterData={dataObject.overallFilterOptions}
						onChange={onChangeFilter}
						data={dataObject.decesionFilter}
						defaultFilters={selectedFilters ?? null}
						filterOrder={dataObject.decesionFilterOrder}
					/>
				</Grid>
			</Grid>
			<Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
				<Typography color="#7E7E7E" fontWeight={600}>
					Assortment Targets (in %)
				</Typography>
				<Stack direction="row" spacing={1}>
					<Typography fontWeight={600} fontSize="12px" color="#000000">
						*NOTE:
					</Typography>
					<Typography color="#7E7E7E" fontSize="12px">
						Assigned Penetration (Distribution) Target can Individually be 0-100%
					</Typography>
				</Stack>
			</Stack>
			<Grid container spacing={2} className="geo_filters_left" columns={12}>
				<Grid item display="flex" xs={12}>
					<Grid item sm={2} display="flex" alignItems="center">
						<Grid item sm={8}>
							<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
								Must have SKU*
							</Typography>
						</Grid>
						<Grid item sm={4}>
							<TextField
								type="number"
								value={selectedPenetrationLimit.mustHaveSKU}
								inputProps={{ min: 0, max: 100 }}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									if (event.target) {
										setSelectedPenetrationLimit({ ...selectedPenetrationLimit, mustHaveSKU: parseFloat(event.target.value) });
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid item display="flex" alignItems="center" sm={2} sx={{ marginLeft: "50px" }}>
						<Grid item sm={8}>
							<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
								Good to have SKU*
							</Typography>
						</Grid>
						<Grid item sm={4}>
							<TextField
								type="number"
								inputProps={{ min: 0, max: 100 }}
								value={selectedPenetrationLimit.goodToHaveSKU}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									if (event.target) {
										setSelectedPenetrationLimit({ ...selectedPenetrationLimit, goodToHaveSKU: parseFloat(event.target.value) });
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item display="flex" xs={12}>
					<Grid item sm={2} display="flex" alignItems="center">
						<Grid item sm={8}>
							<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
								Current Average
							</Typography>
						</Grid>
						<Grid item sm={4}>
							<TextField type="number" value={selectedPenetrationLimit.mustHaveSkuCurrentAverage} disabled />
						</Grid>
					</Grid>
					<Grid item display="flex" alignItems="center" sm={2} sx={{ marginLeft: "50px" }}>
						<Grid item sm={8}>
							<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
								Current Average
							</Typography>
						</Grid>
						<Grid item sm={4}>
							<TextField type="number" value={selectedPenetrationLimit.goodToHaveSkuCurrentAverage} disabled />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</FilterAccordion>
	);
};

export default UserInputFilters;
