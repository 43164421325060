import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { getAllUsers } from "../../../pages/User/service";
import { makeStyles } from "@material-ui/core/styles";
import { DialogTitle, Grid } from "@mui/material";
import { OrangeBtn, PrimaryBtn } from "../../../styles/Common.Styled";
import _ from "lodash";

const useStyles = makeStyles(() => ({
	darkText: {
		color: "#000000 !important",
	},
	errorText: {
		color: "red",
	},
	nameInput: {
		width: "100%",
		"& fieldset": {
			border: "1px solid #000000 !important",
		},
	},
	selectClass: {
		"& ul": {
			height: "400px !important",
		},
	},
	formControl: {
		width: "100% !important",
	},
	indeterminateColor: {
		color: "#005EA6 !important",
	},
	selectAllText: {
		fontWeight: 500,
	},
	selectedAll: {
		backgroundColor: "rgba(0, 0, 0, 0.08) !important",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08) !important",
		},
	},
	popupWidth: {
		width: "430px !important",
	},
}));

const ShareConfirmationPopup: React.FC<{
	onCloseHandler;
	shareData;
	userDetail;
	onShare;
}> = ({ onCloseHandler, shareData, userDetail, onShare }) => {
	const classes = useStyles();
	const [name, setName] = useState(shareData.name || "");
	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState([]);

	const getUserList = async () => {
		// Passing blank string to pull user data for all the countries
		const response = await getAllUsers("");
		if (response?.data) {
			let formattedUsers = response?.data?.map((item) => ({ title: item?.fullName, value: item.emailAddress })).filter((item) => item.id !== userDetail.id);
			formattedUsers = _.orderBy(formattedUsers, "title", "asc");
			setUserList(formattedUsers);
		}
	};

	useEffect(() => {
		getUserList();
	}, []);

	const onSelectUsers = (event, values) => {
		const selectedEmails = values.map((val) => val.value);
		setSelectedUser(selectedEmails);
	};

	return (
		<Dialog open={true} onClose={onCloseHandler} style={{ zIndex: 1 }}>
			<DialogTitle>Share Dashboard</DialogTitle>
			<DialogContent className={classes.popupWidth}>
				<Grid>
					<Grid className="m-b-20">
						<TextField
							InputProps={{ autoComplete: "off" }}
							autoFocus
							placeholder="Enter a Dashboard Name"
							type="text"
							id="dashboardName"
							name="dashboardName"
							value={name}
							variant="outlined"
							onChange={(e) => setName(e.target.value)}
							className={classes.nameInput}
							autoComplete="off"
						/>
					</Grid>
					<Grid>
						<Autocomplete
							multiple
							limitTags={3}
							id="tags-standard"
							options={userList}
							getOptionLabel={(option) => option.title}
							onChange={onSelectUsers}
							renderOption={(props, options) => {
								return (
									<span {...props} style={{ color: "black" }}>
										{options.title}
									</span>
								);
							}}
							renderInput={(params) => <TextField {...params} variant="standard" className={classes.nameInput} placeholder="Select Users" />}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<OrangeBtn color="secondary" className="m-r-10" onClick={onCloseHandler}>
					Close
				</OrangeBtn>
				<PrimaryBtn color="primary" disabled={!name || selectedUser.length === 0} onClick={() => onShare(name, selectedUser)}>
					Share
				</PrimaryBtn>
			</DialogActions>
		</Dialog>
	);
};

export default ShareConfirmationPopup;
