import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const SrpSopPrice = styled(Typography)(() => ({
	fontSize: 18,
	fontWeight: 600,
	color: `#fff`,
	height: `100%`,
	background: `linear-gradient(to right, rgb(13, 54, 103), rgb(182, 22, 182))`,
	display: `flex`,
	justifyContent: `center`,
	alignItems: `center`,
	margin: `0 6px`,
	borderRadius: `18px`,
	borderTopLeftRadius: `0px`,
	borderBottomRightRadius: `0`,
	minWidth: `82px`,
	position: "relative",
}));

const SomBlk = styled(Typography)(() => ({
	fontSize: 18,
	fontWeight: 600,
	color: `#000`,
	height: `100%`,
	background: `#fff`,
	display: `flex`,
	justifyContent: `center`,
	alignItems: `center`,
	margin: `0 6px`,
	flexDirection: `column`,
	backgroundColor: "transparent",
}));

const ListVendors = styled(Typography)(({ theme }) => ({
	width: "100%",
	display: "flex",
	justifyContent: "space-between",
	flexWrap: "wrap",
	alignItems: "stretch",
	marginBottom: "10px",
}));

const ProductList = {
	color: "#000",
	display: "flex",
	width: "auto",
	justifyContent: "center",
	padding: "4px",
};
const ListLogo = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "0 3px 0 0",
};
const ListContentBlk = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "0 5px",
};
const ListContentTitle = {
	fontSize: "18px",
	lineHeight: "1.5",
	fontWeight: "normal",
};
const ModalBrandSec = {
	width: "100%",
	flexWrap: "wrap",
	margin: "0",
	padding: "0",
	display: "flex",
	justifyContent: "flex-start",
	flexDirection: "row",
};
const ModalBrandList = {
	borderRadius: "2px",
	color: "#000",
	display: "flex",
	width: "32%",
	justifyContent: "flex-end",
	border: "1px solid #eceaea",
	margin: "0.5%",
	padding: "9px",
};
const SrpSopBlk = {
	flex: "1 0 auto",
	display: "flex",
	width: "100%",
	alignItems: "stretch",
	justifyContent: "space-around",
	pl: 0,
	pb: 0,
};
const SrpSopBlueSec = {
	display: "flex",
	WebkitAlignItems: "stretch",
	width: "100%",
	alignItems: "center",
	justifyContent: "space-between",
	pt: 0,
	pb: 0,
};
const SrpSopBlueSecLeft = {
	width: "100%",
	flexWrap: "wrap",
	alignItems: "center",
	height: "100%",
	padding: "3px",
	borderRadius: "6px",
	background: "#bdd5ed",
	display: "flex",
	justifyContent: "flex-end",
	minHeight: "73px",
	marginLeft: "4px",
};
const SrpSopPinkSecRight = {
	width: "100%",
	flexWrap: "wrap",
	alignItems: "center",
	height: "100%",
	padding: "3px",
	borderRadius: "6px",
	background: "#ead4ea",
	display: "flex",
	justifyContent: "flex-start",
	minHeight: "73px",
	marginRight: "4px",
};
const SrpBlueList = {
	borderRadius: "6px",
	marginRight: "2px",
	background: "#1e4f81",
	color: "#fff",
	display: "flex",
	width: "auto",
	justifyContent: "flex-end",
	padding: "1px",
	marginBottom: "2px",
	minHeight: "66px",
	":hover": { cursor: "move" },
	transform: "translate3d(0, 0, 0)",
};
const SopPinkList = {
	borderRadius: "6px",
	marginRight: "2px",
	background: "#996699",
	color: "#fff",
	display: "flex",
	width: "auto",
	justifyContent: "flex-end",
	padding: "1px",
	marginBottom: "2px",
	minHeight: "66px",
	transform: "translate3d(0, 0, 0)",
};

const SrpSopImageSec = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "0 2px 0 2px",
};

const SrpSopImageName = {
	fontSize: "9px",
	lineHeight: "1.3",
};
const SrpSopContentSec = {
	display: "flex",
	minWidth: "36px",
	flexDirection: "column",
	alignItems: "center",
	padding: "0",
};

const SrpSopContenttitle = {
	fontSize: "9px",
	lineHeight: "1.3",
};
const toolTipButton = {
	padding: "0",
};
const cursorMove = {
	cursor: "move",
};
const mainCompetitorSec = {
	display: "flex",
	justifyContent: "center",
	alignItems: "stretch",
	margin: "5px auto",
	maxWidth: "100%",
};
const mainComLeftSec = {
	flex: "inherit",
	display: "flex",
	width: "100%",
	justifyContent: "spaceBetween",
	alignItems: "center",
};
const mainComRightSec = {
	flex: "inherit",
	display: "flex",
	width: "100%",
	justifyContent: "spaceBetween",
	alignItems: "center",
	flexWrap: "wrap",
	height: "100%",
};
const mainComLeftSecInn = {
	display: "flex",
	width: "100%",
	flexDirection: "row",
};
const mainComSomBlk = {
	height: "auto",
};
const mainComPriceProduct = {
	fontSize: "12px",
	clear: "both",
	lineHeight: "1em",
	margin: "0 -1px",
	textAlign: "center",
	display: "flex",
	justifyContent: "flexEnd",
	flexDirection: "row",
	flexWrap: "wrap",
	flex: "1",
	width: "100%",
	paddingLeft: "9px",
	paddingRight: "10px",
	paddingBottom: "0",
};
const mainComPriceBlk = {
	borderRadius: "10px",
	padding: "0",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: "20px",
	fontWeight: "bold",
	flexDirection: "column",
	marginRight: "6px",

	MaxWidth: "90px",
};
const mainComPriceBlkInner = {
	height: "100%",
	width: "100%",
	padding: "0px 10px",
};
const blueBg = {
	background: "#1e4f81",
	color: "#fff",
	minHeight: "100px",
};
const pinkBg = {
	background: "#996699",
	border: "2px solid #996699",
	borderRadius: "10px",
	padding: "6px 10px",
	color: "#fff",
	minHeight: "100px",
};
const mainComPriceProductRight = {
	display: "flex",
	paddingRight: "10px",
	flex: "1",
	width: "100%",
	paddingLeft: "10px",
	paddingBottom: "0",
	padding: "0",
	lineHeight: "1em",
	margin: "0 -1px",
	textAlign: "center",
	height: "100%",
};
const mainComLogo = {
	maxHeight: "60px",
	width: "auto",
	height: "auto",
	maxWidth: "90px",
};
const mainComPriceInner = {
	position: "relative",
	minWidth: "90px",
	height: "100%",
};
const editIconPos = {
	position: "absolute",
	top: "8px",
	right: "8px",
	justifyContent: "flexEnd",
};
const greenSmallText = {
	color: "#2dbe04",
	fontSize: "12px",
};
const redSmallText = {
	color: "#fb1111",
	fontSize: "12px",
};
const graySmallText = {
	color: "#808182",
	fontSize: "12px",
};
const labeSmallText = {
	fontSize: "14px",
	fontWeight: "normal",
};
const inputMaxWidth = {
	maxWidth: "70px",
	margin: "0 auto",
	resize: "none",
};

export {
	SrpSopPrice,
	SomBlk,
	ListVendors,
	ProductList,
	ListLogo,
	ListContentBlk,
	ListContentTitle,
	ModalBrandSec,
	ModalBrandList,
	SrpSopBlk,
	SrpSopBlueSec,
	SrpSopBlueSecLeft,
	SrpSopPinkSecRight,
	SrpBlueList,
	SopPinkList,
	SrpSopImageSec,
	SrpSopImageName,
	SrpSopContentSec,
	SrpSopContenttitle,
	toolTipButton,
	mainCompetitorSec,
	mainComLeftSec,
	mainComLeftSecInn,
	mainComSomBlk,
	mainComPriceProduct,
	mainComPriceBlk,
	mainComPriceBlkInner,
	pinkBg,
	mainComPriceProductRight,
	blueBg,
	mainComRightSec,
	mainComLogo,
	mainComPriceInner,
	editIconPos,
	greenSmallText,
	redSmallText,
	graySmallText,
	inputMaxWidth,
	labeSmallText,
	cursorMove,
};
