import { PRICE_SETTING_TOOL_DATA } from "./constants";

let intiState = { priceSettingToolDataLoader: false };

const PriceSettingTool = (state = intiState, action) => {
	if (action.type === PRICE_SETTING_TOOL_DATA) return { ...state, priceSettingToolDataLoader: action.payload };
	else return state;
};

export default PriceSettingTool;
