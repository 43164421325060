import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const Value = styled(Typography)(() => ({
	textAlign: "left",
	fontSize: "24px",
	letterSpacing: "0px",
	color: "#555A60",
	fontWeight: 600,
}));

const Title = styled(Typography)(() => ({
	textAlign: "left",
	marginTop: "5px",
	color: "#005EA6",
	fontSize: "20px",
}));

const Profit = styled(Typography)(() => ({
	color: "green",
	fontSize: "16px",
	fontWeight: 600,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const Loss = styled(Typography)(() => ({
	color: "red",
	fontSize: "16px",
	fontWeight: 600,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

export { Value, Profit, Loss, Title };
