import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, List, Popover, ListItem, ListItemButton, styled, Typography } from "@mui/material";
import { Title, Icon } from "../../styles/Common.Styled";
import { save, share, favorite, favorite_selected } from "../../assets/images/dashboard";
import SaveDashboardPopup from "./SaveDashboardPopup";
import {
	favoriteDashboard,
	getAllDashboards,
	getDashboardData,
	getFavoriteDashboards,
	onShareDashboard,
	removeFavoriteDashboard,
	saveDashboardData,
} from "../../util/services";
import _ from "lodash";
import ShareConfirmationPopup from "./components/ShareConfirmationPopup";
import { toast } from "../../store/actions/toast.action";
import { removeMMPWFromLocal, storeMMPWData, storeMMPWFooterData } from "../../util/helper";
import { saveTrendingPageData } from "../../store/actions/YourDashboard/trendingPage.action";
import { pageList } from "../../mocks/common";
import { loader } from "../../store/actions/common.action";
import { NoPermission } from "../../assets/images";
import { getDashboardsList } from "../../store/actions/YourDashboard/dashboard.action";
import { IDashboardConfiguration } from "../../types/common";
import { fetchFavoriteDashboardList } from "../../store/actions/YourDashboard/favorite.action";

const MessageTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.mode === "light" ? "#000" : "#fff",
}));

const DashboardActions: React.FC<{
	title: string;
	data?;
	disabled?: boolean;
	dashboardKey?: string;
	callback?;
	disableDownloadButton?;
}> = ({ title, children, data, disabled, dashboardKey, callback, disableDownloadButton = true }) => {
	const favDashboard = useSelector((state: any) => state.favoriteDashList.data);
	const DashboardConfiguration = useSelector((state: any) => state.DashboardConfiguration.data);
	const [saveShareRecordId, setSaveShareRecordId] = useState(null);
	const [dashboardId, setDashboardId] = useState(null);
	const [dashboardName, setDashboardName] = useState("");
	const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
	const [openShareConfirm, setOpenShareConfirm] = useState<boolean>(false);
	const [openSaveOptions, setOpenSaveOptions] = useState(null);
	const [dashboardData, setDashboardData] = useState({});
	const [isSharedDashboard, setIsSharedDashboard] = useState<string | null>(null);
	const [isFavoriteDashboard, setIsFavoriteDashboard] = useState<boolean>(false);
	const search = window.location.search;
	const [urlParams] = useState(new URLSearchParams(search));
	const [isFetchDashboardStarted, setIsFetchDashboardStarted] = useState(false);
	const [hasNoCountryCategoryPermission, setHasNoCountryCategoryPermission] = useState({ countries: [], categories: [] });
	const userDetail = useSelector((state: any) => state.User.data);
	const dispatch = useDispatch();
	const saveOption = Boolean(openSaveOptions);
	const dashboard = pageList.find((p) => p.id === dashboardKey);
	useEffect(() => {
		removeMMPWFromLocal("All");
		const shared = urlParams.get("shared");
		setIsSharedDashboard(shared);
		const recordId = parseInt(urlParams.get("id"));
		setSaveShareRecordId(recordId);
		if (dashboard && userDetail && userDetail.id) {
			dispatch(fetchFavoriteDashboardList({ userId: userDetail.id }));
			dispatch(saveTrendingPageData({ pageName: dashboard.label, type: "dashboard", userId: userDetail.id }));
		}
	}, [userDetail]);

	useEffect(() => {
		if (favDashboard && dashboard) {
			const isFavoriteFlag = favDashboard.some((dashboardDetail) => dashboardDetail.name === dashboard.label);
			setIsFavoriteDashboard(isFavoriteFlag);
		}
	}, [favDashboard]);

	useEffect(() => {
		if (saveShareRecordId && userDetail && userDetail.id) {
			retriveData(saveShareRecordId);
		}
	}, [saveShareRecordId, userDetail]);

	const retriveData = async (id) => {
		if (id) {
			dispatch(loader(true));
			try {
				const type = isSharedDashboard ? "share" : "save";
				const response = await getDashboardData(id, type, userDetail.id);
				//check if user has permission for the country and category
				let filters = JSON.parse(response?.data?.filters);
				if (!isSharedDashboard || (isSharedDashboard && checkPermission(filters.global))) {
					if (response?.data && response?.data.mmpw) {
						let mmpwData = JSON.parse(response?.data.mmpw);
						let footerNoteData = {};
						Object.keys(mmpwData).map((key) => {
							footerNoteData[key] = mmpwData[key].footerNote;
							delete mmpwData[key].footerNote;
						});
						storeMMPWData(mmpwData);
						storeMMPWFooterData(footerNoteData);
					}
					setDashboardName(response.data.name);
					setDashboardData({
						...response.data,
						country: userDetail?.defaultCountry,
						category: userDetail?.defaultCategory,
					});
					callback(response.data);
				}
				dispatch(loader(false));
			} catch (e) {
				dispatch(loader(false));
			}
		}
	};

	const checkPermission = (filters) => {
		const permissionCountries = userDetail.permissionCountry.split(",");
		const permissionCategories = userDetail.permissionCategory.split(",");
		let countriesNotHavePermission = [],
			categoriesNotHavePermission = [];
		if (_.isArray(filters.country)) {
			countriesNotHavePermission = filters.country.filter((c) => permissionCountries.findIndex((t) => t === c) === -1);
		} else if (filters.country && permissionCountries.findIndex((p) => p === filters.country) === -1) {
			countriesNotHavePermission = [filters.country];
		}
		if (_.isArray(filters.category)) {
			categoriesNotHavePermission = filters.category.filter((c) => permissionCategories.findIndex((t) => t === c) === -1);
		} else if (filters.category && permissionCountries.findIndex((p) => p === filters.category) === -1) {
			categoriesNotHavePermission = [filters.category];
		}
		setHasNoCountryCategoryPermission({ countries: countriesNotHavePermission, categories: categoriesNotHavePermission });
		return countriesNotHavePermission.length === 0 && categoriesNotHavePermission.length === 0;
	};

	const saveHandler = () => {
		if (dashboardName && !isSharedDashboard) {
			saveDashboard(dashboardName);
		} else {
			onSaveOptionClose();
			setOpenSavePopup(true);
		}
	};

	const shareHandler = () => {
		setOpenShareConfirm(true);
	};

	const onSaveOption = (e) => {
		setOpenSaveOptions(e.currentTarget);
	};

	const onSaveOptionClose = () => {
		setOpenSaveOptions(null);
	};

	useEffect(() => {
		if (dashboardKey && DashboardConfiguration.length > 0) {
			getDashboardId();
		}
	}, [dashboardKey, DashboardConfiguration]);

	useEffect(() => {
		if (DashboardConfiguration.length === 0 && !isFetchDashboardStarted) {
			setIsFetchDashboardStarted(true);
			fetchAllDashboardData();
		}
	}, [isFetchDashboardStarted]);

	const fetchAllDashboardData = async () => {
		try {
			const response = await getAllDashboards();
			const allDashboardData: IDashboardConfiguration[] = response?.data ?? [];
			dispatch(getDashboardsList(allDashboardData));
		} catch (e) {}
	};

	const getDashboardId = () => {
		try {
			let allDashboardData: IDashboardConfiguration[] = DashboardConfiguration;
			const dashboardDetail = allDashboardData.find((x: IDashboardConfiguration) => dashboardKey && x.route.includes(dashboardKey));
			setDashboardId(dashboardDetail?.id);
		} catch (e) {}
	};

	const getSelectedCountryAndCategory = (filters) => {
		return {
			country: _.isArray(filters?.global?.country) && filters?.global?.country.length > 0 ? filters?.global?.country[0] : filters?.global?.country,
			category: filters?.global.category
				? _.isArray(filters?.global.category)
					? filters?.global.category.join(",")
					: filters?.global.category
				: filters?.global.anchorCategory
				? _.isArray(filters?.global.anchorCategory)
					? filters?.global.anchorCategory.join(",")
					: filters?.global.anchorCategory
				: filters?.global.pepsi && filters?.global.pepsi.category
				? _.isArray(filters?.global.pepsi.category)
					? filters?.global.pepsi.category.join(",")
					: filters?.global.pepsi.category
				: "",
		};
	};

	const handleFavoriteAction = () => {
		dispatch(loader(true));
		if (isFavoriteDashboard) {
			removeFavoriteDashboard(userDetail.id, dashboard?.label ?? "")
				.then((res) => {
					dispatch(toast("Removed Favorited Dashboard!", true, 2000, "warning"));
					dispatch(loader(false));
					dispatch(fetchFavoriteDashboardList({ userId: userDetail.id }));
				})
				.catch(() => {
					dispatch(loader(false));
				});
		} else {
			favoriteDashboard(userDetail.id, dashboard?.label ?? "")
				.then((res) => {
					if (res.status === 200) {
						dispatch(toast("Dashboard Favorited!", true, 2000, "success"));
						dispatch(fetchFavoriteDashboardList({ userId: userDetail.id }));
					} else {
						dispatch(toast("Something went wrong!", true, 2000, "error"));
					}
					dispatch(loader(false));
				})
				.catch(() => {
					dispatch(loader(false));
				});
		}
	};

	const saveDashboard = async (name, sharedDashobardData?) => {
		dispatch(loader(true));
		const trimmedName = name.trim();
		let mmpwData = {};
		if (data.mmpwKey) {
			let dashbaordData = JSON.parse(localStorage.getItem("mmpw") || "{}");
			if (dashbaordData && Object.keys(dashbaordData).length > 0) {
				Object.keys(dashbaordData).map((key) => {
					if (key.includes(data.mmpwKey)) {
						mmpwData = {
							...mmpwData,
							[key]: { ...dashbaordData[key] },
						};
					}
				});
			}
			let footerNote = JSON.parse(localStorage.getItem("mmpw-footer") || "{}");
			if (footerNote && Object.keys(footerNote).length > 0) {
				Object.keys(footerNote).map((key) => {
					if (key.includes(data.mmpwKey)) {
						if (mmpwData[key]) {
							mmpwData[key]["footerNote"] = footerNote[key];
						} else {
							mmpwData = {
								...mmpwData,
								[key]: { ...footerNote[key], footerNote: footerNote[key] },
							};
						}
					}
				});
			}
		}
		const formatedData = {
			...getSelectedCountryAndCategory(data.filters),
			filters: JSON.stringify({ ...data.filters, periodicity: localStorage.getItem("periodicity") }),
			name: trimmedName,
			key: dashboardKey,
			version: "1.0",
			mmpw: JSON.stringify(mmpwData),
			dashboardId,
			...(sharedDashobardData && sharedDashobardData.length > 0 ? { emailAddresses: sharedDashobardData } : {}),
			isShared: sharedDashobardData?.length > 0,
			id: saveShareRecordId ?? 0,
			other: "",
		};
		if (trimmedName) {
			if (sharedDashobardData && sharedDashobardData.length > 0) {
				try {
					const response = await onShareDashboard(userDetail.id, { ...formatedData, id: 0 });
					if (response && response.data) {
						dispatch(toast("Dashboard Shared Successfully!", true, 2000, "success"));
						setOpenShareConfirm(false);
						dispatch(loader(false));
					} else {
						dispatch(toast(response.response.data.userMessage, true, 2000, "error"));
						dispatch(loader(false));
					}
				} catch (e) {
					dispatch(toast("Something went wrong", true, 2000, "error"));
					dispatch(loader(false));
				}
			} else {
				try {
					const response = await saveDashboardData(userDetail.id, { ...formatedData, id: isSharedDashboard ? 0 : formatedData.id });
					if (response && response.data) {
						dispatch(toast("Dashboard Saved Successfully!", true, 2000, "success"));
						setOpenSavePopup(false);
						dispatch(loader(false));
					} else {
						dispatch(toast(response.response.data.userMessage, true, 2000, "error"));
						dispatch(loader(false));
					}
				} catch (e) {
					dispatch(toast("Something went wrong", true, 2000, "error"));
					dispatch(loader(false));
				}
			}
		}
	};

	const isLoader = useSelector((state: any) => state.common.loader);
	return (
		<>
			{isLoader || (hasNoCountryCategoryPermission.countries.length === 0 && hasNoCountryCategoryPermission.categories.length === 0) ? (
				<Fragment>
					<Grid container>
						<Grid item xs={12} sm={6}>
							<Grid container>
								<Title className="m-b-20" style={{ fontSize: 24, fontWeight: 600 }}>
									{title}
								</Title>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6} className="m-b-10">
							<Grid container display="flex" justifyContent="flex-end">
								<Icon src={String(isFavoriteDashboard ? favorite_selected : favorite)} onClick={() => handleFavoriteAction()} />
								{!disabled && <Icon src={String(save)} onClick={(e) => onSaveOption(e)} />}
								{!disabled && <Icon src={String(share)} onClick={() => shareHandler()} />}
								<Popover
									open={saveOption}
									anchorEl={openSaveOptions}
									onClose={onSaveOptionClose}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
								>
									<List>
										<ListItem disablePadding>
											<ListItemButton onClick={saveHandler}>
												{saveShareRecordId && !isSharedDashboard ? "Update to RevolutioM" : "Save to RevolutioM"}
											</ListItemButton>
										</ListItem>
									</List>
								</Popover>
							</Grid>
						</Grid>
					</Grid>
					{children}
					{openSavePopup && <SaveDashboardPopup onCloseHandler={() => setOpenSavePopup(false)} onSaveHandler={saveDashboard} value={dashboardName} />}
					{openShareConfirm && (
						<ShareConfirmationPopup
							onCloseHandler={() => setOpenShareConfirm(false)}
							shareData={isSharedDashboard ? dashboardData : { ...dashboardData, id: null }}
							userDetail={userDetail}
							onShare={saveDashboard}
						/>
					)}
				</Fragment>
			) : (
				<Grid>
					<Grid display="flex" justifyContent="center" alignItems="center" className="m-b-20">
						<NoPermission width="120" fill={"#0093CD"} />
					</Grid>
					<Grid display="flex" justifyContent="center" alignItems="center" className="m-b-20">
						{hasNoCountryCategoryPermission.categories.length > 0 ? (
							<MessageTitle>
								Please request access to categories : {hasNoCountryCategoryPermission.categories.join(",")} to view the dashboard{" "}
								{urlParams.get("shared-by") ? `shared by ${urlParams.get("shared-by")}` : null}
							</MessageTitle>
						) : null}
					</Grid>
					<Grid display="flex" justifyContent="center" alignItems="center">
						{hasNoCountryCategoryPermission.countries.length > 0 ? (
							<MessageTitle>
								Please request access to country : {hasNoCountryCategoryPermission.countries.join(",")} to view the dashboard{" "}
								{urlParams.get("shared-by") ? `shared by ${urlParams.get("shared-by")}` : null}
							</MessageTitle>
						) : null}
					</Grid>
				</Grid>
			)}
		</>
	);
};
export default DashboardActions;
