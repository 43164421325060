/* Creates a GraphQL query to get the list of trending dashboards.
 * @param {string} type - The type of dashboard. Currently only has value of "dashboard".
 * @param {string} userId - The user id.
 * @returns The GraphQL query.
 */
export const GET_TRENDING_DASHBOARD_QUERY = (type: string, userId: string) => {
	return {
		query: `{gettrendingdashboard(Type:"${type}", UserId: "${userId}"){
      countries: Countries
      date: Date
      id: Id
      isFavorite: IsFavorite
      name: Name
      route: Route
      thumbnailUrl: ThumbnailUrl
      type: Type
      viewCount: ViewCount
    }}`,
	};
};

/* Creates a GraphQL query to get the list of favorite dashboards for the user.
 * @param {string} userId - The user id.
 * @returns The GraphQL query.
 */
export const GET_FAVORITE_DASHBOARD_QUERY = (userId: string) => {
	return {
		query: `{favoriteDashboard(UserId:"${userId}"){
      id
      isFavorite
      name
      route
      thumbnailUrl
      type
      viewCount
    }}`,
	};
};

/* Creates a GraphQL query to add or delete entry in favorite dashboards list.
 * @param {string} userId - The user id.
 * @param {string} dashboardName - The dashboard name.
 * @param {string} type - The action type. It can be "add" or "delete".
 * @returns The GraphQL query.
 */
export const ADD_DELETE_FAVORITE_DASHBOARD_QUERY = (userId: string, dashboardName: string, type: string) => {
	return {
		query: `mutation {
      favorite(
        dashboardName: "${dashboardName}"
        userId: "${userId}"
        type: "${type}"
      ) {
        Id
        DashboardId
        UserId
        CreatedOn
      }
    }
    `,
	};
};

/* Creates a GraphQL query to get list of saved dashboards.
 * @param {number} userId - The user id.
 * @returns The GraphQL query.
 */
export const GET_SAVED_DASHBOARD_QUERY = (userId: string) => {
	return {
		query: `{getsaves(UserId: "${userId}"){
    dashboardId: DashboardId
    typeName: typeName
    version: Version
    isFavorite: IsFavorite
    id: Id
    viewCount: ViewCount
    thumbnailUrl: ThumbnailUrl
    name: Name
    route: Route
    countries: Countries
    type: Type
    createdOn: CreatedOn
    }}`,
	};
};

/* Creates a GraphQL query to get a saved dashboard properties.
 * @param {number} userId - The user id.
 * @param {number} id - The dashboard id.
 * @param {string} type - The type of dashboard.
 * @returns The GraphQL query.
 */
export const GET_SAVED_DASHBOARD_BY_ID_QUERY = (userId: number, id: number, type: string) => {
	return {
		query: `{getsavesbyid(id :${id}, UserId : ${userId}, type  : "${type}"){
      id: Id
      dashboardId: DashboardId
      name: Name
      version: Version
      mmpw: Mmpw
      filters: Filters
      other: Other
      isShare: IsShare
      country: Country
      category: category
    }}`,
	};
};

/* Creates a GraphQL query to a saved dashboard.
 * @param {number} userId - The user id.
 * @param {number} id - The dashboard id.
 * @returns The GraphQL query.
 */
export const DELETE_SAVED_DASHBOARD = (id: number, userId: number) => {
	return {
		query: `mutation { deletesaveddashboards ( id: ${id}, UserId: "${userId}" ) }`,
	};
};

/* Creates a GraphQL query to get list of dashboards shared with user.
 * @param {string} userId - The user id.
 * @returns The GraphQL query.
 */
export const GET_SHARED_DASHBOARD_QUERY = (userId: string) => {
	return {
		query: `{getSharedDashboards(UserId: "${userId}") {
        id
        name
        route
        viewCount
        thumbnailUrl
        type
        dashboardId
        version
        shareByUser
        shareByUserId
        mmpw
        filters
        createdOn
        isFavorite
      }}
    `,
	};
};

/* Creates a GraphQL query to get list of dashboards shared by user.
 * @param {string} userId - The user id.
 * @returns The GraphQL query.
 */
export const GET_SHARED_BY_ME_DASHBOARD_QUERY = (userId: string) => {
	return {
		query: `{ dashboardSharesByMe(UserId: ${userId}) {
        sharedToUser {
          shareToUserId
          shareToUser
        }
        mmpw
        filters
        dashboardId
        typeName
        version
        isFavorite
        id
        viewCount
        thumbnailUrl
        name
        route
        countries
        type
        createdOn
      }
    }
    `,
	};
};

/* Creates a GraphQL query to delete a dashboard shared by or with a user.
 * @param {string} type - The type of share.
 * @param {number} dashboardId - The dashboard id.
 * @param {string} userId - The user id.
 * @returns The GraphQL query.
 */
export const DELETE_SHARED_DASHBOARD_QUERY = (type: string, dashboardId: number, userId: string) => {
	return {
		query: `mutation { deleteShares ( type: "${type}", id: "${dashboardId}", userId: "${userId}" ) }`,
	};
};

/* Creates a GraphQL query to get the market check KPIs.
 * @param {string[]} countries - The countries to be included in the query.
 * @param {string[]} categories - The categories to be included in the query.
 * @param {string} period - The period to be included in the query.
 * @param {boolean} autoCall - AutoCall will be true if API is called on page load and false if API is called on change of country or category dropdown.
 * @returns The GraphQL query.
 */
export const MARKET_CHECK_QUERY = (countries: string[], categories: string[], period: string, autoCall: boolean) => {
	return {
		query: `{
      marketCheck(
        countries: ${JSON.stringify(countries)}
        categories: ${JSON.stringify(categories)}
        period: "${period}"
        autoCall: ${autoCall}
      ) {
        nrr: Nrr {
          value: Value
          change: Change
        }
        sales: Sales {
          value: Value
          change: Change
        }
        units: Units {
          value: Value
          change: Change
        }
        volume: Volume {
          value: Value
          change: Change
        }
        shareSales: ShareSales {
          value: Value
          change: Change
        }
        shareUnits: ShareUnits {
          value: Value
          change: Change
        }
        shareVolume: ShareVolume {
          value: Value
          change: Change
        }
      }
    }`,
	};
};
