import { styled, Table, TableCell, tableCellClasses } from "@mui/material";
const StyledTable = styled(Table)(() => ({
	border: "1px solid #D3D3D3",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#D3D3D3",
		padding: 10,
	},
	color: theme.palette.common.black,
	border: "1px solid #D3D3D3",
	alignContent: "center",
	padding: "0px 10px",
}));
export { StyledTable, StyledTableCell };
