import { GET_ANCHOR_PRODUCT_FILTERS, GET_ANCHOR_PRODUCT_FILTERS_SUCESS, GET_ANCHOR_PRODUCT_FILTERS_FAILURE, CLEAR_ANCHOR_PRODUCT_FILTERS } from "./constants";
import { fetchAnchorFiltersData } from "../../util/services";

const getAnchorProductFilters = () => {
	return {
		type: GET_ANCHOR_PRODUCT_FILTERS,
	};
};

const getAnchorProductFiltersSucess = (payload: any, filterData) => {
	return {
		type: GET_ANCHOR_PRODUCT_FILTERS_SUCESS,
		payload,
		filterData,
	};
};

const getAnchorProductFiltersFailure = (error) => {
	return {
		type: GET_ANCHOR_PRODUCT_FILTERS_FAILURE,
		payload: error,
	};
};

export function fetchAnchorProductFiltersData(filters, filterData, userId) {
	return (dispatch: any) => {
		dispatch(getAnchorProductFilters());
		fetchAnchorFiltersData(userId, filters)
			.then((response) => {
				dispatch(getAnchorProductFiltersSucess(response, filterData));
			})
			.catch((error) => {
				dispatch(getAnchorProductFiltersFailure(error));
			});
	};
}

export const clearAnchorFilters = () => {
	return {
		type: CLEAR_ANCHOR_PRODUCT_FILTERS,
	};
};
