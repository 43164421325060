import React, { FC } from "react";
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { formatNumber } from "../../../../util/helper";
import { IQuadIntensityChildData } from "../../../../types/revenueMap";
import { IKeyValueConfiguration } from "../../../../types/common";

const useStyles = makeStyles(() => ({
	tableContainer: {
		boxShadow: "none",
	},
	table: {
		border: 0,
	},
	tableCell: {
		border: 0,
		fontSize: "inherit",
		padding: "5px",
	},
	cellLeftAlign: {
		textAlign: "left",
	},
	cellCenterAlign: {
		textAlign: "center",
	},
}));

const DrillDownView: FC<{
	childData: IQuadIntensityChildData[];
	childColumn: IKeyValueConfiguration;
}> = ({ childData, childColumn }) => {
	const classes = useStyles();
	return (
		<TableContainer className={classes.tableContainer} component={Paper} sx={{ maxHeight: "250px" }}>
			<Table className={classes.table} stickyHeader>
				<TableHead>
					<TableCell className={`${classes.tableCell} ${classes.cellLeftAlign}`}>{childColumn.label}</TableCell>
					<TableCell className={`${classes.tableCell} ${classes.cellCenterAlign}`}>Var X</TableCell>
					<TableCell className={`${classes.tableCell} ${classes.cellCenterAlign}`}>Var Y</TableCell>
					<TableCell className={`${classes.tableCell} ${classes.cellCenterAlign}`}>Var Z</TableCell>
				</TableHead>
				{childData
					.sort((a, b) => {
						if (a.somValue < b.somValue) return 1;
						else return -1;
					})
					.map((data) => {
						return (
							<TableRow>
								<TableCell className={`${classes.tableCell} ${classes.cellLeftAlign}`}>{data.name}</TableCell>
								<TableCell className={`${classes.tableCell} ${classes.cellCenterAlign}`}>{formatNumber(data.xValue)}</TableCell>
								<TableCell className={`${classes.tableCell} ${classes.cellCenterAlign}`}>{formatNumber(data.yValue)}</TableCell>
								<TableCell className={`${classes.tableCell} ${classes.cellCenterAlign}`}>{formatNumber(data.somValue)}</TableCell>
							</TableRow>
						);
					})}
			</Table>
		</TableContainer>
	);
};
export default DrillDownView;
