import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveTrendingPageData } from "../../store/actions/YourDashboard/trendingPage.action";

const PageContainer: React.FC<{
	page;
}> = ({ page, children }) => {
	const userDetail = useSelector((state: any) => state.User.data);
	const dispatch = useDispatch();
	useEffect(() => {
		if (page && page.title && userDetail && userDetail.id) {
			dispatch(saveTrendingPageData({ pageName: page.title, type: "portal", userId: userDetail.id }));
		}
	}, [userDetail]);

	return <Fragment>{children}</Fragment>;
};
export default PageContainer;
