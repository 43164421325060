import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../store/actions/common.action";
import { fetchNotifications, getNotificationSuccess } from "../../store/actions/AlertAndNotification/getNotification.action";
import NotificationCard from "../NotificationCard";
import { dissmissNotification } from "../../store/actions/AlertAndNotification/dismissNotification.action";

const GlobalNotifications: React.FC<{ pageName: string }> = ({ pageName }) => {
	const dispatch = useDispatch();

	const userDetail = useSelector((state: any) => state.User.data);

	const [notification, setNotification] = useState<any>({});

	const notificationData = useSelector((state: any) => state.GetAllNotification);

	const dismissNotification = useSelector((state: any) => state.DismissNotification);

	useEffect(() => {
		dispatch(loader(true));
		if (userDetail) dispatch(fetchNotifications(userDetail.id, pageName));
	}, [userDetail]);

	useEffect(() => {
		if (dismissNotification && dismissNotification.data && notification) {
			if (notificationData.data && notificationData.data.length) {
				const notifications = [...notificationData.data];
				dispatch(getNotificationSuccess(notifications.filter((x) => x.id !== notification.id)));
			}
		}
	}, [dismissNotification]);

	useEffect(() => {
		if (notificationData && (notificationData.data || notificationData.error)) {
			dispatch(loader(false));
		}
	}, [notificationData]);

	const onNotificationAction = (payload, notification) => {
		if (payload.type === "dismiss") {
			setNotification(notification);
			dispatch(dissmissNotification(userDetail.id, notification.id));
		}
	};

	return (
		<>
			{notificationData.data &&
				notificationData.data.map((item, i) => {
					return (
						<Grid item xs={12} sm={6} xl={4} key={`notification-${i}`}>
							<NotificationCard data={item} callback={(e) => onNotificationAction(e, item)} />
						</Grid>
					);
				})}
		</>
	);
};
export default GlobalNotifications;
