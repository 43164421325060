import { IIconPickerType, IShapePickerType } from "../../types/common";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import { Line } from "../../assets/icons/mmpw";

import {
	Add,
	AlertError,
	Bookmark,
	BookmarkBorder,
	Pause,
	Cached,
	CheckCircleOutline,
	CheckCircle,
	Check,
	Create,
	ErrorOutline,
	Favorite,
	FilterList,
	Link,
	CursorFinger,
	MapPlace,
	Notification,
	Search,
	Settings,
	Visibility,
} from "../../assets/icons/mmpw/icons-v2";

const shapes: IShapePickerType[] = [
	{ id: "mmpw-square-shape", type: "Shape", Icon: CropSquareOutlinedIcon },
	{ id: "mmpw-circle-shape", type: "Shape", Icon: CircleOutlinedIcon },
	{ id: "mmpw-triangle-shape", type: "Shape", Icon: ChangeHistoryOutlinedIcon },
	{ id: "mmpw-line-shape", type: "Shape", Icon: Line, height: 70, width: 55, fill: "#858C94" },
];

const icons: IIconPickerType[] = [
	{ id: "mmpw-add-icon", type: "Icon", Icon: Add },
	{ id: "mmpw-alert-error-icon", type: "Icon", Icon: AlertError },
	{ id: "mmpw-bookmark-icon", type: "Icon", Icon: Bookmark },
	{ id: "mmpw-bookmark-border-icon", type: "Icon", Icon: BookmarkBorder },
	{ id: "mmpw-build-icon", type: "Icon", Icon: Pause },
	{ id: "mmpw-cached-icon", type: "Icon", Icon: Cached },
	{ id: "mmpw-check-circle-outline-icon", type: "Icon", Icon: CheckCircleOutline },
	{ id: "mmpw-check-circle-icon", type: "Icon", Icon: CheckCircle },
	{ id: "mmpw-check-icon", type: "Icon", Icon: Check },
	{ id: "mmpw-create-icon", type: "Icon", Icon: Create },
	{ id: "mmpw-error-outline-icon", type: "Icon", Icon: ErrorOutline },
	{ id: "mmpw-favorite-icon", type: "Icon", Icon: Favorite },
	{ id: "mmpw-filter-list-icon", type: "Icon", Icon: FilterList },
	{ id: "mmpw-link-icon", type: "Icon", Icon: Link },
	{ id: "mmpw-map-360-icon", type: "Icon", Icon: CursorFinger },
	{ id: "mmpw-map-place-icon", type: "Icon", Icon: MapPlace },
	{ id: "mmpw-notification-icon1", type: "Icon", Icon: Notification },
	{ id: "mmpw-search-icon2", type: "Icon", Icon: Search },
	{ id: "mmpw-settings-icon3", type: "Icon", Icon: Settings },
	{ id: "mmpw-visibility-icon4", type: "Icon", Icon: Visibility },
];

const pinConfig = "number"; //'number' || 'color'

export { shapes, icons, pinConfig };
