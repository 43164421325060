import { graphqlAPI } from "../../store/api-constants";
import { API } from "../../util/helper";
import { IInaseFilters, IUnitVariationExpectedPayload, SimulationDetailsPayload, IGetWeeksPayload } from "../../types/common";
import {
	ADJUSTABLE_FILTERS,
	GET_INASE_MAX_DATE_QUERY,
	GET_INASE_QUERY,
	GET_REAL_VS_EXPECTED_DATA,
	UNIT_VARIATIONS_EXPECTED_QUERY,
	GET_WEEKS_DATA_QUERY,
	GET_WEEKLY_MONTHLY_ACCUMULATED_DATA_QUERY,
	SIMULATION_DETAILS_QUERY,
} from "../../util/queries/elasticityPricingTrack";

export const getMeasureFilterData = async (payload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = ADJUSTABLE_FILTERS(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.adjustablefilters;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};

/* This Function makes an API call to fetch the data for Inase Filters
 * @param payload - Holds information about applied filters.
 */
export const getAdditionalFilterData = async (payload: IInaseFilters) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_INASE_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.inaseFilters;
				Object.keys(data).forEach((key) => {
					if (Array.isArray(data[key]) && data[key].length === 0) {
						data[key] = null;
					}
				});
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};

/* This Function makes an API call to fetch the data for Unit Variation expected dashboard
 * IUnitVariationExpectedPayload contains following payload
 * @param {string} country - Value of countryKey.
 * @param {string[]} geoLevel - Value of geoLevelKey.
 * @param {string[]} channel - Value of channel.
 * @param {string[]} dataSource - Value of dataSourceKey.
 * @param {string[]} regions - Value of regionsKey.
 * @param {string[]} category - Value of categoryKey.
 * @param {string[]} segment - Value of segmentKey.
 * @param {string[]} brand - Value of brandKey.
 * @param {string[]} subBrand - Value of countryKey.
 * @param {string} packSize - Value of packSizeKey.
 * @param {string} permutation - Value of permutationKey.
 * @param {IUnitVariationExpectedMeasurePayload[]} measureFilters - Value of measureFilters.
 * @param {string} date - Value of dateKey.
 */
export const getUnitVariationExpectedData = async (payload: IUnitVariationExpectedPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = UNIT_VARIATIONS_EXPECTED_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter);
	} catch (e) {
		return e;
	}
};

/* This Function makes an API call to fetch the accumulated datafor elasticity pricing track
 * @param payload - Holds information about applied filters.
 */
export const getResultPerWeek = async (payload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_WEEKLY_MONTHLY_ACCUMULATED_DATA_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.weeklyOrMonthlyAccumulated;
			}
			return { data: data, status: response.status };
		});
	} catch (err) {
		return err;
	}
};

/* This Function makes an API call to fetch the data for weeks for elasticity pricing track records
 * @param {string} country - Value of countryKey.
 * @param {string[]} geoLevel - Value of geoLevelKey.
 * @param {string[]} channel - Value of channel.
 * @param {string[]} dataSource - Value of dataSourceKey.
 * @param {string[]} regions - Value of regionsKey.
 * @param {string[]} category - Value of categoryKey.
 * @param {string[]} segment - Value of segmentKey.
 * @param {string[]} brand - Value of brandKey.
 * @param {string[]} subBrand - Value of countryKey.
 * @param {string} packSize - Value of packSizeKey.
 * @param {string} permutation - Value of permutationKey.
 * @param {IUnitVariationExpectedMeasurePayload[]} measureFilters - Value of measureFilters.
 * @param {string} date - Value of dateKey.
 */
export const getWeeksData = async (payload: IGetWeeksPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_WEEKS_DATA_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getWeeks;
			}
			return { data: data };
		});
	} catch (err) {
		return err;
	}
};

/* This Function makes an API call to fetch the data for Real Vs Expected dashboard
 * @param payload - Holds information about applied filters.
 */
export const getRealVsExpectedData = async (payload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_REAL_VS_EXPECTED_DATA(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.realVsExpected;
			}
			return { status: response.status, data: data };
		});
	} catch (e) {
		return e;
	}
};

/* Calls a GraphQL API to get api simulation KPI and chart data for API Predictive dashboard.
 * @param {SimulationDetailsPayload} payload - The object contains filter data for API Predictive dashboard.
 * @returns api simulation KPI and chart data for API Predictive dashboard.
 */
export const getSimulationDetailsData = async (payload: SimulationDetailsPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = SIMULATION_DETAILS_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.simulationDetails;
			}
			return { status: response.status, data: data };
		});
	} catch (err) {
		return err;
	}
};

/* This Function makes an API call to fetch the data for Inase Max Date*/
export const getInaseMaxDate = async () => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = () => {
			return GET_INASE_MAX_DATE_QUERY();
		};
		return await API.post(graphQLApiUrl, queryParameter()).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getInaseMaxDate;
			}
			return { data: data };
		});
	} catch (err) {
		return err;
	}
};
