import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import { MuiListItemIcon, UpArrow, DownArrow, DrawerListItem, MoreVertListItemIcon, MenuItemText } from "./AppMenu.Styled";
import { List, Tooltip, ListItemText, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearGeoFilters } from "../../../store/actions/geoFilter.action";
import { clearProductFilters } from "../../../store/actions/productFilter.action";
import { clearAnchorFilters } from "../../../store/actions/anchorFilter.action";
import { checkPermission } from "../../../util/helper";

// React runtime PropTypes
const AppMenuPropTypes = {
	title: PropTypes.string.isRequired,
	link: PropTypes.string,
	image: PropTypes.string,
	Icon: PropTypes.elementType,
	IconActive: PropTypes.string,
	active: PropTypes.bool,
	isAccess: PropTypes.bool,
	items: PropTypes.array,
	drawerState: PropTypes.bool,
	external: PropTypes.bool,
	target: PropTypes.string,
};

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, "children">;

// Improve child items declaration
type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
	items?: AppMenuItemProps[];
};
const useStyles = makeStyles({
	moreMenuOptions: {
		marginLeft: "10px",
	},
	subMenuPopup: {
		zIndex: 999999,
	},
});
const AppMenuItem: React.FC<AppMenuItemProps> = (props) => {
	const userData = useSelector((state: any) => state.User.data);
	const classes = useStyles();
	const { title, Icon, items = [], link, drawerState, external, target, image, isAccess } = props;
	const isExpandable = items && items.length > 0;
	const [open, setOpen] = useState(false);
	function handleClick(e) {
		if (drawerState && isAccess) {
			setOpen(!open);
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (!drawerState) {
			setOpen(false);
		}
	});

	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const submenuOpen = Boolean(anchorEl);
	const handleSubMenuOptions = (event) => {
		if (isAccess) setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const dispatch = useDispatch();
	const onMenuClick = (link, external, target) => {
		if (external && isAccess) {
			if (target === "_blank") window.open(link, "_blank");
			else window.location.replace(link);
		} else if (isAccess) {
			dispatch(clearGeoFilters());
			dispatch(clearProductFilters());
			dispatch(clearAnchorFilters());

			history.push(link);
			setAnchorEl(null);
		}
	};

	const MenuItemRoot = (
		<DrawerListItem disabled={!isAccess} onClick={handleClick}>
			{Icon || image ? (
				<MuiListItemIcon style={{ ...(!isExpandable ? { display: "flex", justifyContent: "center" } : {}) }}>
					{drawerState ? (
						image ? (
							<img src={image} onClick={() => onMenuClick(link, external, target)} width="30" alt={title} />
						) : (
							<Icon onClick={() => onMenuClick(link, external, target)} />
						)
					) : (
						<Tooltip title={title} arrow placement="right">
							{image ? (
								<img src={image} onClick={() => onMenuClick(link, external, target)} width="30" alt={title} />
							) : (
								<Icon onClick={() => onMenuClick(link, external, target)} />
							)}
						</Tooltip>
					)}

					{isExpandable && !drawerState && (
						<>
							<MoreVertListItemIcon onClick={handleSubMenuOptions} className={classes.moreMenuOptions} />
							<Menu id="basic-menu" anchorEl={anchorEl} open={submenuOpen} onClose={handleClose}>
								{items.map((option, i) => {
									return (
										<MenuItem
											onClick={() => onMenuClick(option.link, option.external, option.target)}
											key={`menu-item-${i}`}
											disabled={!checkPermission(option.key, userData)}
										>
											{option.title}
										</MenuItem>
									);
								})}
							</Menu>
						</>
					)}
					<div></div>
				</MuiListItemIcon>
			) : (
				<MuiListItemIcon style={{ ...(!isExpandable ? { display: "flex", justifyContent: "center" } : {}) }} />
			)}
			{drawerState ? (
				<ListItemText
					onClick={() => onMenuClick(link, external, target)}
					inset={!Icon}
					primary={<MenuItemText variant="caption">{title}</MenuItemText>}
					style={{ paddingLeft: 0 }}
				/>
			) : null}
			{/* Display the expand menu if the item has children */}
			{drawerState && isExpandable && !open && <UpArrow />}
			{drawerState && isExpandable && open && <DownArrow />}
		</DrawerListItem>
	);

	const MenuItemChildren = isExpandable ? (
		<Collapse in={open} timeout="auto" unmountOnExit>
			<Divider />
			<List component="div" style={{ paddingLeft: 20 }}>
				{items.map((item, i) => (
					<AppMenuItem {...item} isAccess={checkPermission(item.key, userData)} key={`sub-menu-item-${i}`} drawerState={drawerState} />
				))}
			</List>
		</Collapse>
	) : null;

	return (
		<>
			{isAccess && MenuItemRoot}
			{isAccess && MenuItemChildren}
		</>
	);
};

AppMenuItem.propTypes = AppMenuPropTypes;

export default AppMenuItem;
