import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import CorrelationChart from "./components/CorrelationChart";
import APIOptimization from "./components/APIOptimization";
import G5 from "./components/G5";
import APISimulation from "./components/APISimulation";
import MultipleAPI from "./components/MultipleAPI";
import TopFilters from "./components/TopFilters";
import MultipleAPIFilters from "./components/MultipleAPIFilters";
import dataObject from "../../mocks/apiPredictiveDashboard";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { loader } from "../../store/actions/common.action";
import DashboardActions from "../../components/DashboardActions";
import { DropdownTitle } from "../../components/DashboardFilters/DashboardFilters.Styled";
import CheckBoxGroup from "../../components/UI-components/CheckBoxGroup";
import APISimulationFilter from "./components/APISimulationFilter";
import GlobalNotifications from "../../components/GlobalNotificationContainer";
import { pageList } from "../../mocks/common";
import DashboardSourceList from "../../components/DashboardSourceList";
import { removeMMPWFromLocal } from "../../util/helper";

const APIPredictiveDashboard: React.FC = () => {
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const [defaultFilters, setDefaultFilters] = useState({} as any);
	const [defaultAPISimulatorFilters, setDefaultAPISimulatorFilters] = useState({} as any);
	const [defaultAPISimulatorBottomFilters, setDefaultAPISimulatorBottomFilters] = useState({} as any);
	const [appliedFilters, setAppliedFilters] = useState({} as any);
	const [apiSimulationBottomFilters, setAPISimulationBottomFilters] = useState({});
	const [apiSimulationFilter, setAPISimulationFilter] = useState({});
	const [apiOptimizationG5Filter, setAPIOptimizationG5Filter] = useState({});
	const [multipleAPIFilter, setMultipleAPIFilter] = useState({ type: "", data: null });
	const [multipleAPIFilterConfig, setMultipleAPIFilterConfig] = useState({ ...dataObject });
	const [pepsiVsNonCompetitorsFilter, setPepsiVsNonCompetitorsFilter] = useState({ type: "", data: null });
	const [defaultMultipleAPIFilter, setDefaultMultipleAPIFilter] = useState({ type: "", data: null });
	const measuresData = [
		{ id: "1", value: "Price per Unit" },
		{ id: "2", value: "Price per Volume" },
	];
	const [selectedMeasureFilters, setSelectedMeasureFilters] = useState({
		variable1: "VolumeIndex",
		somMeasure: null,
		somGroup: null,
		apiUnits: measuresData[0].value,
	});
	const [disableDownloadButton, setDisableDownloadButton] = useState(true);
	const search = window.location.search;
	const [urlParams] = useState(new URLSearchParams(search));
	const recordId = parseInt(urlParams.get("id"));

	const onApplyFilter = (data) => {
		if (data) {
			setDisableDownloadButton(false);
			removeMMPWFromLocal("api-predictive-dash");
			setAppliedFilters(data);
			setAPISimulationFilter({ type: "global", data });
			setMultipleAPIFilter({ type: "global", data });
			setPepsiVsNonCompetitorsFilter({ type: "global", data });
			setMultipleAPIFilterConfig({ ...data });
			setAPIOptimizationG5Filter({
				...data,
				...selectedMeasureFilters,
				apiUnits: selectedMeasureFilters.apiUnits === "Price per Volume" ? "Volume" : "Units",
			});
		}
	};

	const onApplyAPISimulationFilter = (data) => {
		removeMMPWFromLocal("api-predictive-dash-api-simulation-container");
		setAPISimulationFilter({
			type: "local",
			data: { ...appliedFilters, ...data },
		});
		setDefaultAPISimulatorBottomFilters({});
	};

	const onApplyMultipleAPIFilter = (data) => {
		removeMMPWFromLocal("api-predictive-dash-multiple-api-container");
		setMultipleAPIFilter({ type: "local", data });
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loader(false));
	}, []);

	useEffect(() => {
		if (userData && !recordId) {
			const permissionCountries: string[] = userData.permissionCountry.split(",");
			const permissionCategories: string[] = userData.permissionCategory.split(",");
			const defaultFilterConfiguration = dataObject.defaultAPIVolumeFilterConfigurations.find(
				(item) => permissionCountries.includes(item.country) && item.category.every((category) => permissionCategories.includes(category))
			);
			if (defaultFilterConfiguration && Object.keys(defaultFilterConfiguration).length) {
				setDefaultFilters(defaultFilterConfiguration);
				setAppliedFilters(defaultFilterConfiguration);
			}
		}
	}, [userData, dataObject.defaultAPIVolumeFilterConfigurations]);

	useEffect(() => {
		if (selectedMeasureFilters && Object.keys(appliedFilters).length && selectedMeasureFilters.apiUnits) {
			setAPIOptimizationG5Filter({
				...appliedFilters,
				...selectedMeasureFilters,
				apiUnits: selectedMeasureFilters.apiUnits === "Price per Volume" ? "Volume" : "Units",
			});
		}
	}, [selectedMeasureFilters]);

	const getSaveDashBoardsData = (data) => {
		if (data.filters) {
			let savedDashboardData = { filters: JSON.parse(data.filters) };
			localStorage.setItem("periodicity", savedDashboardData.filters.periodicity || "{}");
			setDisableDownloadButton(false);
			const defaultMesureFilters: any = {
				variable1: savedDashboardData?.filters?.global?.variable1,
				somMeasure: savedDashboardData?.filters?.global?.somMeasure,
				somGroup: savedDashboardData?.filters?.global?.somGroup,
				apiUnits: savedDashboardData?.filters?.global?.apiUnits === "Volume" ? "Price per Volume" : "Price per Unit",
			};
			setDefaultFilters(savedDashboardData?.filters?.global);
			setAppliedFilters(savedDashboardData?.filters?.global);
			setSelectedMeasureFilters(defaultMesureFilters);
			setDefaultAPISimulatorFilters(savedDashboardData?.filters?.apiSimulationFilter);
			setDefaultAPISimulatorBottomFilters(savedDashboardData?.filters?.apiSimulationBottomFilters || {});
			setMultipleAPIFilter({
				...savedDashboardData?.filters?.multipleAPIFilter,
				data: {
					...savedDashboardData?.filters?.multipleAPIFilter.data,
					apiUnits: savedDashboardData?.filters?.multipleAPIFilter?.data?.apiUnits === "Volume" ? "Price per Volume" : "Price per Unit",
				},
			});
			setAPISimulationFilter({
				...savedDashboardData?.filters?.apiSimulationFilter,
				data: {
					...savedDashboardData?.filters?.global,
					...savedDashboardData?.filters?.apiSimulationFilter.data,
				},
			});
			setDefaultMultipleAPIFilter(savedDashboardData?.filters?.multipleAPIFilter);
		}
	};

	const reduxState = useSelector((state: any) => state);

	return (
		<>
			{userData && (
				<DashboardActions
					title="API VS Volume Dashboard"
					data={{
						filters: {
							global: { ...appliedFilters },
							apiSimulationFilter: { ...apiSimulationFilter },
							apiSimulationBottomFilters: { ...apiSimulationBottomFilters },
							multipleAPIFilter: { ...multipleAPIFilter },
							pepsiVsNonCompetitorsFilter: { ...pepsiVsNonCompetitorsFilter },
						},
						mmpwKey: "api-predictive-dash",
					}}
					dashboardKey={pageList[1].id}
					callback={getSaveDashBoardsData}
					disableDownloadButton={
						disableDownloadButton ||
						reduxState.common.loader ||
						reduxState.ApiPredictive.correlationChartDataLoader ||
						reduxState.ApiPredictive.correlationTableDataLoader ||
						reduxState.ApiPredictive.optimizationChartData ||
						reduxState.ApiPredictive.g5ChartData ||
						reduxState.ApiPredictive.simulationDataLoader ||
						reduxState.ApiPredictive.simulationValueLoader ||
						reduxState.ApiPredictive.multipleAPIDataLoader
					}
				>
					<Grid container spacing={2} className="m-b-20">
						<GlobalNotifications pageName={pageList[1].value} />
					</Grid>
					<Box className="m-b-20">
						<TopFilters callback={onApplyFilter} data={dataObject} defaultFilters={defaultFilters} />
					</Box>
					<CorrelationChart filters={appliedFilters} />
					{/* <Card className="m-b-20">
				<CardContent>
					{appliedFilters && appliedFilters.country ? (
						<Grid container display="flex" alignItems="center">
							<DropdownTitle className="m-r-20">API Variable Configurations</DropdownTitle>
							<CheckBoxGroup
								data={measuresData}
								color="#000"
								direction="row"
								single={true}
								select={(data) => {
									setSelectedMeasureFilters({
										...selectedMeasureFilters,
										apiUnits: data,
									});
								}}
								defaultOption={selectedMeasureFilters.apiUnits}
							/>
						</Grid>
					) : (
						<Grid className="p-l-16 m-t-20" container spacing={2}>
							<Skeleton variant="rectangular" className="m-r-20" width={210} height={45} />
							<Skeleton variant="rectangular" width={310} height={45} />
						</Grid>
					)}
				</CardContent>
			</Card>
			<APIOptimization filters={apiOptimizationG5Filter} />
			<G5 filters={apiOptimizationG5Filter} />
			<APISimulationFilter callback={onApplyAPISimulationFilter} globalFilters={appliedFilters} defaultFilters={defaultAPISimulatorFilters} />
			<APISimulation filters={apiSimulationFilter} callback={setAPISimulationBottomFilters} defaultFilters={defaultAPISimulatorBottomFilters} />
			<MultipleAPIFilters
				data={{ ...multipleAPIFilterConfig }}
				callback={onApplyMultipleAPIFilter}
				globalFilters={Object.keys(appliedFilters).length > 0 && appliedFilters.country ? appliedFilters : {}}
				defaultFilters={
					Object.keys(appliedFilters).length > 0 && appliedFilters.country && Object.keys({ ...defaultMultipleAPIFilter?.data }).length > 0
						? defaultMultipleAPIFilter
						: {}
				}
			/>
			<MultipleAPI filters={Object.keys(appliedFilters).length > 0 && appliedFilters.country ? multipleAPIFilter : {}} /> */}
					<DashboardSourceList dashboardName={pageList[1].id} appliedFilters={appliedFilters} category={appliedFilters.category} />
				</DashboardActions>
			)}
		</>
	);
};

export default APIPredictiveDashboard;
