import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid } from "@mui/material";
import _ from "lodash";
import PriceLadderSRP from "../PriceLadderSRP";
import PriceLadderMainCompetitor from "../PriceLadderMainCompetitor";
import { fetchPriceLadderSrpData } from "../../service";
import { priceLadderDataLoader } from "../../store/PriceLadder.action";
import { toast } from "../../../../store/actions/toast.action";
import Indicator from "../../../../components/Loader";
import Dashboard from "../../../../components/Dashboard";
import { fetchImageURL } from "../../../../util/services";
import { ICompetitorGraphDataObject, IPLFilterObject, IPLVendorListObject, ISRPDataObject } from "../../../../types/priceLadder";
import { BlackTitle } from "../../../../styles/Common.Styled";
import "../priceLadder.css";

const PriceLadderGraph: React.FC<{
	filters: IPLFilterObject;
	defaultFilters?: IPLFilterObject;
	setUpdatedGraphData;
}> = ({ filters, defaultFilters, setUpdatedGraphData }) => {
	const dispatch = useDispatch();
	const commonLoader = useSelector((state: any) => state.common.loader);
	const [vendors, setVendors] = useState<IPLVendorListObject[]>([]);
	const [srpData, setSrpData] = useState<ISRPDataObject[]>([]);
	const [mainCompetitorData, setMainCompetitorData] = useState<ICompetitorGraphDataObject>({ pepsiProducts: null, competitorProducts: null });
	const [callGraph, setCallGraph] = useState("");
	const [graphRendering, setGraphRendering] = useState(false);
	const [noDataFlag, setNoDataFlag] = useState(false);
	const [loader, setLoader] = useState(false);

	const getSRPGraphData = async (payload) => {
		const requestPayload = { ...payload };
		setLoader(true);
		await fetchPriceLadderSrpData(requestPayload)
			.then(async (response) => {
				setNoDataFlag(response.data.graphData.length === 0);

				if (response && response.data) {
					if (response.data.graphData.length === 0) {
						setNoDataFlag(true);
					}
					const initData = _.cloneDeep(response.data.graphData);
					const graphData = _.cloneDeep(response.data.graphData);

					response.data.vendors.map((vendor, i) => {
						const fileName: string = vendor.logo.split("/")[vendor.logo.split("/").length - 1];
						const img = fetchImageURL(filters.country, fileName);
						vendor["logoImg"] = img;
					});
					graphData.map(async (g, i) => {
						g.products.map((p, j) => {
							const fileName: string = p.img.split("/")[p.img.split("/").length - 1];
							const img = fetchImageURL(filters.country, fileName);
							p["imagBlob"] = img;
						});
					});

					const jsonObj = JSON.stringify(initData);
					localStorage.setItem("srpGraphData", jsonObj);
					setVendors(response.data.vendors);
					localStorage.setItem("vendors", JSON.stringify(response.data.vendors));
					setSrpData(graphData);
					setCallGraph(defaultFilters.priceLadderBy || filters.priceLadderBy);
					setLoader(false);
					setGraphRendering(true);
				}
				if (response.error) {
					dispatch(toast("priceLadderSRP: " + response.error, true, 2000, "error"));
				}
				dispatch(priceLadderDataLoader(false));
			})
			.catch((e) => {
				dispatch(toast("Something Went Wrong!", true, 2000, "error"));
				dispatch(priceLadderDataLoader(false));
				setLoader(false);
			});
	};

	const getMainCompetitorGraphData = async (payload) => {
		const requestPayload = { ...payload };
		setLoader(true);
		await fetchPriceLadderSrpData(requestPayload)
			.then(async (response) => {
				setNoDataFlag(response.data.mainCompetitorGraph.pepsiProducts.length === 0);
				if (response && response.data) {
					const jsonObjpepsiProducts = JSON.stringify(_.cloneDeep(response.data.mainCompetitorGraph.pepsiProducts));
					localStorage.setItem("pepsiProducts", jsonObjpepsiProducts);

					const jsonObjcompetitorProducts = JSON.stringify(_.cloneDeep(response.data.mainCompetitorGraph.competitorProducts));
					localStorage.setItem("competitorProducts", jsonObjcompetitorProducts);

					response.data.vendors.map((vendor, i) => {
						const fileName: string = vendor.logo.split("/")[vendor.logo.split("/").length - 1];
						const img = fetchImageURL(filters.country, fileName);
						vendor["logoImg"] = img;
					});
					response.data.mainCompetitorGraph.pepsiProducts.map((g, i) => {
						const fileName: string = g.img.split("/")[g.img.split("/").length - 1];
						const img = fetchImageURL(filters.country, fileName);
						g["imagBlob"] = img;
					});
					response.data.mainCompetitorGraph.competitorProducts.map((g, i) => {
						const fileName: string = g.img.split("/")[g.img.split("/").length - 1];
						const img = fetchImageURL(filters.country, fileName);
						g["imagBlob"] = img;
					});

					setVendors(response.data.vendors);
					setMainCompetitorData(response.data.mainCompetitorGraph);
					setCallGraph(defaultFilters.priceLadderBy || filters.priceLadderBy);
					setGraphRendering(true);
					setLoader(false);
				}
				if (response.error) {
					dispatch(toast("priceLadderSRP: " + response.error, true, 2000, "error"));
				}
				dispatch(priceLadderDataLoader(false));
			})
			.catch((e) => {
				dispatch(toast("Something Went Wrong!", true, 2000, "error"));
				dispatch(priceLadderDataLoader(false));
				setLoader(false);
			});
	};

	useEffect(() => {
		if (filters && filters.geoLevel2) {
			setCallGraph("");
			setNoDataFlag(false);
			if (filters.priceLadderBy === "suggestedretailprice") {
				getSRPGraphData(filters);
			} else {
				getMainCompetitorGraphData(filters);
			}
		}
	}, [filters]);

	useEffect(() => {
		setUpdatedGraphData(srpData);
	}, [srpData]);

	useEffect(() => {
		setUpdatedGraphData(mainCompetitorData);
	}, [mainCompetitorData]);

	return (
		<>
			<Indicator position="absolute" show={!commonLoader && loader} />
			{graphRendering && callGraph && (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Dashboard
									title={null}
									showSkeleton={false}
									chartRef={() => {}}
									showActionButton={true}
									actionButtons={{
										mmpw: false,
										screenshot: true,
										editMedia: false,
									}}
									id={null}
								>
									<Grid container alignItems={"center"} spacing={1}>
										<Grid xs display="flex" justifyContent="center" alignItems="center" className="m-b-20 m-t-20">
											{noDataFlag ? (
												<BlackTitle fontSize={24} fontWeight={600} color="red">
													No data found!
												</BlackTitle>
											) : (
												<BlackTitle fontSize={24} fontWeight={600}>
													{filters.priceLadderBy === "maincompetitor" ? "Price Ladder by Main Competitor " : "Price Ladder by Suggested Retail Price"}
												</BlackTitle>
											)}
										</Grid>
									</Grid>
									{callGraph && callGraph === "suggestedretailprice" && (
										<PriceLadderSRP filters={filters} vendors={vendors} SRPData={srpData} setSRPData={setSrpData} />
									)}
									{callGraph && callGraph === "maincompetitor" && (
										<PriceLadderMainCompetitor
											filters={filters}
											defaultFilters={defaultFilters}
											vendors={vendors}
											mainCompetitorData={mainCompetitorData}
											setMainCompetitorData={setMainCompetitorData}
										/>
									)}
								</Dashboard>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			)}
		</>
	);
};
export default PriceLadderGraph;
