import React, { useEffect, useState } from "react";
import { Box, Card, Grid, CardContent } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import DashboardPreviewCard from "../DashboardPreviewCard";
import _ from "lodash";
import Dropdown from "../Dropdown";
import { Title } from "../../styles/Common.Styled";
import { standardSortOption, dashboardCardMock } from "../../mocks/common";
import { Typography } from "@material-ui/core";
import NoDashboard from "../NoDashboard/NoDashboard";
import Utilities from "../../util/Utilities";
import GlobalNotifications from "../GlobalNotificationContainer";
import { getCategoryData } from "./service";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
	filterTitle: {
		fontSize: "15px",
		fontWeight: 500,
		color: "#000",
	},
	fevBtn: {
		height: "46px",
		color: "#CE0065",
		textTransform: "capitalize",
		width: "100%",
	},
	fevIcon: {
		fontSize: "40px !important",
		color: "#0093CD",
	},
	leftBorder: {
		borderLeft: "11px solid #0093CD",
	},
	notiAlertTitle: {
		fontSize: 16,
		fontWeight: 600,
		color: "#000",
	},
	notiAlertDescription: {
		color: "#000",
		fontSize: 14,
	},
	closeBtn: {
		fontSize: 12,
	},
}));
const CategoryDashboardList: React.FC<{ title: string; categoryKey: string; pageName: string }> = ({ title, categoryKey, pageName }) => {
	const classes = useStyles();
	const userDetail = useSelector((state: any) => state.User.data);
	const [sortedDashboards, setSortedDashboards] = useState([]);
	const [selectedSort, setSelectedSort] = useState(null);
	const [skeleton, setSkeleton] = useState(true);

	const onSort = (data) => {
		let sortedData = [];
		switch (data[0]) {
			case "newest":
				sortedData = Utilities.sortArray(sortedDashboards, "createdOn");
				break;
			case "most-popular":
				sortedData = Utilities.sortArray(sortedDashboards, "viewCount", "desc");
				break;
			case "a-z":
				sortedData = Utilities.sortArray(sortedDashboards, "name");
				break;
			case "z-a":
				sortedData = Utilities.sortArray(sortedDashboards, "name", "desc");
				break;
		}
		setSelectedSort(data);
		setSortedDashboards(sortedData);
	};

	useEffect(() => {
		if (userDetail && userDetail.id) {
			setSkeleton(true);
			getData();
		}
	}, [userDetail]);

	const getData = () => {
		getCategoryData(userDetail.id, categoryKey)
			.then((response) => {
				setSortedDashboards(response.data);
			})
			.catch((e) => {})
			.finally(() => {
				setSkeleton(false);
			});
	};

	return (
		<Box>
			<Grid item xs={12} sm={6}>
				<Grid container>
					<Title className="m-b-20" style={{ fontSize: 24, fontWeight: 600 }}>
						{title}
					</Title>
				</Grid>
			</Grid>
			<Card className="m-b-20">
				<CardContent>
					<Grid container display="flex" alignItems="center" spacing={1}>
						<Grid item xs={12} sm={1}>
							<Typography className={classes.filterTitle}>Filters</Typography>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Dropdown
								onChange={(data) => {
									onSort(data);
								}}
								defaultOption={selectedSort || ["empty"]}
								keyText={"sort-by-dropdown"}
								data={standardSortOption}
								placeholder="Sort By"
								search={{ enable: false }}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<Grid container spacing={2} className="m-b-20">
				<GlobalNotifications pageName={pageName} />
			</Grid>
			{!skeleton ? (
				<Grid container spacing={2} className="m-b-20">
					{sortedDashboards && sortedDashboards.length ? (
						sortedDashboards.map((item) => (
							<Grid item xs={12} sm={3} key={`dashboard-preview-card-alert-${item.name}`}>
								<DashboardPreviewCard data={item} onToggleFavorite={getData} />
							</Grid>
						))
					) : (
						<Grid xs={12} justifyContent="center">
							<NoDashboard heading="No dashboards available" />
						</Grid>
					)}
				</Grid>
			) : (
				<Grid container spacing={2} className="m-b-15">
					{dashboardCardMock.map((item) => (
						<Grid item xs={12} sm={3} md={3} key={`favorite-dashboard-cards-${item.name}`}>
							<DashboardPreviewCard data={item} skeleton={true} />
						</Grid>
					))}
				</Grid>
			)}
		</Box>
	);
};
export default CategoryDashboardList;
