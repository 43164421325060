import { Box, Grid, Skeleton } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ReactDOMServer from "react-dom/server";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "../../../../../../components/Dashboard";
import HighchartContainer from "../../../../../../components/HighchartContainer";
import { addRemoveLabel, loader, removeSeriesLabels } from "../../../../../../store/actions/common.action";
import { NoData } from "../../../../../../styles/Common.Styled";
import { messages } from "../../../../../../util/config";
import { chartConfig } from "./chartConfig";
import { getDistinctKeys, plotElementsOnChart, sortPriceRangeGraphKeys } from "../../../../../../util/helper";
import TooltipContent from "./TooltipComponent/TooltipComponent";

function sortSubPriceRangesData(subRangeData) {
	const sortedKeys = Object.keys(subRangeData).sort((aKey: string, bKey: string) => {
		const aKeyStartingRange: number = parseInt(aKey.replace(/\$/g, "").split("-")[0]);
		const bKeyStartingRange: number = parseInt(bKey.replace(/\$/g, "").split("-")[0]);
		if (aKeyStartingRange < bKeyStartingRange) {
			return 1;
		} else {
			return -1;
		}
	});
	const sortedObj = {};

	sortedKeys.forEach((key) => {
		sortedObj[key] = subRangeData[key];
	});

	return sortedObj;
}

function getColorPallet(graphKeys, keys) {
	const colorPallet = ["#6C88B7", "#486492", "#3984A3", "#4DADB5", "#62AC5A", "#92D050", "#F1C058", "#E08428", "#DC3E4E", "#C22B49"];
	const graphColorPallet: string[] = [];
	const sortedGraphKey = sortPriceRangeGraphKeys(graphKeys).reverse();
	keys.map((key) => {
		if (key !== "date" && key !== "total") {
			const keyIndex: number = sortedGraphKey.findIndex((graphKey) => graphKey === key);
			graphColorPallet.push(colorPallet[keyIndex]);
		}
	});
	return graphColorPallet;
}

const PriceRangeGraph: React.FC<{
	data;
	skeleton;
	isShowDataFlag;
	title?;
	tooltip;
	id;
	type;
	graphKeys;
}> = ({ data, skeleton, isShowDataFlag, title, tooltip, id, type, graphKeys }) => {
	const chartRef = useRef(null);
	const tooltips = useSelector((state: any) => state.common.highChartTooltips);
	const dispatch = useDispatch();
	const [clickEventData, setClickEventData] = useState<any>({});
	useEffect(() => {
		if (data && graphKeys.length > 0) {
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;
				const result = formatData(data);
				let entries = Object.entries(result.stackSeries);
				let entriesLength = entries.length;
				while (chart && chart.series.length) {
					chart.series[0].remove();
				}
				chart.xAxis[0].setCategories(result.categories);
				chart.addSeries({
					data: result.series1,
					...(type === "pepsi" ? { name: "PepsiCo Total", color: "#757171" } : { name: "Competitor Total", color: "#757171" }),
					type: "spline",
					zIndex: 6,
					lineWidth: 4,
					dataLabels: {
						allowOverlap: true,
						enabled: true,
						formatter: function () {
							return this.y === 0 ? null : this.y.toFixed(1);
						},
					},

					plotOptions: {
						spline: {
							marker: {
								lineColor: "#D0CECE",
							},
						},
					},
				});
				const colorPallet = getColorPallet(graphKeys, result.entries);

				entries.map(([key, val], index) => {
					chart.addSeries({
						data: val,
						name: key,
						color: colorPallet[index],
						dataLabels: {
							formatter: function () {
								return this.y === 0 ? null : this.y.toFixed(1);
							},
						},
					});
				});
				chart.update({
					tooltip: {
						formatter: function () {
							// Render TooltipContent to a string
							if (this.series.type === "spline") {
								return `<b>${this.x}</b><br/>${this.series.name}: ${this.y.toFixed(1)}`;
							} else {
								const content = ReactDOMServer.renderToString(
									<TooltipContent
										data={this.point.customData}
										color={this.color}
										category={this.point.category}
										subCategory={this.series.name}
										fontSize={this?.series?.chart?.options?.tooltip?.style?.fontSize}
									/>
								);
								return content;
							}
						},
					},
					plotOptions: {
						series: {
							cursor: "pointer",
							point: {
								events: {
									click: function () {
										setClickEventData({ ...this });
									},
								},
							},
							events: {
								legendItemClick: function (e) {
									dispatch(removeSeriesLabels([...chart.series[e.target.index].data], id));
								},
							},
						},
					},
				});

				//Resetting the chart tooltip font size on component mount
				if (chartRef?.current?.chart?.tooltip?.options?.style?.fontSize) delete chartRef.current.chart.tooltip.options.style.fontSize;

				let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
				if (dashbaordData && dashbaordData[id]) {
					plotElementsOnChart(dashbaordData[id], chart, id);
				}
			}
			dispatch(loader(false));
		}
	}, [data, graphKeys]);
	useEffect(() => {
		if (Object.keys(clickEventData).length > 0) {
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;
				dispatch(addRemoveLabel([clickEventData], id, tooltips, chart));
				setClickEventData({});
			}
		}
	}, [clickEventData]);

	const formatData = (payload) => {
		const categories: any = [];
		const series1: any = [];
		const stackSeries: any = {};
		let entries = getDistinctKeys(payload.priceRanges);
		entries = sortPriceRangeGraphKeys(entries).reverse();
		payload.priceRanges?.map((item) => {
			categories.push(item.date);
			entries.map((key) => {
				if (key === "total") {
					series1.push(item[key]);
				} else if (key !== "date" && item[key]) {
					const subRanges = item[key]?.subranges ?? [];
					if (stackSeries[key]) stackSeries[key].push({ y: item[key]?.total, customData: sortSubPriceRangesData(subRanges) });
					else stackSeries[key] = [{ y: item[key]?.total, customData: sortSubPriceRangesData(subRanges) }];
				} else if (key !== "date") {
					if (stackSeries[key]) stackSeries[key].push({ y: 0, customData: [] });
					else stackSeries[key] = [{ y: 0, customData: [] }];
				}
			});
		});
		return { categories, series1, stackSeries, entries };
	};

	return (
		<Dashboard
			title={title}
			tooltip={tooltip}
			id={id}
			chartRef={chartRef}
			showSkeleton={skeleton}
			actionButtons={{
				mmpw: true,
				screenshot: true,
				editMedia: true,
			}}
		>
			{skeleton ? (
				<Grid container justifyContent="space-around">
					<Grid item xs={12} sm={6} md={12}>
						<Skeleton variant="rectangular" height={400} width={"95%"} />
					</Grid>
				</Grid>
			) : isShowDataFlag && data && Object.keys(data).length > 0 ? (
				<Grid container spacing={3} justifyContent="space-between">
					<Grid item xs={12} sm={12}>
						<HighchartContainer id={id}>
							<HighchartsReact highcharts={Highcharts} options={chartConfig} ref={chartRef} />
						</HighchartContainer>
					</Grid>
				</Grid>
			) : (
				<Box style={{ height: 400 }} display="flex" justifyContent="center" alignItems="center">
					<NoData>{messages.noData}</NoData>
				</Box>
			)}
		</Dashboard>
	);
};

export default PriceRangeGraph;
