import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer/PageContainer";
import { pageNames } from "../../../mocks/common";
import { Divider, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { ThemeBtn } from "../../../styles/Common.Styled";
import TopFilters from "./FilterSection/TopFilters/TopFilters";
import Summary from "./Summary/Summary";
import Coverage from "./Coverage/Coverage";
import AssortmentList from "./AssortmentList/AssortmentList";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
	convertToCsvWithKeys,
	downloadInCsvFormat,
	fetchFullAssortmentListData,
	fetchFullAssortmentSimulationData,
} from "../../../util/mixAndAssortmentServices";
import { IAssortmentListPayload, IAssortmentListTableData, IMnAFilterConfigurations } from "../../../types/mixAndAssortment";
import { toast } from "../../../store/actions/toast.action";
import { dialog } from "../../../store/actions/dialog.action";
import { useLocation } from "react-router-dom";
import { getScenarioDetailData } from "../../../store/actions/MnADashboards/assortment.action";
import dataObject from "../../../mocks/assortmentTool";

const AssortmentTool: FC<{ setShowScenarioLibrary: Dispatch<SetStateAction<boolean>>; isNewScenario: boolean }> = ({
	setShowScenarioLibrary,
	isNewScenario,
}) => {
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const queryScenario = query.get("scenario") || "";
	const queryModeParam = query.get("mode");
	const dispatch = useDispatch();
	const scenarioDetail = useSelector((state: RootStateOrAny) => state.assortmentDetail.data);
	const [selectedFilters, setSelectedFilters] = useState<IMnAFilterConfigurations>({
		country: [],
		dateTimePeriod: [],
		businessUnit: [],
		endTimePeriod: [],
		region: [],
		channel: [],
		storeSegment: [],
	});
	const [geoFilterSkeleton, setGeoFilterSkeleton] = useState(true);
	const [chartSkeleton, setChartSkeleton] = useState(true);
	const [assortmentListTableData, setAssortmentListTableData] = useState<IAssortmentListTableData[]>([]);
	useEffect(() => {
		if (queryScenario && queryModeParam === "edit") {
			dispatch(getScenarioDetailData(queryScenario, false));
		} else if (queryScenario && queryModeParam !== "edit") {
			dispatch(getScenarioDetailData(queryScenario, true));
		}
	}, []);
	useEffect(() => {
		if (scenarioDetail) {
			setGeoFilterSkeleton(false);
		}
	}, [scenarioDetail]);

	const applyGeoFilters = (payload: IAssortmentListPayload) => {
		fetchFullAssortmentListData(payload).then((response) => {
			if (response.data) {
				let jsonData = JSON.parse(response.data);
				if (typeof jsonData === "string") {
					jsonData = JSON.parse(jsonData);
				}
				setAssortmentListTableData(jsonData);
				setChartSkeleton(false);
			}
			if (response.error) {
				dispatch(toast("Download Assortment List Data: " + response.error, true, 2000, "error"));
			}
		});
	};

	const downloadOverallData = () => {
		fetchFullAssortmentSimulationData(scenarioDetail.scenarioName).then((response) => {
			if (response.data) {
				let jsonData = JSON.parse(response.data);
				if (typeof jsonData === "string") {
					jsonData = JSON.parse(jsonData);
				}
				const keysArray = dataObject.columnsToFullDowload.map((column) => column.field);
				if (Object.keys(jsonData).length === 0) {
					dispatch(dialog(response.message, "Download Assortment Simulation Data: ", true, true));
				} else {
					const csvData = convertToCsvWithKeys(jsonData, keysArray, dataObject.columnsToFullDowload);
					downloadInCsvFormat(csvData, "Assortment_Output_Overall_Data");
				}
			}
			if (response && response.error) {
				dispatch(toast("Download Assortment Simulation Data: " + response.error, true, 2000, "error"));
			}
		});
	};
	return (
		<PageContainer page={pageNames.assortmentTool}>
			<Grid className="m-b-10" xs={12} textAlign={"right"}>
				<Typography fontSize={9}>* Mandatory</Typography>
			</Grid>
			<TopFilters
				isNewScenario={isNewScenario}
				selectedFilters={selectedFilters}
				geoFilterSkeleton={geoFilterSkeleton}
				setShowScenarioLibrary={setShowScenarioLibrary}
				setSelectedFilters={setSelectedFilters}
				callBack={applyGeoFilters}
				downloadData={downloadOverallData}
			/>
			<Box className="m-b-20">
				<Stack direction={"row"} sx={{ alignItems: "center" }}>
					<Grid container sx={{ alignItems: "center" }}>
						<Grid item>
							<Typography variant="h5">OUTPUT</Typography>
						</Grid>
					</Grid>
				</Stack>
			</Box>
			<Divider sx={{ borderBottomWidth: "medium" }} />
			<AssortmentList assortmentListTableData={assortmentListTableData} chartSkeleton={chartSkeleton} />
		</PageContainer>
	);
};

export default AssortmentTool;
