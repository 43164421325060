import { GridColDef } from "@mui/x-data-grid";
import { formatNumber } from "../util/helper";
import { IMnAFilterConfigurations, ISwitchingTableSelectedFilters } from "../types/mixAndAssortment";
import { NetRevenue } from "../assets/icons/dashboard";
import { IStats } from "../types/common";
import { faCoins, faScaleUnbalancedFlip } from "@fortawesome/free-solid-svg-icons";

const geoFilter = {
	channel: {
		title: "Channel",
		options: [],
		placeholder: "Channel",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		first: true,
		children: "region",
	},
	region: {
		title: "Region",
		options: [],
		placeholder: "Region",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		children: "storeSegment",
	},
	storeSegment: {
		title: "Store Segment",
		options: [],
		placeholder: "Segment",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		last: true,
	},
};

const overallFilters = {
	country: {
		title: "Country",
		options: [],
		placeholder: "Country",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		first: true,
		children: "dateTimePeriod",
	},
	dateTimePeriod: {
		title: "Date Time Period",
		options: [],
		placeholder: "Time Period",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		children: "businessUnit",
	},
	businessUnit: {
		title: "Business Unit",
		options: [],
		placeholder: "Business Unit",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		last: true,
	},
};

const decesionFilter = {
	assortmentScenario: {
		title: "Assortment Scenario",
		options: [],
		placeholder: "Scenario",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		first: true,
		children: "mixScenario",
	},
	mixScenario: {
		title: "Mix Scenario",
		options: [],
		placeholder: "Scenario",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		last: true,
	},
	channel: {
		title: "Channel",
		options: [],
		placeholder: "Channel",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		disabled: true,
		last: true,
	},
	region: {
		title: "Region",
		options: [],
		placeholder: "Region",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		disabled: true,
		last: true,
	},
	storeSegment: {
		title: "Store Segment",
		options: [],
		placeholder: "Segment",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		disabled: true,
		last: true,
	},
};

const portfolioLevelFilterData = [
	{
		label: "Brand",
		value: "brand",
	},
	{
		label: "Segment",
		value: "segment",
	},
	{
		label: "SKU",
		value: "sku",
	},
];

const geoLevelFilterData = [
	{
		label: "Channel",
		value: "channel",
	},
	{
		label: "Region",
		value: "region",
	},
	{
		label: "Store Segment",
		value: "storeSegment",
	},
];

const portfolioFilter = {
	channel: {
		title: "Channel",
		options: [],
		placeholder: "Channel",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		first: true,
		children: "region",
	},
	region: {
		title: "Region",
		options: [],
		placeholder: "Region",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		children: "storeSegment",
	},
	storeSegment: {
		title: "Store Segment",
		options: [],
		placeholder: "Store Segment",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		last: true,
	},
};

const geoSummaryFilter = {
	segment: {
		title: "Segment",
		options: [],
		placeholder: "Segment",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		first: true,
		children: "brand",
	},
	brand: {
		title: "Brand",
		options: [],
		placeholder: "Brand",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		children: "sku",
	},
	sku: {
		title: "SKU",
		options: [],
		placeholder: "SKU",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		last: true,
	},
};

const mockSimulationData = {
	overallScore: "57.643864734649696",
	assortment: {
		overallScore: "85.80744815923185",
		status: "Good",
		mustHaveSKUsDistribution: "80.52917415974595",
		goodToHaveSKUsDistribution: "91.08572215871776",
	},
	mix: {
		overallScore: "70",
		status: "Low",
		volumeMix: "40",
		netRevenue: "40",
		nopbt: "40",
	},
	price: {
		overallScore: "37.977330699427526",
		status: "Low",
		netPriceRealisation: "39.80400162769558",
		priceChangeVsRestOfMarket: "30.841244196645402",
		priceChangeVsInflation: "42.56411647297049",
	},
	shelfAndMerchandising: {
		overallScore: "39.75894930306876",
		status: "Low",
		shelfSpace: "38.716686811334405",
		exhibition: "40.801211794803116",
	},
	currentAverage: {
		mustHaveCurrentAverage: "53.765581546169805",
		goodToHaveCurrentAverage: "67.64339252674012",
	},
};

const mockRestOverviewData = {
	netRevenue: [
		{
			netRevenueValue: "6067505053.285416",
			percentageChange: "3.8579550152772146",
		},
	],
	profit: [
		{
			profitPercent: "35.169570682058215",
			percentageChange: "-17.913875764643894",
		},
	],
	volume: [
		{
			volume: "82210041.05596139",
			percentageChange: "17.609395119751703",
		},
	],
};

const mockOpportunitiesData = {
	assortmentOpportunities: {
		"a.MEXSUR/TLAHUA": {
			sop: "1443650.6323258425",
			nopbt: "370242.1433359121",
		},
		acambaro: {
			sop: "248691.1908825744",
			nopbt: "60700.43807212809",
		},
		"agencia  ZAPOPAN": {
			sop: "509151.6953316752",
			nopbt: "80906.8137311357",
		},
		"agencia ACAPULCO": {
			sop: "577499.9797521167",
			nopbt: "147953.53664372818",
		},
	},
	mixOpportunities: {
		"a.MEXSUR/TLAHUA": {
			sop: "0",
			nopbt: "0",
		},
		acambaro: {
			sop: "0",
			nopbt: "0",
		},
		"agencia  ZAPOPAN": {
			sop: "0",
			nopbt: "0",
		},
		"agencia ACAPULCO": {
			sop: "0",
			nopbt: "0",
		},
	},
};

const analysisFilterOrder = [
	{
		key: "country",
		order: 1,
	},
	{
		key: "dateTimePeriod",
		order: 2,
	},
	{
		key: "businessUnit",
		order: 3,
	},
];

const geoFilterOrder = [
	{
		key: "channel",
		order: 1,
	},
	{
		key: "region",
		order: 2,
	},
	{
		key: "storeSegment",
		order: 3,
	},
];

const decesionFilterOrder = [
	{
		key: "assortmentScenario",
		order: 1,
	},
	{
		key: "mixScenario",
		order: 2,
	},
	{
		key: "channel",
		order: 3,
	},
	{
		key: "region",
		order: 4,
	},
	{
		key: "storeSegment",
		order: 5,
	},
];
const portfolioFilterOrder = [
	{
		key: "channel",
		order: 1,
	},
	{
		key: "region",
		order: 2,
	},
	{
		key: "storeSegment",
		order: 3,
	},
];

const geoSummaryFilterOrder = [
	{
		key: "segment",
		order: 1,
	},
	{
		key: "brand",
		order: 2,
	},
	{
		key: "sku",
		order: 3,
	},
];

const switchingTableDefaultFilters: ISwitchingTableSelectedFilters = {
	segment: [],
	brand: [],
	subBrand: [],
	packSize: [],
	sku: [],
	flavor: [],
	checkoutSku: [],
};
const sizeOfPrizeRadio = {
	options: [
		{
			id: "1",
			label: "Assortment",
			value: "assortmentOpportunities",
			inline: "true",
		},
		{
			id: "2",
			label: "Mix",
			value: "mixOpportunities",
			inline: "true",
		},
	],
	defaultOption: { id: "1", value: "assortmentOpportunities" },
	showRadioButton: true,
};

const KPIData: Array<IStats> = [
	{
		icon: NetRevenue,
		title: "Net Revenue",
		value: "0",
		raise: false,
		change: "2%",
	},
	{
		icon: faCoins,
		color: "#008cca",
		title: "Profit % of NR",
		value: "0",
		raise: true,
		change: "0%",
	},
	{
		icon: faScaleUnbalancedFlip,
		color: "#00a84d",
		title: "VOLUME",
		value: "0",
		raise: false,
		change: "0%",
		className: "row1NetRevenue",
	},
];

const overallFilterOptions: IMnAFilterConfigurations = {
	businessUnit: ["BISCUITS", "SAVORY"],
	country: ["MEXICO"],
	dateTimePeriod: ["12MM", "3MM", "6MM"],
	endTimePeriod: ["Aug2024", "Jul2024", "Jun2024", "May2024", "Apr2024", "Mar2024", "Feb2024"],
	channel: ["C-STORE", "DTS", "SMKT", "WHSL"],
	region: ["CENTRO", "NOROESTE", "NORTE", "OCCIDENTE", "SURESTE", "VALLE"],
	storeSegment: ["A.MEXSUR/TLAHUA", "ACAMBARO"],
	mixScenario: ["demo-assortment1", "demo-assortment2"],
	assortmentScenario: ["demo-assortment1", "demo-assortment2"],
	geoSummary: {
		segment: ["CKY-BFY-SWEET", "CKY-CHOC CHIP", "CKY-CHOC COVER", "CKY-FILLED"],
		brand: ["ARCOIRIS", "BOMBITOS", "CHOCOLATINES", "CHOKIS", "CHOKIS MIX"],
		sku: ["ARI REG_MARSHMLW CKY_MARSHMLW_037GM_5PK", "ARI REG_MARSHMLW CKY_MARSHMLW_055GM_1PK", "ARI REG_MARSHMLW CKY_MARSHMLW_075GM_1PK"],
	},
};

const mockPortfolioData = [
	{
		uniqueId: "1",
		segments: "CKY-BFY-SWEET",
		brand: "QUAKER GALLETAS",
		subBrand: "ALL",
		packSize: "ALL",
		avgStorePenetration: "19.481104455243266",
		sku: "ALL",
		flavor: "ALL",
		checkoutSku: "No",
		mixScore: "48.400001525878906",
		volumeMixResult: "48",
		revenueMixResult: "49",
		profitMixResult: "49",
		revenuMix: "2.44324612176325",
		assortmentOverall: "0",
		netRevenueSOP: "0",
		nonPBT_SOP: "0",
		priceScore: "41.52399548635285",
		shelfAndMerchandizingScore: "22.547614139165667",
		executionScore: "12.814322116051981",
		mustHaveDistributionScore: "0",
		goodToHaveDistributionScore: "0",
		priceChangeVsRestOfTheMarketScore: "33.28176864587497",
		netPriceRealisationScore: "47.12891009252224",
		priceChangeVsInflationScore: "32.84137299986765",
		exhibitionScore: "20.071869245824608",
		shelfSpaceScore: "25.023359032506725",
	},
	{
		uniqueId: "2",
		segments: "CKY-CHOC CHIP",
		brand: "CHOKIS",
		subBrand: "ALL",
		packSize: "ALL",
		avgStorePenetration: "27.32975478550036",
		sku: "ALL",
		flavor: "ALL",
		checkoutSku: "No",
		mixScore: "76.20000457763672",
		volumeMixResult: "76",
		revenueMixResult: "77",
		profitMixResult: "76",
		revenuMix: "6.819000970845804",
		assortmentOverall: "0",
		netRevenueSOP: "0",
		nonPBT_SOP: "0",
		priceScore: "43.8337510770266",
		shelfAndMerchandizingScore: "37.28779547508597",
		executionScore: "16.224309552183563",
		mustHaveDistributionScore: "0",
		goodToHaveDistributionScore: "0",
		priceChangeVsRestOfTheMarketScore: "29.011336999114125",
		netPriceRealisationScore: "51.765957581524724",
		priceChangeVsInflationScore: "36.80893883158835",
		exhibitionScore: "31.47203294739111",
		shelfSpaceScore: "43.10355800278083",
	},
	{
		uniqueId: "11",
		segments: "CKY-LOW-END",
		brand: "GAMESA TRAD OTHER LE",
		subBrand: "ALL",
		packSize: "ALL",
		avgStorePenetration: "32.302808416000076",
		sku: "ALL",
		flavor: "ALL",
		checkoutSku: "No",
		mixScore: "79",
		volumeMixResult: "79",
		revenueMixResult: "79",
		profitMixResult: "79",
		revenuMix: "1.015176116180599",
		assortmentOverall: "99.4593919031438",
		netRevenueSOP: "2470.9231452725057",
		nonPBT_SOP: "327.7385141972243",
		priceScore: "23.205822525314865",
		shelfAndMerchandizingScore: "57.54994643752582",
		executionScore: "55.934911387320774",
		mustHaveDistributionScore: "99.47575749205814",
		goodToHaveDistributionScore: "99.421205531178",
		priceChangeVsRestOfTheMarketScore: "39.90289558044279",
		netPriceRealisationScore: "12.338100672223508",
		priceChangeVsInflationScore: "38.848251341021324",
		exhibitionScore: "27.64267555488028",
		shelfSpaceScore: "87.45721732017135",
	},
	{
		uniqueId: "12",
		segments: "CKY-LOW-END",
		brand: "MARINAS",
		subBrand: "ALL",
		packSize: "ALL",
		avgStorePenetration: "5.84122290838588",
		sku: "ALL",
		flavor: "ALL",
		checkoutSku: "No",
		mixScore: "0",
		volumeMixResult: "0",
		revenueMixResult: "0",
		profitMixResult: "0",
		revenuMix: "0.008712862142918132",
		assortmentOverall: "0",
		netRevenueSOP: "0",
		nonPBT_SOP: "0",
		priceScore: "38.872043088469425",
		shelfAndMerchandizingScore: "0",
		executionScore: "7.774408733541601",
		mustHaveDistributionScore: "0",
		goodToHaveDistributionScore: "0",
		priceChangeVsRestOfTheMarketScore: "20.024095441679886",
		netPriceRealisationScore: "51.17980349633786",
		priceChangeVsInflationScore: "21.054238563547322",
		exhibitionScore: "0",
		shelfSpaceScore: "0",
	},
	{
		uniqueId: "13",
		segments: "CKY-MARIAS",
		brand: "GAMESA MARIAS",
		subBrand: "ALL",
		packSize: "ALL",
		avgStorePenetration: "29.830713304042057",
		sku: "ALL",
		flavor: "ALL",
		checkoutSku: "No",
		mixScore: "100",
		volumeMixResult: "100",
		revenueMixResult: "100",
		profitMixResult: "100",
		revenuMix: "20.692916435926143",
		assortmentOverall: "86.17413102164352",
		netRevenueSOP: "17112997.689210664",
		nonPBT_SOP: "1763880.5576054533",
		priceScore: "30.959890041616923",
		shelfAndMerchandizingScore: "30.867652280420668",
		executionScore: "46.8351615709632",
		mustHaveDistributionScore: "85.6260758731774",
		goodToHaveDistributionScore: "87.45292629547198",
		priceChangeVsRestOfTheMarketScore: "33.62371438929149",
		netPriceRealisationScore: "28.65230111652645",
		priceChangeVsInflationScore: "35.7505325210954",
		exhibitionScore: "26.315267862433494",
		shelfSpaceScore: "35.42003669840784",
	},
	{
		uniqueId: "14",
		segments: "CKY-MARIAS",
		brand: "GAMESA MARIAS",
		subBrand: "ALL",
		packSize: "ALL",
		avgStorePenetration: "25.567609556367515",
		sku: "ALL",
		flavor: "ALL",
		checkoutSku: "YES",
		mixScore: "100",
		volumeMixResult: "100",
		revenueMixResult: "100",
		profitMixResult: "100",
		revenuMix: "0.24992801216503663",
		assortmentOverall: "41.03400198214135",
		netRevenueSOP: "638063.0666765453",
		nonPBT_SOP: "-76309.47261435378",
		priceScore: "8.726229478136716",
		shelfAndMerchandizingScore: "45.70834601033837",
		executionScore: "27.300516297360943",
		mustHaveDistributionScore: "40.37383580328598",
		goodToHaveDistributionScore: "42.57438964536171",
		priceChangeVsRestOfTheMarketScore: "21.428112682588274",
		netPriceRealisationScore: "0",
		priceChangeVsInflationScore: "22.461341157397474",
		exhibitionScore: "66.27106051141683",
		shelfSpaceScore: "25.1456315092599",
	},
	{
		uniqueId: "15",
		segments: "CKY-MARSHMLW",
		brand: "ARCOIRIS",
		subBrand: "ALL",
		packSize: "ALL",
		avgStorePenetration: "36.53271572974223",
		sku: "ALL",
		flavor: "ALL",
		checkoutSku: "No",
		mixScore: "40",
		volumeMixResult: "40",
		revenueMixResult: "40",
		profitMixResult: "40",
		revenuMix: "2.2169542938993883",
		assortmentOverall: "80.19491619623903",
		netRevenueSOP: "3892435.8151942487",
		nonPBT_SOP: "1035339.0064156369",
		priceScore: "46.07090134525646",
		shelfAndMerchandizingScore: "41.73099514336559",
		executionScore: "49.63834651588902",
		mustHaveDistributionScore: "74.41421539784378",
		goodToHaveDistributionScore: "93.68321729347994",
		priceChangeVsRestOfTheMarketScore: "35.011372505074554",
		netPriceRealisationScore: "52.287088705608554",
		priceChangeVsInflationScore: "39.63869008490899",
		exhibitionScore: "32.83476815727369",
		shelfSpaceScore: "50.62722212945748",
	},
	{
		uniqueId: "16",
		segments: "CKY-MARSHMLW",
		brand: "FRUITS",
		subBrand: "ALL",
		packSize: "ALL",
		avgStorePenetration: "41.74788463338647",
		sku: "ALL",
		flavor: "ALL",
		checkoutSku: "No",
		mixScore: "80",
		volumeMixResult: "80",
		revenueMixResult: "80",
		profitMixResult: "80",
		revenuMix: "2.0433380324531116",
		assortmentOverall: "57.932093383881366",
		netRevenueSOP: "6289442.4563877275",
		nonPBT_SOP: "809275.9567997868",
		priceScore: "43.76191200477321",
		shelfAndMerchandizingScore: "54.943597172099686",
		executionScore: "42.913939828394646",
		mustHaveDistributionScore: "59.657623862320804",
		goodToHaveDistributionScore: "53.90585582941074",
		priceChangeVsRestOfTheMarketScore: "28.723134231613095",
		netPriceRealisationScore: "50.32381945157857",
		priceChangeVsInflationScore: "42.57890214879604",
		exhibitionScore: "74.84168429804264",
		shelfSpaceScore: "35.04551004615673",
	},
];

const dataObject = {
	sizeOfPrizeRadio,
	KPIData,
	geoFilter,
	overallFilters,
	decesionFilter,
	portfolioFilter,
	geoSummaryFilter,
	geoSummaryFilterOrder,
	geoLevelFilterData,
	portfolioLevelFilterData,
	overallFilterOptions,
	analysisFilterOrder,
	switchingTableDefaultFilters,
	geoFilterOrder,
	decesionFilterOrder,
	portfolioFilterOrder,
	mockSimulationData,
	mockOpportunitiesData,
	mockRestOverviewData,
	mockPortfolioData,
};

export default dataObject;
