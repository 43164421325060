import { Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { BlackTitle, PrimaryBtn } from "../../../../styles/Common.Styled";
import { IconDownload } from "../../../../assets/icons/mna";
import AssortmentSKUTable from "./AssortmentSKUTable/AssortmentSKUTable";
import { columnsToDowloadListTableSKU } from "./AssortmentSKUTable/TableConfig";
import { convertToCsvWithKeys, downloadInCsvFormat } from "../../../../util/mixAndAssortmentServices";
import { IAssortmentListTableData } from "../../../../types/mixAndAssortment";

const AssortmentList: FC<{
	assortmentListTableData: IAssortmentListTableData[];
	chartSkeleton?: boolean;
}> = ({ assortmentListTableData, chartSkeleton = true }) => {
	const [uniqueAssortmentListData, setUniqueAssortmentListData] = useState<IAssortmentListTableData[]>([]);
	useEffect(() => {
		const dataWithId = assortmentListTableData
			.map((row, index) => ({
				id: index, // Use the index as a unique id
				...row,
			}))
			.sort((a, b) => {
				if (a.rank > b.rank) {
					return 1;
				} else return -1;
			});
		setUniqueAssortmentListData(dataWithId);
	}, [assortmentListTableData]);

	const downloadAssortmentListData = () => {
		const keysArray = columnsToDowloadListTableSKU.map((column) => column.field);
		const csvData = convertToCsvWithKeys(uniqueAssortmentListData, keysArray, columnsToDowloadListTableSKU);
		downloadInCsvFormat(csvData, "Assortment_SKU_Table_Data");
	};

	return (
		<Card style={{ marginTop: 24 }}>
			<CardContent>
				{chartSkeleton ? (
					<Grid item xs={12}>
						<Skeleton variant="rectangular" height={350} />
					</Grid>
				) : (
					<>
						<Stack direction={"row"} sx={{ alignItems: "center" }}>
							<Grid container sx={{ alignItems: "center" }}>
								<Grid item xs={12} className="m-b-10" style={{ marginTop: "10px", marginLeft: "16px" }}>
									<BlackTitle style={{ fontSize: 20, fontWeight: 600 }}>Assortment List</BlackTitle>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item style={{ marginLeft: "auto" }}>
									<PrimaryBtn onClick={downloadAssortmentListData}>
										<Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
											<IconDownload />
											<Grid marginLeft={"5px"}>Download</Grid>
										</Grid>
									</PrimaryBtn>
								</Grid>
							</Grid>
						</Stack>
						<Grid container>
							<Grid item sm={12} marginLeft={"16px"}>
								<AssortmentSKUTable assortmentListTableData={uniqueAssortmentListData} />
							</Grid>
						</Grid>
					</>
				)}
			</CardContent>
		</Card>
	);
};

export default AssortmentList;
