import { Card } from "@mui/material";
import React, { Dispatch, FC, SetStateAction } from "react";
import ScenarioLibrary from "../../../components/ScenarioLibrary";
import { useDispatch } from "react-redux";
import { getScenarioDetailData } from "../../../store/actions/MnADashboards/assortment.action";
import { loader } from "../../../store/actions/common.action";
import { useHistory, useLocation } from "react-router-dom";

const AssortmentScenario: FC<{ setShowScenarioLibrary: Dispatch<SetStateAction<boolean>>; setIsNewScenario: Dispatch<SetStateAction<boolean>> }> = ({
	setShowScenarioLibrary,
	setIsNewScenario,
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const useQuery = () => new URLSearchParams(location.search);
	const query = useQuery();

	const newScenario = () => {
		query.set("mode", "new");
		history.push({ search: query.toString() });
		setIsNewScenario(true);
		setShowScenarioLibrary(false);
	};

	const viewScenario = (id: string) => {
		query.set("scenario", id);
		query.set("mode", "read");
		history.push({ search: query.toString() });
		setIsNewScenario(false);
		setShowScenarioLibrary(false);
		dispatch(getScenarioDetailData(id, true));
		dispatch(loader(true));
	};

	const editScenario = (id: string) => {
		query.set("scenario", id);
		query.set("mode", "edit");
		history.push({ search: query.toString() });
		setIsNewScenario(false);
		setShowScenarioLibrary(false);
		dispatch(getScenarioDetailData(id, false));
	};
	return (
		<Card className="m-b-20">
			<ScenarioLibrary type={"Assortment"} newScenario={newScenario} viewScenario={viewScenario} editScenario={editScenario} />
		</Card>
	);
};
export default AssortmentScenario;
