import { styled } from "@mui/material/styles";
import { ListItemIcon, ListItem, List, Typography } from "@mui/material";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import MoreVert from "@material-ui/icons/MoreVert";

export const MuiListItemIcon = styled(ListItemIcon)`
	transform: scale(0.7);
	min-width: 30px;
	color: #75b6e1;
`;

export const UpArrow = styled(IconExpandMore)(({ theme }) => ({
	transform: "scale(0.8)",
	color: theme.palette.mode === "dark" ? theme.palette.common.black : theme.palette.common.white,
}));

export const DownArrow = styled(IconExpandLess)(({ theme }) => ({
	transform: "scale(0.8)",
	color: theme.palette.mode === "dark" ? theme.palette.common.black : theme.palette.common.white,
}));

export const DrawerListItem = styled(ListItem)`
	white-space: pre-wrap;
	cursor: pointer;
`;

export const DrawerList = styled(List)`
	::-webkit-scrollbar-thumb {
		background: #e80e0ee0 !important;
	}
`;
export const MoreVertListItemIcon = styled(MoreVert)`
	color: #75b6e1;
`;

export const MenuItemText = styled(Typography)(({ theme }) => ({
	color: theme.palette.mode === "dark" ? theme.palette.common.black : theme.palette.common.white,
}));
