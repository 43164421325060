import React from "react";
import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import HighchartContainer from "../../../../components/HighchartContainer";
import CircularProgressBar from "../../../../components/CircularProgressBar/CircularProgressBar";

const formatKey = (key: string) => {
	const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);
	const knownAbbreviations = ["SKUs"];
	if (knownAbbreviations.some((abbr) => key.includes(abbr))) {
		return key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
	}
	const words = key
		.replace(/([a-z])([A-Z])/g, "$1 $2")
		.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
		.split(" ");
	return words.map((word, idx) => (idx === 0 ? capitalize(word) : word.toLowerCase())).join(" ");
};

const cardColor = (score: number) => {
	let color = "";
	let bgColor = "";
	if (score <= 40) {
		color = "#FA4242";
		bgColor = "#FEEEEE";
	}
	if (score > 40 && score < 75) {
		color = "#F8BD0E";
		bgColor = "#f8bd0e1a";
	}
	if (score >= 75) {
		color = "#27AF8D";
		bgColor = "#16c79a1a";
	}
	return { color: color, cardColor: bgColor };
};

const SimulationCard: React.FC<{ data; chartSkeleton?: boolean }> = ({ data, chartSkeleton }) => {
	const sections = Object.keys(data).filter((key) => key !== "overallScore" && key != "currentAverage");
	return !chartSkeleton ? (
		<>
			<Grid item sm={6}>
				<HighchartContainer id={1}>
					<CircularProgressBar data={""} chartRef={null} />
				</HighchartContainer>
			</Grid>
			<Grid container spacing={2}>
				{sections.map((sectionKey) => {
					const sectionData = data[sectionKey];
					const filteredData = Object.entries(sectionData).filter(([key]) => key !== "overallScore");
					const cardStyle = cardColor(Number(sectionData.overallScore));
					return (
						<Grid item xs={12} md={6} key={sectionKey}>
							<Paper elevation={24} style={{ padding: "16px", height: "170px", background: cardStyle.cardColor }}>
								<Stack marginBottom={2} direction="row" alignItems="center" justifyContent="space-between">
									<Typography fontSize={16} color="#000000">
										{sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)}
									</Typography>
									<Typography color={cardStyle.color}>{Number(sectionData.overallScore).toFixed(1)}/100</Typography>
								</Stack>
								{filteredData.map(([key, value]) => (
									<Stack direction="row" justifyContent="space-between">
										<Typography fontSize={14} color="#7E7E7E" key={key}>
											{formatKey(key)}
										</Typography>
										<Typography fontSize={14} color="#000000" key={`${key}-value`}>
											{key !== "status" ? Number(value).toFixed(1) : value}
										</Typography>
									</Stack>
								))}
							</Paper>
						</Grid>
					);
				})}
			</Grid>
		</>
	) : (
		<>
			<Grid item sm={6}>
				<Skeleton variant="rectangular" width={400} height={330} />
			</Grid>
			<Grid container spacing={2}>
				{[0, 1, 2].map((card) => {
					return (
						<Grid item xs={12} key={card}>
							<Skeleton variant="rectangular" height={100} />
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

export default SimulationCard;
