import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { useSelector } from "react-redux";

const Logout: React.FC<{ text: string; onClick }> = ({ text, onClick }) => {
	const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);

	const { oktaAuth } = useOktaAuth();
	const onButtonClick = async () => {
		oktaAuth.signOut();
		onClick();
	};

	return (
		<Button onClick={onButtonClick} autoFocus>
			<Typography color={theme.darkmode ? "#214e96" : "#858C94"}>{text}</Typography>
		</Button>
	);
};

export default Logout;
