import React from "react";
import CategoryDashboardList from "../../components/CategoryDashboardList/CategoryDashboardList";
import PageContainer from "../../components/PageContainer";
import { pageList, pageNames } from "../../mocks/common";

const PriceArchitecture: React.FC = () => {
	return (
		<PageContainer page={pageNames.priceArchitecture}>
			<CategoryDashboardList title={"Pricing & Pack Price Architecture"} categoryKey={"price_architecture"} pageName={pageList[0].value} />
		</PageContainer>
	);
};
export default PriceArchitecture;
