import { PriceLadderSRPPayload, IFilterPayload } from "../../types/common";

/* Creates a GraphQL query to price ladder dashboard srp based on filter values.
 * @param {PriceLadderSRPPayload} payload - The object contains price ladder srp request payload.
 * @returns The GraphQL query.
 */
export const PRICE_LADDER_SRP_QUERY = (payload: PriceLadderSRPPayload) => {
	return {
		query: `query {
      priceLadderSRP(
        country: "${payload.country}"
        geoLevel: ${payload.geoLevel ? JSON.stringify(payload.geoLevel) : null}
        geoLevel2: ${payload.geoLevel2 ? JSON.stringify(payload.geoLevel2) : null}
        channel: ${payload.channel ? JSON.stringify(payload.channel) : null}
        periodView: "${payload.periodView}"
        somValueType: "${payload.somValueType}"
        somValueBy: "${payload.somValueBy}"
        apiFilter: "${payload.apiFilter}"
        measuresFilterSOP: "${payload.measuresFilterSOP}"
        markup: "${payload.markup}"
        taxes: "${payload.taxes}"
        priceInterval: "${payload.priceInterval}"
        priceLadderBy: "${payload.priceLadderBy}"
        comparisonLevel: "${payload.comparisonLevel}"
        pepsi: {
          category: ${payload.pepsi.category ? JSON.stringify(payload.pepsi.category) : null}
          segment: ${payload.pepsi.segment ? JSON.stringify(payload.pepsi.segment) : null}
          brand: ${payload.pepsi.brand ? JSON.stringify(payload.pepsi.brand) : null}
          ${payload.pepsi.subBrand ? `subBrand: ${JSON.stringify(payload.pepsi.subBrand)}` : ""}
          packSize: ${payload.pepsi.packSize ? JSON.stringify(payload.pepsi.packSize) : null}
          permutationComputation: ${payload.pepsi.permutationComputation ? JSON.stringify(payload.pepsi.permutationComputation) : null}
          vendor: ${payload.pepsi.vendor ? JSON.stringify(payload.pepsi.vendor) : null}
        }
        competitor: {
          vendor: ${payload.competitor.vendor ? JSON.stringify(payload.competitor.vendor) : null}
          category: ${payload.competitor.category ? JSON.stringify(payload.competitor.category) : null}
          segment: ${payload.competitor.segment ? JSON.stringify(payload.competitor.segment) : null}
          brand: ${payload.competitor.brand ? JSON.stringify(payload.competitor.brand) : null}
          ${payload.competitor.subBrand ? `subBrand: ${JSON.stringify(payload.competitor.subBrand)}` : ""}
          packSize: ${payload.competitor.packSize ? JSON.stringify(payload.competitor.packSize) : null}
          permutationComputation: ${payload.competitor.permutationComputation ? JSON.stringify(payload.competitor.permutationComputation) : null}
        }
        sellInStartDate: "${payload.sellInStartDate}"
        sellInEndDate: "${payload.sellInEndDate}"
        sellOutStartDate: "${payload.sellOutStartDate}"
        sellOutEndDate: "${payload.sellOutEndDate}"
      ) {
        mainCompetitorGraph {
          competitorProducts: CompetitorProducts {
            api
            id
            name
            vendor
            logo
            packsize
            brandsize
            gr
            ppv
            ppvpp
            sop
            img
            ispepsico
            srp
            srpsop
            som
          }
          pepsiProducts: PepsiProducts {
            id
            name
            vendor
            logo
            packsize
            brandsize
            gr
            ppv
            ppvpp
            sop
            img
            ispepsico
            srp
            srpsop
            som
            api {
              key
              value
              sop
              gr
              ppv
            }
          }
        }
        vendors: Vendors {
          vendor: Vendor
          logo: Logo
          selected: Selected
          total: Total
          selectedpp: Selectedpp
          totalpp: Totalpp
        }
        graphData {
          srp
          sompepsico
          sompepsicopp
          somcompetitor
          grcompetitor
          somcompetitorpp
          products {
            id
            name
            vendor
            logo
            packsize
            brandsize
            gr
            ppv
            ppvpp
            sop
            img
            ispepsico
            srp
            srpsop
            som
          }
        }
      }
    }`,
	};
};

/* Creates a GraphQL query to get common anchor product filters for Price Ladder dashboard.
 * @param {IFilterPayload} payload - The object contains filter request payload.
 * @returns The GraphQL query.
 */
export const PRICE_LADDER_PRODUCT_FILTER_QUERY = (payload: IFilterPayload, userId: string) => {
	return {
		query: `{
      priceladderFilter(country: "${payload.country}"
      UserId: "${userId}"
			)
		}`,
	};
};
