import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
	asterik: { color: "red" },

	inputRoot: {
		'& input[type="number"]': {
			"-moz-appearance": "textfield",
			"&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
				"-webkit-appearance": "none",
				margin: 0,
			},
			"&:hover::-webkit-inner-spin-button, &:hover::-webkit-outer-spin-button": {
				backgroundColor: "none",
			},
		},
	},
	input: {
		display: "flex",
		alignItems: "center",
		paddingRight: theme.spacing(0),
	},
	adornment: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "21px",
	},
	arrowButton: {
		padding: "0",
		borderRadius: "0",
		backgroundColor: "#F1F1F1",
		height: "15px",
		minWidth: "15px",
		"&:hover": {
			backgroundColor: "#e0e0e0",
		},
	},
	arrowIcon: {
		width: "18px",
	},
}));

const TextFieldNumber = ({ defaultValue, callback, disabled = false }) => {
	const classes = useStyles();
	const [value, setValue] = useState(defaultValue || 0);
	const [displayAdornmentFlag, setDisplayAdornmentFlag] = useState(false);

	const handleInputChange = (event) => {
		const newValue = parseFloat(event.target.value);
		if (newValue >= 0 && newValue <= 100) {
			setValue(newValue);
			callback(newValue);
		} else if (Number.isNaN(newValue)) {
			setValue(null);
			callback(null);
		} else if (newValue <= 0) {
			callback(0);
		}
	};

	const increaseValue = () => {
		const newValue = Number(value) + 1;
		if (newValue <= 100) {
			setValue(String(newValue));
			callback(newValue);
		}
	};

	const decreaseValue = () => {
		const newValue = Number(value) - 1;
		if (newValue >= 0) {
			setValue(String(newValue));
			callback(newValue);
		}
	};

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	return (
		<>
			<TextField
				type="text"
				value={value}
				onChange={handleInputChange}
				className={classes.inputRoot}
				onMouseEnter={() => {
					setDisplayAdornmentFlag(true);
				}}
				onMouseLeave={() => {
					setDisplayAdornmentFlag(false);
				}}
				InputProps={{
					inputProps: { min: 0, max: 100 },
					endAdornment: displayAdornmentFlag && !disabled && (
						<Grid className={classes.adornment} style={{ pointerEvents: disabled ? "none" : "auto" }}>
							<Button className={classes.arrowButton} onClick={increaseValue}>
								<ArrowDropUpIcon className={classes.arrowIcon} />
							</Button>
							<Button className={classes.arrowButton} onClick={decreaseValue}>
								<ArrowDropDownIcon className={classes.arrowIcon} />
							</Button>
						</Grid>
					),
				}}
				disabled={disabled}
				autoComplete="off"
			/>
		</>
	);
};

export default TextFieldNumber;
