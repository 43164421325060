import React, { FC, useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import dataObject from "../../../../mocks/revenueMap";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup/RadioButtonGroup";
import { IMeasureFilterConfigs, IVariableConfig } from "../../../../types/revenueMap";
import { IKeyValueConfiguration } from "../../../../types/common";

const MeasureFilters: FC<{ onChange: (data: IKeyValueConfiguration[]) => void; selectedMeasureFilters: IMeasureFilterConfigs }> = ({
	onChange,
	selectedMeasureFilters,
}) => {
	const [varXVariableConfigurations, setVarXVariableConfigurations] = useState<IVariableConfig[]>([]);
	const [varXMeasureConfigurations, setVarXMeasureConfigurations] = useState<IVariableConfig[]>([]);
	const [varYVariableConfigurations, setVarYVariableConfigurations] = useState<IVariableConfig[]>([]);
	const [varYMeasureConfigurations, setVarYMeasureConfigurations] = useState<IVariableConfig[]>([]);

	const onChangeViewX = (value: string) => {
		const varConfigurationList = dataObject.variableConfiguration.filter((data) => data.kpi.indexOf(value) !== -1);
		setVarXVariableConfigurations(varConfigurationList);
		const measureConfigurationList = dataObject.measureConfiguration.filter((data) => data.kpi.indexOf(value) !== -1);
		setVarXMeasureConfigurations(measureConfigurationList);
	};

	const onChangeViewY = (value: string) => {
		const varConfigurationList = dataObject.variableConfiguration.filter((data) => data.kpi.indexOf(value) !== -1);
		setVarYVariableConfigurations(varConfigurationList);
		const measureConfigurationList = dataObject.measureConfiguration.filter((data) => data.kpi.indexOf(value) !== -1);
		setVarYMeasureConfigurations(measureConfigurationList);
	};

	useEffect(() => {
		if (selectedMeasureFilters.viewX && selectedMeasureFilters.viewY) {
			onChangeViewX(selectedMeasureFilters.viewX);
			onChangeViewY(selectedMeasureFilters.viewY);
		} else {
			if (!selectedMeasureFilters.viewX) {
				setVarXVariableConfigurations([]);
				setVarXMeasureConfigurations([]);
			}
			if (!selectedMeasureFilters.viewY) {
				setVarYVariableConfigurations([]);
				setVarYMeasureConfigurations([]);
			}
		}
	}, [selectedMeasureFilters.viewX, selectedMeasureFilters.viewY]);

	useEffect(() => {
		const updatedVairableConfig: IKeyValueConfiguration[] = [
			{
				label: "xSOMGroup",
				value: varXVariableConfigurations.length ? varXVariableConfigurations[0].value : "",
			},
			{
				label: "xSOMMeasure",
				value: varXMeasureConfigurations.length ? varXMeasureConfigurations[0].value : "",
			},
		];
		onChange(updatedVairableConfig);
	}, [varXMeasureConfigurations, varXVariableConfigurations]);

	useEffect(() => {
		const updatedVairableConfig: IKeyValueConfiguration[] = [
			{
				label: "ySOMGroup",
				value: varYVariableConfigurations.length ? varYVariableConfigurations[0].value : "",
			},
			{
				label: "ySOMMeasure",
				value: varYMeasureConfigurations.length ? varYMeasureConfigurations[0].value : "",
			},
		];
		onChange(updatedVairableConfig);
	}, [varYMeasureConfigurations, varYVariableConfigurations]);

	return (
		<Grid container className="m-b-20" spacing={2} columns={17}>
			<Grid item container className="m-b-20" spacing={2} columns={17}>
				<Grid item>
					<Typography variant="subtitle2">Var Top&nbsp;</Typography>
					<Grid display="flex" alignItems="center">
						<TextField
							type="number"
							id="view-top"
							onChange={(e) => {
								onChange([{ label: "viewTop", value: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) }]);
							}}
							value={selectedMeasureFilters.viewTop}
							InputProps={{ inputProps: { min: "0", step: "1" }, autoComplete: "off" }}
						/>
						<span style={{ marginLeft: 10 }}>
							<b>Note:</b> Selecting more than 30 in the Var Top field, will impact the dashboard performance and the quality of the visualization.
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container className="m-b-20" spacing={2} columns={17}>
				<Grid item xs={12} sm={2}>
					<DropdownTitle>View X</DropdownTitle>
					<Dropdown
						data={dataObject.variableList}
						disabled={false}
						onChange={(data) => {
							onChangeViewX(data[0]);
							onChange([{ label: "viewX", value: data[0] }]);
						}}
						defaultOption={selectedMeasureFilters.viewX || ["empty"]}
					/>
				</Grid>
				{varXVariableConfigurations.length > 0 && (
					<Grid item xs={12} sm={6}>
						<DropdownTitle>Variables Configuration</DropdownTitle>
						<Grid container>
							<Grid className={`m-r-10 ${varXMeasureConfigurations.length > 0 ? "b-r-1" : ""}`}>
								<RadioButtonGroup
									data={varXVariableConfigurations}
									color="#000"
									direction="row"
									select={(data) => onChange([{ label: "xSOMGroup", value: data }])}
									defaultOption={selectedMeasureFilters.xSOMGroup}
								/>
							</Grid>
							{varXMeasureConfigurations.length > 0 && (
								<Grid>
									<RadioButtonGroup
										color="#000"
										data={varXMeasureConfigurations}
										select={(data) => onChange([{ label: "xSOMMeasure", value: data }])}
										direction="row"
										defaultOption={selectedMeasureFilters.xSOMMeasure}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid item container className="m-b-20" spacing={2} columns={17}>
				<Grid item xs={12} sm={2}>
					<DropdownTitle>View Y</DropdownTitle>
					<Dropdown
						data={dataObject.variableList}
						disabled={false}
						onChange={(data) => {
							onChangeViewY(data[0]);
							onChange([{ label: "viewY", value: data[0] }]);
						}}
						defaultOption={selectedMeasureFilters.viewY || ["empty"]}
					/>
				</Grid>
				{varYVariableConfigurations.length > 0 && (
					<Grid item xs={12} sm={6}>
						<DropdownTitle>Variables Configuration</DropdownTitle>
						<Grid container>
							<Grid className={`m-r-10 ${varYMeasureConfigurations.length > 0 ? "b-r-1" : ""}`}>
								<RadioButtonGroup
									data={varYVariableConfigurations}
									color="#000"
									direction="row"
									select={(data) => onChange([{ label: "ySOMGroup", value: data }])}
									defaultOption={selectedMeasureFilters.ySOMGroup}
								/>
							</Grid>
							{varYMeasureConfigurations.length > 0 && (
								<Grid>
									<RadioButtonGroup
										color="#000"
										data={varYMeasureConfigurations}
										select={(data) => onChange([{ label: "ySOMMeasure", value: data }])}
										direction="row"
										defaultOption={selectedMeasureFilters.ySOMMeasure}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid item container className="m-b-20" spacing={2} columns={17}>
				<Grid item xs={12} sm={2}>
					<DropdownTitle>View Size</DropdownTitle>
					<Dropdown
						data={dataObject.viewSize}
						disabled={false}
						onChange={(data) => {
							onChange([{ label: "viewSize", value: data[0] }]);
						}}
						defaultOption={selectedMeasureFilters.viewSize || ["empty"]}
						search={{ enable: false }}
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<DropdownTitle>View Type</DropdownTitle>

					<Dropdown
						data={dataObject.viewType}
						disabled={false}
						onChange={(data) => {
							onChange([{ label: "viewType", value: data[0] }]);
						}}
						defaultOption={selectedMeasureFilters.viewType || ["empty"]}
						search={{ enable: false }}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default MeasureFilters;
