import { ELASTICITY_COEFFICIENT } from "./constants";

let intiState = { elasticityCoefficient: false };

const SupermarketPepsicoBaseline = (state = intiState, action) => {
	if (action.type === ELASTICITY_COEFFICIENT) return { ...state, elasticityCoefficient: action.payload };
	else return state;
};

export default SupermarketPepsicoBaseline;
