import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import Dropdown from "../Dropdown";
import { DropdownTitle } from "../DashboardFilters/DashboardFilters.Styled";
import { travelChild } from "../../util/helper";
import { IKeyValueConfiguration, IProductFilterConfigurations } from "../../types/common";
import _ from "lodash";
import { useSelector } from "react-redux";

const CustomDropDownTitle: React.FC<{ title: string }> = ({ title }) => {
	return <DropdownTitle dangerouslySetInnerHTML={{ __html: title }} />;
};

function GenerateFilterOptionValues(filterConfiguration: IProductFilterConfigurations[], filterConfig, key, itemKey) {
	const optionValues: IKeyValueConfiguration[] = [...new Set(filterConfiguration.map((item) => item[key]))];
	const optionObject: IKeyValueConfiguration[] = optionValues.filter((optionObject: IKeyValueConfiguration, index: number, self: IKeyValueConfiguration[]) => {
		const itemIndex: number = self.findIndex((item) => item.label === optionObject.label && item.value === optionObject.value);
		return index === itemIndex;
	});
	if (filterConfig[itemKey]) {
		filterConfig[itemKey].options = optionObject;
	}
	return filterConfig;
}

function MapFilterOptions(filterConfiguration: IProductFilterConfigurations[], filterConfig, selectedFilters, filterOrder, isAnchor: boolean) {
	filterOrder.map((orderConfiguration) => {
		const itemKey: string = isAnchor ? "anchor" + orderConfiguration.key.charAt(0).toUpperCase() + orderConfiguration.key.slice(1) : orderConfiguration.key;
		filterConfig = GenerateFilterOptionValues(filterConfiguration, filterConfig, orderConfiguration.key, itemKey);
		filterConfiguration = filterConfiguration.filter(
			(productFilter: IProductFilterConfigurations) =>
				!selectedFilters[itemKey] || selectedFilters[itemKey].findIndex((value: string) => value === productFilter[orderConfiguration.key].value) !== -1
		);
	});
	return filterConfig;
}

function SliceSelectedFilterData(
	filterConfiguration: IProductFilterConfigurations[],
	filterConfig,
	isAnchor: boolean,
	selectedValues,
	selectedKey,
	filterOrder
) {
	selectedKey = isAnchor ? selectedKey.charAt(6).toLowerCase() + selectedKey.slice(7) : selectedKey;
	const selectedFilterKeyOrder: number = filterOrder.filter((object) => object.key === selectedKey)[0].order;
	if (isAnchor) {
		filterOrder.map((orderConfiguration) => {
			const itemKey: string = "anchor" + orderConfiguration.key.charAt(0).toUpperCase() + orderConfiguration.key.slice(1);
			filterConfiguration = filterConfiguration.filter(
				(productFilter: IProductFilterConfigurations) =>
					!selectedValues[itemKey] || selectedValues[itemKey].findIndex((value: string) => value === productFilter[orderConfiguration.key].value) !== -1
			);
		});
	} else {
		filterOrder.map((orderConfiguration) => {
			filterConfiguration = filterConfiguration.filter(
				(productFilter: IProductFilterConfigurations) =>
					!selectedValues[orderConfiguration.key] ||
					selectedValues[orderConfiguration.key].findIndex((value: string) => value === productFilter[orderConfiguration.key].value) !== -1
			);
		});
	}

	Object.keys(filterConfiguration[0]).map((key) => {
		const itemKey: string = isAnchor ? "anchor" + key.charAt(0).toUpperCase() + key.slice(1) : key;
		if (filterConfig[itemKey]) {
			const currentFilterKeyOrder: number = filterOrder.filter((object) => object.key === key)[0].order;
			const optionValues: IKeyValueConfiguration[] = [...new Set(filterConfiguration.map((item) => item[key]))];
			const optionObject: IKeyValueConfiguration[] = optionValues.filter(
				(optionObject: IKeyValueConfiguration, index: number, self: IKeyValueConfiguration[]) => {
					const itemIndex: number = self.findIndex((item) => item.label === optionObject.label && item.value === optionObject.value);
					return index === itemIndex;
				}
			);

			if (currentFilterKeyOrder > selectedFilterKeyOrder) {
				filterConfig[itemKey].options = optionObject;
			}
		}
	});
	return filterConfig;
}

function SetDisableFilterPopup(disableFilters, selectedKey, isAnchor, filterOrder) {
	selectedKey = isAnchor ? selectedKey.charAt(6).toLowerCase() + selectedKey.slice(7) : selectedKey;
	const selectedFilterKeyOrder: number = filterOrder.filter((object) => object.key === selectedKey)[0].order;
	filterOrder.map((object) => {
		const itemKey: string = isAnchor ? "anchor" + object.key.charAt(0).toUpperCase() + object.key.slice(1) : object.key;
		if (object.order <= selectedFilterKeyOrder + 1) {
			disableFilters[itemKey] = false;
		} else {
			disableFilters[itemKey] = true;
		}
	});
	return disableFilters;
}

const CommonProductFilters: React.FC<{
	filterData;
	data;
	onChange?;
	showSkeleton?: boolean;
	defaultFilters?;
	isAnchorFlag;
	filterOrder;
	isFirstLoadFlag?;
}> = ({ filterData, data, onChange, showSkeleton = false, defaultFilters, isAnchorFlag = false, filterOrder, isFirstLoadFlag }) => {
	const userDetail = useSelector((state: any) => state.User.data);
	const [filterConfig, setFilterConfig] = useState({});
	const [filterInitialState, setFilterInitialState] = useState({});
	const [disableInitialState, setDisableInitialState] = useState({});

	const [selectedFilters, setSelectedFilters] = useState(defaultFilters ?? {});
	const [disableFilters, setDisableFilters] = useState({});
	useEffect(() => {
		if (data && userDetail?.id) {
			Object.keys(data).map((key) => {
				filterInitialState[key] = null;
				disableInitialState[key] = true;
			});
			setFilterInitialState({ ...filterInitialState });
			setDisableInitialState({ ...disableInitialState });
			setDisableFilters({ ...disableInitialState });
			setFilterConfig({ ...data });
		}
	}, [data, userDetail]);

	useEffect(() => {
		if (filterData && filterData.length > 0) {
			let filterConfiguration = { ...data };
			let selectedFilterValues = Object.keys(selectedFilters).length > 0 ? selectedFilters : filterInitialState;
			filterConfiguration = MapFilterOptions(filterData, filterConfiguration, selectedFilterValues, filterOrder, isAnchorFlag);
			const firstKey: string = filterOrder.filter((object) => object.order === 1)[0].key;
			const itemKey: string = isAnchorFlag ? "anchor" + firstKey.charAt(0).toUpperCase() + firstKey.slice(1) : firstKey;
			setDisableInitialState({ ...disableInitialState, [itemKey]: false });
			setDisableFilters({ ...disableFilters, [itemKey]: false });
			setFilterConfig(filterConfiguration);
		}
	}, [filterData, selectedFilters]);

	const onChangeFilter = (key, value) => {
		const result = travelChild(filterConfig, key, value, { ...selectedFilters }, { ...disableFilters });
		const selectedValues = { ...result.selectedValues, [key]: value };
		setSelectedFilters(selectedValues);
		if (value && value.length > 0) {
			if (!filterConfig[key].last && ((_.isArray(value) && value.length > 0) || (_.isString(value) && value !== null))) {
				let filterConfiguration = { ...filterConfig };
				filterConfiguration = SliceSelectedFilterData(filterData, filterConfiguration, isAnchorFlag, selectedValues, key, filterOrder);
				setFilterConfig(filterConfiguration);
				const disableFilterConfiguration = SetDisableFilterPopup(disableFilters, key, isAnchorFlag, filterOrder);
				setDisableFilters(disableFilterConfiguration);
			} else {
				setDisableFilters({ ...result.disableFilters });
			}
		} else {
			setDisableFilters({ ...result.disableFilters });
		}
		onChange(selectedValues);
	};

	useEffect(() => {
		if (defaultFilters && _.isObject(defaultFilters) && Object.keys(defaultFilters).length > 0) {
			setSelectedFilters(defaultFilters);
			if (isFirstLoadFlag) {
				Object.keys(data).map((key) => {
					disableInitialState[key] = disableInitialState[key] && !defaultFilters[key];
				});
				setDisableFilters(disableInitialState);
			}
		} else {
			setSelectedFilters({});
			let disableStates = {};
			Object.keys(data).map((key) => {
				disableStates[key] = true;
				setDisableFilters({ ...disableStates });
			});
		}
	}, [defaultFilters, filterData]);
	return (
		<Grid>
			{!showSkeleton ? (
				<Grid container spacing={2} columns={Object.keys(filterConfig).length} className="m-b-20">
					{Object.keys(filterConfig).map((key) => (
						<Grid item xs={12} sm={1} key={filterConfig[key].title}>
							<CustomDropDownTitle title={filterConfig[key].title} />
							<Dropdown
								disabled={disableFilters[key] || filterConfig[key].disabled}
								data={filterConfig[key].options}
								onChange={(data) => onChangeFilter(key, data)}
								defaultOption={(selectedFilters && selectedFilters[key]) || ["empty"]}
								placeholder={filterConfig[key].placeholder}
								multiple={filterConfig[key].multiple}
								allOption={filterConfig[key].all}
								selectionLimit={filterConfig[key].selectionLimit ? filterConfig[key].selectionLimit : null}
							/>
						</Grid>
					))}
				</Grid>
			) : (
				<Grid container spacing={2} columns={Object.keys(filterConfig).length} className="m-b-20">
					{[...Array(Object.keys(filterConfig).length).keys()].map((i) => (
						<Grid item xs={12} sm={1} key={`filter-${i}-skeleton`}>
							<Skeleton height={22} />
							<Skeleton variant="rectangular" height={45} />
						</Grid>
					))}
				</Grid>
			)}
		</Grid>
	);
};

export default CommonProductFilters;
