import { GET_FAVORITE_DASH, POST_FAVORITE, POST_FAVORITE_FAILURE } from "../../actions/YourDashboard/constant";

let favListInitialState = {
	data: null,
	error: null,
	loader: false,
};

const favoriteDashList = (state = favListInitialState, action) => {
	switch (action.type) {
		case GET_FAVORITE_DASH:
			return {
				...state,
				data: action.payload,
			};
		case POST_FAVORITE:
			return {
				...state,
				loader: true,
				error: null,
			};
		case POST_FAVORITE_FAILURE:
			return {
				...state,
				loader: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default favoriteDashList;
