/* Creates a GraphQL query to get list of scenarios created for mix and assortment page.
 * @param {string} dashboardName - The page name.
 * @returns The GraphQL query.
 */
export const GET_SCENARIO_LIBRARY_QUERY = (dashboardName: string) => {
	return {
		query: `query {
  getModelScenarioLibrary(dashboardName: "${dashboardName}") {
    scenarioName,
    createdTimeStamp,
    createdBy,
    lastUpdatedBy,
    lastUpdatedTimeStamp,
    status
  }
}`,
	};
};

/* Creates a GraphQL query to delete scenario from scenario list table.
 * @param {string} name - The scenario name.
 * @param {string} dashboardName - The page name.
 * @returns The GraphQL query.
 */
export const DELETE_SCENARIO_QUERY = (name: string, dashboardName: string) => {
	return {
		query: `mutation {
    deleteScenario(scenarioName: "${name}", dashboardName: "${dashboardName}") {
        message
        status
    }
}`,
	};
};

/* Creates a GraphQL query to fetch product filters for scenarios.
 * @param {string} dashboardName - The page name.
 * @returns The GraphQL query.
 */
export const MNA_PRODUCT_FILTER_QUERY = (dashboardName: string) => {
	return {
		query: `query {
  getAssortmentProductFilter(dashboardName: "${dashboardName}") {
    country,
    dateTimePeriod,
    businessUnit,
    endTimePeriod
  }
}`,
	};
};

/* Creates a GraphQL query to fetch data sources for scenarios.
 * @param {string} dashboardName - The page name.
 * @returns The GraphQL query.
 */
export const MNA_DATASOURCE_QUERY = (dashboardName: string) => {
	return {
		query: `query {
  getDashboardDataSources(dashboardName: "${dashboardName}") {
    sourceType
    sourceName
  }
}`,
	};
};
