import React, { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorIcon from "@material-ui/icons/Error";
import { useDispatch, useSelector } from "react-redux";
import { dialog } from "../../store/actions/dialog.action";
import { Button, DialogActions, DialogContent, DialogContentText, Grid, Typography } from "@mui/material";

const DialogBox: FC = () => {
	//type= success | error | warning | info
	const dispatch = useDispatch();
	const dialogStore = useSelector((state: any) => state.dialog);

	const handleClose = () => {
		dispatch(dialog("", "", true, false));
	};

	return (
		<Dialog open={dialogStore.open} onClose={handleClose}>
			<DialogTitle style={{ padding: "0 20px" }}>
				<Grid style={{ alignItems: "center", borderBottom: "1px solid #333333", display: "flex", justifyContent: "center", padding: "10px 0" }}>
					<ErrorIcon htmlColor="#d32f2f" />{" "}
					<Typography color="#d32f2f" marginLeft="4px">
						Error
					</Typography>
				</Grid>
			</DialogTitle>
			<DialogContent style={{ padding: "10px 24px 0" }}>
				<DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
					{dialogStore.title && <Typography fontWeight={600}>{dialogStore.title}</Typography>}
					<Typography fontWeight={400}>{dialogStore.msg}</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} autoFocus>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogBox;
