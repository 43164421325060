import {
	SIMULATION_DATA,
	SIMULATION_VALUE,
	CORRELATION_CHART_DATA,
	CORRELATION_TABLE_DATA,
	OPTIMIZATION_CHART_DATA,
	G5_CHART_DATA,
	MULTIPLE_API_DATA,
} from "./constants";

let initialState = {
	correlationChartDataLoader: false,
	correlationTableDataLoader: false,
	optimizationChartData: false,
	g5ChartData: false,
	simulationDataLoader: false,
	simulationValueLoader: false,
	multipleAPIDataLoader: false,
};

const ApiPredictive = (state = initialState, action) => {
	switch (action.type) {
		case CORRELATION_CHART_DATA:
			return {
				...state,
				correlationChartDataLoader: action.payload,
			};
		case CORRELATION_TABLE_DATA:
			return {
				...state,
				correlationTableDataLoader: action.payload,
			};
		case OPTIMIZATION_CHART_DATA:
			return {
				...state,
				optimizationChartData: action.payload,
			};
		case G5_CHART_DATA:
			return {
				...state,
				g5ChartData: action.payload,
			};
		case SIMULATION_DATA:
			return {
				...state,
				simulationDataLoader: action.payload,
			};
		case SIMULATION_VALUE:
			return {
				...state,
				simulationValueLoader: action.payload,
			};
		case MULTIPLE_API_DATA:
			return {
				...state,
				multipleAPIDataLoader: action.payload,
			};

		default:
			return state;
	}
};

export default ApiPredictive;
