import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import Dropdown from "../../../../components/Dropdown";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { IconDownload } from "../../../../assets/icons/mna";
import { IMnAFilterConfigurations, ISwitchingoGeoSummaryFilterConfig } from "../../../../types/mixAndAssortment";
import dataObject from "../../../../mocks/executionTracker";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import SwitchingSKUTable from "../SwitchingSKUTable/SwitchingSKUTable";
import { portfolioColumns } from "../SwitchingSKUTable/TableConfig";

const disableApplyBtn = (selectedFilters: IMnAFilterConfigurations) => {
	let flag: boolean = true;
	if (
		selectedFilters.assortmentScenario?.length &&
		selectedFilters.businessUnit.length &&
		selectedFilters.channel.length &&
		selectedFilters.country.length &&
		selectedFilters.dateTimePeriod.length &&
		selectedFilters.mixScenario?.length &&
		selectedFilters.region.length &&
		selectedFilters.storeSegment.length &&
		selectedFilters.geoSummary?.level &&
		selectedFilters.geoSummary?.segment.length &&
		selectedFilters.geoSummary?.brand.length &&
		selectedFilters.geoSummary?.sku.length
	)
		flag = false;
	return flag;
};
const GeoLevelSummary: React.FC<{ selectedFilters: IMnAFilterConfigurations; setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>> }> = ({
	selectedFilters,
	setSelectedFilters,
}) => {
	const [geoFilter, setGeoFilter] = useState<ISwitchingoGeoSummaryFilterConfig>({
		level: "",
		segment: [],
		brand: [],
		sku: [],
	});
	const onChangeGeoFilter = (key, value) => {
		const selectedGeoValues = { ...geoFilter, [key]: value };
		const selectedValues = { ...selectedFilters, geoSummary: selectedGeoValues };
		setGeoFilter(selectedGeoValues);
		setSelectedFilters(selectedValues);
	};
	const [skeleton, setSkeleton] = useState<boolean>(true);

	const applyFilter = () => {
		setSkeleton(false);
	};

	const clearFilter = () => {
		const defaultFilters = {
			level: "",
			segment: [],
			brand: [],
			sku: [],
		};
		setGeoFilter(defaultFilters);
		const selectedValues = { ...selectedFilters, geoSummary: defaultFilters };
		setSelectedFilters(selectedValues);
		setSkeleton(true);
	};
	return (
		<>
			<Card>
				<CardContent>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Title className="ellipsis">Geo Level Summary</Title>
						<Stack direction="row" alignItems="center" spacing={2}>
							<Stack direction="row" alignItems="center" spacing={1}>
								<DropdownTitle>Level:</DropdownTitle>
								<Grid item sm={3}>
									<Dropdown
										keyText="table-sub-heading"
										data={dataObject.geoLevelFilterData}
										multiple={false}
										onChange={(data) => onChangeGeoFilter("level", data[0])}
										placeholder="Level"
										defaultOption={selectedFilters.level}
									/>
								</Grid>
							</Stack>
							<Grid container>
								<Grid item style={{ marginLeft: "auto" }}>
									<PrimaryBtn onClick={() => {}} disabled={disableApplyBtn(selectedFilters)}>
										<Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
											<IconDownload />
											<Grid marginLeft={"5px"}>Download CSV</Grid>
										</Grid>
									</PrimaryBtn>
								</Grid>
							</Grid>
						</Stack>
					</Stack>
					<Stack direction="row" className="m-t-20 m-l-20">
						<Box width="700px">
							<CommonMnAFilters
								filterData={dataObject.overallFilterOptions.geoSummary}
								onChange={onChangeGeoFilter}
								data={dataObject.geoSummaryFilter}
								defaultFilters={selectedFilters.geoSummary ?? null}
								filterOrder={dataObject.geoSummaryFilterOrder}
							/>
						</Box>
						<Grid className="p-l-16" marginBottom={5} mt={3}>
							<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
								Clear Filter
							</OrangeBtn>
							<PrimaryBtn color="primary" onClick={applyFilter} disabled={disableApplyBtn(selectedFilters)}>
								Apply Filter
							</PrimaryBtn>
						</Grid>
					</Stack>
					{!skeleton ? (
						<Grid container>
							<Grid item sm={12} marginLeft={"16px"}>
								<SwitchingSKUTable tableData={dataObject.mockPortfolioData} tableColumns={portfolioColumns} />
							</Grid>
						</Grid>
					) : (
						<Grid item xs={12}>
							<Skeleton variant="rectangular" height={250} />
						</Grid>
					)}
				</CardContent>
			</Card>
		</>
	);
};

export default GeoLevelSummary;
