import React, { useState } from "react";
import { CardContent, Grid, Typography, Card } from "@mui/material";
import { Title, CancelButton, SaveButton, CustomInputFieldWithBorder } from "../../MMPW.Styled";

const EditLine: React.FC<{ data; callback }> = ({ data, callback }) => {
	const [lineWidth, setLineWidth] = useState(data.length > 0 ? data[0].lineWidth : 0);

	const onChangeLineData = (val) => {
		setLineWidth(val);
	};

	const onSave = () => {
		callback({ type: "LineWidth", payload: { lineWidth: parseInt(lineWidth) } });
	};

	const onCancel = () => {
		callback({});
	};

	return (
		<Card>
			<CardContent>
				<Grid item xs={12} display="flex" justifyContent="flex-end">
					<SaveButton onClick={onSave}>Save</SaveButton>
					<CancelButton onClick={onCancel}>Cancel</CancelButton>
				</Grid>
				<Grid item xs={12} className="m-b-20">
					<Title>Edit Line</Title>
					<hr />
				</Grid>
				<Grid item container xs={12} display="flex" justifyContent="flex-center">
					<Grid item sm={10}>
						<Typography color="black">Line Width</Typography>
						<CustomInputFieldWithBorder
							id="width"
							type="text"
							margin="normal"
							variant="outlined"
							value={lineWidth}
							onChange={(val) => onChangeLineData(val.target.value)}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default EditLine;
