import { Box, Grid, Skeleton, TableBody, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { NoData, StyledTableRow } from "../../../../../../styles/Common.Styled";
import { messages } from "../../../../../../util/config";
import dataObject from "../../../../../../mocks/elasticityPricingTrack";
import { getMeasureFilterData } from "../../../../service";
import { StyledTable, StyledTableCell } from "./AdjustableMeasureFilters.Styled";
import RadioButtonGroup from "../../../../../../components/UI-components/RadioButtonGroup";
import { fetchImageURL } from "../../../../../../util/services";
const AdjustableMeasureFilters: React.FC<{
	filters?;
	callback;
	defaultFilters;
	clearFilter;
	showLoader;
}> = ({ filters, callback, defaultFilters, clearFilter, showLoader }) => {
	const [tableMainHeading, setTableMainHeading] = useState([]);
	const [tableValues, setTableValues] = useState([1]);
	const [tableSkeleton, setTableSkeleton] = useState(true);
	const [tempFilters, setTempFilters] = useState({});
	const [originalResponse, setOriginalResponse] = useState([]);

	useEffect(() => {
		if (clearFilter) {
			setTableValues([]);
			setOriginalResponse([]);
			setTableSkeleton(true);
			setTempFilters({});
		}
	}, [clearFilter]);

	const getTableData = async (payload) => {
		showLoader(true);
		getMeasureFilterData(payload)
			.then((response) => {
				if (_.isArray(response.data) && response.data.length > 0) {
					setOriginalResponse(response.data);
				} else {
					setTableSkeleton(false);
					showLoader(false);
				}
			})
			.catch((e) => {
				setTableSkeleton(false);
				showLoader(false);
			});
	};

	useEffect(() => {
		if (originalResponse && originalResponse.length > 0) {
			formatData(originalResponse);
		}
	}, [originalResponse]);

	const renderControls = (cellData, row, column, columnName) => {
		if (cellData.type === "Text") {
			return (
				<TextField
					InputProps={{ autoComplete: "off" }}
					id="proposed-price"
					type="number"
					size="small"
					inputProps={{ step: 0.01 }}
					value={_.isNumber(cellData.value) ? cellData.value.toFixed(2) : cellData.value}
					onKeyDown={(e: any) => {
						if (e.keyCode === 13) {
							calculateValues(tableValues);
						}
					}}
					onChange={(e) => {
						const { value } = e.target;
						if (value.match(/\./g)) {
							const [, decimal] = value.split(".");
							if (decimal?.length > 2) {
								return;
							}
						}
						const newData = [...tableValues];
						newData[row][columnName].value = value;
						setTableValues(newData);
						calculateValues(newData);
					}}
					style={{ width: cellData.width ? cellData.width : 90 }}
				/>
			);
		} else if (cellData.type === "Radio") {
			return (
				<RadioButtonGroup
					key={`table-radio-options-${row}`}
					color="#000"
					showButton={true}
					data={cellData.options}
					defaultOption={cellData.value ? cellData.value : cellData.defaultOption.value}
					select={(e) => {
						const newData = [...tableValues];
						newData[row][columnName].value = e;
						setTableValues(newData);
						formatValues(newData);
					}}
				/>
			);
		}
	};

	const calculateValues = (tableValues, pricingDate?) => {
		const newData = [...tableValues];
		_.isArray(newData) &&
			newData.forEach((item) => {
				item.pricePerUnitVar.value = item.pricePerUnitFrom.value === 0 ? 0 : (item.pricePerUnitTo.value / item.pricePerUnitFrom.value - 1) * 100;
				item.gramPerUnitVar.value = item.gramPerUnitFrom.value === 0 ? 0 : (item.gramPerUnitTo.value / item.gramPerUnitFrom.value - 1) * 100;
			});
		setTableValues(newData);
		formatValues(newData, pricingDate);
		setTableSkeleton(false);
		showLoader(false);
	};

	const formatValues = (tableValues, pricingDate?) => {
		const rows = [];
		let measureDate: string = "";
		tableValues.map((item) => {
			originalResponse.findIndex((measure: any) => {
				if (measure.productInfo === item.productInfo.value) measureDate = measure.date;
			});
			let row = {};
			Object.entries(item).map(([key, val]) => {
				row = {
					...row,
					[key]: val.editable && val.type === "Text" ? parseFloat(val.value) : val.value,
					date: pricingDate || measureDate,
				};
			});
			rows.push(row);
			measureDate = "";
		});
		const payload = [];
		originalResponse.map((item, i) => {
			const index = rows.findIndex((x) => x.productInfo === item.productInfo);
			delete item.image;
			if (index !== -1) {
				payload.push({ ...item, ...rows[index] });
			}
		});
		callback({ tableData: payload, date: pricingDate || filters.date });
	};

	const fetchImage = (payload, name) => {
		const imgSrc = fetchImageURL(payload.country, payload.alt);
		if (imgSrc) {
			return (
				<Tooltip disableHoverListener={!payload.tooltip} title={payload.tooltip}>
					<div>
						<img src={imgSrc} height={payload.height} width={payload.width} alt={payload.alt} />
						{payload.tooltip ? <div style={{ color: "#929292" }}>{payload.tooltip}</div> : null}
					</div>
				</Tooltip>
			);
		} else {
			return (
				<Tooltip disableHoverListener={!payload.tooltip} title={payload.tooltip}>
					<Box className="img-error-box">
						<span>{name}</span>
						{payload.tooltip ? <div style={{ color: "#929292" }}>{payload.tooltip}</div> : null}
					</Box>
				</Tooltip>
			);
		}
	};

	const formatData = (data) => {
		if (_.isArray(data) && data.length > 0) {
			const rows: any = [];
			Promise.all(
				data.map((item) => {
					item.image = {
						name: item.subBrandName,
						alt: item.subBrandName,
						height: 100,
						width: 100,
						tooltip: item.displayName,
						country: filters.country,
					};
				})
			)
				.then(() => {
					let pricingDate;
					data = _.orderBy(data, "productInfo");
					data.map((item, i) => {
						let row = {};
						if (i === 0) {
							pricingDate = item.date;
						}
						Object.entries(dataObject.measureTable1SubHeadings).map(([key, config]) => {
							row = {
								...row,
								[key]: {
									...config,
									value: getValue(item, key),
									...(config?.image && item.image ? { image: item.image } : {}),
									key,
								},
							};
						});
						rows.push(row);
					});
					setTableSkeleton(false);
					calculateValues(rows, pricingDate);
				})
				.catch((e) => {});
		}
	};

	const getValue = (item, key) => {
		if (_.isArray(defaultFilters) && defaultFilters.length > 0) {
			const index = defaultFilters.findIndex((x) => x.productInfo === item.productInfo);

			if (index !== -1) {
				return defaultFilters[index][key];
			}
		} else return item[key];
	};

	useEffect(() => {
		if (filters && filters.country && JSON.stringify(filters) !== JSON.stringify(tempFilters)) {
			setTableSkeleton(true);
			setTempFilters({ ...filters });
			setTableValues([]);
			setOriginalResponse([]);
			if (filters.dataSource && filters.category && filters.packSize && filters.date) {
				getTableData({ ...filters });
			}
			if (filters.dataSource === "sellOut") {
				const tableHeading = _.cloneDeep(dataObject.measureTable1Headings);
				tableHeading[1].label = "EDRP";
				setTableMainHeading([...tableHeading]);
			} else {
				setTableMainHeading([...dataObject.measureTable1Headings]);
			}
		}
	}, [filters]);

	return (
		<TableContainer
			sx={{
				maxHeight: 600,
				maxWidth: "87vw",
				"&::-webkit-scrollbar": {
					height: 8,
				},
			}}
		>
			<StyledTable stickyHeader>
				{!tableSkeleton ? (
					<>
						{tableValues.length > 0 ? (
							<>
								<TableHead style={{ position: "sticky", top: 0, zIndex: 3 }}>
									<TableRow>
										{tableMainHeading.map((item, i) => (
											<StyledTableCell
												className="b-color"
												align="center"
												style={{
													color: "white",
													backgroundColor: "#005EA6",
													width: item.width ? item.width : null,
												}}
												colSpan={item.colspan ? item.colspan : null}
												rowSpan={item.rowspan ? item.rowspan : null}
												key={`table-head-cell-${i}`}
											>
												<div style={{ width: item.width ? item.width : null }}>
													<Tooltip title={item.label}>
														<Grid
															style={{
																width: "100%",
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
															}}
														>
															{item.label}
														</Grid>
													</Tooltip>
												</div>
											</StyledTableCell>
										))}
									</TableRow>
									<TableRow>
										{Object.keys(dataObject.measureTable1SubHeadings).map((key, i) => {
											const item = dataObject.measureTable1SubHeadings[key];
											return item.label ? (
												<StyledTableCell
													align="center"
													data-testid="tableHeader"
													style={{
														backgroundColor: "#0093CD",
														color: "white",
														whiteSpace: "nowrap",
														width: item.width ? item.width : null,
													}}
													key={`table-subhead-cell-${i}`}
												>
													<div style={{ width: item.width ? item.width : null }}>
														<Tooltip title={item.label}>
															<Grid
																style={{
																	width: "100%",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<span className="ellipsis">{item.label}</span>
															</Grid>
														</Tooltip>
													</div>
												</StyledTableCell>
											) : null;
										})}
									</TableRow>
								</TableHead>
								<TableBody>
									{tableValues.map((row, i) => (
										<StyledTableRow key={`table-row-${i}`} data-testid="tableBodyRows">
											{Object.keys(row).map((key, j) => {
												let cellData = row[key];
												return cellData.type !== "none" ? (
													<StyledTableCell align="center" rowSpan={cellData?.rowspan ? cellData.rowspan : null} key={`table-row-${i}-cell-${j}`}>
														{_.isObject(cellData)
															? cellData.editable
																? renderControls(cellData, i, j, key)
																: cellData.image
																? fetchImage(cellData.image, cellData.name)
																: `${cellData.value.toFixed(2)}${cellData.prepend ? cellData.prepend : ""}`
															: cellData}
													</StyledTableCell>
												) : null;
											})}
										</StyledTableRow>
									))}
								</TableBody>
							</>
						) : (
							<Box style={{ height: 400 }} display="flex" justifyContent="center" alignItems="center">
								<NoData>{messages.noData}</NoData>
							</Box>
						)}
					</>
				) : (
					<>
						<TableHead>
							<TableRow>
								{tableMainHeading.map((item, i) => (
									<StyledTableCell align="center" colSpan={item.colspan} key={`table-head-cell-${i}-skeleton`}>
										<Skeleton />
									</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{[...Array(5).keys()].map((i) => (
								<TableRow key={`table-row-${i}-skeleton`}>
									{[...Array(11).keys()].map((j) => (
										<StyledTableCell align="center" key={`table-row-${i}-cell-${j}-skeleton`}>
											<Skeleton />
										</StyledTableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</>
				)}
			</StyledTable>
		</TableContainer>
	);
};
export default AdjustableMeasureFilters;
