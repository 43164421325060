export const chartConfig = {
	chart: {
		type: "column",
		height: 650,
	},
	credits: {
		enabled: false,
	},
	title: {
		text: null,
		align: "left",
	},
	xAxis: {
		labels: {
			rotation: -45,
		},
	},
	yAxis: {
		min: 0,
		title: {
			enabled: false,
			text: "Count trophies",
		},
		stackLabels: {
			enabled: false,
			style: {
				fontWeight: "bold",
				color: "gray",
				textOutline: "none",
			},
		},
	},
	legend: {
		align: "right",
		verticalAlign: "top",
		backgroundColor: "white",
		borderColor: "#CCC",
		borderWidth: 1,
		shadow: false,
		symbolWidth: 16,
		symbolRadius: 0,
		squareSymbol: false,
		maxWidth: 200,
		itemWidth: 120,
		itemMarginBottom: 5,
	},
	plotOptions: {
		column: {
			stacking: "normal",
			dataLabels: {
				enabled: true,
			},
		},
		series: {
			pointPadding: 0.01, // Controls the padding between points in the same series
			groupPadding: 0.05, // Controls the padding between groups of points across different series
			borderWidth: 0, // Removes the border around each column
			pointWidth: null, // Allows automatic calculation of point width
			maxPointWidth: 45, // Removes any restriction on maximum width
		},
	},
	series: [],
	navigation: {
		buttonOptions: {
			enabled: false,
		},
	},
	tooltip: {
		useHTML: true,
		formatter: function () {
			// This will be overridden
			return "";
		},
		headerFormatter: function () {
			// This will be overridden
			return "";
		},
		pointFormat: '<div id="hc-tooltip"></div>',
	},
};
