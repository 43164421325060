/* Creates a GraphQL query for getting user(s) data.
 * @param {number} userId - The user's id.
 * @param {string} emailId - The user's email id.
 * @param {number} pageNumber - The page number for loading a list of users with pagination.
 * @param {number} pageSize - The number of entries on the page for loading a list of users.
 * @functionality -
 * If userId is provided, it will return the user details for the given id.
 * If emailId is provided, it will return the user details for the given email id.
 * If none of the above parameters are provided, it will return the list of all users.
 * @returns The GraphQL query.
 */
export const GET_USER_QUERY = (userId = 0, emailId = "", country = "") => {
	let queryFilters = "";
	if (userId) {
		queryFilters = `(Id: ${userId})`;
	} else if (emailId) {
		queryFilters = `(EmailAddress: "${emailId}")`;
	} else if (country) {
		queryFilters = `(PermissionCountry: ${country})`;
	}
	return {
		query: `{ getUser ${queryFilters}
      {
        id: Id
        firstName: FirstName
        lastName: LastName
        emailAddress: EmailAddress
        phoneNumberCountryCode: PhoneNumberCountryCode
        phoneNumber: PhoneNumber
        address: Address
        role: Role
        defaultCountry: DefaultCountry
        defaultCategory: DefaultCategory
        permissionCountry: PermissionCountry
        permissionCategory: PermissionCategory
        imageUrl: ImageUrl
        isDarkMode: IsDarkMode
        country: Country
        isAdmin: IsAdmin
      }
    }`,
	};
};

/* Creates a GraphQL mutation query for creating or updating a user.
 * @param user - The user details.
 * @returns The GraphQL mutation query.
 */
export const CREATE_UPDATE_USER_QUERY = (user, currentUserId) => {
	return {
		query: `mutation {
      updateusers (
        ${user.id ? `Id: ${user.id}` : ""}
        FirstName: "${user.firstName}"
        LastName: "${user.lastName}"
        EmailAddress: "${user.emailAddress}"
        Country: "${user.country}"
        Role: "${user.role}"
        DefaultCountry: "${user.defaultCountry}"
        DefaultCategory: "${user.defaultCategory}"
        PermissionCountry: "${user.permissionCountry}"
        PermissionCategory: "${user.permissionCategory}"
        IsDarkMode: "${user.isDarkMode || false}"
        IsAdmin: "${user.role.toLowerCase() === "admin"}"
        PhoneNumberCountryCode: ${user.phoneNumberCountryCode ? `"${user.phoneNumberCountryCode}"` : null}
        PhoneNumber: ${user.phoneNumber ? `"${user.phoneNumber}"` : null}
        Address: ${user.address ? `"${user.address}"` : null}
        ImageUrl: ${user.imageUrl ? `"${user.imageUrl}"` : null}
        UserId: "${currentUserId}"
      ) {
        FirstName
        LastName
        EmailAddress
        PhoneNumberCountryCode
        PhoneNumber
        Address
        Role
        DefaultCountry
        DefaultCategory
        PermissionCountry
        PermissionCategory
        ImageUrl
        IsDarkMode
        Country
        IsAdmin
      } }`,
	};
};

/* Creates a GraphQL mutation query for deleting a user.
 * @param {string} id - The id of the user to be deleted.
 * @param {string} userId - The user deleting the user. Only user with Admin role can delete.
 * @returns The GraphQL mutation query.
 */
export const DELETE_USER_QUERY = (id: string, userId: string) => {
	return {
		query: `mutation { deleteUser ( Id: ${id}, UserId: ${userId} ) }`,
	};
};

/* Creates a GraphQL mutation query for switching theme.
 * @param {boolean} isDarkMode - The flag for dark mode.
 * @param {string} userId - The user id.
 * @returns The GraphQL mutation query.
 */
export const UPDATE_DARK_MODE = (isDarkMode: boolean, userId: number) => {
	return {
		query: `mutation {
  updateisdarkmode(IsDarkMode: "${isDarkMode}", UserId: "${userId}")
}`,
	};
};
