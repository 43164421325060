import React, { FC, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid } from "@mui/material";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import _ from "lodash";
import Indicator from "../../../../components/Loader";
import FilterAccordion from "../../../../components/Filters";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import { dataFormat } from "../../../../util/helper";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import { fetchGeoFiltersData } from "../../../../store/actions/geoFilter.action";
import MeasureFilters from "../MeasureFilters";
import { clearProductFilters } from "../../../../store/actions/productFilter.action";
import { periodView, revenueMapProductFilterOrder } from "../../../../mocks/revenueMap";
import { defaultCalendarDates } from "../../../../util/config";
import { initialState } from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import Dropdown from "../../../../components/Dropdown";
import CustomDateRangePicker from "../../../../components/UI-components/DateRangePicker";
import { DateTime } from "luxon";
import { fetchFiltersData, fetchPeriodViewDates } from "../../../../util/services";
import { REVENUE_MAP_PRODUCT_FILTER_QUERY } from "../../../../util/queries/revenueMap";
import CommonProductFilters from "../../../../components/CommonProductFilters";
import dataObject from "../../../../mocks/revenueMap";
import { IKeyValueConfiguration, IProductFilterConfigurations } from "../../../../types/common";
import { dialog } from "../../../../store/actions/dialog.action";
import { IMeasureFilterConfigs, IRevenueMapFilterConfigurations, IRevenueMapProductFilters } from "../../../../types/revenueMap";

const TopFilters: FC<{ callback: (filters: IRevenueMapFilterConfigurations) => Promise<void>; defaultFilters: IRevenueMapFilterConfigurations }> = ({
	callback,
	defaultFilters,
}) => {
	const dispatch = useDispatch();
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const geoFilter = useSelector((state: RootStateOrAny) => state.geoFilter);
	const commonLoader = useSelector((state: RootStateOrAny) => state.common.loader);
	const RevenueMap = useSelector((state: RootStateOrAny) => state.RevenueMap);
	const [loader, setLoader] = useState(false);
	const [selectedProductFilters, setSelectedProductFilters] = useState<IRevenueMapProductFilters>({
		category: [],
		vendor: [],
		segment: [],
		brand: [],
		subBrand: [],
		packSize: [],
	});
	const [isCountryManuallyChangedFlag, setIsCountryManuallyChangedFlag] = useState(false);
	const [selectedMeasureFilters, setSelectedMeasureFilters] = useState<IMeasureFilterConfigs>({
		viewTop: 5,
		data: "",
		viewX: "",
		xSOMGroup: "",
		xSOMMeasure: "",
		viewY: "",
		ySOMGroup: "",
		ySOMMeasure: "",
		viewSize: "",
		viewType: "",
	});
	const [isFirstLoadPepsiFlag, setIsFirstLoadPepsiFlag] = useState(true);
	const [selectedDate, setSelectedDate] = useState<string[] | Date[]>([defaultCalendarDates.startDate, defaultCalendarDates.endDate]);
	const [productFilterOptions, setProductFilterOptions] = useState<IProductFilterConfigurations[]>([]);
	const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
	const [clearGeoFilterFlag, setClearGeoFilterFlag] = useState(false);

	useEffect(() => {
		if (defaultFilters?.date) {
			setSelectedDate(defaultFilters.date.split("-"));
		}
	}, [defaultFilters]);

	const onApplyFilter = () => {
		const payload = {
			...selectedGeoFilters,
			...selectedMeasureFilters,
			...selectedProductFilters,
			date: `${dataFormat(selectedDate[0], "MM/dd/yyyy")}-${dataFormat(selectedDate[1], "MM/dd/yyyy")}`,
			startDate: selectedDate[0],
			endDate: selectedDate[1],
		};
		callback(payload);
	};

	const clearFilter = () => {
		setClearGeoFilterFlag(true);
		dispatch(clearProductFilters());
		setSelectedGeoFilters({ ...initialState });
		setSelectedProductFilters({
			category: [],
			vendor: [],
			segment: [],
			brand: [],
			subBrand: [],
			packSize: [],
		});
		setProductFilterOptions([]);
		setSelectedMeasureFilters({
			viewTop: 5,
			data: "",
			viewX: "",
			xSOMGroup: "",
			xSOMMeasure: "",
			viewY: "",
			ySOMGroup: "",
			ySOMMeasure: "",
			viewSize: "",
			viewType: "",
		});
	};

	useEffect(() => {
		if (userData && userData.id) {
			dispatch(fetchGeoFiltersData(userData.id, "Revenue"));
		}
	}, [userData]);

	const onChangeMeasureFilter = (e: IKeyValueConfiguration[]) => {
		const selectedMeasures = { ...selectedMeasureFilters };
		e.forEach((item) => {
			selectedMeasures[item.label] = item.value;
		});
		setSelectedMeasureFilters(selectedMeasures);
	};

	const disableApplyFilter = () => {
		const flag: boolean =
			geoFilter.loading ||
			!selectedGeoFilters.country ||
			!selectedGeoFilters.geoLevel2 ||
			!selectedMeasureFilters?.viewTop ||
			!selectedMeasureFilters?.viewSize ||
			!selectedMeasureFilters?.viewX ||
			!selectedMeasureFilters?.viewY ||
			_.isEmpty(selectedProductFilters.category) ||
			!selectedMeasureFilters?.viewType;
		return flag;
	};

	useEffect(() => {
		if (defaultFilters && defaultFilters?.country) {
			setSelectedGeoFilters({
				country: defaultFilters.country,
				geoLevel: defaultFilters.geoLevel,
				geoLevel2: defaultFilters.geoLevel2,
				channel: defaultFilters.channel,
				periodView: defaultFilters.periodView,
			});

			setSelectedMeasureFilters({
				viewTop: defaultFilters.viewTop,
				data: defaultFilters.data,
				viewX: defaultFilters.viewX,
				xSOMGroup: defaultFilters?.xSOMGroup ?? "",
				xSOMMeasure: defaultFilters?.xSOMMeasure ?? "",
				viewY: defaultFilters.viewY,
				ySOMGroup: defaultFilters?.ySOMGroup ?? "",
				ySOMMeasure: defaultFilters?.ySOMMeasure ?? "",
				viewSize: defaultFilters.viewSize,
				viewType: defaultFilters.viewType,
			});
			setSelectedProductFilters({
				...selectedProductFilters,
				category: defaultFilters.category,
				vendor: defaultFilters.vendor,
				segment: defaultFilters.segment,
				brand: defaultFilters.brand,
				subBrand: defaultFilters.subBrand,
				packSize: defaultFilters.packSize,
			});
		}
	}, [defaultFilters]);

	const OnProductFiltersChange = (data) => {
		setSelectedProductFilters({ ...data });
		setClearGeoFilterFlag(false);
	};

	const setSelectedGeoFiltersLogic = (e) => {
		setSelectedGeoFilters({ ...e });
		if (clearGeoFilterFlag) {
			setClearGeoFilterFlag(false);
		}
	};

	useEffect(() => {
		onChangePeriodView();
	}, [selectedGeoFilters, selectedProductFilters.category]);

	const onChangePeriodView = () => {
		if (
			selectedGeoFilters.periodView &&
			selectedGeoFilters.periodView[0] !== "na" &&
			selectedGeoFilters.country &&
			selectedGeoFilters.geoLevel &&
			selectedGeoFilters.geoLevel2 &&
			selectedProductFilters.category
		) {
			setLoader(true);
			const payload = {
				period: selectedGeoFilters.periodView[0],
				country: selectedGeoFilters.country,
				geoLevel: selectedGeoFilters.geoLevel,
				geoLevel2: selectedGeoFilters.geoLevel2,
				category: selectedProductFilters.category,
			};
			fetchPeriodViewDates("RevenueMap", payload)
				.then((res) => {
					const response = res.data;
					if (res.status === 200 && response?.startDate && response?.endDate) {
						const startDate = DateTime.fromString(response.startDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");
						const endDate = DateTime.fromString(response.endDate, "yyyy-MM-dd").toFormat("MM/dd/yyyy");
						setSelectedDate([startDate, endDate]);
					} else {
						dispatch(dialog("Data is not available for selected Geo and Product filters!", "Period View", true, true));
					}
					setLoader(false);
				})
				.catch((e) => {
					dispatch(dialog(e.message, "Period View", true, true));
					setLoader(false);
				});
		}
	};

	useEffect(() => {
		if (selectedGeoFilters && selectedGeoFilters.country !== null && userData?.id) {
			fetchFilters({ country: selectedGeoFilters.country });
		}
	}, [selectedGeoFilters.country, userData?.id]);

	const fetchFilters = (payload) => {
		if (isCountryManuallyChangedFlag) {
			setSelectedProductFilters({});
		}
		if (selectedGeoFilters.country) {
			setLoader(true);
			payload = {
				...payload,
			};
			fetchFiltersData(
				"graphql",
				{
					country: selectedGeoFilters.country,
					...payload,
				},
				userData.id,
				REVENUE_MAP_PRODUCT_FILTER_QUERY
			)
				.then((response) => {
					if (response && response.data) {
						const productFilterConfig: IProductFilterConfigurations[] = [];
						const filterObject = JSON.parse(response.data);
						filterObject.map((object) => {
							productFilterConfig.push({
								category: {
									label: object.category,
									value: object.category,
								},
								segment: {
									label: object.segment,
									value: object.segment,
								},
								brand: {
									label: object.brand,
									value: object.brand,
								},
								subBrand: {
									label: object.subbrand,
									value: object.subbrand,
								},
								packSize: {
									label: object.packsize,
									value: object.packsize,
								},
								permutation: {
									label: object.permutation,
									value: object.permutation,
								},
								vendor: {
									label: object.Manufacturer,
									value: object.Manufacturer,
								},
							});
						});

						setProductFilterOptions(productFilterConfig);
						setLoader(false);
					}
				})
				.catch((e) => {
					setLoader(false);
				});
		}
	};

	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<Indicator
				position="absolute"
				show={
					!commonLoader &&
					(RevenueMap.quadrantIntensityLoader ||
						RevenueMap.simulationCurrentValueDataLoader ||
						RevenueMap.simulationPredictiveValueDataLoader ||
						RevenueMap.viewTypeDataLoader ||
						geoFilter.countriesLoading ||
						geoFilter.geoLevelLoading ||
						loader)
				}
			/>
			<CardContent>
				<FilterAccordion title="Geo-Filters">
					<GeoFiltersV2
						data={{ ...geoFilterV2Config }}
						onSelectFilters={(e) => setSelectedGeoFiltersLogic(e)}
						clearFilter={clearGeoFilterFlag}
						apiPath="RevenueMap"
						showLoader={setLoader}
						defaultFilters={defaultFilters}
						showDatePicker={false}
						setIsCountryManuallyChangedFlag={setIsCountryManuallyChangedFlag}
					/>
				</FilterAccordion>

				<FilterAccordion title="Product Filters">
					<CommonProductFilters
						filterData={productFilterOptions}
						onChange={(e) => {
							setIsFirstLoadPepsiFlag(false);
							OnProductFiltersChange(e);
						}}
						data={dataObject.productFilter}
						defaultFilters={selectedProductFilters ?? null}
						filterOrder={revenueMapProductFilterOrder}
						isAnchorFlag={false}
						isFirstLoadFlag={isFirstLoadPepsiFlag}
					/>
				</FilterAccordion>
				<FilterAccordion title="Date Filters">
					<Grid container spacing={2} sx={{ mb: 4 }}>
						<Grid item xs={12} md={2}>
							<DropdownTitle>{periodView.title}</DropdownTitle>
							<Dropdown
								data={periodView.options}
								onChange={(periodView) => {
									setSelectedGeoFilters({ ...selectedGeoFilters, periodView });
								}}
								defaultOption={selectedGeoFilters.periodView || ["empty"]}
								placeholder={periodView.placeholder}
								sort={{ enable: false }}
								search={{ enable: false }}
								disabled={selectedProductFilters?.category?.length === 0 || !selectedProductFilters?.category}
							/>
						</Grid>
						<Grid item xs={12} md={2} sm={2} lg={2}>
							<DropdownTitle>Date Range</DropdownTitle>
							<CustomDateRangePicker defaultDate={selectedDate} callback={setSelectedDate} disabled={selectedGeoFilters.periodView[0] !== "na"} />
						</Grid>
					</Grid>
				</FilterAccordion>
				<FilterAccordion title="Measure Filters">
					<MeasureFilters onChange={(e) => onChangeMeasureFilter(e)} selectedMeasureFilters={selectedMeasureFilters} />
				</FilterAccordion>
				<Grid className="p-l-16">
					<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
						Clear Filter
					</OrangeBtn>
					<PrimaryBtn disabled={disableApplyFilter()} color="primary" onClick={onApplyFilter}>
						Apply Filter
					</PrimaryBtn>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default TopFilters;
