import React from "react";
import { CardContent, Grid } from "@mui/material";
import { Title, StyledCard, CancelButton, SaveButton, IOSSlider } from "../../MMPW.Styled";

const marks = [
	{
		value: 0,
	},
	{
		value: 20,
	},
	{
		value: 37,
	},
	{
		value: 100,
	},
];

const RaiseLowerBar: React.FC = () => {
	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid xs={12} display="flex" justifyContent="flex-end">
						<SaveButton>Save</SaveButton>
						<CancelButton>Cancel</CancelButton>
					</Grid>
					<Grid item xs={12}>
						<Title>Raise/Lower a Bar within a Graph</Title>
						<IOSSlider aria-label="ios slider" defaultValue={60} marks={marks} valueLabelDisplay="on" />
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>
	);
};

export default RaiseLowerBar;
