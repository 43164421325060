import { GET_PRODUCT_FILTERS, GET_PRODUCT_FILTERS_SUCESS, GET_PRODUCT_FILTERS_FAILURE, CLEAR_PRODUCT_FILTERS } from "./constants";
import { API } from "../../util/helper";
import { baseApi } from "../api-constants";

const getProductFilters = () => {
	return {
		type: GET_PRODUCT_FILTERS,
	};
};

const getProductFiltersSucess = (payload: any, filterData) => {
	return {
		type: GET_PRODUCT_FILTERS_SUCESS,
		payload,
		filterData,
	};
};

const getProductFiltersFailure = (error) => {
	return {
		type: GET_PRODUCT_FILTERS_FAILURE,
		payload: error,
	};
};

const getProductFilter = async (payload) => {
	const baseApiUrl: string = await baseApi();
	return API.post(`${baseApiUrl}filter/products`, payload);
};

export function fetchProductFiltersData(filters, filterData) {
	return (dispatch: any) => {
		dispatch(getProductFilters());
		getProductFilter(filters)
			.then((response) => {
				dispatch(getProductFiltersSucess(response, filterData));
			})
			.catch((error) => {
				console.error(error.message);
				dispatch(getProductFiltersFailure(error));
			});
	};
}

export const clearProductFilters = () => {
	return {
		type: CLEAR_PRODUCT_FILTERS,
	};
};
