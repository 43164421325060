import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const CstmBtn = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.light,
	color: theme.palette.primary.main,
	textTransform: "capitalize",
	fontSize: "12px",
	width: "140px",
	height: "45px",
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.light,
	},
	"&:disabled": {
		color: "rgba(0, 0, 0, 0.26)",
	},
}));

export { CstmBtn };
