import {
	HOME,
	PRICING_AND_PPA,
	HISTORIC_PRICE,
	SUPER_MARKET,
	PROFIT_PARABOLA_DASHBOARD,
	API_PREDICTIVE_DASHBOARD,
	SELL_OUT_DASHBOARD,
	ALERT_AND_NOTIFICATION,
	USER_LIST,
	MACRO_ECON,
	REVENUE_MAP,
	ELASTICITY_PRICING_TRACK,
	PRICING_POTENTIAL,
	BRAND_LADDER,
	PRICE_LADDER,
	PACK_ECONOMICS,
	PRICE_SETTING_TOOL,
	PRICE_RANGE_PIANO,
	PROMO_AND_TRADE_MANAGEMENT,
	MIX_MANAGEMENT,
	INTEGRATED_STRATEGY,
	USAGE_COUNT_REPORT,
	USAGE_ACTIVITY_REPORT,
	ASSORTMENT_PRIORITIZER,
	MIX_OPTIMIZER,
	EXECUTION_TRACKER,
} from "../router/CONSTANTS";
import { SideMenuItem } from "../types/common";
import {
	PriceIcon,
	TradeManagementIcon,
	MixManagementIcon,
	IntegratedtIcon,
	CatalogIcon,
	SupportTrainingIcon,
	AlertIcon,
	HomeIcon,
	GlobalAnalytics,
	UserGroup,
	APIVsVolume,
	ProfitParabola,
	PriceRange,
	PackEcon,
	PriceLadder,
	BrandLadder,
	RevenueMap,
	Sellout,
	SalesAtPricePoint,
	MacroEcon,
	PriceSettingTool,
	PricingPotential,
	HPCPE,
	EPT,
	UsageReports,
	Assortment,
	MixOptimizer,
	ExecutionTracker,
} from "../assets/icons/sideMenuIcons";

export const sidedrawerItems: SideMenuItem[] = [
	{
		title: "Home",
		key: "home",
		link: HOME,
		Icon: HomeIcon,
		IconActive: "",
		active: true,
		isAccess: true,
	},
	{
		title: "Pricing & PPA",
		key: "pricing_and_ppa",
		link: PRICING_AND_PPA,
		Icon: PriceIcon,
		IconActive: "",
		active: true,
		isAccess: true,
		items: [
			{
				title: "API VS Volume",
				key: "api_vs_volume",
				link: API_PREDICTIVE_DASHBOARD,
				Icon: APIVsVolume,
				IconActive: "",
				active: true,
				isAccess: true,
			},
			{
				title: "Price Range Piano",
				key: "price_range_piano",
				link: PRICE_RANGE_PIANO,
				Icon: PriceRange,
				IconActive: "",
				active: true,
				isAccess: true,
			},
			// {
			// 	title: "Pack Economics",
			// 	key: "pack_economics",
			// 	link: PACK_ECONOMICS,
			// 	Icon: PackEcon,
			// 	IconActive: "",
			// 	active: true,
			// 	isAccess: true,
			// },
			{
				title: "Price Ladder",
				key: "price_ladder",
				link: PRICE_LADDER,
				Icon: PriceLadder,
				IconActive: "",
				active: true,
				isAccess: true,
			},
			// {
			// 	title: "Sell Out Price Tracking",
			// 	key: "sellout_price_tracking",
			// 	link: SELL_OUT_DASHBOARD,
			// 	Icon: Sellout,
			// 	IconActive: "",
			// 	active: true,
			// 	isAccess: true,
			// },
			// {
			// 	title: "Brand Ladder",
			// 	key: "brand_ladder",
			// 	link: BRAND_LADDER,
			// 	Icon: BrandLadder,
			// 	IconActive: "",
			// 	active: true,
			// 	isAccess: true,
			// },
			{
				title: "Revenue Map",
				key: "revenue_map",
				link: REVENUE_MAP,
				Icon: RevenueMap,
				IconActive: "",
				active: true,
				isAccess: true,
			},
			{
				title: "Elasticity Pricing Track",
				key: "ept",
				link: ELASTICITY_PRICING_TRACK,
				Icon: EPT,
				IconActive: "",
				active: true,
				isAccess: true,
			},
		],
	},
	{
		title: "Promotions & Trade Management",
		key: "promo_and_trade_management",
		link: PROMO_AND_TRADE_MANAGEMENT,
		Icon: TradeManagementIcon,
		IconActive: "",
		active: true,
		isAccess: true,
		// 	items: [
		// 		{
		// 			title: "Sales By Price Point",
		// 			key: "sales_by_price_point",
		// 			link: SUPER_MARKET,
		// 			Icon: SalesAtPricePoint,
		// 			IconActive: "",
		// 			active: true,
		// 			isAccess: true,
		// 		},
		// 		{
		// 			title: "Profit Parabola",
		// 			key: "profit_parabola",
		// 			link: PROFIT_PARABOLA_DASHBOARD,
		// 			Icon: ProfitParabola,
		// 			IconActive: "",
		// 			active: true,
		// 			isAccess: true,
		// 		},
		// 	],
	},
	{
		title: "Mix Management",
		key: "mix_management",
		link: MIX_MANAGEMENT,
		Icon: MixManagementIcon,
		IconActive: "",
		active: true,
		isAccess: true,
		items: [
			{
				title: "Assortment Prioritizer",
				key: "assortment_prioritizer",
				link: ASSORTMENT_PRIORITIZER,
				Icon: Assortment,
				IconActive: "",
				active: true,
				isAccess: true,
			},
			{
				title: "Mix Optimizer",
				key: "mix_optimizer",
				link: MIX_OPTIMIZER,
				Icon: MixOptimizer,
				IconActive: "",
				active: true,
				isAccess: true,
			},
			{
				title: "Execution Tracker",
				key: "execution_tracker",
				link: EXECUTION_TRACKER,
				Icon: ExecutionTracker,
				IconActive: "",
				active: true,
				isAccess: true,
			},
		],
	},
	// {
	// 	title: "Integrated Strategy",
	// 	key: "integrated_strategy",
	// 	link: INTEGRATED_STRATEGY,
	// 	Icon: IntegratedtIcon,
	// 	IconActive: "",
	// 	active: true,
	// 	isAccess: true,
	// 	items: [
	// 		{
	// 			title: "Macro Econ ",
	// 			key: "macro_econ",
	// 			link: MACRO_ECON,
	// 			Icon: MacroEcon,
	// 			IconActive: "",
	// 			active: true,
	// 			isAccess: true,
	// 		},
	// 		{
	// 			title: "Price Setting Tool ",
	// 			key: "price_setting_tool",
	// 			link: PRICE_SETTING_TOOL,
	// 			Icon: PriceSettingTool,
	// 			IconActive: "",
	// 			active: true,
	// 			isAccess: true,
	// 		},
	// 		{
	// 			title: "Historic Price and Cross-Price Elasticities",
	// 			key: "hpcpe",
	// 			link: HISTORIC_PRICE,
	// 			Icon: HPCPE,
	// 			IconActive: "",
	// 			active: true,
	// 			isAccess: true,
	// 		},
	// 		{
	// 			title: "Pricing Potential ",
	// 			key: "pricing_potential",
	// 			link: PRICING_POTENTIAL,
	// 			Icon: PricingPotential,
	// 			IconActive: "",
	// 			active: true,
	// 			isAccess: true,
	// 		},
	// ],
	// },
	{
		title: "Global Analytics Hub",
		key: "global_analytics_hub",
		link: "https://pepsico.sharepoint.com/sites/globalnrm/SitePages/PepsiCo.aspx",
		target: "_blank",
		external: true,
		Icon: GlobalAnalytics,
		IconActive: "",
		active: true,
		isAccess: true,
	},
	{
		title: "Reporting",
		key: "usage_count_report",
		link: USAGE_COUNT_REPORT,
		Icon: UsageReports,
		IconActive: "",
		active: true,
		isAccess: true,
		items: [
			{
				title: "Usage Count Report",
				key: "usage_count_report",
				link: USAGE_COUNT_REPORT,
				Icon: PriceRange,
				IconActive: "",
				active: true,
				isAccess: true,
			},
			{
				title: "Usage Activity Report",
				key: "usage_activity_report",
				link: USAGE_ACTIVITY_REPORT,
				Icon: PriceRange,
				IconActive: "",
				active: true,
				isAccess: true,
			},
		],
	},
];
export const sidedrawerBottomItems = [
	{
		title: "Master Catalogs",
		key: "master_catalogs",
		external: true,
		target: "_blank",
		link: "https://pepsico.sharepoint.com/:f:/r/teams/RevolutioM-2.0/Shared%20Documents/Master%20Catalogs?csf=1&web=1&e=HF7NGV",
		Icon: CatalogIcon,
		IconActive: "",
		active: true,
		isAccess: true,
	},
	{
		title: "Support and Training",
		key: "support_and_training",
		external: true,
		target: "_blank",
		link: "https://pepsico.sharepoint.com/:f:/r/teams/RevolutioM-2.0/Shared%20Documents/Support%20and%20Training?csf=1&web=1&e=k95oQQ",
		Icon: SupportTrainingIcon,
		IconActive: "",
		active: true,
		isAccess: true,
	},
	{
		title: "System Alert & Notifications",
		key: "system_alerts_notifications",
		link: ALERT_AND_NOTIFICATION,
		Icon: AlertIcon,
		IconActive: "",
		active: true,
		isAccess: true,
	},
	{
		title: "User Management",
		key: "user_management",
		link: USER_LIST,
		Icon: UserGroup,
		IconActive: "",
		active: true,
		isAccess: true,
	},
];
