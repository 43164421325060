export const BRAND_LADDER_MAXDATE_QUERY = (payload: any) => {
	return {
		query: `{getBrandLadderMaxDate(
        Country: "${payload.country ?? null}"
        GeoLevel: ${payload.geoLevel ? '[' + payload.geoLevel + ']' : null}
        GeoLevel2: ${payload.geoLevel2 ? '[' + payload.geoLevel2 + ']' : null}
        Category: ${payload.category ? '[' + payload.category + ']' : null}
      ){
        lastRefreshDate
      }
    }
    `,
	};
};