import { PRICE_ELASTICITY_LOCAL_FILTER, PRICE_ELASTICITY_MODEL } from "./constants";

let intiState = {
	priceElasticityDataLoader: false,
	priceElasticityLocalFilter: false,
};

const HistoricPrice = (state = intiState, action) => {
	switch (action.type) {
		case PRICE_ELASTICITY_MODEL:
			return {
				...state,
				priceElasticityDataLoader: action.payload,
			};
		case PRICE_ELASTICITY_LOCAL_FILTER:
			return {
				...state,
				priceElasticityLocalFilter: action.payload,
			};
		default:
			return state;
	}
};

export default HistoricPrice;
