import { graphqlAPI } from "../../store/api-constants";
import { PortalFeatureDashboardPayload } from "../../types/common";
import { API } from "../../util/helper";
import { PORTAL_FEATURE_DASHBOARD_DATA_QUERY } from "../../util/queries/common";

/* Calls GraphQL endpoint to get dashboard source data.
 * @param payload - The object contains the request payload of type PortalFeatureDashboardPayload.
 * @returns The dashboard source data.
 */
export const getDashboardSource = async (payload: PortalFeatureDashboardPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = PORTAL_FEATURE_DASHBOARD_DATA_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.portalFeatureDashboardData;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};
