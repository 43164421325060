import { IPRPColumnChartConfig } from "../../../../../../../types/priceRangePiano";

export const barChartConfig: IPRPColumnChartConfig = {
	chart: {
		type: "bar",
		height: 200,
		width: 300,
	},
	title: {
		text: null,
	},
	xAxis: {
		categories: [],
		gridLineWidth: 0,
		lineWidth: 0,
		labels: {
			style: {
				fontSize: "12px", // Initial font size for xAxis labels
			},
		},
	},
	yAxis: {
		min: 0,
		title: {
			text: null,
			style: {
				fontSize: "12px", // Initial font size for yAxis title
			},
		},
		labels: {
			enabled: false,
		},
		gridLineWidth: 0,
	},
	legend: {
		enabled: false,
	},
	plotOptions: {
		bar: {
			borderRadius: "50%",
			dataLabels: {
				enabled: false,
			},
			groupPadding: 0.1,
		},
	},
	credits: {
		enabled: false,
	},
	series: [],
	exporting: {
		enabled: false, // Disable the export options
	},
	tooltip: {
		useHTML: true,
		formatter: function () {
			// This will be overridden
			return "";
		},
		headerFormatter: function () {
			// This will be overridden
			return "";
		},
		pointFormat: '<div id="hc-tooltip"></div>',
		style: {
			fontSize: "12px", // Initial font size for xAxis labels
		},
	},
};
