import { clearServerCacheAPI, graphqlAPI } from "../../store/api-constants";
import { API } from "../../util/helper";
import { COUNTRIES_QUERY } from "../../util/queries/common";

// This function makes an API call to fetch the data for list of countries
export const getCountries = async () => {
	const graphQLApiUrl: string = await graphqlAPI();
	let queryParameter = COUNTRIES_QUERY;
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: string[] = [];
		if (response && response.data) {
			data = response.data.data.getcountries;
		}
		return { data };
	});
};

// This function makes an API call to clear the server cache
export const clearServerCache = async () => {
	try {
		const clearServerCacheApiUrl: string = await clearServerCacheAPI();
		return await API.post(clearServerCacheApiUrl);
	} catch (e) {
		return e;
	}
};
