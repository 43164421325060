import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";
import { CstmBtn } from "../../AlertAndNotification.Styled";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import FormControl from "@mui/material/FormControl";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Text, CreateNotificationTitle, EditTemplateTextarea } from "./CreateNotification.Styled";
import { DateTime } from "luxon";
import DatePicker from "@mui/lab/DatePicker";
import { typesOfNotification } from "../../../../mocks/alertAndNotification";
import { INotificationCardType } from "../../../../types/common";
import { dataFormat } from "../../../../util/helper";
import { postCreateNotification } from "../../../../store/actions/AlertAndNotification/createNotification.action";
import { useDispatch, useSelector } from "react-redux";
import { pageList } from "../../../../mocks/common";
import { loader } from "../../../../store/actions/common.action";

const initialPayload = {
	type: "",
	title: "",
	pageKey: "",
	subTitle: "",
	message: "",
	expiresOn: new Date(),
};

const CreateNotification: React.FC<{ data }> = ({ data }) => {
	const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
	const dispatch = useDispatch();
	const [notificationPayload, setNotificationPayload] = useState<INotificationCardType>(initialPayload);
	const userDetail = useSelector((state: any) => state.User.data);

	const valueChangeHandler = (key: string, value) => {
		setNotificationPayload({
			...notificationPayload,
			[key]: value,
		});
	};

	const saveNotification = () => {
		const notificationDetails: INotificationCardType = {
			...notificationPayload,
			expiresOn: new Date(`${dataFormat(notificationPayload.expiresOn, "yyyy-MM-dd")}T23:59:59Z`), // Setting time of expiresOn to the end of the day in UTC time
		};
		dispatch(loader(true));
		dispatch(postCreateNotification(userDetail, notificationDetails));
	};

	const createNotificationData = useSelector((state: any) => state.CreateNotification);

	useEffect(() => {
		if (createNotificationData && createNotificationData.data) {
			setNotificationPayload(initialPayload);
			dispatch(loader(false));
		} else if (createNotificationData.error) {
			dispatch(loader(false));
		}
	}, [createNotificationData]);

	useEffect(() => {
		if (data && data.id) {
			setNotificationPayload({
				id: data.id,
				type: data.type,
				title: data.title,
				pageKey: data.pageKey,
				subTitle: data.subTitle,
				message: data.message,
				expiresOn: new Date(data.expiresOn),
			});
		}
	}, [data]);

	const deleteNotificationData = useSelector((state: any) => state.DeleteNotification);

	useEffect(() => {
		if (deleteNotificationData) {
			setNotificationPayload(initialPayload);
		}
	}, [deleteNotificationData]);

	const disableSubmit = () => {
		return (
			!notificationPayload.type || !notificationPayload.title || !notificationPayload.pageKey || !notificationPayload.subTitle || !notificationPayload.expiresOn
		);
	};

	return (
		<Box>
			<Grid>
				<CreateNotificationTitle>{notificationPayload.id ? "Edit Notification" : "Create Notification"}</CreateNotificationTitle>
			</Grid>
			<Grid>
				<Grid container>
					<Grid item xs={12} sm={4} md={4} className="m-b-15">
						<Dropdown
							data={typesOfNotification}
							defaultOption={notificationPayload.type || ["empty"]}
							onChange={(val) => valueChangeHandler("type", val[0])}
							placeholder="Notification Type"
							search={{ enable: false }}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={4} md={4} className="m-b-15">
						<Dropdown
							data={pageList.filter((page) => page.allowCreateNotif)}
							defaultOption={notificationPayload.pageKey || ["empty"]}
							onChange={(val) => valueChangeHandler("pageKey", val[0])}
							placeholder="Page to Display the Notification On"
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={4} className="m-b-20">
						<Text placeholder="Title" value={notificationPayload.title} onChange={(val) => valueChangeHandler("title", val.target.value)} />
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={4} className="m-b-20">
						<Text placeholder="Sub Title" value={notificationPayload.subTitle} onChange={(val) => valueChangeHandler("subTitle", val.target.value)} />
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={4} className="m-b-20">
						<EditTemplateTextarea
							data-testid="templateTextArea"
							minRows={3}
							style={{
								border: `1px solid`,
								borderColor: `${theme.darkmode ? "transparent" : "#b9b9b9"}`,
							}}
							placeholder="Message"
							value={notificationPayload.message}
							onChange={(val) => valueChangeHandler("message", val.target.value)}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={4} className="m-b-15">
					<FormControl>
						<LocalizationProvider dateAdapter={AdapterLuxon} style={{ width: 200 }}>
							<DatePicker
								value={notificationPayload.expiresOn}
								onChange={(date: any) => {
									if (date && !date.invalid) {
										const dt = new Date(date.ts);
										valueChangeHandler("expiresOn", dt);
									} else {
										valueChangeHandler("expiresOn", null);
									}
								}}
								minDate={DateTime.now()}
								renderInput={(params) => <Text hiddenLabel {...params} />}
							/>
						</LocalizationProvider>
					</FormControl>
				</Grid>
				<CstmBtn onClick={saveNotification} className="m-r-20" disabled={disableSubmit()}>
					{notificationPayload.id ? "Update" : "Save"}
				</CstmBtn>
				<CstmBtn onClick={() => setNotificationPayload(initialPayload)}>Clear</CstmBtn>
			</Grid>
		</Box>
	);
};

export default CreateNotification;
