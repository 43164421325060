import React from "react";
import { Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

const AuthError: React.FC<{ error? }> = ({ error }) => {
	let history = useHistory();

	function handleClick() {
		history.push("/");
	}

	return (
		<Grid display="flex" justifyContent="center" alignItems="center" style={{ width: "100vw", height: "100vh" }}>
			<Grid display="flex" spacing={3}>
				<Grid item>
					<h1>
						<b>Login Error</b>
					</h1>
					<h3>
						<p>{error}</p>
					</h3>
					<Grid display="flex" justifyContent="flex-end" className="m-t-20">
						<Button variant="contained" onClick={handleClick}>
							Try again
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default AuthError;
