import { graphqlAPI } from "../../store/api-constants";
import { PricePianoPayload, PriceRangeGraphPayload } from "../../types/common";
import { API } from "../../util/helper";
import { GET_PRICE_PIANO_QUERY, PRICE_RANGE_GRAPH_QUERY } from "../../util/queries/priceRangePiano";

/* This Function makes an API call to fetch the data for price range graph based on specific filter values.
 * @param {PriceRangeGraphPayload} data - Value of Price range graph filter.
 */
export const fetchPricePianoData = async (data: PriceRangeGraphPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = PRICE_RANGE_GRAPH_QUERY(data);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.priceRangeGraph;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

/* This Function makes an API call to fetch the data for price piano based on specific filter values.
 * @param {PRICE_PIANO_PAYLOAD} data - Value of Price Piano filter.
 */
export const fetchPricePianoVisTwoData = async (data: PricePianoPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_PRICE_PIANO_QUERY(data);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getPricePiano;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};
