import { graphqlAPI } from "../../store/api-constants";
import { ICountryAccessData, IUsageActivityFilters, TUsageActivityQueryFunction } from "../../types/reporting";
import { API } from "../../util/helper";
import { COUNTRIES_QUERY } from "../../util/queries/common";
import { USAGE_ACTIVITY_COUNTRY_ACCESS_QUERY } from "../../util/queries/reporting";

export const getMultilevelTableData = async (queryFunction: TUsageActivityQueryFunction, payload: IUsageActivityFilters) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		const { key, query } = queryFunction(payload);
		return await API.post(graphQLApiUrl, query).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data[key];
			}
			return { data };
		});
	} catch (e) {
		return e;
	}
};

export const getCountries = async () => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		const queryParameter = COUNTRIES_QUERY;
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getcountries;
			}
			return { data };
		});
	} catch (e) {
		return e;
	}
};

export const getCountryAccess = async (payload: { country: string[]; users: string[] }) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		const queryParameter = USAGE_ACTIVITY_COUNTRY_ACCESS_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data: ICountryAccessData[] = [];
			if (response && response.data) {
				data = response.data.data.accesswithCountry;
			}
			return { data };
		});
	} catch (e) {
		return e;
	}
};
