import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { ILeveLNamePair, ISOMVendorDataObject } from "../../../../../../../types/priceRangePiano";
import { formatDecimal } from "../../../../../../../util/helper";

const useStyles = makeStyles(() => ({
	borderClass: {
		borderRight: "2px solid #D3D3D3",
		borderBottom: "2px solid #D3D3D3",
	},
	widthClass: {
		borderLeft: "2px solid #D3D3D3",
		borderBottom: "2px solid #D3D3D3",
		textOverflow: "ellipsis",
		overflow: "hidden",
		width: "18%",
	},
	pl2: {
		paddingLeft: "10px",
	},
	headerColor: {
		backgroundColor: "#D3D3D3",
	},
	customTableContainer: {
		overflowX: "initial",
	},
	cellLeftBorderClass: {
		borderLeft: "2px solid #D3D3D3",
	},
	cellPadding: {
		padding: "0px 0px 0px 5px !important",
	},
	flexEvenly: {
		display: "flex !important",
		justifyContent: "space-between !important",
	},
	overflowEllipsis: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		padding: "0px 0px 0px 0px",
	},
	dFlex: {
		display: "flex !important",
		alignItems: "center",
	},
	tdWidth: {
		width: "6vw",
	},
	collapseSignColor: {
		color: "#005EA6",
	},
}));

function getRowValue(data, headerValue) {
	const rowValue = data.find((rowData) => rowData.label === headerValue);
	return rowValue?.value ? formatDecimal(rowValue.value) : rowValue?.value === 0 ? 0.0 : "0.0";
}

function getIsExpandedValue(data, selectedHierarchies) {
	const hierarchyIndex: number = selectedHierarchies.findIndex((hierarchy) => hierarchy.level === data.level && hierarchy.name === data.name);
	return hierarchyIndex !== -1;
}

const HierarchicalTable: FC<{
	data: ISOMVendorDataObject[];
	tableHeader: any;
	style?;
	showSkeleton?: boolean;
}> = ({ data, tableHeader, style, showSkeleton }) => {
	const classes = useStyles();
	const [customTableData, setCustomTableData] = useState<ISOMVendorDataObject[]>([]);
	const [selectedHierarchies, setSelectedHierarchies] = useState<ILeveLNamePair[]>([]);

	useEffect(() => {
		if (data.length !== 0) {
			setCustomTableData([...data]);
		}
	}, [data]);
	const header = tableHeader?.map((cell, i) => {
		return (
			<TableCell
				sx={{ bgcolor: "#D3D3D3" }}
				key={`table-header-cell-${i}`}
				align={i === 0 ? "left" : "center"}
				className={`${classes.headerColor} ${i === 0 ? classes.borderClass : classes.widthClass}`}
				style={{ textWrap: "nowrap" }}
			>
				{cell?.label}
			</TableCell>
		);
	});

	const valueCell = (rowData, name) => {
		const rowCell = tableHeader
			?.filter((headerValue) => headerValue.value !== "Manufacturer")
			.map((cell) => {
				return (
					<TableCell align="center" className={`${classes.borderClass} ${classes.tdWidth} ${classes.overflowEllipsis}`} key={cell?.label + "-" + name}>
						{getRowValue(rowData, cell.label)}
					</TableCell>
				);
			});
		return rowCell;
	};

	const childCell = (childData) => {
		const childRows = childData.map((data) => {
			return (
				<>
					<TableRow>
						<TableCell align="left" className={`${classes.cellLeftBorderClass} ${classes.borderClass} ${classes.dFlex} ${classes.overflowEllipsis}`}>
							{data.children.length > 0 ? (
								<IconButton
									aria-label="expand row"
									size="small"
									style={{ marginLeft: `${data.level * 10}px` }}
									onClick={() => {
										const hierarchyIndex: number = selectedHierarchies.findIndex((hierarchy) => hierarchy.level === data.level && hierarchy.name === data.name);
										const tempSelectedHierarchies = [...selectedHierarchies];
										if (hierarchyIndex === -1) {
											tempSelectedHierarchies.push({
												level: data.level,
												name: data.name,
											});
										} else {
											tempSelectedHierarchies.splice(hierarchyIndex, 1);
										}
										setSelectedHierarchies(tempSelectedHierarchies);
									}}
								>
									{getIsExpandedValue(data, selectedHierarchies) ? (
										<Remove className={classes.collapseSignColor} />
									) : (
										<Add className={classes.collapseSignColor} />
									)}
								</IconButton>
							) : (
								<IconButton style={{ width: "30px", marginLeft: `${data.level * 10}px` }}></IconButton>
							)}
							{data.name}
						</TableCell>
						{valueCell(data.data, data.name)}
					</TableRow>
					{getIsExpandedValue(data, selectedHierarchies) && childCell(data.children)}
				</>
			);
		});
		return childRows;
	};
	return (
		<TableContainer component={Paper} style={{ height: "auto", maxHeight: style.height, overflow: style.overflow }} className={classes.customTableContainer}>
			<Table aria-label="collapsible table" stickyHeader>
				<TableHead>
					<TableRow>{header}</TableRow>
				</TableHead>
				<TableBody>
					{customTableData.map((data: ISOMVendorDataObject) => {
						return (
							<>
								<TableRow>
									<TableCell align="left" className={`${classes.cellLeftBorderClass} ${classes.borderClass} ${classes.dFlex} ${classes.overflowEllipsis}`}>
										{data.children.length > 0 ? (
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() => {
													const hierarchyIndex: number = selectedHierarchies.findIndex(
														(hierarchy) => hierarchy.level === data.level && hierarchy.name === data.name
													);
													const tempSelectedHierarchies = [...selectedHierarchies];
													if (hierarchyIndex === -1) {
														tempSelectedHierarchies.push({
															level: data.level,
															name: data.name,
														});
													} else {
														tempSelectedHierarchies.splice(hierarchyIndex, 1);
													}
													setSelectedHierarchies(tempSelectedHierarchies);
												}}
											>
												{getIsExpandedValue(data, selectedHierarchies) ? (
													<Remove className={classes.collapseSignColor} />
												) : (
													<Add className={classes.collapseSignColor} />
												)}
											</IconButton>
										) : (
											<IconButton style={{ width: "30px" }}></IconButton>
										)}
										{data.name}
									</TableCell>
									{valueCell(data.data, data.name)}
								</TableRow>
								{getIsExpandedValue(data, selectedHierarchies) && childCell(data.children)}
							</>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default HierarchicalTable;
