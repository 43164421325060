import { Box, CardMedia, Grid, IconButton, List, ListItem } from "@mui/material";
import { ListVendors, ProductList, ListLogo, ListContentBlk, ListContentTitle, ModalBrandSec, ModalBrandList } from "../priceLadder.Styled";
import React, { FC, useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { IPLVendorListObject } from "../../../../types/priceLadder";
import { BlackTitle } from "../../../../styles/Common.Styled";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70%",
	backgroundColor: "#fff",
	border: "1px solid #000",
	boxShadow: 24,
	p: 3,
	borderRadius: "8px",
	padding: "20px",
};

const VendorsList: FC<{ vendors: IPLVendorListObject[] }> = ({ vendors }) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Grid container spacing={0}>
			<Grid xs={12} sm={12} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
				<ListVendors>
					{vendors?.slice(0, 4).map((e, i) => {
						return (
							<ListItem alignItems="center" key={`vendor-${i}`} style={ProductList}>
								<Grid style={{ ...ListLogo, flexDirection: "column" }}>
									{e.logoImg ? (
										<CardMedia component="img" sx={{ width: 100 }} image={e.logoImg} />
									) : (
										<Typography color="#000">
											<BlackTitle fontSize={18} fontWeight={600}>
												{e.vendor}
											</BlackTitle>
										</Typography>
									)}
								</Grid>
								<Grid style={{ ...ListContentBlk, minWidth: "204px", flexDirection: "column" }}>
									<Grid container justifyContent={"center"} alignItems={"center"} spacing={0} style={ListContentTitle}>
										<Grid xs={5} textAlign={"right"} style={{ fontSize: "17px", color: "#000" }}>
											Selected
										</Grid>
										<Grid xs={2} style={{ fontSize: "14px", padding: "0 10px", color: "#000", textAlign: "center" }}>
											/
										</Grid>
										<Grid xs={5} textAlign={"left"} style={{ fontSize: "17px", color: "#000" }}>
											Total
										</Grid>
									</Grid>
									<Grid container justifyContent={"center"} alignItems={"center"} spacing={0} style={ListContentTitle}>
										<Grid xs={5} textAlign={"right"} style={{ fontSize: "17px", color: "#000" }}>
											{e.selected}
										</Grid>
										<Grid xs={2} style={{ fontSize: "14px", padding: "0 5px", color: "#000", textAlign: "center" }}>
											/
										</Grid>
										<Grid xs={5} textAlign={"left"} style={{ fontSize: "17px", color: "#000" }}>
											{e.total}
										</Grid>
									</Grid>

									<Grid container justifyContent={"center"} alignItems={"center"} spacing={0} style={ListContentTitle}>
										<Grid xs={5} textAlign={"right"}>
											<BlackTitle
												style={{
													fontSize: "14px",
													paddingRight: "5px",
													color: (parseFloat(e.selectedpp.toString()) < 0 ? "#fb1111" : "") + (parseFloat(e.selectedpp.toString()) > 0 ? "#2dbe04" : ""),
												}}
											>
												{parseFloat(e.selectedpp.toString()) > 0
													? "+" + (parseFloat(e.selectedpp.toString()).toFixed(2) + "pp")
													: "" + (parseFloat(e.selectedpp.toString()).toFixed(2) + "pp")}
											</BlackTitle>
										</Grid>
										<Grid xs={2} style={{ fontSize: "12px", padding: "0 5px", textAlign: "center", color: "#000" }}>
											/
										</Grid>
										<Grid xs={5} textAlign={"left"}>
											<BlackTitle
												style={{
													fontSize: "14px",
													color: (parseFloat(e.totalpp.toString()) < 0 ? "#fb1111" : "") + (parseFloat(e.totalpp.toString()) > 0 ? "#2dbe04" : ""),
												}}
											>
												{parseFloat(e.totalpp.toString()) > 0
													? "+" + (parseFloat(e.totalpp.toString()).toFixed(2) + "pp")
													: "" + (parseFloat(e.totalpp.toString()).toFixed(2) + "pp")}
											</BlackTitle>
										</Grid>
									</Grid>
								</Grid>
							</ListItem>
						);
					})}

					{vendors.length > 4 && (
						<ListItem alignItems="center" style={ProductList}>
							<Button style={{ color: "#1e4f81", fontWeight: 400, textDecoration: "none", border: "1px solid gray" }} onClick={handleOpen}>
								View All Vendors
							</Button>

							<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
								<Box m={style} className="modal competitor_modal">
									<Typography display={"flex"} justifyContent={"space-between"}>
										<Typography id="modal-modal-title" variant="h5" component="h3">
											All Vendors
										</Typography>
										<IconButton aria-label="Close" onClick={handleClose}>
											<CloseIcon />
										</IconButton>
									</Typography>
									<Typography className="modal-body" id="modal-modal-description" sx={{ mt: 1 }}>
										<List style={{ ...ModalBrandSec, flexDirection: "row", flexWrap: "wrap" }}>
											{vendors?.map((e, i) => {
												return (
													<ListItem key={`all-vendors-${i}`} alignItems="center" style={ModalBrandList} sx={{ m: 1 }}>
														<Grid style={{ ...ListLogo, flexDirection: "column" }}>
															{e.logoImg ? (
																<CardMedia component="img" sx={{ width: 80 }} image={e.logoImg} />
															) : (
																<Typography color="text.primery">
																	<BlackTitle fontSize={18} fontWeight={600}>
																		{e.vendor}
																	</BlackTitle>
																</Typography>
															)}
														</Grid>
														<Grid style={{ ...ListContentBlk, width: "70%", flexDirection: "column" }}>
															<Grid container justifyContent={"center"} alignItems={"center"} spacing={0} style={ListContentTitle}>
																<Grid xs={5} textAlign={"right"}>
																	Selected
																</Grid>
																<Grid xs={2} style={{ fontSize: "12px", padding: "0 5px", textAlign: "center" }}>
																	/
																</Grid>
																<Grid xs={5} textAlign={"left"}>
																	Total
																</Grid>
															</Grid>

															<Grid container justifyContent={"center"} alignItems={"center"} spacing={0} style={ListContentTitle}>
																<Grid xs={5} textAlign={"right"}>
																	{e.selected}
																</Grid>
																<Grid xs={2} style={{ fontSize: "12px", padding: "0 5px", textAlign: "center" }}>
																	/
																</Grid>
																<Grid xs={5} textAlign={"left"}>
																	{e.total}
																</Grid>
															</Grid>

															<Grid container justifyContent={"center"} alignItems={"center"} spacing={0} style={ListContentTitle}>
																<Grid xs={5} textAlign={"right"}>
																	<Typography
																		style={{
																			fontSize: "14px",
																			paddingRight: "5px",
																			color:
																				(parseFloat(e.selectedpp.toString()) < 0 ? "#fb1111" : "#000") +
																				(parseFloat(e.selectedpp.toString()) > 0 ? "#2dbe04" : "#000"),
																		}}
																	>
																		{parseFloat(e.selectedpp.toString()) > 0
																			? "+" + (parseFloat(e.selectedpp.toString()).toFixed(2) + "pp")
																			: "" + (parseFloat(e.selectedpp.toString()).toFixed(2) + "pp")}
																	</Typography>
																</Grid>
																<Grid xs={2} style={{ fontSize: "12px", padding: "0 5px", textAlign: "center" }}>
																	/
																</Grid>
																<Grid xs={5} textAlign={"left"}>
																	<Typography
																		style={{
																			fontSize: "14px",
																			color:
																				(parseFloat(e.totalpp.toString()) < 0 ? "#fb1111" : "#000") +
																				(parseFloat(e.totalpp.toString()) > 0 ? "#2dbe04" : "#000"),
																		}}
																	>
																		{parseFloat(e.totalpp.toString()) > 0
																			? "+" + (parseFloat(e.totalpp.toString()).toFixed(2) + "pp")
																			: "" + (parseFloat(e.totalpp.toString()).toFixed(2) + "pp")}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
													</ListItem>
												);
											})}
										</List>
									</Typography>
								</Box>
							</Modal>
						</ListItem>
					)}
				</ListVendors>
			</Grid>
		</Grid>
	);
};
export default VendorsList;
