import { GET_DASHBOARDS_LIST, UPDATE_DASHBOARDS_FETCH_FLAG } from "../../actions/YourDashboard/constant";

let dasboardInitialState = {
	data: [],
	isFetchStarted: false,
};

const DashboardConfiguration = (state = dasboardInitialState, action) => {
	if (action.type === GET_DASHBOARDS_LIST) {
		return {
			...state,
			data: action.payload,
		};
	} else if (action.type === UPDATE_DASHBOARDS_FETCH_FLAG) {
		return {
			...state,
			isFetchStarted: action.payload,
		};
	} else {
		return state;
	}
};
export default DashboardConfiguration;
