import { DISMISS_NOTIFICATION, DISMISS_NOTIFICATION_SUCCESS, DISMISS_NOTIFICATION_FAILURE } from "./constants";
import { API } from "../../../util/helper";
import { graphqlAPI } from "../../api-constants";
import { DISMISS_NOTIFICATION_QUERY } from "../../../util/queries/notifications";

const resetNotification = () => {
	return {
		type: DISMISS_NOTIFICATION,
	};
};

const dissmissNotificationSuccess = (payload: any) => {
	return {
		type: DISMISS_NOTIFICATION_SUCCESS,
		payload,
	};
};

const dissmissNotificationFailure = (error) => {
	return {
		type: DISMISS_NOTIFICATION_FAILURE,
		payload: error,
	};
};

const dissmissNotificationData = async (userId: number, notificationId: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	return await API.post(graphQLApiUrl, DISMISS_NOTIFICATION_QUERY(userId, notificationId)).then((response) => {
		return response;
	});
};

export function dissmissNotification(userId: number, notificationId: string) {
	return (dispatch: any) => {
		dispatch(resetNotification());
		dissmissNotificationData(userId, notificationId)
			.then((response) => {
				if (response.data) {
					dispatch(dissmissNotificationSuccess(response.data));
				} else {
					dispatch(dissmissNotificationSuccess({ userId, notificationId }));
				}
			})
			.catch((error) => {
				dispatch(dissmissNotificationFailure(error));
			});
	};
}
