import { graphqlAPI } from "../../store/api-constants";
import { API } from "../../util/helper";
import { REVENUEMAP_QUAD_INTENSITY_QUERY, REVENUEMAP_VIEW_TYPE_QUERY } from "../../util/queries/revenueMap";
import dataObject from "../../mocks/revenueMap";
import { IRevenueMapFilterConfigurations } from "../../types/revenueMap";

/* Calls GraphQL endpoint to get quadrant intensity graph data for revenue map dashboard.
 * @param {IRevenueMapFilterConfigurations} payload - The object contains the request payload.
 * Returns revenue map quadrant intensity graph data.
 */
export const getQuadrantIntensity = async (payload: IRevenueMapFilterConfigurations) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = REVENUEMAP_QUAD_INTENSITY_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.quadIntensity;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

// Formats the revenue map table data to show data in a particular structure
function formatViewTypeData(data) {
	const keys = ["viewType"].concat(dataObject.HeadingData.map((item) => item.value));
	const formattedData = data.map((item) => {
		const obj = {};
		keys.forEach((key) => {
			obj[key] = item[key];
		});
		return obj;
	});
	return formattedData;
}

/* Calls GraphQL endpoint to get revenue map by manufacturer graph data for revenue map dashboard.
 * @param {IRevenueMapFilterConfigurations} payload - The object contains the request payload.
 * Returns revenue map by manufacturer graph data.
 */
export const fetchViewTypeData = async (payload: IRevenueMapFilterConfigurations) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = REVENUEMAP_VIEW_TYPE_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = formatViewTypeData(response.data.data.ViewType);
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};
