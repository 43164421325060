export const visualTooltip = {
	"mmpw-test-line-chart": {
		tooltip: {
			html: "<p>{{series.name}} {{y}}</p>",
			variables: {
				"series.name": {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
	"mmpw-test-column-drilldown-chart-1": {
		tooltip: {
			html: "<p>{{series.name}} {{y}} abcd</p>",
			variables: {
				"series.name": {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
	//revenue map
	"revenue-map-quadrant-intensity-graph": {
		tooltip: {
			html: "<Grid ><p>Var Description : <b>{{options.name}}</b></p> <br/> <p>Var X : <b>{{x}}</b></p> <br/> <p>Var Y : <b>{{y}}</b></p> <br/> <p>Variable Size : <b>{{z}}</b></p></Grid>",
			variables: {
				"options.name": {
					isFormat: false,
					decimal: null,
				},
				x: {
					isFormat: true,
					decimal: 2,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
				z: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
	// price ranges
	"price-range-pack-index-piano": {
		tooltip: {
			html: "<b>{{category}}</b><br/>{{series.name}}: {{y}}<br/>Total: {{stackTotal}}",
			variables: {
				"series.name": {
					isFormat: false,
					decimal: null,
				},
				category: {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 1,
				},
				stackTotal: {
					isFormat: true,
					decimal: 1,
				},
			},
		},
	},
	"price-range-share-of-market-piano": {
		tooltip: {
			html: "{{category}}<br/>{{series.name}}: {{y}}",
			variables: {
				"series.name": {
					isFormat: false,
					decimal: null,
				},
				category: {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 1,
				},
			},
		},
	},
	"share-of-market-piano-2": {
		tooltip: {
			html: "{{category}}<br/>{{series.name}}: {{y}}<br/>Total: {{stackTotal}}",
			variables: {
				"series.name": {
					isFormat: false,
					decimal: null,
				},
				category: {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 1,
				},
				stackTotal: {
					isFormat: true,
					decimal: 1,
				},
			},
		},
	},
	// Pricing potential

	"pricing-potential-chart1": {
		tooltip: {
			html: "<b>{{category}}</b><p>{{y}}</p>",
			variables: {
				category: {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
	"pricing-potential-chart2": {
		tooltip: {
			html: "<b>{{category}}</b><p> {{series.name}}: {{y}}</p>",
			variables: {
				category: {
					isFormat: false,
					decimal: null,
				},
				"series.name": {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
	//pack economic
	"pack-economics-predictive-chart": {
		tooltip: {
			html: "<b>{{category}}</b><p>AVG: {{y}}</br></p>",
			variables: {
				category: {
					isFormat: false,
					decimal: null,
				},
				x: {
					isFormat: true,
					decimal: 2,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
	// Api predictive
	"api-predictive-dash-api-optimization-container": {
		tooltip: {
			html: "<b>{{series.name}}</b> <p>{{y}}</p>",
			variables: {
				"series.name": {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
	// historic price
	"historic-price-track-cause-of-business": {
		tooltip: {
			html: "{{options.name}} <p>{{y}}</p>",
			variables: {
				"options.name": {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
	"elasticity-pricing-track-simulation-detail-chart": {
		tooltip: {
			series: {
				Real: "Real: {{y}}<br>",
				"Model Output": "Model Output: {{y}}<br> ",
				"No Pricing Simulation": "No Pricing Simulation: {{y}}<br>",
				Holidays: "{{options.dataLabels.name}}<br>",
			},
			variables: {
				category: {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
				"options.dataLabels.name": {
					isFormat: false,
					decimal: null,
				},
			},
		},
	},
	"Variable Impact On Volume by Quarter": {
		tooltip: {
			html: "{{name}} <p>{{y}}</p>",
			variables: {
				name: {
					isFormat: false,
					decimal: null,
				},
				y: {
					isFormat: true,
					decimal: 2,
				},
			},
		},
	},
};
