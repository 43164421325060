import React, { useState } from "react";
import { CardContent, Grid, Typography, Card, TextField } from "@mui/material";
import { Title, SubTitle, CancelButton, SaveButton } from "../../MMPW.Styled";
import _ from "lodash";

const ShiftAxis: React.FC<{ callback; xAxisData; yAxisData }> = ({ callback, xAxisData, yAxisData }) => {
	const [xAxis, setXAxis] = useState([...xAxisData]);
	const [yAxis, setYAxis] = useState([...yAxisData]);

	const onSave = () => {
		callback({ xAxis, yAxis });
	};

	const onCancel = () => {
		callback(null);
	};

	const setAxis = (axis, type, val, index) => {
		if (axis === "x") {
			const axis = [...xAxis];
			axis[index][type] = val;
			setXAxis(axis);
		}
		if (axis === "y") {
			const axis = [...yAxis];
			axis[index][type] = val;
			setYAxis(axis);
		}
	};

	return (
		<Card>
			<CardContent>
				<Grid item xs={12} display="flex" justifyContent="flex-end">
					<SaveButton onClick={onSave}>Save</SaveButton>
					<CancelButton onClick={onCancel}>Cancel</CancelButton>
				</Grid>
				<Grid item xs={12} className="m-b-20">
					<Title>Change Min and Max</Title>
					<SubTitle>High-chart library will automatically adjust the axis in the graph</SubTitle>
					<hr />
				</Grid>
				<Grid container xs={12}>
					<Grid sm={4}></Grid>
					<Grid sm={4}>Min</Grid>
					<Grid sm={4}>Max</Grid>
				</Grid>
				{_.isArray(xAxis)
					? xAxis.map((x, i) => (
							<Grid container display="flex" alignItems="center" key={`x-axis-grid-${i}`}>
								<Grid width={80}>
									<Typography color="black">X Axis</Typography>
								</Grid>
								<Grid width={150}>
									<TextField
										InputProps={{ autoComplete: "off" }}
										type="number"
										id="x-axis-min"
										value={x.min}
										margin="normal"
										onChange={(e) => {
											setAxis("x", "min", e.target.value, i);
										}}
										className="m-r-10"
									/>
								</Grid>
								<Grid width={150}>
									<TextField
										InputProps={{ autoComplete: "off" }}
										type="number"
										id="x-axis-max"
										value={x.max}
										margin="normal"
										onChange={(e) => {
											setAxis("x", "max", e.target.value, i);
										}}
									/>
								</Grid>
							</Grid>
					  ))
					: null}
				{_.isArray(yAxis)
					? yAxis.map((y, i) => (
							<Grid container display="flex" alignItems="center" key={`y-axis-grid-${i}`}>
								<Grid width={80}>
									<Typography color="black">{`Y${yAxis.length > 1 ? i + 1 : ""} Axis`}</Typography>
								</Grid>
								<Grid width={150}>
									<TextField
										InputProps={{ autoComplete: "off" }}
										type="number"
										value={y.min}
										margin="normal"
										id="y-axis-min"
										onChange={(e) => {
											setAxis("y", "min", e.target.value, i);
										}}
										className="m-r-10"
									/>
								</Grid>
								<Grid width={150}>
									<TextField
										InputProps={{ autoComplete: "off" }}
										type="number"
										value={y.max}
										margin="normal"
										id="y-axis-max"
										onChange={(e) => {
											setAxis("y", "max", e.target.value, i);
										}}
									/>
								</Grid>
							</Grid>
					  ))
					: null}
			</CardContent>
		</Card>
	);
};

export default ShiftAxis;
