import { GET_20_SEC_MARKET_CHECK, GET_20_SEC_MARKET_CHECK_FAILURE, GET_20_SEC_MARKET_CHECK_SUCESS } from "../../actions/constants";

let initialState = {
	data: null,
	loading: false,
	error: null,
};

const twentySecMarketCheck = (state = initialState, action) => {
	switch (action.type) {
		case GET_20_SEC_MARKET_CHECK:
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			};
		case GET_20_SEC_MARKET_CHECK_SUCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
				error: null,
			};
		case GET_20_SEC_MARKET_CHECK_FAILURE:
			return {
				...state,
				data: null,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default twentySecMarketCheck;
