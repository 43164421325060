import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { southAmericaMill } from "@react-jvectormap/southamerica";
import { northAmericaMill } from "@react-jvectormap/northamerica";
import _ from "lodash";
import Dropdown from "../../../../components/Dropdown";
import StatCard from "../../../../components/StatCard";
import { ICountryData, IStats } from "../../../../types/common";
import dataObject from "../../../../mocks/20SecMarketCheck";
import { countries } from "../../../../mocks/common";
import { BlackInputTitle } from "../../../../styles/Common.Styled";
import CountryMap from "../CountryMap";
import { fecth20SecMarketCheckData } from "../../../../store/actions/home/20secMarketCheck.action";
import { getCategoriesByCountry } from "../../../../util/services";
import { convertToInternationalCurrencySingleChar } from "../../../../util/helper";

const useStyles = makeStyles(() => ({
	CountryImage: { width: "auto", height: "130px" },
	countryContainer: {
		padding: "10px",
		height: "100%",
	},
	country: {
		display: "flex",
		justifyContent: "center",
	},
}));

const TwentySecMarketCheck: React.FC<{ onSortChange }> = ({ onSortChange }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:1240px)");
	const [skeleton, setSkeleton] = useState<boolean>(true);
	const [southAmericaSelectedCountries, setSouthAmericaSelectedCountries] = useState<ICountryData[]>([]);
	const [northAmericaSelectedCountries, setNorthAmericaSelectedCountries] = useState<ICountryData[]>([]);
	const [southAmericaMapData, setSouthAmericaMapData] = useState<string[]>([]);
	const [northAmericaMapData, setNorthAmericaMapData] = useState<string[]>(["MX"]);
	const userData = useSelector((store: RootStateOrAny) => store.User.data);

	const handleMapClick = ({ region, selectedRegions }) => {
		const filterCountries = _.isArray(selectedRegions) ? countries.filter((x) => selectedRegions.findIndex((y) => y === x.initial) !== -1) : [];
		if (region === "southAmerica") {
			setSouthAmericaMapData(selectedRegions);
			setSouthAmericaSelectedCountries(filterCountries);
		} else if (region === "northAmerica") {
			setNorthAmericaMapData(selectedRegions);
			setNorthAmericaSelectedCountries(filterCountries);
		}
	};

	const [kpiData1, setKpiData1] = useState(dataObject.KPIData1);
	const [kpiData2, setKpiData2] = useState(dataObject.KPIData2);
	const [autoCall, setAutoCall] = useState(true);
	const [countryList, setCountryList] = useState<any>([]);
	const [categories, setCategories] = useState<any>([]);
	const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

	const onChangeCountry = (e) => {
		const result = countries.filter((c) => e.findIndex((x) => x === c.value) !== -1);
		const southAmerica = result.filter((x) => x.region === "south_america_mill");
		setSouthAmericaSelectedCountries(southAmerica);
		setSouthAmericaMapData(southAmerica.map((x) => x.initial));

		const northAmerica = result.filter((x) => x.region === "north_america_mill");
		setNorthAmericaSelectedCountries(northAmerica);
		setNorthAmericaMapData(northAmerica.map((x) => x.initial));
		getCategories(e);
	};

	useEffect(() => {
		// Fetching userData from store and slicing data to show only the list of applicable countries and categories to user.
		if (userData !== null && countryList.length === 0) {
			getCountries();
		}
	}, [userData]);
	// This function fetches list of countries where user has permission and set default selected countries
	const getCountries = () => {
		const userPermissionCountryList = userData.permissionCountry.split(",");
		const filteredCountryList = countries.filter((country: any) => userPermissionCountryList.indexOf(country.value) !== -1);
		const userDefaultCountryList = userData.defaultCountry.split(",");
		const northAmericaDefaultCountryList: ICountryData[] = countries.filter(
			(country: any) => userDefaultCountryList.indexOf(country.value) !== -1 && country.region === "north_america_mill"
		);
		const southAmericaDefaultCountryList: ICountryData[] = countries.filter(
			(country: any) => userDefaultCountryList.indexOf(country.value) !== -1 && country.region === "south_america_mill"
		);
		const northAmericaDefaultCountryMapData = northAmericaDefaultCountryList.map((country: ICountryData) => country.initial);
		const southAmericaDefaultCountryMapData = southAmericaDefaultCountryList.map((country: ICountryData) => country.initial);
		setCountryList(filteredCountryList);
		setNorthAmericaSelectedCountries(northAmericaDefaultCountryList);
		setSouthAmericaSelectedCountries(southAmericaDefaultCountryList);
		setNorthAmericaMapData(northAmericaDefaultCountryMapData);
		setSouthAmericaMapData(southAmericaDefaultCountryMapData);
		getCategories(userDefaultCountryList);
	};
	// This function fetches list of categories where user has permission and set default selected categories
	const getCategories = (countries: string[]) => {
		const permissionCategories = userData.permissionCategory.split(",");
		getCategoriesByCountry(countries, userData.id)
			.then((response) => {
				const filteredCategoryList = response.data.filter((category: any) => permissionCategories.indexOf(category.value) !== -1);
				setCategories(filteredCategoryList);
				const userDefaultCategoryList = userData.defaultCategory.split(",");
				setSelectedCategories(userDefaultCategoryList.length ? userDefaultCategoryList : ["empty"]);
			})
			.catch((e) => {});
	};

	const onChangeCatrgory = (category) => {
		setSelectedCategories(category);
	};

	const twentySecMarketCheckData = useSelector((state: any) => state.twentySecMarketCheck);

	const formatKPIData = (response) => {
		const kpi1 = [...kpiData1];
		const kpi2 = [...kpiData2];
		const selectedCountries = [...northAmericaSelectedCountries.map((c) => c.value), ...southAmericaSelectedCountries.map((c) => c.value)];
		if (_.isArray(selectedCountries) && selectedCountries.length > 1) {
			kpi1[0].value = "--";
			kpi1[0].raise = undefined;
			kpi1[0].change = "--";
			kpi1[1].raise = undefined;
			kpi1[2].raise = undefined;
			kpi1[3].value = "--";
			kpi1[3].raise = undefined;
			kpi1[3].change = "--";
			kpi2[0].value = "--";
			kpi2[0].raise = undefined;
			kpi2[0].change = "--";
			kpi2[1].raise = undefined;
			kpi2[2].raise = undefined;
		} else {
			kpi1[0].value = response.sales.value ? convertToInternationalCurrencySingleChar("$", response.sales.value) : "0";
			kpi1[0].raise = response.sales.change ? response.sales.change >= 0 : null;
			kpi1[0].change = response.sales.change ? `${convertToInternationalCurrencySingleChar("", response.sales.change * 100)}%` : "0";
			kpi1[1].raise = response.volume.change ? response.volume.change >= 0 : null;
			kpi1[2].raise = response.units.change ? response.units.change >= 0 : null;
			kpi1[3].value = response.nrr.value ? convertToInternationalCurrencySingleChar("$", response.nrr.value) : "0";
			kpi1[3].raise = response.nrr.change ? response.nrr.change >= 0 : null;
			kpi1[3].change = response.nrr.change ? `${convertToInternationalCurrencySingleChar("", response.nrr.change * 100)}%` : "0";
			kpi2[0].value = response.shareSales.value ? `${convertToInternationalCurrencySingleChar("", response.shareSales.value * 100)}%` : "0";
			kpi2[0].raise = response.shareSales.change ? response.shareSales.change >= 0 : null;
			kpi2[0].change = response.shareSales.change ? `${convertToInternationalCurrencySingleChar("", response.shareSales.change * 100)}%` : "0";
			kpi2[1].raise = response.shareVolume.change ? response.shareVolume.change >= 0 : null;
			kpi2[2].raise = response.shareUnits.change ? response.shareUnits.change >= 0 : null;
		}
		kpi1[1].value = response.volume.value ? `${convertToInternationalCurrencySingleChar("", response.volume.value)}` : "0";
		kpi1[1].change = response.volume.change ? `${convertToInternationalCurrencySingleChar("", response.volume.change * 100)}%` : "0";
		kpi1[2].value = response.units.value ? convertToInternationalCurrencySingleChar("", response.units.value) : "0";
		kpi1[2].change = response.units.change ? `${convertToInternationalCurrencySingleChar("", response.units.change * 100)}%` : "0";
		kpi2[1].value = response.shareVolume.value ? `${convertToInternationalCurrencySingleChar("", response.shareVolume.value * 100)}%` : "0";
		kpi2[1].change = response.shareVolume.change ? `${convertToInternationalCurrencySingleChar("", response.shareVolume.change * 100)}%` : "0";
		kpi2[2].value = response.shareUnits.value ? `${convertToInternationalCurrencySingleChar("", response.shareUnits.value * 100)}%` : "0";
		kpi2[2].change = response.shareUnits.change ? `${convertToInternationalCurrencySingleChar("", response.shareUnits.change * 100)}%` : "0";
		kpi2[3].value = response.nrr.value ? convertToInternationalCurrencySingleChar("$", response.nrr.value) : "0";
		kpi2[3].raise = response.nrr.change ? response.nrr.change >= 0 : null;
		kpi2[3].change = response.nrr.change ? `${convertToInternationalCurrencySingleChar("", response.nrr.change * 100)}%` : "0";
		setKpiData1(kpi1);
		setKpiData2(kpi2);
	};

	useEffect(() => {
		const countries = [...southAmericaSelectedCountries, ...northAmericaSelectedCountries].map((x) => x.value.toUpperCase());
		const payload = {
			countries: countries.length ? countries : null,
			categories: selectedCategories.length ? selectedCategories : null,
			period: onSortChange,
			autoCall,
		};
		if (payload.countries && payload.countries.length && payload.categories && payload.categories.length && payload.period) {
			setSkeleton(true);
			dispatch(fecth20SecMarketCheckData(payload));
			if (autoCall) {
				setAutoCall(false);
			}
		}
	}, [dispatch, selectedCategories, onSortChange]);

	useEffect(() => {
		if (twentySecMarketCheckData && twentySecMarketCheckData.data && twentySecMarketCheckData.data.sales) {
			setSkeleton(false);
			formatKPIData(twentySecMarketCheckData.data);
		}
		if (twentySecMarketCheckData && twentySecMarketCheckData.error) {
			setSkeleton(false);
		}
	}, [twentySecMarketCheckData]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={3}>
				<Card className={classes.countryContainer}>
					<Grid container direction="column">
						<Grid className={classes.country}>
							<Grid container>
								<Grid item xs={6}>
									<CountryMap region="southAmerica" map={southAmericaMill} defaultCountries={southAmericaMapData} onChange={handleMapClick} />
								</Grid>
								<Grid item xs={6}>
									<CountryMap region="northAmerica" map={northAmericaMill} defaultCountries={northAmericaMapData} onChange={handleMapClick} />
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<BlackInputTitle>Filter by Country</BlackInputTitle>
								<Dropdown
									keyText="homepage-country-dropdown"
									data={countryList}
									multiple={true}
									multipleSelectionMsg="Multiple"
									placeholder="Select Country"
									allOption={true}
									defaultOption={[...northAmericaSelectedCountries.map((c) => c.value), ...southAmericaSelectedCountries.map((c) => c.value)]}
									onChange={onChangeCountry}
								/>
							</Grid>
							<Grid item xs={12}>
								<BlackInputTitle>Filter by Category</BlackInputTitle>
								<Dropdown
									keyText="homepage-country-dropdown"
									data={categories}
									placeholder="Select Category"
									defaultOption={selectedCategories}
									onChange={onChangeCatrgory}
									multiple={true}
									multipleSelectionMsg="Multiple"
									allOption={true}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<Grid item xs={12} md={9}>
				{matches ? (
					<Box>
						<Grid container spacing={2}>
							{kpiData1.map((item: IStats, i) => (
								<Grid item key={`set-1-stat-card-${i}`} style={{ minWidth: 280 }} className={item.className}>
									<StatCard data={item} showSkeleton={skeleton} />
								</Grid>
							))}
						</Grid>
						<Grid container spacing={2} className={"m-t-20"}>
							{kpiData2.map((item: IStats, i) => (
								<Grid item key={`set-2-stat-card-${i}`} style={{ width: 280 }} className={item.className}>
									<StatCard data={item} showSkeleton={skeleton} />
								</Grid>
							))}
						</Grid>
					</Box>
				) : (
					<Grid container spacing={2}>
						<Grid item xs={12} md={6} style={{ width: 280 }}>
							{kpiData1.map((item: IStats, i) => (
								<Box key={`set-1-stat-card-${i}`} className={kpiData1.length - 1 !== i ? "m-b-20" : undefined}>
									<StatCard data={item} showSkeleton={skeleton} />
								</Box>
							))}
						</Grid>
						<Grid item xs={12} md={6} style={{ width: 280 }}>
							{kpiData2.map((item: IStats, i) => (
								<Box key={`set-2-stat-card-${i}`} className={`m-b-20 ${item.className}`}>
									<StatCard data={item} showSkeleton={skeleton} />
								</Box>
							))}
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default TwentySecMarketCheck;
