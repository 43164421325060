import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { LoadMoreBtn } from "../Dashboard.Styled";
import NoDashboard from "../../../../components/NoDashboard/NoDashboard";
import DashboardPreviewCard from "../../../../components/DashboardPreviewCard/DashboardPreviewCard";
import { Grid } from "@mui/material";
import { getAllSavedDashboards, deleteSavedDashboard } from "../../../../util/services";
import { toast } from "../../../../store/actions/toast.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { dashboardCardMock } from "../../../../mocks/common";
import { sortData } from "../../../../util/helper";
import { loader } from "../../../../store/actions/common.action";

const SavedDashboard: React.FC<{ sortOrder }> = ({ sortOrder }) => {
	const dispatch = useDispatch();
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const [dashboards, setDashboards] = useState([]);
	const [allSavedDashboardsData, setAllSavedDashboardsData] = useState([]);
	const [skeleton, setSkeleton] = useState(true);

	const sortDashboards = (data) => {
		const column = sortOrder === "newest" ? "createdOn" : null;
		const sortedData = sortData(sortOrder, data, column);
		setAllSavedDashboardsData(sortedData);
	};

	useEffect(() => {
		if (allSavedDashboardsData.length) sortDashboards(allSavedDashboardsData);
	}, [sortOrder]);

	const loadMore = () => {
		setDashboards([...allSavedDashboardsData]);
	};

	useEffect(() => {
		if (_.isArray(allSavedDashboardsData) && allSavedDashboardsData.length) {
			setDashboards([..._.take(allSavedDashboardsData, 4)]);
		}
	}, [allSavedDashboardsData]);

	const fetchSavedDashboards = async () => {
		setSkeleton(true);
		let response = await getAllSavedDashboards(userData.id);
		if (response && _.isArray(response.data)) {
			const formattedResponse = response.data.map((s) => ({ ...s, route: `${s.route}?id=${s.id}` }));
			sortDashboards(formattedResponse);
		}
		setSkeleton(false);
	};

	useEffect(() => {
		if (userData && userData.id) {
			fetchSavedDashboards();
		}
	}, [userData]);

	const deleteSaveDashboard = (id) => {
		deleteSavedDashboard(id, userData.id).then(() => {
			dispatch(toast("Deleted Successfully!", true, 2000, "success"));
			fetchSavedDashboards();
			dispatch(loader(false));
		});
	};

	return (
		<Grid>
			{skeleton ? (
				<Grid container spacing={2} className="m-b-15">
					{dashboardCardMock.map((item, i) => (
						<Grid item xs={12} sm={3} md={3} key={`saved-dashboard-card-${i}-skeleton`}>
							<DashboardPreviewCard data={item} skeleton={true} isSaved={true} />
						</Grid>
					))}
				</Grid>
			) : dashboards?.length > 0 ? (
				<Grid container spacing={2} className="m-b-15">
					{dashboards?.map((item, i) => (
						<Grid item xs={12} sm={3} key={`saved-dashboard-card-${i}`}>
							<DashboardPreviewCard
								data={item}
								callback={deleteSaveDashboard}
								isSaved={true}
								showViewCount={false}
								showFavorite={false}
								onToggleFavorite={fetchSavedDashboards}
							/>
						</Grid>
					))}
				</Grid>
			) : (
				<Grid item xs={12} justifyContent="center">
					<NoDashboard heading="No dashboards available" />
				</Grid>
			)}
			<Box className="m-b-15" display="flex" justifyContent="center" alignItems="center">
				{dashboards.length !== allSavedDashboardsData.length ? <LoadMoreBtn onClick={loadMore}>Load More</LoadMoreBtn> : null}
			</Box>
		</Grid>
	);
};
export default SavedDashboard;
