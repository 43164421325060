import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Popover, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { INotificationCardType } from "../../../../types/common";
import { Title } from "./NotificationListCard.Styled";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { DateTime } from "luxon";
import { BugReportIcon, SystemAlertIcon, DashboardCreatedIcon, DataUpdatingIcon } from "../../../../assets/icons/notification";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	bugTypo: { color: "#FF721E !important" },
	alertTypo: { color: "#CE0065 !important" },
	newTypo: { color: "#0093CD !important" },
	updateTypo: { color: "#00AA52 !important" },
	readTypoTitle: { color: "#888888 !important" },
	readTypoSubTitle: {
		color: "#888888 !important",
	},
	readTypoShortDis: {
		color: "#888888 !important",
	},
	readTypoDateTime: {
		color: "#888888 !important",
	},
	icon: {
		width: "70px",
		borderRadius: "6px",
	},
}));

const NotificationListCard: React.FC<{
	data: INotificationCardType;
	callback?;
}> = ({ data, callback }) => {
	const classes = useStyles();
	const { type, title, subTitle, message, expiresOn, isRead, createdDate } = data;
	const expirationString = `${expiresOn < DateTime.now().toISO() ? "Expired" : "Expiring"} ${DateTime.fromISO(expiresOn, { zone: "utc" }).toRelative()}`;
	const createdOn = `Created ${DateTime.fromISO(createdDate, { zone: "utc" }).toRelative({
		base: this,
	})}`;
	const menues = ["Edit", "Mark as Read", "Delete"];
	const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
	const [anchorEl, setAnchorEl] = useState(null);
	const actionPopoverOpen = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const notificationAction = (type) => {
		handleClose();
		callback({ type });
	};
	const notificationIcon = (type: string) => {
		switch (type) {
			case "Bug":
				return <img src={BugReportIcon} alt="icon" className={classes.icon} />;
			case "System_Alert":
				return <img src={SystemAlertIcon} alt="icon" className={classes.icon} />;
			case "New_Dashboard_Created":
				return <img src={DashboardCreatedIcon} alt="icon" className={classes.icon} />;
			default:
				return <img src={DataUpdatingIcon} alt="icon" className={classes.icon} />;
		}
	};

	return (
		<>
			<Grid container style={{ marginTop: 20, marginBottom: 20 }}>
				<Grid item xs={2} sm={2} md={1} className="m-r-10">
					{notificationIcon(type)}
				</Grid>
				<Grid item xs={12} sm={10}>
					<Grid container style={{ marginBottom: 10 }}>
						<Grid item xs={12} sm={4} md={2}>
							{!isRead ? <Title>{title}</Title> : <Title color={theme.darkmode ? "#D6D2D2" : "#888888"}>{title}</Title>}
						</Grid>
						<Grid
							item
							sm={12}
							md={10}
							sx={{
								display: { xs: "none", md: "block" },
							}}
						>
							<Grid container>
								<Grid item xs={12} sm={2} display="flex" alignItems="center" justifyContent="center">
									{!isRead && (
										<Typography style={{ fontSize: 12 }} onClick={() => notificationAction("markAsRead")} className="cursor-pointer">
											Mark as read
										</Typography>
									)}
								</Grid>
								<Grid item xs={12} sm={3} display="flex" alignItems="center" justifyContent="center">
									{!isRead ? (
										<Typography style={{ fontSize: 12 }}>{createdOn}</Typography>
									) : (
										<Typography style={{ fontSize: 12 }} color={theme.darkmode ? "#D6D2D2" : "#888888"}>
											{createdOn}
										</Typography>
									)}
								</Grid>
								<Grid item xs={4} sm={2} display="flex" alignItems="center" justifyContent="center">
									{!isRead ? (
										<Typography style={{ fontSize: 12 }}>{expirationString}</Typography>
									) : (
										<Typography style={{ fontSize: 12 }} color={theme.darkmode ? "#D6D2D2" : "#888888"}>
											{expirationString}
										</Typography>
									)}
								</Grid>
								<Grid item xs={4} sm={0.6} style={{ fontSize: 12 }} display="flex" alignItems="center" justifyContent="center">
									<Typography>
										<MoreHoriz onClick={handleClick} className="cursor-pointer" />
									</Typography>
									<Popover
										open={actionPopoverOpen}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
									>
										<List>
											{!isRead && (
												<>
													<ListItem disablePadding>
														<ListItemButton onClick={() => notificationAction("edit")}>{menues[0]}</ListItemButton>
													</ListItem>
													<ListItem disablePadding>
														{!isRead ? (
															<ListItemButton onClick={() => notificationAction("markAsRead")}>{menues[1]}</ListItemButton>
														) : (
															<ListItemButton>{menues[1]}</ListItemButton>
														)}
													</ListItem>
												</>
											)}
											<ListItem disablePadding onClick={() => notificationAction("delete")}>
												<ListItemButton>{menues[2]}</ListItemButton>
											</ListItem>
										</List>
									</Popover>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{!isRead ? (
						<>
							<Typography style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }}>{subTitle}</Typography>
							<Typography style={{ fontSize: 12, minWidth: "50vw" }}>{message}</Typography>
						</>
					) : (
						<>
							<Typography style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }} color={theme.darkmode ? "#D6D2D2" : "#888888"}>
								{subTitle}
							</Typography>
							<Typography
								style={{
									fontSize: 12,
									minWidth: "50vw",
								}}
								color={theme.darkmode ? "#D6D2D2" : "#888888"}
							>
								{message}
							</Typography>
						</>
					)}
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					display: { xs: "flex", md: "none" },
				}}
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid item xs={2}>
					{!isRead && (
						<Typography style={{ fontSize: 12 }} onClick={() => notificationAction("markAsRead")} className="cursor-pointer">
							Mark as read
						</Typography>
					)}
				</Grid>
				<Grid item xs={3}>
					{!isRead ? (
						<Typography style={{ fontSize: 12 }}>{createdOn}</Typography>
					) : (
						<Typography style={{ fontSize: 12 }} color={theme.darkmode ? "#D6D2D2" : "#888888"}>
							{createdOn}
						</Typography>
					)}
				</Grid>
				<Grid item xs={3}>
					{!isRead ? (
						<Typography style={{ fontSize: 12 }}>{expirationString}</Typography>
					) : (
						<Typography style={{ fontSize: 12 }} color={theme.darkmode ? "#D6D2D2" : "#888888"}>
							{expirationString}
						</Typography>
					)}
				</Grid>
				<Grid item xs={2} style={{ fontSize: 12 }}>
					<Typography>
						<MoreHoriz onClick={handleClick} className="cursor-pointer" />
					</Typography>
					<Popover
						open={actionPopoverOpen}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
					>
						<List>
							{!isRead && (
								<>
									<ListItem disablePadding>
										<ListItemButton onClick={() => notificationAction("edit")}>{menues[0]}</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										{!isRead ? (
											<ListItemButton onClick={() => notificationAction("markAsRead")}>{menues[1]}</ListItemButton>
										) : (
											<ListItemButton>{menues[1]}</ListItemButton>
										)}
									</ListItem>
								</>
							)}
							<ListItem disablePadding onClick={() => notificationAction("delete")}>
								<ListItemButton>{menues[2]}</ListItemButton>
							</ListItem>
						</List>
					</Popover>
				</Grid>
			</Grid>
		</>
	);
};

export default NotificationListCard;
