import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Skeleton, Drawer, IconButton, Badge, Tooltip } from "@mui/material";
import { Title, ThemeTitle, FooterBox } from "./Dashboard.Styled";
import { mmpw, screenshot, editMedia } from "../../assets/images/dashboard";
import { copyScreenshotToClipboard, plotElementsOnChart, removeElementsOnChart } from "../../util/helper";
import MMPW from "../MMPW";
import Canvas from "../Canvas";
import { Icon, CloseButton } from "../../styles/Common.Styled";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { DrawerHeader } from "../../Layout/Layout.Styled";
import { Pin } from "../../assets/icons/mmpw/icons-v2";
import { DateTime } from "luxon";
import { pinConfig } from "../MMPW/config";
import { loader } from "../../store/actions/common.action";
import html2canvas from "html2canvas";
import { updateStylePropertyValue } from "../../util/cssHelperFunctions";
const Dashboard: React.FC<{
	title?;
	chartRef?;
	id;
	themeTitle?: boolean;
	showSkeleton?: boolean;
	showTrend?: boolean;
	showActionButton?: boolean;
	actionButtons?;
	tooltip?;
	mmpwConfig?;
}> = ({
	title,
	themeTitle = false,
	children,
	chartRef,
	showSkeleton,
	id,
	showTrend,
	showActionButton = true,
	actionButtons = {
		mmpw: true,
		screenshot: true,
		editMedia: true,
	},
	tooltip,
	mmpwConfig,
}) => {
	const [isMMPWOpen, setIsMMPWOpen] = useState(false);
	const [canvasData, setCanvasData] = useState(null);
	const [canvasMode, setCanvasMode] = useState("");
	const [mmpwLocalStorageData, setMMPWLocalStorageData] = useState<any>({});
	const [openCommentSection, setOpenCommentSection] = useState(false);
	const containerRef = useRef(null);
	const [screenshotImage, setScreenshotImage] = useState(null);
	const [footerNoteData, setFooterNoteData] = useState([]);
	const dispatch = useDispatch();

	const getFooterNotesFromLocalstorage = () => {
		let data = JSON.parse(localStorage.getItem("mmpw-footer"));
		if (data && data[id]) {
			setFooterNoteData(data[id]);
		}
	};

	const onClickMMPW = () => {
		if (!canvasMode && !canvasData && chartRef && chartRef.current && chartRef.current.chart) {
			setIsMMPWOpen(true);
		}
	};

	const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
	useEffect(() => {
		if (chartRef && chartRef.current && chartRef.current.chart) {
			setTimeout(() => window.dispatchEvent(new Event("resize")), 100);
		}
	}, [sidebarOpen]);

	const mmpwCallback = (data) => {
		if (!data) {
			data = {};
		}
		if (data.type === "close" || data.type === "back") {
			if (!canvasData || !_.isArray(canvasData.Text)) {
				setCanvasData(null);
				setCanvasMode(null);
				// Triggering a window resize event so that plotlines become draggable on change
				if (id === "revenue-map-quadrant-intensity-graph" && data.type === "close") window.dispatchEvent(new Event("resize"));
			}
			setIsMMPWOpen(false);
		} else if (data.type === "footer-note") {
			if (data.note) {
				const newData = [...footerNoteData, { note: data.note, color: data.color }];
				setFooterNoteToLocalStorage(newData);
			}
			setCanvasMode(null);
			setIsMMPWOpen(false);
		} else if (["Text", "Image", "Icon", "Shape", "Comment", "CustomIcon"].includes(data.type)) {
			const newData = {
				...canvasData,
				[data.type]: [...(_.isObject(canvasData) && _.isArray(canvasData[data.type]) ? canvasData[data.type] : []), data],
			};
			setCanvasData(newData);
			setCanvasMode("add");
			setIsMMPWOpen(false);
		} else if (["fontFamily", "fontSize", "textColor", "fontStyle", "fontWeight", "textDecoration"].includes(data.type)) {
			const newData = { ...(mmpwLocalStorageData ?? {}) };
			_.isArray(newData.Text) &&
				newData.Text.map((x) => {
					x.style.fontFamily = updateStylePropertyValue(x.style.fontFamily, data.fontFamily, data.type, "fontFamily", x.style.fontFamily);
					x.style.fontSize = updateStylePropertyValue(x.style.fontSize, data.fontSize, data.type, "fontSize", null);
					x.style.fontStyle = updateStylePropertyValue(x.style.fontStyle, data.fontStyle, data.type, "fontStyle", "");
					x.style.fontWeight = updateStylePropertyValue(x.style.fontWeight, data.fontWeight, data.type, "fontWeight", "");
					x.style.textDecoration = updateStylePropertyValue(x.style.textDecoration, data.textDecoration, data.type, "textDecoration", "");
					x.style.color = data.color ?? x.style.color;
				});
			setCanvasData(newData);
			setCanvasMode("global-edit");
		} else if (data.type === "ChartConfig") {
			const newData = {
				...canvasData,
				chartConfig: {
					...(canvasData && canvasData.chartConfig ? canvasData.chartConfig : {}),
					...data.payload,
				},
			};
			setCanvasData(newData);
			setCanvasMode("add");
			setIsMMPWOpen(false);
		} else if (data.type === "SectionColor") {
			const newData = {
				...canvasData,
				series: [...(canvasData && _.isArray(canvasData.series) ? canvasData.series : []), ...data.payload],
			};
			setCanvasData(newData);
			setCanvasMode("add");
			setIsMMPWOpen(false);
		} else if (data.type === "LineWidth") {
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;
				const newData = {
					...(mmpwLocalStorageData ?? {}),
				};
				if (newData && _.isArray(newData.series)) {
					newData.series.map((item, i) => {
						if (chart.series[i].options.borderWidth) {
							item.borderWidth = data.payload.lineWidth;
						} else {
							item.lineWidth = data.payload.lineWidth;
						}
					});
				} else {
					const payload = [];
					if (chart) {
						chart.series.forEach((item, i) => {
							if (item.visible) {
								let temp = {
									borderWidth: item.userOptions.borderWidth,
									lineWidth: item.userOptions.lineWidth,
								};
								if (item.userOptions.borderWidth) {
									temp.borderWidth = data.payload.lineWidth;
								} else {
									temp.lineWidth = data.payload.lineWidth;
								}
								payload.push({
									...temp,
									title: item.name,
								});
							}
						});
						newData.series = payload;
					}
				}
				setCanvasData({
					...canvasData,
					series: newData.series,
				});
				setCanvasMode("add");
				setIsMMPWOpen(false);
			}
		} else if (data.type === "LineBorderColor") {
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;
				const newData = {
					...(mmpwLocalStorageData ?? {}),
				};
				if (newData && _.isArray(newData.series)) {
					newData.series.map((item, i) => {
						const color = _.isArray(data.payload) && data.payload.find((x) => x.index === i);
						if (chart.series[i].options.borderColor && color) {
							item.borderColor = color.borderColor;
						}
					});
				} else {
					const payload = [];
					if (chart) {
						chart.series.forEach((item, i) => {
							if (item.visible) {
								const color = _.isArray(data.payload) && data.payload.find((x) => x.index === i);
								payload.push({
									title: item.name,
									borderColor: color ? color.borderColor : item.options.borderColor,
								});
							}
						});
						newData.series = payload;
					}
				}
				setCanvasData({
					...canvasData,
					series: newData.series,
				});
				setCanvasMode("add");
				setIsMMPWOpen(false);
			}
		}
	};

	const setFooterNoteToLocalStorage = (newFooterNoteData) => {
		setFooterNoteData(newFooterNoteData);
		if (id) {
			let payload = {
				...JSON.parse(localStorage.getItem("mmpw-footer")),
				[id]: newFooterNoteData,
			};
			localStorage.setItem("mmpw-footer", JSON.stringify(payload));
		}
	};

	const onScreenshot = () => {
		dispatch(loader(true));
		setScreenshotImage(null);
		setTimeout(() => {
			takeScreenShot(containerRef.current);
		}, 0);
	};

	const takeScreenShot = (node: HTMLElement) => {
		if (!node) {
			dispatch(loader(false));
			throw new Error("You should provide correct html node.");
		}
		if (node.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0])
			node.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].style.background = "white";
		html2canvas(node)
			.then((canvas) => {
				const croppedCanvas = document.createElement("canvas");
				const croppedCanvasContext = croppedCanvas.getContext("2d");
				// init data
				const cropPositionTop = 0;
				const cropPositionLeft = 0;
				const cropWidth = canvas.width;
				const cropHeight = canvas.height;
				croppedCanvas.width = cropWidth;
				croppedCanvas.height = cropHeight;
				croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);
				const base64Image = croppedCanvas.toDataURL();
				setScreenshotImage(base64Image);
			})
			.catch(() => {
				dispatch(loader(false));
			});
	};

	useEffect(() => {
		if (screenshotImage) {
			copyScreenshotToClipboard(screenshotImage, dispatch);
		} else {
			dispatch(loader(false));
		}
	}, [screenshotImage]);

	const onEditMedia = () => {
		if (canvasMode !== "edit" && chartRef && chartRef.current && chartRef.current.chart) {
			setCanvasData(null);
			setIsMMPWOpen(false);
			setCanvasMode("edit");
		}
	};

	const deleteFooterHandler = (footerNote) => {
		let newFoolterNotes = [...footerNoteData];
		const index = newFoolterNotes.findIndex((item) => item.id === footerNote.id);
		if (index !== -1) {
			newFoolterNotes.splice(index, 1);
		}
		setFooterNoteToLocalStorage(newFoolterNotes);
	};

	useEffect(() => {
		window.addEventListener("removed-storage", (dt) => {
			getFooterNotesFromLocalstorage();
		});

		window.addEventListener("storage", (dt) => {
			let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
			if (dashbaordData && dashbaordData[id]) {
				setMMPWLocalStorageData(dashbaordData[id]);
			}
		});

		return () => {
			window.removeEventListener("storage", () => {});
			window.removeEventListener("removed-storage", () => {});
		};
	}, []);

	useEffect(() => {
		if (!mmpwConfig || (mmpwConfig && !mmpwConfig.disableRetriveMMPW)) {
			let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
			if (chartRef && chartRef.current && chartRef.current.chart && dashbaordData && dashbaordData[id]) {
				const chart = chartRef.current.chart;
				plotElementsOnChart(dashbaordData[id], chart, id);
			}
		}
		getFooterNotesFromLocalstorage();
	}, [chartRef && chartRef.current]);

	const resetData = () => {
		setTimeout(() => {
			let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
			if (dashbaordData && dashbaordData[id]) {
				removeElementsOnChart(id);
				setMMPWLocalStorageData(dashbaordData[id]);
				if (chartRef && chartRef.current && chartRef.current.chart) {
					const chart = chartRef.current.chart;
					plotElementsOnChart(dashbaordData[id], chart, id);
				}
			}
		}, 0);
	};

	return (
		<Grid container ref={containerRef} id={`${id}-screenshot`}>
			<Grid container display="flex" justifyContent={title ? "space-between" : "flex-end"}>
				{title ? (
					<Grid item xs={12} sm={6} md={8} className="m-b-10">
						<Grid container>
							<Tooltip title={tooltip ?? title}>
								<Box className="ellipsis">
									{!themeTitle && (
										<Title className="ellipsis">
											<span dangerouslySetInnerHTML={{ __html: title }}></span>
										</Title>
									)}
									{themeTitle && (
										<ThemeTitle className="ellipsis">
											<span dangerouslySetInnerHTML={{ __html: title }}></span>
										</ThemeTitle>
									)}
								</Box>
							</Tooltip>
						</Grid>
					</Grid>
				) : null}
				<Grid item className="m-b-10">
					<Grid container>
						{!showSkeleton ? (
							showActionButton && (
								<>
									{actionButtons.mmpw && (
										<Icon
											style={{
												marginLeft: 0,
												pointerEvents: canvasMode && canvasMode === "edit" ? "none" : "visible",
											}}
											onClick={onClickMMPW}
											src={String(mmpw)}
										/>
									)}
									{actionButtons.screenshot && <Icon onClick={onScreenshot} src={String(screenshot)} />}
									{actionButtons.editMedia && (
										<Icon
											style={{
												pointerEvents: canvasMode && canvasMode === "add" ? "none" : "visible",
											}}
											onClick={onEditMedia}
											src={String(editMedia)}
										/>
									)}
									{mmpwLocalStorageData && _.isArray(mmpwLocalStorageData.Comment) && mmpwLocalStorageData.Comment.length > 0 && (
										<Box
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												marginLeft: 10,
												borderRadius: 5,
												fill: "#fff",
												background: "#005EA6",
												height: 35,
												width: 35,
												cursor: "pointer",
											}}
										>
											<Pin onClick={() => setOpenCommentSection(true)} style={{ width: 20, height: 20 }} />
										</Box>
									)}
								</>
							)
						) : (
							<>
								{[...Array(3).keys()].map((i) => (
									<Skeleton key={`skeleton-${i}`} variant="rectangular" width={40} height={35} className="m-r-5" style={{ backgroundColor: "#E0E3E8" }} />
								))}
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} id={id}>
					{children && (
						<div style={{ position: "relative" }}>
							{children}
							{canvasMode && (
								<Canvas
									chartRef={chartRef}
									close={() => {
										setCanvasMode(null);
										setCanvasData(null);
										resetData();
									}}
									mode={canvasMode}
									data={canvasData}
									id={id}
								/>
							)}
						</div>
					)}
					{!showSkeleton &&
						footerNoteData &&
						footerNoteData?.map((footerNote, i) => (
							<FooterBox key={`footer-${i}`} color={footerNote.color} style={{ position: "relative" }} className="m-b-20">
								<CloseButton onClick={() => deleteFooterHandler(footerNote)}>
									<DeleteIcon fontSize="small" />
								</CloseButton>
								{footerNote.note}
							</FooterBox>
						))}
				</Grid>
			</Grid>
			{isMMPWOpen && (
				<MMPW
					data={mmpwLocalStorageData.Text}
					chartRef={chartRef}
					open={isMMPWOpen}
					callback={mmpwCallback}
					dashboardId={id}
					selectedMenu="1"
					config={mmpwConfig}
				/>
			)}
			{openCommentSection && (
				<Drawer variant="permanent" anchor="right">
					<DrawerHeader />
					<Box
						style={{
							width: 450,
							height: "100vh",
							padding: 20,
							background: "#fff",
							color: "#000",
							overflow: "auto",
						}}
					>
						<Grid container display="flex" justifyContent="space-between" alignItems="start">
							<Grid item xs={10}>
								<Tooltip title={tooltip ?? title}>
									<Title>
										<span dangerouslySetInnerHTML={{ __html: title }}></span>
									</Title>
								</Tooltip>
							</Grid>
							<Grid>
								<IconButton onClick={() => setOpenCommentSection(false)} component="span">
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
						{mmpwLocalStorageData &&
							mmpwLocalStorageData.Comment &&
							mmpwLocalStorageData.Comment.map((item, i) => (
								<Grid className="m-t-20" key={`tile-grid-${i}`}>
									<Grid container>
										<Grid item className="m-r-20">
											{pinConfig === "number" ? (
												<Badge badgeContent={item.commentNumber} color="secondary">
													<Pin style={{ width: 30, height: 30 }} />
												</Badge>
											) : (
												<img src={item.base64} alt="" />
											)}
										</Grid>
										<Grid item>
											<Box style={{ fontSize: 18, fontWeight: 500 }}>{item.user}</Box>
											<Box>{DateTime.fromISO(item.date).toRelative()}</Box>
										</Grid>
									</Grid>
									<Grid container className="m-t-10">
										<Box style={{ fontSize: 16 }}>{item.content}</Box>
									</Grid>
								</Grid>
							))}
					</Box>
				</Drawer>
			)}
		</Grid>
	);
};
export default Dashboard;
