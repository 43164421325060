import {
	GET_PRODUCT_FILTERS,
	GET_PRODUCT_FILTERS_SUCESS,
	GET_PRODUCT_FILTERS_FAILURE,
	ON_CHANGE_PRODUCT_OPTION,
	CLEAR_PRODUCT_FILTERS,
} from "../actions/constants";
import { mapFilterOptions, selectAllOptions } from "../../util/helper";
import _ from "lodash";

export const productInitialState = {
	data: null,
	loading: false,
	error: null,
	selectedFilters: {
		category: null,
		segment: null,
		brand: null,
		subBrand: null,
		packSize: null,
	},
	disabledFilters: {
		category: true,
		segment: true,
		brand: true,
		subBrand: true,
		packSize: true,
	},
};
const setValues = (previous, payload) => {
	const key = Object.keys(payload)[0];
	const val = payload[key];

	switch (key) {
		case "category":
			payload = {
				category: val,
				segment: null,
				brand: null,
				subBrand: null,
				packSize: null,
			};
			break;
		case "segment":
			payload = {
				...previous,
				segment: val,
				brand: null,
				subBrand: null,
				packSize: null,
			};
			break;
		case "brand":
			payload = {
				...previous,
				brand: val,
				subBrand: null,
				packSize: null,
			};
			break;
		case "subBrand":
			payload = {
				...previous,
				subBrand: val,
				packSize: null,
			};
			break;
		case "packSize":
			payload = {
				...previous,
				packSize: val,
			};
			break;
	}
	return payload;
};

const productFilter = (state = productInitialState, action) => {
	switch (action.type) {
		case GET_PRODUCT_FILTERS:
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			};
		case GET_PRODUCT_FILTERS_SUCESS:
			return {
				...state,
				data: mapFilterOptions(action.payload, action.filterData),
				loading: false,
				error: null,
				selectedFilters: { ...state.selectedFilters, ...selectAllOptions(action.payload, action.filterData) },
				disabledFilters: {
					...state.disabledFilters,
					category: false,
				},
			};
		case GET_PRODUCT_FILTERS_FAILURE:
			return {
				...state,
				data: null,
				loading: false,
				error: action.payload,
			};
		case ON_CHANGE_PRODUCT_OPTION:
			return {
				...state,
				selectedFilters: setValues(state.selectedFilters, action.payload),
				disabledFilters: disabledFilters(action.payload),
			};
		case CLEAR_PRODUCT_FILTERS:
			return {
				...productInitialState,
			};
		default:
			return state;
	}
};

export default productFilter;

const disabledFilters = (payload) => {
	let data = {
		category: true,
		segment: true,
		brand: true,
		subBrand: true,
		packSize: true,
	};
	const key = Object.keys(payload)[0];
	let val = payload[key];
	if (_.isArray(val) && val.length === 0) {
		val = null;
	}

	switch (key) {
		case "category":
			data = {
				...data,
				category: false,
				segment: val === null,
			};
			break;
		case "segment":
			data = {
				...data,
				category: false,
				segment: false,
				brand: val === null,
			};
			break;
		case "brand":
			data = {
				...data,
				category: false,
				segment: false,
				brand: false,
				subBrand: val === null,
			};
			break;
		case "subBrand":
			data = {
				...data,
				category: false,
				segment: false,
				brand: false,
				subBrand: false,
				packSize: val === null,
			};
			break;
		case "packSize":
			data = {
				...data,
				category: false,
				segment: false,
				brand: false,
				subBrand: false,
				packSize: false,
			};
			break;
	}
	return data;
};
