import MuiDrawer from "@mui/material/Drawer";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { Box } from "@mui/system";

export const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(9)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
}));

interface FlotButtonProps extends ButtonProps {
	open?: boolean;
}

const FlotButton = styled(Button, { shouldForwardProp: (prop) => prop !== "open" })<FlotButtonProps>`
	&& {
		border-radius: 50%;
		border: 1px solid #fff;
		position: fixed;
		top: 50vh;
		min-width: 35px;
		min-height: 35px;
		max-width: 35px;
		max-height: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		box-shadow: 0px 1px 2px #00000029;
		left: ${(props) => (props.open ? "220px" : "55px")};
		background: ${(props) => props.theme.palette.common.white};
		color: ${(props) => props.theme.palette.grey[500]};
		transition: ${(props) =>
			props.theme.transitions.create(["left", "margin"], {
				easing: props.theme.transitions.easing.sharp,
				duration: props.open ? props.theme.transitions.duration.leavingScreen : props.theme.transitions.duration.enteringScreen,
			})};
	}
`;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
	"& MuiTypography-root": {
		fontSize: "12px",
	},
}));

const MainContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	minHeight: "100vh",
}));

export { Drawer, FlotButton, DrawerHeader, MainContainer };
