export const productLogos = [
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "3DS REGULAR",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "ARCOR GARRAPINADAS MANI",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "ARCOR NUTS",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "EL PEONCITO REGULAR",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "NIKITOS POPCORN",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "NIKITOS POTATO",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "NIKITOS WHEAT",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "PEDRIN NUTS",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "PEDRIN REGULAR",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "PEHUAMAR ACANALADAS",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "PEHUAMAR CLASICAS",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "PEHUAMAR MANI JAPONES",
	},
	{
		type: "jpg",
		country: "ARGENTINA",
		value: "TOSTITOS REGULAR",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "CHEETOS REGULAR",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "CROOPERS MIX",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "CROOPERS REGULAR",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "DORITOS FLAVOURS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "DORITOS QUESO",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "GOOD SHOW POTATO",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "KRACHITOS EXTRUDED",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "KRACHITOS NUTS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "KRACHITOS POTATO",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "KRACHITOS WHEAT",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "LAYS ACANALADAS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "LAYS CLASSIC",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "LAYS FLAVOURS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "MACRITAS REGULAR",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "NIKITOS EXTRUDED",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "PEDRIN EXTRUDED",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "PEDRIN OTHER",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "PEHUAMAR MANI PELADO",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "PEP PALITOS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "PEP RUEDITAS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "PRINGLES REGULAR",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "QUENTO NUTS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "QUENTO REGULAR",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "QUENTO WHEAT",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "SALADIX BACONZITOS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "SALADIX CROSS WHEAT",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "SALADIX CROSS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "SALADIX KESITOS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "SALADIX PANCHITOS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "SALADIX PIZZITAS",
	},
	{
		type: "png",
		country: "ARGENTINA",
		value: "SALADIX POTATO",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "BACONZITOS REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "BARCEL KIYAKIS JAPONES",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "BARCEL NUTS OTHER",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "BOGOS NUTS",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "BOKADOS NUTS",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "CEBOLITOS REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "DAVID PEPITA",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "DORITOS HEATWAVE",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "DPD REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "FANDANGOS REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "FARM NUTS NUECES GOURMET HORNEADAS",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "FOFURA JR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "FOFURA MIX",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "FOFURA REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "KACANG BLAZIN",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "KARATE",
	},
	{
		type: "jpeg",
		country: "BRAZIL",
		value: "KURUMAYA JAPONES",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "LANCHINHO REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "LEO CACAHUATE JAPONES",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "LUCKY PENTA",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "MICOS EXTRUDED REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "MIKAO EXTRUDED REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "NA MESA",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "NATURES HEART NUEZ",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "PINGO DOURO REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "PLANTERS MIXED",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "PLANTERS TOSTADO",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "PROVI JAPONES",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "QUAKER OATS FLAKES.JPG",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "STAX REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "STIKSY REGULAR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "TORCIDA JR",
	},
	{
		type: "jpg",
		country: "BRAZIL",
		value: "TORCIDA REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "AMENDUPA REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BARCEL GOLDEN NUTS REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BARCEL GOLDEN PEPITA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BARCEL HOT NUTS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BARCEL KIYAKIS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BARCEL NUTS OTHER SUBBRAND",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BIG BILU REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BILU EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BILU OTHER SALTY SNACKS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BILU POPCORN RTE REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BILU POTATO REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BILUZITOS EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BILUZITOS OTHER REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BOKADOS ENCHILADO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BOKADOS JAPONES",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BOKADOS MIX",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BOKADOS SAL",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "BRAZITOS REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CASHITAS NUTS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CASHITAS PEPITA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CASHITAS SEMILLAS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CHEETOS ASSADO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CHEETOS BOLA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CHEETOS ESQUELETO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CHEETOS LUA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CHEETOS MIX",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CHEETOS ONDA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CHEETOS POPCORN",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "CHEETOS TUBO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "DAVID",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "DORITOS REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "DPD ENCHILADOS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "ELBIS REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "ELBIS WATER AND SALT",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "ENCANTO NUTS SEMILLA GIRASOL",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "ENCANTO NUTS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "GOLDEN NUTS ENCHILADO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "GOLDEN NUTS JAPONES",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "GOLDEN NUTS PEPITA REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "GOLDEN NUTS SALADAS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "GULAO EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "GULOZITOS EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "GULOZITOS OTHER REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "HOT NUTS CUBIERTO DE CHILE",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "HOT NUTS FUEGO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KACANG ENCHILADO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KACANG FLAMIN HOT",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KACANG HABANERO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KACANG JAPONES",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KACANG PEPITA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KACANG SAL",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KACANG",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KARATE ENCHILADO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KARATE JAPONES",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KARATE SALSA NEGRA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KELECK EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KELECK OTHER REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "KELECK POTATO REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "LAYS REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "LEO CACAHUATE",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "LEO PEPITA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "LEON",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER ENCHILADO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER JAPONES CLASICO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER JAPONES LIMON",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER MIX ARANDANO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER MIX FRESA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER MIX VARIETY",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER SAL",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER TOSTADO SAZONADO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAFER",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MAIS PURA REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "MILIOPA EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "NATURES HEART NUTS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "NIPON ENCHILADO",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "NIPON JAPONES",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "NIPON SALADAS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "NIPON",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "NISHIKAWA JAPONES",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "NISHIKAWA",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "PIPOTECA EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "PIPPOS REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "PLANTERS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "PRINGLES REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "PROVI NUTS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "QUAKER OATS FINE FLAKE",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "QUAKER OATS FINE FLAKES",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "QUAKER OATS FLAKES",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "QUAKER OATS REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "RUFFLES REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "RUFFLES STAX",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "SCRUSCH OTHER REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "SCRUSCH POTATO REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "SENSACOES ELMA CHIPS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "SOL NUTS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "TOTIS NUTS",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "VISCONTI REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "YOKI OTHER REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "YOKI POPCORN-RTE REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "YOKITOS EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "YOKITOS POTATO REGULAR",
	},
	{
		type: "png",
		country: "BRAZIL",
		value: "ZEZE OTHER REGULAR",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "ARCOR MIXED",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "CHIS POP REGULAR",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "EVERCRISP NUTS JAPONES",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "EVERCRISP NUTS MIXED",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "EVERCRISP NUTS PREMIUM",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "EVERCRISP NUTS SAL",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "EVERCRISP NUTS SIN SAL",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "EVERCRISP VARIETY",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "GATOLATE REGULAR",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "KRYZPO AIR CRUNCH",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "KRYZPO REGULAR",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "LAYS ARTESANAS",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "LAYS CLASSIC",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "LAYS FLAVOURS",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "LAYS MAXX",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "LAYS MEDITERRANEAS",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "LAYS WAVY",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "MILLANTU FLAVORED",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "MILLANTU MIXED",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "PANCHO VILLA NACHOS",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "RAMITAS EVERCRISP",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "SNACK MIX REGULAR",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "TOSTITOS REGULAR",
	},
	{
		type: "jpg",
		country: "CHILE",
		value: "TRAGA TRAGA REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "BARCEL",
	},
	{
		type: "png",
		country: "CHILE",
		value: "CAFE DO BRASIL",
	},
	{
		type: "png",
		country: "CHILE",
		value: "CHEETOS PUFF",
	},
	{
		type: "png",
		country: "CHILE",
		value: "CHEETOS REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "CHEEZELS REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "CRUGN BKN EXTRUDED",
	},
	{
		type: "png",
		country: "CHILE",
		value: "CRUGN BKN HEALTHY",
	},
	{
		type: "png",
		country: "CHILE",
		value: "DORITOS HEATWAVE",
	},
	{
		type: "png",
		country: "CHILE",
		value: "DORITOS REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "EL ARRIERO REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "FRUTISA MIXED",
	},
	{
		type: "png",
		country: "CHILE",
		value: "FRUTISA SAL",
	},
	{
		type: "png",
		country: "CHILE",
		value: "KELLOGGS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO CASERAS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO CORTE AMERICANO",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO CRUNCHIS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO MIX",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO MIXED",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO NUTS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO OTHER",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO PREMIUM",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO RAMITAS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO RUSTICAS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO SABORES",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO SAL",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO SNACK MIX",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MARCO POLO TORTILLA",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MILLANTU JAPONES",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MILLANTU PREMIUM",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MILLANTU SAL",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MOMS CASERAS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MOMS CHAPARRITOS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MOMS CHOCLITOS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "MOMS SUFLES",
	},
	{
		type: "png",
		country: "CHILE",
		value: "PANCHO VILLA NACHO",
	},
	{
		type: "png",
		country: "CHILE",
		value: "PANCHO VILLA PANCHITOS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "PANCHO VILLA PAPAS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "PEPSICO",
	},
	{
		type: "png",
		country: "CHILE",
		value: "PRIMOR REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "PRINGLES REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "STAX REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TAKIS REGULAR",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TIKA CHIPS",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TIKA NATIVE",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TIKA TORTIKA",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TIM EXTRUDED",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TIM OTHER",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TIM POTATO",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TIM SAL",
	},
	{
		type: "png",
		country: "CHILE",
		value: "TIM WHEAT",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "BARRITAS MINI",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "BARRITAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "BELVITA HONY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "BELVITA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CANELITAS BITES",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CANELITAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CHEESE TRIS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CHIPS AHOY REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CHOCLITOS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CHOLCITOS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "COLOMBINA HEALTHY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "COLOMBINA POPCORN",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "COLOMBINA POTATO",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA ANIMALITOS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA CHOC CHIP REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA CHOC CHIP",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA DIAVOLIN",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA HOJALDRADA REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA MARIAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA MARIAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA MARSHMALLOW",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA MEXICANAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA NICKELODEON",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA SANDWICH DEPORTIVA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA SANDWICH EL REY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA SANDWICH JR COMBINADO",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA SANDWICH MIL",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA SANDWICH",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA TRAD CANELA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA TRAD GRAGEA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA TRAD MATRAQUILLAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA TRAD PALMERA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA TRAD POLVORON",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA TRAD RECOCO",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA TRAD ROSCAYEMA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA TRAD",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA VARIETY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA WAFER NAPOLITANA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA WAFER REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA WAFER TOLOSA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "CUETARA WAFER",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DE TODITO SWEET & SALTY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DELALLO REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DELIMANI REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DONDE ANIMALITOS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DONDE GLOBITOS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DONDE MARIAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DONDE POLVORON",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DONDE RIKAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DONDE SOLES",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DONDE VARIETY FANTASIA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "DORITOS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "FRITO LAY LONCHERA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "FRITO LAY PROMOTIONAL HEALTHY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "FRITO LAY PROMOTIONAL MIX",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "FROOT LOOPS GALLETAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GABI BESOS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GABI OREJITAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GABI POLVORON",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GABI REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GABI TARTAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GANSITO REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GAPSA MARIAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GOLDEN FOODS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GRANVITA AVENAS COOKIES",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "GRANVITA AVENITAS COOKIES",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "JACKS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA ESPECIAL REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA ANIMALITOS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA KANELIUX",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA MARIANITAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA MARIAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA MARIAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA SANDWICH",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA SAURIS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA TRAD",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA VARIETY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA WAFER",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LA MODERNA XTRA NIEVES",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LARA ANIMALITOS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LARA CHOCO CHIPS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LARA DELICIOSAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LARA MARIAS REGULAR",
	},
	{
		type: "jpeg",
		country: "COLOMBIA",
		value: "LARA VARIETY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "LORS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA ABANICO",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA CHOCCO",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA CHOCOLATE",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA FRESA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA HAZELNUT",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA NATA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA VARIEDADES REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA VARIEDADES",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA VARIETY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA WAFEER",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA WAFER REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MACMA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MANIMOTO MIX",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MANIMOTO REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARGARITA KETTEL",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARGARITA REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARGARITA WAVY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIAN BOLITAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIAN NOCHE BUENA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIAN REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIAN VARIETY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIBEL ANIMALITOS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIBEL CHISPITAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIBEL DORADAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIBEL MARIAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MARIBEL TOSCANA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "MEKATO REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "NABISCO MARBU DORADA",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "NATUCHIPS PLATANO",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "NATUCHIPS RICE",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "OREO DOUBLE STUFF",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "OREO FUDGE",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "OREO MINI",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "OREO WHITE FUDGE",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "PAGASA ANIMALITOS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "PAGASA BETUNADAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "PAPADOPOULOS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "POPETAS MIX",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "PRINCIPE REGULAR",
	},
	{
		type: "jpeg",
		country: "COLOMBIA",
		value: "PRINCIPE TENTACION",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "ROCKO MINI",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "ROCKO MOBI RECULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "ROCKO REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "SANTIVERI OTHER COOKIES",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "SANTIVERI OTHER",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "SPONCH REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "SUANDY PATISETAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "SUAVICREMAS REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TAIFELDS SUPER",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TAIFLEDS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TIA ROSA TARTINAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TIA ROSA TRADITIONALS GALLETA OATS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TIA ROSA TRADITIONALS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TRES ESTRELLAS NARANTINAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TRES ESTRELLAS POLVORON",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TRES ESTRELLAS TROYANAS",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TRES ESTRELLAS XCREAM",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TRIKI-TRAKES BITES",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TRIKI-TRAKES REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "TRUSNACK REGULAR",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "YUPI EXTRUDED",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "YUPI HEALTHY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "YUPI MIX",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "YUPI PORCORN",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "YUPI POTATO",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "YUPI TOSTI",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "YUPI VARIETY",
	},
	{
		type: "jpg",
		country: "COLOMBIA",
		value: "ZAKS REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "BARRITAS MARINELA",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "BOMBONETES",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CABARET REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CHEETOS BOLICHEETOS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CHEETOS CRISPETA",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CHEETOS REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CHEETOS TRISSITOS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CHIPS AHOY",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CHOKIS CHOCO POFF",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CUETARA CHAVALIN REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "CUETARA OVALADAS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "DONDE AKTUA SANDWICH",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "DONDE MARIAS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "DONDE OTHER COOKIES",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "DONDE SANDWICH",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "DONDE TRAD",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "FROOT LOOPS GALLETAS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "GABI VARIETY",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "GABI WAFER REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "GANSITO",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "GRANVITA COOKIES",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "GRANVITA HOJUELITAS COOKIES",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "LA INTEGRAL",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "LARA MAGNAS REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "LORS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "MACMA CRIPS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "MANITOBA REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "MARINELA CANELITAS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "MARINELA POLVORON REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "MARINELA POLVORON",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "MARINELA PRINCIPE REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "MISURA OTHER COOKIES",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "MISURA REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "OREO REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "OREO",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "PAGASA MARIAS REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "PAGASA MARIAS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "PAGASA OTHER COOKIES",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "PAPADOPOULOS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "PRINGLES REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "RAMO REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "ROCKO",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SPONCH",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SUANDY",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SUAVICREMAS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SUPER RICAS ASSORTED",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SUPER RICAS EXTRUDED",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SUPER RICAS POTATO",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SUPER RICAS TAJADITAS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SUPER RICAS VARIETY",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "SUPER RICAS WHEAT",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TIA ROSA TARTINAS REGULAR",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TIA ROSA TRADITIONALS POLVORON",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TOSH NUTS",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TOSH TORTILLA",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TRES ESTRELLAS SANDWICH",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TRES ESTRELLAS TRAD",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TRES ESTRELLAS WAFER",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TRIKI-TRAKES",
	},
	{
		type: "png",
		country: "COLOMBIA",
		value: "TRUSNACK",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "BOCA2 REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "CARIBAS REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "CHOBIX REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "LAYS BAKED",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "LAYS HULA HOOPS",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "LAYS KETTLE",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "LAYS MAXX",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "LAYS REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "PIKARONES REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "POFFETS REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "SENORIAL EXTRUDED REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "SENORIAL PELLET REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "SENORIAL POPCORN RTE REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "SENORIAL POTATO REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "SENORIAL SALTY MIX REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "SUN CHIPS REGULAR",
	},
	{
		type: "jpg",
		country: "GUATEMALA",
		value: "TORTRIX TACO",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "BOCADELI CORN REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "BOCADELI EXTRUDED REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "BOCADELI POTATO REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "CHEETOS CRUNCHY",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "CHEETOS OTHER SUBBRAND",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "CHEETOS POFFS",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "CHEETOS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "CHIPS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "CHURRUMAIS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "CRUJITOS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DIANA CORN REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DIANA HEALTHY SNACK REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DIANA OTHER SAVORY REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DIANA PELLET REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DIANA POPORN RTE REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DIANA POTATO REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DIANA TORTILLA REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DIANA VARIETY PK REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "DORITOS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "FRITOS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "KARAMELADAS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "MUCHO NACHO REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "NATUCHIPS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "PRINGLES REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "RANCHERITOS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "ROLD GOLD REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "RUFFLES REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "SABRITAS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "TAKIS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "TAQUERITOS REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "TORTRIX DETODITO",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "TORTRIX REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "TORTRIX TACOS",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "TOTIS CORN REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "TOTIS POPCORN RTE REGULAR",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES DEL RANCHO",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES EXTRUDED CAPPY",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES NACHOS",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES POPCORN RTE CAPPY",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES RANCHITAS",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES TACO",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES TRONES CHICHARRON",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES ZAMBOS HEALTHY SNACK",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES ZAMBOS SALTY MIX",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES ZIBAS EXTRUDED",
	},
	{
		type: "png",
		country: "GUATEMALA",
		value: "YUMMIES ZIBAS POTATO",
	},
	{
		type: "jpg",
		country: "MEXICO",
		value: "CUETARA VARIETY (1)",
	},
	{
		type: "jpg",
		country: "MEXICO",
		value: "EMPERADOR 11",
	},
	{
		type: "jpg",
		country: "MEXICO",
		value: "EMPERADOR RELLENA 11",
	},
	{
		type: "jpg",
		country: "MEXICO",
		value: "HOLICHURROS REGULAR",
	},
	{
		type: "jpg",
		country: "MEXICO",
		value: "QUESABRITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "3DS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "3DS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "4 BUDDIES POPCORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ACT II BALANCE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ACT II CARAMELO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ACT II CHILE Y LIMON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ACT II MANTEQUILLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ACT II NATURAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ACT II QUESO CHEDDAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ACT II",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "AIRES DEL CAMPO BARRAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "AIRES DEL CAMPO BARRAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ALL BRAN BARS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ALL BRAN BARS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "All Product(Weighted Average)",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ARCOIRIS FRUITS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ARCOIRIS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ARCOIRIS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "AVENA 1 INSTANT REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "AVENA 1 INSTANT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "AVENA 1 NATURAL REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "AVENA 1 NATURAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "AZTECA FRITOS MAIZ CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL BIG MIX FUEGO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL BIG MIX OTHER SUBBRAND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL BIG MIX QUESO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL BIG MIX REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL BIG MIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHICH DE CERDO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHICH DE CERDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPOTLES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPOTLES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPS ADOBADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPS FUEGO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPS JALAPENO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPS OTHER SUBBRAND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPS SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPS TOREADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHIPS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHURRITOS FUEGO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHURRITOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL CHURRITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL GOLDEN NUTS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL GOLDEN PEPITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL HOT NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL KIYAKIS JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL KIYAKIS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL NUTS OTHER SUBBRAND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL NUTS OTHER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL POP KARAMELADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL POP",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL RUNNERS SALSA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL RUNNERS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL WAPAS QUESO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL WAPAS SALSA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL WAPAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARCEL WATZ",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARRITAS MARINELA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARRITAS MINI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BARRITAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BELVITA HONY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BELVITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BIMBO BRAN FRUIT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BIMBO MULTIGRANO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BIMBO MULTIGRANO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BIMBO NATURA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BIMBO NATURA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BIMBO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOBS RED MILL OAT BARS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOBS RED MILL OAT NATURAL REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOBS RED MILL OAT NATURAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOBS RED MILL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOGOS CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOGOS NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOGOS PORK RINDS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOGOS TORTILLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA CHICH DE CERDO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA CORN BOKADITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA CORN CHURRITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA CORN FRIKOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA CORN STRIPS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA ENRE REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA EXTRUDED CHITOS EXTREME",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA EXTRUDED CHITOS PUFFED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA EXTRUDED CHITOS TORNILLO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA HUEKOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA MIX D-TOCHOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA MIX VARIADITO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA PELLET CHICH DE HARINA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA PELLET PRISPAS HABANERO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA PELLET PRISPAS PICOSITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA TOPITOS GRILL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA TOPITOS JALAPENO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA TOPITOS PIZZA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA TOPITOS QUESO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA TOPITOS SALSA VERDE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKA TOSTADITA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKACHITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS CHICH DE CERDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS ENCHILADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS MIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS PAPAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS PAPAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS PELLET",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS TORTILLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOKADOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOMBITOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOMBITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOMBONETES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BOTANAS SOL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BRAN BAKER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BRAN FRUT REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BRAN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "BYDSA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CABARET REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CANELITAS BITES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CANELITAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CAPED REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CAPED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CASHITAS MIXTO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CASHITAS NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CASHITAS PEPITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CASHITAS SEMILLAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CAZARES DONITAS SAL Y LIMON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CAZARES DONITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CAZARES HOT CORN REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CAZARES HOT CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHECHITOS CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHECHITOS EXTRUDED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS BAKED BOLITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS BAKED POFFS FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS BAKED POFFS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS BAKED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS COLMILLOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS COLMILLOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS FRIED FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS FRIED NACHO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS FRIED TORCIDITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS FRIED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS POFFETS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHEETOS POFFETS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHIPS AHOY REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHIPS AHOY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOCO KRISPIS BARS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOCOLATINES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOKIS BROWNIE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOKIS CHOKO 11",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOKIS CHOKO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOKIS CHOKOMAX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOKIS MINI 11",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOKIS MINI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOKIS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHOKIS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHURRUMAIS FLAMAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHURRUMAIS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CHURRUMAIS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CONAGRA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CRACKETS DUO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CRACKETS MINI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CRACKETS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CRACKETS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CREMAX NO SUGAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CREMAX REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CREMAX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CRUJITOS FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CRUJITOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CRUJITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA ANIMALITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA CHAVALIN REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA CHOC CHIP REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA CHOC CHIP",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA CRACKER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA DIAVOLIN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA HOJALDRADA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA MARIAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA MARIAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA MARSHMALLOW",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA MEXICANAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA NICKELODEON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA OIL PAN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA OIL TOSTACREM",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA OIL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA OVALADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA SALADITAS OTHER SUBBRAND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA SALADITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA SANDWICH DEPORTIVA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA SANDWICH EL REY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA SANDWICH JR COMBINADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA SANDWICH MIL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA SANDWICH",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TOSTAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TRAD CANELA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TRAD GRAGEA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TRAD MATRAQUILLAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TRAD PALMERA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TRAD POLVORON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TRAD RECOCO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TRAD ROSCAYEMA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA TRAD",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA WAFER NAPOLITANA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA WAFER REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA WAFER TOLOSA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA WAFER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CUETARA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "CURPI CERDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DARE BRETON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DARE CRACKERS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DAVID PEPITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DAVID",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DELALLO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DON NOPAL CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DON NOPAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DON SILVIO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE AKTUA SANDWICH",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE ANIMALITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE BIZCOCHITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE DIPS MINI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE DIPS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE DIPS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE GLOBITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE MARIAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE MARIAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE OIL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE OTHER COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE POLVORON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE RIKAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE SALADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE SALTINE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE SANDWICH",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE SODA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE SOLES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE TRAD",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE VARIETY FANTASIA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DONDE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORIS PAPAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORITOS CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORITOS DINAMITA CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORITOS DINAMITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORITOS FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORITOS INCOGNITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORITOS NACHO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORITOS PIZZEROLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DORITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DPD ENCHILADOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DPD REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DULCEREL INSTANTANEA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "DULCEREL POLVO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "EL CUERNITO OATS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "EMPERADOR NOCTURNO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "EMPERADOR REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "EMPERADOR RELLENA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "EMPERADOR SENZO MIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "EMPERADOR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "EMPERADOROld",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO CHARRONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO CONCHITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO EXTRUDED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO NUTS SEMILLA GIRASOL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO PAPAS ADOBADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO PAPAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO PELLET",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO TORTILLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ENCANTO VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FARM NUTS NUECES GOURMET HORNEADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FIBER ONE CHEWY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FIBER ONE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FLOR DE NARANJO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FRIMEX CHICHARRON CERDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FRITOS CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FRITOS CHORIZO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FRITOS SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FRITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FROOT LOOPS BARRAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FROOT LOOPS BARRAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FROOT LOOPS GALLETAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "FROOT LOOPS GALLETAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GABI BESOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GABI OREJITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GABI POLVORON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GABI REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GABI TARTAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GABI VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GABI WAFER REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA BIZCOCHITOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA BIZCOCHITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS 11",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS AZUCARADAS 1",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS AZUCARADAS 11",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS AZUCARADAS 2",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS AZUCARADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS CLASICA 1",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS CLASICA 11",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS CLASICA Original",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS CLASICA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS DORADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS MINI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA MARIAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA SALADITAS CLASICA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA SALADITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD ANIMALITOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD COOKIES BARRAS DE COCO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD COOKIES DELICIAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD COOKIES HAWAIANAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD COOKIES MARAVILLAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD COOKIES MINI MARAVILLAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD COOKIES POLVORON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD COOKIES RICANELAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD FLORENTINAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD FRUIT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD OTHER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA TRAD POPULARES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA VARIETY PEKEPAKES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAMESA VARIETY REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GANSITO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GANSITO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAPSA MARIAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GAPSA SALADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GENERAL MILLS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GIRO CHOCOLATE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GIRO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GOLDEN FOODS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GOLDEN NUTS ENCHILADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GOLDEN NUTS JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GOLDEN NUTS PEPITA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GOLDEN NUTS SALADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC CHECHI DONA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC EXTRUDED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC KIUBO DIPACHOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC KIUBO OH RINGS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC KIUBO PAPA CASERA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC KIUBO RE MIX REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC KIUBO TOTOPO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC MIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC PELLET",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC POTATO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GONAC TORTILLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GRANVITA AVENAS COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GRANVITA AVENITAS COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GRANVITA COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GRANVITA HOJUELITAS COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GRANVITA INSTANTANEA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GRANVITA INSTANTANEA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "GUATOSO CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "HABANERAS BRAN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "HABANERAS CLASICAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "HABANERAS INTEGRALES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "HABANERO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "HEB CONCHITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "HOLICHURROS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "HOT NUTS CUBIERTO DE CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "HOT NUTS FUEGO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG BLAZIN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG ENCHILADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG HABANERO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG INCOGNITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG PEPITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KACANG",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KARATE ENCHILADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KARATE JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KARATE SALSA NEGRA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KARATE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KELLOGGS BARS CHOCO KRISPIES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KELLOGGS BARS FRUTI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KELLOGGS BARS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KELLOGGS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KETTLE REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KETTLE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KIPI CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "KURUMAYA JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA INTEGRAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA CHARRITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA CHICH DE CERDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA MIX REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA MIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA PAPAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA PAPAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA PORK RIND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA LUPITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA ANIMALITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA KANELIUX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA MARIANITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA MARIAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA MARIAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA SANDWICH",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA SAURIS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA TRAD",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA WAFER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA XTRA NIEVES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LA MODERNA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LARA ANIMALITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LARA CANAPINAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LARA CHOCO CHIPS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LARA DELICIOSAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LARA MAGNAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LARA MARIAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LARA SALADAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LARA VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LAS TOLOQUENAS PAPAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO CACAHUATE JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO CACAHUATE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO CHICH DE CERDO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO CHICH DE CERDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO CHICH HARINA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO CHICH HARINA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO EXTRUDED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO PAPAS ADOBADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO PAPAS ONDULADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO PAPAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO PEPITA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEO TORTILLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LEON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LORS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LORS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LOS COYOTES BOTANAZO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LOS COYOTES CHICH DE CERDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LOS COYOTES PAPA ADOBADA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LOS COYOTES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LOURDES CORN REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LOURDES PORK RINDS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LOURDES RETRO QUESITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LUCKY CHARMS BARS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "LUCKY CHARMS TREATS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA ABANICO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA CHOCCO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA CHOCOLATE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA CRIPS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA FRESA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA HAZELNUT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA NATA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA VARIEDADES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA VARIEDADES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA WAFEER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA WAFER REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MACMA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER ENCHILADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER JAPONES CLASICO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER JAPONES LIMON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER MIX ARANDANO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER MIX FRESA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER MIX VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER TOSTADO SAZONADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAFER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAMUT BLANCO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAMUT CLASICO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAMUT FLIPY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAMUT MINI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MAMUT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MANOLITO PELLET",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MANOLITO POTATO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MANOLITO POTATO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MANOLITO SNACK MIX REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MANOLITO SNACK MIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIAN BOLITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIAN NOCHE BUENA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIAN REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIAN VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIBEL ANIMALITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIBEL CHISPITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIBEL DORADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIBEL MARIAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIBEL TAPATIAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARIBEL TOSCANA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARINAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARINELA CANELITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARINELA POLVORON REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARINELA POLVORON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MARINELA PRINCIPE REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MI CARRITO CHURRO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MI CARRITO EXTRUIDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MI CARRITO SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MI CARRITO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MISURA OTHER COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MISURA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MONDELEZ",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "MUY NATURAL CHURRITOS DE MAIZNOPAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NABISCO MARBU DORADA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NATURAL BALANCE REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NATURAL BALANCE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NATURE VALLEY BARS NUTR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NATURE VALLEY CRUNCHY REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NATURE VALLEY PROTEIN REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NATURES HEART NUEZ",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NATURES HEART NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NATURES PATH FOODS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NESTLE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NIPON ENCHILADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NIPON JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NIPON SALADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NIPON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NISHIKAWA (1)",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NISHIKAWA JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NISHIKAWA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NOPALIA CHURRITOS CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NOPALIA CHURRITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NUTRI GRAIN CLASICA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "NUTRI GRAIN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "OREO DOUBLE STUFF",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "OREO FUDGE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "OREO MINI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "OREO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "OREO WHITE FUDGE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "OREO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAGASA ANIMALITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAGASA BETUNADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAGASA MARIAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAGASA MARIAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAGASA OTHER COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAKETAXO BOTANERO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAKETAXO MEZCLADITO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAKETAXO PASUMECHA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAKETAXO QUEXO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAKETAXO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAN CREMA CLASICA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PANCREMA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAPADOPOULOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAPADOPOULOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAPATINAS CHILIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PAPATINAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PARIOS CHIPS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PARIOS CHIPS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PEPPERIDGE FARM GOLDFISH REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PEPPERIDGE FARM GOLDFISH",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PEPSICO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PICOREY CHARRITOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PICOREY CHARRITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PICOREY CHICHARRON REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PICOREY CHICHARRON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PICOREY MIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PICOREY POTATO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PICOREY VARIETY PK",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PLANTERS MIXED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PLANTERS TOSTADO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PLANTERS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "POP TARTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PORK RIND CHICH DE CERDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PORK RIND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINCIPE REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINCIPE TENTACION",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES BBQ",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES CHILE Y LIMON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES CREMA Y CEBOLLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES EXTRA HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES ORIGINAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES OTHER SUBBRAND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES PICANTE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES QUESO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PRINGLES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PROVI CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PROVI JAPONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PROVI NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PROVI PORK RINDS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PROVI PORK RINDS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PROVI POTATO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "PROVI POTATO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER BARS FRUIT FLATS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER BARS FRUIT REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER BARS FRUIT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER BARS GRANOLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER BARS OTHER SUBBRAND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER CEREAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER GALLETAS ARTESANAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER GALLETAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER GALLETAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER OATS - Copy",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER OATS 3 MINUTOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER OATS INSTANT REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER OATS OLD FASH REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER OATS OLD FASH",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER OATS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER RICE CAKES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER RICE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUAKER SUPERMIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "QUESABRITAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RANCHERITOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RANCHERITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RECETA CRUIENTE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RECETA CRUJIENTE CHILES ROJOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RECETA CRUJIENTE FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RECETA CRUJIENTE JALAPENO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RECETA CRUJIENTE SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RECETA CRUJIENTE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RICE KRISPIES BARRAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RICE KRISPIES BARRAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RICHAUD CHARRITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RICHAUD PORK RIND REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RINCON TARASCO PORK RINDS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RITZ BITS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RITZ REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RITZ VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RITZ",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RIVERO AVENA NATURAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RIVERO MILLED POWDER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RIVERO OAT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RIVERO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ROCKO MINI",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ROCKO MOBI RECULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ROCKO REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ROCKO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RUFFLES MEGA CRUNCH JALAPENO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RUFFLES MEGA CRUNCH SALSA ARDIENTE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RUFFLES MEGA CRUNCH SALSA NEGRA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RUFFLES QUESO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RUFFLES SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RUFFLES ULTRA BLAZIN CHEESE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RUFFLES ULTRA FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "RUFFLES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS ADOBADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS CREMA Y ESPECIAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS HABANERO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS LIMON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS OTHER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS PC",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS SABRIMAYOREO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS SABRISURTIDO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITAS VARIETY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SABRITONES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SANISSIMO ROSETAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SANISSIMO ROSETAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SANTIVERI OTHER COOKIES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SANTIVERI OTHER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SLIM POP CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SLIM POP LIMON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SLIM POP SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SLIM POP SWEET AND SALTY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SLIM POP",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SNYDERS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SODA REAL CLASICA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SODA REAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SOL CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SOL EXTRUDED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SOL NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SOL POTATO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SOL TUBIPAPA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SPECIAL K BARS NUTR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SPECIAL K NUT BAR REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SPIRRONES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SPIRRONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SPONCH REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SPONCH",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "STAX CHEDDAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "STAX ORIGINAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "STAX SOUR CREAM",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "STAX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "STILA FIT REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "STILA FIT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "STILA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "STILA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUANDY PATISETAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUANDY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUAVICREMAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUAVICREMAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUNBITES CHILES ROJOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUNBITES PLATANITOS CHILE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUNBITES PLATANITOS DULCE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUNBITES POPCORN CHEDDAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUNBITES POPCORN SAL DE MAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUNBITES POPCORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUNBITES TRES QUESOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUNBITES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUPERMIX INSTANT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUPERMIX OLD FASHION",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUSALIA REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SUSALIA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "SYNDERS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TAIFELDS SUPER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TAIFLEDS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TAKIS FUEGO",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TAKIS HUAKAMOLE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TAKIS SALSA BRAVA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TAKIS VERDE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TAKIS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TIA ROSA TARTINAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TIA ROSA TARTINAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TIA ROSA TRADITIONALS GALLETA OATS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TIA ROSA TRADITIONALS POLVORON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TIA ROSA TRADITIONALS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TORRITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOSTACHOS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOSTACHOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOSTITOS CRONCHOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOSTITOS FLAMIN HOT",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOSTITOS SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOSTITOS SALSA VERDE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOSTITOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS CHURRITOS CHILE LIMON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS CORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS DONITAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS EXTRUDED",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS LUCHONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS MIX",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS NUTS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS PALOMITAS MANTEQUILLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS PAPS SAL",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS PAPS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS PARTY",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS PELLET",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS POPCORN RTE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS PORK RIND REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS PORK RIND",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS QUETOTIS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS TORTILLA",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS TOTOPOS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TOTIS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS NARANTINAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS POLVORON",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS SALADAS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS SALADAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS SANDWICH",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS TRAD",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS TROYANAS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS WAFER",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRES ESTRELLAS XCREAM",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRIKI-TRAKES BITES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRIKI-TRAKES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRIKI-TRAKES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRISCUITS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRISCUITS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRUSNACK REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "TRUSNACK",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "VALENTONES REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "VALENTONES",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "VERDE VALLE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "WHEAT THINS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "WHEAT THINS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "WISE CHIPS REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "WISE CHIPS",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "WISE POPCORN REGULAR",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "WISE POPCORN",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "WISE",
	},
	{
		type: "png",
		country: "MEXICO",
		value: "ZAKS REGULAR",
	},
];
