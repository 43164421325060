import { graphqlAPI } from "../../store/api-constants";
import { IUsageCountFilters, TUsageCountQueryFunction } from "../../types/reporting";
import { API } from "../../util/helper";
import { USAGE_COUNT_KPI_DATA_QUERY } from "../../util/queries/reporting";

export const getKpiData = async (payload: IUsageCountFilters) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		return await API.post(graphQLApiUrl, USAGE_COUNT_KPI_DATA_QUERY(payload)).then((response) => {
			let data = {};
			if (response && response.data) {
				data = response.data.data.getKpiData;
			}
			return { data };
		});
	} catch (e) {
		return e;
	}
};

export const getReports = async (queryFunction: TUsageCountQueryFunction, payload: IUsageCountFilters) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		const { key, query } = queryFunction(payload);
		return await API.post(graphQLApiUrl, query).then((response) => {
			let data = { chartData: [], tableData: [] };
			if (response && response.data) {
				data = response.data.data[key];
			}
			return { status: response.status, data };
		});
	} catch (e) {
		return e;
	}
};
