import { graphqlAPI } from "../../store/api-constants";
import { PriceLadderSRPPayload } from "../../types/common";
import { API } from "../../util/helper";
import { PRICE_LADDER_SRP_QUERY } from "../../util/queries/priceLadder";

/* This Function makes an API call to fetch the data for price ladder srp based on filter values.
 * @param {PriceLadderSRPPayload} data - Value of Price Piano filter.
 */
export const fetchPriceLadderSrpData = async (data: PriceLadderSRPPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = PRICE_LADDER_SRP_QUERY(data);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.priceLadderSRP;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};
