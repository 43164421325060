import React, { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar: FC<{ searchQuery: string; callBack: Dispatch<SetStateAction<string>>; width: number; placeHolder?: string }> = ({
	searchQuery,
	callBack,
	width,
	placeHolder = "Search...",
}) => {
	return (
		<Paper sx={{ p: "2px 4px 0", display: "flex", alignItems: "center", width: { width }, border: "1px solid #E3E6EB", backgroundColor: "#FFFFFF" }}>
			<IconButton type="button" sx={{ p: "10px" }} aria-label="search">
				<SearchIcon sx={{ color: "#33333366" }} />
			</IconButton>
			<InputBase
				sx={{ ml: 1, flex: 1 }}
				placeholder={placeHolder}
				inputProps={{ "aria-label": "search.." }}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					callBack(event.target.value);
				}}
				value={searchQuery}
			/>
		</Paper>
	);
};
export default SearchBar;
