import { formatNumber } from "../../../../../../util/helper";

export const MultipleChartConfig = (chartType, data, axisTitle, pricing, chartColor, id, xAxisLabel, outlier?) => {
	return {
		chart: {
			height: xAxisLabel ? "180px" : "130px",
		},
		title: {
			text: "",
		},
		exporting: {
			enabled: false,
		},
		xAxis: {
			plotLines: [
				{
					color: "#D41165",
					dashStyle: "dash",
					width: 2,
					value: pricing,
					label: {
						text: "Pricing",
					},
					zIndex: 6,
					id: `x-${id}`,
				},
			],
			labels: {
				enabled: xAxisLabel,
			},
			categories: xAxisLabel && data.map((x) => x.name),
		},
		yAxis: {
			enabled: true,
			title: {
				text: axisTitle.y,
				style: {
					fontSize: 12,
					fontWeight: 600,
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
				},
			},
			labels: {
				useHTML: true,
				style: {
					minWidth: "20px",
				},
			},
		},
		credits: {
			enabled: false,
		},
		series: [
			{
				data: data,
				type: chartType,
				color: chartColor,
				showInLegend: false,
				tooltip: {
					pointFormatter: function () {
						return `Value: ${formatNumber(this.y.toFixed(2))}`;
					},
				},
			},
			...(outlier
				? [
						{
							data: outlier.data,
							type: "column",
							color: "#ff5b5b",
							pointWidth: 10,
							showInLegend: false,
							tooltip: {
								pointFormatter: function () {
									return `Outlier in ${this.total.label}`;
								},
							},
						},
				  ]
				: []),
		],
	};
};
