import React from "react";
import { FormControl, RadioGroup, FormControlLabel, Radio, Skeleton, FormLabel } from "@mui/material";
import { Box } from "./RadioButtonGroup.Styled";

const RadioButtonGroup: React.FC<{
	showButton?: boolean;
	data;
	defaultOption?;
	color?;
	showSkeleton?: boolean;
	direction?: string;
	select?;
	disabled?: boolean;
	label?;
}> = ({ showButton = true, data, defaultOption, color, showSkeleton, direction, select, disabled = false, label }) => {
	const handleChange = (event) => {
		select(event.target.value);
	};
	return (
		<FormControl component="fieldset" disabled={disabled}>
			{label && <FormLabel component="legend">{label}</FormLabel>}
			<RadioGroup row={direction === "row"} name="radio-buttons-group" onChange={handleChange}>
				{!showSkeleton ? (
					<>
						{data.map((item: { id: string; value: string; disabled?: boolean; label?: string }, i) => (
							<FormControlLabel
								disabled={item.disabled}
								key={`radio-button-${i}`}
								control={
									showButton ? (
										<Radio
											checked={defaultOption === item.value}
											sx={{
												color: "#005393",
												"&.Mui-checked": {
													color: "#005393",
												},
												"&.Mui-disabled": {
													color: "darkgrey",
												},
											}}
										/>
									) : (
										<Box />
									)
								}
								value={item.value}
								label={<span style={color && { color, fontSize: 14 }}>{item.label || item.value}</span>}
							/>
						))}
					</>
				) : (
					<>
						{[...Array(data.length).keys()].map((i) => (
							<FormControlLabel
								disabled
								key={`radio-button-${i}-skeleton`}
								control={
									showButton ? (
										<Radio
											disabled={disabled}
											sx={{
												color: "#005393",
												"&.Mui-checked, Mui-disabled": {
													color: "#005393",
												},
												"&.Mui-disabled": {
													color: "darkgrey",
												},
											}}
										/>
									) : (
										<Box />
									)
								}
								label={<Skeleton width={60} />}
							/>
						))}
					</>
				)}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioButtonGroup;
