import { TUsageActivityQueryFunction, IUsageActivityFilters, IUsageCountFilters, TUsageCountQueryFunction } from "../../types/reporting";

/* Returns GraphQL query to fetch KPI data for Usage Count page
 * @param {IUsageCountFilters} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_COUNT_KPI_DATA_QUERY = (payload: IUsageCountFilters) => {
	return {
		query: `{ getKpiData(
      periodicity: "${payload.periodicity}"
      date: "${payload.date}"
      country: ${JSON.stringify(payload.country)}
      users: ${JSON.stringify(payload.users)}
    ) {
      userView {
        value
        change
      }
      favorited {
        value
        change
      }
      shared {
        value
        change
      }
      saved {
        value
        change
      }
      trend {
        value
        change
      }
    }}`,
	};
};

/* Returns GraphQL query to fetch Trending Dashboard data for Usage Count page
 * @param {IUsageCountFilters} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_COUNT_TRENDING_DASHBOARD: TUsageCountQueryFunction = (payload: IUsageCountFilters) => {
	const key = "getTrendingUsageReport";
	return {
		key,
		query: {
			query: `{ ${key}(
        periodicity: "${payload.periodicity}"
        date: "${payload.date}"
        country: ${JSON.stringify(payload.country)}
        users: ${JSON.stringify(payload.users)}
      ) {
        chartData {
          date
          value
        }
        tableData {
          dashboardName
          totalCount
          data {
            label
            value
          }
        }
      }}`,
		},
	};
};

/* Returns GraphQL query to fetch Dashboard Viewed data for Usage Count page
 * @param {IUsageCountFilters} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_COUNT_DASHBOARD_VIEWED: TUsageCountQueryFunction = (payload: IUsageCountFilters) => {
	const key = "getUserViewUsageReport";
	return {
		key,
		query: {
			query: `{ ${key}(
        periodicity: "${payload.periodicity}"
        date: "${payload.date}"
        country: ${JSON.stringify(payload.country)}
        users: ${JSON.stringify(payload.users)}
      ) {
        chartData {
          date
          value
        }
        tableData {
          dashboardName
          totalCount
          data {
            label
            value
          }
        }
      }}`,
		},
	};
};

/* Returns GraphQL query to fetch Dashboard Saved data for Usage Count page
 * @param {IUsageCountFilters} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_COUNT_DASHBOARD_SAVED: TUsageCountQueryFunction = (payload: IUsageCountFilters) => {
	const key = "getSaveUsageReport";
	return {
		key,
		query: {
			query: `{ ${key}(
        periodicity: "${payload.periodicity}"
        date: "${payload.date}"
        country: ${JSON.stringify(payload.country)}
        users: ${JSON.stringify(payload.users)}
      ) {
        chartData {
          date
          value
        }
        tableData {
          dashboardName
          totalCount
          data {
            label
            value
          }
        }
      }}`,
		},
	};
};

/* Returns GraphQL query to fetch Dashboard Shared data for Usage Count page
 * @param {IUsageCountFilters} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_COUNT_DASHBOARD_SHARED: TUsageCountQueryFunction = (payload: IUsageCountFilters) => {
	const key = "getSharedUsageReport";
	return {
		key,
		query: {
			query: `{ ${key}(
        periodicity: "${payload.periodicity}"
        date: "${payload.date}"
        country: ${JSON.stringify(payload.country)}
        users: ${JSON.stringify(payload.users)}
      ) {
        chartData {
          date
          value
        }
        tableData {
          dashboardName
          totalCount
          data {
            label
            value
          }
        }
      }}`,
		},
	};
};

/* Returns GraphQL query to fetch Dashboard Favorited data for Usage Count page
 * @param {IUsageCountFilters} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_COUNT_DASHBOARD_FAVORITED: TUsageCountQueryFunction = (payload: IUsageCountFilters) => {
	const key = "getFavoriteUsageReport";
	return {
		key,
		query: {
			query: `{ ${key}(
        periodicity: "${payload.periodicity}"
        date: "${payload.date}"
        country: ${JSON.stringify(payload.country)}
        users: ${JSON.stringify(payload.users)}
      ) {
        chartData {
          date
          value
        }
        tableData {
          dashboardName
          totalCount
          data {
            label
            value
          }
        }
      }}`,
		},
	};
};

/* Returns GraphQL query to fetch Country Access data for Usage Activity page
 * @param {{ country: string[]; users: string[] }} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_ACTIVITY_COUNTRY_ACCESS_QUERY = (payload: { country: string[]; users: string[] }) => {
	return {
		query: `{ accesswithCountry(
      country: ${JSON.stringify(payload.country)}
      users: ${JSON.stringify(payload.users)}
    ) {
      fullName
      firstName
      lastName
      email
      countries {
        country
        isAccessible
      }
    }}`,
	};
};

/* Returns GraphQL query to fetch Country Activity data for Usage Activity page
 * @param {IUsageActivityFilters} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_ACTIVITY_COUNTRY_ACTIVITY_QUERY: TUsageActivityQueryFunction = (payload: IUsageActivityFilters) => {
	const key = "getUsageReportCountryActivitywithCountry";
	return {
		key,
		query: {
			query: `{ ${key}(
        date: "${payload.date}"
        country: ${JSON.stringify(payload.country)}
        users: ${JSON.stringify(payload.users)}
      ) {
        country {
          name
          viewCount
          saveCount
          sharedCount
        }
        user {
          parentName
          name
          viewCount
          saveCount
          sharedCount
        }
        dashboard {
          country
          parentName
          name
          viewCount
          saveCount
          sharedCount
        }
      }}`,
		},
	};
};

/* Returns GraphQL query to fetch User Activity data for Usage Activity page
 * @param {IUsageActivityFilters} payload - The filters to be passes to the query.
 * @returns The GraphQL query.
 */
export const USAGE_ACTIVITY_USER_ACTIVITY_QUERY: TUsageActivityQueryFunction = (payload: IUsageActivityFilters) => {
	const key = "getUsageReportUserActivityWithCountry";
	return {
		key,
		query: {
			query: `{ ${key}(
        date: "${payload.date}"
        country: ${JSON.stringify(payload.country)}
        users: ${JSON.stringify(payload.users)}
      ) {
        country {
          name
        }
        user {
          parentName
          name
        }
        activity {
          country
          parentName
          name
          dashboard
          date
        }
      }}`,
		},
	};
};
