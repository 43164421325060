import AdapterLuxon from "@mui/lab/AdapterLuxon";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import Dropdown from "../../../../components/Dropdown";
import FilterAccordion from "../../../../components/Filters";
import { initialState } from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import GeoFiltersV3 from "../../../../components/GeoFilters-V3";
import Indicator from "../../../../components/Loader";
import CommonProductFilters from "../../../../components/CommonProductFilters";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import dataObject, { elasticityRadio, primaryFilter } from "../../../../mocks/elasticityPricingTrack";
import { BlackInputTitle, OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { dataFormat } from "../../../../util/helper";
import { fetchFilterMonthData } from "../../../BrandLadder/service";
import { fetchFiltersData } from "../../../../util/services";
import { getAdditionalFilterData, getInaseMaxDate } from "../../service";
import { unitVariationLoader } from "../../store/elasticityPricing.action";
import AdjustableMeasureFilters from "./components/AdjustableMeasureFilters";
import { EPT_PRODUCT_FILTER_QUERY } from "../../../../util/queries/elasticityPricingTrack";
import { IProductFilterConfigurations, IInaseFilters } from "../../../../types/common";

const TopFilters: React.FC<{ defaultFilters; callback }> = ({ defaultFilters = {}, callback }) => {
	const ElasticityPricingTrack = useSelector((state: any) => state.ElasticityPricingTrack);
	const userDetail = useSelector((state: any) => state.User.data);
	const commonLoader = useSelector((state: any) => state.common.loader);
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [selectedGeoFilters, setSelectedGeoFilters] = useState({
		...initialState,
		country: defaultFilters.country ?? "MEXICO",
		geoLevel: defaultFilters.geoLevel,
		channel: defaultFilters.channel,
	});
	const [selectedProductFilters, setSelectedProductFilters] = useState<any>({});
	const [selectedAdjustableFilters, setSelectedAdjustableFilters] = useState<any>({});
	const [clearTopFilters, setClearTopFilters] = useState(false);
	const [clearAdjustableMeasureFilters, setClearAdjustableMeasureFilters] = useState(false);
	const [adjustableMeasureFilters, setAdjustableMeasureFilters] = useState<any>({});
	const [dataSource, setDataSource] = useState(elasticityRadio.defaultOption.value);
	const [selectedSellOutAndInase, setSelectedSellOutAndInase] = useState({ regions: null });
	const [disabledSellOutAndInaseFilters, setDisabledSellOutAndInaseFilters] = useState({ regions: true });
	const geoFilter = useSelector((state: any) => state.geoFilter);
	const [pricingDate, setPricingDate] = useState<any>(null);
	const [formattedDate, setFormattedDate] = useState("");
	const [sellOutAndInaseFiltersOptions, setSellOutAndInaseFiltersOptions] = useState({
		...dataObject.sellOutAndInaseFilters,
	});
	const [minDate, setMinDate] = useState(null);
	const [maxDate, setMaxDate] = useState(null);
	const [lastUpdatedRecordDateApiFlag, setLastUpdatedRecordDateApiFlag] = useState(false);
	const [productFilterOptions, setProductFilterOptions] = useState<IProductFilterConfigurations[]>([]);
	const [isFirstLoadPepsiFlag, setIsFirstLoadPepsiFlag] = useState(true);
	const onSelectProduct = (data) => {
		setSelectedProductFilters({ ...data });
		setClearTopFilters(false);
		setClearAdjustableMeasureFilters(false);
		setSelectedAdjustableFilters({});
	};

	const getMaxDateEndPoint = (payload) => {
		return dataSource === "inase" ? getInaseMaxDate() : fetchFilterMonthData(payload);
	};

	const getLastUpdatedRecordDate = async (payload) => {
		setLoader(true);

		await getMaxDateEndPoint(payload)
			.then((response) => {
				if (response && response.data) {
					setLoader(false);
					setLastUpdatedRecordDateApiFlag(true);
					if (!pricingDate && defaultFilters && defaultFilters.pricingDate) {
						setPricingDate(defaultFilters.pricingDate);
						setFormattedDate(defaultFilters.date);
					} else {
						setPricingDate(dataFormat(response.data.lastRefreshDate, "MM/dd/yyyy"));
						setFormattedDate(dataFormat(response.data.lastRefreshDate, "MM/dd/yyyy"));
					}
					setMaxDate(DateTime.fromFormat(response.data.lastRefreshDate, "yyyy-MM-dd").toLocal());
					setMinDate(DateTime.fromFormat(response.data.lastRefreshDate, "yyyy-MM-dd").minus({ week: 53 }).toLocal());
				}
			})
			.catch((e) => {});
	};

	const onApplyFilter = () => {
		dispatch(unitVariationLoader(true));
		callback({
			type: "FILTER_APPLIED",
			filters: {
				..._.omit(selectedGeoFilters, "periodView"),
				dataSource,
				...selectedSellOutAndInase,
				...selectedProductFilters,
				measureFilters: _.isArray(adjustableMeasureFilters.tableData) ? [...adjustableMeasureFilters.tableData] : [],
				date: adjustableMeasureFilters.date,
				pricingDate,
			},
		});
	};

	const onChangeRegion = (data) => {
		setSelectedSellOutAndInase({ regions: data });
		setDisabledSellOutAndInaseFilters({ regions: false });
	};

	const disableApplyFilter = () => {
		const flag: boolean = geoFilter.loading || !selectedGeoFilters.country || !selectedProductFilters.packSize || !pricingDate;
		return flag;
	};

	const clearFilter = (clearDataSource = false) => {
		setLastUpdatedRecordDateApiFlag(false);
		setSelectedGeoFilters({ ...initialState, country: "MEXICO", geoLevel: ["DC-MIX"], channel: ["DTS"] });
		setClearTopFilters(true);
		setClearAdjustableMeasureFilters(false);
		if (clearDataSource) {
			setTimeout(() => {
				setDataSource(elasticityRadio.defaultOption.value);
			}, 0);
		}
		setPricingDate(null);
		setFormattedDate("");
		setSelectedSellOutAndInase({ regions: null });
		setAdjustableMeasureFilters({});
		setSelectedAdjustableFilters({});
		setSelectedProductFilters({});
		defaultFilters.pricingDate = null;
	};

	const getFilterData = async (payload: IInaseFilters) => {
		return new Promise((resolve) => {
			setLoader(true);
			getAdditionalFilterData(payload)
				.then((response) => {
					const data = response.data;
					const filterData = { ...sellOutAndInaseFiltersOptions };
					filterData.regions.options = [...(data ?? [])];
					setSellOutAndInaseFiltersOptions(filterData);
					setDisabledSellOutAndInaseFilters({ regions: false });
					setLoader(false);
					resolve(true);
				})
				.catch((e) => {
					resolve(true);
				});
		});
	};

	const onChangeDataSource = (source, clearfilters = false) => {
		if (selectedGeoFilters && selectedGeoFilters.country === "MEXICO" && source === "inase") {
			if (clearfilters) {
				clearFilter();
			}
			setSelectedGeoFilters({ ...selectedGeoFilters, geoLevel: ["DC-MIX"], channel: ["DTS"] });
			if (!defaultFilters || (defaultFilters && defaultFilters.dataSource !== "inase"))
				getFilterData({
					country: "MEXICO",
					geoLevel: ["DC-MIX"],
					channel: ["DTS"],
					geoLevel2: null,
				});
		} else {
			if (clearfilters) {
				clearFilter();
			}
			setSelectedGeoFilters({ ...selectedGeoFilters, geoLevel: ["REGION-CHAN"], channel: ["DTS", "OT", "WHSL"] });
		}
		setDataSource(source);
	};

	useEffect(() => {
		if (dataSource === "inase") {
			getFilterData({
				country: "MEXICO",
				geoLevel: ["DC-MIX"],
				channel: ["DTS"],
				geoLevel2: null,
			});
			setSelectedGeoFilters({ ...selectedGeoFilters, geoLevel: ["DC-MIX"], channel: ["DTS"] });
		}
	}, [dataSource]);

	useEffect(() => {
		setClearAdjustableMeasureFilters(true);
		setPricingDate(null);
		setFormattedDate("");
		setLastUpdatedRecordDateApiFlag(false);
	}, [selectedGeoFilters.geoLevel]);

	useEffect(() => {
		if (selectedGeoFilters.country && selectedProductFilters.permutation && selectedProductFilters.permutation.length > 0 && !lastUpdatedRecordDateApiFlag) {
			getLastUpdatedRecordDate({
				country: selectedGeoFilters.country,
				geoLevel: selectedGeoFilters.geoLevel,
				geoLevel2: selectedGeoFilters.geoLevel2,
				category: selectedProductFilters.category,
			});
		}
	}, [selectedProductFilters]);

	const setInaseDefaultFilters = () => {
		if (selectedSellOutAndInase && _.isEmpty(selectedSellOutAndInase.regions)) {
			onChangeRegion(defaultFilters.regions);
		}
	};

	useEffect(() => {
		if (defaultFilters && Object.keys(defaultFilters).length > 0 && defaultFilters.country && defaultFilters.regions !== null) {
			setInaseDefaultFilters();
		}
	}, [sellOutAndInaseFiltersOptions]);

	useEffect(() => {
		if (Object.keys(defaultFilters).length > 0) {
			setSelectedSellOutAndInase({ regions: defaultFilters.regions });
		}

		if (defaultFilters && defaultFilters.country && userDetail?.id) {
			setSelectedGeoFilters({
				...initialState,
				country: defaultFilters.country,
				geoLevel: defaultFilters.geoLevel,
				geoLevel2: defaultFilters.geoLevel2,
				channel: defaultFilters.channel,
			});
			setSelectedProductFilters({
				category: defaultFilters.category,
				segment: defaultFilters.segment,
				brand: defaultFilters.brand,
				subBrand: defaultFilters.subBrand,
				packSize: defaultFilters.packSize,
				permutation: defaultFilters.permutation,
			});
			setSelectedAdjustableFilters(defaultFilters.measureFilters);
		}
	}, [defaultFilters, userDetail]);

	useEffect(() => {
		if (selectedGeoFilters && selectedGeoFilters.country !== null && userDetail?.id) {
			fetchFilters({ country: selectedGeoFilters.country });
		}
	}, [selectedGeoFilters.country, userDetail?.id]);

	useEffect(() => {
		if (selectedProductFilters && defaultFilters && defaultFilters.pepsi) {
			selectedProductFilters({
				...selectedProductFilters,
				category: defaultFilters.category,
				segment: defaultFilters.segment,
				brand: defaultFilters.brand,
				subBrand: defaultFilters.subBrand,
				packSize: defaultFilters.packSize,
			});
		}
	}, [productFilterOptions]);

	const fetchFilters = (payload) => {
		const productFilterConfig: IProductFilterConfigurations[] = [];
		if (selectedGeoFilters.country) {
			setLoader(true);
			payload = {
				...payload,
			};
			fetchFiltersData(
				"graphql",
				{
					country: selectedGeoFilters.country,
					...payload,
				},
				userDetail.id,
				EPT_PRODUCT_FILTER_QUERY
			)
				.then((response) => {
					if (response && response.data) {
						const filterObject = JSON.parse(response.data);
						filterObject.map((object) => {
							productFilterConfig.push({
								category: {
									label: object.category,
									value: object.category,
								},
								segment: {
									label: object.segment,
									value: object.segment,
								},
								brand: {
									label: object.brand,
									value: object.brand,
								},
								subBrand: {
									label: object.subBrand,
									value: object.subBrand,
								},
								packSize: {
									label: object.packSize,
									value: object.packSize,
								},
								permutation: {
									label: object.permutation,
									value: object.permutationvalue,
								},
								vendor: {
									label: object.Manufacturer,
									value: object.Manufacturer,
								},
							});
						});
						setProductFilterOptions(productFilterConfig);
						setLoader(false);
					}
				})
				.catch((e) => {
					setLoader(false);
				});
		}
	};

	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<Indicator position="absolute" show={!commonLoader && (loader || ElasticityPricingTrack.unitVariationExpected)} />
			<CardContent>
				<FilterAccordion title="Additional Filters">
					<BlackInputTitle sx={{ mb: 2, fontSize: "15px" }}>Data Sources</BlackInputTitle>
					<Grid container spacing={2} columns={17}>
						<Grid item>
							<RadioButtonGroup
								color="#000"
								showButton={true}
								data={elasticityRadio.dataSource}
								defaultOption={dataSource}
								select={(source) => onChangeDataSource(source, true)}
							/>
						</Grid>
					</Grid>
					{dataSource === "inase" ? (
						<Grid container spacing={2} marginTop={0.5}>
							<Grid item xs={12} md={3} sm={3} lg={2}>
								<DropdownTitle>{sellOutAndInaseFiltersOptions.regions.title}</DropdownTitle>
								<Dropdown
									disabled={disabledSellOutAndInaseFilters.regions}
									data={sellOutAndInaseFiltersOptions.regions.options}
									onChange={(data) => onChangeRegion(data)}
									defaultOption={selectedSellOutAndInase.regions || ["empty"]}
									placeholder={sellOutAndInaseFiltersOptions.regions.placeholder}
									multiple={sellOutAndInaseFiltersOptions.regions.multiple}
									allOption={sellOutAndInaseFiltersOptions.regions.all}
								/>
							</Grid>
						</Grid>
					) : null}
				</FilterAccordion>
				{dataSource === "sellOut" ? (
					<FilterAccordion title="Geo-Filters">
						<GeoFiltersV3
							data={primaryFilter}
							onSelectFilters={setSelectedGeoFilters}
							clearFilter={clearTopFilters}
							apiPath="ElasticityPricingTrack"
							showLoader={setLoader}
							showDatePicker={false}
							dataSource={dataSource}
							defaultFilters={defaultFilters}
						/>
						{selectedGeoFilters && selectedGeoFilters.geoLevel && _.isArray(selectedGeoFilters.geoLevel) && selectedGeoFilters.geoLevel[0] === "DC-MIX" && (
							<Box marginTop={2}>
								<span style={{ fontSize: 16 }}>
									<b>NOTE :</b> 5A DCMIX is not a valid selection for sell-out data sources.
								</span>
							</Box>
						)}
					</FilterAccordion>
				) : null}

				<FilterAccordion title="Product Filters">
					<CommonProductFilters
						filterData={productFilterOptions}
						onChange={(e) => {
							setIsFirstLoadPepsiFlag(false);
							onSelectProduct(e);
						}}
						data={dataObject.productFilter}
						defaultFilters={selectedProductFilters && Object.keys(selectedProductFilters).length > 0 ? selectedProductFilters : null}
						isAnchorFlag={false}
						filterOrder={dataObject.eptFilterOrder}
						isFirstLoadFlag={isFirstLoadPepsiFlag}
					/>
				</FilterAccordion>
				<FilterAccordion title="Date Filters">
					<BlackInputTitle sx={{ mb: 2, fontSize: "15px" }}>Pricing Date</BlackInputTitle>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<DatePicker
							label=""
							value={pricingDate}
							onChange={(newValue) => {
								setPricingDate(newValue);
								setFormattedDate(dataFormat(new Date(newValue.ts), "MM/dd/yyyy"));
							}}
							minDate={minDate}
							maxDate={maxDate}
							renderInput={(params) => <TextField InputProps={{ autoComplete: "off" }} {...params} onKeyDown={(e) => e.preventDefault()} />}
						/>
					</LocalizationProvider>
				</FilterAccordion>
				<FilterAccordion title="Adjustable Measure Filters">
					<BlackInputTitle sx={{ mb: 2, fontSize: "15px" }}>Elasticity Pricing Tracker</BlackInputTitle>
					<AdjustableMeasureFilters
						filters={
							(selectedGeoFilters && selectedGeoFilters.geoLevel && selectedGeoFilters.geoLevel2) ||
							(selectedSellOutAndInase.regions &&
								selectedSellOutAndInase.regions.length > 0 &&
								Object.keys(selectedProductFilters) &&
								selectedProductFilters.permutation &&
								selectedProductFilters.permutation.length > 0)
								? Object.keys(selectedGeoFilters).length > 0 && {
										..._.omit(selectedGeoFilters, "periodView"),
										...selectedProductFilters,
										...selectedSellOutAndInase,
										date: formattedDate,
										dataSource,
										country: selectedGeoFilters.country,
								  }
								: {}
						}
						defaultFilters={selectedAdjustableFilters}
						callback={setAdjustableMeasureFilters}
						clearFilter={clearAdjustableMeasureFilters}
						showLoader={setLoader}
					/>
				</FilterAccordion>
				<Grid className="p-l-16">
					<OrangeBtn color="secondary" className="m-r-20" onClick={() => clearFilter(true)}>
						Clear Filter
					</OrangeBtn>
					<PrimaryBtn color="primary" onClick={onApplyFilter} disabled={disableApplyFilter()}>
						Apply Filter
					</PrimaryBtn>
				</Grid>
			</CardContent>
		</Card>
	);
};
export default TopFilters;
