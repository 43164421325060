import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { simulationChartConfig } from "./simulationChartConfig";
import WeeklyTable from "../../../WeeklyTable";
import _ from "lodash";
import IncrementDecrement from "../../../../../../components/IncrementDecrement";
import { makeStyles } from "@material-ui/core";
import HighchartContainer from "../../../../../../components/HighchartContainer/HighchartContainer";
import { formatNumber } from "../../../../../../util/helper";
import { variableTrendInPriceElasticityModelTableHead } from "../../../../../../mocks/elasticityPricingTrack";
import VariableTrendInPETable from "../VariableTrendInPETable";
import Dashboard from "../../../../../../components/Dashboard";

const useStyles = makeStyles(() => ({
	hide: {
		opacity: 0,
		"&:hover": {
			opacity: 1,
		},
	},
}));
const SimulationChart: React.FC<{
	id;
	data;
	index;
	filters;
	chartRef;
}> = ({ id, data, index, filters, chartRef }) => {
	const [tableHeadings, setTableHeadings] = useState([]);
	const [tableValues, setTableValues] = useState<any>([]);
	const classes = useStyles();

	//Resetting the chart tooltip font size on component mount
	useEffect(() => {
		if (chartRef?.current?.chart?.tooltip?.options?.style?.fontSize) delete chartRef.current.chart.tooltip.options.style.fontSize;
	}, []);

	const formatData = (selectedDataIndex) => {
		if (_.isObject(data) && Object.keys(data).length > 0) {
			const newCharData = { ...data, chartData: data[selectedDataIndex.item] };
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;
				const series1 = [],
					series2 = [],
					series3 = [],
					series4 = [],
					tableData1 = [],
					tableData2 = [],
					tableData3 = [],
					tableData4 = [];
				let xAxis = [];
				let pricingDate = newCharData?.chartData?.findIndex((x) => x.date === newCharData.pricingDate);
				newCharData.chartData?.map((x, i) => {
					series1.push(x.real);
					series2.push(x.modelInput);
					series3.push(i < pricingDate ? null : x.noPricingSimulation);

					tableData1.push(x.real);
					tableData2.push(x.modelInput);
					tableData3.push(x.noPricingSimulation);

					xAxis.push(x.date);
					if (filters.dataSource === "sellOut") {
						series4.push(x.baseLine);
						tableData4.push(x.baseLine);
					}
				});
				setTableHeadings(xAxis);
				const keyEventData: any = [];
				if (filters.dataSource === "inase") {
					const value = _.max([...series1, ...series2, ...series3]);
					const keyEvents = newCharData.keyEvents[`${selectedDataIndex.item}KeyEvents`] ? [...newCharData.keyEvents[`${selectedDataIndex.item}KeyEvents`]] : [];

					xAxis.map((x, i) => {
						const index = keyEvents.findIndex((d) => d.start === x);
						if (index !== -1) {
							keyEventData.push({
								x: x.start,
								y: value,
								dataLabels: {
									enabled: false,
									name: keyEvents[index].name,
									end: keyEvents[index].overlap,
								},
							});
						} else {
							keyEventData.push(null);
						}
					});
					chart.series[3].setData(keyEventData);
				}
				chart.xAxis[0].setCategories(xAxis);
				chart.xAxis[0].addPlotLine({
					color: "#D41165",
					width: 2,
					value: pricingDate,
					dashStyle: "ShortDash",
					label: { text: `Pricing` },
					zIndex: 1,
					type: "line",
					id: `x-${index}`,
				});
				chart.series[0].setData(series1);
				chart.series[1].setData(series2);
				chart.series[2].setData(series3);

				const tableData = [
					{ title: "Real", width: 200, data: tableData1 },
					{ title: "Model Output", width: 200, data: tableData2 },
					{
						title: "No Pricing Simulation",
						width: 200,
						data: tableData3,
					},
				];

				if (chart.series.length > 4) {
					chart.series[4].remove();
				}
				if (filters.dataSource === "sellOut") {
					chart.addSeries({
						type: "line",
						name: "Baseline",
						color: "#f79969",
						data: series4,
						tooltip: {
							pointFormatter: function () {
								return `Baseline: ${formatNumber(this.y.toFixed(2))}<br>`;
							},
						},
					});
					tableData.push({
						title: "Baseline",
						width: 200,
						data: tableData4,
					});
				}
				setTableValues(tableData);
			}
		}
	};

	const formatVariableTrendTableData = (product) => {
		try {
			let tableData: any = [];
			let record: any = [];
			let modelVariables: any = [];
			if (filters.dataSource === "inase") {
				modelVariables = [
					"units",
					"sales",
					"volume",
					"distribution",
					"vpu",
					"seasonality",
					"trend",
					"holidays",
					"ppu",
					"htCovidPositiveRate",
					"htCovidTotalCases",
					"mbtyGroceryPharmacyPercentchangeFromBaseline",
					"mbtyRetailRecreationPercentchangeFromBaseline",
				];
			} else {
				modelVariables = ["units", "vpu", "sales", "volume"];
			}
			modelVariables.map((key, i) => {
				record = [];
				product[key] &&
					Object.keys(product[key]).map((key2) => {
						if (key2 === "variableContribution" || key2 === "varPercentage") {
							record.push(
								_.isNumber(product[key][key2])
									? (key === "units" || key === "volume" || key === "sales" || (filters.dataSource === "sellOut" && key === "ppu")) &&
									  key2 === "variableContribution"
										? ""
										: formatNumber((product[key][key2] * 100).toFixed(2))
									: (key === "units" || key === "volume" || key === "sales" || (filters.dataSource === "sellOut" && key === "ppu")) &&
									  key2 === "variableContribution"
									? ""
									: product[key][key2]
							);
						} else {
							record.push(_.isNumber(product[key][key2]) ? formatNumber(product[key][key2].toFixed(2)) : product[key][key2]);
						}
					});
				tableData.push(record);
			});
			if (filters.dataSource === "sellOut") {
				const index = tableData.findIndex((item) => item[0] === "PPU");
				if (index !== -1 && index !== 0) {
					const ppu = tableData.splice(index, 1);
					tableData.unshift(ppu[0]);
				}
			}
			return tableData;
		} catch (e) {}
	};

	return (
		<Grid container>
			<Grid
				item
				sm={data.displayName !== "All Product(Weighted Average)" ? 6.8 : 11.8}
				style={{
					position: "relative",
				}}
			>
				{data &&
					Object.keys(data).length > 0 &&
					(data.displayName !== "All Product(Weighted Average)" ? (
						<Grid
							style={{
								position: "absolute",
								left: 10,
								bottom: 10,
								top: 350,
								width: "100%",
								zIndex: 2,
								height: "max-content",
							}}
							className={classes.hide}
						>
							<IncrementDecrement
								data={data}
								list={["weekly", "monthly", "quarterly", "yearly"]}
								callback={formatData}
								id={id}
								defaultOption={(localStorage.getItem("periodicity") && JSON.parse(localStorage.getItem("periodicity"))[id]?.item) || null}
							/>
						</Grid>
					) : (
						<></>
					))}
				<Dashboard title={data.displayName} id={id} chartRef={chartRef} actionButtons={{ screenshot: false, mmpw: true, editMedia: true }}>
					<HighchartContainer chartRef={chartRef} id={id}>
						<HighchartsReact highcharts={Highcharts} options={simulationChartConfig} ref={chartRef} />
					</HighchartContainer>
				</Dashboard>
			</Grid>
			<Grid item sm={0.2}></Grid>
			{/* <Grid item sm={data.displayName !== "All Product(Weighted Average)" ? 5 : 0}>
				{data.displayName !== "All Product(Weighted Average)" && (
					<VariableTrendInPETable Headings={variableTrendInPriceElasticityModelTableHead} data={formatVariableTrendTableData(data.variableTrend) || []} />
				)}
			</Grid> */}
			<Grid item sm={11.9}>
				<WeeklyTable heading={tableHeadings} data={tableValues} showPercent={false} />
			</Grid>
		</Grid>
	);
};
export default SimulationChart;
