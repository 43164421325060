import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandIcon } from "./Filters.Styled";
import { BlackTitle, DarkGreyTitle } from "../../../src/styles/Common.Styled";

const useStyles = makeStyles((theme) => ({
	m_0: { margin: "0px !important", boxShadow: "none !important" },
}));

const FilterAccordion: React.FC<{ title?: string; children; showSkeleton?: boolean; subTitle?: string; titleColor?: string; expandFlag?: boolean }> = ({
	title,
	children,
	showSkeleton = false,
	subTitle,
	titleColor,
	expandFlag = false,
}) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(expandFlag);
	const handleChange = (isExpanded: boolean) => {
		setExpanded(!isExpanded);
	};
	return (
		<Accordion
			data-testid="accordion"
			className={classes.m_0}
			key={`${title}-accordion`}
			sx={{ "&:before": { backgroundColor: "#000", opacity: 0.1 } }}
			expanded={expanded}
			onChange={() => {
				handleChange(expanded);
			}}
		>
			<AccordionSummary expandIcon={<ExpandIcon />}>
				{showSkeleton ? (
					<Skeleton variant="rectangular" width={210} height={40} />
				) : (
					<>
						{titleColor ? (
							<Typography className={"f-w-5"} color={titleColor}>
								<span style={{ font: "normal normal 400 16px" }} dangerouslySetInnerHTML={{ __html: title }}></span>
							</Typography>
						) : (
							<BlackTitle className={"f-w-5"}>
								<span style={{ font: "normal normal 400 16px" }} dangerouslySetInnerHTML={{ __html: title }}></span>
							</BlackTitle>
						)}
						{subTitle && (
							<DarkGreyTitle className={"f-w-5"} style={{ marginLeft: "10px" }}>
								<span style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: subTitle }}></span>
							</DarkGreyTitle>
						)}
					</>
				)}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default FilterAccordion;
