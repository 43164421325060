import React, { FC, useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";
import { pageNames } from "../../mocks/common";
import AssortmentScenario from "./AssortmentScenario/AssortmentScenario";
import AssortmentTool from "./AssortmentTool/AssortmentTool";
import { Title } from "../../styles/Common.Styled";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getScenarioDetailSuccess } from "../../store/actions/MnADashboards/assortment.action";

const AssortmentPrioritizer: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const useQuery = () => new URLSearchParams(location.search);
	const query = useQuery();
	const queryScenario = query.get("scenario");
	const scenarioModeParam = query.get("mode");
	const [showScenarioLibrary, setShowScenarioLibrary] = useState(queryScenario ? false : true);
	const [isNewScenario, setIsNewScenario] = useState(queryScenario ? false : true);

	useEffect(() => {
		if (!queryScenario && scenarioModeParam !== "new") {
			setShowScenarioLibrary(true);
			dispatch(getScenarioDetailSuccess(null, false));
		}
	}, [query, location, queryScenario]);

	return (
		<PageContainer page={pageNames.assortmentPrioritizer}>
			<Title className="ellipsis m-b-10">Assortment Prioritizer</Title>
			{showScenarioLibrary ? (
				<AssortmentScenario setShowScenarioLibrary={setShowScenarioLibrary} setIsNewScenario={setIsNewScenario} />
			) : (
				<AssortmentTool setShowScenarioLibrary={setShowScenarioLibrary} isNewScenario={isNewScenario} />
			)}
		</PageContainer>
	);
};

export default AssortmentPrioritizer;
