import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dashboard from "../../../../components/Dashboard";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import Dropdown from "../../../../components/Dropdown";
import Indicator from "../../../../components/Loader";
import { resultPerWeekHeadings, resultPerWeekMainHeadings, resultPerWeekSubHeadings, Unit } from "../../../../mocks/elasticityPricingTrack";
import { NoData, OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import { formatNumber, getValueFromObject } from "../../../../util/helper";
import { StyledTableContainer } from "../../../PriceSettingTool/components/VisualizationContainer/components/VisualizationTable/VisualizationTable.Styled";
import { getResultPerWeek, getWeeksData } from "../../service";
import ResultTable from "./components/ResultTable";
import { fetchImageURL } from "../../../../util/services";

const ResultPerMonthAndAccumulated: React.FC<{ filters: any; callback; defaultFilters }> = ({ filters, callback, defaultFilters }) => {
	const [localFilters, setLocalFilters] = useState({
		filtervisual2units: [],
		filtervisual2dates: [],
	});
	const [skeleton, setSkeleton] = useState(true);
	const [tableSkeleton, setTableSkeleton] = useState(true);
	const [mainHeadings, setMainHeadings] = useState([...resultPerWeekMainHeadings]);
	const [headings, setHeadings] = useState([]);
	const [subHeadings, setSubHeadings] = useState([]);
	const [tableData, setTableData] = useState<any>([]);
	const [weekData, setWeekData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [loaderForDropdown, setLoaderForDropdown] = useState(true);
	const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
	const onSelectFilter = (key, data) => {
		setLocalFilters({ ...localFilters, [key]: _.sortBy(data) });
	};
	const disableApplyFilter = () => {
		const flag: boolean =
			(_.isArray(localFilters?.filtervisual2units) && localFilters?.filtervisual2units.length === 0) ||
			(_.isArray(localFilters?.filtervisual2dates) && localFilters?.filtervisual2dates.length === 0);
		return flag;
	};
	const updateMainHeadings = (payload) => {
		let mainHeadingsColspan = 5;
		payload.filtervisual2units.map((item) => {
			if (item === "sales") {
				mainHeadingsColspan += 2;
			}
			if (item === "units") {
				mainHeadingsColspan += 2;
			}
			if (item === "volume") {
				mainHeadingsColspan += 2;
			}
		});
		const newResultPerWeekMainHeadings = [...resultPerWeekMainHeadings];
		newResultPerWeekMainHeadings[1].config.colspan = mainHeadingsColspan;
		newResultPerWeekMainHeadings[2].config.colspan = mainHeadingsColspan - 2;
		setMainHeadings([...newResultPerWeekMainHeadings]);
	};
	const updateHeadings = (headings, payload) => {
		const newHeadings: any = [];
		Object.keys(headings).map((key) => {
			if (headings[key].config.display) {
				const getVal = key.split(".").length > 0 ? getValueFromObject(payload, key) : payload[key];
				if (getVal === 0 || key === "image" || (getVal !== "" && getVal !== null && getVal)) {
					newHeadings.push({ ...headings[key] });
				}
			}
		});
		return newHeadings;
	};
	const formatData = (response) => {
		const brands: any = [];
		response.map((brand) => {
			const subBrands: any = [];
			brand.child.map((subBrand) => {
				subBrands.push(extractData(subBrand));
			});
			subBrands.push(extractData(brand.parent));
			brands.push(subBrands);
		});
		setTableData([...brands]);
		setTableSkeleton(false);
		setLoader(false);
	};
	const extractData = (payload) => {
		const result: any = [];
		Object.keys(resultPerWeekSubHeadings).map((key) => {
			const getVal = key.split(".").length > 0 ? getValueFromObject(payload, key) : payload[key];
			if (getVal === 0 || key === "image" || (getVal !== "" && getVal !== null && getVal)) {
				result.push(
					resultPerWeekSubHeadings[key].config?.formatNumber
						? { value: formatNumber(getVal), config: resultPerWeekSubHeadings[key].config }
						: { value: getVal, config: resultPerWeekSubHeadings[key].config }
				);
			}
		});
		return result;
	};
	const onApplyFilter = (defaultRequest?) => {
		let payload = { ...filters, ...localFilters };
		if (defaultRequest && Object.keys(defaultRequest).length > 0) {
			payload = { ...defaultRequest };
		}
		setTableData([]);
		setTableSkeleton(true);
		setLoader(true);
		updateMainHeadings({
			filtervisual2units: payload.filtervisual2units,
			filtervisual2dates: payload.filtervisual2dates,
		});
		getResultPerWeek(payload)
			.then((res) => {
				if (res.status === 200) {
					const response = res.data.brands;
					if (
						_.isArray(response) &&
						response.length > 0 &&
						(response[0].parent.weekly.salesIndex !== null || response[0].parent.weekly.unitsIndex !== null || response[0].parent.weekly.volumeIndex !== null)
					) {
						Promise.all(
							response?.map((item, i) => {
								if (item.parent.imageName) {
									item.parent.image = fetchImageURL(payload.country, item.parent.imageName);
								} else {
									item.parent.image = "";
								}
								if (_.isArray(item.child)) {
									item.child.forEach((c) => {
										if (c.imageName) {
											c.image = fetchImageURL(payload.country, c.imageName);
										} else {
											c.image = "";
										}
									});
								}
							})
						)
							.then(() => {
								if (filters.dataSource === "sellOut") {
									const tableHeadings = _.cloneDeep(resultPerWeekHeadings);
									tableHeadings["weekly.unitsIndex"].config = { colspan: 2, width: 200, display: true };
									tableHeadings["accumulated.unitsIndex"].config = { colspan: 2, width: 200, display: true };
									tableHeadings["weekly.ppuPricing"].label = "EDRP Pricing %";
									tableHeadings["accumulated.ppuPricing"].label = "EDRP Pricing %";
									setHeadings(updateHeadings(tableHeadings, response[0].parent));
									const tableSubHeadings = _.cloneDeep(resultPerWeekSubHeadings);
									delete tableSubHeadings["weekly.unitsIndex.vsExpected"];
									delete tableSubHeadings["accumulated.unitsIndex.vsExpected"];
									setSubHeadings(updateHeadings(tableSubHeadings, response[0].parent));
								} else {
									setHeadings(updateHeadings(resultPerWeekHeadings, response[0].parent));
									setSubHeadings(updateHeadings(resultPerWeekSubHeadings, response[0].parent));
								}
								formatData(response);
							})
							.catch((e) => {
								setTableSkeleton(false);
								setLoader(false);
							});
					} else {
						setTableSkeleton(false);
						setLoader(false);
					}
				} else {
					setTableSkeleton(false);
					setLoader(false);
				}
			})
			.catch((e) => {
				setTableSkeleton(false);
				setLoader(false);
			});
		callback({
			filtervisual2units: payload.filtervisual2units,
			filtervisual2dates: payload.filtervisual2dates,
		});
	};
	const clearFilter = () => {
		setLocalFilters({ filtervisual2units: [], filtervisual2dates: [] });
	};
	useEffect(() => {
		if (filters && filters?.country) {
			setTableSkeleton(true);
			clearFilter();
			setLoaderForDropdown(true);
			getWeeksData(filters)
				.then((res) => {
					setWeekData(res.data);
					setLoaderForDropdown(false);
					setSkeleton(false);
					if (defaultFilters && Object.keys(defaultFilters).length > 0 && defaultFilters.country)
						setLocalFilters({
							filtervisual2units: defaultFilters.filtervisual2units || [],
							filtervisual2dates: defaultFilters.filtervisual2dates || [],
						});
				})
				.catch((e) => {
					setLoaderForDropdown(false);
					setSkeleton(false);
				});
			// }
		}
	}, [filters]);
	useEffect(() => {
		if (
			defaultFilters &&
			Object.keys(defaultFilters).length > 0 &&
			defaultFilters?.filtervisual2units &&
			defaultFilters?.filtervisual2dates &&
			defaultFilters.country
		) {
			onApplyFilter({ ...defaultFilters });
		}
	}, [defaultFilters]);
	const ElasticityPricingTrack = useSelector((state: any) => state.ElasticityPricingTrack);
	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<CardContent>
				<Title className="ellipsis">Result Per Week/Month and Accumulated</Title>
				<Grid container spacing={2} mt={2}>
					{!skeleton && _.isArray(weekData) && weekData.length > 0 ? (
						<>
							<Indicator position="absolute" show={loader || loaderForDropdown || ElasticityPricingTrack.unitVariationExpected} />
							<Grid item sm={3.5}>
								<Dropdown
									data={[...Unit]}
									defaultOption={localFilters.filtervisual2units || ["empty"]}
									onChange={(data) => {
										onSelectFilter("filtervisual2units", data);
									}}
									disabled={false}
									multiple={true}
									maxWidth={"30vw"}
									placeholder={"Select"}
									search={{ enable: false }}
								/>
							</Grid>
							<Grid item sm={3.5}>
								<Dropdown
									data={weekData}
									defaultOption={localFilters.filtervisual2dates || ["empty"]}
									onChange={(data) => {
										onSelectFilter("filtervisual2dates", data);
									}}
									disabled={false}
									allOption={true}
									multiple={true}
									maxWidth={"30vw"}
									placeholder={"Select"}
								/>
							</Grid>
							<Grid item sm={3.5}>
								<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
									Clear Filter
								</OrangeBtn>
								<PrimaryBtn
									disabled={disableApplyFilter()}
									color="primary"
									onClick={() => {
										onApplyFilter();
									}}
								>
									Apply Filter
								</PrimaryBtn>
							</Grid>
						</>
					) : skeleton ? (
						<>
							<Grid item sm={3.5}>
								<Skeleton height={70}></Skeleton>
							</Grid>
							<Grid item sm={3.5}>
								<Skeleton height={70}></Skeleton>
							</Grid>
							<Grid item sm={2}>
								<Skeleton height={70}></Skeleton>
							</Grid>
							<Grid item sm={2}>
								<Skeleton height={70}></Skeleton>
							</Grid>
						</>
					) : (
						<Grid container display="flex" justifyContent="center" alignItems="center" style={{ height: 400 }}>
							<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
						</Grid>
					)}
				</Grid>
				{skeleton || (_.isArray(weekData) && weekData?.length > 0) ? (
					<Dashboard
						title={null}
						showSkeleton={tableSkeleton}
						chartRef={() => {}}
						showActionButton={true}
						actionButtons={{
							mmpw: false,
							screenshot: true,
							editMedia: false,
						}}
						id={null}
					>
						<Grid container spacing={2} mt={2}>
							{!tableSkeleton && tableData.length > 0 ? (
								<Grid item sm={12} style={{ width: "500px", overflowX: "auto" }}>
									<StyledTableContainer
										isSidebarOpen={sidebarOpen}
										sx={{
											paddingBottom: 4,
											"&::-webkit-scrollbar": {
												width: 20,
												height: 8,
											},
										}}
									>
										{tableData.map((item, i) => (
											<ResultTable
												showHeader={i === 0}
												heading={headings}
												subHeading={subHeadings}
												data={[...item]}
												mainHeaders={[...mainHeadings]}
												key={`table-${i}`}
											/>
										))}
									</StyledTableContainer>
									<Typography sx={{ mt: 2, fontSize: 16, color: "#000" }}>*Expected elasticity are considering price and grams effect.</Typography>
								</Grid>
							) : !tableSkeleton ? (
								<Grid container display="flex" justifyContent="center" alignItems="center" style={{ height: 400 }}>
									<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
								</Grid>
							) : (
								<Grid item sm={12} sx={{ padding: 0 }} height={400}>
									<Skeleton variant="rectangular" height={"100%"}></Skeleton>
								</Grid>
							)}
						</Grid>
					</Dashboard>
				) : null}
			</CardContent>
		</Card>
	);
};
export default ResultPerMonthAndAccumulated;
