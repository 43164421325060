import { GraphCardType, IStats } from "../types/common";
import { DateTime } from "luxon";
import { NetRevenue } from "../assets/icons/dashboard";
import { graph1, graph2, graph3, graph4 } from "../assets/images/dashboard";
import { dataFormat } from "../util/helper";
import { faCoins, faScaleUnbalancedFlip, faChartPie, faCartShopping } from "@fortawesome/free-solid-svg-icons";

const fevDashboards: Array<GraphCardType> = [
	{
		graphImg: graph2,
		label: "Discounts and Allowances",
		type: "BarChart",
	},
	{
		graphImg: graph1,
		label: "Net Revenue Realization",
		type: "LineChart",
	},
	{
		graphImg: graph4,
		label: "Target vs Actual Tracking",
		type: "LineChart",
	},
	{
		graphImg: graph3,
		label: "Mix Management",
		type: "PieChart",
	},
];

const recentSaveedDashboards: Array<GraphCardType> = [
	{
		graphImg: graph1,
		label: "Net Revenue Realization",
		type: "LineChart",
	},
	{
		graphImg: graph2,
		label: "Discounts and Allowances",
		type: "BarChart",
	},
	{
		graphImg: graph4,
		label: "Target vs Actual Tracking",
		type: "LineChart",
	},
];

const previouslySaveDashboards: Array<GraphCardType> = [
	{
		graphImg: graph4,
		label: "Target vs Actual Tracking",
		type: "LineChart",
	},
	{
		graphImg: graph3,
		label: "Mix Management",
		type: "PieChart",
	},
	{
		graphImg: graph1,
		label: "Net Revenue Realization",
		type: "LineChart",
	},
	{
		graphImg: graph2,
		label: "Discounts and Allowances",
		type: "BarChart",
	},
];

const trendingDashboards: Array<GraphCardType> = [
	{
		graphImg: graph1,
		label: "Net Revenue Realization",
		type: "LineChart",
	},
	{
		graphImg: graph2,
		label: "Discounts and Allowances",
		type: "BarChart",
	},
	{
		graphImg: graph3,
		label: "Mix Management",
		type: "PieChart",
	},
	{
		graphImg: graph4,
		label: "Target vs Actual Tracking",
		type: "LineChart",
	},
];

const KPIData1: Array<IStats> = [
	{
		icon: faCoins,
		color: "#008cca",
		title: "Sales",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: faScaleUnbalancedFlip,
		color: "#00a84d",
		title: "VOLUME (KGS)",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: faCartShopping,
		color: "#ff6910",
		title: "UNITS",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: NetRevenue,
		title: "Gross Profit",
		value: "0",
		raise: null,
		change: "0%",
		className: "row1NetRevenue",
	},
];

const KPIData2: Array<IStats> = [
	{
		icon: faChartPie,
		color: "#008cca",
		title: "SHARE OF MARKET - SALES",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: faChartPie,
		color: "#00a84d",
		title: "SHARE OF MARKET - VOLUME",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: faChartPie,
		color: "#ff6910",
		title: "SHARE OF MARKET - UNITS",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: NetRevenue,
		title: "Gross Profit",
		value: "0",
		raise: null,
		change: "0%",
		className: "row2NetRevenue",
	},
];

const sortingDropdown = [
	{ label: "Year To Date", value: "YearToDate" },
	{ label: "Last 12 Months", value: "Last12Months" },
	{ label: "Quarter To Date", value: "QuarterToDate" },
	{
		label: dataFormat(DateTime.local().minus({ quarter: 1 }).toISO(), "Qq yyyy"),
		value: "Q-1",
	},
	{
		label: dataFormat(DateTime.local().minus({ quarter: 2 }).toISO(), "Qq yyyy"),
		value: "Q-2",
	},
	{ label: "Month To Date", value: "MonthToDate" },
	{ label: "Last Month", value: "LastMonth" },
	{ label: "Last Week", value: "LastWeek" },
];
const saveDashBoardTabsData = {
	data: [
		{
			dashboardId: 123,
			typeName: "string",
			route: "/supermarket",
			viewCount: 2,
			thumbnailUrl: "https://southportiodev.blob.core.windows.net/pepsio/thumbnail1.png",
			countries: ["string"],
			type: "string",
			id: 123456,
			name: "super1",
			version: "1",
		},
		{
			dashboardId: 123,
			typeName: "string",
			route: "/supermarket",
			viewCount: 2,
			thumbnailUrl: "https://southportiodev.blob.core.windows.net/pepsio/thumbnail1.png",
			countries: ["string"],
			type: "string",
			id: 222,
			name: "super2",
			version: "1",
		},
		{
			dashboardId: 123,
			typeName: "string",
			route: "/supermarket",
			viewCount: 2,
			thumbnailUrl: "https://southportiodev.blob.core.windows.net/pepsio/thumbnail1.png",
			countries: ["string"],
			type: "string",
			id: 3333,
			name: "super3",
			version: "1",
		},
		{
			dashboardId: 4567,
			typeName: "string",
			route: "/profit-parabola-dashboard",
			viewCount: 2,
			thumbnailUrl: "https://southportiodev.blob.core.windows.net/pepsio/thumbnail1.png",
			countries: ["string"],
			type: "string",
			id: 6666,
			name: "profit parabola1",
			version: "1",
		},

		{
			dashboardId: 6789,
			typeName: "string",
			route: "/pricing-potential",
			viewCount: 2,
			thumbnailUrl: "https://southportiodev.blob.core.windows.net/pepsio/thumbnail1.png",
			countries: ["string"],
			type: "string",
			id: 9999,
			name: "pricing potential",
			version: "1",
		},
	],
};

const saveDashboardData = {
	filters: {
		global: {
			country: "MEXICO",
			geoLevel: ["REGION-CHAN"],
			geoLevel2: ["MX SOUTHEAST-OT"],
			channel: ["DTS", "OT", "WHSL"],
			category: ["SAV-SALTY"],
			segment: ["SAV-POTATO"],
			brand: ["SABRITAS PC"],
			subBrand: ["SABRITAS SAL"],
			packSize: ["04X-CHICO"],
			date: "01/01/2021-12/31/2021",
		},
		inputs: {
			sdv: "8.00",
			cogs: "9.00",
			otherCost: "12",
			srp: "16.69",
			markup: 0.1,
			taxes: 0.11,
			elasticityCoefficient: -5,
		},
	},
	name: "super1",
	key: "super-market",
	version: "1.0",
	mmpw: '{"avg-sales-at-every-price-point":{"Text":[{"type":"Text","text":"Test","style":{"color":"#000","fontSize":20,"fontFamily":"Roboto"},"id":"Text-1","x":222.00051887658324,"y":60}]},"sales-at-every-price-point":{"Text":[{"type":"Text","text":"test1","style":{"color":"#000","fontSize":20,"fontFamily":"Roboto"},"id":"Text-1","x":187.0004332129964,"y":69}]}}',
	isShared: false,
	id: 123,
	dashboardId: 0,
};

const dataObject = {
	fevDashboards,
	recentSaveedDashboards,
	previouslySaveDashboards,
	saveDashBoardTabsData,
	saveDashboardData,
	trendingDashboards,
	KPIData1,
	KPIData2,
	sortingDropdown,
};

export default dataObject;
