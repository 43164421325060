import Highcharts from "highcharts";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

HighchartsSolidGauge(Highcharts);
export const CircularProgressBarConfig = {
	chart: {
		type: "solidgauge",
		backgroundColor: "transparent",
		// events: {
		// 	load: function () {
		// 		const chart = this;
		// 		const centerX = chart.plotLeft + chart.plotWidth / 2.3;
		// 		const centerY = chart.plotTop + chart.plotHeight / 1.6;
		// 		chart.renderer
		// 			.text("Overall Score", centerX, centerY)
		// 			.css({
		// 				fontSize: "18px",
		// 				color: "#D6D6D6",
		// 				fontWeight: "600",
		// 			})
		// 			.attr({
		// 				zIndex: 5,
		// 			})
		// 			.add();
		// 	},
		// },
	},
	title: null,
	pane: {
		startAngle: 0,
		endAngle: 360,
		background: [
			{
				backgroundColor: "#D6D6D6",
				borderWidth: 0,
				outerRadius: "100%",
				innerRadius: "60%",
				shape: "arc",
			},
		],
	},
	yAxis: [
		{
			min: 0,
			max: 100,
			lineWidth: 0,
			tickPositions: [],
			stops: [
				[0.1, "#6C88B7"],
				[0.5, "#486492"],
				[0.9, "#3984A3"],
			],
		},
	],
	series: [
		{
			name: "Score",
			data: [80],

			dataLabels: {
				enabled: true,
				useHTML: true,
				format: `
        <div>
            <span style="font-weight: 600; font-size: 24px; color: #333333; margin-left:10px">{y}/100</span><br/>
            <span style="font-size: 16px; color: #666666;">Overall Score</span>
        </div>
    `,
				style: {
					fontWeight: "600",
					fontSize: "24px",
					color: "#333333",
					textOutline: "none",
				},
				borderWidth: 0,
				verticalAlign: "middle",
				y: 0,
			},
		},
	],
	tooltip: {
		enabled: false,
	},
	navigation: {
		buttonOptions: {
			enabled: false, // Disable navigation buttons
		},
	},
	credits: false,
};
