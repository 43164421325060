import React from "react";
import { Grid } from "@mui/material";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";

const MeasureFilter: React.FC<{ data; selectedMeasureFilters; callback }> = ({ data, selectedMeasureFilters, callback }) => {
	const onSelectMeasure = (type, selectedVal) => {
		let temp = { ...selectedMeasureFilters };
		if (type === "variable1") {
			temp.somGroup = selectedVal === "SOM" ? "" : temp.somGroup;
			temp.somMeasure = selectedVal === "SOM" ? "" : temp.somMeasure;
		}
		temp[type] = selectedVal;
		callback(temp);
	};

	return (
		<Grid container spacing={2} columns={6}>
			<Grid item sm={6} md={1}>
				<DropdownTitle>Variable 1</DropdownTitle>
				<RadioButtonGroup
					color="#000"
					data={data.variable1.options}
					defaultOption={selectedMeasureFilters.variable1}
					select={(data) => onSelectMeasure("variable1", data)}
				/>
			</Grid>
			<Grid item sm={6} md={3}>
				<DropdownTitle>Variable Configuration</DropdownTitle>
				<Grid container>
					<Grid className="m-r-10 b-r-1">
						<RadioButtonGroup
							color="#000"
							data={data.SOMVarConfig.options}
							defaultOption={selectedMeasureFilters.somGroup}
							select={(data) => onSelectMeasure("somGroup", data)}
							direction="row"
							disabled={selectedMeasureFilters.variable1 !== "SOM"}
						/>
					</Grid>
					<Grid>
						<RadioButtonGroup
							color="#000"
							data={data.SOM.options}
							defaultOption={selectedMeasureFilters.somMeasure}
							select={(data) => onSelectMeasure("somMeasure", data)}
							direction="row"
							disabled={selectedMeasureFilters.variable1 !== "SOM"}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item sm={6} md={2}>
				<DropdownTitle>API Configuration</DropdownTitle>
				<RadioButtonGroup
					color="#000"
					data={data.measure.options}
					select={(data) => onSelectMeasure("apiUnits", data)}
					defaultOption={selectedMeasureFilters.apiUnits}
					direction="row"
				/>
			</Grid>
		</Grid>
	);
};

export default MeasureFilter;
