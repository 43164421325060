import { IUserDetails, INotificationCardType } from "../../types/common";
/* Creates a GraphQL mutation query for creating or updating a notification.
 * @param user - The user details.
 * @param notification - The notification details.
 * @returns The GraphQL mutation query.
 */
export const CREATE_NOTIFICATION_QUERY = (user: IUserDetails, notification: INotificationCardType) => {
	return {
		query: `mutation
    {updateNotification(
      ${notification.id ? `Id: ${notification.id},` : ""}
      UserId: "${user.id}",
      Type: "${notification.type}",
      Title: "${notification.title}",
      SubTitle: "${notification.subTitle}",
      ExpiresOn: "${notification.expiresOn.toISOString()}",
      Message: "${notification.message}",
      PageKey: "${notification.pageKey}",
      Createdby: "${user.firstName} ${user.lastName}",
      Modifiedby: "${user.firstName} ${user.lastName}"
    ){
      id: Id
      type: Type
      title: Title
      subTitle: SubTitle
      expiresOn: ExpiresOn
      message: Message
      pageKey: PageKey
    }}`,
	};
};
/* Creates a GraphQL query get notifications from the database.
 * @param {string} userId - The user id.
 * @param {string} pageName - The page for which notifcations are being fetched.
 * @param {number} sortOrder - The selected sort order in Alerts and Notifications page.
 * Need either pageName or sortOrder parameter each time.
 * If pageName is provided, data is fetched from notificationsByPageKey function.
 * If sortOrder is provided, data is fetched from notification function.
 * @returns The GraphQL query.
 */

export const NOTIFICATION_QUERY = (userId: string, pageName?: string, sortOrder?: number) => {
	return {
		query: `{
      ${pageName ? `notificationsByPageKey(pageKey:"${pageName}"` : `notification(filter:${sortOrder}`}
      UserId: "${userId}"
      ){
      id: Id
      type: Type
      title: Title
      subTitle: SubTitle
      createdDate: CreatedDate
      expiresOn: ExpiresOn
      message: Message
      pageKey: PageKey
    }}`,
	};
};
/* Creates a GraphQL mutation query for dismissing a notification.
 * @param userId - The user id.
 * @param notificationId - The id for the dismissed notification.
 * @returns The GraphQL mutation query.
 */

export const DISMISS_NOTIFICATION_QUERY = (userId: number, notificationId: string) => {
	return {
		query: `mutation{
    updateNotificationDismissal(
      UserId: "${userId}",
      NotificationId: ${notificationId}
    ) }`,
	};
};
/* Creates a GraphQL mutation query to delete a notification.
 * @param {string} notificationId - The id of the notification to be deleted.
 * @returns The GraphQL mutation query.
 */

export const DELETE_NOTIFICATION_QUERY = (notificationId: number) => {
	return {
		query: `mutation { deleteNotification(Id: ${notificationId}) }`,
	};
};
