import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import dataObject from "../../../../mocks/executionTracker";
import { IMnAFilterConfigurations, ISwitchingPortfolioGeoFilterConfig } from "../../../../types/mixAndAssortment";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import SwitchingSKUTable from "../SwitchingSKUTable/SwitchingSKUTable";
import { portfolioColumns } from "../SwitchingSKUTable/TableConfig";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import Dropdown from "../../../../components/Dropdown";
import { IconDownload } from "../../../../assets/icons/mna";

const disableApplyBtn = (selectedFilters: IMnAFilterConfigurations) => {
	let flag: boolean = true;
	if (
		selectedFilters.assortmentScenario?.length &&
		selectedFilters.businessUnit.length &&
		selectedFilters.channel.length &&
		selectedFilters.country.length &&
		selectedFilters.dateTimePeriod.length &&
		selectedFilters.mixScenario?.length &&
		selectedFilters.region.length &&
		selectedFilters.storeSegment.length &&
		selectedFilters.portfolioGeo?.level &&
		selectedFilters.portfolioGeo?.channel.length &&
		selectedFilters.portfolioGeo?.region.length &&
		selectedFilters.portfolioGeo?.storeSegment.length
	)
		flag = false;
	return flag;
};

const Portfolio: React.FC<{
	selectedFilters: IMnAFilterConfigurations;
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
}> = ({ selectedFilters, setSelectedFilters }) => {
	const [geoFilter, setGeoFilter] = useState<ISwitchingPortfolioGeoFilterConfig>({
		level: "",
		channel: [],
		region: [],
		storeSegment: [],
	});
	const onChangeGeoFilter = (key, value) => {
		const selectedGeoValues = { ...geoFilter, [key]: value };
		const selectedValues = { ...selectedFilters, portfolioGeo: selectedGeoValues };
		setGeoFilter(selectedGeoValues);
		setSelectedFilters(selectedValues);
	};
	const [skeleton, setSkeleton] = useState<boolean>(true);

	const applyFilter = () => {
		setSkeleton(false);
	};

	const clearFilter = () => {
		const defaultFilters = {
			level: "",
			channel: [],
			region: [],
			storeSegment: [],
		};
		setGeoFilter(defaultFilters);
		const selectedValues = { ...selectedFilters, portfolioGeo: defaultFilters };
		setSelectedFilters(selectedValues);
		setSkeleton(true);
	};

	return (
		<>
			<Card className="m-b-20">
				<CardContent>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Title className="ellipsis">Portfolio</Title>
						<Stack direction="row" alignItems="center" spacing={2}>
							<Stack direction="row" alignItems="center" spacing={1}>
								<DropdownTitle>Level:</DropdownTitle>
								<Grid item sm={3}>
									<Dropdown
										keyText="table-sub-heading"
										data={dataObject.portfolioLevelFilterData}
										multiple={false}
										onChange={(data) => onChangeGeoFilter("level", data[0])}
										placeholder="Level"
										defaultOption={selectedFilters.portfolioGeo?.level}
									/>
								</Grid>
							</Stack>
							<Grid container>
								<Grid item style={{ marginLeft: "auto" }}>
									<PrimaryBtn onClick={() => {}} disabled={disableApplyBtn(selectedFilters)}>
										<Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
											<IconDownload />
											<Grid marginLeft={"5px"}>Download CSV</Grid>
										</Grid>
									</PrimaryBtn>
								</Grid>
							</Grid>
						</Stack>
					</Stack>
					<Stack direction="row" className="m-t-20 m-l-20">
						<Box width="700px">
							<CommonMnAFilters
								filterData={dataObject.overallFilterOptions}
								onChange={onChangeGeoFilter}
								data={dataObject.portfolioFilter}
								defaultFilters={selectedFilters.portfolioGeo ?? null}
								filterOrder={dataObject.portfolioFilterOrder}
							/>
						</Box>
						<Grid className="p-l-16" marginBottom={5} mt={3}>
							<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
								Clear Filter
							</OrangeBtn>
							<PrimaryBtn color="primary" onClick={applyFilter} disabled={disableApplyBtn(selectedFilters)}>
								Apply Filter
							</PrimaryBtn>
						</Grid>
					</Stack>
					{!skeleton ? (
						<Grid container>
							<Grid item sm={12} marginLeft={"16px"}>
								<SwitchingSKUTable tableData={dataObject.mockPortfolioData} tableColumns={portfolioColumns} />
							</Grid>
						</Grid>
					) : (
						<Grid item xs={12}>
							<Skeleton variant="rectangular" height={250} />
						</Grid>
					)}
				</CardContent>
			</Card>
		</>
	);
};

export default Portfolio;
