import styled from "styled-components";
import { Box, Switch, Typography } from "@mui/material";

const BlackTitle = styled(Typography)(({ theme }) => ({
	color: "#000",
	fontSize: 20,
	fontWeight: 600,
	marginBottom: 20,
}));

const GraphCard = styled(Box)(({ theme }) => ({
	marginRight: "10px",
}));

const GraphSubTitle = styled(Typography)(({ theme }) => ({
	fontSize: "18px",
	color: "#000",
	fontWeight: "600",
}));

const LegendBox = styled(Box)<any>`
	height: 20px;
	width: 20px;
	backgroundcolor: ${(props) => props.bgcolor};
	borderradius: 3px;
`;

const CustomSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		"&.Mui-checked": {
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: "#005393",
			},
		},
	},
	"& .MuiSwitch-track": {
		backgroundColor: "gray",
	},
}));

export { GraphSubTitle, BlackTitle, GraphCard, LegendBox, CustomSwitch };
