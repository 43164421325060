import { graphqlAPI, userProfileAPI } from "../../store/api-constants";
import { API } from "../../util/helper";
import { CREATE_UPDATE_USER_QUERY, GET_USER_QUERY, DELETE_USER_QUERY, UPDATE_DARK_MODE } from "../../util/queries/users";

/* This function makes an API call to add or update a user
 * @param {object} userDetails - The details of the user to be added.
 * @param {string} currentUserId - The user adding the user. Only user with Admin role can add.
 */
export const createOrUpdateUser = async (userDetails, currentUserId) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = CREATE_UPDATE_USER_QUERY(userDetails, currentUserId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = null;
			let error = "";
			if (response && response.data.data.updateusers) {
				data = response.data.data.updateusers;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { status: response.status, data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

/* This function makes an API call to get a user
 * @param {number} id - The id of the user to be fetched.
 */
export const getUser = async (id: number) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_USER_QUERY(id);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getUser[0];
			}
			return { status: response.status, data: data };
		});
	} catch (e) {
		return e;
	}
};

/* This function makes an API call to update application theme.
 * @param {boolean} isDarkTheme - Darkmode flag.
 * @param {number} id - The id of the logged in user.
 */
export const updateDarkTheme = async (isDarkTheme: boolean, userId: number) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = UPDATE_DARK_MODE(isDarkTheme, userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.updateisdarkmode;
			}
			return { status: response.status, data: data };
		});
	} catch (e) {
		return e;
	}
};

/* This function makes an API call to get all users with permission for a particular country
 * @param {string} country - The country of the user to be fetched.
 */
export const getAllUsers = async (country: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_USER_QUERY(0, "", country);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getUser.map((item) => {
					item.fullName = item.firstName + " " + item.lastName;
					return item;
				});
			}
			return { status: response.status, data: data };
		});
	} catch (e) {
		return e;
	}
};
/* This function makes an API call to get all users
 */
export const getUserList = async () => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_USER_QUERY();
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = {};
			if (response && response.data) {
				data = {
					users: response.data.data.getUser,
					totalUsers: response.data.data.getUser.length,
				};
			}
			return { status: response.status, data: data };
		});
	} catch (e) {
		return e;
	}
};

/* This function makes an API call to delete a user
 * @param {string} id - The id of the user to be deleted.
 * @param {string} userId - The user deleting the user. Only user with Admin role can delete.
 */
export const deleteUser = async (id: string, userId: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	let queryParameter = DELETE_USER_QUERY(id, userId);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = [];
		if (response && response.data) {
			data = response.data.data.deleteUser;
		}
		return { data: data };
	});
};

/* This function makes an API call to set the user profile
 * @param {object} formData - The form data holding the user profile to be updated.
 */
export const updateUserProfile = async (formData) => {
	try {
		const userProfileApiUrl: string = await userProfileAPI();
		return await API.post(`${userProfileApiUrl}`, formData, {
			headers: { "content-type": "multipart/form-data" },
		});
	} catch (e) {
		return e;
	}
};

/* This function makes an API call to delete the user profile
 * @param {string} profileImageUrl - The name of the user profile to be deleted.
 */
export const deleteUserProfile = async (profileImageUrl) => {
	try {
		const userProfileApiUrl: string = await userProfileAPI();
		return await API.delete(`${userProfileApiUrl}/${profileImageUrl}`);
	} catch (e) {
		return e;
	}
};
