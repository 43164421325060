import React, { createContext, FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import PageContainer from "../../components/PageContainer";
import { Title } from "../../styles/Common.Styled";
import ScenarioLibrary from "../../components/ScenarioLibrary";
import ScenarioDetails from "./ScenarioDetails/ScenarioDetails";
import { pageNames } from "../../mocks/common";
import { IMixPopup } from "../../types/mixAndAssortment";

export const MixPopupContext = createContext<{
	popup: IMixPopup;
	setPopup: React.Dispatch<React.SetStateAction<IMixPopup>>;
}>({
	popup: { show: false, title: "", message: "" },
	setPopup: () => undefined,
});

const MixOptimizer: FC = () => {
	const theme = useTheme();
	let history = useHistory();
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const [showScenarioLibrary, setShowScenarioLibrary] = useState(true);
	const [selectedScenario, setSelectedScenario] = useState<string | null>(null);
	const [isNewScenarioMode, setIsNewScenarioMode] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [popup, setPopup] = useState<IMixPopup>({ show: false, title: "", message: "" });

	const closeScenario = () => {
		history.push(window.location.pathname);
	};

	const onCloseScenario = () => {
		setSelectedScenario(null);
		setIsNewScenarioMode(false);
		setIsEditMode(false);
		setShowScenarioLibrary(true);
	};

	const newScenario = () => {
		query.set("mode", "new");
		history.push({ search: query.toString() });
	};

	const onNewScenario = () => {
		setSelectedScenario(null);
		setIsNewScenarioMode(true);
		setIsEditMode(false);
		setShowScenarioLibrary(false);
	};

	const viewScenario = (scenario: string) => {
		query.set("scenario", scenario);
		query.set("mode", "view");
		history.push({ search: query.toString() });
	};

	const onViewScenario = (scenario: string) => {
		setSelectedScenario(scenario);
		setIsNewScenarioMode(false);
		setIsEditMode(false);
		setShowScenarioLibrary(false);
	};

	const editScenario = (scenario: string) => {
		query.set("scenario", scenario);
		query.set("mode", "edit");
		history.push({ search: query.toString() });
	};

	const onEditScenario = (scenario: string) => {
		setSelectedScenario(scenario);
		setIsNewScenarioMode(false);
		setIsEditMode(true);
		setShowScenarioLibrary(false);
	};

	const closePopup = () => {
		setPopup({ show: false, title: "", message: "" });
	};

	useEffect(() => {
		const queryScenario = query.get("scenario");
		const queryMode = query.get("mode");
		if (queryScenario) {
			if (queryMode === "view") {
				onViewScenario(queryScenario);
			} else if (queryMode === "edit") {
				onEditScenario(queryScenario);
			} else {
				closeScenario();
			}
		} else if (queryMode === "new") {
			onNewScenario();
		} else {
			onCloseScenario();
		}
	}, [query]);

	return (
		<MixPopupContext.Provider value={{ popup, setPopup }}>
			<Dialog open={popup.show} onClose={closePopup}>
				<DialogTitle color={theme.palette.common.black}>{popup.title}</DialogTitle>
				<DialogContent>
					<DialogContentText color={theme.palette.grey[800]}>{popup.message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					{popup.bypass ? (
						<>
							<Button onClick={popup.bypass} variant={"contained"}>
								Yes
							</Button>
							<Button onClick={closePopup} autoFocus variant={"contained"}>
								No
							</Button>
						</>
					) : (
						<Button onClick={closePopup} autoFocus variant={"contained"}>
							Close
						</Button>
					)}
				</DialogActions>
			</Dialog>
			<PageContainer page={pageNames.mixOptimizer}>
				<Title className={"ellipsis m-b-10"}>Mix Optimizer</Title>
				{showScenarioLibrary ? (
					<ScenarioLibrary type={"Mix"} newScenario={newScenario} viewScenario={viewScenario} editScenario={editScenario} />
				) : (
					<ScenarioDetails selectedScenario={selectedScenario} isNewScenarioMode={isNewScenarioMode} isEditMode={isEditMode} closeScenario={closeScenario} />
				)}
			</PageContainer>
		</MixPopupContext.Provider>
	);
};

export default MixOptimizer;
