import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { LoadMoreBtn } from "../../pages/Home/components/Dashboard.Styled";
import { DashboardPerformanceIcon } from "../../assets/icons/dashboard";

const NoDashboard: React.FC<{ heading?: string; subHeading?: string; showAddButton?: boolean }> = ({
	heading = "",
	subHeading = "",
	showAddButton = false,
}) => {
	return (
		<Grid display="flex" justifyContent="center" alignItems="center" flexDirection="column" className="m-t-30">
			<img className="m-b-15" style={{ width: 100 }} src={DashboardPerformanceIcon} alt="Dashboard Performance" />

			<Typography className="m-b-15">{heading}</Typography>
			<Typography className="m-b-15">{subHeading}</Typography>
			{showAddButton && <LoadMoreBtn className="m-b-15">Add Dashboards</LoadMoreBtn>}
		</Grid>
	);
};
export default NoDashboard;
