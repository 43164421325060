import { INotificationCardType } from "../types/common";

const sortNotification = [
	{ label: "A-Z", value: "1" },
	{ label: "Z-A", value: "2" },
	{ label: "Newest", value: "3" },
	{ label: "Oldest", value: "4" },
	{ label: "Read", value: "5" },
	{ label: "Unread", value: "6" },
];

export const typesOfNotification = [
	{ label: "Bug Report", value: "Bug" },
	{ label: "System Alert", value: "System_Alert" },
	{ label: "New Dashboard Created", value: "New_Dashboard_Created" },
	{ label: "Data Updated", value: "Data_Updated" },
];

const notifications: Array<INotificationCardType> = [
	{
		id: 1,
		type: "bug",
		title: "Bug Report",
		subTitle: "We're blasting off",
		pageKey: "Home",
		message: "OneSignal announces 500% growth, delivering 2 trillion messages annually & delivery rates of 1.75 million per second.",
		expiresOn: new Date("2021-11-14T00:34"),
	},
	{
		id: 2,
		type: "alert",
		title: "System Alert",
		subTitle: "We're blasting off",
		pageKey: "Home",
		message: "OneSignal announces 500% growth, delivering 2 trillion messages annually.",
		expiresOn: new Date("2021-11-10T00:34"),
	},
	{
		id: 3,
		type: "new",
		title: "New Dashboard Created",
		subTitle: "We're blasting off",
		pageKey: "Home",
		message: "OneSignal announces 500% growth, delivering 2 trillion messages annually & delivery rates of 1.75 million per second.",
		expiresOn: new Date("2021-11-14T11:04"),
	},
	{
		id: 4,
		type: "update",
		title: "Data Update",
		subTitle: "We're blasting off",
		pageKey: "Home",
		message: "OneSignal announces 500% growth, delivering 2 trillion messages annually.",
		expiresOn: new Date("2021-11-14T00:34"),
	},
];

const dataObject = {
	sortNotification,
	notifications,
};

export default dataObject;
