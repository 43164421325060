import React, { useEffect, useRef, useState } from "react";
import Dashboard from "../../../../../../components/Dashboard";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { columnChartConfig } from "./columnChartConfig";
import { Grid } from "@mui/material";
import _ from "lodash";
import WeeklyTable from "../../../WeeklyTable";
import { makeStyles } from "@material-ui/core/styles";
import { formatNumber } from "../../../../../../util/helper";
import HighchartContainer from "../../../../../../components/HighchartContainer";

const useStyles = makeStyles(() => ({
	b2: {
		border: "2px solid gray",
	},
	bold: {
		fontWeight: "bold",
	},
	title: {
		fontWeight: 600,
		fontSize: 18,
	},
	blueBox: {
		background: "#005EA6",
		color: "#fff",
	},
	center: {
		display: "flex",
		alignItems: "center",
		paddingLeft: 10,
	},
}));

const UnitVariationExpected: React.FC<{ id; data }> = ({ id, data }) => {
	const classes = useStyles();
	const chartRef = useRef(null);
	const [tableHeadings, setTableHeadings] = useState([]);
	const [tableValues, setTableValues] = useState([]);

	useEffect(() => {
		if (_.isObject(data) && Object.keys(data).length > 0) {
			if (chartRef && chartRef.current && chartRef.current.chart) {
				const chart = chartRef.current.chart;
				const series1: any = [],
					series2: any = [],
					series3: any = [],
					xAxis: any = [],
					tableHeadings: any = [];
				data.week.map((x, i) => {
					series1.push(x.priceEffect);
					series2.push(x.gramEffect);
					series3.push(x.totalEffect);
					tableHeadings.push(i + 1);
					xAxis.push(i + 1);
				});
				chart.xAxis[0].setCategories(xAxis);
				chart.series[0].setData(series1);
				chart.series[1].setData(series2);
				chart.series[2].setData(series3);

				const tableData = [];
				tableData.push({ title: "Price Effect", width: 200, data: series1 });
				tableData.push({ title: "Gram Effect", width: 200, data: series2 });
				tableData.push({ title: "Total Effect", width: 200, data: series3 });
				setTableHeadings(tableHeadings);
				setTableValues(tableData);
			}
		}
	}, [data]);

	return (
		<Dashboard title={data.displayName} id={id} chartRef={chartRef}>
			<Grid style={{ border: "3px solid #cad0d8", borderRadius: 10, padding: 20 }}>
				<HighchartContainer chartRef={chartRef} id={id}>
					<HighchartsReact highcharts={Highcharts} options={columnChartConfig} ref={chartRef} />
				</HighchartContainer>

				<WeeklyTable heading={tableHeadings} data={tableValues} showPercent={true} />
			</Grid>
			<Grid mt={5} mb={5} style={{ border: "3px solid #cad0d8", borderRadius: 10, padding: 20 }}>
				<Grid container sx={{ height: 70 }} justifyContent="center" alignItems="center">
					<span className={classes.title}> Full Year {data.displayName}</span>
				</Grid>
				<Grid container sx={{ height: 70 }}>
					<Grid item sm={2} className={`${classes.b2} ${classes.blueBox} ${classes.center}`}>
						<span className={classes.bold}>Price Effect</span>
					</Grid>
					<Grid item sm={2} className={`${classes.b2} ${classes.center}`}>
						{formatNumber(data.fullYear.priceEffect.toFixed(2))}%
					</Grid>
					<Grid item sm={2} className={`${classes.b2} ${classes.blueBox} ${classes.center}`}>
						<span className={classes.bold}>Gram Effect</span>
					</Grid>
					<Grid item sm={2} className={`${classes.b2} ${classes.center}`}>
						{formatNumber(data.fullYear.gramEffect.toFixed(2))}%
					</Grid>
					<Grid item sm={2} className={`${classes.b2} ${classes.blueBox} ${classes.center}`}>
						<span className={classes.bold}>Total Effect</span>
					</Grid>
					<Grid item sm={2} className={`${classes.b2} ${classes.center}`}>
						{formatNumber(data.fullYear.totalEffect.toFixed(2))}%
					</Grid>
				</Grid>
			</Grid>
		</Dashboard>
	);
};

export default UnitVariationExpected;
