import React, { useContext } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { EnvironmentContext } from "../App";

import { LOGIN_CALLBACK } from "./CONSTANTS";

import Indicator from "../components/Loader";
import AuthError from "../components/AuthError";
import RouterConfig from "./RouterConfig";

const AuthenticationModel = () => {
	const history = useHistory();
	const environmentVariables = useContext(EnvironmentContext).environmentVariables;
	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
	};
	const oktaAuth = new OktaAuth({
		issuer: environmentVariables.AUTH_URL,
		clientId: environmentVariables.IDENTITY_CLIENT_ID,
		redirectUri: window.location.origin + "/signin-oidc",
		scopes: ["openid", "email", "profile", "offline_access"],
	});
	const onAuthResume = async () => {
		history.push("/");
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<Switch>
				<Route
					path={LOGIN_CALLBACK}
					render={(props) => <LoginCallback {...props} errorComponent={AuthError} loadingElement={<Indicator show={true} />} onAuthResume={onAuthResume} />}
				/>
				<RouterConfig />
			</Switch>
		</Security>
	);
};

export default AuthenticationModel;
