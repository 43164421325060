import React from "react";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import { Loss, Profit, Title, Value } from "./StatCard.Styled";

const StatCard: React.FC<{ data; showSkeleton: boolean }> = ({ data, showSkeleton }) => {
	const { title, value, raise, change } = data;

	return (
		<Card>
			<CardContent data-testid="stat-card-container">
				{!showSkeleton ? (
					<>
						<Title>{title}</Title>
						<Box alignItems="center">
							<Grid container spacing={2} display="flex" justifyContent="space-between" alignItems={"center"} style={{ marginTop: "15px" }}>
								<Grid item md={6}>
									<Value>{value}</Value>
								</Grid>
								<Grid item md={6}>
									{raise ? (
										<Profit data-testid="profit">
											+ {change} <ArrowUpward />
										</Profit>
									) : raise !== undefined ? (
										<Loss data-testid="loss">
											- {change} <ArrowDownward />
										</Loss>
									) : null}
								</Grid>
							</Grid>
						</Box>
					</>
				) : (
					<>
						<Box width={50} height={50}>
							<Skeleton width={"100%"} height={"100%"} />
						</Box>
						<Box alignItems="center">
							<Grid container spacing={2} display="flex" justifyContent="space-between" alignItems={"center"}>
								<Grid item>
									<Value>
										<Skeleton width={100} height={50} />
									</Value>
								</Grid>
								<Grid item>
									<Skeleton width={50} height={50} />
								</Grid>
							</Grid>
						</Box>
					</>
				)}
			</CardContent>
		</Card>
	);
};
export default StatCard;
