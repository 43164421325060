/* Function to return style property value based on property type.
 * @param  oldValue - The object contains old value.
 * @param  newValue - The object contains new value.
 * @param dataType - The object contains data type value.
 * @param propertyType - The object contains property type value.
 * @param defaultValue - The object contains default value.
 */
export function updateStylePropertyValue(oldValue, newValue, dataType, propertyType, defaultValue) {
	if (dataType === propertyType) {
		return newValue ?? defaultValue;
	} else {
		return oldValue;
	}
}
