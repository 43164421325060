import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TrendingDashboard from "../TrendingDashboard";
import FavoritedDashboard from "../FavoritedDashboard";
import SavedDashboard from "../SavedDashboard";
import SharedDashboard from "../SharedDashboard";
import { Grid, Tab, Tabs } from "@mui/material";
function TabPanel(props: { children: any; value: any; index: any }) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={{ width: "100%", paddingLeft: "16px" }}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
const TabView: React.FC<{ callback; sortOrder }> = ({ callback, sortOrder }) => {
	const [value, setValue] = useState(0);
	const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
		setValue(newValue);
		callback(newValue);
	};

	return (
		<Grid container spacing={1}>
			<Grid container className="m-b-15" sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary">
					<Tab data-testid="tab" label="Trending Dashboards" id="simple-tab-0" />
					<Tab data-testid="tab" label="Favorited Dashboards" id="simple-tab-1" />
					<Tab data-testid="tab" label="Saved Dashboards" id="simple-tab-2" />
					<Tab data-testid="tab" label="Shared Dashboards" id="simple-tab-3" />
				</Tabs>
			</Grid>
			<TabPanel value={value} index={0}>
				<TrendingDashboard sortOrder={sortOrder} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<FavoritedDashboard sortOrder={sortOrder} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<SavedDashboard sortOrder={sortOrder} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<SharedDashboard sortOrder={sortOrder} />
			</TabPanel>
		</Grid>
	);
};
export default TabView;
