import { fetchAssortmentDetails } from "../../../util/mixAndAssortmentServices";
import { loader } from "../common.action";
import { GET_SCENARIO_DETAIL, GET_SCENARIO_DETAIL_FAILURE, GET_SCENARIO_DETAIL_SUCCESS } from "./constants";

const getScenarioDetail = () => {
	return {
		type: GET_SCENARIO_DETAIL,
	};
};

export const getScenarioDetailSuccess = (payload: any, isReadOnly: boolean) => {
	return {
		type: GET_SCENARIO_DETAIL_SUCCESS,
		payload,
		isReadOnly,
	};
};

const getScenarioDetailFailure = (error) => {
	return {
		type: GET_SCENARIO_DETAIL_FAILURE,
		payload: error,
	};
};

export function getScenarioDetailData(scenarioName: string, isReadOnly: boolean) {
	return (dispatch: any) => {
		dispatch(getScenarioDetail());
		fetchAssortmentDetails(scenarioName)
			.then((response) => {
				if (response && response.data) {
					dispatch(getScenarioDetailSuccess(response.data, isReadOnly));
				} else if (response && response.error) {
					dispatch(getScenarioDetailFailure(response.error));
				}
				dispatch(loader(false));
			})
			.catch((error) => {
				dispatch(getScenarioDetailFailure(error));
			});
	};
}
