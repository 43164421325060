import React, { useEffect, useState } from "react";
import _ from "lodash";
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Typography, Skeleton } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Row from "./Row";
const useStyles = makeStyles(() => ({
	borderClass: {
		borderLeft: "2px solid #D3D3D3",
		borderBottom: "2px solid #D3D3D3",
	},
	widthClass: {
		borderLeft: "2px solid #D3D3D3",
		borderBottom: "2px solid #D3D3D3",
		textOverflow: "ellipsis",
		overflow: "hidden",
		width: "18%",
	},
	pl2: {
		paddingLeft: "10px",
	},
	headerColor: {
		backgroundColor: "#D3D3D3",
	},
	customTableContainer: {
		overflowX: "initial",
	},
	tableFooter: {
		position: "sticky",
		bottom: 0,
		zIndex: 1,
	},
}));
const MultiLevelTable: React.FC<{
	data: any;
	tableHeader?: any;
	tableFooter?: any;
	style?;
	level?;
	showSkeleton?: boolean;
	hierarchy;
	hierarchyData?;
	filteredData?;
	orderByNum?;
}> = ({ data, tableHeader, tableFooter, style, level, showSkeleton, hierarchy, hierarchyData, filteredData = null, orderByNum = "name" }) => {
	const classes = useStyles();
	const [levelData, setLevelData] = useState<any>([]);

	const header = tableHeader?.map((cell, i) => {
		return (
			<TableCell
				sx={{ bgcolor: "#D3D3D3" }}
				key={`table-header-cell-${i}`}
				align={i === 0 ? "left" : "center"}
				className={`${classes.headerColor} ${i === 0 ? classes.borderClass : classes.widthClass}`}
				style={{ textWrap: "nowrap" }}
			>
				{cell?.label}
			</TableCell>
		);
	});
	const footer = tableFooter?.map((cell, i) => {
		return (
			<TableCell
				sx={{ bgcolor: "#D3D3D3" }}
				key={`table-footer-cell-${i}`}
				align={i === 0 ? "left" : "center"}
				className={`${classes.headerColor} ${i === 0 ? classes.borderClass : classes.widthClass}`}
			>
				{cell?.label}
			</TableCell>
		);
	});

	useEffect(() => {
		if (filteredData && orderByNum !== "name") {
			setLevelData(_.orderBy(filteredData, [orderByNum], ["desc"]));
		} else if (filteredData) {
			setLevelData(_.sortBy(filteredData, (item) => item?.name));
		} else if (orderByNum !== "name") {
			setLevelData(_.orderBy(data[level], [orderByNum], ["desc"]));
		} else {
			setLevelData(_.sortBy(data[level], (item) => item?.name));
		}
	}, []);
	return (
		<TableContainer component={Paper} style={{ height: style.height, overflow: style.overflow }} className={classes.customTableContainer}>
			<Table aria-label="collapsible table" stickyHeader>
				<TableHead>
					<TableRow>{header}</TableRow>
				</TableHead>
				{!showSkeleton ? (
					<TableBody>
						{levelData ? (
							levelData?.map((row: any, i) => (
								<Row
									key={`row-${i}`}
									data={row}
									originalData={data}
									level={level}
									style={style}
									hierarchy={hierarchy}
									hierarchyData={hierarchyData}
									orderByNum={orderByNum}
								/>
							))
						) : (
							<Typography className={classes.pl2}>No Data Found</Typography>
						)}
					</TableBody>
				) : (
					<TableBody>
						{[...Array(8).keys()].map((i) => (
							<TableRow key={`table-row-${i}-skeleton`}>
								{[...Array(tableHeader.length).keys()].map((j) => (
									<TableCell key={`table-row-${i}-cell-${j}-skeleton`}>
										<Skeleton width="100%" />
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				)}
				{tableFooter && (
					<TableHead>
						<TableRow className={classes.tableFooter}>{footer}</TableRow>
					</TableHead>
				)}
			</Table>
		</TableContainer>
	);
};
export default MultiLevelTable;
