import { Grid, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import { BlackFilledInfo } from "../../../assets/icons/mna";
import { getDataSources } from "../../../util/mixAndAssortmentServices";
import { useDispatch } from "react-redux";
import { toast } from "../../../store/actions/toast.action";
import { IMnADataSourceList } from "../../../types/mixAndAssortment";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
		padding: "10px",
	},
}));
const DatasourceTooltip: FC<{ dashboardName: string }> = ({ dashboardName }) => {
	const dispatch = useDispatch();
	const [dataSourceData, setDataSourceData] = useState<IMnADataSourceList[]>([]);
	useEffect(() => {
		getDataSources(dashboardName).then((response) => {
			if (response.data) {
				setDataSourceData(response.data);
			} else if (response.error) {
				dispatch(toast("Data Source Data: " + response.error, true, 2000, "error"));
			}
		});
	}, []);
	return (
		<Grid
			style={{
				color: "#7E7E7E",
				display: "flex",
				fontWeight: 600,
				position: "absolute",
				right: "15px",
				zIndex: 10,
				alignItems: "center",
				marginRight: "18px",
			}}
		>
			Data Sources
			<HtmlTooltip
				title={
					<React.Fragment>
						{dataSourceData.map((dataSource) => {
							return (
								<Typography color="inherit">
									{dataSource.sourceType} --{">"} {dataSource.sourceName}
								</Typography>
							);
						})}
					</React.Fragment>
				}
			>
				<BlackFilledInfo style={{ cursor: "pointer", marginLeft: "5px" }} />
			</HtmlTooltip>
		</Grid>
	);
};

export default DatasourceTooltip;
