import {
	GET_ANCHOR_PRODUCT_FILTERS,
	GET_ANCHOR_PRODUCT_FILTERS_SUCESS,
	GET_ANCHOR_PRODUCT_FILTERS_FAILURE,
	ON_CHANGE_ANCHOR_OPTION,
	CLEAR_ANCHOR_PRODUCT_FILTERS,
} from "../actions/constants";
import { mapFilterOptions, selectAllOptions } from "../../util/helper";
import _ from "lodash";

export const anchorInitialState = {
	data: [],
	loading: false,
	error: {},
	selectedFilters: {
		anchorCategory: null,
		anchorSegment: null,
		anchorBrand: null,
		anchorSubBrand: null,
		anchorPackSize: null,
	},
	disabledFilters: {
		anchorCategory: true,
		anchorSegment: true,
		anchorBrand: true,
		anchorSubBrand: true,
		anchorPackSize: true,
	},
};

const setAnchorValues = (previous, payload) => {
	const key = Object.keys(payload)[0];
	const val = payload[key];
	switch (key) {
		case "anchorCategory":
			payload = {
				anchorCategory: val,
				anchorSegment: null,
				anchorBrand: null,
				anchorSubBrand: null,
				anchorPackSize: null,
			};
			break;
		case "anchorSegment":
			payload = {
				...previous,
				anchorSegment: val,
				anchorBrand: null,
				anchorSubBrand: null,
				anchorPackSize: null,
			};
			break;
		case "anchorBrand":
			payload = {
				...previous,
				anchorBrand: val,
				anchorSubBrand: null,
				anchorPackSize: null,
			};
			break;
		case "anchorSubBrand":
			payload = {
				...previous,
				anchorSubBrand: val,
				anchorPackSize: null,
			};
			break;
		case "anchorPackSize":
			payload = {
				...previous,
				anchorPackSize: val,
			};
			break;
	}
	return payload;
};

const anchorFilter = (state = anchorInitialState, action) => {
	switch (action.type) {
		case GET_ANCHOR_PRODUCT_FILTERS:
			return {
				...state,
				data: action.payload,
				loading: true,
				error: {},
			};
		case GET_ANCHOR_PRODUCT_FILTERS_SUCESS:
			return {
				...state,
				data: mapFilterOptions(action.payload, action.filterData),
				loading: false,
				error: {},
				selectedFilters: { ...state.selectedFilters, ...selectAllOptions(action.payload, action.filterData) },
				disabledFilters: {
					...state.disabledFilters,
					anchorCategory: false,
				},
			};
		case GET_ANCHOR_PRODUCT_FILTERS_FAILURE:
			return {
				...state,
				data: [],
				loading: false,
				error: action.payload,
			};
		case ON_CHANGE_ANCHOR_OPTION:
			return {
				...state,
				selectedFilters: setAnchorValues(state.selectedFilters, action.payload),
				disabledFilters: disabledFilters(action.payload),
			};
		case CLEAR_ANCHOR_PRODUCT_FILTERS:
			return {
				...anchorInitialState,
			};
		default:
			return state;
	}
};

export default anchorFilter;

const disabledFilters = (payload) => {
	let data = {
		anchorCategory: true,
		anchorSegment: true,
		anchorBrand: true,
		anchorSubBrand: true,
		anchorPackSize: true,
	};
	const key = Object.keys(payload)[0];
	let val = payload[key];
	if (_.isArray(val) && val.length === 0) {
		val = null;
	}

	switch (key) {
		case "anchorCategory":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: val === null,
			};
			break;
		case "anchorSegment":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: false,
				anchorBrand: val === null,
			};
			break;
		case "anchorBrand":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: false,
				anchorBrand: false,
				anchorSubBrand: val === null,
			};
			break;
		case "anchorSubBrand":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: false,
				anchorBrand: false,
				anchorSubBrand: false,
				anchorPackSize: val === null,
			};
			break;
		case "anchorPackSize":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: false,
				anchorBrand: false,
				anchorSubBrand: false,
				anchorPackSize: false,
			};
			break;
	}
	return data;
};
