import { makeStyles } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Title } from "../../../../../../components/Dashboard/Dashboard.Styled";
import IncrementDecrement from "../../../../../../components/IncrementDecrement";
import { NoData } from "../../../../../../styles/Common.Styled";
import { messages } from "../../../../../../util/config";
import MultipleChart from "../MultipleChart";
import RegionElasticitiesTable from "../RegionElasticitiesTable";

const useStyles = makeStyles(() => ({
	center: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	hide: {
		opacity: 0,
		"&:hover": {
			opacity: 1,
		},
	},
	nameBox: { background: "gray", border: "1px solid #efefef", color: "#fff" },
}));

const defaultPeriodicity = "weekly";

const VariableTrendContainer: React.FC<{
	filters: any;
	index;
	originalResponse;
	regionElasticities;
}> = ({ filters, index, originalResponse, regionElasticities }) => {
	const [chartTableData, setChartTableData] = useState<any>({});
	const classes = useStyles();

	const formatChartData = (product) => {
		try {
			let chartData: any = [];
			let chartType = [
				{ type: "column", color: "#38ad6f" },
				{ type: "area", color: "#fc9e66" },
				{ type: "area", color: "#6FBAE3" },
				{ type: "column", color: "#0186e0" },
			];
			let modelVariables: any = [];

			if (filters.dataSource === "inase") {
				modelVariables = ["units", "sales", "volume"];
			} else {
				modelVariables = ["units", "vpu", "sales", "volume"];
			}
			if (product["weeklyData"] !== null) {
				modelVariables = [...modelVariables, ...Object.keys(product["weeklyData"])];
			}
			chartData = [];
			modelVariables.map((key, i) => {
				if (filters.dataSource === "inase") {
					if (key !== "units" && key !== "volume" && key !== "sales") {
						chartData.push({
							type: "area",
							color: chartType[i % chartType.length].color,
							title: product[key].label,
							pricing: product["weeklyData"][key].findIndex((x) => x.date === product.pricingDate),
							data: product["weeklyData"][key].map(({ date: name, value: y, ...rest }) => ({ name, y, ...rest })),
						});
					}
				} else if (key !== "units" && key !== "vpu" && key !== "distribution" && key !== "volume" && key !== "sales") {
					chartData.push({
						type: chartType[i % chartType.length].type,
						color: chartType[i % chartType.length].color,
						title: product[key].label,
						pricing: product["weeklyData"][key].findIndex((x) => x.date === product.pricingDate),
						data: product["weeklyData"][key].map(({ date: name, value: y, ...rest }) => ({ name, y, ...rest })),
					});
				}
			});
			if (filters.dataSource === "sellOut") {
				const i = chartData.findIndex((item) => item.title === "PPU");
				if (i !== -1 && i !== 0) {
					const ppu = chartData.splice(i, 1);
					chartData.unshift(ppu[0]);
				}
			}
			return {
				image: product.image,
				imageHeight: 100,
				imageWidth: 100,
				name: product.name,
				parentName: product.parentName,
				chartData,
			};
		} catch (e) {}
	};

	const onSelectPeriodicity = (item) => {
		if (item && item?.item && Object.keys(originalResponse).length > 0) {
			const payload = {
				..._.omit(originalResponse, "weekly", "monthly", "quarterly", "yearly"),
				weeklyData: originalResponse[item.item],
			};
			const formattedData = { ...formatChartData(payload) };
			setChartTableData(formattedData);
		}
	};

	useEffect(() => {
		if (Object.keys(originalResponse).length > 0) {
			const payload = {
				..._.omit(originalResponse, "weekly", "monthly", "quarterly", "yearly"),
				weeklyData: originalResponse[defaultPeriodicity],
			};
			const formattedData = { ...formatChartData(payload) };
			setChartTableData(formattedData);
		}
	}, [originalResponse]);

	return (
		<Grid container className={"m-b-20 m-t-20"}>
			{Object.keys(chartTableData).length > 0 ? (
				<Grid container className="m-t-10">
					<Grid item sm={6.8}>
						<Title className="ellipsis">Model Variable Trend</Title>
						<Grid
							style={{
								border: "3px solid #cad0d8",
								borderRadius: 10,
								padding: 20,
								position: "relative",
							}}
						>
							<Grid
								style={{
									position: "absolute",
									bottom: 20,
									width: "100%",
									left: 15,
									zIndex: 2,
								}}
								className={classes.hide}
							>
								<IncrementDecrement
									data={originalResponse}
									callback={(data) => {
										onSelectPeriodicity(data);
									}}
									id={"variable-trend-container-multiple-chart" + index}
									defaultOption={
										(localStorage.getItem("periodicity") &&
											JSON.parse(localStorage.getItem("periodicity") || "{}")["variable-trend-container-multiple-chart" + index]?.item) ||
										null
									}
								/>
							</Grid>
							{chartTableData.chartData.length > 0 ? (
								chartTableData.chartData.map(
									(item, j) =>
										item.data.length > 0 && (
											<MultipleChart
												chartType={item.type}
												chartColor={item.color}
												data={item.data}
												axisTitle={{ x: item.title, y: item.title }}
												pricing={item.pricing}
												id={`multiplechart-${j}`}
												key={`multiplechart-${j}`}
												xAxisLabel={j === chartTableData.chartData.length - 1}
												outlier={item.outlier}
											/>
										)
								)
							) : (
								<Box
									style={{
										height: 100,
										display: "table-cell",
										verticalAlign: "middle",
									}}
								>
									<NoData>{messages.noData}</NoData>
								</Box>
							)}
						</Grid>
					</Grid>
					<Grid item sm={0.2}></Grid>
					<Grid item sm={5}>
						{regionElasticities && regionElasticities?.regionCalculation?.length ? (
							<Grid style={{ marginTop: 30 }}>
								<Title className="ellipsis">Region Elasticities</Title>
								<RegionElasticitiesTable data={regionElasticities || []} />
							</Grid>
						) : null}
					</Grid>
				</Grid>
			) : (
				<Grid container spacing={2} className="m-t-10">
					<Title className="ellipsis">Model Variable Trend</Title>
					<Grid container display="flex" justifyContent="center" alignItems="center" height={400}>
						<NoData sx={{ color: "black" }}>{messages.noData}</NoData>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
export default VariableTrendContainer;
