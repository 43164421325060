import { productLogos } from "../mocks/logoMocks";
import { baseApi, graphqlAPI } from "../store/api-constants";
import { AnchorProductsPayload, ISavedDashboardsPayload, PeriodViewPayload, SellPeriodViewPayload, SharedDashboardsPayload } from "../types/common";
import { API } from "./helper";
import {
	DASHBOARD_GEOLEVEL_QUERY,
	DASHBOARD_QUERY,
	UPDATE_SAVED_DASHBOARD,
	FILTER_CATEGORY_QUERY,
	SHARE_DASHBOARD_QUERY,
	ANCHOR_PRODUCTS_QUERY,
	PERIOD_VIEW_QUERY,
	SELL_PERIOD_VIEW_QUERY,
} from "./queries/common";
import {
	GET_TRENDING_DASHBOARD_QUERY,
	GET_FAVORITE_DASHBOARD_QUERY,
	ADD_DELETE_FAVORITE_DASHBOARD_QUERY,
	GET_SAVED_DASHBOARD_QUERY,
	GET_SAVED_DASHBOARD_BY_ID_QUERY,
	DELETE_SAVED_DASHBOARD,
	GET_SHARED_DASHBOARD_QUERY,
	DELETE_SHARED_DASHBOARD_QUERY,
	GET_SHARED_BY_ME_DASHBOARD_QUERY,
} from "./queries/home";

export const getCategoriesByCountry = async (countries: string[], userId?: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = FILTER_CATEGORY_QUERY(countries, userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data: any[] = [];
			if (response && response.data) {
				data = response.data.data.filterCategories || [];
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};
export const getGeoLevel2 = async (userId, dashboardKey) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = DASHBOARD_GEOLEVEL_QUERY(userId, dashboardKey);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.dashboardKeygeoLevel2;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};
export const fetchCompetitorProductFiltersData = async (payload) => {
	try {
		const baseApiUrl: string = await baseApi();
		return await API.post(`${baseApiUrl}dashboards/ProductFilter`, payload);
	} catch (e) {
		return e;
	}
};

/* Calls GraphQL endpoint to get product filters.
 * @param {string} url - The url to call the API. Different pages have different URLs which they fetch from.
 * If the URL is "graphql" then it will call the GraphQL endpoint to get Elasticity Pricing track product filters.
 * @param payload - The object contains the request payload.
 * @param {number} userId - The user id needed for GraphQL endpoint.
 * @param queryfunction - The function to create respective queries.
 */
export const fetchFiltersData = async (url, payload, userId?, queryfunction?) => {
	try {
		if (url === "graphql" && userId && queryfunction) {
			const graphQLApiUrl: string = await graphqlAPI();
			let queryParameter = queryfunction(payload, userId);
			return await API.post(graphQLApiUrl, queryParameter).then((response) => {
				let data = {};
				if (response && response.data) {
					const keys = Object.keys(response.data.data);
					data = response.data.data[keys[0]];
					Object.keys(data).forEach((key) => {
						if (Array.isArray(data[key]) && data[key].length === 0) {
							data[key] = null;
						}
					});
				}
				return { data: data };
			});
		} else {
			const baseApiUrl: string = await baseApi();
			return await API.post(`${baseApiUrl}${url}`, payload);
		}
	} catch (e) {
		return e;
	}
};

/* Calls GraphQL endpoint to fetch period view start date and end date.
 * @param {string} dashboardKey - The dashboard key.
 * @param {PeriodViewPayload} payload - The object contains period view request payload.
 * @returns the period view start date and end date.
 */
export const fetchPeriodViewDates = async (dashboardKey: string, payload: PeriodViewPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = PERIOD_VIEW_QUERY(dashboardKey, payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.periodView;
			}
			return { status: response.status, data: data };
		});
	} catch (e) {
		return e;
	}
};

/* Calls GraphQL endpoint to update saved dashboard records.
 * @param {number} userId - The user id.
 * @param {ISavedDashboardsPayload} payload - The object contains saved dashboard request payload.
 * @returns the object of dashboard properties.
 */
export const saveDashboardData = async (userId: number, payload: ISavedDashboardsPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		/* This will convert (") into (\") to allow passing the whole string as a single parameter.
		This however converts any existing (\") into (\\"), and this causes failure due to (\\) being an escape character.
		So, we will handle this by converting (\\") into (\\\"). */
		const doubleQuotesRegEx: RegExp = /\"/gi; //For double quotes (")
		const escapedDoubleQuotesregEx: RegExp = /\\\\\"/gi; //For updated escaped double quotes (\\")
		payload.mmpw = payload.mmpw.replace(doubleQuotesRegEx, '\\"').replace(escapedDoubleQuotesregEx, '\\\\\\"'); // Converts all (") into (\") and then all (\\") into (\\\").
		payload.filters = payload.filters.replace(doubleQuotesRegEx, '\\"').replace(escapedDoubleQuotesregEx, '\\\\\\"'); // Converts all (") into (\") and then all (\\") into (\\\").
		let queryParameter = UPDATE_SAVED_DASHBOARD(userId, payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.updateSavedDashboard;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};

/* Calls GraphQL endpoint to get list of saved dashboards.
 * @param {number} userId - The user id.
 * @returns the list of saved dashboards.
 */
export const getAllSavedDashboards = async (userId) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_SAVED_DASHBOARD_QUERY(userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getsaves;
			}
			return { data: data };
		});
	} catch (e) {
		alert("some thing went wrong");
	}
};

/* Calls GraphQL endpoint to get a saved dashboard properties.
 * @param {number} userId - The user id.
 * @param {number} id - The dashboard id.
 * @param {string} type - The type of dashboard.
 * @returns the object of the saved dashboard properties.
 */
export const getDashboardData = async (dashboardId, type, userId) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_SAVED_DASHBOARD_BY_ID_QUERY(userId, dashboardId, type);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getsavesbyid;
			}
			return { data: data };
		});
	} catch (e) {
		alert("some thing went wrong");
	}
};

export const getTrendingDashboard = async (userId, type) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_TRENDING_DASHBOARD_QUERY(type, userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.gettrendingdashboard;
			}
			return { data: data };
		});
	} catch (e) {
		alert("some thing went wrong");
	}
};

/* Calls GraphQL endpoint to delete a saved dashboard.
 * @param {number} userId - The user id.
 * @param {number} id - The dashboard id.
 * @returns The deleted dashboard properties.
 */
export const deleteSavedDashboard = async (id, userId) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = DELETE_SAVED_DASHBOARD(id, userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.deletesaveddashboards;
			}
			return { data: data };
		});
	} catch (e) {
		alert("some thing went wrong");
	}
};

/* Calls GraphQL endpoint to share any dashboard.
 * @param {string} userId - The user id.
 * @param {SharedDashboardsPayload} payload - The payload value of type SharedDashboardsPayload.
 * @returns success pop-up when dashboard shared successfully	else show an error alert if API call fails/ throws error.
 */
export const onShareDashboard = async (userId: number, payload: SharedDashboardsPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		/* This will convert (") into (\") to allow passing the whole string as a single parameter.
		This however converts any existing (\") into (\\"), and this causes failure due to (\\) being an escape character.
		So, we will handle this by converting (\\") into (\\\"). */
		const doubleQuotesRegEx: RegExp = /\"/gi; //For double quotes (")
		const escapedDoubleQuotesregEx: RegExp = /\\\\\"/gi; //For updated escaped double quotes (\\")
		payload.mmpw = payload.mmpw.replace(doubleQuotesRegEx, '\\"').replace(escapedDoubleQuotesregEx, '\\\\\\"'); // Converts all (") into (\") and then all (\\") into (\\\").
		payload.filters = payload.filters.replace(doubleQuotesRegEx, '\\"').replace(escapedDoubleQuotesregEx, '\\\\\\"'); // Converts all (") into (\") and then all (\\") into (\\\").
		let queryParameter = SHARE_DASHBOARD_QUERY(userId, payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.updateShares;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};

/* Calls GraphQL endpoint to get list of dashboards shared with user.
 * @param {string} userId - The user id.
 * @returns list of dashboards shared with user else show an error pop up if API call fails.
 */
export const getSharedDashBoardsToMe = async (userId) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_SHARED_DASHBOARD_QUERY(userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.getSharedDashboards;
			}
			return { data: data };
		});
	} catch (e) {
		alert("some thing went wrong");
	}
};

/* Calls GraphQL endpoint to get list of dashboards shared by the user.
 * @param {string} userId - The user id.
 * @returns list of dashboards shared by user.
 */
export const getSharedDashBoardsByMe = async (userId: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_SHARED_BY_ME_DASHBOARD_QUERY(userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.dashboardSharesByMe;
			}
			return { data: data };
		});
	} catch (e) {
		alert("some thing went wrong");
	}
};

export const getAllDashboards = async () => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = DASHBOARD_QUERY;
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.dashboards;
			}
			return { data: data };
		});
	} catch (e) {
		alert("some thing went wrong");
	}
};

/* Calls GraphQL endpoint to fetch sell period view start date and end date.
 * @param {string} dashboardKey - The dashboard key.
 * @param {SellPeriodViewPayload} payload - The object contains sell period view request payload.
 * @returns the sell period view start date and end date.
 */
export const fetchSellPeriodViewDates = async (dashboardKey: string, payload: SellPeriodViewPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = SELL_PERIOD_VIEW_QUERY(dashboardKey, payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.sellPeriodView;
			}
			return { status: response.status, data: data };
		});
	} catch (e) {
		return e;
	}
};

export const getFavoriteDashboards = async (userId: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_FAVORITE_DASHBOARD_QUERY(userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.favoriteDashboard;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};

/* Calls GraphQL endpoint to add a dashboard to favorite list.
 * @param {string} userId - The user id.
 * @param {string} dashboardName - The name of the dashboard.
 * @returns Favorited dashboard data and API status on completion.
 */
export const favoriteDashboard = async (userId: string, dashboardName: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = ADD_DELETE_FAVORITE_DASHBOARD_QUERY(userId, dashboardName, "add");
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.favorite;
			}
			return { data: data, status: response.status };
		});
	} catch (e) {
		return e;
	}
};

/* Calls GraphQL endpoint to remove a dashboard from favorite list.
 * @param {string} userId - The user id.
 * @param {string} dashboardName - The name of the dashboard.
 * @returns API status on completion.
 */
export const removeFavoriteDashboard = async (userId: string, dashboardName: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = ADD_DELETE_FAVORITE_DASHBOARD_QUERY(userId, dashboardName, "delete");
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			return { data: null, status: response.status };
		});
	} catch (e) {
		return e;
	}
};

/* Calls GraphQL endpoint to delete a shared dashboard.
 * @param {string} type - The type of dashboard.
 * @param {number} sharedDashboardId - The shared dashboard id.
 * @param {string} userId - The user id.
 * @returns API status on completion.
 */
export const deleteSharedDashboard = async (type: string, sharedDashboardId: number, userId: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = DELETE_SHARED_DASHBOARD_QUERY(type, sharedDashboardId, userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			return { data: null, status: response.status };
		});
	} catch (e) {
		return e;
	}
};

export const fetchAnchorFiltersData = async (userId: number, payload: AnchorProductsPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = ANCHOR_PRODUCTS_QUERY(payload, userId);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.anchorproducts;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};

/* This Function checks if an icon/logo exists of a product for a country or not and returns url of image if exists.
 * @param {string} country - Name of country.
 * @param {string} imageName - Name of logo image.
 */
export const fetchImageURL = (country, imageName) => {
	const fileIndex: number = productLogos.findIndex((value) => value.country === country && value.value === imageName);
	if (fileIndex !== -1) {
		return `/ProductImages/${country}/${imageName}.${productLogos[fileIndex].type}`;
	}
	return null;
};
