import { LINEAR_REGRESSION } from "./constants";

let intiState = { linearRegression: false };

const ProfitParabolaLinearRegression = (state = intiState, action) => {
	if (action.type === LINEAR_REGRESSION) return { ...state, linearRegression: action.payload };
	else return state;
};

export default ProfitParabolaLinearRegression;
