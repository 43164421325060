import { graphqlAPI } from "../../store/api-constants";
import { API } from "../../util/helper";
import { CATEGORIES_DASHBOARD_QUERY } from "../../util/queries/common";

export const getCategoryData = async (userId: string, key: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = CATEGORIES_DASHBOARD_QUERY(userId, key);
		return API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			if (response && response.data) {
				data = response.data.data.categories;
			}
			return { data: data };
		});
	} catch (e) {
		return e;
	}
};
