import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import DashboardActions from "../../components/DashboardActions";
import GlobalNotifications from "../../components/GlobalNotificationContainer";
import { pageList } from "../../mocks/common";
import TopFilters from "./components/TopFilters";
import UnitVariationExpectedContainer from "./components/UnitVariationExpectedContainer";
import ResultPerMonthAndAccumulated from "./components/ResultPerMonthAndAccumulated";
import PriceElasticityRealVsExpected from "./components/PriceElasticityRealVsExpected";
import { removeMMPWFromLocal } from "../../util/helper";
import DashboardSourceList from "../../components/DashboardSourceList";
import { RootStateOrAny, useSelector } from "react-redux";
import ModelDetails from "./components/ModelDetails";
import dataObject from "../../mocks/elasticityPricingTrack";

const ElasticityPricingTrack: React.FC<{}> = () => {
	const userData = useSelector((store: RootStateOrAny) => store.User.data);
	const [appliedFilters, setAppliedFilters] = useState({} as any);
	const [defaultFilters, setDefaultFilters] = useState({});
	const [variationType, setVariationType] = useState("");
	const [simulationDetail, setSimulationDetail] = useState("");
	const [disableDownloadButton, setDisableDownloadButton] = useState(true);
	const search = window.location.search;
	const [urlParams] = useState(new URLSearchParams(search));
	const recordId = parseInt(urlParams.get("id"));

	const [resultPerAccumulatedLocalFilters, setResultPerAccumulatedLocalFilters] = useState({});

	const onApplyFilter = (data) => {
		if (data.type === "FILTER_APPLIED") {
			setDisableDownloadButton(false);
			removeMMPWFromLocal("elasticity-pricing-track");
			setAppliedFilters(data.filters);
		}
	};

	useEffect(() => {
		return () => {
			removeMMPWFromLocal("elasticity-pricing-track");
		};
	}, []);
	useEffect(() => {
		if (userData && !recordId) {
			const permissionCategories: string[] = userData.permissionCategory.split(",");
			const defaultPermissionCategory = dataObject.defaultEPTFilterConfigurations.category.find((categoryValue) =>
				permissionCategories.includes(categoryValue)
			);
			if (defaultPermissionCategory && Object.keys(defaultPermissionCategory).length) {
				setDefaultFilters(dataObject.defaultEPTFilterConfigurations);
				setAppliedFilters(dataObject.defaultEPTFilterConfigurations);
			}
		}
	}, [userData, dataObject.defaultEPTFilterConfigurations]);

	const getSaveDashBoardsData = (data) => {
		if (data.filters) {
			let savedDashboardData = { filters: JSON.parse(data.filters) };
			localStorage.setItem("periodicity", savedDashboardData.filters.periodicity || "{}");
			setDisableDownloadButton(false);
			setDefaultFilters(savedDashboardData?.filters?.global);
			setAppliedFilters(savedDashboardData?.filters?.global);
		}
	};

	const ElasticityPricingTrack = useSelector((state: any) => state.ElasticityPricingTrack);

	return userData ? (
		<DashboardActions
			title="Elasticity Pricing Track"
			data={{
				filters: {
					global: {
						...appliedFilters,
						variationType,
						simulationDetail,
						...resultPerAccumulatedLocalFilters,
					},
				},
				mmpwKey: "elasticity-pricing-track",
			}}
			callback={getSaveDashBoardsData}
			dashboardKey={pageList[8].id}
			disableDownloadButton={disableDownloadButton || ElasticityPricingTrack.unitVariationExpected}
		>
			<Grid container spacing={2} className="m-b-20">
				<GlobalNotifications pageName={pageList[8].value} />
			</Grid>
			<TopFilters callback={onApplyFilter} defaultFilters={Object.keys(defaultFilters).length > 0 ? defaultFilters : {}} />
			<UnitVariationExpectedContainer filters={appliedFilters} />
			<ResultPerMonthAndAccumulated
				callback={setResultPerAccumulatedLocalFilters}
				defaultFilters={Object.keys(defaultFilters).length > 0 ? defaultFilters : {}}
				filters={appliedFilters}
			/>
			<PriceElasticityRealVsExpected
				filters={appliedFilters}
				defaultFilters={Object.keys(defaultFilters).length > 0 ? defaultFilters : {}}
				callback={setVariationType}
			/>
			<ModelDetails filters={appliedFilters} callback={setSimulationDetail} />
			<DashboardSourceList
				dashboardName={pageList[8].id}
				appliedFilters={appliedFilters}
				category={appliedFilters.category}
				sellInSellOutData={appliedFilters.dataSource}
			/>
		</DashboardActions>
	) : null;
};

export default ElasticityPricingTrack;
